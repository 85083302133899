/**
* MyBets
* @author David Calleja
* @author Robin Ebers <robin.ebers@gmail.com>
*/
(function(raceBetsJS) {
    raceBetsJS.application.content.myBets = function() {
        // @private

        var container_template = raceBetsJS.application.templates.myBets,
            filters_template = raceBetsJS.application.templates.myBets.filters,
            type = 'filter',
            currentPage = 1,
            filter = {},
            filterString = '',
            dataURL = '',
            betTypes= ['WIN', 'PLC', 'SHW', 'WP', 'WS', 'PS', 'WPS', 'EXA', 'TRI', 'SFC', 'QNL', 'SWG', 'TRO', 'ITA', 'TRT'],
            betCategories= ['BOK', 'FXD'],
            betStatuses= ['OPN', 'WIN', 'LSD', 'RFD', 'CNC', 'INV'];
            /*
            myBetsInterval = new raceBetsJS.time.Interval({
                interval: 30000,
                tick: function() {
                    $.ajax({
                        url: dataURL,
                        success: render
                    });

                }
            });
            */

        /**
        * initHome
        *
        * Initiate Page when data has been received
        */
        var initPage = function(data) {
            var container_data = {'type':type, 'pages':data.pagesNum, 'filterString':filterString,'currentPage':currentPage},
                container = container_template(container_data);

            raceBetsJS.application.header.navigation.activateItemByKey('account bets');

            raceBetsJS.application.globals.contentMain.html($(container).addClass('my-bet-' + type));

            //Filter data if necessary
            if(type == 'upcoming'){
                data.results = _.filter(
                    data.results,
                    function(result){
                        return result.antePost == "0";
                    }
                );
            }

            if (data.type && data.type === 'error') {
                switch (data.errorMsg) {
                    case 'DATE_RANGE_LIMIT':
                        showDateRangeNotice(raceBetsJS.i18n.print('errorTimeRangeExceeded', { days: 31 }));
                        break;
                    case 'FROM_DATE_LIMIT':
                        showDateRangeNotice(raceBetsJS.i18n.print('errorDateFromExceeded', { days: 180 }));
                        break;
                }
            }

            render(data);

            if(type == 'filter'){
                createFormElements();
            }

            initEvents();



            //myBetsInterval.start();

            filter = {}; // reset filter
        };

        var render = function(data){
            var output = $('<div></div>');

            if(data.results !== undefined && data.results.length > 0){
                $.each(
                    data.results,
                    function(result){
                        //convert if currencies do not match
                        if(this.bets) {
                            for(var i = 0, l = this.bets.length; i < l; i++) {
                                var bet = this.bets[i];
                                if(bet.currency !== raceBetsJS.application.user.currency) {
                                    bet.totalStake = raceBetsJS.localize.exchange(bet.totalStake, bet.currency, raceBetsJS.application.user.currency);
                                    bet.unitStake = raceBetsJS.localize.exchange(bet.unitStake, bet.currency, raceBetsJS.application.user.currency);
                                    bet.winnings = raceBetsJS.localize.exchange(bet.winnings, bet.currency, raceBetsJS.application.user.currency);
                                    bet.refund = raceBetsJS.localize.exchange(bet.refund, bet.currency, raceBetsJS.application.user.currency);
                                }
                            }
                        }
                        output.append(raceBetsJS.application.templates.myBets[this.type.toLowerCase()](this));
                    }
                );

            } else {
                output.append('<p>' + raceBetsJS.i18n.data.noBetsFound + '</p>');
            }

            raceBetsJS.application.globals.contentMain.find('.bets').find('.content').html(output.html());
        };

        var createFormElements = function(){
            raceBetsJS.application.globals.contentMain.find('.periods').append(filters_template({'selected':filter, 'betTypes':betTypes, 'betCategories':betCategories, 'betStatuses':betStatuses}));
        };

        var checkDateRangeOutOfBounds = function(from, to){
            return (to - from) > 2678400;
        };

        var initEvents = function() {

            var datapickers = [];
            if(type == 'filter'){
                var timeRange = $('[data-filter="time-range"]'),
                    dateFrom = $('[data-filter="date-from"]'),
                    dateTo = $('[data-filter="date-to"]');

                // time range
                timeRange.on('click', 'button',function() {
                    var thisValue = $(this).attr('data-value');
                    if (thisValue) {
                        var now = new Date();
                        now.setHours(0);
                        now.setMinutes(0);
                        now.setSeconds(0);
                        now.setMilliseconds(0);
                        var unixTime = Math.round(now.getTime()/1000);

                        try {
                            var range = JSON.parse(thisValue);
                        } catch (error) {
                            range = [0,0];
                        }
                        var tsFrom = unixTime - range[0];
                        dateFrom.data('timestamp', tsFrom);
                        $(dateFrom).val(raceBetsJS.format.date(raceBetsJS.i18n.data.dateFormatDate, tsFrom))

                        var tsTo = unixTime - range[1];
                        dateTo.data('timestamp', tsTo);
                        $(dateTo).val(raceBetsJS.format.date(raceBetsJS.i18n.data.dateFormatDate, tsTo))
                    }
                });

                $('#filters').on('change', 'select', function(e) {
                    $(e.target).nextAll('.c-select__value').text($(e.target).find('option:selected').text());
                })

                $('#filters select').each( function(e) {
                    var _self = $(this),
                        text = _self.find('option[value="'+ _self.val() +'"]').text();

                    _self.nextAll('.c-select__value').text(text);
                });

                // create date picker
                $.each([dateFrom, dateTo], function(index, value) {
                    value.val(raceBetsJS.format.date(raceBetsJS.i18n.data.dateFormatDate, value.data('timestamp')));

                    var datapickerInput = $(value);
                    var datapicker = $('#mybets_datepicker-'+(index+1)).datepicker({
                        firstDay: 1,
                        dateFormat: '@',
                        changeMonth: true,
                        changeYear: true,
                        updateInput: false,
                        yearRange: '-1:+1',
                        minDate: "-180d",
                        defaultDate: new Date($(value).data('timestamp') * 1000),
                        onSelect: function(dateText, inst) {
                            dateText = Math.round(dateText/1000);
                            datapicker.hide();
                            datapickerInput.data('timestamp', dateText);
                            datapickerInput.attr('data-timestamp', dateText);

                            raceBetsJS.localize.time(value, raceBetsJS.application.assets.settings.get().dialog.general.timezone);
                        }
                    }).hide();

                    function datepickerDate(timestamp) {
                        var d = new Date(timestamp * 1000);
                        var day   = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
                        var month = ((d.getMonth()+1) < 10) ? '0' + (d.getMonth()+1) : (d.getMonth()+1);
                        var year  = d.getFullYear();

                        return day + '/' + month + '/' + year ;
                    }

                    datapickerInput.val( datepickerDate($(value).data('timestamp') ) );

                    datapickerInput.click(function () {
                        datapicker.toggle();
                    });

                    datapickers.push(datapicker);

                });

                // Hide when click outside
                $(document).on('click.mybets-datepicker', function (e) {
                    var target = $(e.target);
                    if(!target.is('#mybets_datepicker-1') && !target.next().is('#mybets_datepicker-1') && !target.parents().filter('.ui-datepicker-header').length) {
                        datapickers[0].hide();
                    }

                    if(!target.is('#mybets_datepicker-2')  && !target.next().is('#mybets_datepicker-2') && !target.parents().filter('.ui-datepicker-header').length) {
                        datapickers[1].hide();
                    }
                });

                $('#submit-filter').on('click', function(e) {
                    var href = "";
                    href = '/bets/main/filter';

                    href += dateFrom.data('timestamp') ? '/from/' + raceBetsJS.application.assets.timezone.date('Y-m-d', dateFrom.data('timestamp')) : '';
                    href += dateTo.data('timestamp') ? '/to/' + raceBetsJS.application.assets.timezone.date('Y-m-d', dateTo.data('timestamp')) : '';

                    href += $('[data-filter="bet-type"]').val() ?
                        '/type/' + $('[data-filter="bet-type"]').val() : '';

                    href += $('[data-filter="category"]').val() ?
                        '/category/' + $('[data-filter="category"]').val() : '';

                    href += $('[data-filter="status"]').val() ?
                        '/status/' + $('[data-filter="status"]').val() : '';

                    href += $('[data-filter="betslip-type"]').val() ?
                        '/betslips/' + $('[data-filter="betslip-type"]').val() : '';

                    href += $('[data-filter="event-type"]').val() ?
                        '/eventtype/' + $('[data-filter="event-type"]').val() : '';

                    href += $('[data-filter="public-id"]').val() ?
                        '/id/' + $('[data-filter="public-id"]').val() : '';

                    if(checkDateRangeOutOfBounds(dateFrom.data('timestamp'),dateTo.data('timestamp'))){
                        showDateRangeNotice(raceBetsJS.i18n.print('errorTimeRangeExceeded', { days: 31 }));
                    } else {
                        raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + href);
                    }
                });
            }

            $('#my-bets').find('.bets').on(
                'click','tr',
                function(){
                    raceBetsJS.application.assets.betDetails.show({
                        id: $(this).data('id')
                    });
                }
            );
        };

        var showDateRangeNotice = function(content){
            raceBetsJS.application.assets.messageBox.show({
                type: 'notice',
                closeButton: true,
                prepend: raceBetsJS.application.globals.contentMain,
                content: content || ''
            });
        };

        // @public
        return {
            init: function() {
                // Setup route controller
                raceBetsJS.application.contentController.addDynamicPage({
                    urlPattern: /\/bets\/?$|bets\/main\/([a-z]+)\/?(from\/([0-9\-]+)\/to\/([0-9\-]+)\/?)?(type\/(\w+)\/?)?(category\/(\w+)\/?)?(status\/(\w+)\/?)?(betslips\/(\w+)\/?)?(eventtype\/(\w+)\/?)?(page\/([0-9]+)\/?)?(id\/([\w\d-]+)\/?)?/,
                    dataSourceURLBuilder: function(period, from_to_match, from, to, bettype_match, bettype, category_match, category, status_match, status, betslips_match, betslips, eventtype_match, eventtype, page_match, page, id_match, id) {

                        // IE7/8 regex fix
                        if (from === '') { from = undefined; }
                        if (to === '') { to = undefined; }
                        if (bettype === '') { bettype = undefined; }
                        if (category === '') { category = undefined; }
                        if (status === '') { status = undefined; }
                        if (betslips === '') { betslips = undefined; }
                        if (eventtype === '') { eventtype = undefined; }
                        if (page === '') { page = undefined; }
                        if (id === '') { id = undefined; }
                        // -finito-

                        if(!_.isUndefined(from)){
                            filter.from = {};
                            filter.from.value = from;
                            filter.from.timestamp = new Date(from).getTime() / 1000;
                        }

                        if(!_.isUndefined(from)){
                            filter.to = {};
                            filter.to.value = to;
                            filter.to.timestamp = new Date(to).getTime() / 1000;
                        }

                        filter.type = bettype;
                        filter.category = category;
                        filter.status = status;
                        filter.betslips = betslips;
                        filter.eventtype = eventtype;
                        filter.id = id;

                        if(!_.isUndefined(from) && !_.isUndefined(to) && checkDateRangeOutOfBounds(filter.from.timestamp,filter.to.timestamp)){
                            filter.from = filter.to = {};
                        }

                        filterString = '';

                        $.each(
                            filter,
                            function(key, value){
                                if(!_.isUndefined(value)){
                                    filterString += '/' + key + '/' + (_.isObject(value) ? value.value : value);
                                }
                            }
                        );

                        if(_.isUndefined(page)){
                            currentPage=1;
                        } else {
                            currentPage = parseInt(page);
                        }

                        if(!period){
                            type = 'today';
                            dataURL = '/ajax/bets/today/' + ((!_.isUndefined(page) ? 'page/' + page : ''));
                        };

                        switch(period){
                            case 'today':
                            case 'yesterday':
                            case 'upcoming':
                            case 'antepost':
                                type = period;
                                dataURL = '/ajax/bets/' + period + ((!_.isUndefined(page) ? '/page/' + page : ''));
                                break;

                            case 'filter':
                                type = 'filter';
                                dataURL = '/ajax/bets/filter/' + ((!_.isUndefined(from) && !_.isUndefined(to) ? 'from/' + from + '/to/' + to + '/' : '')) + ((!_.isUndefined(bettype) ? 'type/' + bettype + '/' : '')) + ((!_.isUndefined(category) ? 'category/' + category + '/' : '')) + ((!_.isUndefined(status) ? 'status/' + status + '/' : '')) + ((!_.isUndefined(betslips) ? 'betslips/' + betslips + '/' : '')) + ((!_.isUndefined(eventtype) ? 'eventtype/' + eventtype + '/' : '')) + ((!_.isUndefined(page) ? 'page/' + page : '') + ((!_.isUndefined(id) ? 'id/' + id : '')));
                                break;
                        };

                        return dataURL;
                    },
                    onDataReceived: initPage,
                    onUnload: function() {
                        //myBetsInterval.stop();
                    }
                });
            }
        };
    }();
})(raceBetsJS);
