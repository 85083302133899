/**
* Dropdown
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
    raceBetsJS.application.assets.Dropdown = Class.extend({
        /**
        * init
        *
        * Construct a new Dropdown object
        */
        init: function(elem, options) {
            elem = $(elem);

            // options
            this.options = $.extend({
                width: 200,
                buttonWidth: 26,
                disabled: false,
                selected: null, // value which is initially selected
                sortOptions: false,
                textAlign: 'left',
                isTextInput: false, // should it be also possible to enter an own value?
                textInputOnBlur: false, // callback for onBlur event of text input (e.g. to format input)
                onChange: false // on change callback
            }, options);

            // dom references
            this.dom = {};

            // create the dom element
            this.dom.dropdown = $('<div />')
                .addClass('rb-dropdown clearfix '
                    + (this.options.isTextInput ? 'text-input' : 'dropdown-only')
                ).css({ width: this.options.width + 'px' });

            // create hidden field
            this.dom.hiddenField = $('<input />').attr({
                type: 'hidden',
                name: elem.attr('name')
            });

            // insert hidden field
            this.dom.dropdown.append(this.dom.hiddenField);

            // insert select element
            this.dom.select = $('<div />').addClass('select clearfix');

            // insert text box
            this.dom.select.append($('<input />')
                .attr({
                    type: 'text'
                })
                .css({
                    outerWidth: (this.options.width - this.options.buttonWidth) + 'px',
                    textAlign: this.options.textAlign
                })
            );

            // insert button
            this.dom.select.append($('<div class="btn button"></div>'));

            // insert select to the dropdown
            this.dom.dropdown.append(this.dom.select);

            // create options container
            this.dom.options = $('<ul />')
                .addClass('options')
                .css({ minWidth: this.options.width + 'px' })
                .hide();

            // create options
            var optionList = [];
            $.each(elem.find('option'), $.proxy(function(key, oldOption) {
                var oldOption = $(oldOption);
                var option = $('<li />')
                    .attr('data-value', oldOption.attr('value')) // when using .data() we can not use the value later with a css selector
                    .html(oldOption.html());

                option.data('sort', oldOption.data('sort'));

                if (oldOption.data('flag')) {
                    option.addClass('with-flag').prepend($('<span />').addClass('c-flag isCountry' + oldOption.data('flag').toUpperCase()));
                }

                if (!this.options.selected && oldOption.attr('selected')) {
                    this.options.selected = oldOption.attr('value');
                }

                optionList.push(option);
                //this.dom.options.append(option);
            }, this));

            // sort options
            if (this.options.sortOptions) {
                optionList = _.sortBy(optionList, function(option) {
                    var a = option.data('sort') || option.text();
                    a = a.toLowerCase();
                    a = a.replace(/ä/g,"a");
                    a = a.replace(/ö/g,"o");
                    a = a.replace(/ü/g,"u");
                    a = a.replace(/ß/g,"s");
                    return a;
                });
            }

            // insert options
            $.each(optionList, $.proxy(function(index, option) {
                this.dom.options.append(option);
            }, this));

            // append options to the dropdown
            this.dom.dropdown.append(this.dom.options);

            // observe select click
            this.dom.select.on('click', $.proxy(function(e) {
                if (e.target.tagName == 'DIV') {
                    $(e.target).blur();
                }

                if (this.dom.options.is(':hidden') && this.enabled && (!this.options.isTextInput || e.target.tagName == 'DIV')) {
                    // set selected class for current option
                    this.dom.options.find('li.selected').removeClass('selected');
                    this.dom.options.find('li[data-value="' + this.val() + '"]').addClass('selected');

                    this.dom.dropdown.addClass('active');

                    // show options
                    this.dom.options.show();

                    // hide on click (doesn't matter if outside or inside this element)
                    $('html').one('click', $.proxy(function() {
                        this.dom.options.hide();
                        this.dom.dropdown.removeClass('active');
                    }, this));

                    // stop immediate propagation, otherwise the handler assigned before is immediately triggered
                    e.stopImmediatePropagation();
                }
            }, this));

            // observe text input
            if (this.options.isTextInput) {
                this.dom.select
                    .on('click', 'input', $.proxy(function(e) {
                        if (!this.enabled) {
                            return;
                        }

                        $(e.target).select();
                    }, this))
                    .on('keypress', 'input', function(e) {
                        if (e.which == 13) { // Enter key pressed
                            $(e.target).blur();
                            e.preventDefault(); // otherwise IE9 behaviour is strange
                        }
                    })
                    .on('blur', 'input', this.options.textInputOnBlur || $.proxy(function(e) {
                        this.val($(e.target).val());
                    }, this));
            }

            // observe options hover
            this.dom.options.on('mouseover', 'li:not(.selected)', $.proxy(function(e) {
                this.dom.options.find('li.selected').removeClass('selected');
                $(e.target).addClass('selected');
            }, this));

            // observe options click
            this.dom.options.on('click', 'li', $.proxy(function(e) {
                this.val($(e.target).attr('data-value'), true); // when using .data() strings like "0.50" are converted to floats what leads to problems
            }, this));

            // enable
            if (this.options.disabled) {
                this.disable();
            } else {
                this.enable();
            }

            // select the given option or by default the first one
            this.val(this.options.selected || this.dom.options.find('li').first().attr('data-value'), true);

            // finally replace the old select with the new dropdown
            elem.replaceWith(this.dom.dropdown);

            // init done
            this.initDone = true;
        },

        /**
        * disable
        *
        * Disables the element
        */
        disable: function() {
            this.dom.dropdown.removeClass('enabled').addClass('disabled');
            this.dom.select.find('input').attr('readonly', true);
            this.enabled = false;

            return this;
        },

        /**
        * enable
        *
        * Enables the element
        */
        enable: function() {
            this.dom.dropdown.addClass('enabled').removeClass('disabled');
            this.dom.select.find('input').attr('readonly', !this.options.isTextInput);
            this.enabled = true;

            return this;
        },

        /**
        * selectFirst
        *
        * Selects the first option from the dropdown
        */
        selectFirst: function() {
            this.val(this.dom.options.find('li').first().attr('data-value'), true);
            return this;
        },

        /**
        * setOptions
        *
        * Removes the current options and inserts the given ones
        */
        setOptions: function(options) {
            this.dom.options.empty();

            $.each(options, $.proxy(function(key, opt) {
                var option = $('<li />')
                    .attr('data-value', opt.value) // when using .data() we can not use the value later with a css selector
                    .html(opt.title);

                this.dom.options.append(option);
            }, this));

            if (!this.dom.options.find('li[data-value="' + this.val() + '"]').length) {
                // if currently selected option does not exist, select first one
                this.val(this.dom.options.find('li').first().attr('data-value'), true);
            } else {
                // select current option once again (title might have changed)
                this.val(this.val(), true);
            }

            return this;
        },

        /**
        * val
        *
        * Read the current value or set a value
        *
        * @param selected  boolean, true if the value was selected from the dropdown
        */
        val: function(value, selected) {
            if (value === undefined) {
                return this.dom.hiddenField.attr('value');
            } else {
                if ((!selected && !this.options.isTextInput)) {
                    return;
                }

                var oldValue = this.dom.hiddenField.attr('value');

                this.dom.hiddenField.attr('value', value);

                if (selected) {
                    var selectedOption = this.dom.options.find('li[data-value="' + value + '"]');
                    var flag = selectedOption.find('span.c-flag');
                    var input = this.dom.select.find('input');

                    input.val(selectedOption.text());

                    input.siblings('span.c-flag').remove();
                    if (flag.length) {
                        input
                            .before(flag.clone())
                            .addClass('with-flag')
                            .width(this.options.width - this.options.buttonWidth - 21);
                    } else {
                        input.removeClass('with-flag').width(this.options.width - this.options.buttonWidth);
                    }
                }

                if (this.initDone && value !== oldValue && _.isFunction(this.options.onChange)) {
                    this.options.onChange(this);
                }

                return this;
            }
        },

        /**
        * focus
        *
        * Focuses the text input
        */
        focus: function() {
            this.dom.select.find('input').focus();
        }
    });
})(raceBetsJS);
