/**
* i18n
*/

(function(raceBetsJS) {
	raceBetsJS.i18n = {
        /**
        * print
        *
        * @param elem
        * @param replacements
        * @param unescapeHtml
        * @return string
        */

        /**
         * Contains object with translations for the mobile app.
         */
        mobile: {},

        print: function(elem, replacements, unescapeHtml) {
		    var string = raceBetsJS.i18n.data[elem];

            if (replacements != undefined) {
		        $.each(replacements, function(key, value) {
                    var pattern = new RegExp("{" + key + "}", "g");
			        string = string.replace(pattern, value);
		        });
            }

		    return string;
        },

        /**
        * getRaceTitle
        *
        * @param raceTitle
        * @param raceNumber
        * @return string
        */
        getRaceTitle: function(raceTitle, raceNumber) {
            if (raceTitle !== undefined && raceTitle !== '') {
                return raceTitle;
            }

            return raceBetsJS.i18n.print('raceNumberX', { race: raceNumber });
        },

        /**
        * languageToFlag
        *
        * @param language
        * @return string
        */
        languageToFlag: function(language) {
            // requested my moritz
            if (typeof language !== 'string' || language.length !== 2) {
                language = '';
            }

            language = language.toLowerCase();
            var flags = {
                'cs': 'cz',
                'en': 'gb',
                'zh': 'cn',
                'el': 'gr',
                'sv': 'se',
                'ja': 'jp'
            }

            return (flags[language] !== undefined) ? flags[language] : language;
        }
	};
})(raceBetsJS);
