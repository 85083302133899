/**
* Bet Details
*
* @author Robin Ebers <robin.ebers@gmail.com>
* @author Andras Matzon <matzon@racebets.com>
*/

raceBetsJS.application.templates.betDetails = _.template('\
    <%= raceBetsJS.application.templates.betDetails.title({ betDetails: betDetails }) %>\
    <div class="my-bets">\
        <div class="my-bets-details <% if (betDetails.betType === "QNP") { %>my-bets-details-wide<% } %>">\
            <% if (betDetails.betslipType === "STD") { %>\
                <% if (betDetails.featured === true && betDetails.isSpecialBets) { %>\
                    <% /* ANTEPOST SPECIAL BETS */ %>\
                    <%= raceBetsJS.application.templates.betDetails.antePostSpecial({ betDetails: betDetails }) %>\
                <% } else if (betDetails.isSpecialBets && betDetails.specialBetType === "H2H") { %>\
                    <% /* H2H SPECIALS */ %>\
                    <%= raceBetsJS.application.templates.betDetails.head2head({ betDetails: betDetails }) %>\
                <% } else if (betDetails.isSpecialBets) { %>\
                    <% /* SPECIAL BETS */ %>\
                    <%= raceBetsJS.application.templates.betDetails.special({ betDetails: betDetails }) %>\
                <% } else if (betDetails.featured === true) { %>\
                    <% /* ANTE-POST BETS */ %>\
                    <%= raceBetsJS.application.templates.betDetails.antePost({ betDetails: betDetails }) %>\
                <% } else { %>\
                    <% /* SINGLES */ %>\
                    <% if ($.inArray(betDetails.betType, ["EXA", "QNL", "SWG", "TRI", "SFC", "TRO" ,"TOF", "QRP", "QNP", "SF4", "TRC", "M4", "M5", "M6", "M7", "PK5"]) > -1) { %>\
                        <% /* MULTI-COLUMN BET TYPE */ %>\
                        <%= raceBetsJS.application.templates.betDetails.combinable({ betDetails: betDetails }) %>\
                    <% } else { %>\
                        <% /* SINGLE-COLUMN BET TYPE */ %>\
                        <%= raceBetsJS.application.templates.betDetails.normal({ betDetails: betDetails }) %>\
                    <% } %>\
                <% } %>\
            <% } else if (betDetails.betslipType === "ACC") { %>\
                <% if (raceBetsJS.format.isPickBetType(betDetails.betType)) { %>\
                    <% /* PICK BETS */ %>\
                    <%= raceBetsJS.application.templates.betDetails.pick({ betDetails: betDetails }) %>\
                <% } else if (betDetails.featured === true) { %>\
                    <% /* ANTE-POST MULTIPLES */ %>\
                    <%= raceBetsJS.application.templates.betDetails.antePost({ betDetails: betDetails }) %>\
                <% } else if (betDetails.specialBetType === "H2H") { %>\
                    <% /* H2H MULTIPLES */ %>\
                    <%= raceBetsJS.application.templates.betDetails.head2head({ betDetails: betDetails }) %>\
                <% } else { %>\
                    <% /* STANDARD MULTIPLES */ %>\
                    <%= raceBetsJS.application.templates.betDetails.normal({ betDetails: betDetails }) %>\
                <% } %>\
            <% } else { %>\
                <% /* UNKNOWN BETTYPE - POSSIBLE BUG IN JSON/JS? */ %>\
                <% /* WE SHOULD DO SOME NOTIFICATION HERE I GUESS? */ %>\
            <% } %>\
        </div>\
        <div id="my-bets-transactions" class="my-bets-transactions">\
            <%= raceBetsJS.application.templates.betDetails.transactions({ relations: relations }) %>\
        </div>\
    </div>\
    <%= raceBetsJS.application.templates.betDetails.options() %>\
    <%= raceBetsJS.application.templates.betDetails.summary({ betDetails: betDetails }) %>\
    <%= raceBetsJS.application.templates.betDetails.footer({ betDetails: betDetails }) %>\
');

raceBetsJS.application.templates.betDetails.title = _.template('\
    <div class="my-bets-status box-status-right">\
        <div class="box-status-bg">\
            <div class="box-status-content">\
                <span class="c-tag isBetdetail isBetdetail<% if (betDetails.betStatus === "WIN") { %>Win<% } else if (betDetails.betStatus === "OPN") { %>Open<% } else { %>Lost<% } %> bet-status-<%= betDetails.betStatus.toLowerCase() %>"><%=  raceBetsJS.i18n.data["betStatus" + betDetails.betStatus] %></span>\
            </div>\
        </div>\
    </div>\
');

raceBetsJS.application.templates.betDetails.runnerList = _.template('\
    <% if (marks.length === 0) { %>\
        <span class="icon table-empty"></span>\
    <% } else { %>\
        <ol class="rows">\
            <% $.each(marks, function() { %>\
                <li title="<%= this.name %>">\
                    <span class="name"><%= this.programNumber %> <%= this.name %></span>\
                    <% if (typeof showOdds === "undefined" || (typeof showOdds !== "undefined" && showOdds !== false)) { %>\
                        <% if (this.odds.useSp === true) { %>\
                            <span class="onright">SP</span>\
                        <% } else if (this.odds.useSp === false && (this.odds.fixedOddsWin || this.odds.fixedOddsPlace)) { %>\
                            <span class="onright">\
                                <% if (this.odds.fixedOddsWin) { %>\
                                    <%= raceBetsJS.format.odds(this.odds.fixedOddsWin) %>\
                                <% } %>\
                                <% if (this.odds.fixedOddsPlace && !obj.rule4) { %>\
                                    <% if (this.odds.fixedOddsWin) { %>\
                                        |\
                                    <% } %>\
                                    <%= raceBetsJS.format.odds(this.odds.fixedOddsPlace) %>\
                                <% } %>\
                            </span>\
                       <% } %>\
                   <% } %>\
                </li>\
            <% }); %>\
        </ol>\
    <% } %>\
');

raceBetsJS.application.templates.betDetails.normal = _.template('\
    <table class="bet-details-normal">\
        <thead>\
            <tr>\
                <th><%= raceBetsJS.i18n.data.labelDate %></th>\
                <th><%= raceBetsJS.i18n.data.labelTrack %></th>\
                <th><%= raceBetsJS.i18n.data.labelRace %></th>\
                <th><%= raceBetsJS.i18n.data.labelSelection %></th>\
            </tr>\
        </thead>\
        <tbody>\
            <% if (betDetails.legs && betDetails.legs.length > 0) { %>\
                <% $.each(betDetails.legs, function(i) { %>\
                    <tr class="<%= ((i % 2 === 0) ? "odd" : "") %>">\
                        <td class="date"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, this.postTime) %></td>\
                        <td class="track">\
                            <% if (this.offline === false && this.country !== "") { %>\
                                <a href="<%= raceBetsJS.application.globals.urlPrefix %>/event/details/id/<%= this.idEvent %>/" class="ajaxify">\
                            <% } %>\
                            <%= this.title %>\
                            <% if (this.offline === false && this.country !== "") { %>\
                                </a>\
                            <% } %>\
                        </td>\
                        <td class="race <% if (this.country === "") { %>isVirtual<% } %>">\
                            <% if (this.offline === false) { %>\
                                <a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= this.idRace %>/" class="race-tag-<%= raceBetsJS.format.statusToColour(this.subStatus) %> ajaxify">\
                                    <span class="race-number status-<%= this.subStatus %>"><%= this.raceNumber %></span>\
                                    <span class="race-time"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, this.postTime) %></span>\
                                </a>\
                            <% } else { %>\
                                <span class="race-tag-<%= raceBetsJS.format.statusToColour(this.subStatus) %>">\
                                    <span class="race-nr"><%= this.raceNumber %></span><span class="race-time"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, this.postTime) %></span>\
                                </span>\
                            <% } %>\
                            <% if (this.bestOddsGuaranteed || this.rule4) { %>\
                                <span class="icon info tipped" data-rule4="<%= this.rule4 %>" data-bog="<%= this.bestOddsGuaranteed %>"></span>\
                                <%= raceBetsJS.application.templates.betDetails.tooltip({ "bestOddsGuaranteed": this.bestOddsGuaranteed, "rule4": this.rule4 }) %>\
                            <% } %>\
                        </td>\
                        <td class="results">\
                            <%= raceBetsJS.application.templates.betDetails.runnerList({ marks: this.marks, rule4: this.rule4 }) %>\
                        </td>\
                    </tr>\
                <% }); %>\
            <% } else { %>\
                <tr class="odd">\
                    <td class="date"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, betDetails.postTime) %></td>\
                    <td class="track">\
                        <% if (betDetails.offline === false && betDetails.country !== "") { %>\
                            <a href="<%= raceBetsJS.application.globals.urlPrefix %>/event/details/id/<%= betDetails.idEvent %>/" class="ajaxify">\
                        <% } %>\
                        <%= betDetails.title %>\
                        <% if (betDetails.offline === false && betDetails.country !== "") { %>\
                            </a>\
                        <% } %>\
                    </td>\
                    <td class="race <% if (betDetails.country === "") { %>isVirtual<% } %>">\
                        <% if (betDetails.offline === false) { %>\
                            <a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= betDetails.idRace %>/" class="race-tag-<%= raceBetsJS.format.statusToColour(betDetails.raceStatus) %> ajaxify">\
                                <span class="race-number status-<%= betDetails.raceStatus %>"><%= betDetails.raceNumber %></span>\
                                <span class="race-time"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, betDetails.postTime) %></span>\
                            </a>\
                        <% } else { %>\
                                <span class="race-tag-<%= raceBetsJS.format.statusToColour(betDetails.raceStatus) %>">\
                                    <span class="race-number status-<%= betDetails.raceStatus %>"><%= betDetails.raceNumber %></span>\
                                    <span class="race-time"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, betDetails.postTime) %></span>\
                            </span>\
                        <% } %>\
                        <% if (betDetails.bestOddsGuaranteed || betDetails.rule4) { %>\
                            <span class="icon info tipped" data-rule4="<%= betDetails.rule4 %>" data-bog="<%= betDetails.bestOddsGuaranteed %>"></span>\
                            <%= raceBetsJS.application.templates.betDetails.tooltip({ "bestOddsGuaranteed": betDetails.bestOddsGuaranteed, "rule4": betDetails.rule4 }) %>\
                        <% } %>\
                    </td>\
                    <td class="results">\
                        <% var activeCol = (betDetails.betType === "TRT" ? 2 : (betDetails.betType === "ITA" ? 1 : 0)); %>\
                        <%= raceBetsJS.application.templates.betDetails.runnerList({ marks: betDetails.marks[activeCol], rule4: betDetails.rule4 }) %>\
                    </td>\
                </tr>\
            <% } %>\
        </tbody>\
    </table>\
');

raceBetsJS.application.templates.betDetails.pick = _.template('\
    <table class="table-zebra bet-details-pick">\
        <thead>\
            <tr>\
                <th><%= raceBetsJS.i18n.data.labelDate %></th>\
                <th><%= raceBetsJS.i18n.data.labelTrack %></th>\
                <th><%= raceBetsJS.i18n.data.labelRace %></th>\
                <th><%= raceBetsJS.i18n.data.labelSelection %></th>\
            </tr>\
        </thead>\
        <tbody>\
            <% $.each(betDetails.legs, function(i) { %>\
                <tr class="<%= ((i % 2 === 0) ? "odd" : "") %>">\
                    <% if (i === 0) { %>\
                        <td class="date"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, this.postTime) %></td>\
                        <td class="track">\
                            <% if (this.offline === false && this.country !== "") { %>\
                                <a href="<%= raceBetsJS.application.globals.urlPrefix %>/event/details/id/<%= this.idEvent %>/" class="ajaxify">\
                            <% } %>\
                            <%= this.title %>\
                            <% if (this.offline === false && this.country !== "") { %>\
                                </a>\
                            <% } %>\
                        </td>\
                    <% } else { %>\
                        <td class="date empty"></td>\
                        <td class="track empty"></td>\
                    <% } %>\
                    <td class="race <% if (this.country === "") { %>isVirtual<% } %>">\
                        <% if (this.offline === false) { %>\
                            <a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= this.idRace %>/" class="race-tag-<%= raceBetsJS.format.statusToColour(this.subStatus) %> ajaxify">\
                                <span class="race-number status-<%= this.subStatus %>"><%= this.raceNumber %></span>\
                                <span class="race-time"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, this.postTime) %></span>\
                            </a>\
                        <% } else { %>\
                            <span class="race-tag-<%= raceBetsJS.format.statusToColour(this.subStatus) %>">\
                                <span class="race-number status-<%= this.subStatus %>"><%= this.raceNumber %></span>\
                                <span class="race-time"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, this.postTime) %></span>\
                            </span>\
                        <% } %>\
                        <% if (this.bestOddsGuaranteed || this.rule4) { %>\
                            <span class="icon info tipped" data-rule4="<%= this.rule4 %>" data-bog="<%= this.bestOddsGuaranteed %>"></span>\
                            <%= raceBetsJS.application.templates.betDetails.tooltip({ "bestOddsGuaranteed": this.bestOddsGuaranteed, "rule4": this.rule4 }) %>\
                        <% } %>\
                    </td>\
                    <td class="results">\
                        <%= raceBetsJS.application.templates.betDetails.runnerList({ marks: this.marks, rule4: this.rule4 }) %>\
                    </td>\
                </tr>\
            <% }); %>\
        </tbody>\
    </table>\
');

raceBetsJS.application.templates.betDetails.combinable = _.template('\
    <table class="bet-details-normal bet-details-combinable">\
        <thead>\
            <tr>\
                <th><%= raceBetsJS.i18n.data.labelDate %></th>\
                <th><%= raceBetsJS.i18n.data.labelTrack %></th>\
                <th><%= raceBetsJS.i18n.data.labelRace %></th>\
                <th><%= raceBetsJS.i18n.data.labelResults %></th>\
            </tr>\
        </thead>\
        <tbody>\
            <tr class="odd">\
                <td class="date"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, betDetails.postTime) %></td>\
                <td class="track">\
                    <% if (betDetails.offline === false && betDetails.country !== "") { %>\
                        <a href="<%= raceBetsJS.application.globals.urlPrefix %>/event/details/id/<%= betDetails.idEvent %>/" class="ajaxify">\
                    <% } %>\
                    <%= betDetails.title %></td>\
                    <% if (betDetails.offline === false && betDetails.country !== "") { %>\
                        </a>\
                    <% } %>\
                <td class="race <% if (betDetails.country === "") { %>isVirtual<% } %>">\
                    <% if (betDetails.offline === false) { %>\
                        <a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= betDetails.idRace %>/" class="race-tag-<%= raceBetsJS.format.statusToColour(betDetails.raceStatus) %> ajaxify">\
                            <span class="race-number status-<%= betDetails.raceStatus %>"><%= betDetails.raceNumber %></span>\
                            <span class="race-time"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, betDetails.postTime) %></span>\
                        </a>\
                    <% } else { %>\
                        <span class="race-tag-<%= raceBetsJS.format.statusToColour(betDetails.raceStatus) %>">\
                            <span class="race-number status-<%= betDetails.raceStatus %>"><%= betDetails.raceNumber %></span>\
                            <span class="race-time"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, betDetails.postTime) %></span>\
                        </span>\
                    <% } %>\
                </td>\
                <td class="results">\
                    <% if (_.size(betDetails.finalPositions) > 0) { %>\
                        <% $.each(betDetails.finalPositions, function(i, positions) { %>\
                            <% $.each(positions, function(j, position) { %>\
                                <span class="nr <% if (j > 0) { %>dead-heat<% } %>"><%= position %></span>\
                            <% })%>\
                        <% })%>\
                    <% } else { %>\
                        <%= raceBetsJS.i18n.data.labelRaceOpen %>\
                    <% } %>\
                </td>\
            </tr>\
        </tbody>\
    </table>\
    <table class="bet-details-normal layout-fixed bet-details-combinable-details">\
        <thead>\
            <tr>\
                <th class="alc"><%= raceBetsJS.i18n.data.betslipCol1 %></th>\
                <th class="alc"><%= raceBetsJS.i18n.data.betslipCol2 %></th>\
                <% if (["TRI", "SFC", "QRP", "QNP", "SF4"].indexOf(betDetails.betType) > -1) {  %>\
                    <th class="alc"><%= raceBetsJS.i18n.data.betslipCol3 %></th>\
                <% }  %>\
                <% if (["SFC", "QRP", "QNP", "SF4"].indexOf(betDetails.betType) > -1) {  %>\
                    <th class="alc"><%= raceBetsJS.i18n.data.betslipCol4 %></th>\
                <% }  %>\
                <% if (["QNP"].indexOf(betDetails.betType) > -1) {  %>\
                    <th class="alc"><%= raceBetsJS.i18n.data.betslipCol5 %></th>\
                <% }  %>\
                <th class="alc"><%= raceBetsJS.i18n.data.betslipColC %></th>\
            </tr>\
        </thead>\
        <tbody>\
            <tr class="odd">\
                <td<% if (betDetails.marks[0].length === 0) { %> class="empty alc"<% } %>>\
                    <%= raceBetsJS.application.templates.betDetails.runnerList({ marks: betDetails.marks[0], showOdds: false }) %>\
                </td>\
                <td<% if (betDetails.marks[1].length === 0) { %> class="empty alc"<% } %>>\
                    <%= raceBetsJS.application.templates.betDetails.runnerList({ marks: betDetails.marks[1], showOdds: false }) %>\
                </td>\
                <% if (["TRI", "SFC", "QRP", "QNP", "SF4"].indexOf(betDetails.betType) > -1) {  %>\
                    <td<% if (betDetails.marks[2].length === 0) { %> class="empty alc"<% } %>>\
                        <%= raceBetsJS.application.templates.betDetails.runnerList({ marks: betDetails.marks[2], showOdds: false }) %>\
                    </td>\
                <% }  %>\
                <% if (["SFC", "QRP", "QNP", "SF4"].indexOf(betDetails.betType) > -1) {  %>\
                    <td<% if (betDetails.marks[3].length === 0) { %> class="empty alc"<% } %>>\
                        <%= raceBetsJS.application.templates.betDetails.runnerList({ marks: betDetails.marks[3], showOdds: false }) %>\
                    </td>\
                <% }  %>\
                <% if (["QNP"].indexOf(betDetails.betType) > -1) {  %>\
                    <td<% if (betDetails.marks[4].length === 0) { %> class="empty alc"<% } %>>\
                        <%= raceBetsJS.application.templates.betDetails.runnerList({ marks: betDetails.marks[4], showOdds: false }) %>\
                    </td>\
                <% }  %>\
                <% if (["QNP"].indexOf(betDetails.betType) === -1) {  %>\
                    <td<% if (betDetails.marks[4].length === 0) { %> class="empty alc"<% } %>>\
                        <%= raceBetsJS.application.templates.betDetails.runnerList({ marks: betDetails.marks[4], showOdds: false }) %>\
                    </td>\
                <% } else { %>\
                    <td<% if (betDetails.marks[5].length === 0) { %> class="empty alc"<% } %>>\
                        <%= raceBetsJS.application.templates.betDetails.runnerList({ marks: betDetails.marks[5], showOdds: false }) %>\
                    </td>\
                <% }  %>\
            </tr>\
        </tbody>\
    </table>\
');

raceBetsJS.application.templates.betDetails.antePost = _.template('\
    <table class="bet-details-normal">\
        <thead>\
            <tr>\
                <th><%= raceBetsJS.i18n.data.labelDate %></th>\
                <th><%= raceBetsJS.i18n.data.labelMarket %></th>\
                <th><%= raceBetsJS.i18n.data.labelSelection %></th>\
            </tr>\
        </thead>\
        <tbody>\
            <% if (betDetails.legs && betDetails.legs.length > 0) { %>\
                <% $.each(betDetails.legs, function(i) { %>\
                    <tr class="<%= ((i % 2 === 0) ? "odd" : "") %>">\
                        <td class="date"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, this.postTime) %></td>\
                        <td class="market">\
                            <% if (this.offline === false) { %>\
                                <a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= this.idRace %>/" class="ajaxify">\
                            <% } %>\
                            <strong><%= this.title %></strong>\
                            <% if (this.offline === false) { %>\
                                </a>\
                            <% } %>\
                            <% if (this.raceTitle) { %>\
                                <br /><%= this.raceTitle %>\
                            <% } %>\
                        </td>\
                        <td class="selection">\
                            <%= raceBetsJS.application.templates.betDetails.runnerList({ marks: this.marks }) %>\
                        </td>\
                    </tr>\
                <% }) %>\
            <% } else { %>\
                <tr class="odd">\
                    <td class="date"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, betDetails.postTime) %></td>\
                    <td class="market">\
                        <% if (betDetails.offline === false) { %>\
                            <a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= betDetails.idRace %>/" class="ajaxify">\
                        <% } %>\
                        <strong><%= betDetails.title %></strong>\
                        <% if (betDetails.offline === false) { %>\
                            </a>\
                        <% } %>\
                        <% if (betDetails.raceTitle) { %>\
                            <br /><%= betDetails.raceTitle %>\
                        <% } %>\
                    </td>\
                    <td class="selection">\
                        <%= raceBetsJS.application.templates.betDetails.runnerList({ marks: betDetails.marks[0] }) %>\
                    </td>\
                </tr>\
            <% } %>\
        </tbody>\
    </table>\
');

raceBetsJS.application.templates.betDetails.antePostSpecial = _.template('\
    <table class="table-zebra bet-details-antepost-special">\
        <thead>\
            <tr>\
                <th><%= raceBetsJS.i18n.data.labelBet %></th>\
                <th><%= raceBetsJS.i18n.data.labelSelection %></th>\
            </tr>\
        </thead>\
        <tbody>\
            <tr class="odd">\
                <td class="bet">\
                    <strong><%= betDetails.title %></strong><br/><%= betDetails.raceTitle %>\
                    <% if (betDetails.conditions) { %>\
                        <span class="icon info tipped" data-tipped="<%= betDetails.conditions %>"></span>\
                    <% } %>\
                </td>\
                <td class="selection">\
                    <strong><%= betDetails.marks[0][0].name %></strong>\
                    <span class="onright">\
                            <%= raceBetsJS.format.odds(betDetails.marks[0][0].odds.fixedOddsWin) %>\
                    </span>\
                </td>\
            </tr>\
        </tbody>\
    </table>\
');

raceBetsJS.application.templates.betDetails.special = _.template('\
    <table class="table-zebra bet-details-special">\
        <thead>\
            <tr>\
                <th><%= raceBetsJS.i18n.data.labelDate %></th>\
                <th><%= raceBetsJS.i18n.data.labelTrack %></th>\
                <th><%= raceBetsJS.i18n.data.labelBet %></th>\
            </tr>\
        </thead>\
        <tbody>\
            <tr class="odd">\
                <td class="date"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, betDetails.postTime) %></td>\
                <td class="track"><%= betDetails.title %></td>\
                <td class="bet">\
                    <%= betDetails.raceTitle %>\
                    <span class="icon info tipped" data-tipped="<%= betDetails.conditions %>"></span>\
                    <div class="hr hr-h2h-empty"></div>\
                    <span class="name"><%= betDetails.marks[0][0].name %></span>\
                    <span class="onright"><%= raceBetsJS.format.odds(betDetails.marks[0][0].odds.fixedOddsWin) %></span>\
                </td>\
            </tr>\
        </tbody>\
    </table>\
');

raceBetsJS.application.templates.betDetails.head2head = _.template('\
    <table class="bet-details-normal bet-details-head2head">\
        <thead>\
            <tr>\
                <th><%= raceBetsJS.i18n.data.labelDate %></th>\
                <th><%= raceBetsJS.i18n.data.labelTrack %></th>\
                <th><%= raceBetsJS.i18n.data.labelRace %></th>\
                <th><%= raceBetsJS.i18n.data.labelSelection %></th>\
                <% if (betDetails.raceStatus === "FNL") { %><th><%= raceBetsJS.i18n.data.labelPosAbbr %></th><% } %>\
            </tr>\
        </thead>\
        <tbody>\
            <tr class="odd">\
                <td class="date"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, betDetails.postTime) %></td>\
                <td class="track">\
                    <% if (betDetails.offline === false && betDetails.country !== "") { %>\
                        <a href="<%= raceBetsJS.application.globals.urlPrefix %>/event/details/id/<%= betDetails.idEvent %>/" class="ajaxify">\
                    <% } %>\
                    <strong><%= betDetails.title %></strong>\
                    <% if (betDetails.offline === false && betDetails.country !== "") { %>\
                        </a>\
                    <% } %>\
                </td>\
                <td class="race <% if (betDetails.country === "") { %>isVirtual<% } %>">\
                    <% if (betDetails.offline === false) { %>\
                        <a href="<%= raceBetsJS.application.globals.urlPrefix %>/event/details/id/<%= betDetails.idEvent %>/" class="race-tag-<%= raceBetsJS.format.statusToColour(betDetails.raceStatus) %> ajaxify">\
                            <span class="race-number status-<%= betDetails.raceStatus %>"><%= betDetails.raceNumber %></span>\
                            <span class="race-time"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, betDetails.postTime) %></span>\
                        </a>\
                    <% } else { %>\
                        <span class="race-tag-<%= raceBetsJS.format.statusToColour(betDetails.raceStatus) %>">\
                            <span class="race-number status-<%= betDetails.raceStatus %>"><%= betDetails.raceNumber %></span>\
                            <span class="race-time"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, betDetails.postTime) %></span>\
                        </span>\
                    <% } %>\
                </td>\
                <td class="selection">\
                    <span class="nr"><%= betDetails.marks[0][0].programNumber %></span> <span class="name"><%= betDetails.marks[0][0].name %></span> <span class="onright"><%= raceBetsJS.format.odds(betDetails.marks[0][0].odds.fixedOddsWin) %></span>\
                    <div class="hr hr-h2h"><span class="hr-mask"><b>vs</b></span></div>\
                    <span class="nr"><%= betDetails.opponents[0].programNumber %></span> <span class="name"><%= betDetails.opponents[0].name %></span>\
                </td>\
                <% if (betDetails.raceStatus === "FNL") { %>\
                    <td class="alc">\
                        <span <% if (betDetails.marks[0][0].finalPosition < betDetails.opponents[0].finalPosition && betDetails.marks[0][0].finalPosition !== 0) { %>class="h2h-winner"<% } %>><%= betDetails.marks[0][0].finalPosition %></span>\
                        <div class="hr hr-h2h-empty"></div>\
                        <span <% if (betDetails.opponents[0].finalPosition < betDetails.marks[0][0].finalPosition && betDetails.opponents[0].finalPosition !== 0) { %>class="h2h-winner"<% } %>><%= betDetails.opponents[0].finalPosition %></span>\
                    </td>\
                <% } %>\
            </tr>\
        </tbody>\
    </table>\
');

raceBetsJS.application.templates.betDetails.transactions = _.template('\
    <table class="table-zebra bet-details-transactions">\
        <thead>\
            <tr>\
                <th><%= raceBetsJS.i18n.data.labelDate %></th>\
                <th><%= raceBetsJS.i18n.data.labelId  %></th>\
                <th><%= raceBetsJS.i18n.data.labelType%></th>\
                <th class="alr"><%= raceBetsJS.i18n.data.labelAmount %></th>\
            </tr>\
        </thead>\
        <tbody>\
            <% $.each(relations,function(index, relation){ %>\
                <tr class="<%= ((index + 1) % 2 === 0) ? "even" : "odd" %>">\
                    <td class="transaction-date">\
                        <%= raceBetsJS.application.assets.timezone.date("d.m.Y", relation.stamp) %>\
                        <%= raceBetsJS.i18n.print("timeShort", {time:raceBetsJS.application.assets.timezone.date("H:i", relation.stamp)}) %>\
                    </td>\
                    <td class="transaction-id">\
                        <%= relation.publicId %>\
                    </td>\
                    <td class="transaction-type"><%= raceBetsJS.i18n.data["operationDetail" + relation.operationDetail] %></td>\
                    <td class="transaction-amount alr operation-<%= relation.operationDetail.toLowerCase() %> <%= (relation.amount < 0) ? "red" : "green" %>">\
                        <%= raceBetsJS.format.money(relation.amount, 2, raceBetsJS.application.user.currency, true, undefined, true) %></span>\
                    </td>\
                </tr>\
            <% }) %>\
        </tbody>\
    </table>\
');

raceBetsJS.application.templates.betDetails.options = _.template('\
    <% if (!raceBetsJS.application.globals.isB2B) { %>\
        <div class="my-bets-options">\
            <a href="#my-bets-transactions" class="toggle-transactions"><span class="label"><%= raceBetsJS.i18n.data.labelShowTransactions %></span></a>\
        </div>\
    <% } %>\
');

raceBetsJS.application.templates.betDetails.summary = _.template('\
    <div class="my-bets-summary clearfix">\
        <div class="summary-item">\
            <div class="hr-header"><%= raceBetsJS.i18n.data.labelTotalStake2 %></div>\
            <div class="hr-content"><%= raceBetsJS.format.money((betDetails.totalStakeTote ? betDetails.totalStakeTote : betDetails.totalStake), 2, raceBetsJS.application.user.currency, true) %></div>\
        </div>\
        <% if (raceBetsJS.application.user.currency !== betDetails.currency) { %>\
            <div class="summary-item">\
                <%= raceBetsJS.application.templates.betDetails.tooltipExchangeRate({ betDetails: betDetails }) %>\
            </div>\
        <% } %>\
        <% if (betDetails.bonusStakes > 0) { %>\
            <div class="summary-item">\
                <div class="hr-header"><%= raceBetsJS.i18n.data.labelBonusUsed %></div>\
                <div class="hr-content"><%= raceBetsJS.format.money(betDetails.bonusStakes, 2, raceBetsJS.application.user.currency, true) %></div>\
            </div>\
        <% } %>\
        <% if (betDetails.taxAmount > 0) { %>\
            <div class="summary-item">\
                <div class="hr-header"><%= raceBetsJS.i18n.data.labelTaxes %></div>\
                <div class="hr-content"><%= raceBetsJS.format.money(betDetails.taxAmount, 2, raceBetsJS.application.user.currency, true) %></div>\
            </div>\
        <% } %>\
        <% if (betDetails.maxPayoutAmount > 0) { %>\
            <div class="summary-item">\
                <div class="hr-header">@maxPayoutLabel</div>\
                <div class="hr-content"><%= raceBetsJS.format.money(betDetails.maxPayoutAmount, 2, raceBetsJS.application.user.currency, true) %></div>\
            </div>\
        <% } %>\
        <% if (betDetails.winAmount > 0) { %>\
            <div class="summary-item">\
                <div class="hr-header"><%= raceBetsJS.i18n.data.labelWinnings %></div>\
                <div class="hr-content"><%= raceBetsJS.format.money(betDetails.winAmount, 2, betDetails.currency, true, true) %></div>\
            </div>\
        <% } %>\
        <% if (betDetails.refundAmount > 0) { %>\
            <div class="summary-item">\
                <div class="hr-header"><%= raceBetsJS.i18n.data.labelRefund %></div>\
                <div class="hr-content"><%= raceBetsJS.format.money(betDetails.refundAmount, 2, raceBetsJS.application.user.currency, true) %></div>\
            </div>\
        <% } %>\
        <% if (betDetails.deduction > 5) { %>\
            <div class="summary-item">\
                <div class="hr-header"><%= raceBetsJS.i18n.data.labelDeductionRule4 %>\
                    <span class="light-grey">(<%= raceBetsJS.i18n.data.labelBettingDialogRule4 %>)</span>\
                </div>\
                <div class="hr-content"><span class="hr-mask"><%= betDetails.deduction %>%</div>\
            </div>\
        <% } %>\
    </div>\
');

raceBetsJS.application.templates.betDetails.footer = _.template('\
    <div class="my-bets-footer">\
        <p class="bet-details">\
            <%= raceBetsJS.i18n.print("headBetslipDetails", { betslipId: betDetails.publicId }) %>\
            <%= raceBetsJS.i18n.data.labelStampPlaced %>\
            <%=raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, betDetails.stamp)%>\
            <%=raceBetsJS.i18n.print("timeLong", {time:raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, betDetails.stamp)})%>.\
            <% if (betDetails.distributionChannel === "IPH") { %>\
                <span data-tipped="<%= raceBetsJS.i18n.data.labelChannel %>: <%= raceBetsJS.i18n.data["channel" + (($.inArray(betDetails.distributionChannel, ["IPH", "PHB"]) > -1) ? betDetails.distributionChannel : "Default")] %>" class="icon mobile tipped"></span><% } %>\
            <%= raceBetsJS.i18n.data.labelTAC %></p>\
        <div class="bet-sharing">\
            <a href="#print" class="print"><%= raceBetsJS.i18n.data.labelPrintBetslip %></a>\
        </div>\
    </div>\
');

raceBetsJS.application.templates.betDetails.tooltip = _.template('\
    <div class="my-bets-rules">\
        <% if (bestOddsGuaranteed) { %>\
        <p><span class="notify-tag green has-icon bog"><i class="icon tick"></i><%= raceBetsJS.i18n.data.labelBOG %></span></p>\
        <% } %>\
        <% if (rule4) { %>\
        <p><span class="notify-tag light-grey rule4"><b><%= raceBetsJS.i18n.data.labelRule4Applies %></b></span></p>\
        <% } %>\
    </div>\
');

raceBetsJS.application.templates.betDetails.tooltipExchangeRate = _.template('\
    <table class="bets-exchange-rate-table">\
        <tr>\
            <th><%= raceBetsJS.i18n.data.labelAmount %> <%= betDetails.currency %></th>\
            <td class="alr"><%= raceBetsJS.format.money(betDetails.totalStake, 2, betDetails.currency, true, false) %></td>\
        </tr>\
        <tr>\
            <th><%= raceBetsJS.i18n.data.labelExchangeRate %></th>\
            <td class="alr"><%= raceBetsJS.format.number(betDetails.exchangeRate, 6) %></td>\
        </tr>\
    </table>\
');


/**
========================================================================================================================
========================================================================================================================
ORIGINAL LOGIC
@todo refactor and re-implement
========================================================================================================================
========================================================================================================================

    <table class="table my-bets-details">\
        <tr>\
            <td class="label"><%= raceBetsJS.i18n.data.labelEvent %>:</td>\
            <td class="value">\
                <% if(!_.isUndefined(betDetails.legs)){ %>\
                    <% if (betDetails.system == "") { %>\
                        <%= raceBetsJS.i18n.data.accumulatedBet %>\
                    <% } else if (betDetails.system == "C" || betDetails.system == "F") { %>\
                        <%= raceBetsJS.i18n.data["system" + betDetails.legs.length + betDetails.system] %>\
                    <% } else { %>\
                        <%= raceBetsJS.i18n.data["system" + betDetails.system + "X"] %>\
                    <% } %>\
                <% } else { %>\
                    <a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= betDetails.idRace %>/" class="ajaxify">\
                        <%= betDetails.title %><%= (parseInt(betDetails.antePost) ? "" : ", " + raceBetsJS.i18n.print("abbrRaceNumberX", {"race":betDetails.raceNumber})) %>\
                    </a>\
                <% } %>\
            </td>\
            <td class="label"><%= raceBetsJS.i18n.data.labelEventDate %>:</td>\
            <td class="value"><%=raceBetsJS.application.assets.timezone.date("d.m.Y", betDetails.postTime) %></td>\
        </tr>\
        <tr>\
            <td class="label"><%= raceBetsJS.i18n.data.labelBetType %>:</td>\
            <td class="value"><%= raceBetsJS.format.betTypeName(betDetails.betType, betDetails.category, raceBetsJS.application.user.lang, (betDetails.currency == "USD"), betDetails.country) %> <% if (betDetails.betslipType === "ACC" && betDetails.category !== "TOT") {} else { %><span class="light-grey">(<%= raceBetsJS.i18n.data["betCategory" + betDetails.category] %>)</span><% } %></td>\
            <td class="label"><%= raceBetsJS.i18n.data.labelStampPlaced %>:</td>\
            <td class="value"><%=raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, betDetails.stamp)%> <%=raceBetsJS.i18n.print("timeLong", {time:raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, betDetails.stamp)})%></td>\
        </tr>\
        <tr>\
            <td class="label"><%= raceBetsJS.i18n.data.labelStake %>:</td>\
            <td class="value">\
                <%= raceBetsJS.format.money(betDetails.totalStake, 2, raceBetsJS.application.user.currency, true) %>\
                <% if (betDetails.totalStake != betDetails.unitStake) { %>\
                    <span class="light-grey">(<%= raceBetsJS.format.money(betDetails.unitStake, 2, raceBetsJS.application.user.currency, true) %>)</span>\
                <% } %>\
            </td>\
            <td class="label"><%= raceBetsJS.i18n.data.labelChannel %>:</td>\
            <td class="value"><%= raceBetsJS.i18n.data["channel" + (($.inArray(betDetails.distributionChannel, ["IPH", "PHB"]) > -1) ? betDetails.distributionChannel : "Default")] %></td>\
        </tr>\
        <% if (betDetails.bonusTotalStake) { %>\
            <tr>\
                <td class="label"><%= raceBetsJS.i18n.data.labelBonusUsed %>:</td>\
                <td class="value" colspan="3">\
                    <%= raceBetsJS.format.money(betDetails.bonusTotalStake, 2, raceBetsJS.application.user.currency, true) %>\
                </td>\
            </tr>\
        <% } %>\
        <% if (betDetails.freebet === true) { %>\
            <tr>\
                <td class="label"><%= raceBetsJS.i18n.data.labelBonusUsed %>:</td>\
                <td class="value" colspan="3">\
                    <span class="tag free-bet">\
                        <%= raceBetsJS.i18n.data.labelFreeBet %>\
                    </span>\
                </td>\
            </tr>\
        <% } %>\
        <% if (betDetails.winnings > 0) { %>\
            <tr>\
                <td style="padding-top: 12px" class="label"><%= raceBetsJS.i18n.data.labelWinnings %>:</td>\
                <td style="padding-top: 12px" class="value" colspan="3">\
                    <%= raceBetsJS.format.money(betDetails.winnings, 2, raceBetsJS.application.user.currency, true) %>\
                </td>\
            </tr>\
        <% } %>\
        <% if (betDetails.refund > 0) { %>\
            <tr>\
                <td <% if (betDetails.winnings == 0) { %>style="padding-top: 12px"<% } %> class="label"><%= raceBetsJS.i18n.data.labelRefund %>:</td>\
                <td <% if (betDetails.winnings == 0) { %>style="padding-top: 12px"<% } %> class="value" colspan="3">\
                    <%= raceBetsJS.format.money(betDetails.refund, 2, raceBetsJS.application.user.currency, true) %>\
                </td>\
            </tr>\
        <% } %>\
        <% if(betDetails.category == "FXD" && _.isUndefined(betDetails.legs)) { %>\
            <tr>\
                <td class="label" style="padding-top: 12px"><%= raceBetsJS.i18n.data.labelOdds %>:</td>\
                <td class="value" colspan="3" style="padding-top: 12px">\
                    <% var oddsFormat = raceBetsJS.application.assets.settings.get().dialog.general.oddsFormat; %>\
                    <% oddsFormat = (oddsFormat == "fractional" ? "base1" : oddsFormat) %>\
                    <% if (_.include(["WIN", "WP"], betDetails.betType)) { print(raceBetsJS.format.odds(betDetails.fixedOdds[0])); } %>\
                    <% if (betDetails.placesNum < 1 || !betDetails.placesNum || betDetails.betType == "PLC") { %>\
                        <% if (!betDetails.rule4 && betDetails.betType == "WP") { print("|"); } %>\
                        <% if ((!betDetails.rule4 && _.include(["PLC", "WP"], betDetails.betType)) || (betDetails.betType == "PLC" && betDetails.rule4)) { print(raceBetsJS.format.odds(betDetails.fixedOdds[(betDetails.betType == "PLC" ? 0 : 1)], oddsFormat)); } %>\
                    <% } %>\
                </td>\
            </tr>\
        <% } %>\
        <% if (betDetails.placesNum > 0 && betDetails.betType != "PLC") { %>\
            <tr<% if(betDetails.category != "FXD") { %> class="margin-top"<% } %>>\
                <td class="label"><%= raceBetsJS.i18n.data.labelEachWayTerms %>:</td>\
                <td class="value" colspan="3">\
                    <%= betDetails.placesNum %> <%= raceBetsJS.i18n.data.labelPlaces %> @ 1/<%= betDetails.placeOddsFactor %> <%= raceBetsJS.i18n.data.labelOdds %>\
                </td>\
            </tr>\
        <% } %>\
        <% if (betDetails.deduction > 0) { %>\
            <tr<% if(betDetails.category != "FXD" && (betDetails.placesNum < 1 || betDetails.placesNum === undefined)) { %> class="margin-top"<% } %>>\
                <td class="label"><%= raceBetsJS.i18n.data.labelDeduction %>:</td>\
                <td class="value" colspan="3">\
                    <%= betDetails.deduction %>% <span class="light-grey">(Rule 4<% if (betDetails.deduction <= 5) { %> - <%= raceBetsJS.i18n.data.labelWaived %><% } %>)</span>\
                </td>\
            </tr>\
        <% } %>\
        <% if (betDetails.category == "FXD" && betDetails.betslipType !== "ACC") { %>\
            <tr<% if(betDetails.category != "FXD" && (betDetails.placesNum < 1 || betDetails.placesNum === undefined) && betDetails.deduction < 1) { %> class="margin-top"<% } %>>\
                <td class="label"><%= raceBetsJS.i18n.data.labelRule4Applies %>:</td>\
                <td class="value" colspan="3">\
                    <%= raceBetsJS.i18n.data["button" + (betDetails.rule4 ? "Yes" : "No")] %>\
                </td>\
            </tr>\
        <% } %>\
        <tr class="margin-top">\
            <td class="label"><%= raceBetsJS.i18n.data.labelBetDetails %>:</td>\
            <td class="value" colspan="3">\
                <% if(!_.isUndefined(betDetails.legs)) { %>\
                    <table class="marks">\
                        <% $.each(betDetails.legs, function() { %>\
                            <tr>\
                                <td class="race">\
                                    <% if (betDetails.antePost == 1 && betDetails.betslipType == "ACC") { %>\
                                        <a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= this.idRace %>/" class="ajaxify"><%= this.event %>:</a> &nbsp;\
                                    <% } else { %>\
                                        <a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= this.idRace %>/" class="ajaxify"><%= this.event %> | <%= raceBetsJS.application.assets.timezone.date("d.m",this.postTime) %> | <%= raceBetsJS.i18n.print("abbrRaceNumberX", {"race":this.raceNumber}) %>:</a> &nbsp;\
                                    <% } %>\
                                </td>\
                                <td>\
                                    <% if(!_.isUndefined(this.mark)) { %>\
                                        <%= this.mark.name %>\
                                            <% if(parseInt(antePost)) { %>\
                                        <% } else { %>\
                                            <span class="light-grey">(<%= this.mark.programNumber %>)</span>\
                                        <% } %>\
                                    <% } else if(!_.isUndefined(this.marksV2)){ %>\
                                        <% if (this.specialBetType === "H2H") { %>\
                                            <% $.each(this.marksV2, function() { %>\
                                                <span class="icon-label bet odds"><em><%= this.name %></em>@ <%= raceBetsJS.format.odds(this.fixedOddsWin) %></span>\
                                                <span class="light-grey"><%= raceBetsJS.i18n.data.headToHeadVs %> <%= this.opponents[0] %></span>\
                                            <%}); %>\
                                        <% } else if (betDetails.category === "TOT") { %>\
                                            <% $.each(this.marksV2, $.proxy(function(i, mark) { %><%= mark.programNumber %><%= (i === (this.marksV2.length-1) ? "" : "-") %><% }, this)); %>\
                                        <% } else { %>\
                                            <% $.each(this.marksV2, $.proxy(function(i, mark) { %>\
                                                <% if (betDetails.antePost == 1 && betDetails.betslipType == "ACC") { %>\
                                                    <span class="icon-label bet odds" title="<%= (mark.fixedOddsWin !== undefined && this.rule4 === true ? raceBetsJS.i18n.data.labelRule4Applies : "") %>"><em><%= mark.name %></em>@ <%= (betDetails.category !== "FXD" || mark.useSp === true ? "SP" : raceBetsJS.format.odds(mark.fixedOddsWin)) %></span>\
                                                <% } else { %>\
                                                    <span class="icon-label bet odds" title="<%= (mark.fixedOddsWin !== undefined && this.rule4 === true ? raceBetsJS.i18n.data.labelRule4Applies : "") %>"><em><%= mark.programNumber %></em>@ <%= (betDetails.category !== "FXD" || mark.useSp === true ? "SP" : raceBetsJS.format.odds(mark.fixedOddsWin)) %></span>\
                                                <% } %>\
                                            <%}, this)); %>\
                                        <% } %>\
                                    <% } %>\
                                </td>\
                            </tr>\
                        <% }) %>\
                    </table>\
                <% } else if(betDetails.betslipType == "H2H") { %>\
                    <%= betDetails.mark.name %><br />\
                    <span class="light-grey"><%= raceBetsJS.i18n.data["headToHeadVs"] %> <%= betDetails.opponents[0] %></span>\
                <% } else if(betDetails.betslipType == "SPC") { %>\
                    <%= betDetails.mark %><br />\
                    <span class="light-grey"><%= betDetails.bet %></span>\
                <% } else if(!_.isUndefined(betDetails.mark)) { %>\
                    <%= betDetails.mark.name %> <span class="light-grey">(<%= betDetails.mark.programNumber %>)</span>\
                <% } else if(!_.isUndefined(betDetails.marks)){ %>\
                    <% $.each(betDetails.marks, function(key, value){ %>\
                        <%= raceBetsJS.i18n.data["labelCol" + key] %>: <%= ($(value).size() > 1) ? value.join("-") : value %><br />\
                    <% }) %>\
                <% } %>\
                <% if (betDetails.bestOddsGuaranteed == 1) { %>\
                    <br />\
                    <span class="notify-tag green has-icon">\
                        <i class="icon tick"></i>\
                        <%= raceBetsJS.i18n.data.labelBOG %>\
                    </span>\
                <% } %>\
            </td>\
        </tr>\
    </table>\



    <table class="table my-bets-transactions">\
        <thead>\
            <tr>\
                <td class="first"><%= raceBetsJS.i18n.data.labelDate %></td>\
                <td><%= raceBetsJS.i18n.data.labelId  %></td>\
                <td><%= raceBetsJS.i18n.data.labelType%></td>\
                <td class="last"><%= raceBetsJS.i18n.data.labelAmount %></td>\
            </tr>\
        </thead>\
        <tbody>\
            <% $.each(relations,function(i){ %>\
                <tr class="<%= ((i+1)%2 == 0) ? "even" : "" %>">\
                    <td class="first">\
                        <%=raceBetsJS.application.assets.timezone.date("d.m.Y",this.stamp)%>\
                        <%=raceBetsJS.i18n.print("timeShort", {time:raceBetsJS.application.assets.timezone.date("H:i", this.stamp)})%>\
                    </td>\
                    <td>\
                        <%= this.publicId %>\
                    </td>\
                    <td><%= raceBetsJS.i18n.data["operationDetail" + this.operationDetail] %></td>\
                    <td class="operation-<%= this.operationDetail %> last <%= (this.amount < 0) ? "red" : "green" %>">\
                        <%= raceBetsJS.format.money(this.amount, 2, raceBetsJS.application.user.currency, true, undefined, true) %></span>\
                    </td>\
                </tr>\
            <% }) %>\
        </tbody>\
    </table>\
*/
