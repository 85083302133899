/**
* @description formguide content template
* @author Robin Ebers <robin.ebers@gmail.com>
*/
(function(raceBetsJS) {
    raceBetsJS.application.templates.formguide = {};


    raceBetsJS.application.templates.formguide.overview = _.template('\
        <div class="container">\
            <div class="left">\
                <div class="box box-primary margin-bottom">\
                    <h3 class="header"><%= raceBetsJS.i18n.data.headGeneral %></h3>\
                    <div class="content">\
                       <table>\
                           <% if(data.yearOfBirth > 0){ %>\
                               <tr>\
                                   <td class="label"><%= raceBetsJS.i18n.data.labelBirthyear %></td>\
                                   <td>\
                                        <%= data.yearOfBirth %><em>\
                                        <%= (data.age) ? raceBetsJS.i18n.print("xYearOldAbbr", { age: data.age }) : "" %></em>\
                                   </td>\
                               </tr>\
                           <% } %>\
                           <% if(data.origin != ""){ %>\
                               <tr>\
                                   <td class="label"><%= raceBetsJS.i18n.data.labelOrigin %></td>\
                                   <td><%=  raceBetsJS.i18n.data.countries[data.origin] %></td>\
                               </tr>\
                           <% } %>\
                           <% if(data.gender != ""){ %>\
                           <tr>\
                               <td class="label"><%= raceBetsJS.i18n.data.labelGender %></td>\
                               <% var dd = (raceBetsJS.i18n.data["gender" + data.gender] || ""); %>\
                               <td><%= dd.charAt(0).toUpperCase() + dd.slice(1) %></td>\
                           </tr>\
                           <% } %>\
                           <% if(data.colour != ""){ %>\
                           <tr>\
                               <td class="label"><%= raceBetsJS.i18n.data.labelHorseColour %></td>\
                               <% var dd = (raceBetsJS.i18n.data["colour"+data.colour] || ""); %>\
                               <td><%= dd.charAt(0).toUpperCase() + dd.slice(1) %></td>\
                           </tr>\
                           <% } %>\
                           <% if(data.sire != ""){ %>\
                           <tr>\
                               <td class="label"><%= raceBetsJS.i18n.data.labelSire %></td>\
                               <td><%= data.sire %></td>\
                           </tr>\
                           <% } %>\
                           <% if(data.dam != ""){ %>\
                           <tr>\
                               <td class="label"><%= raceBetsJS.i18n.data.labelDam %></td>\
                               <td><%= data.dam %></td>\
                           </tr>\
                           <% } %>\
                           <% if(data.damSire != ""){ %>\
                           <tr>\
                               <td class="label"><%= raceBetsJS.i18n.data.labelDamSire %></td>\
                               <td><%= data.damSire %></td>\
                           </tr>\
                           <% } %>\
                       </table>\
                    </div>\
                </div>\
                <% if(!$.isEmptyObject(data.lastPerformance)) { %>\
                <div class="box box-primary margin-bottom">\
                    <h3 class="header"><%= raceBetsJS.i18n.data.headLastPerformance %></h3>\
                    <div class="content">\
                        <table>\
                            <% if(!$.isEmptyObject(data.lastPerformance)) { %>\
                                <% $.each(data.lastPerformance,function(i,e){ %>\
                                <tr class="<%= (i%2) ? "" : "odd" %>">\
                                    <td><%= e.finalPosition %>/<em><%= e.numRunners %></em></td>\
                                    <td><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.formatDateLong, e.date) %></td>\
                                    <td style="max-width: 100px">\
                                        <span class="c-flag isCountry<%= e.country %>"></span>\
                                        <%= e.title %>\
                                    </td>\
                                </tr>\
                                <% }); %>\
                            <% } %>\
                        </table>\
                    </div>\
                </div>\
                <% } %>\
                <% if(!$.isEmptyObject(data.ratings)) { %>\
                <div class="box box-primary">\
                    <h3 class="header"><%= raceBetsJS.i18n.data.labelRatings %></h3>\
                    <div class="content">\
                        <table>\
                            <% if(!$.isEmptyObject(data.ratings)) { %>\
                                <% $.each(data.ratings,function(i,e){ %>\
                                <tr class="<%= (i%2) ? "odd" : "" %>">\
                                    <td style="width: 75px">\
                                        <span class="c-flag isCountry<%= e.country %>"></span>\
                                        <%= raceBetsJS.i18n.data["ratingType" + e.ratingType] %>\
                                    </td>\
                                    <td align="right" style="width: 30px">\
                                        <%= e.rating %>\
                                    </td>\
                                    <td>\
                                        <%= e.type %>\
                                    </td>\
                                </tr>\
                                <% }); %>\
                            <% } %>\
                        </table>\
                    </div>\
                </div>\
                <% } %>\
                <div class="clearfix"></div>\
            </div>\
            <div class="right">\
                <div class="box box-primary margin-bottom other">\
                    <h3 class="header"><%= raceBetsJS.i18n.data.headOther %></h3>\
                    <div class="content<%= data.silkId !== "slw-formguide" ? " has-silk" : "" %><%= data.photo ? " has-photo" : "" %>">\
                        <table>\
                           <tr class="odd">\
                               <td class="label"><%= raceBetsJS.i18n.data.labelTrainer %></td>\
                               <td><%= data.trainer.name %></td>\
                           </tr>\
                           <tr>\
                               <td class="label"><%= raceBetsJS.i18n.data.labelRaceOwner %></td>\
                               <td><%= data.owner.name || "-" %></td>\
                           </tr>\
                           <tr class="odd">\
                               <td class="label"><%= raceBetsJS.i18n.data.labelBreeder %></td>\
                               <td><%= data.breeder || "-" %></td>\
                           </tr>\
                        </table>\
                        <% if (data.silkId !== "slw-formguide") { %>\
                            <div class="silk">\
                                <span style="background-image: url(<%= raceBetsJS.application.globals.imageHost + "/cache/img/silks/" + data.silkId + "_w156." + data.silkExtension %>);"></span>\
                            </div>\
                        <% } %>\
                        <% if (data.photo) { %>\
                            <div class="photo">\
                                <span style="background-image: url(<%= raceBetsJS.application.globals.imageHost %>/cache/img/photos/subjects/<%= runnerInfo.idSubject %>_<%= data.photo %>_w230.jpg);"></span>\
                            </div>\
                        <% } %>\
                    </div>\
                </div>\
                <% if(data.hasOwnProperty("comment")) { %>\
                <div class="box box-primary margin-bottom">\
                    <h3 class="header"><%= raceBetsJS.i18n.data.labelComment %></h3>\
                    <div class="content">\
                        <p class="comment-text" ><%= data.shortComment %>&nbsp;\
                        <% if(data.showMore){%>\
                            <a class="more-link" href="#"><%= raceBetsJS.i18n.data.linkMore %></a>\
                        <% } %>\
                        </p>\
                    </div>\
                </div>\
                <% } %>\
                <% if(!$.isEmptyObject(data.raceRecord)) { %>\
                    <div class="box box-primary">\
                        <h3 class="header"><%= raceBetsJS.i18n.data.headRaceRecord %></h3>\
                        <div class="content">\
                            <table>\
                               <colgroup>\
                                <col width="50" />\
                                <col width="100" />\
                                <col width="60" />\
                                <col width="50" />\
                                <col width="50" />\
                                <col width="50" />\
                               </colgroup>\
                               <thead>\
                                  <tr>\
                                    <td><%= raceBetsJS.i18n.data.labelYear %></td>\
                                    <td><%= raceBetsJS.i18n.data.labelRaceType %></td>\
                                    <td class="alc"><%= raceBetsJS.i18n.data.labelStarts %></td>\
                                    <td class="alc"><%= raceBetsJS.i18n.data.labelCol1 %></td>\
                                    <td class="alc"><%= raceBetsJS.i18n.data.labelCol2 %></td>\
                                    <td class="alc"><%= raceBetsJS.i18n.data.labelCol3 %></td>\
                                    <td class="alr"><%= raceBetsJS.i18n.data.labelEarnings %></td>\
                                  </tr>\
                               </thead>\
                               <tbody>\
                                    <% if(!$.isEmptyObject(data.raceRecord.annual)) { %>\
                                        <% var counter = 0; %>\
                                        <% $.each(data.raceRecord.annual,function(i,year){ %>\
                                            <% if(!$.isEmptyObject(year.results)) { %>\
                                                <% $.each(year.results,function(k,type){ %>\
                                                   <tr class="<%= (counter%2) ? "" : "odd" %>">\
                                                        <td><%= year.year %></td>\
                                                        <td><%= raceBetsJS.i18n.data["trackSurface"+k] %></td>\
                                                        <td class="alc"><%= type.hasOwnProperty("starts") ? type.starts : "-" %></td>\
                                                        <td class="alc"><%= type.hasOwnProperty("1") ? type["1"] : "-" %></td>\
                                                        <td class="alc"><%= type.hasOwnProperty("2") ? type["2"] : "-" %></td>\
                                                        <td class="alc"><%= type.hasOwnProperty("3") ? type["3"] : "-" %></td>\
                                                        <td class="alr">\
                                                            <%= type.hasOwnProperty("earnings") ? raceBetsJS.format.money(type.earnings, 0, "EUR", true, true) : "-" %>\
                                                        </td>\
                                                   </tr>\
                                                   <% counter++; %>\
                                                <% }); %>\
                                            <% } %>\
                                       <% }); %>\
                                    <% } %>\
                                    <% if( (!$.isEmptyObject(data.raceRecord.total)) && (!$.isEmptyObject(data.raceRecord.total.results)) ) { %>\
                                       <tr class="summary">\
                                            <td><%= raceBetsJS.i18n.data.labelLifeTime %></td>\
                                            <td><%= raceBetsJS.i18n.data.labelRulesRaces %></td>\
                                            <td class="alc"><%= data.raceRecord.total.hasOwnProperty("starts") ? data.raceRecord.total.starts : "-" %></td>\
                                            <td class="alc"><%= data.raceRecord.total.results.hasOwnProperty("1") ? data.raceRecord.total.results["1"] : "-" %></td>\
                                            <td class="alc"><%= data.raceRecord.total.results.hasOwnProperty("2") ? data.raceRecord.total.results["2"] : "-" %></td>\
                                            <td class="alc"><%= data.raceRecord.total.results.hasOwnProperty("3") ? data.raceRecord.total.results["3"] : "-" %></td>\
                                            <td class="alr">\
                                                <% if (data.startedInFrance === true) { %>\
                                                    n/a\
                                                <% } else { %>\
                                                    <%= data.raceRecord.total.hasOwnProperty("earnings") ? raceBetsJS.format.money(data.raceRecord.total.earnings, 0, "EUR", true, true) : "-" %>\
                                                <% } %>\
                                            </td>\
                                       </tr>\
                                   <% } %>\
                               </tbody>\
                            </table>\
                        </div>\
                    </div>\
                    <div class="clearfix"></div>\
                <% }%>\
            </div>\
        </div>\
    ');

    raceBetsJS.application.templates.formguide.form = _.template('\
        <% if($.isEmptyObject(data)) { %>\
            <div id="content">\
                <div class="empty-form-container">\
                    <%= raceBetsJS.application.templates.formguide.duels.formEmpty() %>\
                </div>\
            </div>\
        <% }else {%>\
        <div class="container">\
            <div class="scrollable-inside">\
                <div id="form-left-col" class="box box-primary margin-bottom">\
                    <h3 class="header">\
                        <%= raceBetsJS.i18n.data.labelForm %>\
                        <span class="buttons">\
                            <span class="close" ></span>\
                        </span>\
                    </h3>\
                    <div id="form-table-container" class="content">\
                        <table>\
                            <thead>\
                                <tr>\
                                    <td class="dateCol" ><%= raceBetsJS.i18n.data.labelDate %></td>\
                                    <td class="courseCol"><%= raceBetsJS.i18n.data.courseLabel %></td>\
                                    <td align="left" class="race-outcome"><%= raceBetsJS.i18n.data.raceOutcome %> <span class="info-icon"></span></td>\
                                    <td class="slim"><%= raceBetsJS.i18n.data.labelAbbrCategory %></td>\
                                    <td class="slim"></td>\
                                    <td align="left" class="slim"><%= raceBetsJS.i18n.data.labelDistance %></td>\
                                    <td class="slim"></td>\
                                    <td align="left" class="slim"><%= raceBetsJS.i18n.data.labelPurse %></td>\
                                    <td><%= (runnerInfo.subjectRaceType == "T") ? raceBetsJS.i18n.data.labelDriver : raceBetsJS.i18n.data.labelJockey %></td>\
                                    <% if (flags.ratingsFlag === true) { %>\
                                        <td align="center" class="slim"><%= raceBetsJS.i18n.data.labelRating %></td>\
                                    <% } %>\
                                    <td align="right" class="tote-odds"><%= raceBetsJS.i18n.data.labelToteOdds %></td>\
                                </tr>\
                            </thead>\
                            <tbody>\
                                <% $.each(data,function(i,e){ %>\
                                <tr class="<%= (i%2) ? "" : "odd" %>" data-racetype="<%= e.raceType %>" data-idrace="<%= e.idRace %>">\
                                    <td class="dateCol"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.formatDateLong, e.date) %></td>\
                                    <td class="courseCol"><%= e.trackName %></td>\
                                    <td align="left">\
                                        <b class="<%= (parseInt(e.finalPosition)==1) ? "orange" : "" %>" >\
                                        <%= (e.hasOwnProperty("finalPosition")) ? e.finalPosition : (e.maxResult) ? (parseInt(e.maxResult)+1)+"+" : "-" %>\
                                        </b>/<%= e.numRunners %>&nbsp;\
                                        <% var distance = ((e.hasOwnProperty("horseDistance") && e.horseDistance > 0) ? e.horseDistance + " " : "") %>\
                                        <%= (parseInt(e.finalPosition) == 1 && (e.hasOwnProperty("horseDistance") && e.horseDistance > 0 || !e.hasOwnProperty("horseDistance"))) ? "(" + distance + e.subsequentName + ")" : ((e.opponentName) ? "(" + distance + e.opponentName +")" : "") %>\
                                    </td>\
                                    <td class="slim">\
                                        <% if(e.raceType == "D") { %>\
                                            <%= e.category %>\
                                        <% } else { %>\
                                            <%= raceBetsJS.i18n.data["raceCategoryAbbr" + e.category] %>\
                                        <% } %>\
                                    </td>\
                                    <td class="slim">\
                                        <% if (flags.categoryLetterFlag === true && e.country == "FR" && e.categoryLetter !== "") { %>\
                                            <%= e.categoryLetter %>\
                                        <% } %>\
                                    </td>\
                                    <td class="slim">\
                                        <%= raceBetsJS.format.distance(e.raceDistance, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) %>\
                                    </td>\
                                    <td align="center" class="slim">\
                                        <span title="<%= raceBetsJS.format.trackConditions({trackGoing: e.trackGoing, trackSurface: e.trackSurface, country: e.country}, false) %>">\
                                            <%= raceBetsJS.format.trackConditions({trackGoing: e.trackGoing, trackSurface: e.trackSurface, country: e.country}, true) %>\
                                        </span>\
                                    </td>\
                                    <td class="slim">\
                                        <%= raceBetsJS.format.getCurrencySymbol(e.purseCurrency) + e.purse %>\
                                    </td>\
                                    <td><%= e.jockey %></td>\
                                    <% if (flags.ratingsFlag === true) { %>\
                                        <td align="center" class="slim">\
                                            <%= ((e.hasOwnProperty("rating")) ? e.rating : "-") %>\
                                        </td>\
                                    <% } %>\
                                    <td align="right"><% if(e.SP < 10) { %>&#x2007;&#x2007;<% } else if(e.SP < 100) { %>&#x2007;<% } %><%= (e.SP == 0) ? "-" : raceBetsJS.format.odds(e.SP) %></td>\
                                </tr>\
                                <% });%>\
                            </tbody>\
                        </table>\
                    </div>\
                </div>\
                <div id="form-right-col"><div id="formCarrousel"></div></div>\
            </div>\
        </div>\
        <% } %>\
    ');


    raceBetsJS.application.templates.formguide.formtooltipLoading = _.template('\
        <div class="formguide-tooltip loading">\
            <%= raceBetsJS.application.templates.loadingLayer({loadingStyle: "" }) %>\
        </div>\
    ');

    raceBetsJS.application.templates.formguide.formRightColBox = _.template('\
            <div class="box box-primary data-box">\
                <div class="loading-cover">\
                    <%= raceBetsJS.application.templates.loadingLayer({loadingStyle: "" }) %>\
                </div>\
                <h3 class="header">\
                <span class="c-flag isCountry<%= data.raceDetails.country %>"></span>\
                <span class="marketTitle"><%= data.raceDetails.title %></span>\
                <span class="post-time">\
                    <%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, data.raceDetails.raceTime) %>\
                </span>\
                <span class="buttons">\
                    <% if(!$.isEmptyObject(data.raceDetails.media)){ %>\
                    <span class="formtovideo" data-idrace="<%= data.raceDetails.idRace %>"\
                        data-country="<%= data.raceDetails.country %>"\
                        data-archive="<%= data.raceDetails.media.archiveLink %>"\
                        data-idchannel="<%= data.raceDetails.media.idChannel %>"\
                        data-provider="<%= data.raceDetails.media.provider %>"\
                        data-numrunners="<%= data.runners.length %>"\
                        data-streamtype="<%= data.raceDetails.media.streamType %>" >\
                    </span>\
                    <% } %>\
                    <a href="race/details/id/<%= data.raceDetails.idRace %>" class="toracecard" title="<%= raceBetsJS.i18n.data.labelOpenRaceCard %>"></a>\
                </span>\
                <div class="sub-header">\
                    <%= raceBetsJS.i18n.getRaceTitle(data.raceDetails.raceTitle, data.raceDetails.raceNumber) %>\
                    <% if(data.raceDetails.category != "") { %>\
                        <% if(data.raceDetails.raceType == "D") { %>\
                            <%= "- " + data.raceDetails.category %>\
                        <% } else { %>\
                            <%= "- " + raceBetsJS.i18n.data["raceCategory" + data.raceDetails.category] %>\
                        <% } %>\
                    <% } %>\
                    <% if (data.raceDetails.categoryLetter !== "") { %>\
                        <span class="category-letter">\
                            <% if(data.raceDetails.country == "FR") { %>\
                                (<%= raceBetsJS.i18n.print("raceCategoryLetterFrance", {letter: data.raceDetails.categoryLetter}) %>)\
                            <% } else if($.inArray(data.raceDetails.country, ["GB", "IE", "AE"]) > -1) { %>\
                                (Class <%= data.raceDetails.categoryLetter %>)\
                            <% } else { %>\
                                (<%= data.raceDetails.categoryLetter %>)\
                            <% } %>\
                        </span>\
                    <% } %>\
                    <% if (data.raceDetails.raceTitle != "") { %>\
                        | <%= raceBetsJS.i18n.print("raceNumberX", {"race": data.raceDetails.raceNumber}) %>\
                    <% } %>\
                    | <%= raceBetsJS.i18n.print("timeShort", {time: raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, data.raceDetails.raceTime)}) %>\
                    | <%= raceBetsJS.format.distance(data.raceDetails.distance, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) %>\
                    | <span class="purse" data-tip="<%= data.raceDetails.purseDetails %>">\
                        <%= raceBetsJS.format.number(data.raceDetails.purse) + " " + data.raceDetails.purseCurrency %>\
                    </span>\
                    <% if (data.raceDetails.trackSurface) { %>\
                        | <%= raceBetsJS.i18n.data["trackSurface" + data.raceDetails.trackSurface] %>\
                        <% if (data.raceDetails.trackGoing) { %>\
                            <% var trackSurface = data.raceDetails.trackSurface.charAt(0).toUpperCase() + data.raceDetails.trackSurface.slice(1).toLowerCase(); %>\
                            | \
                            <% if (data.raceDetails.trackSurface == "TRF" && data.raceDetails.trackGoing == 2.5) { %>\
                                <%= raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.ceil(data.raceDetails.trackGoing)] %> <%= raceBetsJS.i18n.data.connectTo %> <%= raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.floor(data.raceDetails.trackGoing)] %>\
                            <% } else if (data.raceDetails.trackSurface == "TRF" && data.raceDetails.trackGoing == 3.5 && data.raceDetails.country == "IE" && raceBetsJS.application.user.lang == "en") { %>\
                                yielding\
                            <% } else if ((data.raceDetails.trackGoing % 1) > 0) { %>\
                                <%= raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.floor(data.raceDetails.trackGoing)] %> <%= raceBetsJS.i18n.data.connectTo %> <%= raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.ceil(data.raceDetails.trackGoing)] %>\
                            <% } else { %>\
                                <%= raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.floor(data.raceDetails.trackGoing)] %>\
                            <% } %>\
                        <% } %>\
                    <% } %>\
                    <% if(data.raceDetails.conditions != "") { %>\
                        <span class="race-conditions info-icon" data-tip="<%= data.raceDetails.conditions %><% if(data.raceDetails.conditionsDetails != "") { %>&lt;br /&gt;<%= data.raceDetails.conditionsDetails %><% } %>\
                        "></span>\
                    <% } %>\
                    <% if (data.flags.finalDistancesFlag === true) { %>\
                        <br />\
                        <span id="stewardsDecision"><%= raceBetsJS.i18n.data.labelFinalDistances + " " + data.finalDistances %></span>\
                    <% } %>\
                </div>\
                </h3>\
                <div class="tooltip-content">\
                    <div id="form-right-table-container" class="table-container">\
                        <table>\
                            <thead>\
                                <tr>\
                                    <td align="right" class="bolded" ><%= raceBetsJS.i18n.data.labelAbbrPos %></td>\
                                    <td align="left" ><%= raceBetsJS.i18n.data.labelHorse %></td>\
                                    <% if(data.flags.distanceFlag === true){ %>\
                                    <td align="center" class="<% if(data.raceDetails.raceType != "G" && data.raceDetails.raceType != "J"){%>g-distance<%}%>">\
                                        <%= raceBetsJS.i18n.data.labelDistance %><% if(data.raceDetails.raceType == "G" || data.raceDetails.raceType == "J"){%>&nbsp;<span class="info-icon"></span><%}%>\
                                    </td>\
                                    <% } %>\
                                    <% if(data.flags.postPositionFlag === true){ %>\
                                        <% if(data.raceDetails.raceType != "T") { %>\
                                            <td align="center"><%= raceBetsJS.i18n.data.labelBox %></td>\
                                        <% } %>\
                                    <% } %>\
                                    <td align="center"><%= raceBetsJS.i18n.data.labelAge %></td>\
                                    <td align="left"><%= (runnerInfo.subjectRaceType == "T") ? raceBetsJS.i18n.data.labelDriver : raceBetsJS.i18n.data.labelJockey %></td>\
                                    <% if(data.raceDetails.raceType != "T") { %>\
                                        <% if (data.flags.ratingsFlag === true) { %>\
                                            <td align="center"><%= raceBetsJS.i18n.data.labelRating %></td>\
                                        <% } %>\
                                        <td align="center"><%= raceBetsJS.i18n.data.labelWeight %></td>\
                                    <% } %>\
                                    <td align="center"><%= raceBetsJS.i18n.data.labelOdds %></td>\
                                </tr>\
                            </thead>\
                            <tbody>\
                                <% if(!$.isEmptyObject(data.runners)){ %>\
                                    <% $.each(data.runners,function(i, runner){ %>\
                                    <tr align="center" class="<% if(runner.idSubject == data.currentIdSubject){ %>active<% } %>">\
                                        <td align="right" class="bolded">\
                                            <% if (runner.scratched === true) { %>\
                                                -\
                                            <% } else if (parseInt(runner.finalPosition) == 0) { %>\
                                                <span title="<%= raceBetsJS.i18n.data["disqualification" + runner.finalPositionComment] %>" class="tipped">\
                                                    <%= raceBetsJS.i18n.data["disqualificationAbbr" + runner.finalPositionComment] %>\
                                                </span>\
                                            <% } else { %>\
                                                <%= runner.finalPosition %>\
                                            <% } %>\
                                        </td>\
                                        <td align="left">\
                                            <% if (!runner.idSubject || (runner.idSubject == data.currentIdSubject)) { %>\
                                                <span class="runnerName popup" data-popup-width="950" data-popup-height="725" data-popup-scroll="yes" data-trainer="<%= runner.trainer %>" data-owner="<%= runner.owner %>">\
                                                    <%= runner.name %>\
                                                </span>\
                                            <% } else { %>\
                                                <a href="/formguide/overview/id/<%= runner.idSubject %>" class="runnerName popup" data-popup-width="950" data-popup-height="725" data-popup-scroll="yes" data-trainer="<%= runner.trainer %>" data-owner="<%= runner.owner %>">\
                                                    <%= runner.name %>\
                                                </a>\
                                            <% } %>\
                                            </td>\
                                        <% if(data.flags.distanceFlag === true){ %>\
                                        <td align="center">\
                                            <% if(data.raceDetails.raceType == "T") {  %>\
                                                <%= raceBetsJS.format.distance(runner.distance, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) %>\
                                            <% } else { %>\
                                                <%= runner.distance || "-" %>\
                                            <% } %>\
                                        </td>\
                                        <% } %>\
                                        <% if(data.flags.postPositionFlag === true){ %>\
                                            <% if(data.raceDetails.raceType != "T") { %>\
                                                <td align="center"><%= runner.postPosition %></td>\
                                            <% } %>\
                                        <% } %>\
                                        <td align="center"><%= raceBetsJS.i18n.print("xYearOldAbbr", { age: runner.age }) %></td>\
                                        <td align="left"><%= runner.jockey %></td>\
                                        <% if(data.raceDetails.raceType != "T") { %>\
                                            <% if (data.flags.ratingsFlag === true) { %>\
                                                <td align="center">\
                                                    <%= ((runner.hasOwnProperty("rating") && runner.rating > 0) ? runner.rating : "-") %>\
                                                </td>\
                                            <% } %>\
                                            <td align="center"><%= (parseInt(runner.weight) > 0) ?  raceBetsJS.format.weight(runner.weight,  raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) : "-" %></td>\
                                        <% } %>\
                                        <td align="center"><a class="odds-button"><span>\
                                        <% if(runner.scratched === true){ %>\
                                            <%= raceBetsJS.i18n.data.labelScratchedButton %>\
                                        <% } else { %>\
                                            <%= raceBetsJS.format.odds(runner.odds, null, true) %>\
                                        <% } %>\
                                        </span></a></td>\
                                    </tr>\
                                    <% }); %>\
                                <% } %>\
                            </tbody>\
                        </table>\
                    </div>\
                </div>\
            </div>\
    ');

    raceBetsJS.application.templates.formguide.chartDotTooltip = _.template('\
        <div class="chartTooltip market">\
            <div class="sub-header">\
                <div>\
                    <span class="c-flag isCountry<%= data.country %>"></span>\
                    <%= data.trackName + " - " + raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.formatDateLong, data.date) %>\
                </div>\
                <div class="nowrap">\
                    <span class="bold">\
                        <%= raceBetsJS.i18n.getRaceTitle(data.raceTitle, data.raceNumber) %>\
                    </span>\
                    <span>\
                        <% if(data.category != "") { %>\
                            - <% if(data.raceType == "D") { %>\
                                <%= data.category %>\
                            <% } else { %>\
                                <%= raceBetsJS.i18n.data["raceCategoryAbbr" + data.category] %>\
                            <% } %>\
                        <% } %>\
                        <% if (data.categoryLetter !== "") { %>\
                            (<%= data.categoryLetter %>)\
                        <% } %>\
                    </span>\
                </div>\
            </div>\
            <div class="sub-content">\
                <table>\
                    <tr>\
                        <td class="bold"><%= raceBetsJS.i18n.data.raceOutcome %></td>\
                        <td align="right">\
                        <b><%= (data.hasOwnProperty("finalPosition")) ? data.finalPosition : (parseInt(data.maxResult)+1)+"+"  %></b>/<%= data.numRunners %>&nbsp;\
                        <% var distance = ((data.hasOwnProperty("horseDistance")) ? data.horseDistance + " " : "") %>\
                        <%= (parseInt(data.finalPosition)==1) ? "("+distance+data.subsequentName+")" : "("+ ((data.finalPosition===0) ? (data.finalPositionComment) : distance)   + data.opponentName +")" %>\
                        </td>\
                    </tr>\
                    <tr>\
                        <td class="bold"><%= raceBetsJS.i18n.data.labelDistance %></td>\
                        <td align="right">\
                            <%= raceBetsJS.format.distance(data.raceDistance, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) %>\
                        </td>\
                    </tr>\
                    <tr>\
                        <td class="bold"><%= raceBetsJS.i18n.data.labelTrackSurface %></td>\
                        <td align="right">\
                            <%= (raceBetsJS.i18n.data["trackSurface"+data.trackSurface] || "") != "" ? (raceBetsJS.i18n.data["trackSurface"+data.trackSurface]) : "" %>\
                            <% if (data.trackGoing > 0) { %>\
                                (<%= raceBetsJS.format.trackConditions({trackGoing: data.trackGoing, trackSurface: data.trackSurface, country: data.country}, false) %>)\
                            <% } %>\
                        </td>\
                    </tr>\
                    <tr>\
                        <td class="bold"><%= raceBetsJS.i18n.data.labelPurse %></td>\
                        <td align="right">\
                            <%= raceBetsJS.format.getCurrencySymbol(data.purseCurrency) + data.purse %>\
                        </td>\
                    </tr>\
                    <tr>\
                        <td class="bold"><%= raceBetsJS.i18n.data.labelJockey %></td>\
                        <td align="right">\<%= data.jockey %></td>\
                    </tr>\
                </table>\
            </div>\
        </div>\
    ');

    raceBetsJS.application.templates.formguide.chartLeyendItem = _.template('\
        <li><span class="leyend-color-box"></span><span class="leyend-label"><%= data.label %></span></li>\
    ');


    raceBetsJS.application.templates.formguide.chart = _.template('\
        <% if($.isEmptyObject(data)) { %>\
        <div class="empty-form-container">\
            <%= raceBetsJS.application.templates.formguide.duels.formEmpty() %>\
        </div>\
        <% }else {%>\
            <div class="container graph">\
                <div class="sub-header">\
                    <div class="leyend">\
                    <ul id="leyend-list">\
                    </ul>\
                    </div>\
                </div>\
                <div id="graph-container"><canvas id="canvas-graph" ></canvas></div>\
            </div>\
        <% } %>\
    ');

    raceBetsJS.application.templates.formguide.statistics = _.template('\
        <% if($.isEmptyObject(data)) { %>\
        <div class="empty-form-container">\
            <%= raceBetsJS.application.templates.formguide.duels.formEmpty() %>\
        </div>\
        <% }else {%>\
        <div class="container">\
            <div class="container">\
                <div class="left">\
                    <% if(!$.isEmptyObject(data.tracks)){ %>\
                    <div class="box box-primary margin-bottom">\
                        <h3 class="header"><%= raceBetsJS.i18n.data.HeadTrackStatistics %></h3>\
                        <div class="content">\
                            <table>\
                                <colgroup>\
                                    <col width="220" />\
                                    <col width="80" />\
                                    <col width="80" />\
                                </colgroup>\
                                <thead>\
                                    <tr>\
                                        <td><%= raceBetsJS.i18n.data.labelTrack %></td>\
                                        <td class="alc"><%= raceBetsJS.i18n.data.labelStarts %></td>\
                                        <td class="alc"><%= raceBetsJS.i18n.data.labelAvgPlace %></td>\
                                    </tr>\
                                </thead>\
                                <tbody>\
                                    <% $.each(data.tracks,function(i,track){ %>\
                                    <tr class="<%= (i%2) ? "" : "odd" %>" id="row<%= i %>">\
                                        <td><%= track.hasOwnProperty("trackName") ? track.trackName : "-" %></td>\
                                        <td class="alc"><%= track.hasOwnProperty("numRaces") ? track.numRaces : "-" %></td>\
                                        <td class="alc"><%= track.hasOwnProperty("averagePosition") ? track.averagePosition : "-" %></td>\
                                    </tr>\
                                    <% }); %>\
                                </tbody>\
                            </table>\
                        </div>\
                    </div>\
                    <% } %>\
                    <% if(!$.isEmptyObject(data.trackConditions)){ %>\
                    <div class="box box-primary">\
                        <h3 class="header"><%= raceBetsJS.i18n.data.headTrackConditions%></h3>\
                        <div class="content">\
                            <table>\
                                <colgroup>\
                                    <col width="220" />\
                                    <col width="80" />\
                                    <col width="80" />\
                                </colgroup>\
                                <thead>\
                                    <tr>\
                                        <td><%= raceBetsJS.i18n.data.labelTrackCondition %></td>\
                                        <td class="alc"><%= raceBetsJS.i18n.data.labelStarts %></td>\
                                        <td class="alc"><%= raceBetsJS.i18n.data.labelAvgPlace %></td>\
                                    </tr>\
                                </thead>\
                                <tbody>\
                                    <% $.each(data.trackConditions,function(i,track){ %>\
                                    <tr class="<%= (i%2) ? "" : "odd" %>" id="row<%= i %>">\
                                        <td><%= raceBetsJS.format.trackConditions({trackGoing: track.trackGoing,trackSurface: track.trackSurface, country: track.trackCountry}) %>\</td>\
                                        <td class="alc"><%= track.hasOwnProperty("numRaces") ? track.numRaces : "-" %></td>\
                                        <td class="alc"><%= track.hasOwnProperty("averagePosition") ? track.averagePosition : "-" %></td>\
                                    </tr>\
                                    <% }); %>\
                                </tbody>\
                            </table>\
                        </div>\
                    </div>\
                    <% } %>\
                </div>\
                <div class="right">\
                    <% if(!$.isEmptyObject(data.jockeys)){ %>\
                    <div class="box box-primary margin-bottom">\
                        <h3 class="header"><%= (runnerInfo.subjectRaceType == "T") ? raceBetsJS.i18n.data.headDriverStatistic : raceBetsJS.i18n.data.headJockeyStatistic %></h3>\
                        <div class="content">\
                            <table>\
                                <colgroup>\
                                    <col width="150" />\
                                    <col width="100" />\
                                    <col width="100" />\
                                </colgroup>\
                                <thead>\
                                    <tr>\
                                        <td><%= (runnerInfo.subjectRaceType == "T") ? raceBetsJS.i18n.data.labelDriver : raceBetsJS.i18n.data.labelJockey %></td>\
                                        <td class="alc"><%= raceBetsJS.i18n.data.labelStarts %></td>\
                                        <td class="alc"><%= raceBetsJS.i18n.data.labelAvgPlace %></td>\
                                    </tr>\
                                </thead>\
                                <tbody>\
                                    <% $.each(data.jockeys,function(i,jockey){ %>\
                                    <tr class="<%= (i%2) ? "" : "odd" %>">\
                                        <td><%= jockey.hasOwnProperty("jockey") ? jockey.jockey : "-" %></td>\
                                        <td class="alc"><%= jockey.hasOwnProperty("numRaces") ? jockey.numRaces : "-" %></td>\
                                        <td class="alc"><%= jockey.hasOwnProperty("averagePosition") ? jockey.averagePosition : "-" %></td>\
                                    </tr>\
                                    <% }); %>\
                                </tbody>\
                            </table>\
                        </div>\
                    </div>\
                    <% } %>\
                    <% if(!$.isEmptyObject(data.distances)){ %>\
                    <div class="box box-primary">\
                        <h3 class="header"><%=raceBetsJS.i18n.data.headBestTimes%></h3>\
                        <div class="content">\
                            <table>\
                                <colgroup>\
                                    <col width="150" />\
                                    <col width="100" />\
                                    <col width="100" />\
                                </colgroup>\
                                <thead>\
                                    <tr>\
                                        <td><%= raceBetsJS.i18n.data.labelDistance %></td>\
                                        <td class="alc"><%= raceBetsJS.i18n.data.labelRrWins %></td>\
                                        <td class="alc"><%= raceBetsJS.i18n.data.labelBestTime %></td>\
                                    </tr>\
                                </thead>\
                                <tbody>\
                                    <% $.each(data.distances,function(i,distance){ %>\
                                    <tr class="<%= (i%2) ? "" : "odd" %>">\
                                        <td><%= raceBetsJS.format.distance(distance.distance, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) %></td>\
                                        <td class="alc"><%= distance.wins %></td>\
                                        <td class="alc"><%= (!$.isEmptyObject(distance.minTime) ? distance.minTime : "-") %></td>\
                                    </tr>\
                                    <% }); %>\
                                </tbody>\
                            </table>\
                        </div>\
                    </div>\
                    <% } %>\
                </div>\
            </div>\
        </div>\
        <% } %>\
    ');

    raceBetsJS.application.templates.formguide.duels = _.template('\
        <div class="container">\
            <div class="left">\
                <div id="opponents-search-box" class="box box-primary margin-bottom">\
                    <h3 class="header"><%= raceBetsJS.i18n.data.searchOpponentLabel %></h3>\
                    <div class="content search">\
                        <div class="input-wrapper">\
                            <input autocomplete="off" type="text" id="formguide-search-opponent-input" name="" placeholder="<%= raceBetsJS.i18n.data.labelTypeHorseName %>">\
                            <span class="magni-cross magnifier"></span>\
                        </div>\
                    </div>\
                </div>\
                <% if(!$.isEmptyObject(data.opponents)){ %>\
                <div class="box box-primary">\
                    <h3 class="header"><%= raceBetsJS.i18n.data.labelRaceOpponents %></h3>\
                    <div class="content duel-opponents-content">\
                        <table class="duel-opponents">\
                            <tbody>\
                                <% $.each(data.opponents,function(i,runner){ %>\
                                <tr class="<%= (i%2) ? "" : "odd" %>" data-idrunner="<%= runner.idRunner %>">\
                                    <td class="silk"><img src="<%= raceBetsJS.application.globals.imageHost + "/cache/img/silks/" + runner.silkId + "_w42." + runner.silkExtension %>" width="21" height="20" /></td>\
                                    <td class="runner"><%= runner.name %></td>\
                                </tr>\
                                <% }); %>\
                            </tbody>\
                        </table>\
                    </div>\
                </div>\
                <% } %>\
            </div>\
            <div id="duels-right" class="right">\
                <div class="empty-duels-container">\
                    <%= raceBetsJS.application.templates.formguide.duels.rightColumnEmpty() %>\
                </div>\
                <div id="duels-right-content"></div>\
            </div>\
        </div>\
    ');

    raceBetsJS.application.templates.formguide.duels.rightColumnEmpty = _.template('\
            <div class="box-noresults">\
                <p class="duels-empty"><%= raceBetsJS.i18n.data.labelHead2HeadEmpty %></p>\
            </div>\
            <div class="loading">\
                <%= raceBetsJS.application.templates.loadingLayer({loadingStyle: "" }) %>\
            </div>\
    ');

    raceBetsJS.application.templates.formguide.duels.rightColumn = _.template('\
        <div class="duel-overview">\
            <div class="allduels"><strong><%= data.opponent.numDuels %></strong><%= raceBetsJS.i18n.data["labelDuel" + (data.opponent.numDuels > 1 ? "s" : "")] %></div>\
            <div class="therunner"><strong><%= data.opponent.duelsWon %></strong>\
                <img src="<%= raceBetsJS.application.globals.imageHost + "/cache/img/silks/" + data.runner.silkId + "_w42." + data.runner.silkExtension %>" alt="" width="21" /><%= data.runner.name %>\
            </div>\
            <div class="theopponent"><strong><%= data.opponent.duelsLost %></strong>\
                <img src="<%= raceBetsJS.application.globals.imageHost + "/cache/img/silks/" + data.opponent.silkId + "_w42." + data.opponent.silkExtension %>" alt="" width="21" /><%= data.opponent.name %>\
            </div>\
        </div>\
        <% if(data.opponent.numDuels >0) { %>\
            <% $.each(data.opponent.duels,function(i,duel){ %>\
                <div class="box box-primary box-racecard margin-bottom">\
                    <h3 class="header">\
                        <span class="c-flag isCountry<%= duel.country %>"></span>\
                        <%= duel.title %>,\
                        <span class="date"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.formatDateLong, duel.postTime) %></span>\
                        <span class="buttons">\
                            <% if( !$.isEmptyObject(duel.media)) { %>\
                            <span class="tovideo" data-duelhash="<%= duel.duelHash %>" title="<%= raceBetsJS.i18n.data.linkOpenArchiveVideo %>"></span>\
                            <% } %>\
                            <a href="race/details/id/<%= duel.idRace %>" class="toracecard" title="<%= raceBetsJS.i18n.data.labelOpenRaceCard %>"></a>\
                        </span>\
                        <p><%= raceBetsJS.i18n.getRaceTitle(duel.raceTitle, duel.raceNumber) %>\
                        <% if(duel.category != "") { %>\
                            <% if(duel.raceType == "D") { %>\
                                <%= "- " + duel.category %>\
                            <% } else { %>\
                                <%= "- " + raceBetsJS.i18n.data["raceCategory" + duel.category] %>\
                            <% } %>\
                        <% } %>\
                        <% if (duel.categoryLetter !== "") { %>\
                            <span class="category-letter">\
                                <% if(duel.country == "FR") { %>\
                                    (<%= raceBetsJS.i18n.print("raceCategoryLetterFrance", {letter: duel.categoryLetter}) %>)\
                                <% } else if($.inArray(duel.country, ["GB", "IE", "AE"]) > -1) { %>\
                                    (Class <%= duel.categoryLetter %>)\
                                <% } else { %>\
                                    (<%= duel.categoryLetter %>)\
                                <% } %>\
                            </span>\
                        <% } %>\
                        &nbsp;| <%= raceBetsJS.format.distance(duel.distance, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) %> | <%= raceBetsJS.format.number(duel.purse) + " " + duel.purseCurrency %>\
                        </p>\
                    </h3>\
                    <div class="content">\
                        <table>\
                            <thead>\
                                <tr>\
                                    <td><%= raceBetsJS.i18n.data.labelAbbrPos %></td>\
                                    <td colspan="2"><%= raceBetsJS.i18n.data.labelHorse %></td>\
                                    <td><%= raceBetsJS.i18n.data.labelJockey %></td>\
                                    <td class="alc"><%= raceBetsJS.i18n.data.labelToteOdds %></td>\
                                </tr>\
                            </thead>\
                            <tbody>\
                                <% $.each(duel.runners,function(i,runner){%>\
                                    <tr>\
                                        <td class="position">\
                                            <strong>\
                                                <% if (parseInt(runner.finalPosition) === 0) { %>\
                                                    <span title="<%= raceBetsJS.i18n.data["disqualification" + runner.finalPositionComment] %>" class="tipped">\
                                                        <%= raceBetsJS.i18n.data["disqualificationAbbr" + runner.finalPositionComment] %>\
                                                    </span>\
                                                <% } else { %>\
                                                    <%= runner.finalPosition %>\
                                                <% } %>\
                                            </strong>\
                                        </td>\
                                        <td class="silk alc"><img src="<%= raceBetsJS.application.globals.imageHost + "/cache/img/silks/" + ((runner.idSubject == data.runner.idSubject) ? (data.runner.silkId + "_w42." + data.runner.silkExtension) : (data.opponent.silkId + "_w42." + data.opponent.silkExtension)) %>" alt="" width="21" /></td>\
                                        <td class="runner"><%= runner.name %></td>\
                                        <td><%= runner.jockey %></td>\
                                        <td class="alc">\
                                            <a class="odds-button disabled">\
                                                <span><%= raceBetsJS.format.odds(runner.sp, null, true) %></span>\
                                            </a>\
                                        </td>\
                                    </tr>\
                                <% }); %>\
                            </tbody>\
                        </table>\
                    </div>\
                </div>\
            <% }); %>\
        <% } %>\
    ');

    raceBetsJS.application.templates.formguide.duels.runner = _.template('\
        <tr class="odd" data-idrunner="<%= runner.idRunner %>">\
            <td class="silk"><img src="<%= /*raceBetsJS.application.globals.imageHost +*/ runner.silk%>" width="21" height="20" /></td>\
            <td class="runner"><%= runner.name %></td>\
        </tr>\
    ');

    raceBetsJS.application.templates.formguide.duels.formEmpty = _.template('\
            <div class="box-noresults">\
                <p class="duels-empty"><%= raceBetsJS.i18n.data.labelformEmpty %></p>\
            </div>\
    ');

    raceBetsJS.application.templates.formguide.duels.antePostEmpty = _.template('\
            <div class="box-noresults">\
                <p class="ante-post-empty"><%= raceBetsJS.i18n.data.labelAntePostEmpty %></p>\
            </div>\
    ');

    raceBetsJS.application.templates.formguide.antepost = _.template('\
        <div class="container">\
            <% if($.isEmptyObject(data)) { %>\
            <div class="empty-duels-container">\
                <%= raceBetsJS.application.templates.formguide.duels.antePostEmpty() %>\
            </div>\
            <% }else {%>\
                <% $.each(data,function(i,e){%>\
                <div class="box box-primary margin-bottom <%= (i%2==0) ? "" : "right" %>">\
                    <h3 class="header">\
                        <span class="c-flag isCountry<%= e.country %>"></span>\
                        <%= e.title %>,\
                        <span class="date"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.formatDateLong, e.date) %></span>\
                        <p><%= e.raceTitle %>\
                        <% if(e.category != "") { %>\
                            <% if(e.raceType == "D") { %>\
                                <%= "- " + e.category %>\
                            <% } else { %>\
                                <%= "- " + raceBetsJS.i18n.data["raceCategory" + e.category] %>\
                            <% } %>\
                        <% } %>\
                        <% if (e.categoryLetter !== "") { %>\
                            <span class="category-letter">\
                                <% if(e.country == "FR") { %>\
                                    (<%= raceBetsJS.i18n.print("raceCategoryLetterFrance", {letter: e.categoryLetter}) %>)\
                                <% } else if($.inArray(e.country, ["GB", "IE", "AE"]) > -1) { %>\
                                    (Class <%= e.categoryLetter %>)\
                                <% } else { %>\
                                    (<%= e.categoryLetter %>)\
                                <% } %>\
                            </span>\
                        <% } %>\
                        &nbsp;| <%= raceBetsJS.format.distance(e.distance, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) %> | <%= raceBetsJS.format.number(e.purse) + " " + e.purseCurrency %>\
                        </p>\
                    </h3>\
                    <div class="content">\
                        <table>\
                            <thead>\
                                <tr>\
                                    <td><%= raceBetsJS.i18n.data.labelHorse %></td>\
                                    <td class="alc"><%= raceBetsJS.i18n.data.betTypeWIN %></td>\
                                    <td class="alc"><%= raceBetsJS.i18n.data.betTypePLU %></td>\
                                </tr>\
                            </thead>\
                            <tbody>\
                                <tr>\
                                    <% if (e.silkId !== "slw-formguide") { %>\
                                        <td class="runner has-silk" style="background-image: url(<%= raceBetsJS.application.globals.imageHost + "/cache/img/silks/" + e.silkId + "_w42." + e.silkExtension %>);"><%= e.name %></td>\
                                    <% } else { %>\
                                        <td class="runner"><%= e.name %></td>\
                                    <% } %>\
                                    <td class="alc">\
                                            <a class="odds-button fixed <%= (e.fixedOddsStatus == "ON" && e.raceStatus == "OPN" && !e.scratched) ? "enabled" : "" %>" data-index="<%= i %>" data-id-runner="<%= e.idRunner %>" data-bet-type="WIN">\
                                                <span data-odds="<%= e.fixedOddsWin %>">\
                                                    <%= raceBetsJS.format.odds(e.fixedOddsWin, null, true) %>\
                                                </span>\
                                            </a>\
                                    </td>\
                                    <td class="alc">\
                                            <a class="odds-button fixed <%= (e.fixedOddsStatus == "ON" && e.raceStatus == "OPN" && !e.scratched) ? "enabled" : "" %>" data-index="<%= i %>" data-id-runner="<%= e.idRunner %>" data-bet-type="PLC">\
                                                <span data-odds="<%= e.fixedOddsPlace %>">\
                                                    <%= raceBetsJS.format.odds(e.fixedOddsPlace, null, true) %>\
                                                </span>\
                                            </a>\
                                    </td>\
                                </tr>\
                            </tbody>\
                        </table>\
                        <span class="market-link">\
                            <a href="race/details/id/<%= e.idRace %>" class="toracecard"><%= raceBetsJS.i18n.data.linkGoToRace %></a>\
                        </span>\
                    </div>\
                </div>\
                <% }); %>\
            <% } %>\
        </div>\
    ');

    raceBetsJS.application.templates.formguide.issueReport = _.template('\
        <form>\
            <textarea id="dlg-issuereport-textarea" placeholder="<%= raceBetsJS.i18n.data.dialogReportIssuePlaceholder %>"></textarea>\
        </form>\
    ');

    raceBetsJS.application.templates.formguide.horseSearch = {};
    raceBetsJS.application.templates.formguide.horseSearch.base = _.template('\
        <div class="c-pageHeader">\
            <h1 class="c-pageHeader__title">\
              <%= raceBetsJS.i18n.data.labelFormGuide %>\
            </h1>\
            <span class="c-icon c-icon--dt isStats"></span>\
        </div>\
        <div class="search-area margin-bottom">\
            <div class="input-button-cont">\
                <div class="input-wrapper">\
                    <input class="c-input c-input--text" autocomplete="off" type="text" id="formguide-search-opponent-input" name="" placeholder="<%= raceBetsJS.i18n.data.labelTypeHorseName %>">\
                    <span class="cross"></span>\
                </div>\
                <button  id="search-button"  class="btn has-icon btn-primary">\
                    <span class="icon magnifier"></span>\
                    <span class="label"><%= raceBetsJS.i18n.data.searchHorseLabel %></span>\
                </button>\
                <div class="clearfix"></div>\
            </div>\
            <div class="loading margin-top">\
                <%= raceBetsJS.application.templates.loadingLayer({loadingStyle: "" }) %>\
            </div>\
        </div>\
        <div id="box-noresults" class="">\
            <p><%= raceBetsJS.i18n.data.textSearchNoMatches %></p>\
        </div>\
        <div class="box box-primary margin-bottom" id="search-box-cont">\
            <h3 class="header"><%= raceBetsJS.i18n.data.labelSearchResultsFor %> "<span id="search-query"></span>"</h3>\
            <div class="content" >\
                <ul id="search-results"></ul>\
            </div>\
            <div id="infoBox" class="yellow-info-box margin-top"></div>\
        </div>\
    ');

    raceBetsJS.application.templates.formguide.horseSearch.searchResults = _.template('\
    <% if(!$.isEmptyObject(data.subjects)) {  %>\
        <table class="search-result-table">\
            <tr>\
                <th>\
                </th>\
                <th>\
                    <%= raceBetsJS.i18n.data.labelRunner %>\
                </th>\
                <th>\
                    <%= raceBetsJS.i18n.data.labelYear %>\
                </th>\
                <th>\
                    <%= raceBetsJS.i18n.data.labelGender %>\
                </th>\
                <th>\
                    <%= raceBetsJS.i18n.data.labelSire %>\
                </th>\
                <th>\
                    <%= raceBetsJS.i18n.data.labelDam %>\
                </th>\
            </tr>\
        <% $.each(data.subjects,function(i,runner){ %>\
            <tr data-idsubject="<%= runner.idSubject %>">\
                <td>\
                    <img src="<%= raceBetsJS.application.globals.imageHost + "/cache/img/silks/" + runner.silkId + "_w62." + runner.silkExtension %>" alt="" width="20" />\
                </td>\
                <td>\
                    <%= runner.name %>\
                </td>\
                <td>\
                    <% if(runner.yearOfBirth != "0000"){%>\
                        <%= runner.yearOfBirth %>\
                        <span class="light-grey"><%= raceBetsJS.i18n.print("xYearOldAbbr", { age: (new Date).getFullYear() - runner.yearOfBirth }) %></span>\
                    <% } %>\
                </td>\
                <td>\
                    <%= ( raceBetsJS.i18n.data["gender"+ runner.gender ] || "") %>\
                </td>\
                <td>\
                    <%= runner.sire %>\
                </td>\
                <td>\
                    <%= runner.dam %>\
                </td>\
            <tr>\
        <% }); %>\
        </table>\
    <% } %>\
    ');
})(raceBetsJS);
