/**
* MessageBox module
*
* @author Robin Ebers
*/
(function(raceBetsJS) {
	raceBetsJS.application.assets.messageBox = function() {
		// @private

        /**
        * Default options
        * Why is this in a function? Because it's using the dict that otherwise is not yet initilised, that's why! ;D
        */
        function defaultOptions() {
            return {
                id: '#global-messagebox',
                type: 'notice',
                content: '',
                prepend: '#content-container',
                after: false,
                before: false,
                closeButton: true,
                onCloseButton: false,
                fadeOut: 15000,
                buttons: []
            }
        };

        function show(options) {
            var options = $.extend({}, defaultOptions(), options || {});

            if ($(options.id).length > 0) {
                $(options.id).remove();
            }

            var msgBox = $('<div>').attr('id', options.id.replace('#', '')).addClass(options.type).addClass('is-message-box clearfix');
            var content = $('<p>').html(options.content);
            msgBox.append(content);

            if (options.buttons.length > 0) {
                msgBox.addClass('buttons');
                var buttonBar = $('<div class="buttons">');

                $.each(options.buttons, function() {
                    var button = $('<button class="active">');
                    button.text(this.label);
                    button.click(this.action);
                    buttonBar.append(button);
                });

                msgBox.append(buttonBar);
            }

            if (options.closeButton) {
                var closeButton = $('<span>').addClass('close').click(function() {
                    $(options.id).remove();
                    if (options.onCloseButton !== false) {
                        options.onCloseButton();
                    }
                });

                msgBox.append(closeButton);
            }

            // BOOYAH! GO DOM...
            if (options.after !== false) {
                $(options.after).after(msgBox);
            } else if (options.before !== false) {
                $(options.before).before(msgBox);
            } else {
                $(options.prepend).prepend(msgBox);
            }

            // after it is painted, let's get the JS magic going
            if (options.buttons.length > 0) {
                // setting max width of content area so the buttons are in line
                content.css({ 'max-width': (msgBox.outerWidth() - buttonBar.outerWidth() - 50) });

                // setting margin-top of buttonBar so they're always centered
                buttonBar.css({ 'margin-top': ((msgBox.innerHeight() / 2) - (buttonBar.outerHeight() / 2) - 8) });
            }

            msgBox.hide().fadeIn(500);

            if (options.fadeOut !== false) {
                new raceBetsJS.time.Timeout({
                    delay: options.fadeOut,
                    callback: function() {
                        hide({ box: msgBox });
                    }
                });
            }
        }

        function hide(options) {
            if (options !== undefined) {
                if (options.id !== undefined) {
                    $(options.id).fadeOut(500, function() {
                        $(this).remove();

                        if (options.onHide !== undefined) {
                            options.onHide();
                        }
                    });
                } else if (options.box !== undefined) {
                    $(options.box).fadeOut(500, function() {
                        $(this).remove();

                        if (options.onHide !== undefined) {
                            options.onHide();
                        }
                    });
                } else if (options.removeAll !== undefined) {
                    $('div.is-message-box').fadeOut(500, function() {
                        $(this).remove();
                    });
                }

            } else {
                $('#global-messagebox').fadeOut(500, function() {
                    $(this).remove();
                });

            }
        }

		// @public
		return {
            show: show,
            hide: hide
		};
	}();
})(raceBetsJS);