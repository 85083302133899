/**
* Format Helpers
*/
(function(raceBetsJS) {
	raceBetsJS.format = {

        trackConditions : function(race,abbr) {
                var ret = "",
                    trackSurface;
                /*
                  Parameters:
                    trackGoing
                    trackSurface
                    country
                */
                if (race.hasOwnProperty("trackGoing") && race.hasOwnProperty("trackSurface") && race.hasOwnProperty("country")) {
                    race.trackSurface = race.trackSurface !== "TRF" ? "DRT" : "TRF";

                    race.trackGoing = parseFloat(race.trackGoing);
                    if(race.trackGoing == 0.0) {
                        ret = "";
                    } else {
                        trackSurface = race.trackSurface.charAt(0).toUpperCase() + race.trackSurface.slice(1).toLowerCase();

                        if (race.trackSurface == "TRF" && race.trackGoing == 2.5) {
                            if(abbr) {
                                ret = (raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.ceil(race.trackGoing)].charAt(0).toUpperCase()
                                    + raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.floor(race.trackGoing)] || "" ).charAt(0).toUpperCase();
                            } else {
                                ret = raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.ceil(race.trackGoing)] + " "
                                      + raceBetsJS.i18n.data.connectTo + " "
                                      + raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.floor(race.trackGoing)];
                            }
                        } else if (race.trackSurface == "TRF" && race.trackGoing == 3.5 && race.country == "IE" && raceBetsJS.application.user.lang == "en") {
                            if(abbr) {
                                ret = "Y";
                            }else {
                                ret = "yielding";
                            }
                        } else if (race.trackSurface == "DRT" && race.trackGoing > 3) {
                            ret = (raceBetsJS.i18n.data["trackGoing" + trackSurface + "3"] || "").charAt(0).toUpperCase()
                        } else if ((race.trackGoing % 1) > 0) {
                            if(abbr) {
                                ret = (raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.floor(race.trackGoing)] || "").charAt(0).toUpperCase()
                                    + (raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.ceil(race.trackGoing)] || "").charAt(0).toUpperCase();
                            } else {
                                ret = (raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.floor(race.trackGoing)] || "")
                                    + " " + raceBetsJS.i18n.data.connectTo + " " + raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.ceil(race.trackGoing)];
                            }

                        } else {
                            if(abbr) {
                                ret = (raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.floor(race.trackGoing)] || "").charAt(0).toUpperCase();
                            } else {
                                ret = raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.floor(race.trackGoing)];
                            }

                        }
                    }
                }

                return ret;
        },

        ageCondition: function(ageFrom, ageTo) {
            if(ageFrom != 0 && ageTo != 0 && ageFrom != ageTo) {
                return raceBetsJS.i18n.print('conditionAgeXToY', {
                    ageFrom: ageFrom,
                    ageTo: ageTo
                });
            } else if(ageFrom != 0 && ageTo != 0 && ageFrom == ageTo) {
                return raceBetsJS.i18n.print('conditionAgeX', { age: ageFrom });
            } else if(ageFrom != 0) {
                return raceBetsJS.i18n.print('conditionAgeXPlus', { age: ageFrom });
            } else if(ageTo != 0) {
                return raceBetsJS.i18n.print('conditionAgeUpToX', { age: ageTo });
            }
        },

        // PHPJS date function
        date: function(format, timestamp) {
            var that = this,
                jsdate, f, formatChr = /\\?([a-z])/gi,
                formatChrCb,
                // Keep this here (works, but for code commented-out
                // below for file size reasons)
                //, tal= [],
                _pad = function (n, c) {
                    if ((n = n + '').length < c) {
                        return new Array((++c) - n.length).join('0') + n;
                    }
                    return n;
                },
                // TODO: Dirty mix of application namespace and raceBetsJS lib
                txt_words = raceBetsJS.application.globals.localizedDateStrings; //["Sun", "Mon", "Tues", "Wednes", "Thurs", "Fri", "Satur", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            formatChrCb = function (t, s) {
                return f[t] ? f[t]() : s;
            };
            f = {
                // Day
                d: function () { // Day of month w/leading 0; 01..31
                    return _pad(f.j(), 2);
                },
                D: function () { // Shorthand day name; Mon...Sun
                    return f.l().slice(0, 3);
                },
                j: function () { // Day of month; 1..31
                    return jsdate.getDate();
                },
                l: function () { // Full day name; Monday...Sunday
                    return txt_words[f.w()];
                },
                N: function () { // ISO-8601 day of week; 1[Mon]..7[Sun]
                    return f.w() || 7;
                },
                S: function () { // Ordinal suffix for day of month; st, nd, rd, th
                    var j = f.j();
                    return j > 4 || j < 21 ? 'th' : {1: 'st', 2: 'nd', 3: 'rd'}[j % 10] || 'th';
                },
                w: function () { // Day of week; 0[Sun]..6[Sat]
                    return jsdate.getDay();
                },
                z: function () { // Day of year; 0..365
                    var a = new Date(f.Y(), f.n() - 1, f.j()),
                        b = new Date(f.Y(), 0, 1);
                    return Math.round((a - b) / 864e5) + 1;
                },

                // Week
                W: function () { // ISO-8601 week number
                    var a = new Date(f.Y(), f.n() - 1, f.j() - f.N() + 3),
                        b = new Date(a.getFullYear(), 0, 4);
                    return _pad(1 + Math.round((a - b) / 864e5 / 7), 2);
                },

                // Month
                F: function () { // Full month name; January...December
                    return txt_words[6 + f.n()];
                },
                m: function () { // Month w/leading 0; 01...12
                    return _pad(f.n(), 2);
                },
                M: function () { // Shorthand month name; Jan...Dec
                    return f.F().slice(0, 3);
                },
                n: function () { // Month; 1...12
                    return jsdate.getMonth() + 1;
                },
                t: function () { // Days in month; 28...31
                    return (new Date(f.Y(), f.n(), 0)).getDate();
                },

                // Year
                L: function () { // Is leap year?; 0 or 1
                    return new Date(f.Y(), 1, 29).getMonth() === 1 | 0;
                },
                o: function () { // ISO-8601 year
                    var n = f.n(),
                        W = f.W(),
                        Y = f.Y();
                    return Y + (n === 12 && W < 9 ? -1 : n === 1 && W > 9);
                },
                Y: function () { // Full year; e.g. 1980...2010
                    return jsdate.getFullYear();
                },
                y: function () { // Last two digits of year; 00...99
                    return (f.Y() + "").slice(-2);
                },

                // Time
                a: function () { // am or pm
                    return jsdate.getHours() > 11 ? "pm" : "am";
                },
                A: function () { // AM or PM
                    return f.a().toUpperCase();
                },
                B: function () { // Swatch Internet time; 000..999
                    var H = jsdate.getUTCHours() * 36e2,
                        // Hours
                        i = jsdate.getUTCMinutes() * 60,
                        // Minutes
                        s = jsdate.getUTCSeconds(); // Seconds
                    return _pad(Math.floor((H + i + s + 36e2) / 86.4) % 1e3, 3);
                },
                g: function () { // 12-Hours; 1..12
                    return f.G() % 12 || 12;
                },
                G: function () { // 24-Hours; 0..23
                    return jsdate.getHours();
                },
                h: function () { // 12-Hours w/leading 0; 01..12
                    return _pad(f.g(), 2);
                },
                H: function () { // 24-Hours w/leading 0; 00..23
                    return _pad(f.G(), 2);
                },
                i: function () { // Minutes w/leading 0; 00..59
                    return _pad(jsdate.getMinutes(), 2);
                },
                s: function () { // Seconds w/leading 0; 00..59
                    return _pad(jsdate.getSeconds(), 2);
                },
                u: function () { // Microseconds; 000000-999000
                    return _pad(jsdate.getMilliseconds() * 1000, 6);
                },

                // Timezone
                e: function () { // Timezone identifier; e.g. Atlantic/Azores, ...
                    // The following works, but requires inclusion of the very large
                    // timezone_abbreviations_list() function.
        /*              return this.date_default_timezone_get();
        */
                    throw 'Not supported (see source code of date() for timezone on how to add support)';
                },
                I: function () { // DST observed?; 0 or 1
                    // Compares Jan 1 minus Jan 1 UTC to Jul 1 minus Jul 1 UTC.
                    // If they are not equal, then DST is observed.
                    var a = new Date(f.Y(), 0),
                        // Jan 1
                        c = Date.UTC(f.Y(), 0),
                        // Jan 1 UTC
                        b = new Date(f.Y(), 6),
                        // Jul 1
                        d = Date.UTC(f.Y(), 6); // Jul 1 UTC
                    return 0 + ((a - c) !== (b - d));
                },
                O: function () { // Difference to GMT in hour format; e.g. +0200
                    var a = jsdate.getTimezoneOffset();
                    return (a > 0 ? "-" : "+") + _pad(Math.abs(a / 60 * 100), 4);
                },
                P: function () { // Difference to GMT w/colon; e.g. +02:00
                    var O = f.O();
                    return (O.substr(0, 3) + ":" + O.substr(3, 2));
                },
                T: function () { // Timezone abbreviation; e.g. EST, MDT, ...
                    // The following works, but requires inclusion of the very
                    // large timezone_abbreviations_list() function.
        /*              var abbr = '', i = 0, os = 0, default = 0;
                    if (!tal.length) {
                        tal = that.timezone_abbreviations_list();
                    }
                    if (that.php_js && that.php_js.default_timezone) {
                        default = that.php_js.default_timezone;
                        for (abbr in tal) {
                            for (i=0; i < tal[abbr].length; i++) {
                                if (tal[abbr][i].timezone_id === default) {
                                    return abbr.toUpperCase();
                                }
                            }
                        }
                    }
                    for (abbr in tal) {
                        for (i = 0; i < tal[abbr].length; i++) {
                            os = -jsdate.getTimezoneOffset() * 60;
                            if (tal[abbr][i].offset === os) {
                                return abbr.toUpperCase();
                            }
                        }
                    }
        */
                    return 'UTC';
                },
                Z: function () { // Timezone offset in seconds (-43200...50400)
                    return -jsdate.getTimezoneOffset() * 60;
                },

                // Full Date/Time
                c: function () { // ISO-8601 date.
                    return 'Y-m-d\\Th:i:sP'.replace(formatChr, formatChrCb);
                },
                r: function () { // RFC 2822
                    return 'D, d M Y H:i:s O'.replace(formatChr, formatChrCb);
                },
                U: function () { // Seconds since UNIX epoch
                    return jsdate.getTime() / 1000 | 0;
                }
            };
            this.date = function (format, timestamp) {
                that = this;
                jsdate = ((typeof timestamp === 'undefined') ? new Date() : // Not provided
                (timestamp instanceof Date) ? new Date(timestamp) : // JS Date()
                new Date(timestamp * 1000) // UNIX timestamp (auto-convert to int)
                );
                return format.replace(formatChr, formatChrCb);
            };
            return this.date(format, timestamp);
        },

        currencySymbols: {
            'USD' : 'US$',
            'GBP' : '£',
            'AED' : 'AED',
            'HKD' : 'HK$',
            'SEK' : 'kr',
            'ZAR' : 'R',
            'COP' : 'CO$',
            'CZK' : 'Kč',
            'AUD' : 'AU$',
            'SGD' : 'SG$',
            'JPY' : '¥',
            'MXN' : 'MX$',
            'NOK' : 'kr',
            'CHF' : 'CHF',
            'DKK' : 'kr',
            'MOP' : 'MOP$',
            'TRY' : 'TR₤',
            'INR' : '₹',
            'CLP' : '$',
            'ARS' : '$',
            'UYU' : '$U',
            'PEN' : 'S/',
            'PLN' : 'zł',
            'CAN' : 'C$',
            'NZD' : 'NZ$',
            'BRL' : 'R$',
            'HUF' : 'Ft',
            'EUR' : '€',
            'SAR' : 'SAR',
            'GEL' : '₾'
        },

        getCurrencySymbol : function(currency){
            return (raceBetsJS.format.currencySymbols.hasOwnProperty(currency) ? raceBetsJS.format.currencySymbols[currency] : "");
        },

        money: function(amount, decimals, currency, symbol, toAccountCurrency, forceSuffix, dec_beautify) {
            if (toAccountCurrency) {
                amount = raceBetsJS.localize.exchange(amount, currency, raceBetsJS.application.user.currency);
                currency = raceBetsJS.application.user.currency;
            }

            // currencies which should show the sign before the amount (i.e. €10.00)
            var precedingCurrencies = ['GBP', 'USD', 'INR', 'TRY', 'BRL'];

            decimals = (decimals === undefined) ? 2 : decimals;
            var formatted = this.number(amount, decimals);

            // remove empty decimal places
            if (dec_beautify !== undefined) {
                formatted = formatted.toString().replace( raceBetsJS.application.globals.decimalSeparator + new Array(decimals + 1).join('0'), '');
            }

            if (currency !== undefined) {
                if ($.inArray(currency, precedingCurrencies) == -1 || forceSuffix === true) {
                    return formatted + ' ' + (symbol ? raceBetsJS.format.getCurrencySymbol(currency) : currency);
                } else{
                    return (symbol ? raceBetsJS.format.getCurrencySymbol(currency) : currency + ' ') + formatted;
                }
            } else {
                return formatted;
            }
        },

        number: function(number, decimals, dec_point, thousands_sep, dec_beautify) {
            number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? raceBetsJS.application.globals.thousandsSeparator : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? raceBetsJS.application.globals.decimalSeparator : dec_point,
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + Math.round(n * k) / k;
                };
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }

            var res = s.join(dec);

            // remove empty decimal places
            if (dec_beautify !== undefined) {
                res = res.toString().replace(dec + new Array(decimals + 1).join('0'), '');
            }

            return res;
        },

        odds: function(odds, format, noStrPad) {
            // get odds format
            format = (!format) ? raceBetsJS.application.assets.settings.get().dialog.general.oddsFormat : format;

            if (format == 'base1') {
                odds = this.number(odds, 2, '.', '');
            } else if (format == 'base10') {
                odds = Math.floor(odds * 10);
            } else if (format == 'fractional') {
                if (raceBetsJS.format.ODDS_FRACTIONAL[odds]) {
                    odds = raceBetsJS.format.ODDS_FRACTIONAL[odds];
                } else if (odds == 99.90 || odds == 99.99) {
                    odds = '99/1';
                } else {
                    odds = (odds - 1).toFixed(2) * 1;

                    var denum = 1;
                    while (odds % 1 != 0) {
                        denum *= 10;
                        odds = (odds * 10).toFixed(2) * 1;
                    }

                    odds = odds + '/' + denum;

                    /*var decimal = parseFloat(odds);
                    var denominator = 100;
                    var numerator = (decimal-1) * denominator;
                    numerator = Math.round(numerator);

                    var common = getCommonDenominator(numerator, denominator);
                    numerator /= common;
                    denominator /= common;

                    if (denominator == 10) {
                        odds = (numerator / 10) + '/1';
                    } else {
                        odds = numerator + '/' + denominator;
                    }*/
                }
            }

            // remove padding zeros if set
            if (format == 'base10' || (noStrPad && format == 'base1')) {
                odds = odds * 1;
            }

            return odds;
        },

        /**
        * "constant" to contain the fractional equivalents of decimal odds
        */
        ODDS_FRACTIONAL: {
            1.01: '1/100',
            1.02: '1/50',
            1.03: '1/33',
            1.04: '1/25',
            1.05: '1/20',
            1.06: '1/16',
            1.07: '1/14',
            1.08: '1/12',
            1.09: '1/11',
            1.10: '1/10',
            1.11: '1/9',
            1.12: '1/8',
            1.13: '2/15',
            1.14: '1/7',
            1.15: '2/13',
            1.16: '1/6',
            1.18: '2/11',
            1.20: '1/5',
            1.22: '2/9',
            1.25: '1/4',
            1.26: '20/75',
            1.28: '2/7',
            1.30: '3/10',
            1.33: '1/3',
            1.35: '7/20',
            1.36: '4/11',
            1.40: '2/5',
            1.42: '40/95',
            1.44: '4/9',
            1.45: '9/20',
            1.47: '40/85',
            1.50: '1/2',
            1.53: '8/15',
            1.55: '11/20',
            1.57: '4/7',
            1.60: '3/5',
            1.61: '8/13',
            1.62: '5/8',
            1.65: '13/20',
            1.66: '4/6',
            1.70: '7/10',
            1.72: '8/11',
            1.75: '15/20',
            1.80: '4/5',
            1.83: '5/6',
            1.85: '17/20',
            1.90: '10/11',
            1.95: '20/21',
            2.00: '1/1',
            2.05: '21/20',
            2.10: '11/10',
            2.20: '6/5',
            2.25: '5/4',
            2.30: '13/10',
            2.37: '11/8',
            2.40: '7/5',
            2.50: '6/4',
            2.60: '8/5',
            2.62: '13/8',
            2.70: '17/10',
            2.75: '7/4',
            2.80: '9/5',
            2.87: '15/8',
            2.90: '19/10',
            3.00: '2/1',
            3.10: '21/10',
            3.12: '85/40',
            3.20: '11/5',
            3.25: '9/4',
            3.30: '23/10',
            3.37: '95/40',
            3.40: '12/5',
            3.50: '5/2',
            3.60: '13/5',
            3.75: '11/4',
            3.80: '14/5',
            4.00: '3/1',
            4.20: '16/5',
            4.30: '33/10',
            4.33: '10/3',
            4.40: '17/5',
            4.50: '7/2',
            4.60: '18/5',
            4.75: '75/20',
            4.80: '19/5',
            5.00: '4/1',
            5.50: '9/2',
            6.00: '5/1',
            6.50: '11/2',
            7.00: '6/1',
            7.50: '13/2',
            8.00: '7/1',
            8.50: '15/2',
            9.00: '8/1',
            9.50: '17/2'
        },

        /**
        * Format a number to a roman numeral
        *
        * @param integer number     Number that needs to be converted
        */
        romanNumeral: function(number) {
            var romanNumerals = ['I', 'II', 'III', 'IV', 'V'];
            return romanNumerals[number-1];
        },

        isPickBetType: function(betType) {
            return /^(P|V|PP)(\d+)$/.test(betType);
        },

        isPickPlace: function(betType) {
            return /^PP/.test(betType);
        },

        getPickNumRaces: function(pickType) {
            return raceBetsJS.format.isPickPlace(pickType)
                ? parseInt(pickType.substr(2), 10)
                : /^V(64|65|75)$/.test(pickType)
                    ? parseInt(pickType.substr(1, 1), 10)
                    : parseInt(pickType.substr(1), 10);
        },

        getMinStakeBOKFXD: function(currencySettings, betCategory, betType) {
            return currencySettings['minStake' + betCategory + betType] !== undefined
                ? currencySettings['minStake' + betCategory + betType]
                : currencySettings['minStake' + betCategory];
        },

        getMaxStakeBOKFXD: function(currencySettings, betCategory, betType) {
            return currencySettings['maxStake' + betCategory + betType] !== undefined
                ? currencySettings['maxStake' + betCategory + betType]
                : currencySettings['maxStake' + betCategory];
        },

        /**
        * Format complex pick bet name
        *
        * @param object betTypes    TOT bet types object
        * @param string country     country of the event
        */
        formatComplexPickName: function (betTypes, country) {
            var pickNumWinRaces = [],
                pickNumPlaceRaces = [],
                pickWin = [],
                pickPlace = [],
                resultArr = [];

            _.each(betTypes, function (betType) {
                if (raceBetsJS.format.isPickPlace(betType)) {
                    pickPlace.push(betType);
                } else {
                    pickWin.push(betType);
                }
            });

            if (pickWin.length > 0) {
                if (pickWin.length === 1) {
                    resultArr.push(raceBetsJS.format.betTypeName(pickWin[0], "", raceBetsJS.application.user.lang, null, country));
                } else {
                    _.each(pickWin, function (pickType) { pickNumWinRaces.push(raceBetsJS.format.getPickNumRaces(pickType)) });
                    resultArr.push(raceBetsJS.i18n.print("betTypePXX", { numRaces: _.uniq(pickNumWinRaces).join("/") }));
                }
            }

            if (pickPlace.length > 0) {
                if (pickPlace.length === 1) {
                    resultArr.push(raceBetsJS.format.betTypeName(pickPlace[0], "", raceBetsJS.application.user.lang, null, country));
                } else {
                    _.each(pickTypes, function (pickType) { pickNumPlaceRaces.push(raceBetsJS.format.getPickNumRaces(pickType)) });
                    resultArr.push(raceBetsJS.i18n.print("betTypePPX", { numRaces: _.uniq(pickNumPlaceRaces).join("/") }));
                }
            }

            return resultArr.join(', ');
        },
        /**
        * Format a betType name
        *
        * @param string betType     betType abbreviation from DB
        * @param bool   wps         Win/Place/Show
        * @param string country     country of the event
        */
        betTypeName: function(betType, betCategory, language, wps, country) {
            if (raceBetsJS.format.isPickBetType(betType)) {
                if ($.inArray(country, ['DE', 'SE', 'NO', 'DK', 'FI']) > -1) {
                    return 'V' + raceBetsJS.format.getPickNumRaces(betType);
                } else if (raceBetsJS.format.isPickPlace(betType)) {
                    return raceBetsJS.i18n.print('betTypePPX', { numRaces: raceBetsJS.format.getPickNumRaces(betType) });
                }
                return raceBetsJS.i18n.print('betTypePXX', { numRaces: raceBetsJS.format.getPickNumRaces(betType) });

            } else if (wps && (betType == 'WIN' || betType == 'PLC' || betType == 'WP')) {
                return raceBetsJS.i18n.data['betType' + betType + 'US'];

            } else if (betCategory == 'BOK' && (betType == 'EXA' || betType == 'TRI') && language == 'en') {
                return raceBetsJS.i18n.data['betType' + betType + 'BOKUK'];

            }

            return raceBetsJS.i18n.data['betType' + betType];
        },

        weight: function(sourceKg, outputFormat) {
            if (outputFormat == 'imperial') {
                var basePounds = Math.round(parseFloat(sourceKg) / 0.453592370); // convert to pounds
                var stones = Math.floor(basePounds / 14); // convert to stones
                var pounds = Math.round(basePounds % 14);

                return stones + '-' + pounds;

            } else {
                sourceKg = raceBetsJS.format.number(sourceKg, 1);
                return raceBetsJS.i18n.print('weightKg', {weight: sourceKg});

            }
        },

        distance: function(sourceMeters, outputFormat) {
            var output = '';
            if (outputFormat == 'imperial') {
                // imperial
                var yards = Math.round(sourceMeters / 0.9144);
                var furlong = Math.floor(yards / 220);
                var miles = Math.floor(furlong / 8);

                if (miles > 0) {
                    output += miles + raceBetsJS.i18n.data.abbrMiles;
                }

                if ((furlong % 8) > 0) {
                    output += Math.floor((furlong % 8)) + raceBetsJS.i18n.data.abbrFurlong;
                }

                if ((yards % 220) > 0) {
                    output += Math.floor((yards % 220)) + raceBetsJS.i18n.data.abbrYards;
                }

            } else {
                // meters as default
                output =  raceBetsJS.i18n.print('abbreviationMeters', {
                    distance: raceBetsJS.format.number(sourceMeters)
                });
            }

            return output;
        },

        statusToColour: function(raceStatus) {
            var res;
            switch (raceStatus.toUpperCase()) {
                case 'OPN':
                    res = 'green';
                    break;

                case 'STR':
                case 'TMP':
                    res = 'yellow';

                default:
                    res = 'red';
                    break;
            }

            return res;
        }
    };
})(raceBetsJS);
