/**
* Parameter
*
* @author Robin Ebers
*/
(function(raceBetsJS) {
    raceBetsJS.browser.params = {
        get: function(param) {
            var url = window.location.href;

            // if hash url is available, use this instead
            if (url.indexOf('#http') > 0) {
                url = url.substr(url.indexOf('#http')+1, url.length);
            }

            // the following regex is the heart of this function. basically it doesn't care if it is a normal query url
            // or a url path, rewritten mod_rewrite. it simply interprets every case and it should be fine doing this.
            var regEx = new RegExp('[?|&|\/]' + param + '[=|\/]([^&|$|#|\/]+)');
            var value = regEx.exec(url);

            // return parameter value if matched
            return (value != null) ? value[1] : false;
        }
    };
})(raceBetsJS);