/**
* Timezone
* This script (Javascript Timezone Detection) detects Olson timezone ID of user's system's timezone.
* Detection is tested and passed 100% in the following conditions:
*
* * Windows 7,Opera 11.00
* * Windows 7,Firefox 3.6.13
* * Windows 7,Safari 5.0.3
* * Windows 7,Google Chrome 8.0.552.237
* * Windows 7,Internet Explorer 8.0.7600.16385
*
* * Mac OS X,Google Chrome 8.0.552.237
* * Mac OS X,Firefox 3.6.13
* * Mac OS X,Opera 11.01
* * Mac OS X,Safari Version 5.0.3
*
* * Centos 5,Firefox 3.6.13
* * Centos 5,Safari
* * Centos 5,Opera 11.00
*
* The script has ability to detect 90 timezones.
*
* Every row in zones-array consist of
* 0: Olson timezone ID,
* 1-6: Transition start time (usually summer time start and sometimes winter time start)
* 7: Utc offset in minutes
* 8: 1 = has transition,0 = has not transition
*
* @author Robin Ebers (robin.ebers@gmail.com)
* @author Timo Kähkönen (https://bitbucket.org/pellepim/jstimezonedetect/issue/21/simpler-detection-script)
*/

(function(raceBetsJS) {
    raceBetsJS.time.timezone = function() {

        var getTimezoneList = function() {
            return zones = [
                [ 'Etc/GMT+12',                      '(UTC-12:00) GMT+12', 2011,0,1,0,0,0, -720, 0 ]/* STD: -720 */,
                [ 'Pacific/Apia',                    '(UTC-11:00) Apia', 2011,3,3,11,0,0, -660, 1 ]/* For Opera Linux. Unexpectedly winter-offset. */,
                // [ 'Pacific/Apia',                    '(UTC-11:00) Apia', 2010,8,26,11,0,0, -600, 1 ]/* STD: -660 */,
                [ 'Pacific/Pago_Pago',               '(UTC-11:00) Midway Island, Samoa', 2011,0,1,0,0,0, -660, 0 ]/* STD: -660 */,
                [ 'Pacific/Marquesas',               '(UTC-10:30) Marquesas Islands', 2011,0,1,0,0,0, -570, 0 ]/* STD: -570 */,
                [ 'America/Adak',                    '(UTC-10:00) Hawaii-Aleutian', 2011,2,13,12,0,0, -540, 1 ]/* STD: -600 */,
                [ 'Pacific/Honolulu',                '(UTC-10:00) Hawaii', 2011,0,1,0,0,0, -600, 0 ]/* STD: -600 */,
                [ 'Pacific/Gambier',                 '(UTC-09:30) Gambier Islands', 2011,0,1,0,0,0, -540, 0 ]/* STD: -540 */,
                [ 'America/Anchorage',               '(UTC-09:00) Alaska', 2011,2,13,11,0,0, -480, 1 ]/* STD: -540 */,
                [ 'America/Los_Angeles',             '(UTC-08:00) Pacific Time (US & Canada)', 2011,2,13,10,0,0, -420, 1 ]/* STD: -480 */,
                [ 'Pacific/Pitcairn',                '(UTC-08:00) Pitcairn Islands', 2011,0,1,0,0,0, -480, 0 ]/* STD: -480 */,
                [ 'America/Santa_Isabel',            '(UTC-08:00) Santa Isabel', 2011,3,3,10,0,0, -420, 1 ]/* STD: -480 */,
                [ 'America/Phoenix',                 '(UTC-07:00) Arizona', 2011,0,1,0,0,0, -420, 0 ]/* STD: -420 */,
                [ 'America/Denver',                  '(UTC-07:00) Mountain Time (US & Canada)', 2011,2,13,9,0,0, -360, 1 ]/* STD: -420 */,
                [ 'America/Mazatlan',                '(UTC-07:00) Chihuahua, La Paz, Mazatlan', 2011,3,3,9,0,0, -360, 1 ]/* STD: -420 */,
                [ 'America/Guatemala',               '(UTC-06:00) Saskatchewan, Central America', 2011,0,1,0,0,0, -360, 0 ]/* STD: -360 */,
                [ 'America/Chicago',                 '(UTC-06:00) Central Time (US & Canada)', 2011,2,13,8,0,0, -300, 1 ]/* STD: -360 */,
                [ 'America/Mexico_City',             '(UTC-06:00) Guadalajara, Mexico City, Monterrey', 2011,3,3,8,0,0, -300, 1 ]/* STD: -360 */,
                [ 'America/Caracas',                 '(UTC-05:30) Caracas', 2011,0,1,0,0,0, -270, 0 ]/* STD: -270 */,
                [ 'America/Bogota',                  '(UTC-05:00) Bogota, Lima, Quito, Rio Branco', 2011,0,1,0,0,0, -300, 0 ]/* STD: -300 */,
                [ 'Pacific/Easter',                  '(UTC-05:00) Easter Island', 2011,9,9,4,0,0, -300, 1 ]/* STD: -360 */,
                [ 'America/Havana',                  '(UTC-05:00) Cuba', 2011,2,13,5,0,0, -240, 1 ]/* STD: -300 */,
                [ 'America/New_York',                '(UTC-05:00) Eastern Time (US & Canada)', 2011,2,13,7,0,0, -240, 1 ]/* STD: -300 */,
                [ 'America/St_Johns',                '(UTC-04:30) Newfoundland', 2011,2,13,3,31,0, -150, 1 ]/* STD: -210 */,
                [ 'America/Goose_Bay',               '(UTC-04:00) Atlantic Time (Goose Bay)', 2011,2,13,4,1,0, -180, 1 ]/* STD: -240 */,
                [ 'America/Halifax',                 '(UTC-04:00) Atlantic Time (Canada)', 2011,2,13,6,0,0, -180, 1 ]/* STD: -240 */,
                [ 'America/Santo_Domingo',           '(UTC-04:00) Santo Domingo', 2011,0,1,0,0,0, -240, 0 ]/* STD: -240 */,
                [ 'Atlantic/Stanley',                '(UTC-03:00) Faukland Islands', 2011,8,4,6,0,0, -180, 1 ]/* STD: -240 */,
                [ 'America/Asuncion',                '(UTC-03:00) Asuncion', 2011,9,2,4,0,0, -180, 1 ]/* STD: -240 */,
                [ 'America/Santiago',                '(UTC-03:00) Santiago', 2011,9,9,4,0,0, -180, 1 ]/* STD: -240 */,
                [ 'America/Campo_Grande',            '(UTC-04:00) Brazil-Amazonia', 2011,9,16,4,0,0, -180, 1 ]/* STD: -240 */,
                [ 'America/Miquelon',                '(UTC-03:00) Miquelon, St. Pierre', 2011,2,13,5,0,0, -120, 1 ]/* STD: -180 */,
                [ 'America/Godthab',                 '(UTC-03:00) Greenland', 2011,2,27,1,0,0, -120, 1 ]/* STD: -180 */,
                [ 'America/Argentina/Buenos_Aires',  '(UTC-03:00) Buenos Aires', 2011,0,1,0,0,0, -180, 0 ]/* STD: -180 */,
                [ 'America/Montevideo',              '(UTC-02:00) Montevideo', 2011,9,2,5,0,0, -120, 1 ]/* STD: -180 */,
                [ 'America/Sao_Paulo',               '(UTC-03:00) Brasilia', 2011,9,16,3,0,0, -120, 1 ]/* STD: -180 */,
                [ 'Atlantic/Azores',                 '(UTC-01:00) Azores', 2011,2,27,1,0,0, 0, 1 ]/* STD: -60 */,
                // [ 'Atlantic/Azores',                 '(UTC-01:00) Azores', 2010,2,28,3,0,0, 0, 1 ], /* Windows fix */
                [ 'Atlantic/Cape_Verde',             '(UTC-01:00) Cape Verde Is.', 2011,0,1,0,0,0, -60, 0 ]/* STD: -60 */,
                [ 'Europe/London',                   '(UTC) London, Lisbon, Dublin, Monrovia, Reykjavik', 2011,2,27,1,0,0,      60, 1 ]/* STD: 0 */,
                [ 'Africa/Casablanca',               '(UTC) Casablanca', 2011,0,1,0,0,0,0,0 ]/* STD: 0 */,
                [ 'Europe/Berlin',                   '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', 2011,2,27,1,0,0,      120, 1 ]/* STD: 60 */,
                [ 'Africa/Lagos',                    '(UTC+01:00) West Central Africa', 2011,0,1,0,0,0,60, 0 ]/* STD: 60 */,
                [ 'Africa/Windhoek',                 '(UTC+02:00) Windhoek', 2011,8,4,1,0,0, 120, 1 ]/* STD: 60 */,
                [ 'Asia/Gaza',                       '(UTC+02:00) Gaza', 2011,2,25,22,1,0, 180, 1 ]/* STD: 120 */,
                [ 'Asia/Beirut',                     '(UTC+02:00) Beirut', 2011,2,26,22,0,0, 180, 1 ]/* STD: 120 */,
                [ 'Africa/Johannesburg',             '(UTC+02:00) Harare, Pretoria', 2011,0,1,0,0,0,120,0 ]/* STD: 120 */,
                //[ 'Europe/Istanbul',                 '(UTC+03:00) Instanbul', 2011,2,27,1,0,0, 180, 1 ]/* STD: 120 */,
                [ 'Europe/Helsinki',                 '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', 2011,2,27,1,0,0,      180, 1 ]/* STD: 120 */,
                [ 'Asia/Jerusalem',                  '(UTC+02:00) Jerusalem', 2011,3,1,0,0,0, 180, 1 ]/* STD: 120 */,
                [ 'Africa/Cairo',                    '(UTC+02:00) Cairo', 2011,3,28,22,0,0, 180, 1 ]/* STD: 120 */,
                [ 'Asia/Damascus',                   '(UTC+02:00) Syria', 2011,9,27,21,0,0, 120, 1 ]/* Unexpectedly here winter-offset */,
                [ 'Asia/Amman',                      '(UTC+03:00) Amman', 2011,9,27,22,0,0, 120, 1 ]/* Unexpectedly here winter-offset */,
                [ 'Asia/Baghdad',                    '(UTC+03:00) Nairobi, Baghdad', 2011,0,1,0,0,0,180, 0 ]/* STD: 180 */,
                [ 'Asia/Yerevan',                    '(UTC+04:00) Yerevan', 2011,2,26,22,0,0, 300, 1 ]/* STD: 240 */,
                [ 'Asia/Baku',                       '(UTC+04:00) Baku', 2011,2,27,0,0,0, 300, 1 ]/* STD: 240 */,
                [ 'Asia/Dubai',                      '(UTC+04:00) Abu Dhabi, Muscat', 2011,0,1,0,0,0,240,0 ]/* STD: 240 */,
                [ 'Asia/Tehran',                     '(UTC+04:30) Tehran', 2009,2,21,20,30,0, 270, 1 ]/* STD: 210 */,
                [ 'Asia/Yekaterinburg',              '(UTC+05:00) Ekaterinburg', 2011,2,26,21,0,0, 360, 1 ]/* STD: 300 */,
                [ 'Asia/Karachi',                    '(UTC+05:00) Tashkent', 2011,0,1,0,0,0,300,0 ]/* STD: 300 */,
                [ 'Asia/Kabul',                      '(UTC+05:30) Kabul', 2011,0,1,0,0,0,270,0 ]/* STD: 270 */,
                [ 'Asia/Omsk',                       '(UTC+06:00) Novosibirsk', 2011,2,26,20,0,0, 420, 1 ]/* STD: 360 */,
                [ 'Asia/Dhaka',                      '(UTC+06:00) Astana, Dhaka', 2011,0,1,0,0,0,360,0 ]/* STD: 360 */,
                [ 'Asia/Kolkata',                    '(UTC+06:30) Chennai, Kolkata, Mumbai, New Delhi', 2011,0,1,0,0,0,330,0 ]/* STD: 330 */,
                [ 'Asia/Kathmandu',                  '(UTC+06:45) Kathmandu', 2011,0,1,0,0,0,345,0 ]/* STD: 345 */,
                [ 'Asia/Krasnoyarsk',                '(UTC+07:00) Krasnoyarsk', 2011,2,26,19,0,0, 480, 1 ]/* STD: 420 */,
                [ 'Asia/Jakarta',                    '(UTC+07:00) Bangkok, Hanoi, Jakarta', 2011,0,1,0,0,0,420,0 ]/* STD: 420 */,
                [ 'Asia/Rangoon',                    '(UTC+07:30) Yangon (Rangoon)', 2011,0,1,0,0,0,390,0 ]/* STD: 390 */,
                [ 'Asia/Irkutsk',                    '(UTC+08:00) Irkutsk, Ulaan Bataar', 2011,2,26,18,0,0, 540, 1 ]/* STD: 480 */,
                [ 'Asia/Shanghai',                   '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi, Perth', 2011,0,1,0,0,0,480, 0 ]/* STD: 480 */,
                [ 'Asia/Yakutsk',                    '(UTC+09:00) Yakutsk', 2011,2,26,17,0,0, 600, 1 ]/* STD: 540 */,
                [ 'Asia/Tokyo',                      '(UTC+09:00) Osaka, Sapporo, Tokyo, Seoul', 2011,0,1,0,0,0,540, 0 ]/* STD: 540 */,
                [ 'Australia/Eucla',                 '(UTC+09:45) Eucla', 2011,0,1,0,0,0,525,0 ]/* STD: 525 */,
                [ 'Asia/Vladivostok',                '(UTC+10:00) Vladivostok', 2011,2,26,16,0,0, 660, 1 ]/* STD: 600 */,
                [ 'Australia/Brisbane',              '(UTC+10:00) Brisbane', 2011,0,1,0,0,0,600,0 ]/* STD: 600 */,
                [ 'Australia/Darwin',                '(UTC+10:30) Darwin', 2011,0,1,0,0,0,570,0 ]/* STD: 570 */,
                [ 'Australia/Lord_Howe',             '(UTC+11:00) Lord Howe Island', 2011,9,1,15,30,0, 660, 1 ]/* STD: 630 */,
                [ 'Australia/Sydney',                '(UTC+11:00) Sydney, Hobart', 2011,9,1,16,0,0, 660, 1 ]/* STD: 600 */,
                [ 'Asia/Kamchatka',                  '(UTC+11:00) Kamchatka', 2011,2,26,15,0,0, 720, 1 ]/* STD: 660 */,
                [ 'Pacific/Noumea',                  '(UTC+11:00) Solomon Is., New Caledonia', 2011,0,1,0,0,0,660,0 ]/* STD: 660 */,
                [ 'Australia/Adelaide',              '(UTC+11:30) Adelaide', 2011,9,1,16,30,0, 630, 1 ]/* STD: 570 */,
                [ 'Pacific/Fiji',                    '(UTC+12:00) Anadyr, Kamchatka', 2011,2,5,14,0,0, 720, 1 ]/* STD: 660 */,
                [ 'Pacific/Fiji',                    '(UTC+12:00) Anadyr, Kamchatka', 2011,2,26,14,0,0, 720, 1 ]/* STD: 660 */,
                [ 'Pacific/Tarawa',                  '(UTC+12:00) Tarawa', 2011,0,1,0,0,0,720,0 ]/* STD: 720 */,
                [ 'Pacific/Norfolk',                 '(UTC+12:30) Norfolk Island', 2011,0,1,0,0,0,690,0 ]/* STD: 690 */,
                [ 'Pacific/Auckland',                '(UTC+13:00) Auckland, Wellington', 2011,8,24,14,0,0, 780, 1 ]/* STD: 720 */,
                [ 'Pacific/Tongatapu',               '(UTC+13:00) Nuku Alofa', 2011,0,1,0,0,0,780,0 ]/* STD: 780 */,
                [ 'Pacific/Kiritimati',              '(UTC+14:00) Kiritimati', 2010,0,1,0,0,0, 840, 0, '(LINT)' ], /* To prevent Firefox detecting Pacific/Kiritimati as Pacific Honolulu */
                // [ 'Pacific/Kiritimati',              '(UTC+14:00) Kiritimati', 2011,0,1,0,0,0,840,0 ],/* STD: 840 */
                [ 'Pacific/Chatham',                 '(UTC+14:45) Chatham Islands', 2011,8,24,14,0,0, 825, 1 ]/* STD: 765 */,
            ];
        }


        /**
        * detect()
        * Detects timezone in the browser and returns Olson ID, like "Europe/Berlin" or "Asia/Theran"
        *
        * @returns string
        */
        var detect = function() {
            var tz = '';

            var zones = getTimezoneList();

            for (var i in zones) {
                olson_name = zones[i][0];
                has_transitions = zones[i][9];

                date_1 = new Date(Date.UTC(zones[i][2],zones[i][3],zones[i][4],zones[i][5],zones[i][6],zones[i][7]));
                date_1_offset_data = zones[i][8];
                date_1_offset_user = -date_1.getTimezoneOffset();

                date_2 = new Date(Date.UTC(zones[i][2],zones[i][3],zones[i][4],zones[i][5],zones[i][6],zones[i][7]) - 1000);
                date_2_offset_user = -date_2.getTimezoneOffset();

                var patt1=/\([^)]+\)/g;
                if (zones[i][10] != undefined) {
                    var abbr = zones[i][10]; // To determine Pacific/Kiritimati in Firefox
                } else {
                    var abbr = '';
                }

                if (
                    abbr == date_1.toString().match(patt1)
                    // Detect timezones that has transitions
                    || (has_transitions &&  date_1_offset_user == date_1_offset_data && date_2_offset_user != date_1_offset_data)
                    // Detect timezones that has not transitions
                    || (!has_transitions && date_1_offset_data == date_1_offset_user)
                )
                {
                    tz = olson_name;
                    return tz;
                }
            }
        }

        /**
        * convert()
        * Converts a timestamp from one timezone, to another
        *
        * @param timestamp
        * @param timezone
        * @param sourceTimezone
        */
        var convert = function(timestamp, timezone, sourceTimezone) {
            // default source timezone is always 'Europe/Berlin'
            sourceTimezone = typeof sourceTimezone !== 'undefined' ? sourceTimezone : raceBetsJS.time.timezone.detect();

            // create Date() object with desired source timezone
            var dateObj = new timezoneJS.Date(Math.round(timestamp * 1000), timezone);

            // Fix bug for some browsers (like Firefox 3.x)
            /*
            if (isNaN(dateObj.getDay())) {
                var i = 0;
                while (isNaN(dateObj.getDay())) {
                    if (i > 10) { // max 10 tries, then throw exception
                        throw 'Please reload the page and try again.';
                    }

                    dateObj = new timezoneJS.Date(Math.round(timestamp * 1000), sourceTimezone);
                    i++;
                }
            }
            */

            if (isNaN(timestamp)) {
                throw 'Invalid timestamp, cannot convert NaN to timestamp!';
            }

            // set destination timezone
            var timezoneOffset = dateObj.getTimezoneOffset() * 60;

            if (timezone !== sourceTimezone) {
                // in case the user wants to display a timezone different from the one set on his computer
                dateObj.setTimezone(sourceTimezone)
                var timezoneOffsetSource = dateObj.getTimezoneOffset() * 60;
                return Math.round(timestamp + (timezoneOffsetSource - timezoneOffset));

            } else {

                // don't modify anything
                return timestamp;
            }
        }

        /**
        * toBerlin()
        * Alias of convert() for quicker conversion to base time (Europe/Berlin)
        *
        * @param timestamp
        * @param sourceTimezone
        */
        var toBerlin = function(timestamp, sourceTimezone) {
            //return timestamp;
            return convert(timestamp, 'Europe/Berlin', sourceTimezone);
        }

        var validateTimezone = function(timezone) {
            var arr = getTimezoneList();
            for(var tz in arr) {
                if (arr[tz][0] === timezone) {
                    return true;
                }
            }
            return false;
        }
        // @public
        return {
            detect: detect,
            convert: convert,
            toBerlin: toBerlin,
            getTimezoneList: getTimezoneList,
            validateTimezone: validateTimezone
        };
    }();
})(raceBetsJS);
