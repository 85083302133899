/**
* Accumulation Betslip Templates
*
*/
(function(raceBetsJS) {
    /**
    * Main Template
    */
    raceBetsJS.application.templates.accBetslip = _.template(
    '<div id="acc-betslip" class="rounded panel new <%= pick ? "pick" : "accumulator" %> swapped">'+
            '<div class="header_container">'+
                '<h2><span class="text"><%= raceBetsJS.i18n.data[!pick ? "headAccBetslip" : "headPickBetslip"] %></span></h2>'+
                '<span class="close"></span>'+
            '</div>'+
            '<div class="content options">'+
                '<div class="bet-type clearfix">'+
                    '<label><%= raceBetsJS.i18n.data.labelBetType %></label>'+
                    '<select name="betType">'+
                        '<% if (!pick) { %>'+
                            '<option value="WIN"><%= raceBetsJS.i18n.data.betTypeWIN %></option>'+
                            '<option value="PLC"><%= raceBetsJS.i18n.data.betTypePLC %></option>'+
                            '<option value="WP"><%= raceBetsJS.i18n.data.betTypeWP %></option>'+
                            '<option value="ITA"><%= raceBetsJS.i18n.data.betTypeITA %></option>'+
                            '<option value="TRT"><%= raceBetsJS.i18n.data.betTypeTRT %></option>'+
                        '<% } else { %>'+
                            '<% if (!$.isEmptyObject(pick.types)) { %>'+
                                '<% var pickTypes = []; %>'+
                                '<% if (pick.types.TOT) { %>'+
                                    '<% pickTypes = $.map(pick.types.TOT, function(val, index) { return [index] }) %>'+
                                '<% } else if (pick.types.BOK) { %>'+
                                    '<% pickTypes = $.map(pick.types.BOK, function(val, index) { return [index] }) %>'+
                                '<% } %>'+
                                '<% $.each(pickTypes, function(index, pickType) { %>'+
                                    '<option value="<%= pickType %>"><%= raceBetsJS.format.betTypeName(pickType, (pick.types.TOT) ? "TOT" : "BOK", raceBetsJS.application.user.lang, false, pick.country) %></option>'+
                                '<% }) %>'+
                            '<% } %>'+
                        '<% } %>'+
                    '</select>'+
                '</div>'+
                '<div class="stake clearfix">'+
                    '<label><%= raceBetsJS.i18n.data.labelStake %></label>'+
                    '<select name="unitStake">'+
                        '<% if (!pick) { %>'+
                            '<% $.each(raceBetsJS.application.assets.settings.get().dialog.betslip.stakes[raceBetsJS.application.user.currency], function() { %>'+
                                '<option value="<%= this %>"><%= raceBetsJS.format.money(this, 2, raceBetsJS.application.user.currency) %></option>'+
                            '<% }); %>'+
                        '<% } %>'+
                    '</select>'+
                '</div>'+
                '<% if (!pick) { %>'+
                    '<div class="system clearfix">'+
                        '<label><%= raceBetsJS.i18n.data.labelAccSystem %></label>'+
                        '<select name="system">'+
                            '<option value=""><%= raceBetsJS.i18n.data.systemNone %></option>'+
                        '</select>'+
                    '</div>'+
                '<% } %>'+
            '</div>'+
            '<div class="content legs">'+
                '<div class="legs-container">'+
                    '<div class="content no-leg"><%= raceBetsJS.i18n.data.accBetslipInitText %></div>'+
                '</div>'+
            '</div>'+
            '<div class="content footer">'+
                    '<div class="summary">'+
                    '<div class="status valid"></div>'+
                    '<div class="diff-currency-info"></div>'+
                    '<div class="info">'+
                        '<span class="num-bets"></span><br />'+
                        '<span class="total-stake"></span>'+
                    '</div>'+
                '</div>'+
                '<% if (raceBetsJS.application.globals.brandName === "racebets" && raceBetsJS.application.user.country === "GB") { %>'+
                    '<p class="bonus-money-info"><%= raceBetsJS.i18n.data.msgBonusMoneyInfo %></p>'+
                    '<p class="bonus-money-details" style="display: none;"><%= raceBetsJS.i18n.print("textBonusMoneyInfo", { url: raceBetsJS.application.assets.settings.getBrandSettings("supportURLs", "bonuses") }) %></p>'+
                '<% } %>'+
                '<button type="submit" class="submit c-btn c-btn--primary c-btn--l"><%= raceBetsJS.i18n.data.buttonPlaceBet %></button>'+
            '</div>'+
        '</div>'
    );

    /**
    * Content Leg (race)
    */
    raceBetsJS.application.templates.accBetslip.leg = _.template('\
        <div class="content leg" data-race="<%= race.idRace %>">\
            <div class="race clearfix">\
                <span class="leg-no"></span>\
                <a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= race.idRace %>/" class="title ajaxify">\
                    <span class="raceTitle"><%= race.event %></span>\
                    <% if (!race.isAntePost) { %>\
                     | <%= raceBetsJS.i18n.print("abbrRaceNumberX", { race: race.raceNumber }) %>\
                    <% } %>\
                </a>\
                <span class="remove race"></span>\
            </div>\
            <% /* %><div class="row numbers"></div><% */ %>\
        </div>\
    ');

    /**
    * Runner Row
    */
    raceBetsJS.application.templates.accBetslip.runner = _.template('\
        <div class="row runner clearfix<%= (_.size(runner.odds) === 1 ? " single" : "") %>" data-runner="<%= runner.idRunner %>">\
            <span class="name"><%= runner.name %> <span class="light-grey">(<%= runner.programNumber %>)</span></span>\
            <span class="remove runner"></span>\
            <select class="bet-category">\
                <% $.each(runner.odds, function(cat, odds) { %>\
                    <% if (cat === "FXP") { return } %>\
                    <option value="<%= cat %>"><%= (cat === "PRC" ? "SP" : raceBetsJS.format.odds(odds)) %></option>\
                <% }); %>\
            </select>\
        </div>\
    ');

    /**
    * Runner Row
    */
    raceBetsJS.application.templates.accBetslip.runner.H2H = _.template('\
        <div class="row runner h2h clearfix" data-runner="<%= runner.idRunner %>">\
            <span class="name"><%= runner.name %></span>\
            <span class="odds">@ <%= raceBetsJS.format.odds(runner.odds.FXW) %></span>\
            <% _.each(runner.opponents, function(opponent) { %>\
                <br clear="left" />\
                <span class="opponent"><%= raceBetsJS.i18n.data.headToHeadVs %> <%= opponent.name %></span>\
            <% }) %>\
        </div>\
    ');

})(raceBetsJS);
