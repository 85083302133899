/**
* Homepage "Upcoming Races" module
*
* @author David Calleja
*/
(function(raceBetsJS) {
	raceBetsJS.application.content.home.nextRaces = function() {

		// @private
		var panel_template = raceBetsJS.application.templates.panel,
			content_template = raceBetsJS.application.templates.home.nextRaces,
			showcase_template = raceBetsJS.application.templates.home.nextRaces.showcase,
			race_template = raceBetsJS.application.templates.home.nextRaces.race,
			panel_data = {
				'title': '',
				'cssClass':'c-widget--upcomingRaces showcase with-rows',
				'id':'upcoming-races',
				'more':null
			},
			content_area_selector = '.content_container',
			current_selected_item = 0,
			el = null,
			data = {};

		var render = function(area){

			var content = $('<div>').append(content_template(data)),
				refreshing = false;

			if(el == null){
				area.append(panel_template($.extend(panel_data, {'content': $('<div>').append( content.eq(0).clone() ).html() })));
				el = area.find('#' + panel_data.id).find(content_area_selector);
			} else {
				refreshing = true;
				el.empty();
				el.append(content);
			}

			$.each(
				data,
				function(i, race){
					var li = $('<li>'),
						currentDate = new Date();
					li.data('showcase', race);
					li.addClass('race-type-' + race.raceType.toLowerCase());
					li.attr('data-item', i);
					if(i%2 == 0){
						li.addClass('even');
					}
					if(i == 0){
						li.addClass('first active');
					}
					if(i == data.length - 1){
						li.addClass('last');
					}
					li.append(race_template(race));
					li.find('.time').text( raceBetsJS.time.toString( Math.ceil((race.raceTime * 1000 - (currentDate.getTime() - (currentDate.getSeconds() * 1000) - raceBetsJS.application.user.timeDiff)) / 1000), true ) );
					el.find('ul.races').append(li);
				}
			);

			if(!refreshing){
				eventsInit();
			}

			activateShowcase(el.find('ul.races').find('li').get(current_selected_item));
		};

		var eventsInit = function(){
            if(raceBetsJS.browser.type.isiPad() || raceBetsJS.browser.type.isiPhone()){
            	el.find('ul.races').find('a.ajaxify').removeClass('ajaxify');
				el.on('click', 'ul.races li a', function(e) {
					e.preventDefault();
					activateShowcase($(this).parents('li').get(0));
				});
            } else {
				el.on('mouseenter', 'ul.races li', function(e) {
					e.preventDefault();
					activateShowcase(this);
				});
            }
			el.on('mouseup', '.odds-button.fixed.enabled', function(e) {
				e.preventDefault();
				raceBetsJS.application.assets.bettingDialog.show(
					this,
					{
						onOddsChange: $.proxy(
							function(newOdds) {
								replaceOdds(el.find('.odds-button[data-id-race="' + $(this).data('id-race') + '"]'));
							},
							this
						)
					}
				);
			});
			el.on('mouseup', '.odds-button.price.enabled', function() {
				raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + $(this).attr("data-id-race"));
			});
		};

		var replaceOdds = function(elements){
			var ids = [];
			$.each(
				elements,
				function(){
					ids.push($(this).data('id-runner'));
				}
			);
			$.post(
				'/ajax/races/odds',
				{'runners':ids.join(',')},
				function(data){
					$.each(
						data,
						function(runner,odds){
							var element = el.find('.odds-button[data-id-runner="' + runner + '"]').find('span');
							element.html(raceBetsJS.format.odds(odds, null, true));
							element.data('odds', odds);
						}
					)
				}
			);
		};

		var createOddsButtons = function(){
			$.each(
				el.find('.showcase').find('.odds-button'),
				function(){
					var fixedOdds = $(this).hasClass('fixed'),
						button = null;

					if(fixedOdds){
						button = new raceBetsJS.application.content.race.OddsButton($(this), 'FXW');
					} else {
						button = new raceBetsJS.application.content.race.OddsButton($(this), 'PRC');
					}
					button.setOdds($(this).children('span').data('odds'), $(this).hasClass('enabled'));
				}
			);
		};

		var activateShowcase = function(button){
            if (!el) {
                return;
            }
			var position = $(button).position().top + 1,
				link = el.find('.showcase').find('.link'),
				showSilks = true;

			$(button).addClass('active');
			$(button).siblings('li').removeClass('active');
			link.css('top', position + 'px');

			$.each(
				$(button).data('showcase').topRunners,
				function(){
					if(this.silkExtension === "" || this.silkExtension === null){
						showSilks = false;
					}
				}
			);

			el.find('.showcase').find('.container').html(showcase_template($.extend($(button).data('showcase'),{'showSilks':showSilks})));
			current_selected_item = $(button).attr('data-item');
			createOddsButtons();
		};


		var remove = function(){
			el = null;
			current_selected_item = 0;
		};

		var setData = function(json){
			data = json;
		};

		// @public
		return {
			init: function(json) {
				if(json != null && !_.isEmpty(json)){
					setData(json);

					//set translations
					panel_data.title = raceBetsJS.i18n.data.headNextRaces;

					return this;
				} else if(el != null){
					return this;
				}
				return false;
			},
			render: render,
			remove: remove,
			el:el
		};
	}();
})(raceBetsJS);
