/**
* Settings module
*
* @author Robin Ebers
*/

(function (raceBetsJS) {

    /**
    * Settings Dialog
    */
    raceBetsJS.application.assets.settings = (function () {
        var cookieName = 'rbset';
        var cookieConsentName = 'CONSENT';
        var languageSelect;
        var commonCountries = [
            'AE', 'AR', 'AT', 'AU', 'BE', 'CA', 'CH', 'CL', 'CU', 'CZ', 'DE', 'DK', 'ES', 'FI', 'BR',
            'FR', 'GB', 'HK', 'HU', 'IE', 'IN', 'IT', 'JP', 'MO', 'MU', 'NL', 'NO', 'PL', 'PR', 'QA',
            'SE', 'SG', 'SK', 'TR', 'US', 'VN', 'ZA', 'ZW'
        ];

        var xtremePushCookieName = 'xtrpush3';

        var applyNewStakes = function (stakeButton) {
            var newStake = $('#settings-default-stake-input')
                                .val()
                                .replace(/[a-z ]+/i, '')
                                .replace(',', '.');

            stakeButton.data('stake', newStake).html(raceBetsJS.format.number(
                newStake,
                2,
                raceBetsJS.application.globals.decimalSeparator,
                ''
            )).removeClass('active');

            raceBetsJS.application.assets.overlay.close();
        };

        var show = function (dialogOptions) {
            var options = get().dialog;
            var dialogTitle = $(raceBetsJS.application.templates.settings.tabSettings());
            var dialog = $(raceBetsJS.application.templates.settings.content({ data: options }));
            var consent = getConsent();

            if(_.isNull(consent)) {
                saveConsent('false');
                consent = {marketing: 0};
            }

            // show overlay with dialog content
            raceBetsJS.application.assets.modalDialog.show({
                title: dialogTitle, //raceBetsJS.i18n.data.headSettings
                content: dialog,
                buttons: [
                    {
                        label: raceBetsJS.i18n.data.buttonCancel,
                        id: 'settings-dialog-close',
                        action: function () {
                            raceBetsJS.application.assets.overlay.close();
                        }
                    },
                    {
                        label: raceBetsJS.i18n.data.buttonSave,
                        id: 'settings-dialog-apply',
                        active: true,
                        action: function () {
                            raceBetsJS.application.assets.overlay.showPleaseWait();
                            var settings = buildJson();

                            if (raceBetsJS.application.globals.gdpr && !raceBetsJS.application.user.loggedIn) {
                                var consentSetting = $('#settings-tab-cookie input[name="settings[advertisingCookies]"]:checked').val();
                                if (consentSetting) {
                                    saveConsent(consentSetting);
                                }
                            }
                            // save settings
                            save(settings, function () {
                                window.location.reload();
                            });
                        }
                    }
                ],
                buttonAlign: 'apart',
                className: 'dialog-settings',
                width: raceBetsJS.application.user.lang === 'pt' ? 680 : 560
            });

            // tabs
            dialogTitle.on('click', '.c-btn', function (e) {
                e.preventDefault();
                var $this = $(this);
                dialogTitle.find('.isSelected').removeClass('isSelected');
                $this.addClass('isSelected');
                dialog.find('.c-tab__content.isSelected').removeClass('isSelected');
                dialog.find($this.attr('href')).addClass('isSelected');
                if( $('.overlay').height() < $('.modal-dialog').height() ){
                    raceBetsJS.browser.crossFrame.send('height', $('.modal-dialog').height() + 50);
                }
            });

            // default tab
            if (dialogOptions && dialogOptions.activeTab) {
                // tabs.activateTab($(tabs.tabs[dialogOptions.activeTab]));
                dialogTitle.find('.c-btn').eq(dialogOptions.activeTab).click();
            }

            // sortable connected list
            $('#settings-countries-source, #settings-countries-sorted').sortable({
                    items: 'li',
                    opacity: 0.8,
                    revert: true,
                    connectWith: '.settings-country-sort'
                })
                .sortable('option', 'connectWith', '.settings-country-sort') // apparently increases performance if done here
                .disableSelection();

            // country select box ("most common", "all countries")
            var countriesSource = dialog.find('#settings-countries-source');
            $('#setting-countries-select').change(function () {
                var selection = $(this).val(),
                    label = $(this).find('option:selected').text();

                $(this).next('.c-select__value').text(label);

                if (selection === 'common') {
                    countriesSource.addClass('common');
                } else {
                    countriesSource.removeClass('common');
                }
            });

            // default stakes
            dialog.on('click', '.default-stakes .stake', function () {
                var stakeButton = $(this);
                var stake = stakeButton.data('stake');

                stakeButton.addClass('active');

                var stakeDialog = $('<div>', { 'class': 'c-form__group' });
                stakeDialog.append($('<label/>', { 'class': 'c-form__label', 'for': 'settings-default-stake-input' })
                    .html(raceBetsJS.i18n.data.labelNewStake));

                var stakeInput = $('<input type="number" class="c-input c-input--number">')
                        .attr('id', 'settings-default-stake-input')
                        .val(
                            raceBetsJS.format.number(stake, 2, raceBetsJS.application.globals.decimalSeparator, '')
                        )
                        .keydown(function (e) {
                            if (e.which === 13) {
                                applyNewStakes(stakeButton);
                            }
                        });

                stakeDialog.append(stakeInput);
                raceBetsJS.application.assets.modalDialog.show({
                    width: 230,
                    title: raceBetsJS.i18n.data.titleDefaultStakesDialog,
                    className: 'default-stake-dialog',
                    content: stakeDialog,
                    buttons: [
                        {
                            label: raceBetsJS.i18n.data.buttonCancel,
                            action: function () {
                                raceBetsJS.application.assets.overlay.close();
                                stakeButton.removeClass('active');
                            }
                        },
                        {
                            label: raceBetsJS.i18n.data.buttonSave,
                            action: function () {
                                applyNewStakes(stakeButton);
                            },
                            active: true
                        }
                    ]
                });

                $('#settings-default-stake-input').focus().select();
            });

            if (raceBetsJS.application.user.loggedIn) {
                dialogTitle.on('click', '[data-content-id="settings-tab-cookie"]', function(e) {
                    e.preventDefault();
                    raceBetsJS.application.assets.overlay.close();
                    raceBetsJS.browser.history.navigateTo('/account/marketing');
                });
            }

            // language select
            languageList = dialog.find('div.rb-select.language select').first();
            $.each(raceBetsJS.application.globals.languages, function (iso2, name) {
                languageList.append(
                    '<option value="' + iso2 + '" data-flag="' + raceBetsJS.i18n.languageToFlag(iso2) + '">' + name + '</option>'
                );
            });

            languageSelect = new raceBetsJS.application.assets.Dropdown(languageList, {
                width: 158,
                selected: options.general.language,
                sortOptions: true
            });

            // fill all countries
            $.each(raceBetsJS.i18n.data.countries, function (iso2, name) {
                countriesSource.append(
                    '<li data-country="' + iso2 + '" class="' +
                        (($.inArray(iso2, commonCountries) > -1) ? 'common' : '')  +
                        '"><span class="c-flag isCountry' + iso2.toUpperCase() + '"></span>' + name + '</li>'
                );
            });

            // selected timezone
            $('#settings-timezone').val(raceBetsJS.application.assets.settings.get().dialog.general.timezone);

            /**
            * Select Current Settings
            */
            var countriesSorted = $('#settings-countries-sorted');
            $.each(options.countries.order, function () {
                countriesSorted.append(
                    '<li data-country="' + this + '" class="' + (($.inArray(this.toString(), commonCountries) > -1) ? 'common' : '') + '">' +
                        '<span class="c-flag isCountry' + this.toUpperCase() + '"></span>' +
                        raceBetsJS.i18n.data.countries[this] + '</li>'
                );
                countriesSource.children('[data-country="' + this + '"]').remove();
            });
            dialog.find('#settings-odds-format-' + options.general.oddsFormat).prop('checked', true);
            dialog.find('#settings-units-' + options.general.unitSystem).prop('checked', true);
            dialog.find('#settings-dogs-' + options.general.hideDogs).prop('checked', true);
            dialog.find('#settings-streams-popup-' + options.general.popupStreams).prop('checked', true);
            dialog.find('#settings-close-countries-' + options.general.closeCountries).prop('checked', true);
            dialog.find('#settings-betslip-confirmation-' + options.betslip.confirmation).prop('checked', true);

            if (raceBetsJS.application.globals.gdpr && !raceBetsJS.application.user.loggedIn) {
                dialog.find('#settings-advertising-cookies-' + (consent.marketing === 1 ? 'on' : 'off')).prop('checked', true);
            }
        };

        var buildJson = function () {
            var settings = {};

            // country order
            var countryOrder = [];
            $('#settings-countries-sorted li').each(function () {
                countryOrder.push($(this).data('country'));
            });

            if (countryOrder.length > 0) {
                settings.countries = {};
                settings.countries.order = countryOrder;
            }

            // betslip preselection and confirmation
            var confirmation = $('#settings-tab-betslip input[name="settings[betslip-confirmation]"]:checked').val();

            // default stakes
            settings.betslip = {};
            settings.betslip.stakes = {};
            $('#settings-tab-betslip .default-stakes ul').each(function () {
                var currency = $(this).data('currency');
                settings.betslip.stakes[currency] = {};

                $(this).children('.stake').each(function () {
                    if (raceBetsJS.application.globals.defaultSettings.dialog.betslip.stakes[currency][$(this).data('slot')] != $(this).data('stake')) {
                        settings.betslip.stakes[currency][$(this).data('slot')] = parseFloat(raceBetsJS.format.number($(this).data('stake'), 2, '.', ''));
                    }
                });

            });

            if (raceBetsJS.application.globals.defaultSettings.dialog.betslip.confirmation != stringToBoolean(confirmation)) {
                settings.betslip.confirmation = stringToBoolean(confirmation);
            }

            var oddsFormat = $('#settings-tab-format input[name="settings[odds-format]"]:checked').val();
            var unitSystem = $('#settings-tab-format input[name="settings[unit-system]"]:checked').val();
            var popupStreams = $('#settings-tab-general input[name="settings[popup-streams]"]:checked').val();
            var closeCountries = $('#settings-tab-general input[name="settings[close-countries]"]:checked').val();

            if(raceBetsJS.application.globals.isB2B) {
                var timezone = raceBetsJS.time.timezone.detect();
                var language = raceBetsJS.application.user.lang;
            } else {
                var timezone = $('#settings-tab-general select#settings-timezone').val();
                var language = languageSelect.val();
            }

            settings.general = {};
            settings.general.language = language;

            var currency = raceBetsJS.application.user.currency;
            settings.general.currency = currency;

            settings.general.oddsFormat = oddsFormat;

            if (raceBetsJS.application.globals.defaultSettings.dialog.general.popupStreams !== stringToBoolean(popupStreams)) {
                settings.general.popupStreams = stringToBoolean(popupStreams);
            }

            if (
                raceBetsJS.application.globals.websiteTLD !== 'de' && 
                raceBetsJS.application.globals.brandName !== 'suaposta' && 
                ['betssonarbacity', 'betssonarbaprovince'].indexOf(raceBetsJS.application.globals.brandChildName) === -1
            ) {
                var hideDogs = $('#settings-tab-general input[name="settings[dogs]"]:checked').val();
                if (raceBetsJS.application.globals.defaultSettings.dialog.general.hideDogs !== stringToBoolean(hideDogs)) {
                    settings.general.hideDogs = stringToBoolean(hideDogs);
                }
            }

            if (raceBetsJS.application.globals.defaultSettings.dialog.general.closeCountries !== stringToBoolean(closeCountries)) {
                settings.general.closeCountries = stringToBoolean(closeCountries);
            }

            if (raceBetsJS.application.globals.defaultSettings.dialog.general.unitSystem !== unitSystem) {
                settings.general.unitSystem = unitSystem;
            }

            // ALWAYS save timezone
            settings.general.timezone = timezone;

            cookieJson = get();

            if (cookieJson.dialog.general.hideBalance) {
                settings.general.hideBalance = true;
            }

            cookieJson.dialog = settings;

            if (cookieJson.dialog.betslip && cookieJson.dialog.betslip.stakes) {
                var currencies = Object.keys(raceBetsJS.application.globals.defaultSettings.dialog.betslip.stakes);
                _.each(currencies, function(currency) {
                    if (cookieJson.dialog.betslip.stakes.hasOwnProperty(currency) && _.isEmpty(cookieJson.dialog.betslip.stakes[currency])) {
                        delete cookieJson.dialog.betslip.stakes[currency]
                    }
                })
            }

            var json = $.stringify(cookieJson);

            return json;
        };

        var get = function (raw) {
            try {
                // in case of UK / IE customers, set fractional odds, lbs etc instead of PROPER standards
                var cookieJson = $.parseJSON($.cookie(cookieName));
                cookieJson = (cookieJson === null) ? {'dialog':{'general':{'language': raceBetsJS.application.user.lang}}} : cookieJson;

                if ($.inArray(raceBetsJS.application.user.country, ['GB', 'IE']) > -1) {
                    if (cookieJson.dialog.general.oddsFormat === undefined) {
                        raceBetsJS.application.globals.defaultSettings.dialog.general.oddsFormat = 'fractional';
                    }

                    if (cookieJson.dialog.general.unitSystem === undefined) {
                        raceBetsJS.application.globals.defaultSettings.dialog.general.unitSystem = 'imperial';
                    }
                }

                if (
                    raceBetsJS.application.globals.brandName === 'suaposta' || 
                    ['betssonarbacity', 'betssonarbaprovince'].indexOf(raceBetsJS.application.globals.brandChildName) > -1
                ) {
                    raceBetsJS.application.globals.defaultSettings.dialog.general.hideDogs = true;
                }

                // set default timezone
                raceBetsJS.application.globals.defaultSettings.dialog.general.timezone = raceBetsJS.time.timezone.detect();

                return $.extend(
                    true,
                    {},
                    (raw ? {} : raceBetsJS.application.globals.defaultSettings),
                    cookieJson
                );
            } catch (e) {
                // overwrite corrupt cookie with default options
                var cookie = {'dialog':{'general':{'language': raceBetsJS.application.user.lang}}};

                set(cookie);

                // show error message
                $.ajax({
                    type: 'post',
                    url: '/ajax/customer/updatesettings/',
                    data: {
                        settings: $.stringify(cookie)
                    }
                });
                // raceBetsJS.application.assets.modalDialog.show({
                //     type: 'error',
                //     width: 400,
                //     title: raceBetsJS.i18n.data.anErrorOccurred,
                //     content: raceBetsJS.i18n.data.errorSettingsCorrupt,
                //     buttons: [
                //         {
                //             label: raceBetsJS.i18n.data.buttonOK,
                //             action: function () {
                //                 $.ajax({
                //                     type: 'post',
                //                     url: '/ajax/customer/updatesettings/',
                //                     data: {
                //                         settings: $.stringify(cookie)
                //                     },
                //                     beforeSend: function () {
                //                         raceBetsJS.application.assets.overlay.showPleaseWait();
                //                     },
                //                     complete: function () {
                //                         raceBetsJS.application.assets.overlay.close();
                //                     }
                //                 });
                //             }
                //         }
                //     ]
                // });

                // return default cookie if above didn't work out
                return get(raw);
            }
        };

        var set = function (json) {
            $.cookie(cookieName, $.stringify(json), {
                secure: true,
                path: '/',
                domain: getCookieDomain()
            });
        };

        var getCookieDomain = function () {
            // if IP, just return IP
            if (document.location.hostname.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)) {
                return document.location.hostname;
            } else {
                // if not, return the domain name, replace www., www1., www2. etc
                return '.' + document.location.hostname;
            }
        };

        var getTldCookieDomain = function () {
            var parts = location.hostname.split('.');
            return parts[0] && parts[0] === 'localhost' ? parts[0] : '.' + (parts[parts.length - 2].match(/^co(m?)$/) ? parts.slice(parts.length - 3) : parts.slice(parts.length - 2)).join('.');
        };

        var save = function (json, callback) {
            if (json === undefined) {
                var json = $.cookie(cookieName);
            }

            $.ajax({
                type: 'post',
                url: '/ajax/customer/updatesettings/',
                data: {
                    settings: json
                },
                complete: function () {
                    if (callback !== undefined) {
                        callback();
                    }
                }
            });
        };

        var getConsent = function () {
            var consentCookie = $.cookie(cookieConsentName);
            if (consentCookie) {
                try {
                    var consent = $.parseJSON(consentCookie);
                    return consent;
                } catch (e) {
                    return null;
                }
            }
            return null;
        }

        var saveConsent = function (marketing) {
            var consent = getConsent();
            if (consent) {
                consent.marketing = +(marketing === 'true');
            } else {
                consent = {marketing: +(marketing === 'true')};
            }
            var date = new Date();
            date.setYear(date.getFullYear() + 2);
            $.cookie(cookieConsentName, JSON.stringify(consent), {
                secure: true,
                path: '/',
                domain: getTldCookieDomain(),
                expires: date
            });

            // Track event
            if (consent.marketing === 1) {
                dataLayer.push({
                    'event': 'MarketingConsent'
                });
            }
        }

        var status = function (namespace, name, value) {
            var cookie = raceBetsJS.application.assets.settings.get(true);

            // set status is set
            if (value !== undefined) {
                if (cookie[namespace] === undefined) {
                    cookie[namespace] = {};
                }
                cookie[namespace][name] = value;

                // rewrite cookie
                raceBetsJS.application.assets.settings.set(cookie);
            }

            // return status
            if (cookie[namespace] === undefined) {
                return false;
            } else if (cookie[namespace] !== undefined && cookie[namespace][name] === undefined) {
                return false;
            } else {
                return cookie[namespace][name];
            }
        };

        var stringToBoolean = function (string) {
            switch (string.toLowerCase()) {
                case 'true': case 'yes': case '1': return true;
                case 'false': case 'no': case '0': case null: return false;
                default: return Boolean(string);
            }
        };

        var getBrandSettings = function (group, subgroup) {
            var lang = raceBetsJS.application.user.lang;
            var parentBrand = raceBetsJS.application.globals.brandName;
            var childBrand = raceBetsJS.application.globals.brandChildName;
            var brandSettings = raceBetsJS.application.globals.brandSettings[childBrand] || raceBetsJS.application.globals.brandSettings[parentBrand];

            if (group) {
                var groupSettings = brandSettings[group] || raceBetsJS.application.globals.brandSettings.racebets[group];

                if (subgroup) {
                    var subgroupSettings = groupSettings[subgroup];

                    // localised setting groups
                    if (['bettingRules', 'rule4', 'nonRunnerNoBet', 'offers', 'contact', 'bonuses'].indexOf(subgroup) > -1) {
                        return _.has(subgroupSettings, lang) ? subgroupSettings[lang] : subgroupSettings.en;
                    }

                    return subgroupSettings;
                }

                return groupSettings;
            }

            return brandSettings;
        };

        var initEvents = function() {
            var $footer = $('#footer'),
                // $wrapper = $('#wrapper'),
                $headerSettings = $('#m-header__settings'),
                $footerLangSel = $footer.find('.select-language'),
                $footerLangSelOptions = $footerLangSel.find('option'),
                $footerLangSelSelect = $footerLangSel.find('select');

            $headerSettings.on('click', function(e) {
                e.preventDefault();
                show();
            });

            // adjust sticky footer height (height differs when homepage is loaded at first)
            // $wrapper.css('padding-bottom', $footer.outerHeight());
            // $(window).on('resize', function () {
            //     $wrapper.css('padding-bottom', $footer.outerHeight());
            // });

            // add flags to lang selector
            $.each($footerLangSelOptions, function () {
                $(this).prepend('{span class="c-flag isCountry'+ raceBetsJS.i18n.languageToFlag($(this).val()).toUpperCase() +'"}{/span}');
            });

            raceBetsJS.application.assets.guidelines.selectBoxControl($footerLangSelSelect, {
                onSelect: function (value) {
                    var currentLang = raceBetsJS.application.user.lang;
                    if (value !== currentLang) {
                        raceBetsJS.application.assets.overlay.showPleaseWait();

                        var json = get(true);
                            json.dialog.general.language = value;

                        save($.stringify(json), function () {
                            location.reload();
                        });
                    } else {
                        return false;
                    }
                }
            });

            $footer
                .on('click', '#btn-pagetop', function(e) {
                    e.preventDefault();
                    $('body, html').animate({ scrollTop: 0 }, 'fast');
                })
                .on('click', '#footer-settings', function (e) {
                    e.preventDefault();
                    raceBetsJS.application.assets.settings.show();
                })
                .on('click', '#footer-cookies', function (e) {
                    if (raceBetsJS.application.user.loggedIn) {
                        location.replace('account/marketing');
                    } else {
                        e.preventDefault();
                        raceBetsJS.application.assets.settings.show({ activeTab: 2 });
                    }

                })
                .on('click', '.m-footer__menu a[data-key="disputes"]', function(e) {
                    e.preventDefault();
                    raceBetsJS.browser.popup.open({
                        href: (raceBetsJS.application.user.lang !== 'de') ? $(this).attr('href') : $(this).attr('href').replace('/en/', '/de/'),
                        name: 'knowledgePopup',
                        height: 564,
                        width: 980,
                        scroll: 'yes',
                        resizable: 'yes'
                    });
                })
                .on('click', '.m-footer__menu a[data-key^="raceBets"]', function(e) {
                    e.preventDefault();
                    if ($.cookie('redirectSource')) {
                        $.cookie('redirectSource', null, {
                            path: '/',
                            domain: getCookieDomain()
                        });
                    }
                    if ($(this).data('key') === 'raceBetsCom' && $.cookie('rbTldAccepted')) {
                        $.cookie('rbTldAccepted', null, {
                            path: '/',
                            domain: getCookieDomain()
                        });
                    }
                    window.location.href = $(this).attr('href');
                });
        };

        /**
         * We need to enable XtremePush related logic for .com and .de tld independently.
         * XtremePush can be:
         * 1. (ROW) - Enabled for ROW (globals.xtremePus) and disabled for Germany (globals.xtremePushDE).
         * 2. (DE) - Disabled for ROW and enabled for Germany.
         * 3. (ROW + DE) - Enabled both for ROW and Germany.
         * 4. Disabled for both ROW and Germany.
         */
         var getXtremePushEnabledState = function() {
            var globals = raceBetsJS.application.globals;
            var websiteTLD = globals.websiteTLD;
            var isGermanTLD = websiteTLD === 'de';

            var deIsEnabled = isGermanTLD && globals.xtremePushDE;
            var rowIsEnabled = !isGermanTLD && globals.xtremePush

            return (deIsEnabled || rowIsEnabled) ? true : false
        }

        return {
            init: initEvents,
            show: show,
            get: get,
            set: set,
            save: save,
            status: status,
            getCookieDomain: getCookieDomain,
            getTldCookieDomain: getTldCookieDomain,
            getBrandSettings: getBrandSettings,
            xtremePushCookieName: xtremePushCookieName,
            getXtremePushEnabledState: getXtremePushEnabledState
        };
    })();
})(raceBetsJS);
