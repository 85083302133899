/**
* Clock module
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
	raceBetsJS.application.header.clock = function() {
		// @private

        // local timezone offset in milliseconds to GMT
        var timezoneOffset = 0;

		/**
        * Update the clock element text
        */
		var updateClock = function() {
			var date = new Date($.now() - raceBetsJS.application.user.timeDiff);

			clockElement.html(raceBetsJS.i18n.print('currentTime', {
                time: raceBetsJS.application.assets.timezone.date("H:i", (date.getTime() / 1000))
            }));

            return date;
		};

        // Interval object for update of clock time
		var interval = new raceBetsJS.time.Interval({
			interval: 60000,
			tick: updateClock
		});

		// The DOM element
		var clockElement = $(document.createElement('span')).attr('id', 'clock');

		// @public
		return {
			init: function() {
				// Insert the clock element
				$('#m-header__clock').append(clockElement);

				// Start the clock
				var seconds = updateClock().getSeconds();
                new raceBetsJS.time.Timeout({
                    delay: 60000 - (seconds * 1000),
                    callback: function() {
                        updateClock();
                        interval.start();
                    }
                });
			}
		};
	}();
})(raceBetsJS);
