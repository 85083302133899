/**
* Calendar
*
* @description: Events list
*
*/
(function(raceBetsJS) {
    raceBetsJS.application.content.calendar = function() {
        // @private
        var type = null,
            timer = null,
            refreshRate = 60,
            timerStatus = false;

        var initPage = function(data) {
            // Restores Tipped tooltips to initial state
            Tipped.init();

            // get date object - what is this doing here anyway?! should be done in JS?! (rob, 28/01/2014)
            var dateObj = data.calendarDates;
            delete data.calendarDates;

            var controlHtml = raceBetsJS.application.templates.calendar.dateControl(dateObj);
            raceBetsJS.application.globals.contentMain.empty().html(controlHtml);

            datepickerWidget(dateObj.calendarDate);

            // check if any results are found
            if (_.size(data) < 1) {
                raceBetsJS.application.globals.contentMain.append(
                    $('<div id="calendar" class="calendar-content"/>').html(
                        '<div class="box box-error"><h2 class="header">'+raceBetsJS.i18n.data.noResultsFound+
                        '</h2><div class="content"><p>'+raceBetsJS.i18n.data.selectAnotherDate+'</p></div></div>'
                    )
                );
                return;
            }

            // group and sort countries
            var sorted = groupByCountry(data);

            // build markup
            var container = $('<div id="calendar" class="calendar-content"/>');
            sorted.forEach(function(events) {
                var rowIterator = 0;
                var isVirtual =  events[0].isVirtual;

                // create
                var box = $(raceBetsJS.application.templates.calendar.countryBox({
                    country: events[0].country,
                    isVirtual: isVirtual
                }));

                // cache
                var dom = {
                    box: box,
                    content: box.find('.content')
                };

                // iterate events
                events.forEach(function(event) {

                    // skip dog races
                    if (event.raceType === 'D' && raceBetsJS.application.assets.settings.get().dialog.general.hideDogs === true) {
                        return;
                    }

                    // add row class
                    event.rowClass = (rowIterator % 2 ? 'odd' : '');
                    event.isVirtual = isVirtual;
                    event.link = createEventLink(event);

                    // create event row
                    var eventRow = $(raceBetsJS.application.templates.calendar.eventRow(event));

                    // append to box
                    dom.content.append(eventRow);

                    // increase row iterator
                    rowIterator++;

                    // iterate children (H2H, ...)
                    if (event.children.length > 0) {
                        event.children.forEach(function(child) {
                            // add row class
                            child.rowClass = (rowIterator % 2 ? 'odd' : '');
                            child.isChild = true;
                            child.parentTitle = event.title;
                            child.isVirtual = isVirtual;
                            child.link = createEventLink(child, true);

                            // create child element
                            var childRow = $(raceBetsJS.application.templates.calendar.eventRow(child));
                            dom.content.append(childRow);

                            // increase row iterator
                            rowIterator++;
                        });
                    }
                });

                // append to container
                container.append(dom.box);
            });

            // append to dom
            raceBetsJS.application.globals.contentMain.append(container);

            // show tipped
            if (!raceBetsJS.browser.type.isiPad() && !raceBetsJS.browser.type.isiPhone()){
                Tipped.create('#calendar ul.race-numbers a.ajaxify', function(elem) {
                    return raceBetsJS.i18n.print('timeLong', {
                        time: raceBetsJS.application.assets.timezone.date('H:i', $(elem).data('post-time'))
                    }) + ': ' + $(elem).data('race-title');
                }, {
                    hook: 'bottommiddle',
                    skin: 'racebets',
                    maxWidth: 250
                });
            }

            // start updates
            startTimer();
        };

        var datepickerWidget = function (selTimestamp) {
            var racesDatepicker = $('#races_datepicker');
            var racesDatepickerIcon = $('#races_datepicker_icon');
            var rdpSettings = {
                firstDay: 1,
                dateFormat: 'yy-mm-dd',
                changeMonth: true,
                changeYear: true,
                minDate: new Date(2005, 0, 1),
                maxDate: '+2 year',
                defaultDate: datepickerDate(selTimestamp),
                showOtherMonths: true,
                onSelect: function(dateText) {
                    $('#races_datepicker').hide();
                    raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/calendar/main/date/' + dateText);
                }
            };

            function datepickerDate(timestamp) {
                var d = new Date(timestamp * 1000);
                var day   = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
                var month = ((d.getMonth()+1) < 10) ? '0' + (d.getMonth()+1) : (d.getMonth()+1);
                var year  = d.getFullYear();

                return year + '-' + month + '-' + day;
            }

            // Create widget
            racesDatepicker.datepicker(rdpSettings).hide();
            racesDatepickerIcon.click(function () {
                racesDatepicker.toggle();
            });
            // Hide when click outside
            $(document).on('click.races_datepicker', function (e) {
                var target = $(e.target);

                if (!target.is('#races_datepicker_icon') && !target.parents().filter('#races_datepicker_icon').length && !target.parents().filter('.ui-datepicker-header').length) {
                    racesDatepicker.hide();
                }
            });
        };

        /**
        * Create event link
        *
        * @description: virtuals don't have event card - get race link
        *               child races like H2H don't have racing card - get event link
        */
        var createEventLink = function(event, isChildEvent) {
            var result = raceBetsJS.application.globals.urlPrefix;
            // find first open race
            if (event.isVirtual && !isChildEvent) {
                var openRaceId = event.relatedRaces.filter(function(race){
                    return race.raceStatus === 'OPN';
                });
                // of none open race found - get first race form array
                openRaceId = openRaceId[0] ? openRaceId[0].idRace : event.relatedRaces[0].idRace;
                result += '/race/details/id/' + openRaceId + '/';
            } else {
                result += '/event/details/id/' + event.idEvent  + '/';
            }
            return result;
        }

        var isVirtualAvailable = function() {
            return raceBetsJS.application.globals.isVirtualAvailable;
        }

        var groupByCountry = function(data) {
            var cacheIndex = {},
                countryOrder = raceBetsJS.application.assets.countryOrder.getOrder(),
                hideVirtuals = !isVirtualAvailable();

            var result = Object.keys(data).reduce(function (arr, eventId, idx) {
                // Group events by country
                var event = data[eventId],
                    cacheLabel = event.isVirtual ? 'VR' : event.country;

                    if (hideVirtuals && event.isVirtual) {
                        // hide virtuals if user DE
                        return arr;
                    } else if(typeof cacheIndex[cacheLabel] === 'number') {
                        // Country already found - add to existing array
                        arr[cacheIndex[cacheLabel]].push(event);
                    } else {
                        // Country havent found before - push to new array
                        var newLength = arr.push([event]);
                        cacheIndex[cacheLabel] = newLength - 1;
                    }

                return arr;
            }, []);

            // Sort countres groups - virtual first, not existing on global settings - last;
            result.sort(function(a,b){
                // if country is not on the global setting list (index -1) put on the end (countryOrder.length)
                var aIdx = countryOrder.indexOf(a[0].country) === -1 ? countryOrder.length : countryOrder.indexOf(a[0].country),
                    bIdx = countryOrder.indexOf(b[0].country) === -1 ? countryOrder.length : countryOrder.indexOf(b[0].country);

                if (a[0].isVirtual) {
                    return -1;
                }
                if (b[0].isVirtual) {
                    return 1;
                }
                return aIdx - bIdx;
            });

            return result;

        };

        var refresh = function(){
            if(type === 'current'){
                stopTimer();
                $.getJSON(
                    '/ajax/events/calendar',
                    function(json){
                        initPage(json, true);
                        startTimer();
                    }
                );
            } else {
                startTimer();
            }
        };

        var startTimer = function(){
            timerStatus = true;
            timer = setTimeout(
                function(){
                    if(timerStatus === true) {
                        refresh();
                    }
                },
                refreshRate * 1000
            );
        };

        var stopTimer = function(){
            if(timer !== null && timerStatus === true){
                timerStatus = false;
                clearTimeout(timer);
                timer = null;
            }
        };

        var isToday = function(date){
            var currentTime = new Date();
            date = date.split('-');
            return (parseInt(date[0], 10) === currentTime.getFullYear()) && (parseInt(date[1], 10) === currentTime.getMonth() + 1) && (parseInt(date[2], 10) === currentTime.getDate());
        };

        var isFuture = function(date){
            var currentTime = new Date();
            date = date.split('-');
            return (parseInt(date[0], 10) > currentTime.getFullYear()) || (parseInt(date[1], 10) > currentTime.getMonth() + 1) || (parseInt(date[2], 10) > currentTime.getDate());
        };

        // @public
        return {
            init: function() {
                // Setup route controller
                raceBetsJS.application.contentController.addDynamicPage({
                    urlPattern: /\/calendar\/(today|tomorrow|yesterday|main\/date\/(\d{4}-\d{2}-\d{2}))\/?/,
                    dataSourceURLBuilder: function(requestType, requestDate) {
                        // set menu
                        raceBetsJS.application.header.navigation.activateItemByKey('racing ' + requestType);

                        // build JSON URL
                        if (requestDate !== undefined) {
                            type = isToday(requestDate) ? 'current' : (isFuture(requestDate) ? 'future' : 'archive');
                            return '/ajax/events/calendar/date/' + requestDate;
                        } else {
                            type = (requestType === 'today') ? 'current' : (requestType === 'tomorrow' ? 'future' : 'archive');
                            return '/ajax/events/calendar/date/' + requestType;
                        }
                    },
                    onDataReceived: initPage,
                    onUnload: function() {
                        //panels = {};
                        stopTimer();
                    }
                });
            },
            refresh: refresh
        };
    }();
})(raceBetsJS);
