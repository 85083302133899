/**
* Brand Settings
*/

(function (raceBetsJS) {

    raceBetsJS.application.globals.brandSettings = {
        betsafe: {
            supportURLs: {
                bettingRules: {
                    en: 'https://www.betsafe.com/en/game-rules/horseracing',
                    eu: 'https://www.betsafe.com/eu/game-rules/horseracing',
                    sv: 'https://www.betsafe.com/sv/spelregler/hastkapplopning',
                    no: 'https://www.betsafe.com/no/spillregler/trav',
                    fi: 'https://www.betsafe.com/fi/pelisaannot/hevosurheilu',
                },
                rule4: {
                    en: 'https://www.betsafe.com/en/game-rules/horseracing#rule-4',
                    eu: 'https://www.betsafe.com/eu/game-rules/horseracing#rule-4',
                    sv: 'https://www.betsafe.com/sv/spelregler/hastkapplopning#regel-4',
                    no: 'https://www.betsafe.com/no/spillregler/trav#regel-4',
                    fi: 'https://www.betsafe.com/fi/pelisaannot/hevosurheilu#saanto-4',
                },
                nonRunnerNoBet: {
                    en: 'https://www.betsafe.com/en/game-rules/horseracing#markets',
                    eu: 'https://www.betsafe.com/eu/game-rules/horseracing#markets',
                    sv: 'https://www.betsafe.com/sv/spelregler/hastkapplopning#spel',
                    no: 'https://www.betsafe.com/no/spillregler/trav#markeder',
                    fi: 'https://www.betsafe.com/fi/pelisaannot/hevosurheilu#vedonlyontikohteet',
                },
                offers: {
                    en: 'https://www.betsafe.com/en/promotions',
                    eu: 'https://www.betsafe.com/eu/promotions',
                    sv: 'https://www.betsafe.com/sv/promotions',
                    no: 'https://www.betsafe.com/no/promotions',
                    fi: 'https://www.betsafe.com/fi/promotions',
                },
                contact: {
                    en: 'https://support.betsafe.com',
                    eu: 'https://support.betsafe.com/eu.html',
                },
                bonuses: {
                    en: 'https://support.betsafe.com',
                    eu: 'https://support.betsafe.com/eu.html',
                    sv: 'https://support.betsafe.com/sv.html',
                    no: 'https://support.betsafe.com/no.html',
                    fi: 'https://support.betsafe.com/fi.html',
                    pl: 'https://support.betsafe.com/pl.html',
                    es: 'https://support.betsafe.com/es.html',
                    ca: 'https://support.betsafe.com/ca.html',
                    pe: 'https://support.betsafe.com/pe.html',
                }
            },
            env: {
                prodURI: 'https://www.betsafe.com',
                prodOrigin: '.betsafe.com'
            },
            chartColors: {
                xAxisColor: '#4A4A4A',
                xAxisTextColor: '#999999',
                yAxisColor: '#4A4A4A',
                yAxisTextColor: '#999999',
                yAxisTopStrokeColor:'transparent',
                yAxisGridStrokeColor: '#222326',
                yAxisEvery5gridStrokeColor: '#4A4A4A'
            },
            bettingDialog: {
                borderSize: 0,
                borderColor: 'transparent',
                background: '#000',
                shadowOpacity: 0,
                maxWidth: 320
            },
            overlayColor: 'rgba(0,0,0,0.75)',
            streamColor: '#A20000'
        },
        betsson: {
            supportURLs: {
                bettingRules: {
                    en: 'https://www.betsson.com/en/game-rules/horse-racing',
                    eu: 'https://www.betsson.com/eu/game-rules/horse-racing',
                    sv: 'https://www.betsson.com/sv/spelregler/trav-och-galopp',
                    no: 'https://www.betsson.com/no/spillregler/trav',
                    fi: 'https://www.betsson.com/fi/pelisaannot/hevosurheilu',
                    pe: 'https://www.betsson.com/pe/reglas-juegos/carreras-de-caballos',
                    cl: 'https://www.betsson.com/cl/reglas-juegos/carreras-de-caballos',
                },
                rule4: {
                    en: 'https://www.betsson.com/en/game-rules/horse-racing#rule-4',
                    eu: 'https://www.betsson.com/eu/game-rules/horse-racing#rule-4',
                    sv: 'https://www.betsson.com/sv/spelregler/trav-och-galopp#regel-4',
                    no: 'https://www.betsson.com/no/spillregler/trav#regel-4',
                    fi: 'https://www.betsson.com/fi/pelisaannot/hevosurheilu#saanto-4',
                    pe: 'https://www.betsson.com/pe/reglas-juegos/carreras-de-caballos#regla-4',
                    cl: 'https://www.betsson.com/cl/reglas-juegos/carreras-de-caballos#regla-4',
                },
                nonRunnerNoBet: {
                    en: 'https://www.betsson.com/en/game-rules/horse-racing#markets',
                    eu: 'https://www.betsson.com/eu/game-rules/horse-racing#markets',
                    sv: 'https://www.betsson.com/sv/spelregler/trav-och-galopp#spel',
                    no: 'https://www.betsson.com/no/spillregler/trav#markeder',
                    fi: 'https://www.betsson.com/fi/pelisaannot/hevosurheilu#vedonlyontikohteet',
                    pe: 'https://www.betsson.com/pe/reglas-juegos/carreras-de-caballos#mercados',
                    cl: 'https://www.betsson.com/cl/reglas-juegos/carreras-de-caballos#mercados',
                },
                offers: {
                    en: 'https://www.betsson.com/en/promotions',
                    eu: 'https://www.betsson.com/eu/promotions',
                    sv: 'https://www.betsson.com/sv/promotions',
                    no: 'https://www.betsson.com/no/promotions',
                    fi: 'https://www.betsson.com/fi/promotions',
                    pe: 'https://www.betsson.com/pe/promotions',
                    cl: 'https://www.betsson.com/cl/promotions',
                },
                contact: {
                    en: 'https://support.betsson.com',
                    eu: 'https://support.betsson.com/eu.html',
                    sv: 'https://support.betsson.com/sv.html',
                    no: 'https://support.betsson.com/no.html',
                    fi: 'https://support.betsson.com/fi.html',
                    pe: 'https://support.betsson.com/pe.html',
                    cl: 'https://support.betsson.com/cl.html',
                },
                bonuses: {
                    en: 'https://support.betsson.com',
                    eu: 'https://support.betsson.com/eu.html',
                    sv: 'https://support.betsson.com/sv.html',
                    no: 'https://support.betsson.com/no.html',
                    fi: 'https://support.betsson.com/fi.html',
                    de: 'https://support.betsson.com/de.html',
                    pl: 'https://support.betsson.com/pl.html',
                    es: 'https://support.betsson.com/es.html',
                    pe: 'https://support.betsson.com/pe.html',
                    cl: 'https://support.betsson.com/cl.html',
                    br: 'https://support.betsson.com/br.html',
                }
            },
            env: {
                prodURI: 'https://www.betsson.com',
                prodOrigin: '.betsson.com'
            },
            chartColors: {
                xAxisColor: '#4A4A4A',
                xAxisTextColor: '#999999',
                yAxisColor: '#4A4A4A',
                yAxisTextColor: '#999999',
                yAxisTopStrokeColor:'transparent',
                yAxisGridStrokeColor: '#4F6273',
                yAxisEvery5gridStrokeColor: '#4A4A4A'
            },
            bettingDialog: {
                borderSize: 1,
                borderColor: '#DCDCDC',
                background: '#000',
                shadowOpacity: 0,
                maxWidth: 320
            },
            overlayColor: 'rgba(0,0,0,0.75)',
            streamColor: '#A20000'
        },
        betssongr: {
            supportURLs: {
                bettingRules: {
                    en: 'https://www.betsson.gr/en/game-rules',
                    el: 'https://www.betsson.gr/el/kanones-paixnidiou',
                },
                rule4: {
                    en: 'https://www.betsson.gr/en/game-rules',
                    el: 'https://www.betsson.gr/el/kanones-paixnidiou',
                },
                nonRunnerNoBet: {
                    en: 'https://www.betsson.gr/en/game-rules',
                    el: 'https://www.betsson.gr/el/kanones-paixnidiou',
                },
                offers: {
                    en: 'https://www.betsson.gr/en/promotions',
                    el: 'https://www.betsson.gr/el/prosfores',
                },
                contact: {
                    en: 'https://support.betsson.gr/en.html',
                    el: 'https://support.betsson.gr/gr.html'
                },
                bonuses: {
                    en: 'https://support.betsson.gr/en.html',
                    el: 'https://support.betsson.gr/gr.html',
                }
            },
            env: {
                prodURI: 'https://www.betsson.gr',
                prodOrigin: '.betsson.gr'
            },
            chartColors: {
                xAxisColor: '#4A4A4A',
                xAxisTextColor: '#999999',
                yAxisColor: '#4A4A4A',
                yAxisTextColor: '#999999',
                yAxisTopStrokeColor:'transparent',
                yAxisGridStrokeColor: '#4F6273',
                yAxisEvery5gridStrokeColor: '#4A4A4A'
            },
            bettingDialog: {
                borderSize: 1,
                borderColor: '#DCDCDC',
                background: '#000',
                shadowOpacity: 0,
                maxWidth: 320
            },
            overlayColor: 'rgba(0,0,0,0.75)',
            streamColor: '#A20000'
        },
        betssonarbacity: {
            supportURLs: {
                bettingRules: {
                    en: 'https://caba.betsson.bet.ar/reglas-juegos/horse-racing',
                    ag: 'https://caba.betsson.bet.ar/reglas-juegos/horse-racing',
                },
                rule4: {
                    en: 'https://caba.betsson.bet.ar/reglas-juegos/horse-racing#regla-4',
                    ag: 'https://caba.betsson.bet.ar/reglas-juegos/horse-racing#regla-4',
                },
                nonRunnerNoBet: {
                    en: 'https://caba.betsson.bet.ar/reglas-juegos/horse-racing#mercados',
                    ag: 'https://caba.betsson.bet.ar/reglas-juegos/horse-racing#mercados',
                },
                offers: {
                    en: 'https://caba.betsson.bet.ar/promotions',
                    ag: 'https://caba.betsson.bet.ar/promotions',
                },
                contact: {
                    en: 'https://support.caba.betsson.bet.ar/es.html',
                    ag: 'https://support.caba.betsson.bet.ar/es.html'
                },
                bonuses: {
                    en: 'https://support.caba.betsson.bet.ar/es.html',
                    ag: 'https://support.caba.betsson.bet.ar/es.html',
                }
            },
            env: {
                prodURI: 'https://caba.betsson.bet.ar',
                prodOrigin: 'betsson.bet.ar'
            },
            chartColors: {
                xAxisColor: '#4A4A4A',
                xAxisTextColor: '#999999',
                yAxisColor: '#4A4A4A',
                yAxisTextColor: '#999999',
                yAxisTopStrokeColor:'transparent',
                yAxisGridStrokeColor: '#4F6273',
                yAxisEvery5gridStrokeColor: '#4A4A4A'
            },
            bettingDialog: {
                borderSize: 1,
                borderColor: '#DCDCDC',
                background: '#000',
                shadowOpacity: 0,
                maxWidth: 320
            },
            overlayColor: 'rgba(0,0,0,0.75)',
            streamColor: '#A20000'
        },
        suaposta: {
            supportURLs: {
                bettingRules: {
                    en: 'https://www.betsson.com/en/game-rules/horse-racing',
                    pt: 'https://support.betsson.com.br/br.html?category=REGRAS%20DE%20APOSTAS',
                },
                rule4: {
                    en: 'https://www.betsson.com/en/game-rules/horse-racing#rule-4',
                    pt: 'https://support.betsson.com.br/br.html?category=REGRAS%20DE%20APOSTAS',
                },
                nonRunnerNoBet: {
                    en: 'https://www.betsson.com/en/game-rules/horse-racing#markets',
                    pt: 'https://support.betsson.com.br/br.html?category=REGRAS%20DE%20APOSTAS',
                },
                offers: {
                    en: 'https://www.betsson.com/en/promotions',
                },
                contact: {
                    en: 'https://support.betsson.com.br',
                },
                bonuses: {
                    pt: 'https://support.betsson.com.br/br.html?faq=5678',
                }
            },
            env: {
                prodURI: 'https://www.betsson.com.br',
                prodOrigin: '.betsson.com.br'
            },
            chartColors: {
                xAxisColor: '#4A4A4A',
                xAxisTextColor: '#999999',
                yAxisColor: '#4A4A4A',
                yAxisTextColor: '#999999',
                yAxisTopStrokeColor:'transparent',
                yAxisGridStrokeColor: '#4F6273',
                yAxisEvery5gridStrokeColor: '#4A4A4A'
            },
            bettingDialog: {
                borderSize: 1,
                borderColor: '#DCDCDC',
                background: '#000',
                shadowOpacity: 0,
                maxWidth: 320
            },
            overlayColor: 'rgba(0,0,0,0.75)',
            streamColor: '#A20000'
        },
        nordicbet: {
            supportURLs: {
                bettingRules: {
                    en: 'https://www.nordicbet.com/en/game-rules/horse-racing',
                    eu: 'https://www.nordicbet.com/eu/game-rules/horse-racing',
                    sv: 'https://www.nordicbet.com/sv/spelregler/trav-och-galopp',
                    no: 'https://www.nordicbet.com/no/spillregler/trav',
                    fi: 'https://www.nordicbet.com/fi/pelisaannot/hevosurheilu',
                },
                rule4: {
                    en: 'https://www.nordicbet.com/en/game-rules/horse-racing#rule-4',
                    eu: 'https://www.nordicbet.com/eu/game-rules/horse-racing#rule-4',
                    sv: 'https://www.nordicbet.com/sv/spelregler/trav-och-galopp#regel-4',
                    no: 'https://www.nordicbet.com/no/spillregler/trav#regel-4',
                    fi: 'https://www.nordicbet.com/fi/pelisaannot/hevosurheilu#saanto-4',
                },
                nonRunnerNoBet: {
                    en: 'https://www.nordicbet.com/en/game-rules/horse-racing#markets',
                    eu: 'https://www.nordicbet.com/eu/game-rules/horse-racing#markets',
                    sv: 'https://www.nordicbet.com/sv/spelregler/trav-och-galopp#spel',
                    no: 'https://www.nordicbet.com/no/spillregler/trav#markeder',
                    fi: 'https://www.nordicbet.com/fi/pelisaannot/hevosurheilu#vedonlyontikohteet',
                },
                offers: {
                    en: 'https://www.nordicbet.com/en/promotions',
                    eu: 'https://www.nordicbet.com/eu/promotions',
                    sv: 'https://www.nordicbet.com/sv/promotions',
                    no: 'https://www.nordicbet.com/no/promotions',
                    fi: 'https://www.nordicbet.com/fi/promotions',
                },
                contact: {
                    en: 'https://support.nordicbet.com',
                    eu: 'https://support.nordicbet.com/eu.html',
                },
                bonuses: {
                    en: 'https://support.nordicbet.com',
                    eu: 'https://support.nordicbet.com/eu.html',
                    sv: 'https://support.nordicbet.com/sv.html',
                    no: 'https://support.nordicbet.com/no.html',
                    fi: 'https://support.nordicbet.com/fi.html',
                }
            },
            env: {
                prodURI: 'https://www.nordicbet.com',
                prodOrigin: '.nordicbet.com'
            },
            chartColors: {
                xAxisColor: '#4F6273',
                xAxisTextColor: '#979797',
                yAxisColor: '#4F6273',
                yAxisTextColor: '#979797',
                yAxisTopStrokeColor:'transparent',
                yAxisGridStrokeColor: '#4F6273',
                yAxisEvery5gridStrokeColor: '#4F6273'
            },
            bettingDialog: {
                borderSize: 1,
                borderColor: '#DCDCDC',
                background: '#2F4152',
                shadowOpacity: 0,
                maxWidth: 360
            },
            overlayColor: 'rgba(10, 85, 139, 0.75)',
            streamColor: '#A20000'
        },
        europebet: {
            supportURLs: {
                bettingRules: {
                    en: 'https://www.europebet.com/en/game-rules/horse-racing',
                    ka: 'https://www.europebet.com/ka/game-rules/horse-racing',
                    ru: 'https://www.europebet.com/ru/game-rules/horse-racing',
                    tr: 'https://www.europebet.com/tr/game-rules/horse-racing',
                },
                rule4: {
                    en: 'https://www.europebet.com/en/game-rules/horse-racing',
                    ka: 'https://www.europebet.com/ka/game-rules/horse-racing',
                    ru: 'https://www.europebet.com/ru/game-rules/horse-racing',
                    tr: 'https://www.europebet.com/tr/game-rules/horse-racing',
                },
                nonRunnerNoBet: {
                    en: 'https://www.europebet.com/en/game-rules/horse-racing',
                    ka: 'https://www.europebet.com/ka/game-rules/horse-racing',
                    ru: 'https://www.europebet.com/ru/game-rules/horse-racing',
                    tr: 'https://www.europebet.com/tr/game-rules/horse-racing',
                },
                offers: {
                    en: 'https://www.europebet.com/en/promotions',
                    ka: 'https://www.europebet.com/ka/promotions',
                    ru: 'https://www.europebet.com/ru/promotions',
                    tr: 'https://www.europebet.com/tr/promotions',
                },
                contact: {
                    en: 'https://www.europebet.com/en/contact-us',
                    ka: 'https://www.europebet.com/ka/contact-us',
                    ru: 'https://www.europebet.com/ru/contact-us',
                    tr: 'https://www.europebet.com/tr/contact-us',
                },
                bonuses: {
                    en: 'https://www.europebet.com/en/help',
                    ka: 'https://www.europebet.com/ka/help',
                    ru: 'https://www.europebet.com/ru/help',
                    tr: 'https://www.europebet.com/tr/help',
                }
            },
            env: {
                prodURI: 'https://www.europebet.com',
                prodOrigin: '.europebet.com',
            },
            chartColors: {
                xAxisColor: '#4A4A4A',
                xAxisTextColor: '#999999',
                yAxisColor: '#4A4A4A',
                yAxisTextColor: '#999999',
                yAxisTopStrokeColor: 'transparent',
                yAxisGridStrokeColor: '#222326',
                yAxisEvery5gridStrokeColor: '#4A4A4A',
            },
            bettingDialog: {
                borderSize: 0,
                borderColor: 'transparent',
                background: '#000',
                shadowOpacity: 0,
                maxWidth: 320,
            },
            overlayColor: 'rgba(0,0,0,0.75)',
            streamColor: '#A20000',
        },
        racebets: {
            env: {
                prodOrigin: '.racebets.',
            },
            chartColors: {
                xAxisColor: '#4A4A4A',
                xAxisTextColor: '#999999',
                yAxisColor: '#4A4A4A',
                yAxisTextColor: '#999999',
                yAxisTopStrokeColor:'transparent',
                yAxisGridStrokeColor: '#222326',
                yAxisEvery5gridStrokeColor: '#4A4A4A'
            },
            bettingDialog: {
                borderSize: 0,
                borderColor: 'transparent',
                background: '#333333',
                shadowOpacity: 0.4,
                maxWidth: 360
            },
            overlayColor: 'rgba(0,0,0,0.75)',
            streamColor: '#ffc531',
            supportURLs: {
                rule4: {
                    en: 'https://support.racebets.com/en.html?faq=4567',
                    de: 'https://support.racebets.com/de.html?faq=4567',
                },
                contact: {
                    en: 'https://support.racebets.com/en.html',
                    de: 'https://support.racebets.com/de.html',
                },
                bonuses: {
                    en: 'https://support.racebets.com/en.html?category=promotions-and-bonuses',
                    de: 'https://support.racebets.com/de.html?category=aktionen-und-boni',
                },
                nonRunnerNoBet: {
                    en: 'https://support.racebets.com/en.html?faq=4575',
                    de: 'https://support.racebets.com/de.html?faq=4575',
                },
                verification: {
                    en: 'https://support.racebets.com/en.html?faq=4556',
                    de: 'https://support.racebets.com/de.html?faq=4556',
                },
                responsibleGaming: {
                    en: 'https://support.racebets.com/en.html?category=responsible-gaming',
                    de: 'https://support.racebets.com/de.html?category=responsible-gaming',
                }
            }
        },
        racebetsDE: {
            env: {
                prodOrigin: '.racebets.',
            },
            chartColors: {
                xAxisColor: '#4A4A4A',
                xAxisTextColor: '#999999',
                yAxisColor: '#4A4A4A',
                yAxisTextColor: '#999999',
                yAxisTopStrokeColor:'transparent',
                yAxisGridStrokeColor: '#222326',
                yAxisEvery5gridStrokeColor: '#4A4A4A'
            },
            bettingDialog: {
                borderSize: 0,
                borderColor: 'transparent',
                background: '#333333',
                shadowOpacity: 0.4,
                maxWidth: 360
            },
            overlayColor: 'rgba(0,0,0,0.75)',
            streamColor: '#ffc531',
            supportURLs: {
                rule4: {
                    en: 'https://support.racebets.de/en.html?faq=10754',
                    de: 'https://support.racebets.de/de.html?faq=10754',
                },
                contact: {
                    en: 'https://support.racebets.de/en.html',
                    de: 'https://support.racebets.de/de.html',
                },
                bonuses: {
                    en: 'https://support.racebets.de/en.html?category=promotions-and-bonuses',
                    de: 'https://support.racebets.de/de.html?category=aktionen-und-boni',
                },
                nonRunnerNoBet: {
                    en: 'https://support.racebets.de/en.html?faq=10762',
                    de: 'https://support.racebets.de/de.html?faq=10762',
                },
                verification: {
                    en: 'https://support.racebets.de/en.html?faq=10734',
                    de: 'https://support.racebets.de/de.html?faq=10734',
                },
                responsibleGaming: {
                    en: 'https://support.racebets.de/en.html?category=responsible-gaming',
                    de: 'https://support.racebets.de/de.html?category=responsible-gaming',
                }
            }
        },
    };

})(raceBetsJS);
