/**
* @description panel template
*
*/
(function(raceBetsJS) {
	raceBetsJS.application.templates.panel = _.template(
		'<div id="<%= id %>" class="panel <%= cssClass %>">' +
			'<div class="header_container">' +
				'<h2>' +
					'<span class="text"><%= title %></span>' +
				'</h2>' +
			'</div>' +
			'<div class="content_container">' +
				'<%= content %>' +
			'</div>' +
			'<% if(!_.isEmpty(more)) { %>' +
				'<div class="more">' +
					'<a class="ajaxify" href="<%= more.link %>" title="<%= more.text %>"><%= more.text %></a>' +
				'</div>' +
			'<% } %>' +
		'</div>'
	);
})(raceBetsJS);
