/**
* Favourites
*
* @author: Robin Ebers
*/

(function(raceBetsJS) {
    raceBetsJS.application.content.favourites = (function() {
        // @private
        var favourites = [],
            searchForm,
            resultCont;

        function onContentLoaded() {
            resultCont = $('#favourites-search-results');
            searchForm = $('#favourites-search-form');

            // submit search form
            searchForm.submit(searchHorses);

            // search result delegator
            resultCont.on('click', 'a[data-subject]', addFavourite);

            $('#favourites-search-form-name').keyup(function() {
                var button = $('#favourites-search-form-submit');

                if ($(this).val().length >= 3) {
                    button.removeAttr('disabled');
                } else {
                    button.attr('disabled', true);
                }
            });


            Tipped.create('.c-pageHeader--favourites .tipped', {
                hook: 'bottommiddle',
                maxWidth: 600,
                hideOn: [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' }
                ]
            });

            // icon actions
            $('#favourites').on('click', '.c-btn[data-action]', function(e) {
                e.preventDefault();

                var action = $(this).data('action');
                var idSubject = $(this).data('subject');
                var li = $(this).parent().parent();

                switch (action) {
                    case 'edit':
                        // find note span
                        var span = li.find('span[data-subject="' + idSubject + '"]');
                        var note = (($.trim(span.text()) === '-') ? '' : $.trim(span.text()));

                        raceBetsJS.application.assets.dialogs.runnerNotes.show({
                            noteContent: note,
                            idSubject: idSubject,
                            eventTarget: li.find('.c-btn.notes')
                        });
                        break;
                    case 'save':
                        var textarea = li.find('textarea');
                        var note = textarea.val();

                        // deactivate button
                        $(this).data('action', 'edit');

                        if (note === '') {
                            $(this).removeClass('active');
                        };

                        $.ajax({
                            type: 'post',
                            url: '/ajax/favourites/savenote/',
                            context: $(this),
                            data: {
                                id: idSubject,
                                note: note
                            },
                            complete: function() {
                                // delete row if runner favourite has neither a comment, nor is favourite anymore
                                if (note === '' && !li.find('a[data-action=toggle]').hasClass('active')) {
                                    li.remove();
                                } else {
                                    li.find('span[data-subject]').attr('ng-non-bindable', '').text((textarea.val() != '') ? $(textarea).val() : '-').show(); // show span with updated note
                                    $(textarea).remove(); // remove textarea
                                }
                            }
                        });
                        break;
                    case 'toggle':
                        $.ajax({
                            type: 'get',
                            context: $(this),
                            url: '/ajax/favourites/star/id/' + idSubject + '/store/' + ($(this).hasClass('active') ? 'false' : 'true'),
                            beforeSend: function () {
                                raceBetsJS.application.assets.overlay.showPleaseWait();
                            },
                            success: function() {
                                if ($(this).hasClass('active')) {
                                    if (li.find('span[data-subject="' + idSubject + '"]').text().trim() === ''){
                                        li.addClass('removed');
                                        li.find('.active').removeClass('active');
                                    } else {
                                        $(this).removeClass('active');
                                    }

                                    var favIndex = favourites.indexOf(idSubject);
                                    if (favIndex > -1) {
                                        favourites.splice(favIndex, 1);
                                    }
                                } else {
                                    $(this).addClass('active');
                                    li.removeClass('removed');
                                }
                            },
                            complete: function () {
                                raceBetsJS.application.assets.overlay.hidePleaseWait();
                            }
                        });
                        break;
                    case 'delete':
                        raceBetsJS.application.assets.modalDialog.show({
                            content: raceBetsJS.i18n.data.confirmRemoveFavourite,
                            buttons: [
                                {
                                    label: raceBetsJS.i18n.data.buttonCancel,
                                    action: function() {
                                        raceBetsJS.application.assets.overlay.close();
                                    }
                                },
                                {
                                    label: raceBetsJS.i18n.data.removeRunnerButton,
                                    action: function() {
                                        $.ajax({
                                            type: 'post',
                                            url: '/ajax/favourites/delete/',
                                            data: { id: idSubject },
                                            complete: function() {
                                                li.remove();
                                                raceBetsJS.application.assets.overlay.close();
                                            }
                                        });
                                    },
                                    active: true
                                }
                            ]
                        });
                        break;
                }
            });
        }

        function setFavourite(idSubject) {
            favourites.push(idSubject);
        }

        function searchHorses(e) {
            e.preventDefault();

            // stop submit while button is disabled
            if ($('#favourites-search-form-submit').attr('disabled')) {
                return;
            }

            // empty and hide results
            resultCont.empty().hide();

            $.ajax({
                type: 'post',
                url: '/ajax/favourites/search/',
                data: {
                    name: $('#favourites-search-form-name').val(),
                    raceType: $('#favourites-search-form-race-type').val()
                },
                beforeSend: function() {
                    raceBetsJS.application.assets.overlay.showPleaseWait();
                },
                success: function(data) {
                    var resultLists = data;

                    // no results
                    if (resultLists.length === 0) {
                        resultCont
                            .append($('<h3>').text(raceBetsJS.i18n.data.headSearchResult))
                            .append($('<div class="list clearfix"><p></p></div>').text(raceBetsJS.i18n.data.noResultsFound))
                            .show();
                        return;
                    }

                    var resultNr = 0;
                    $.each(resultLists, function (i, results) {
                        resultNr += results.length;

                        $.each(results, function (i, runner) {
                            if (favourites.indexOf(parseInt(runner.idSubject)) > -1) {
                                runner.emailAlert = true;
                            }
                        });
                    });

                    resultCont
                        .append($('<h3>').text(raceBetsJS.i18n.data.headSearchResult))
                        .append( raceBetsJS.application.templates.favourites({ results: resultLists }) );

                    if (resultNr >= 48) {
                        resultCont.append('<p class="info">' + raceBetsJS.i18n.print('headSearchResultLimit', { limit: 48 }) + '</p>');
                    }

                    resultCont.show();
                },
                complete: function() {
                    raceBetsJS.application.assets.overlay.close();
                }
            });
        }

        function displayNote(idSubject, note) {
            $('#favourites').find('.comment span[data-subject='+ idSubject +']').text(note);
        }

        function addFavourite(e) {
            e.preventDefault();
            $.ajax({
                url: '/ajax/favourites/star/id/' + $(this).data('subject') + '/store/true/',
                beforeSend: function() {
                    raceBetsJS.application.assets.overlay.showPleaseWait();
                },
                success: function() {
                    location.reload();
                }
            });
        }

        // @public
        return {
            init: function() {
                // add content loaded observer
                raceBetsJS.application.contentController.addCallback(/\/favourites/, onContentLoaded);
            },
            setFavourite: setFavourite,
            displayNote: displayNote
        };
    })();
})(raceBetsJS);
