/**
* main.js
*
* @description Application layer initialisation/entry point
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
    // Define the application namespace
    $.extend(raceBetsJS, {
        application: {
            globals: {},
            user: {},
            templates: {},
            header: {},
            assets: {},
            content: {},
            customers: {},
            util: {},
            tours: {}
        }
    });

    raceBetsJS.application.init = function(brand) {
        // Connect the websocket manager
        if (raceBetsJS.application.globals.webSockets) {
            // not logged yet
            raceBetsJS.application.user.loggedInNode = false;
            raceBetsJS.webSockets.nodeJS.connect(
                raceBetsJS.application.globals.webSocketsHost,
                raceBetsJS.application.globals.webSocketsPort,
                raceBetsJS.application.globals.brandChildName
            );
        }

        // Show reality check for logged in UK customers only
        if (raceBetsJS.application.user.loggedIn && raceBetsJS.application.user.licenseJurisdiction === 'GB') {
            $.subscribe('realityCheck:warning', function() {
                raceBetsJS.application.content.adventPromo.showDialog();
            });
        }

        if (window.history && window.history.pushState) {
            $(window).on('popstate', function() {
                // This reloads the page when user clicks the Back button and lands on the fantasy widget page.
                var dfsRooute = location.pathname.indexOf('/betssonfc/futebol') !== -1
                if(dfsRooute) {
                    window.location.reload();
                }
            });
        }

        // set default options for ajax requests
        // var _super = $.ajaxSettings.xhr; // related to jQuery bug #10338

        var xhrErrors = 0; // count the number of unsuccessfully ajax calls

        // var lastSuccessCall = new Date().getTime() / 1000;

        var retryAjaxHostDelay = raceBetsJS.application.globals.ajax.retryInterval; // number of minutes to wait until next retry on default ajax host

        // default ajax options
        $.ajaxSetup({
            cache: false,
            timeout: 90000,
            headers: {
                'X-XSRF-TOKEN': $.cookie('XSRF-TOKEN'),
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        var ajaxPrefilterHandler = function (options, originalOptions, jqXHR) {
            var ajaxHost = raceBetsJS.application.globals.ajaxHost;

            if (!ajaxHost) {
                return; //nothing to do
            }

            options.crossDomain = true;

            // disable script execution bug as described in issue #2432
            // https://github.com/jquery/jquery/issues/2432
            options.contents.javascript = false;

            // ajax host timeout 5secs
            if (options.url.indexOf(ajaxHost) > -1) {
                if (raceBetsJS.application.globals.isAjaxHostOn == false) {
                    options.crossDomain = false;
                    options.url = ajaxToDefaultHost(options.url);
                } else {
                    options.timeout = raceBetsJS.application.globals.ajax.timeout;
                }
            }
        };

        $.ajaxPrefilter(ajaxPrefilterHandler);

        var isAjaxHostCall = function (url) {
            var ajaxHost = raceBetsJS.application.globals.ajaxHost;

            if (ajaxHost && url.indexOf(ajaxHost) > -1) {
                return true;
            }
            return false;
        };

        var displayPopupError = function (jqXHR, thrownError) {
            var errorText = null,
                title = raceBetsJS.i18n.data.anErrorOccurred,
                type = 'error',
                closeButton = true,
                buttonCaption = null,
                buttonAction = function () {
                    raceBetsJS.application.globals.ajaxErrorOverlay = false;
                    raceBetsJS.application.assets.overlay.close();
                };

            if (jqXHR.status == 403) { // Forbidden
                raceBetsJS.application.user.loggedIn = false;
                raceBetsJS.application.assets.dialogs.loginDialog.show({
                        type: 'loginRequired'
                    });
                errorText = null;
                title = null;
            } else if (jqXHR.status == 500) { // Internal server error
                try {
                    errorText = $.parseJSON(jqXHR.responseText).errorMsg;
                } catch (exception) {
                    errorText = raceBetsJS.i18n.print('msg_generic_error');
                }
            } else if (jqXHR.status != 0 || jqXHR.readyState != 0 || thrownError == 'timeout') {
                errorText = raceBetsJS.i18n.data.ajaxErrorReload;
            } else if (jqXHR.status == 0 && jqXHR.readyState == 0 && jqXHR.statusText == 'error') {
                if (++xhrErrors >= 5) {
                    errorText = raceBetsJS.i18n.data.internetDisconnection;
                    title = raceBetsJS.i18n.data.titleDisconnection;
                    buttonCaption = raceBetsJS.i18n.data.buttonReload;
                    type = 'attention';
                    closeButton = false;
                    buttonAction = function () {
                        raceBetsJS.application.assets.overlay.close();
                        raceBetsJS.application.assets.overlay.showPleaseWait();
                        location.reload();
                    }
                }
            }

            if (errorText && !raceBetsJS.application.globals.ajaxErrorOverlay) {
                raceBetsJS.application.globals.ajaxErrorOverlay = true;

                raceBetsJS.application.assets.modalDialog.show({
                    type: type,
                    title: title,
                    content: errorText,
                    buttonAction: buttonAction,
                    buttonCaption: buttonCaption,
                    closeButton: closeButton
                });
            }
        }

        var tryAjaxFallback = function (ajaxSettings) {
            if (isAjaxHostCall(ajaxSettings.url) && !ajaxSettings.regularCall) {
                ajaxSettings.url = ajaxToDefaultHost(ajaxSettings.url);
                ajaxSettings.regularCall = true;
                raceBetsJS.application.globals.isAjaxHostOn = false;

                // re-try call
                $.ajax(ajaxSettings);
                // re-try to use original ajax host
                retryAjaxHost();
                return true;
            }

            return false;

            function retryAjaxHost() {
                return setTimeout(function () {
                    raceBetsJS.application.globals.isAjaxHostOn = true;
                }, 60000 * retryAjaxHostDelay++);
            }
        };

        var ajaxErrorHandler = function (evt, jqXHR, ajaxSettings, thrownError) {
            // try to apply ajax fallback
            if (!tryAjaxFallback(ajaxSettings)) {
                // fallback failed, display error popup
                displayPopupError(jqXHR, thrownError);
            }
        };

        var ajaxToDefaultHost = function (url) {
            var newUrl = '//' + window.location.host;
            return url.replace(raceBetsJS.application.globals.ajaxHost, newUrl);
        };

        $(document).ajaxError(ajaxErrorHandler);

        // ajax success
        $(document).ajaxSuccess(function (evt, jqXHR, ajaxSettings) {
            // var now = new Date().getTime() / 1000;
            // if ((now - lastSuccessCall) > 180) {
            //     if (!raceBetsJS.application.globals.ajaxErrorOverlay) {
            //         raceBetsJS.application.globals.ajaxErrorOverlay = true;
            //         raceBetsJS.application.assets.modalDialog.show({
            //             type: 'attention',
            //             title: raceBetsJS.i18n.data.titleDisconnection,
            //             content: raceBetsJS.i18n.data.internetDisconnection,
            //             closeButton: false,
            //             buttonAction: function () {
            //                 raceBetsJS.application.assets.overlay.close();
            //                 raceBetsJS.application.assets.overlay.showPleaseWait();
            //                 location.reload();
            //             },
            //             buttonCaption: raceBetsJS.i18n.data.buttonReload
            //         });
            //     }
            // } else {
            //     lastSuccessCall = now;
            // }

            if (isAjaxHostCall(ajaxSettings.url)) {
                retryAjaxHostDelay = 2; // reset ajax retry to default value
            }
        });

        var htmlElem = document.getElementsByTagName('html')[0];
        raceBetsJS.application.globals.isB2B = htmlElem.getAttribute('data-client') !== 'standalone';
        raceBetsJS.application.globals.brandName = htmlElem.getAttribute('data-brand');

        if (raceBetsJS.application.globals.isB2B &&
            raceBetsJS.browser.params.get('redirect') === 'false') {
            var currentDate = new Date();
            $.cookie('rbredirect', 'false', {
                secure: true,
                path: '/',
                domain: raceBetsJS.application.assets.settings.getCookieDomain(),
                expires: new Date(currentDate.setFullYear(currentDate.getFullYear()+1))
            });
        }

        if (raceBetsJS.application.globals.isB2B &&
            !raceBetsJS.application.globals.isTest &&
            window.self === window.top &&
            !$.cookie('rbredirect')
        ) {
            raceBetsJS.application.assets.overlay.showPleaseWait();

            if (raceBetsJS.application.globals.brandName === 'europebet') {
                window.location.href = raceBetsJS.application.assets.settings.getBrandSettings('env', 'prodURI')
                    + '/' + raceBetsJS.application.user.lang
                    + '/games/sportsbook/horse-racing?innerUrl=/'
                    + window.location.pathname.substr(1);
            } else if (['betssonarbacity', 'betssonarbaprovince'].indexOf(raceBetsJS.application.globals.brandChildName) > -1) {
                window.location.href = raceBetsJS.application.assets.settings.getBrandSettings('env', 'prodURI')
                    + '/horse-racing/'
                    + window.location.pathname.substr(1);
            } else {
                window.location.href = raceBetsJS.application.assets.settings.getBrandSettings('env', 'prodURI')
                    + '/' + raceBetsJS.application.user.lang
                    + '/horse-racing/'
                    + window.location.pathname.substr(1);
            }

            return;
        }

        // set url prefix
        if (raceBetsJS.application.globals.isB2B) {
            raceBetsJS.application.globals.urlPrefix = '';
        } else {
            var lang = (raceBetsJS.application.globals.brandName === 'suaposta' && raceBetsJS.application.user.lang === 'pt') ? 'br' : raceBetsJS.application.user.lang;
            raceBetsJS.application.globals.urlPrefix = '/' + lang + '/' + raceBetsJS.application.globals.urlKeyword;
        }

        // is popup or not?
        raceBetsJS.application.globals.isPopup = ($('#racebets').data('mode') === 'popup');

        if (raceBetsJS.application.globals.isPopup) {
            raceBetsJS.application.globals.contentContainer = $('#body');
            raceBetsJS.application.globals.contentMain = $('#popup');
        } else {
            raceBetsJS.application.globals.contentContainer = $('#content-container');
            raceBetsJS.application.globals.contentMain = $('#content-main');
        }

        // Global Delegators
        /**
         * Disabled to make angularJS router do the routing stuff
         * @author Sandor Turanszky
         */
        // $(document).on('click', 'a.ajaxify', raceBetsJS.browser.history.handleAnchorClick);

        /**
         * Subscribe to postMessages
         */
        raceBetsJS.browser.crossFrame.init(raceBetsJS.application.assets.settings.getBrandSettings('env', 'prodOrigin'));

        if (raceBetsJS.application.globals.webSockets) {
            raceBetsJS.webSockets.nodeJS.join({
                channel : 'versionUpdate',
                callback : function(){
                    raceBetsJS.application.globals.reloadPage = true;
                }
            });
        }

        // prevent "click-outside" tipped tooltips to hide, when interacting with the overlay
        $('body').delegate('click', '.overlay', function(event) {
            event.stopPropagation();
        });

        // only in full version
        if (!raceBetsJS.application.globals.isPopup) {
            raceBetsJS.application.header.init();
        }

        raceBetsJS.application.content.init();
        // last but not least, login in node
        // Moved to Angular's Account Widget push event service
        // raceBetsJS.webSockets.nodeJS.login();


        // cookie notification
        // var cookieName = 'cookieNotice';
        // if ($.cookie(cookieName) !== 'true') {
        //     var $cookieInfoBox = $(raceBetsJS.application.templates.cookieNotification());
        //     $cookieInfoBox.hide().prependTo('body');

        //     $('#cookieNotification .more').on('click', function(e) {
        //         e.preventDefault();
        //         var popupOptions = {
        //             href: $(this).attr('href'),
        //             name: 'helpPopup',
        //             height: 564,
        //             width: 980,
        //             scroll: 'yes',
        //             resizable: 'yes'
        //         };
        //         raceBetsJS.browser.popup.open(popupOptions);
        //     });

        //     $cookieInfoBox.slideDown();

        //     $cookieInfoBox.on('click', 'button', function() {
        //         // set cookie
        //         $.cookie(cookieName, 'true', {
        //             secure: true,
        //             path: '/',
        //             domain: raceBetsJS.application.assets.settings.getCookieDomain(),
        //             expires: 365
        //         });

        //         //$.cookie("cookie_notification","true");
        //         $cookieInfoBox.slideUp(function() {
        //             $cookieInfoBox.remove();
        //         });
        //     });
        // }
    };

    $(document).ready(function() {
        var brand = $('html').data('brand');
        // load timezones
        timezoneJS.timezone.zoneFileBasePath = '/scripts/lib/timezone-js/timezones';
        timezoneJS.timezone.defaultZoneFile = 'europe';
        timezoneJS.timezone.init({
            callback: function() {
                // start application
                raceBetsJS.application.init(brand);
            }
        });
    });

})(raceBetsJS);
