/**
* Home
*
* Homepage
*/
(function(raceBetsJS) {
    raceBetsJS.application.content.home = function() {
        // @private

        var areas = {
                top: {},
                middle: {
                    left: {},
                    right: {}
                }
            },
            widgets = {},
            timer = null,
            refreshRate = 60,
            timerStatus = 0;

        var initWidgets = function(data){
            widgets.banner = raceBetsJS.application.content.home.banner.init((data.banner != null) ? data.banner.header : {});
            widgets.nextRaces = raceBetsJS.application.content.home.nextRaces.init(data.nextRaces);
            widgets.antePostRunners = raceBetsJS.application.content.home.antePostRunners.init(data.antePostRunners);
            widgets.horsesAbroad = raceBetsJS.application.content.home.horsesAbroad.init(data.horsesAbroad);
            widgets.europeansAbroad = raceBetsJS.application.content.home.europeansAbroad.init(data.europeansAbroad);
            widgets.nextH2H = raceBetsJS.application.content.home.head2head.init(data.nextH2H);
            widgets.highlights = raceBetsJS.application.content.home.highlights.init(data.highlights);
            widgets.marketMovers = raceBetsJS.application.content.home.marketMovers.init(data.marketMovers);
            widgets.mostBet = raceBetsJS.application.content.home.mostBet.init(data.mostBetted);
            widgets.raceOfTheDay = raceBetsJS.application.content.home.raceOfDay.init(data.raceOfTheDay);
            widgets.topRatio = raceBetsJS.application.content.home.topRatio.init(data.topRatio);
            widgets.stars = raceBetsJS.application.content.home.stars.init(data.stars);
            widgets.tips = raceBetsJS.application.content.home.tips.init(data.tips);
            widgets.topWinnings = raceBetsJS.application.content.home.topWinnings.init(data.topWinnings);

            if (data.antePostRaces !== undefined && data.antePostRaces.length > 0) {
                widgets.antePostRaces = raceBetsJS.application.content.home.antePostRaces.init(data.antePostRaces);
            }
        };

        var initAreas = function(){
            areas.top.left = raceBetsJS.application.globals.contentMain.find('.m-home__widgetAreaTopCol1');
            areas.top.right = raceBetsJS.application.globals.contentMain.find('.m-home__widgetAreaTopCol2');
            areas.middle.left.top = raceBetsJS.application.globals.contentMain.find('.m-home__widgetAreaMidLeftTop');
            areas.middle.left.col1 = raceBetsJS.application.globals.contentMain.find('.m-home__widgetAreaMidLeftCol1');
            areas.middle.left.col2 = raceBetsJS.application.globals.contentMain.find('.m-home__widgetAreaMidLeftCol2');
            areas.middle.right = raceBetsJS.application.globals.contentMain.find('.m-home__widgetAreaMidRight');
        };

        var addToArea = function(widget, area){
            if(widget){
                widget.render(area);
            }
        };

        /**
        * initHome
        *
        * Initiate Homepage when data has been received
        */
        var initHome = function(data, update) {

            if(!update){
                removeWidgets();

                raceBetsJS.application.header.navigation.activateItemByKey('home');

                //Spit out content
                raceBetsJS.application.globals.contentMain.html($(raceBetsJS.application.templates.home()));

                var handle = raceBetsJS.application.globals.contentMain.find('#seo_text_home_page');

                handle.html(
                    raceBetsJS.application.angularApp.commonCtrl().getSEOText()
                );
                
                raceBetsJS.application.globals.contentMain.find('#show_more').click(function(){
                    handle.toggleClass('extended')
                    var arrow = $(this).find('.c-caret');
                    var txt = $(this).find('#button_seo_text');
              
                    if (arrow.hasClass('toDown')) {
                        txt.html(raceBetsJS.i18n.data.labelShowLess)
                        arrow.removeClass('toDown').addClass('toUp');
                    } else { 
                        txt.html(raceBetsJS.i18n.data.labelShowMore)
                         arrow.removeClass('toUp').addClass('toDown');
                    }

                })

                //Initialize areas
                initAreas();

                // Track home page visits
                dataLayer.push({
                    'event': 'HomeVisits'
                });
            }


            //Inititalize widgets
            initWidgets(data);

            raceBetsJS.application.content.home.banner.render()

            // Top
            // banner is added and rendered independently
            // addToArea(widgets.banner, areas.top.left);
            addToArea(widgets.antePostRaces, areas.top.right);

            // Middle Left
            addToArea(widgets.nextRaces, areas.middle.left.top);
            addToArea(widgets.highlights, areas.middle.left.top);
            addToArea(widgets.mostBet, areas.middle.left.col1);
            addToArea(widgets.antePostRunners, areas.middle.left.col1);
            addToArea(widgets.tips, areas.middle.left.col1);
            addToArea(widgets.stars, areas.middle.left.col1);
            addToArea(widgets.raceOfTheDay, areas.middle.left.col1);
            addToArea(widgets.topRatio, areas.middle.left.col2);
            addToArea(widgets.topWinnings, areas.middle.left.col2);

            // Middle Right
            addToArea(widgets.nextH2H, areas.middle.right);
            addToArea(widgets.horsesAbroad, areas.middle.right);
            addToArea(widgets.marketMovers, areas.middle.right);
            addToArea(widgets.europeansAbroad, areas.middle.right);

            // check for unlock or verification link processing
            var apiStatus = raceBetsJS.browser.params.get('status');
            var apiType = raceBetsJS.browser.params.get('type');
            
            if (apiStatus !== false && apiType !== false) {
                var dictElem = 'apiStatus' + raceBetsJS.string.ucfirst($.camelCase(apiType));
                raceBetsJS.application.assets.messageBox.show({ type: apiStatus, content: raceBetsJS.i18n.data[dictElem] });
                raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix +  '/');
            }

            startTimer();

        };

        var refresh = function(){
            if(!raceBetsJS.application.assets.bettingDialog.isVisible()){
                stopTimer();
                $.getJSON(
                    raceBetsJS.application.content.getAjaxCacheUrl('/ajax/home/content/version/short/'),
                    {polling: true},
                    //'/json_store/content_short.js',
                    function(json){
                        initHome(json, true);
                        startTimer();
                    }
                );
            } else {
                startTimer();
            }
        };

        var removeWidgets = function(){
            $.each(
                widgets,
                function(key, value){
                    if(value){
                        this.remove();
                    }
                }
            );
        };

        var startTimer = function(){
            timerStatus = 1;
            timer = setTimeout(
                function(){
                    if(timerStatus == 1){
                        refresh();
                    }
                },
                refreshRate * 1000
            );
        };

        var stopTimer = function(){
            if(timer != null && timerStatus == 1){
                timerStatus = 0;
                clearTimeout(timer);
                timer = null;
            }
        };


        // @public
        return {
            init: function() {
                //$.log('Init Home');

                // Setup route controller
                raceBetsJS.application.contentController.addDynamicPage({
                    urlPattern: /^\/+$|^\/home($|\/)|^\/index\.php/,
                    dataSourceURLBuilder: function() {
                        //$.log('Data Source URL', raceBetsJS.application.content.getAjaxCacheUrl('/ajax/home/content/version/detailed/'));
						return raceBetsJS.application.content.getAjaxCacheUrl('/ajax/home/content/version/detailed/');
						//return '/json_store/content_detailed.js';
                    },
                    onDataReceived: initHome,
                    onUnload: function() {
                        removeWidgets();
                        stopTimer();
                    }
                });
            },
            refresh: refresh,
            widgets: widgets
        };
    }();
})(raceBetsJS);
