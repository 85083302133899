/**
 * ContentController.js
 *
 * @author Moritz Honig
 */
(function (raceBetsJS) {
    raceBetsJS.content.ContentController = Class.extend({
        init: function (options) {
            var defaults = {
                onContentLoaded: null
            }
            this.options = $.extend({}, defaults, options || {});
            this.dynamicRouter = new raceBetsJS.content.Router();
            this.callbackRouter = new raceBetsJS.content.Router();

            var dfsRooute = location.pathname.indexOf('/betssonfc/futebol/') !== -1

            // Do not make http requests for the DFS widget
            if(dfsRooute) {
                raceBetsJS.application.header.navigation.activateItemByKey('game');
            }
        },

        /**
         * Loads content by ajax
         */
        loadContent: function (url, onSuccess, onUnload, dataType, requiresLogin) {
            var dfsRooute = location.pathname.indexOf('/betssonfc/futebol/') !== -1

            // Do not make http requests for the DFS widget
            if(dfsRooute) {
                return
            }

            // Invoke existing onUnload
            if (this.onUnload) {
                this.onUnload();
                this.onUnload = null;
            }

            // Abort pending Ajax.Request
            if (this.pendingRequest) {
                this.pendingRequest.abort();
                this.pendingRequest = null;
            }

            // frontend: show overlay
            // it wont be displayedon popups
            var showIndicator = new raceBetsJS.time.Timeout({
                delay: 500,
                callback: function () {
                    if (!raceBetsJS.application.globals.isPopup) {
                        raceBetsJS.application.globals.contentMain.empty().append($('<div />').addClass('loading-content'));
                    }
                }
            });

            // Perform Ajax request
            this.pendingRequest = new $.ajax({
                url: url,
                context: this,
                dataType: (dataType !== undefined) ? dataType : 'html',
                success: function (data, status, xhrObj) {

                    // scroll to top
                    window.scrollTo(0, 0);
                    showIndicator.stop();

                    // Clear pending request
                    this.pendingRequest = null;

                    // Set the onUnload callback
                    this.onUnload = onUnload;

                    // Invoke the onSuccess callback, if any
                    if (onSuccess) {
                        onSuccess(data);
                    }

                    // Call onContentLoaded
                    if (this.options.onContentLoaded) {
                        this.options.onContentLoaded(url);
                    }

                    if (raceBetsJS.application.globals.angularApp === false) {
                        //originally there were only this two strings:
                        var hideSidebar = xhrObj.getResponseHeader('hideSidebar');
                        var hideRightbar = xhrObj.getResponseHeader('hideRightbar');
                        var isFantasy = xhrObj.getResponseHeader('isFantasy');
                        raceBetsJS.application.content.hideSidebar((hideSidebar == 'true'));
                        raceBetsJS.application.content.hideRightbar((hideRightbar == 'true'));
                        raceBetsJS.application.content.toggleFantasy((isFantasy == 'true'));
                    }
                },

                error: function (xhr, textStatus, errorThrown) {
                    // Clear pending request
                    this.pendingRequest = null;

                    showIndicator.stop();
                }
            });
        },

        updateUserMethodsInMemory: function(value) {
            this.existing_methods_data.user_methods = value;
        },

        /**
         * Add content loaded callback
         */
        addCallback: function (urlPattern, callback) {
            this.callbackRouter.route(urlPattern).to(callback);
        },

        /**
         * Trigger Callback
         */
        triggerCallback: function (url) {
            return this.callbackRouter.trigger(this.getCleanURL(url));
        },

        /**
         * Add a dynamic page
         */
        addDynamicPage: function (page) {
            //$.log('Adding dynamic page on: ' + page.urlPattern);

            this.dynamicRouter.route(page.urlPattern).to($.proxy(function () {
                var url = page.dataSourceURLBuilder.apply(null, arguments);

                if (url !== false) {
                    this.loadContent(
                        url,
                        page.onDataReceived,
                        page.onUnload,
                        page.dataType || null,
                        page.requiresLogin || null
                    );

                } else {
                    // scroll to top
                    window.scrollTo(0, 0);
                }
            }, this));
        },

        /**
         * Is dynamic
         */
        isDynamic: function (url) {
            return this.dynamicRouter.match(this.getCleanURL(url)) !== undefined;
        },

        /**
         * Decide if to load flat or dynamic content
         */
        navigate: function (url) {
            //$.log('navigate');

            var triggerUrl = this.getCleanURL(url);

            //$.log('Trigger the router: ' + (triggerUrl || url));

            // Trigger the router
            if (!this.dynamicRouter.trigger(triggerUrl || url)) {
                // ... and if no dynamic pages match load flat content.
                this.loadFlatContent(url);
            }
        },

        /**
         * Load flat content
         */
        loadFlatContent: function (url) {
            this.loadContent(url, $.proxy(function (data) {
                raceBetsJS.application.globals.contentMain.html(data);

                // load right column if neccessary
                raceBetsJS.application.assets.rightColumn.init();

                this.triggerCallback(url);
            }, this));
        },

        /**
         * Get clean URL (without prefix)
         */
        getCleanURL: function (url) {
            //$.log('Cleaning up URL: ' + url + ' with urlPrefix: ' + raceBetsJS.application.globals.urlPrefix + '');
            if (raceBetsJS.application.globals.urlPrefix !== '') {
                url = url.replace(/^\/\w{2}\/[^\/|$]*/g, ''); // fix
            }

            //$.log('Result: ' + url);

            /*
             if (url.indexOf(raceBetsJS.application.globals.urlPrefix) == 0) {
             url = url.substr(raceBetsJS.application.globals.urlPrefix.length);
             }
             */

            return url;
        }
    });
})(raceBetsJS);
