/**
* Bonuses
*
* @author Robin Ebers
*/

(function(raceBetsJS) {
    raceBetsJS.application.content.bonuses = function() {
        // @private

        var DOMElements,
            forceFreeBets = false;

        // ERROR MAPPING
        var bonusErrorMap =  $.extend({}, raceBetsJS.application.content.cashier.getErrorMap(), {
            INVALID_BONUS_ID:       'msgInvalidVoucherCode',
            BONUS_ALREADY_SELECTED: 'msgBonusCodeUsed',
            CODE_USED: 'msgBonusCodeUsed',
            NO_TURNOVER: 'msgBonusNoTurnover',
            OPEN_RACES: 'msgBonusOpenRaces',
            BONUS_SUBSCRIPTION_EXPIRED: 'msgSubscribeErrorExpired',
            BONUS_SUBSCRIPTION_EXISTS: 'msgSubscribeErrorAlreadySubscribed',
            BDY_OFFER_NOT_VALID: 'msgBirthdayBonusExpired',
            OFFER_NOT_VALID: 'msgBonusCodeUnknown'
        });

        var ProgressBar = Class.extend({
            init: function(sets){
                this.sets = $.extend({
                    width : 0,
                    left : 0,
                    progress: 0,
                    prefix: "_",
                    bubbleAmount: 0,
                    container: null,
                    animationTime: 0,
                    bubbleAnimationTime: 0,
                    alone: true,
                    callback : null
                },sets);
            },
            getHtml: function(){
            },
            animate: function(animate) {

                var sets = this.sets,
                newWidth = parseFloat((sets.progress * sets.width)/100).toFixed(2),
                completedAmount =  raceBetsJS.format.money(sets.completedAmount, 2, raceBetsJS.application.user.currency, true),
                leftLimit = 70,
                completedAmountElement;

                newWidth = ((newWidth >0)&&(newWidth < 5)) ? 5 : newWidth;

                if(animate) {

                    $("#"+sets.prefix+"-bar").find(".green-bar").animate({width:newWidth+"px"},sets.animationTime,"linear",function(){
                        if(sets.progress == 100){
                            $("#"+sets.prefix+"-bar").find(".green-bubble").animate({width:"40px"},sets.bubbleAnimationTime,"linear",
                            function() {
                                $("#"+sets.prefix+"-bar").find(".green-bubble .white-tick").fadeIn({duration:300});
                                if(sets.callback){
                                    sets.callback();
                                }
                            });
                        }else {
                            completedAmountElement = $("<div></div>").addClass("currentAmount").html(completedAmount).hide().appendTo("#"+sets.prefix+"-bar .gray-bar");
                            completedAmountElement.css({
                                left: ( sets.progress > leftLimit) ? $(this).width()-completedAmountElement.width()-10+"px" : $(this).width()+10+"px"
                            }).show();
                        }
                    });

                }else {

                    $("#"+sets.prefix+"-bar").find(".green-bar").css({width:newWidth+"px"});


                    if(sets.progress == 100){
                        $("#"+sets.prefix+"-bar").find(".green-bubble").css({width:"40px"});

                            $("#"+sets.prefix+"-bar").find(".green-bubble .white-tick").show();
                            if(sets.callback){
                                sets.callback();
                            }

                    }else {
                        completedAmountElement = $("<div></div>").addClass("currentAmount").html(completedAmount).hide().appendTo("#"+sets.prefix+"-bar .gray-bar");
                        completedAmountElement.css({
                            left: ( sets.progress > leftLimit) ? $(this).width()-completedAmountElement.width()-10+"px" : $(this).width()+10+"px"
                        }).show();
                    }


                }
            },
            render: function() {
                var sets = this.sets;
                var bubbleLeft = (sets.type == "activate") ? sets.width+sets.left-4 : sets.width+sets.left-5;
                var bar;

                sets.container.append(raceBetsJS.application.templates.bonuses.progressBar(sets));

                bar = $("#"+sets.prefix+"-bar");

                if(sets.alone && sets.type == "main"){
                    bar.addClass("alone");
                }
                bar.find(".gray-bar").css({width:sets.width+"px",left:sets.left+"px"});
                bar.find(".gray-bubble,.green-bubble").css({left:bubbleLeft+"px"});

                if(sets.type == "main") {
                    bar.find(".top-label").html(raceBetsJS.i18n.data.labelWithdrawable);
                    bar.find(".sub-label").html(
                        raceBetsJS.format.money(sets.bubbleAmount , 2, raceBetsJS.application.user.currency, true)
                    );
                }
                else if(sets.type == "activate") {
                    bar.find(".top-label").css({left:bubbleLeft+"px"}).html(raceBetsJS.i18n.data.labelActivated);
                    bar.find(".sub-label").css({
                        left: bubbleLeft-16+"px"

                    }).html(
                        raceBetsJS.format.money(sets.bubbleAmount, 2, raceBetsJS.application.user.currency, true)
                    );
                }

            }
        });

        function onContentLoaded(bonusData) {

            var maxWidth = 505,
                completeTime = 1500, // Total time plus bubbles to complete both bars
                activeBarWidth = activeProgress = mainBarWidth = mainBarProgress = 0,
                bubbleWidth = 40,
                velocityFactor = completeTime / maxWidth,
                greenWidth,
                activeChunkBars = {},
                mainChunkBars = {},
                stopSubmit = false,
                finalContainer,
                dropDownOutInterval,
                currentActivatedBonus =
                    ((!$.isEmptyObject(bonusData.currentBonus)) &&  (!$.isEmptyObject(bonusData.currentBonus.chunk)));

            raceBetsJS.application.header.navigation.activateItemByKey('account bonus');

            function initToolTips() {
                Tipped.create('#pendingBonuses span.icon.conditions',
                    function(elem) {
                        return raceBetsJS.i18n.print('tooltipConditions', {
                            activationTurnover: raceBetsJS.format.money($(elem).data('actturnover'), 2, raceBetsJS.application.user.currency),
                            withdrawalTurnover: raceBetsJS.format.money($(elem).data('cshturnover'), 2, raceBetsJS.application.user.currency)
                        });
                    },
                    {
                        hook: 'topmiddle'
                    }
                );
                Tipped.create('#pending-offers span.icon.info.expiry-date', raceBetsJS.i18n.data.tooltipExpiryDate, {
                    hook: 'topmiddle'
                });
            }

            function preSelectCheckboxes() {
                // pre-select pending bonuses
                if(!bonusData.activeBonus && (!$.isEmptyObject(bonusData.pendingOffers))) {
                    var checkbox = DOMElements.pendingTable.find('input[type=checkbox][value=' + bonusData.pendingOffers[0].idBonus + ']');

                    DOMElements.pendingTable.find('input[type=checkbox]').prop('disabled', true);
                    checkbox.prop('checked',true).prop('disabled', false);

                    if(checkbox.data('combinable') == '1') {
                        DOMElements.pendingTable.find('input[type=checkbox][data-combinable=1]').prop('disabled',false).prop('checked',true);
                    }

                    activateRedeemButton();
                }
            }

            function displayChunkBars(activeChunk, finalContainer, animate) {
                var mainBar,activeBar;
                function setMainBar(mainBarSets) {

                    var sets = $.extend({
                        left: 0,
                        alone: true
                    }, mainBarSets);

                    var barData = mainBarSets.barData,
                        mainBarWidth = ( barData.activateAmount > 0 ) ? parseInt ( maxWidth - ( (barData.activateAmount * maxWidth) / barData.withdrawAmount ) ) : maxWidth,
                        mainBarProgress = (barData.activateAmount >= barData.completedAmount) ? 0 : ((barData.completedAmount - barData.activateAmount)/(barData.withdrawAmount - barData.activateAmount)*100),
                        greenWidth = parseInt((mainBarProgress * mainBarWidth)/100);

                    mainBar = mainChunkBars[activeChunk.idBonusChunk] = new ProgressBar({
                        type :                  'main',
                        width :                 mainBarWidth,
                        left :                  sets.left,
                        progress:               mainBarProgress,
                        animationTime:          parseInt((greenWidth * velocityFactor)),
                        bubbleAnimationTime:    parseInt((bubbleWidth * velocityFactor)),
                        prefix:                 activeChunk.idBonusChunk+"-main",
                        bubbleAmount:           barData.withdrawAmount,
                        container:              finalContainer,
                        alone:                  sets.alone,
                        completedAmount:        barData.completedAmount
                    });
                }

                var barData = {
                    withdrawAmount : parseFloat(activeChunk.cshTurnover),
                    activateAmount : parseFloat(activeChunk.actTurnover),
                    completedAmount : parseFloat(activeChunk.cshTurnover) - parseFloat(activeChunk.remainingCshTurnover)
                };

                if (barData.activateAmount > 0) {

                    activeBarWidth = parseInt((barData.activateAmount * maxWidth) / barData.withdrawAmount);
                    activeProgress = parseInt((barData.activateAmount <= barData.completedAmount ) ? 100 : ((barData.completedAmount / barData.activateAmount) * 100));
                    setMainBar({
                        left: activeBarWidth+bubbleWidth-10,
                        alone: false,
                        barData: barData
                    });

                    greenWidth = parseInt((activeProgress * activeBarWidth)/100);

                    activateBar = activeChunkBars[activeChunk.idBonusChunk] =  new ProgressBar({
                        type:                   'activate',
                        width:                  activeBarWidth,
                        left:                   0,
                        progress:               activeProgress,
                        animationTime:          parseInt( ( greenWidth * velocityFactor) ),
                        bubbleAnimationTime:    parseInt( ( bubbleWidth * velocityFactor ) ),
                        prefix:                 activeChunk.idBonusChunk+"-activate",
                        bubbleAmount:           barData.activateAmount,
                        container:              finalContainer,
                        completedAmount:        barData.completedAmount,
                        callback:               $.proxy(function(){
                                                    mainBar.animate(animate);
                                                }, mainBar)
                    });


                    mainBar.render();
                    activateBar.render();
                    activateBar.animate(animate);
                }
                else{
                    maxWidth += 30;
                    setMainBar({barData: barData});
                    mainBar.render();
                    mainBar.animate(animate);
                }

                $('<div />').addClass("cero").html(raceBetsJS.format.money(0, 2, raceBetsJS.application.user.currency, true)).appendTo(finalContainer);
            }

            bonusData.activeBonus = (!$.isEmptyObject(bonusData.currentBonus));

            bonusData.displayBonusBox = (raceBetsJS.application.user.noBonus === false || raceBetsJS.application.user.email.indexOf('@betssongroup.com') > -1) ? true : false;

            bonusData.helpLink =  raceBetsJS.application.assets.settings.getBrandSettings('supportURLs', 'bonuses');

            bonusData.showHelpLink = raceBetsJS.application.globals.brandName === 'racebets'


            // populate DOM
            raceBetsJS.application.globals.contentMain.html(
                raceBetsJS.application.templates.bonuses({
                    data: bonusData
                })
            );

            // cache DOM elements
            DOMElements = {
                innerContent: $('#pending-offers .inner'),
                tabs: $('#pending-offers .tabs'),
                section: $('#section-bonuses'),
            };

            // tabs logic
            function activateTab(tab) {
                var elem = DOMElements.tabs.find('[data-tab="' + tab + '"]');

                // button activity
                DOMElements.tabs.find('li').removeClass('active');
                elem.addClass('active');

                // add class to content area
                DOMElements.section.removeClass('freebets bonuses').addClass(tab);

                // fill DOM
                DOMElements.innerContent.empty().html(
                    raceBetsJS.application.templates.bonuses['tab' + raceBetsJS.string.ucfirst(tab)]({
                        data: bonusData
                    })
                );

                // Set listeners for freebet list of countries
                $('.freebets-countries-more').on('click', function() {
                    // show dialog
                    raceBetsJS.application.assets.modalDialog.show({
                        content: $(this).parent().data('countries'),
                        title: raceBetsJS.i18n.data.labelAvailableCountries
                    });

                });
            }

            DOMElements.tabs.on('click', 'li:not(.disabled,.active)', function() {
                var elem = $(this);
                activateTab(elem.data('tab'));
                if(elem.data('tab')=='bonuses') {
                    preSelectCheckboxes();
                }
            });

            // activate one of the tabs, if appropriate
            if(forceFreeBets) {
                activateTab('freebets');
            } else {
                if (_.size(bonusData.pendingOffers) > 0) {
                    activateTab('bonuses');
                } else if (_.size(bonusData.freebets) > 0) {
                    activateTab('freebets');
                }
            }

            $.extend(DOMElements,{
                pendingTable: $('#pendingBonuses'),
                submitCodeForm: $('#form-deposit-bonus'),
                activateBonusButton: $('#activateBonusButton'),
                activeBonusTable: $('#active-bonus-table'),
                bonusCodeInput: $('#bonusCodeInput'),
                bonusCodeButton: $('#bonusCodeButton'),
            });

            // "resume mobile verification" binding
            $('#pending-offers').on('click', 'div.mobile-verification', function() {
                raceBetsJS.application.assets.mobileVerification.show({
                    startStep: 'two'
                });
            });

            // Focus bonus input
            DOMElements.bonusCodeInput.focus();

            // See details binding
            DOMElements.activeBonusTable.on('click', 'a.see-details', function(event) {
                event.preventDefault();

                var jqxhr = $.ajax({
                    type: 'post',
                    url: '/ajax/bonuses/listChunkTansactions',
                    data: {
                        idBonusChunk: $(this).data('idbonuschunk')
                    },
                    beforeSend: function(){
                        if(stopSubmit) {
                            return false;
                        }else{
                            stopSubmit = true;
                        }
                    }
                })
                .done(function(data) {
                    if(data.length > 0) {
                        // populate table with transactions
                        var content = raceBetsJS.application.templates.bonuses.detailedTable({data: {chunks:data}});
                        raceBetsJS.application.assets.modalDialog.show({
                            content: content,
                            width: 580,
                            buttons: false,
                            title: raceBetsJS.i18n.data.titleRelevantStakes,
                            className: 'no-padding'
                        });

                        $('table.bonus-detail-table').on('click', 'a[data-public-id]', function(e) {
                                e.preventDefault();

                                raceBetsJS.application.assets.betDetails.show({
                                    id: $(this).data('public-id')
                                });
                            }
                        );
                    }
                })
                .always(function() {
                    stopSubmit = false;
                });
            });

            if (currentActivatedBonus) {
                finalContainer = $("#chunk-"+bonusData.currentBonus.chunk.idBonusChunk+" .progress-bar-area");
                displayChunkBars(bonusData.currentBonus.chunk,finalContainer,true);
            }


            // activate bonus form
            var getSelectedOffers = function() {
                return DOMElements.pendingTable.find("input[type=checkbox]:checked").map(function(){
                      return $(this).val();
                }).get();
            };

            // check if mobile verification is required
            var isMobileVerificationRequired = function() {
                return (DOMElements.pendingTable.find("input[type=checkbox][data-mobile-verification=1]:checked").length > 0);
            };

            // activeBonus form binding
            stopSubmit = false;

            DOMElements.bonusCodeInput.on('keyup', function() {
                if ($(this).val().length > 0) {
                    DOMElements.bonusCodeButton.addClass('btn-primary');
                } else {
                    DOMElements.bonusCodeButton.removeClass('btn-primary');
                }
            });

            var bonusCodeSent;

            DOMElements.submitCodeForm.ajaxForm({
                beforeSubmit: function(arr) {
                    bonusCodeSent = arr[0];

                    if(stopSubmit || DOMElements.bonusCodeInput.val() === ''){
                        DOMElements.bonusCodeInput.focus();
                        return false;
                    } else{
                        stopSubmit = true;
                    }
                },
                success: function(data) {
                    // update login info
                    // raceBetsJS.application.header.login.updateLoginInfo();

                    if (data.type == 'success') {

                        // activate appropriate tab
                        if (data.promotionType === 'freebet' && getActiveTab() !== 'freebet') {
                            activateTab('freebets');

                        } else if (getActiveTab() !== 'bonuses') {
                            activateTab('freebets');
                        }

                        if (data.promotionType === 'freebet' || data.promotionType === 'freebets') {
                            showRedeemMessage(data);

                        } else {

                            if (data.idBonus !== undefined) {
                                if(currentActivatedBonus) {
                                    raceBetsJS.application.assets.modalDialog.show({
                                        content: raceBetsJS.i18n.data.msgBonusAdded,
                                        type:'success',
                                        closeButton: true,
                                        buttonAction: function(){
                                            raceBetsJS.browser.history.navigateTo('/offers/bonuses');
                                            raceBetsJS.application.assets.overlay.close();
                                        }
                                    });

                                } else {

                                    var bonusDialogButtons = [
                                        {
                                            label: data.idBonus === 0 ? raceBetsJS.i18n.data.buttonOK : raceBetsJS.i18n.data.buttonNo,
                                            action: function() {
                                                raceBetsJS.browser.history.navigateTo('/offers/bonuses');
                                                raceBetsJS.application.assets.overlay.close();
                                            }
                                        }
                                    ];

                                    if (data.idBonus !== 0) {
                                        bonusDialogButtons.push({
                                            label: raceBetsJS.i18n.data.buttonYes,
                                            active: true,
                                            action: function() {
                                                raceBetsJS.application.assets.overlay.close();
                                                raceBetsJS.application.assets.overlay.showPleaseWait();

                                                var jqxhr = $.ajax({
                                                    type: 'post',
                                                    url: '/ajax/bonuses/selectBonuses',
                                                    data: {
                                                        bonusesIds: $.stringify([data.idBonus])
                                                    }
                                                })
                                                    .done(function(data) {
                                                        raceBetsJS.application.assets.overlay.close();

                                                        switch(data.type){
                                                            case 'error':
                                                                if (bonusErrorMap[data.errorMsg] !== undefined) {
                                                                    raceBetsJS.application.assets.modalDialog.show({
                                                                        content: raceBetsJS.i18n.data[bonusErrorMap[data.errorMsg]],
                                                                        type:'error',
                                                                        closeButton: true
                                                                    });

                                                                } else if (data.errorMsg == 'MOBILE_VERIFICATION_REQUIRED') {
                                                                    // mobile verification
                                                                    if (raceBetsJS.application.user.mobileVerified === false) {
                                                                        // mobile not yet verified, reverse checkbox status and show mobile verification instead
                                                                        raceBetsJS.browser.history.navigateTo('/offers/bonuses');
                                                                        raceBetsJS.application.assets.mobileVerification.show();
                                                                        return;
                                                                    }

                                                                } else {
                                                                    raceBetsJS.application.assets.modalDialog.generic(data.errorMsg);
                                                                }
                                                                break;

                                                            case 'success':

                                                                raceBetsJS.application.assets.modalDialog.show({
                                                                    content: raceBetsJS.i18n.data.msgBonusRedeemed,
                                                                    type: 'success',
                                                                    closeButton: true,
                                                                    buttonAction: function(){
                                                                        raceBetsJS.browser.history.navigateTo('/offers/bonuses');
                                                                        raceBetsJS.application.assets.overlay.close();
                                                                        // raceBetsJS.application.header.login.updateLoginInfo();
                                                                    }
                                                                });

                                                                break;
                                                        }
                                                    });
                                            }
                                        });
                                    }

                                    // ask to activate bonus
                                    raceBetsJS.application.assets.modalDialog.show({
                                        content: data.idBonus === 0 ? raceBetsJS.i18n.data.msgBonusClaimed : raceBetsJS.i18n.data.msgActivateBonus,
                                        type: 'success',
                                        closeButton: true,
                                        buttons: bonusDialogButtons
                                    });
                                }

                            } else {
                                var successMessage = (data.promotionType === 'subscription' ? 'msgSubscribeSuccess' : 'msgBonusAdded');
                                raceBetsJS.application.assets.modalDialog.show({
                                    content: raceBetsJS.i18n.data[successMessage],
                                    type:'success',
                                    closeButton: true,
                                    buttonAction: function(){
                                        raceBetsJS.browser.history.navigateTo('/offers/bonuses/');
                                        raceBetsJS.application.assets.overlay.close();
                                    }
                                });
                            }
                        }

                    } else {
                        if (bonusErrorMap[data.errorMsg] !== undefined) {
                            raceBetsJS.application.assets.modalDialog.show({
                                content: raceBetsJS.i18n.data[bonusErrorMap[data.errorMsg]],
                                type:'error',
                                closeButton: true
                            });

                        } else if (data.errorMsg === 'BONUS_SUBSCRIPTION_LIMIT') {
                            raceBetsJS.application.assets.modalDialog.show({
                                content: raceBetsJS.i18n.print('msgSubscribeErrorLimitReached', {
                                    bonus: raceBetsJS.format.money(
                                        raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].welcomeBonusMaxDeposit, 0, raceBetsJS.application.user.currency, true
                                    ),
                                    tocLink: raceBetsJS.application.globals.urlPrefix + '/content/show/module/offers/'
                                }),
                                closeButton: true
                            });

                        } else if (data.errorMsg === 'RESTRICTED_PAYMENT_METHOD') {
                            raceBetsJS.application.assets.modalDialog.show({
                                content: raceBetsJS.i18n.data.msgRestrictedMethod,
                                type: 'error',
                                hasSupport: true,
                                closeButton: true
                            });

                        } else if (data.errorMsg === 'MOBILE_VERIFICATION_REQUIRED') {
                            raceBetsJS.application.assets.mobileVerification.show();
                            DOMElements.bonusCodeInput.val(bonusCodeSent.value);

                        } else if (data.errorMsg === 'MOBILE_VERIFICATION_NOT_FINISHED') {
                            raceBetsJS.application.assets.mobileVerification.show({
                                startStep: 'two'
                            });
                            DOMElements.bonusCodeInput.val(bonusCodeSent.value);

                        } else if ($.inArray(data.errorMsg, ['INSUFFICIENT_TURNOVER', 'INSUFFICIENT_DEPOSIT']) > -1) {
                            var errorMap = {
                                INSUFFICIENT_TURNOVER: 'msgInsufficientTurnover',
                                INSUFFICIENT_DEPOSIT: 'msgInsufficientDeposit'
                            };

                            raceBetsJS.application.assets.modalDialog.show({
                                content: raceBetsJS.i18n.print(errorMap[data.errorMsg], {
                                    amount: raceBetsJS.format.money(data.amount, 2, raceBetsJS.application.user.currency),
                                    amountRequired: raceBetsJS.format.money(data.amountRequired, 2, raceBetsJS.application.user.currency)
                                }),
                                closeButton: true
                            });

                        } else if (data.errorMsg === 'INSUFFICIENT_DFS_TURNOVER') {
                            raceBetsJS.application.assets.modalDialog.show({
                                content: raceBetsJS.i18n.print('msgInsufficientTurnoverDFS', {
                                    amount: raceBetsJS.format.money(data.amount, 2, raceBetsJS.application.user.currency),
                                    amountRequired: raceBetsJS.format.money(data.amountRequired, 2, raceBetsJS.application.user.currency)
                                }),
                                closeButton: true
                            });
                        } else if (data.errorMsg === 'INSUFFICIENT_NUM_BETS') {
                            raceBetsJS.application.assets.modalDialog.show({
                                content: raceBetsJS.i18n.print('msgInsufficientNumBets', {
                                    amount: data.amount,
                                    amountRequired: data.amountRequired
                                }),
                                closeButton: true
                            });
                        } else if (data.errorMsg === 'ACCOUNT_VERIFY_ONLY') {
                            raceBetsJS.application.assets.modalDialog.show({
                                type: 'error',
                                content: raceBetsJS.i18n.data.msgAccountVerifyOnly
                            });
                            $('.modal-dialog .content a').off('click').on('click', function() {
                                raceBetsJS.application.assets.overlay.close();
                                $(this).off('click');
                            });
                        } else {
                            raceBetsJS.application.assets.modalDialog.generic(data.errorMsg);
                        }
                    }
                },
                complete: function(){
                    stopSubmit = false;
                },
                resetForm: true
            });
            // end bonus submit form

            function activateRedeemButton() {
                var sum = 0;
                var checked = DOMElements.pendingTable.find("input[type=checkbox]:checked");
                $.each(checked, function(index, item){
                    sum += parseFloat($(item).data("amount"));
                });
                var minStakeBOK = raceBetsJS.format.getMinStakeBOKFXD(raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency], "BOK", "WIN");
                if (sum >= minStakeBOK) {
                    DOMElements.activateBonusButton.next('.error').html('');
                    DOMElements.activateBonusButton.siblings('.hint').show();
                    DOMElements.activateBonusButton.find('span.label').html(
                        raceBetsJS.i18n.print('buttonRedeemBonusEx', {
                            amount: raceBetsJS.format.money(sum, 2, raceBetsJS.application.user.currency)
                        })
                    );
                    DOMElements.activateBonusButton.prop('disabled', false).addClass("btn-primary");
                } else {
                    deActivateRedeemButton();
                    if (sum > 0) {
                        DOMElements.activateBonusButton.siblings('.hint').hide();
                        DOMElements.activateBonusButton.next('.error').html(
                            raceBetsJS.i18n.print('msgMinBonusAmount', {
                                amount: raceBetsJS.format.money(minStakeBOK, 2, raceBetsJS.application.user.currency)
                            })
                        );
                    }
                }
            }

            function deActivateRedeemButton() {
                DOMElements.activateBonusButton.next('.error').html('');
                DOMElements.activateBonusButton.siblings('.hint').show();
                DOMElements.activateBonusButton.find('span.label').html(raceBetsJS.i18n.data.buttonRedeemBonus);
                DOMElements.activateBonusButton.prop('disabled', true).removeClass("btn-primary");
            }

            // Activate bonus button binding
            DOMElements.activateBonusButton.on('click',function(){
                if (!$(this).not(':disabled')) {
                    return false;
                }

                // mobile verification
                if (isMobileVerificationRequired() && raceBetsJS.application.user.mobileVerified === false) {
                    // mobile not yet verified, reverse checkbox status and show mobile verification instead
                    raceBetsJS.application.assets.mobileVerification.show();
                    return;
                }

                var jqxhr = $.ajax({
                    type: 'post',
                    url: '/ajax/bonuses/selectBonuses',
                    data: {
                        bonusesIds: $.stringify(getSelectedOffers())
                    },
                    beforeSend: function(){
                        if(stopSubmit) {
                            return false;
                        } else{
                            stopSubmit = true;
                        }
                    }
                })
                .done(function(data) {
                    switch(data.type){
                        case 'error':
                            if (bonusErrorMap[data.errorMsg] !== undefined) {
                                raceBetsJS.application.assets.modalDialog.show({
                                    content: raceBetsJS.i18n.data[bonusErrorMap[data.errorMsg]],
                                    type:'error',
                                    closeButton: true
                                });

                            } else {
                                raceBetsJS.application.assets.modalDialog.generic(data.errorMsg);
                            }
                            break;

                        case 'success':
                            raceBetsJS.application.assets.modalDialog.show({
                                content: raceBetsJS.i18n.data.msgBonusRedeemed,
                                type: 'success',
                                closeButton: true,
                                buttonAction: function(){
                                    raceBetsJS.browser.history.navigateTo('/offers/bonuses/');
                                    raceBetsJS.application.assets.overlay.close();
                                    // raceBetsJS.application.header.login.updateLoginInfo();
                                }
                            });
                            break;
                    }
                }).always(function() {
                    stopSubmit = false;
                });

                return false; // avoid to execute the actual submit of the form.
            });

            // Checkboxes binding
            DOMElements.pendingTable.on("click","input[type=checkbox]", function(){
                var selectedOffers = getSelectedOffers();

                // When selelecting
                if($(this).attr("checked")){
                    //if this is combinable, disable not combinable and not in same group checkboxes
                    if($(this).data("combinable") == "1"){
                        DOMElements.pendingTable.find("input[type=checkbox][data-combinable=0]:not([value="+$(this).val()+"])").prop("disabled",true).prop("checked",false);
                        // allow to combine bonuses of different type
                        //DOMElements.pendingTable.find("input[type=checkbox]:not([data-operation-detail="+$(this).data("operation-detail")+"])").prop("disabled",true).prop("checked",false);
                    }else{
                        // if not combinable disable anyone else
                        DOMElements.pendingTable.find("input[type=checkbox]:not([value="+$(this).val()+"])").prop("disabled",true).prop("checked",false);
                    }
                } else{
                // When deselecting
                    if(selectedOffers.length === 0){
                        DOMElements.pendingTable.find("input[type=checkbox]").prop("disabled",false);
                    }
                }

                if(selectedOffers.length > 0){
                    activateRedeemButton();
                } else{
                    deActivateRedeemButton();
                }
            });

            // init old bonus click
            $("#bonus-history").on("click","span.switcher",function(){
                $("#bonus-history").toggleClass("active");
                $("#active-bonus .bonus-history-list").toggleClass("active");
            });

            Tipped.create('#active-bonus h3 span.bonus-description', function() {
                var str = '<table id="multi-bonus-tooltip">';
                $.each(bonusData.currentBonus.bonuses, function() {
                    str += '<tr>\
                               <td>' + getBonusDescription(this) + '</td>\
                               <td class="amount">' + raceBetsJS.format.money(this.amount, 2, raceBetsJS.application.user.currency) + '</td>\
                            </tr>';
                });
                str += '</table>';
                return str;
            }, {
                hook: 'bottomleft',
                offset: { x: -2 }
            });

            $("#active-bonus .bonus-history-list").on("click","li",function(){

                var jqxhr = $.ajax({
                    type: 'post',
                    url: '/ajax/bonuses/overview/',
                    data: {
                        idBonusChunk: $(this).data('id-bonus-chunk')
                    }
                })
                .done(function(data) {
                    onContentLoaded(data);
                });
            });

            $("#active-bonus .bonus-history-list, #bonus-history span.switcher").on('mouseleave', function(){
                dropDownOutInterval = setTimeout(function(){
                    $("#active-bonus .bonus-history-list").removeClass("active");
                    $("#bonus-history").removeClass("active");
                },500);
            });

            $("#active-bonus .bonus-history-list, #bonus-history sp.switcher").on("mouseenter",function(){
                clearInterval(dropDownOutInterval);
            });

            initToolTips();

            preSelectCheckboxes();

            // redeem message delegator
            DOMElements.section.find('.sub-header').on('click', '.redeem-message .close', function() {
                elem = $(this).parent();
                elem.slideUp();
            });
        }

        var getActiveTab = function() {
            return DOMElements.tabs.find('.active').data('tab');
        };

        var showRedeemMessage = function(bonusData) {
            var link;

            // get link
            if ((bonusData.idRace !== undefined && bonusData.programNumber !== undefined) || (bonusData.idEvent !== undefined && bonusData.idRace !== undefined)) {
                // idRace or idRace AND programNumber restricted
                link = '/race/details/id/' + bonusData.idRace;

            } else if (bonusData.idEvent !== undefined) {
                // idEvent restricted
                link = '/event/details/id/' + bonusData.idEvent;

            } else {
                // unrestricted
                link = '/';
            }

            var modalMessage = raceBetsJS.i18n.data.msgUseAfterRedeem;

            var modalButtons = [{
                label: raceBetsJS.i18n.data.buttonOK,
                action: function() {
                    raceBetsJS.browser.history.navigateTo('/offers/bonuses/freeBets');
                    raceBetsJS.application.assets.overlay.close();
                }
            }, {
                label: raceBetsJS.i18n.data.btnUseFreeBet,
                active: true,
                action: function() {
                    raceBetsJS.browser.history.navigateTo(link);
                    raceBetsJS.application.assets.overlay.close();
                }
            }]

            // check if it's an ante post or combined free bets and show only OK button
            if (bonusData.antepost || bonusData.promotionType === 'freebets') {
                modalButtons = [{
                    label: raceBetsJS.i18n.data.buttonOK,
                    action: function() {
                        raceBetsJS.browser.history.navigateTo('/offers/bonuses/freeBets');
                        raceBetsJS.application.assets.overlay.close();
                    }
                }]
            }

            // Set label for combined free bets
            if (bonusData.promotionType === 'freebets') {
                modalMessage = raceBetsJS.i18n.data.msgUseCombiAfterRedeem;
            }

            // generate message

            // ask to activate bonus
            raceBetsJS.application.assets.modalDialog.show({
                content: modalMessage,
                type: 'success',
                closeButton: false,
                buttons: modalButtons
            });

            /*
            var message = $('<div class="redeem-message">').append(
                $('<span class="icon">'),
                raceBetsJS.i18n.print('msgUseAfterRedeem', { link: link }),
                $('<span class="close">')
            ).hide();

            // populate DOM
            DOMElements.section.find('.sub-header').append(message);

            // add ajaxify here, so it's not in the dictionary element
            message.find('a').addClass('ajaxify');

            message.slideDown();
            */
        };

        var getBetDescription = function(bonusData) {
            var ret = $('<a>'),
                icon = $('<span class="link-icon">'),
                antePostLabel = '',
                countriesLabel = '',
                countriesList = '',
                countriesArr = [];

            // Create labels for freebets limited to events in selected countries
            if (bonusData.countries.length > 0) {
                countriesLabel = raceBetsJS.i18n.data.labelFreebetEventCountries + ' ';

                $.each(bonusData.countries, function (index, item) {
                    countriesArr.push(raceBetsJS.i18n.data.countries[item])
                });

                countriesArr.sort();

                $.each(countriesArr, function(index, item){
                    countriesLabel += item
                    countriesLabel = (index !== bonusData.countries.length - 1) ? countriesLabel += ', ' : countriesLabel += '. ';
                });

                if (bonusData.countries.length > 3) {
                    countriesList += '<a class="freebets-countries nowrap" data-countries="' + countriesLabel + '">' + raceBetsJS.i18n.print('labelAvailableForEvents', { countries: bonusData.countries.length, class: 'freebets-countries-more' }) + '</a>';
                } else {
                    countriesList += '<a class="freebets-countries nowrap">' + countriesLabel + '</a>';
                }
            }

            // Create labels for freebets limited to antepost
            if (bonusData.antepost) {
                antePostLabel = raceBetsJS.i18n.data.labelAntePost + '. ';
            }

            if (bonusData.idRace !== undefined && bonusData.programNumber !== undefined) {
                ///////////////////////////////
                // program number restricted //
                ///////////////////////////////

                // add elements
                ret.append(
                    icon,
                    antePostLabel +
                    bonusData.title +
                    ', ' +
                    raceBetsJS.i18n.print('abbrRaceNumberX', { race: bonusData.raceNumber }) +
                    '<em>(' + raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDateTime, bonusData.postTime) + ')</em>' +
                    ', ' +
                    bonusData.name +
                    '. ' +
                    countriesList
                );

                // add link
                if (!bonusData.antepost) {
                    ret.attr('href', raceBetsJS.application.globals.urlPrefix + '/race/details/id/' + bonusData.idRace);
                }
            } else if (bonusData.idEvent !== undefined && bonusData.idRace !== undefined) {
                /////////////////////
                // race restricted //
                /////////////////////

                // add elements
                ret.append(
                    icon,
                    antePostLabel +
                    bonusData.title +
                    ', ' +
                    raceBetsJS.i18n.print('abbrRaceNumberX', { race: bonusData.raceNumber }) +
                    '<em>(' + raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDateTime, bonusData.postTime) + ')</em>' +
                    '. ' +
                    countriesList
                );

                // add link
                if (!bonusData.antepost) {
                    ret.attr('href', raceBetsJS.application.globals.urlPrefix + '/race/details/id/' + bonusData.idRace);
                }

            } else if (bonusData.idEvent !== undefined) {
                //////////////////////
                // event restricted //
                //////////////////////

                // add elements
                ret.append(
                    icon,
                    antePostLabel +
                    bonusData.title +
                    '<em>(' + raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, bonusData.firstStart) + ')</em>' +
                    '. ' +
                    countriesList
                );

                // add link
                if (!bonusData.antepost) {
                    ret.attr('href', raceBetsJS.application.globals.urlPrefix + '/event/details/id/' + bonusData.idEvent);
                }

            } else if (bonusData.antepost) {
                //////////////////////
                // antepost races   //
                //////////////////////

                // add elements
                ret.append(
                    icon,
                    antePostLabel +
                    countriesList
                )
            } else if (bonusData.countries.length > 0){
                //////////////////////////////////////
                // races limited to countries list  //
                //////////////////////////////////////

                // add elements
                ret.append(
                    icon,
                    antePostLabel +
                    countriesList
                );
            } else {
                // no restriction
                var description = raceBetsJS.i18n.data.labelNoRestrictions;
                if (bonusData.description) {
                    description += ' | ' + bonusData.description;
                }

                ret.append(
                    icon,
                    description
                );

                ret
                    .attr('href', raceBetsJS.application.globals.urlPrefix + '/')
                    .addClass('ajaxify');
            }

            return ret[0].outerHTML;
        };

        var getBonusDescription = function(bonusData) {
            if (bonusData.description && bonusData.description !== '') {
                return bonusData.description;
            } else if (bonusData.promotionCode && bonusData.promotionCode !== '') {
                if (bonusData.operationDetail && bonusData.operationDetail === 'BDY') {
                    return raceBetsJS.i18n.data.labelBonus + ': ' + raceBetsJS.i18n.data.operationDetailBDY;
                }
                return raceBetsJS.i18n.data.labelBonusCode + ': ' + bonusData.promotionCode;
            } else if (bonusData.operationDetail && bonusData.operationDetail !== '') {
                var baseElem = (($.inArray(bonusData.operationDetail, ['DEP', 'MSC', 'BNS', 'BET']) > -1) ? 'operation' : 'operationDetail');
                return raceBetsJS.i18n.data.labelBonus + ': ' + raceBetsJS.i18n.data[baseElem + bonusData.operationDetail];
            } else {
                return raceBetsJS.i18n.data.labelBonus;
            }
        };

        // @public
        return {
            init: function() {

                raceBetsJS.application.contentController.addDynamicPage({
                    urlPattern: /\/offers\/bonuses\/?(freebets)?\/?/,
                    dataSourceURLBuilder: function(freeBets) {
                        forceFreeBets = (freeBets == "freebets");
                        return '/ajax/bonuses/overview/';
                    },
                    onDataReceived: onContentLoaded
                });

            },
            getBonusDescription: getBonusDescription,
            getBetDescription: getBetDescription
        };
    }();
})(raceBetsJS);
