/**
*
* raceBetsJs.webSockets.nodeJS
* @autor    Javier Cobos Mesa
* @date 08/03/2013
*
* This is the websocket connection manager. Help us to stablish the connection with the node JS server.
* The main idea is the creation of a modular structure, an abstraction layer to all the libraries necessary to handle
* the connection  and transactions with the node Server.
* At this moment we are using socket.io and the jquery.pubsub.js plugin.
* We are providing 5 methods
*
* @function     connect                             Sends an order to the node server to join the socket to a new dynamic channel.
*                                                   This library will handle all the reconnection issues. Also the re-subscriptions to the server.
*
* @function     join({channel, callback})           Sends an order to the node server to join the socket to a new dynamic channel.
*                                                   At the same time subscribes the callback to the responses of the server to this channel.
*                                                   @returns the subscription handler.
*
* @function     leave(subscription_handler)         Commands the server to make the socket leave the channel and unsubscribe from the push.
*
* @function     publish({channel,data})             Publishes data on the specific channel. This functionis call when the server pushes something
*
* @function     isOnline                            Small method telling us if the connection is ON.
*
*
* @todo         The connection settings (conSets) could be placed in main config file
*/

(function (raceBetsJS){

    raceBetsJS.webSockets.nodeJS = function() {

        // PRIVATE VARIABLES

        var connection = null,

            // connection status flag
            connected = false,

            // display console.log
            doLog = false,

            // Here we are going to store all the channel join Requests, just in case
            // the server is not responding when trying to join, so we can rejoin on reconnect.
            joinRequests = {},

            // this settings will help to form the urls to make the connections
            conSets = {
                ip:                     'node.racebets.com',
                port:                   '8080',
                protocol:               'http://',
                secureProtocol:         'https://',
                reconnectDelay:         12000 + (1000 * Math.floor((Math.random()*10)+1)),
                maxReconnectionAttemps: 100,
                brand: document.getElementsByTagName('html')[0].getAttribute('data-brand')
            },

            // those options will be passed as parameters to the connection
            conOptions = {
                'reconnectionDelay': conSets.reconnectDelay,
                'reconnectionAttempts': 10,
                'reconnectionDelayMax': 600000,
                'secure':'true',
                'transports': ['websocket', 'polling']
            },

            // socket.io.js URL
            ioSrc = document.getElementById('socketiojs').getAttribute('src'),

            // server base URL
            server  = '';

        // PRIVATE METHODS

        // Sets server uri and port
        function _setServer() {
            server = conSets.secureProtocol + conSets.ip + ':' + conSets.port + '/' + _getNamespace();
        }

        function _getNamespace() {
            var namespace = conSets.brand;

            if (conSets.brand === 'betsson') {
                if (['cl', 'co', 'pe'].indexOf(raceBetsJS.application.user.lang) > -1) {
                    namespace += raceBetsJS.application.user.lang;
                }
            }
            return namespace
        }

        // Wrapper for console.log allowing configuration
        function _log(m) {
            if (doLog && window.console) {
                console.log(m);
            }
        }

        // Allows us to set if the connection is establishied or not
        function _setConnectionStatus(status) {
            connected = status;
        }

        // Returns true/false depending if the socket.io.js is loaded or not
        // it happens when the server is off from the beginning
        function _isIOLoaded() {
            return (typeof(io) !== 'undefined') ? true : false;
        }

        // Reloads the socket.io.js <script> in the site's <head>
        // If server is not loaded, socket.io.js will be not available so we need to redeclare the dom node
        function _reloadIO() {
            _log('SOCKET MANAGER >> RELOAD >> Socket.io.js');

            var headElem = document.getElementsByTagName('head')[0],
                script = document.createElement('script');

            script.src = ioSrc + '?' + Math.floor(Math.random() * 111);
            script.id = 'socketiojs';
            headElem.removeChild(document.getElementById('socketiojs'));
            headElem.appendChild(script);

            // nullify
            script = headElem = null;
            return;
        }

        // Try to repeat all the join requests once the server is reconnected
        // The server closes all the channels when it drops
        // But we are going to store only the requests made before the socket.io.js was loaded
        // in other case the socket.io will do it for us
        function _reJoin() {
            var key;
            for (key in joinRequests){
                if (joinRequests.hasOwnProperty(key)) {
                    _log('SOCKET MANAGER >> REJOIN >> Rejoining channel ' + joinRequests[key].channel);
                    connection.emit('join', joinRequests[key]);
                }

            }
        }

        // Creates the socket connection with the server, checking if server is ON.
        // If server is OFF it will try to reconnect
        function _establishConnection() {
            var errorLogged = false;

            if (!_isIOLoaded()) {
                _log('SOCKET MANAGER >> SOCKET.IO NOT LOADED >> Server not reachable, trying again in ' + (conSets.reconnectDelay/1000) + ' seconds');
                _reloadIO();
                return false;
            }

            connection = io.connect(server,conOptions);

            if (connection) {
                _log(connection);

                connection.on('connect', function(){
                    _log('SOCKET MANAGER >> CONNECT >> Connection with the node.js server established');
                    _setConnectionStatus(true);
                    if (connection.reconnected) {
                        login(true);
                        setTimeout(function(){_reJoin();},5000);
                    }
                });

                connection.on('connect_error', function (err) {
                    if (!errorLogged) {
                        err.message2 = err.message || 'empty';
                        $.post('/rest/v1/log/general', JSON.stringify({log_level: 'INF', data: JSON.stringify(err)}));
                        errorLogged = true;
                    }
                    _log('SOCKET MANAGER >> ERROR >> Reconnecting in ' + (conSets.reconnectDelay/1000) + ' seconds');

                    _setConnectionStatus(false);
                    raceBetsJS.application.user.loggedInNode = false;

                    setTimeout(function(){
                        connection.reconnected = true;
                        connection.connect();
                        _log('SOCKET MANAGER >> RECONNECT CALLED');
                    },conSets.reconnectDelay);
                });

                connection.on('disconnect', function () {
                    _log('SOCKET MANAGER >> DISCONNECTED >> Reconnecting in ' + (conSets.reconnectDelay/1000) + ' seconds');
                    _setConnectionStatus(false);
                    raceBetsJS.application.user.loggedInNode = false;

                    setTimeout(function(){
                        connection.reconnected = true;
                        connection.connect();
                        _log('SOCKET MANAGER >> RECONNECT CALLED');
                    },conSets.reconnectDelay);
                });

                connection.on('reconnect', function () {
                    _log('SOCKET MANAGER >> RECONNECTED');
                    connection.reconnected = true;
                });

                connection.on('dataUpdate', function (o) {
                    // discard if have different brands
                    if (o.data && o.data.orgBrand && o.data.orgBrand !== conSets.brand) {
                        $.post('/rest/v1/log/general', JSON.stringify({log_level: 'INF', data: JSON.stringify({brand: conSets.brand, data: o.data})}));
                        _log('SOCKET MANAGER >> DATA UPDATE >> different brand channel');
                        return false;
                    }
                    _log('SOCKET MANAGER >> DATA UPDATE >> Update received');
                    raceBetsJS.webSockets.nodeJS.publish(o);

                    if(o && o.data === 'realityCheck') {
                        $.publish('realityCheck:warning',['realityCheck']);
                    }
                });

                return true;

            } else {
                _log('SOCKET MANAGER >> FAILED >> Connection failed, trying again in ' + (conSets.reconnectDelay/1000) + ' seconds');
                return false;
            }

            return false;
        }


        // PUBLIC METHODS

        var getConnectionStatus = function(){
            return connected;
        };

        // Returns the handler to be used in the unsubscription
        var join = function(o){

            // @param channel
            // @param timeStamp
            // @param callback
            var channel = o.channel;
            var obj = {channel: o.channel, timestamp: o.timestamp};
            if (o.idEvent) {
                obj.idEvent = o.idEvent;
            }

            _log('SOCKET MANAGER >> JOIN >> Joining to channel: ' + channel);

            if (_isIOLoaded()) {
                connection.emit('join', obj);
            }

            // Lets store the join request so if the sever goes down, we can reconnect
            joinRequests[channel] = obj;
            return $.subscribe(channel,o.callback);
        };

        var login = function(relog, callback){
            var cookie = raceBetsJS.application.user.cookie || $.parseJSON($.cookie('sockie'));

            if(raceBetsJS.application.user.loggedIn && !raceBetsJS.application.user.loggedInNode && cookie !== null){
                // I must do that with connection instead of channel to avoid propagation to other tabs.
                connection.on('session', function(session) {
                    _log('SOCKET MANAGER >> SESSION >> Session event ' + session.event);
                    switch(session.event){
                        case 'loggedIn':
                            raceBetsJS.application.user.loggedInNode = true;
                            // This code will handle the user private channel responses
                            raceBetsJS.webSockets.nodeJS.join({
                                    channel :   'user_private_' + cookie.userId,
                                    callback : function(d){
                                        if (callback) {
                                            callback(d.channel, d.message);
                                        }
                                        // this function will broadcast data using a single channel and client pub sub
                                        $.publish(d.channel,[d.message]);
                                    }
                            });
                            $.publish('loggedInNode');
                            break;
                        case 'loggedOut':
                            raceBetsJS.application.user.loggedInNode = false;
                            $.publish('loggedOutNode');
                            break;
                        case 'relogIn':
                            raceBetsJS.application.user.loggedInNode = true;
                            break;
                    }
                });

                if (relog) {
                    cookie.relog = true;
                }

                connection.emit('login',cookie);

            } else if (raceBetsJS.application.user.loggedIn){
                $.post('/rest/v1/log/general', JSON.stringify({log_level: 'INF', data: JSON.stringify(cookie)}));
            }
        };

        // @param h = [channel, callback]
        var leave = function(h) {

            if ( !$.isArray(h) || ( $.isArray(h) && h.length === 0 ) ) {
                return;
            }

            var channel = h[0];

            if ( _isIOLoaded() ){
                connection.emit('leave',channel);
                _log('SOCKET MANAGER >> LEAVE >> Just left channel ' + channel);
            }

            delete joinRequests[channel];
            // joinRequests.splice(joinRequests.indexOf(channel),1);

            $.unsubscribe(h);

            //nullify
            h = channel = null;

            return;
        };


        var publish = function(o){
            _log('SOCKET MANAGER >> PUBLISH >> Fired event from channel ' + o.channel);
            $.publish(o.channel,[o.data]);
            //nullify
            o = null;
            return;
        };

        var connect = function(newHost, newPort, newBrand) {
            var connectionInterval;

            if (newBrand) {
                conSets.brand = newBrand;
                _setServer();
            }

            if (newHost) {
                conSets.ip = newHost;
                _setServer();
            }

            if (newPort) {
                conSets.port = newPort;
                _setServer();
            }

            if (!_establishConnection()) {
                // @todo change the interval for racebets interval if necessary
                if (connectionInterval) {
                    connectionInterval.stop();
                }
                connectionInterval = new raceBetsJS.time.Interval({
                    interval: conSets.reconnectDelay,
                    tick: function() {
                        _log('SOCKET MANAGER >> CONNECT >> Connection interval...');
                        if(_establishConnection()){
                            connectionInterval.stop();
                        }
                    }
                });
                connectionInterval.start();

                /*
                connectionInterval = setInterval(function(){
                    if( _establishConnection() ) clearInterval(connectionInterval);
                },conSets.reconnectDelay);
                */
                return false;
            }
            else{
                _log('SOCKET MANAGER >> CONNECT >> Connected to the server');
                return true;
            }

        };

        var disconnect = function() {
            if (connected) {
                connection.io.disconnect();
            }
        };

        //  public
        return {
            join: join,
            leave: leave,
            connect: connect,
            disconnect: disconnect,
            login: login,
            publish: publish,
            isOnline:  getConnectionStatus
        };
    }();

})(raceBetsJS ||  {});
