/**
* Homepage "Tips of the day" module
*
* @author David Calleja
*/
(function(raceBetsJS) {
	raceBetsJS.application.content.home.tips = (function() {
		// @private
		var panel_template = raceBetsJS.application.templates.panel,
			content_template = raceBetsJS.application.templates.home.tips,
			panel_data = {
				'title':'',
				'cssClass':'c-widget--tips light with-rows',
				'id':'tips',
				'more':null
			},
			content_area_selector = '.content_container',
			el = null,
			data = {};

		var render = function(area){
			var content = $('<ul>'),
				i = 0;

			$.each(
				data,
				function(key, value){
					var li = $('<li>');
					li.addClass('race-type-' + this.raceType.toLowerCase());
					if((i+1)%2 === 0){
						li.addClass('even');
					}
					content.append(li.append(content_template($.extend(value, {type: key}))));
					i++;
				}
			);

			if(el === null){
				area.append(panel_template($.extend(panel_data, {'content': $('<div>').append( content.eq(0).clone() ).html() })));
				el = area.find('#' + panel_data.id).find(content_area_selector);
				eventsInit();
			} else {
				el.empty();
				el.append(content);
			}
		};

		var eventsInit = function(){
			el.on('mouseup', '.odds-button.price.enabled', function() {
				raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + $(this).attr("data-id-race"));
			});
		};


		var remove = function(){
			el = null;
		};

		var setData = function(json){
			data = json;
		};

		// @public
		return {
			init: function(json) {
				if(json != null && !_.isEmpty(json)){
					setData(json);

                    //set translations
                    panel_data.title = raceBetsJS.i18n.data.headTipsOfTheDay;

					return this;
				} else if(el != null){
					return this;
				}
				return false;
			},
			render: render,
			remove: remove
		};
	})();
})(raceBetsJS);
