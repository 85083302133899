/**
* Betting Dialog
*
* @todo
* [ ] positioning if screen space is missing
* [ ] message if user is not logged in
*/

(function(raceBetsJS) {
    raceBetsJS.application.assets.bettingDialog = function() {
        var runner,
            freeBet = null,
            freeBets,
            dom,
            channelName,
            betDetails,
            betExtras,
            updatedOddsWin,
            updatedOddsPlace,
            betValid,
            options = {},
            payoutOdds = {
                1.03: 1.03030304,
                1.06: 1.0625,
                1.07: 1.07142858,
                1.08: 1.08333334,
                1.09: 1.0909091,
                1.11: 1.11111112,
                1.12: 1.125,
                1.13: 1.13333334,
                1.14: 1.14285715,
                1.15: 1.15384616,
                1.16: 1.16666667,
                1.18: 1.18181819,
                1.22: 1.22222223,
                1.26: 1.26666667,
                1.28: 1.28571429,
                1.33: 1.33333334,
                1.36: 1.36363637,
                1.42: 1.42105264,
                1.44: 1.44444445,
                1.47: 1.47058824,
                1.53: 1.53333334,
                1.57: 1.57142858,
                1.61: 1.61538462,
                1.62: 1.625,
                1.66: 1.66666667,
                1.72: 1.72727273,
                1.83: 1.83333334,
                1.9: 1.90909091,
                1.95: 1.95238096,
                2.37: 2.375,
                2.62: 2.625,
                2.87: 2.875,
                3.12: 3.125,
                3.37: 3.375,
                4.33: 4.33333334
            };

        var show = function(elem, _options) {
            if (!raceBetsJS.application.header.login.check()) {
                return;
            }

            // reset odds
            updatedOddsWin = undefined;
            updatedOddsPlace = undefined;

            options = $.extend(options, _options);

            dom = {
                oddsButton: $(elem)
            };

            // disable button and show loading indicator
            $(elem).removeClass('enabled').addClass('loading');

            // get runner information
            $.get('/ajax/races/bettingDialog/id/' + dom.oddsButton.data('id-runner')).success(function(data) {
                runner = data;

                // enable button again
                dom.oddsButton.removeClass('loading').addClass('enabled');

                if (raceBetsJS.application.assets.accBetslip.isOpen() === 'acc' && runner.multiplesFxd === true) {
                    addAccRunner();

                } else {
                    createTipped();
                }
            });
        };

        var createTipped = function() {
            var settings = raceBetsJS.application.assets.settings.getBrandSettings('bettingDialog');

            Tipped.Skins.bettingDialog.background.color = settings.background;
            Tipped.Skins.bettingDialog.shadow.opacity = settings.shadowOpacity;
            Tipped.Skins.bettingDialog.border.color = settings.borderColor;
            Tipped.Skins.bettingDialog.border.size = settings.borderSize;
            Tipped.Skins.bettingDialog.maxWidth = settings.maxWidth;

            Tipped.create(dom.oddsButton, '', {
                showOn: false,
                closeButton: true,
                hideOn: 'click-outside',
                skin: 'bettingDialog',
                zIndex: 10000,
                onShow: initDialog,
                onHide: function() {
                    freeBet = null;
                    $.unsubscribe(channelName, runnerUpdate);
                    if (!raceBetsJS.application.globals.isB2B) {
                        Tipped.remove(dom.bonusMoneyInfo);
                    }
                }
            }).show();
        };

        var runnerUpdate = function(data) {
            if (data.runners !== undefined) {
                $.each(data.runners, function() {
                    if (this.idRunner == dom.oddsButton.data('id-runner')) {
                        updatedOddsWin = this.odds.FXW || undefined;
                        updatedOddsPlace = this.odds.FXP || undefined;
                    }
                });
            }
        };

        var addAccRunner = function() {
            // accumulation betslip
            var odds = {};

            if (runner.betTypesV2.BOK !== undefined && $.inArray('WIN', runner.betTypesV2.BOK) > -1) {
                odds.PRC = 0;
            }
            if (runner.fixedOddsWin !== undefined && runner.fixedOddsWin > 0) {
                odds.FXW = runner.fixedOddsWin;
            }

            if (runner.fixedOddsPlace !== undefined && runner.fixedOddsPlace > 0) {
                odds.FXP = runner.fixedOddsPlace;
            }

            var getRelatedRunners = function(runner) {
                var ids = [runner.relatedIdRunner];
                _.each(runner.h2hOpponents, function(opponent) {
                    ids.push(opponent.relatedIdRunner);
                })
                return ids;
            };

            var betTypes = {
                FXD: _.values(runner.betTypesV2.FXD),
                BOK: _.values(runner.betTypesV2.BOK)
            };

            var details = {
                race: {
                    idRace: runner.idRace.toString(),
                    relatedIdRace: runner.isSpecialBet ? runner.relatedIdRace : null,
                    event: (runner.isSpecialBet ? raceBetsJS.i18n.data.headToHeadAbbr + ' ' : '') + runner.title,
                    country: runner.country,
                    raceNumber: runner.isSpecialBet ? runner.relatedRaceNumber : runner.raceNumber,
                    betTypes: betTypes,
                    isHeadToHead: runner.isHeadToHead,
                    isSpecialBets: runner.isSpecialBet,
                    isAntePost: runner.isAntePost,
                    specialBetType: (runner.h2hOpponents !== undefined ? 'H2H' : undefined)
                },
                runner: {
                    idRunner: runner.idRunner,
                    relatedIdRunners: runner.isSpecialBet ? getRelatedRunners(runner) : null,
                    betCategory: 'FXD',
                    name: runner.name,
                    opponents: runner.h2hOpponents,
                    programNumber: runner.programNumber,
                    odds: odds
                }
            };

            // check if runner would be a valid one, if not... show dialog instead!
            if (raceBetsJS.application.assets.accBetslip.isValidRunner(details)) {
                // add runner
                var res = raceBetsJS.application.assets.accBetslip.addRunner(details);

                // resolve or reject promise
                if (options.accDeferred !== undefined) {
                    if (res === true) {
                        options.accDeferred.resolve();
                    } else {
                        options.accDeferred.reject();
                    }
                }

                return;
            }

            createTipped();
        };

        var initDialog = function(content, elem) {
            content = $(content);

            var oddsButtonBetType = dom.oddsButton.data('bet-type');

            content.addClass('betting-dialog loading');

            // set bet details
            betDetails = {
                idRace: runner.idRace,
                taxRate: runner.taxRateV2.FXD || 0,
                betType: null,
                betslipType: 'STD',
                betCategory: 'FXD',
                unitStake: 0,
                country: runner.country,
                currency: raceBetsJS.application.user.currency,
                numBets: 0,
                fixedOddsWin: runner.fixedOddsWin || null,
                fixedOddsPlace: runner.fixedOddsPlace || null,
                marks: {
                    1: [{
                        programNumber: parseInt(runner.programNumber),
                        name: runner.name
                    }],
                    2: [],
                    3: [],
                    4: [],
                    'c': []
                }
            };

            // subscribe to updates
            channelName = 'node_' + (runner.idSpecialBet ? 'eventCard_' + runner.idEvent : 'raceCard_' + runner.idRace);
            $.subscribe(channelName, runnerUpdate);

            // set bet extras
            betExtras = {
                isAntePost: runner.isAntePost,
                isSpecialBet: runner.isSpecialBet,
                spEvent: runner.spEvent,
                rule4: runner.rule4,
                placesNum: runner.placesNum,
                placeOddsFactor: runner.placeOddsFactor
            };

            // remove indicator
            content.removeClass('loading');

            // find free bets
            freeBets = _.filter(raceBetsJS.application.assets.session.get('freebets'), function(idx){
                return idx.countries.length === 0 || _.contains(idx.countries, runner.country);
            });

            var freeBetsValid = [];

            if (freeBets) {
                $.each(freeBets, function() {

                    // no free bets on place fixed odds
                    if(oddsButtonBetType === 'PLC') {
                        return; 
                    }
                
                    // ante post don't match
                    if (this.antepost !== runner.isAntePost) {
                        return;
                    }

                    // event doesn't match
                    if (this.idEvent !== undefined && this.idEvent !== runner.idEvent) {
                        return;
                    }

                    // race doesn't match
                    if (this.idRace !== undefined && this.idRace !== runner.idRace) {
                        return;
                    }

                    // runner doesn't match
                    if (this.programNumber !== undefined && this.programNumber !== runner.programNumber) {
                        return;
                    }

                    // push object to array
                    freeBetsValid.push(this);
                });
            }

            // publish dialog dom
            $.extend(runner, { oddsButtonBetType: oddsButtonBetType });
            content.html(raceBetsJS.application.templates.bettingDialog({
                data: runner,
                freeBets: freeBetsValid
            }));

            // cache dom elements
            dom.odds = content.find('div.title span.odds');
            dom.stakesRow = content.find('div.row.stakes');
            dom.betTypes = content.find('div.bet-types');
            dom.winnings = content.find('div.winnings');
            dom.submitButton = content.find('div.buttons button[type="submit"]');
            dom.freeBets = content.find('#free-bets-select');
            dom.bonusMoneyInfo = content.find('.buttons .bonus-money-info');

            // free bets select
            var stakeRow = null;
            var select = raceBetsJS.application.assets.guidelines.selectBoxControl(dom.freeBets, {
                width: 160,
                onSelect: function(value) {
                    // reset
                    freeBet = null;

                    // no valid value
                    if (value < 1 || isNaN(value)) {
                        dom.stakesRow.show();
                        Tipped.refresh(elem);
                        evalBet();
                        delete betDetails.idFreebet;
                        return;
                    }

                    // get freebet
                    freeBet = getFreeBetById(value);

                    betDetails.idFreebet = freeBet.idFreebet;
                    evalBet();

                    dom.stakesRow.hide();
                    Tipped.refresh(elem);
                }
            });

            // add css class for width of button bar
            var numBetTypes = dom.betTypes.find('.bet-type').length;
            dom.betTypes.addClass('num' + numBetTypes);

            // Check if odds are still the same
            var compareOdds = (oddsButtonBetType == 'WIN') ? runner.fixedOddsWin : runner.fixedOddsPlace;
            if (dom.oddsButton.children('span').data('odds') != compareOdds) {

                // update odds on buttons
                if(options.onOddsChange){
					options.onOddsChange(compareOdds);
                } else {
					$.publish('/race/' + runner.idRace + '/update');
                }

                // show message that odds have changed
                content.find('div.title span.odds').addClass('changed');
            }

            // check if only one bet type, then hide bet type
            if (numBetTypes == 1) {
                dom.betTypes.hide();
                dom.odds.prepend(raceBetsJS.format.betTypeName(_.keys(runner.betTypes)[0], 'BOK', raceBetsJS.application.user.lang, null, null));
            }

            // create stake select box
            dom.stakeInput = raceBetsJS.application.assets.guidelines.selectBoxControl(content.find('div.stakes select'), {
                editable: true,
                width: 100,
                onSelect: evalBet,
                onTextInputBlur: function(e) {
                    var elem = $(e.target),
                        stake = elem.val();
                        //remove all non-numeric (except .,) characters to avoid NaN value form parseFloat
                        stake = stake.replace(/[^0-9.,]/g, '');
                        stake = parseFloat(elem.val().replace(/,/, '.'));


                    dom.stakeInput.val(
                        raceBetsJS.format.money(stake, 2, raceBetsJS.application.user.currency),
                        stake
                    );
                },
                onTextInputKeyUp: function(e) {
                    var key = e.keyCode || e.which;

                    if (key === 13) {
                        var elem = $(e.target),
                            stake = parseFloat(elem.val().replace(/,/, '.'));

                        dom.stakeInput.val(
                            raceBetsJS.format.money(stake, 2, raceBetsJS.application.user.currency),
                            stake
                        );
                    }
                }
            });

            // UK Each-way checkbox
            $('div.betting-dialog div.title').on('change', '#each-way-option', function() {
                setBetType($(this).is(':checked') ? 'WP' : 'WIN');
            });

            content.find('div.stakes .other-amount .c-btn').click(function(e) {
                e.preventDefault();
                dom.stakeInput.val('');
                dom.stakeInput.focus();
            });

            // preselect bet-type
            if (oddsButtonBetType && runner.betTypes[oddsButtonBetType] !== undefined) {
                setBetType(oddsButtonBetType);
            } else if (numBetTypes == 1 || raceBetsJS.application.assets.settings.get().dialog.betslip.preselection) {
                setBetType(dom.betTypes.find('.bet-type').first().data('bet-type'));
            }

            // bet type button observers
            dom.betTypes.on('click', '.bet-type', function() {
                setBetType($(this).data('bet-type'));
            });

            dom.betTypes.find('div:first').addClass('first');
            dom.betTypes.find('div:last').addClass('last');

            // general tooltip
            Tipped.create(content.find('.tipped'), {
                hook: 'righttop',
                offset: { x: 2, y: -5 },
                maxWidth: 120,
                hideOn: [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' }
                ]
            });

            // redraw tooltip
            Tipped.refresh(elem);

            // submit button observer
            content.on('click', 'div.buttons button[type="submit"]:not(:disabled)', submit);

            // accumulation deferred? if so, reject here
            if (options.accDeferred !== undefined) {
                options.accDeferred.reject();
            }
        };

        /**
        * setBetType
        */
        var setBetType = function(betType) {
            dom.betTypes.children('div.bet-type.active').removeClass('active');
            dom.betTypes.children('div.bet-type[data-bet-type=' + betType + ']').addClass('active');

            betDetails.betType = betType;
            betDetails.numBets = (betType == 'WP') ? 2 : 1;

            evalBet();
        };

        /**
        * getFreeBetById
        * Returns object of idFreeBet
        */
        var getFreeBetById = function(id) {
            var ret = null;
            $.each(freeBets, function() {
                if (this.idFreebet == id) {
                    ret = this;
                }
            });

            return ret;
        };

        /**
        * setFreeBetOptions
        * Sets betslip options to reflect changes
        */
        var setFreeBetOptions = function() {
            /*
            var betType = card.dom.freeBets.betType.find(':radio:checked').val();

            card.betslip.setBetCategory('BOK');
            card.betslip.setBetType(betType);
                card.betslip.setStake((betType == 'WP' ? freeBet.amount/2 : freeBet.amount));
            card.betslip.setIdFreeBet(freeBet.idFreeBet);
            */
        };

        /**
        * evalBet
        */
        var evalBet = function() {
            // get unit stake
            if (freeBet) {
                // free bet stake
                betDetails.unitStake = (betDetails.betType === 'WIN') ? freeBet.amount : freeBet.amount/2;
            } else {
                // user stake
                betDetails.unitStake = parseFloat(dom.stakeInput.val().replace(',', '.'));
            }
            var betValid = (betDetails.numBets && betDetails.betType && betDetails.unitStake);

            var totalStake = betDetails.numBets * betDetails.unitStake;
            var totalStakeEUR;
            var winnings = 0;
            var totalCost = {};

            if (betValid) {
                dom.submitButton.attr('disabled', false).removeClass('btn-disabled').addClass('btn-primary');
                // freeBet is null here not undefined
                totalCost = raceBetsJS.betting.calculateTax(totalStake, betDetails.taxRate, 'FXD', (freeBet !== null));

                if (betDetails.betType == 'WIN' || betDetails.betType == 'WP') {
                    winnings += betDetails.unitStake * (payoutOdds[betDetails.fixedOddsWin] !== undefined ? payoutOdds[betDetails.fixedOddsWin] : betDetails.fixedOddsWin);
                }

                if (betDetails.betType == 'PLC' || betDetails.betType == 'WP') {
                    var stakeMultiplier;

                    if (betExtras.placeOddsFactor !== undefined) {
                        // formular: (winOdds- 1) / placeFactor + 1
                        stakeMultiplier = ((betDetails.fixedOddsWin - 1) / betExtras.placeOddsFactor) + 1;
                    } else {
                        stakeMultiplier = (payoutOdds[betDetails.fixedOddsPlace] !== undefined ? payoutOdds[betDetails.fixedOddsPlace] : betDetails.fixedOddsPlace);
                    }

                    winnings += betDetails.unitStake * stakeMultiplier;
                }

                if (betDetails.currency !== 'EUR') {
                    totalStakeEUR = Math.round(betDetails.numBets * betDetails.unitStake * 100) / 100;
                    betDetails.totalStakeEUR = raceBetsJS.localize.exchange(totalStakeEUR, betDetails.currency, 'EUR');
                } else {
                    betDetails.totalStakeEUR = totalStake;
                }

            } else {
                dom.submitButton.attr('disabled', true).removeClass('btn-primary').addClass('btn-disabled');
            }

            // if free bet, deduct free bet state
            winnings -= (freeBet !== null) ? freeBet.amount : 0;

            var betInfo = '<table><tr><th class="total-cost">' + (raceBetsJS.i18n.print(totalCost.taxDue ? 'labelTotalCost' : 'labelTotalStake', { amount: '' })) + '</th><td>' + (betValid ? raceBetsJS.format.money(totalCost.totalCost, 2, betDetails.currency, true) : '-') + '</td></tr>';
            betInfo += '<tr><th class="potential-winnings">' + raceBetsJS.i18n.print('potentialWinnings', { amount: '' }) + '</th><td>' + (betValid ? raceBetsJS.format.money(winnings, 2, betDetails.currency, true) : '-') + '</td></tr></table>';

            dom.winnings.html(betInfo);

            if (totalCost.taxFee > 0) {
                var domTotalCost = dom.winnings.find('.total-cost');
                var domTaxInfo = $('<span />').addClass('tax-info');

                domTotalCost.append(domTaxInfo);

                // total stake tooltip
                Tipped.create(domTaxInfo, raceBetsJS.application.templates.betTaxTooltip({
                    totalStake: totalStake,
                    labelTax: raceBetsJS.i18n.data.labelTaxRWLG,
                    taxDue: totalCost.taxDue,
                    taxSubvention: totalCost.taxSubvention,
                    taxSubventionRate: totalCost.taxSubventionRate
                }), {
                    hook: 'topright',
                    offset: { x: 3, y: -2 },
                    skin: 'racebets',
                    maxWidth: 300,
                    onShow: function(content, elem) {
                        $(content).addClass('betting-tax');
                    }
                });
            }

            betDetails.taxFee = totalCost.taxFee; // required for confirmation dialog
            if (!raceBetsJS.application.globals.isB2B) {
                raceBetsJS.application.assets.bonusMoney.show(dom.bonusMoneyInfo, totalStake);
            }
        };

        /**
        * submit
        */
        var submit = function(betType, unitStake) {

            dom.submitButton.attr('disabled', true).removeClass('btn-primary').addClass('btn-disabled');

            // observe odds change
            if ((updatedOddsWin !== undefined || updatedOddsPlace !== undefined) && (betDetails.fixedOddsWin != updatedOddsWin || betDetails.fixedOddsPlace != updatedOddsPlace)) {
                var content = raceBetsJS.i18n.data.msgFixedOddsChanged + ' ';

                if (updatedOddsWin) {
                    content = content + raceBetsJS.i18n.print('msgNewWinFixedOdds', { odds: raceBetsJS.format.odds(updatedOddsWin) }) + ' ';
                }
                if (updatedOddsPlace) {
                    content = content + raceBetsJS.i18n.print('msgNewPlaceFixedOdds', { odds: raceBetsJS.format.odds(updatedOddsPlace) }) + ' ';
                }
                content = content + raceBetsJS.i18n.data.msgAcceptNewFixedOdds;

                raceBetsJS.application.assets.modalDialog.show({
                    type:'attention',
                    buttonAlign: 'right',
                    content: content,
                    buttons: [
                        {
                            label: raceBetsJS.i18n.data.buttonYes,
                            action: function() {
                                betDetails.fixedOddsWin = updatedOddsWin;
                                betDetails.fixedOddsPlace = updatedOddsPlace;
                                raceBetsJS.application.assets.overlay.close();
                                submit();
                            },
                            active: true
                        },
                        {
                            label: raceBetsJS.i18n.data.buttonNo,
                            action: function() {
                                Tipped.hideAll();
                                raceBetsJS.application.assets.overlay.close();
                            }
                        }
                    ]
                });

                return;
            }

            // min and max stake
            var minStake = parseFloat((betDetails.betCategory == 'TOT')
                ? runner.betTypes[betDetails.betType]
                : raceBetsJS.format.getMinStakeBOKFXD(raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency], betDetails.betCategory, betDetails.betType));
            var maxStake = raceBetsJS.format.getMaxStakeBOKFXD(raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency], betDetails.betCategory, betDetails.betType);

            if (betDetails.unitStake < minStake) {
                raceBetsJS.application.assets.modalDialog.show({
                    content: raceBetsJS.i18n.print('errorMinStake', { amount: raceBetsJS.format.money(minStake, 2, betDetails.currency, true), type:'attention' })
                });
                return;

            } else if (betDetails.unitStake > maxStake) {
                raceBetsJS.application.assets.modalDialog.show({
                    content: raceBetsJS.i18n.print('errorMaxStake', { amount: raceBetsJS.format.money(maxStake, 2, betDetails.currency, true), type:'attention' })
                });
                return;
            }

            // track event
            dataLayer.push({
                'BetCategory': betDetails.betCategory,
                'BetType': betDetails.betType,
                'event': 'PlaceBetClick'
            });

            var bet = new raceBetsJS.Bet(betDetails, betExtras);
            $.when(bet.submit())
                .done(function() {
                    Tipped.remove(dom.oddsButton);
                })
                .fail(function() {
                    dom.submitButton.attr('disabled', false).removeClass('btn-disabled').addClass('btn-primary');
                });
        };

        var isVisible = function() {
            // Looks like Tipped .t_visible class is only available in webkit browsers
            if ( raceBetsJS.browser.type.isWebkit() ) {
                return $('.t_Tooltip.betting-dialog.t_visible').length > 0;
            } else {
                if ( $('.t_Tooltip.betting-dialog').length > 0 ) {
                    return $('.t_Tooltip.betting-dialog').position().left >= 0;
                } else {
                    return false;
                }
            }
        };

        return {
            show: show,
            isVisible: isVisible
        };
    }();
})(raceBetsJS);
