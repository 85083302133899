/**
* Tipped extends
*
* @author Robin Ebers
*/

$.extend(Tipped.Skins, {
    'racebets': {
        maxWidth: 250,
        border: false,
        background: {
            color: '#333333'
        },
        radius: {
            size: 3,
            position: 'background'
        },
        shadow: false,
        hook: {
            target: 'bottommiddle',
            tooltip: 'topleft'
        },
        stem: {
            height: 6,
            width: 11,
            offset: {
                x: 5,
                y: 5
            }
        }
    },
    'stars': {
        maxWidth: 250,
        border: false,
        background: {
            color: '#333333'
        },
        radius: {
            size: 3,
            position: 'background'
        },
        shadow: false,
        hook: {
            target: 'bottommiddle',
            tooltip: 'topleft'
        },
        stem: {
            height: 6,
            width: 11,
            offset: {
                x: 5,
                y: 5
            }
        }
    },
	'formguideChart': {
        maxWidth: 250,
        border: false,
        background: {
            color: '#333333'
        },
        radius: 4,
        shadow: false,
        hook: 'righttop',
        stem: {
            height: 6,
            width: 11,
            offset: {
                x: 5,
                y: 5
            }
        },
        offset: {
            y: -10
        }
    },
    'sidebar': {
        maxWidth: 250,
        border: {
            size: 1,
            color: '#FFFFFF',
            opacity: 0.8
        },
        background: {
            color: '#333333'
        },
        radius: {
            size: 3,
            position: 'background'
        },
        shadow: false,
        hook: {
            target: 'bottommiddle',
            tooltip: 'topleft'
        },
        stem: {
            height: 6,
            width: 11,
            offset: {
                x: 5,
                y: 5
            }
        }
    },
    'eventRaces': {
         maxWidth: 500,
         border: {
             size: 2,
             color: '#333333',
             opacity: 1
         },
         background: {
             color: '#FFFFFF'
         },
         radius: {
             size: 3,
             position: 'background'
         },
         shadow: {
             blur: 5,
             color: '#000000',
             offset: { x: 0, y: 0 },
             opacity: 0.2
         },
         hook: 'rightbottom',
         offset: {
             x: 1,
             y: 2
         },
         stem: {
             height: 6,
             width: 11,
             offset: {
                 x: 5,
                 y: 5
             }
         }
    },
    'multi': {
        border: {
          size: 1,
          color: '#A7A7A7'
        },
        radius: 6,
        background: {
            color: '#FFFFFF'
        },
        shadow: false
    },
    'multiOdds': {
        maxWidth: 250,
        border: false,
        background: {
            color: '#333333'
        },
        radius: {
            size: 3,
            position: 'background'
        },
        shadow: false,
        hook: {
            target: 'bottommiddle',
            tooltip: 'topleft'
        },
        stem: {
            height: 6,
            width: 11,
            offset: {
                x: 5,
                y: 5
            }
        }
    },
    'oddsHistory': {
        maxWidth: 250,
        border: false,
        background: {
            color: '#333333'
        },
        radius: {
            size: 3,
            position: 'background'
        },
        shadow: false,
        hook: {
            target: 'bottommiddle',
            tooltip: 'topleft'
        },
        stem: {
            height: 6,
            width: 11,
            offset: {
                x: 5,
                y: 5
            }
        }
    },
    'runnerDetails': {
        maxWidth: 400,
        offset: { y: 16 },
        border: false,
        background: {
            color: '#333333'
        },
        radius: {
            size: 3,
            position: 'background'
        },
        shadow: false,
        hook: {
            target: 'bottommiddle',
            tooltip: 'topleft'
        },
        stem: {
            height: 6,
            width: 11,
            offset: {
                x: 5,
                y: 5
            }
        }
    },
    'runnersNotes': {
        maxWidth: 180,
        border: false,
        background: {
            color: '#333333'
        },
        radius: 3,
        shadow: false,
        hook: {
            target: 'bottommiddle',
            tooltip: 'topleft'
        },
        stem: {
            height: 6,
            width: 11,
            offset: {
                x: 5,
                y: 5
            }
        }
    },
    'bettingDialog': {
        maxWidth: 320,
        border: {
            size: 3,
            color: '#FFFFFF'
        },
        background: {
            color: '#333333'
        },
        radius: 5,
        shadow: {
            blur: 3,
            color: '#111111',
            opacity: 0.4
        },
        hook: 'topmiddle',
        offset: {
            y: -5
        },
        stem: {
            height: 6,
            width: 11,
            offset: {
                x: 5,
                y: 5
            }
        }
    }
});

$.extend(Tipped.Skins.CloseButtons, {
    'racebets': {
        diameter: 14,
        x: { diameter: 9, size: 2, opacity: 1 },
        offset: { x: 10, y: 5 }
    }
});

// Tipped.setDefaultSkin('racebets');

$(document).ready(function() {
    $('.tip-menu').each(function() {
        Tipped.create(this, $('#' + $(this).data('tip-element')).clone(true, true).removeAttr('id')[0], {
            skin: 'multi',
            hook: 'rightmiddle',
            showOn: 'click',
            hideOn: [
                { element: 'self', event: 'click' },
                { element: 'self', event: 'mouseleave' },
                { element: 'tooltip', event: 'mouseleave' }
            ],
            hideDelay: 750
        });
    });
});
