/**
* Formguide
*
* @author: Robin Ebers
*/

(function(raceBetsJS) {
    raceBetsJS.application.content.formguide = function() {

        var minH = 740,
            minW = 842,
            resizeW = minW,
            resizeH = minH;

        if (window.screen.availWidth < minW && window.screen.availHeight < minH) {
            resizeW = window.screen.availWidth;
            resizeH = window.screen.availHeight;
            window.resizeTo(resizeW, resizeH);
        }

        if (window.screen.availWidth < minW) {
            resizeW = window.screen.availWidth;
            window.resizeTo(resizeW, resizeH);
        }

        if (window.screen.availHeight < minH) {
            resizeH = window.screen.availHeight;
            window.resizeTo(resizeW, resizeH);
        }

        /**
        * Formguide v2
        */
        var dom, category, idRunner, template, data;

        function racePopup(idRace) {
            var popupOptions = {
                href: raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + idRace + "/popup/true/loadModules/race",
                name: idRace,
                height: 530,
                width: 800,
                scroll: 'no',
                resizable: 'yes'
            };

            var popup = raceBetsJS.browser.popup.open(popupOptions);
        }

        function onSearchForm() {
            if ($('#formguide-search-form-input').data('id-horse')) {
                raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix +
                    '/formguide/horse/id/' + $('#formguide-search-form-input').data('id-horse'));
            }

            // search button
            $('#formguide-search-form').submit(function(event) {
                event.preventDefault();

                raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix +
                    '/formguide/main/name/' + $('#formguide-search-form-input').val());
            });
        }

        function onHorseStatistics() {
            Tipped.create('div#formguide-horse-details .tipped', {
                hook: 'topmiddle',
                skin: 'racebets',
                hideOn: [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' }
                ]
            });

            $.each(['jockey', 'ground'], function(index, value) {
                $('div#formguide-horse-details div.panel.' + value + 's li[data-' + value + ']').each(function() {
                    $(this).click(function() {
                        var li = $('#formguide-horse-details div.panel.latest-form ul');
                        li.find('li.highlighted').removeClass('highlighted');
                        li.find('li[data-' + value + '="' + $(this).data(value) + '"]').addClass('highlighted');
                    });
                });
            });
        }


        function onOverview(data) {
            $("#content").on("click",".more-link",function(event){
                event.preventDefault();
                $("#content .comment-text").html(data.comment);
                $(this).hide();
            });
        }

        function onForm() {
            var scrollInterval,
            cached = [],
            onAnimation = false,
            $rows = $('#form-table-container tbody tr'),
            $formTableCon = $('#form-table-container'),
            $formRTableCon = false,
            headerOffset = 0,
            stewardsDecisionOffset = 0,
            wW=0,
            wH=0,
            extraScroll = 0,
            rightDisplayed = false,
            $formLeftCol = $('#form-left-col'),
            $formRightCol = $('#form-right-col'),
            $formRightColCar = $('#formCarrousel'),
            $scrollableInside = $('.scrollable-inside');

            $("#foot").hide();

            //console.log('Form...');
            Tipped.create($("#content table thead tr td.race-outcome span.info-icon"),
            raceBetsJS.i18n.data.labelTooltipOutcome,
            {
                hook: 'topmiddle',
                skin: 'racebets',
                maxWidth: 200
            });

            Tipped.create($("#form-table-container table tbody tr td span[title]"),
            {
                hook: 'topmiddle',
                skin: 'racebets',
                maxWidth: 600,
                hideOn: [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' }
                ]
            });

            /*
            Tipped.create($("#content table thead tr td.race-conditions span.info-icon"),
                raceBetsJS.i18n.data.labelTooltipConditions,
                {
                    hook: 'bottommiddle',
                    skin: 'racebets',
                    maxWidth: 200,
                    offset: { x: 3 }
            });
            */

            $('#content').on('click','.toracecard',function(e){
                e.preventDefault();
                raceBetsJS.browser.crossFrame.send('open', '/'+$(this).attr('href'));
            });

            $("#content").on("click", "span.formtovideo", function(){
                if (raceBetsJS.application.user.loggedIn) {
                    var popupFixedHeight = 381, // stream + details height
                        popupRunnerHeight = 38,
                        popupMaxHeight = $(window).height() - 10,
                        popupHeight;

                    // precalculate the height of the popup from number of players
                    popupHeight = popupFixedHeight + (Math.round($(this).data("numrunners") / 2) * popupRunnerHeight) + 17;
                    if (popupHeight > popupMaxHeight) {
                        popupHeight = popupMaxHeight;
                    }
                    raceBetsJS.application.assets.media.show({
                         idRace: $(this).data("idrace"),
                         idChannel: $(this).data("idchannel"),
                         provider: $(this).data("provider"),
                         streamType: $(this).data("streamtype"),
                         url: $(this).data("archive"),
                         country: $(this).data("country"),
                         forcePopup: true,
                         href: '/media/archivePopup/idRace/' + $(this).data("idrace") + '/',
                         height: popupHeight
                    });
                } else {
                    raceBetsJS.application.assets.dialogs.loginDialog.show();
                }
            });

            function loadRace(idRace) {
                if (onAnimation) {
                    return false;
                }

                onAnimation = true;
                $('.loading-cover').css('display', 'block');
                if($.isEmptyObject(cached[idRace])) {
                    $.ajax({
                        url: '/ajax/formguide/racecard/id/',
                        type: 'get',
                        data: {
                            id : idRace
                        },
                        complete: function() {
                            onAnimation = false;
                        },
                        success: function(raceData) {
                            if(!$.isEmptyObject(raceData)){
                                $.extend(raceData, {currentIdSubject : runnerInfo.idSubject});

                                if (raceData.raceDetails.purseDetails) {
                                    $.each(raceData.raceDetails.purseDetails, function(index, elem) {
                                            raceData.raceDetails.purseDetails[index] = raceBetsJS.format.number(elem);
                                    });
                                    raceData.raceDetails.purseDetails = raceData.raceDetails.purseDetails.join(' - ');
                                }

                                cached[idRace] = raceBetsJS.application.templates.formguide.formRightColBox({data: raceData});
                                displayRight(cached[idRace]);
                            }
                        }
                    });
                } else {
                    displayRight(cached[idRace]);
                    onAnimation = false;
                }
            }

            // binding onclick to open the tooltip
            $('#form-table-container tbody').on('click','tr:not(.active)',function(){
                $rows.removeClass('active');
                $(this).addClass('active');
                loadRace($(this).data('idrace'));
            });

            function undoHighlightRace(idRace) {
                var $tr = $("#form-table-container tr[data-idrace=" + idRace + "]");
                $("#form-table-container tr.active").removeClass("active");
            }

            function highlightRace(idRace) {
                var $tr = $("#form-table-container tr[data-idrace=" + idRace + "]"),
                $scroll = $("div.scrollable-inside"),
                scrollTop = $scroll.scrollTop(),
                trY = $tr.position().top,
                trH = $tr.height(),
                scrollH = $scroll.height();

                $("#form-table-container tr.active").removeClass("active");
                $tr.addClass("active");

                clearInterval(scrollInterval);
                scrollInterval = setTimeout(function(){
                    $scroll.animate({scrollTop :  (scrollTop + (trY - $scroll.position().top) - (scrollH/2) + (trH/2) )});
                }, 100);
            }

            function updateSizes(resized) {
                headerOffset = $('#nav').height() + $('#horse').height() + 12,
                stewardsDecisionOffset = ($('#stewardsDecision').length == 1) ? $('#stewardsDecision').height() : 0,
                wH = $(window).height(),
                wW = $(window).width() - extraScroll;

                if ($('#beta-notification-wrapper').length == 1) {
                    headerOffset += $('#beta-notification-wrapper').height();
                }

                var $boxes = $formRightCol.find('.data-box');

                $('#form-right-table-container').css('max-height', (wH - headerOffset - 82 - stewardsDecisionOffset));

                $formRightCol.css('width', (wW - 220));

                if(rightDisplayed){
                    $formRightColCar.css('width',((wW * 2) - 220));
                } else {
                    $formRightColCar.css('width', (wW - 220));
                }

                $boxes.css('width', (wW - 220));

                //$formRightCol.css({height: wH - headerOffset - 15 });

                $formTableCon.css('max-height', (wH - headerOffset - 45));
                $formRightCol.css('min-height', (wH - headerOffset - 16 + stewardsDecisionOffset));

                if (resized) {
                    $formRightCol.css('width', (wW - 220));
                    $boxes.css('width', (wW - 236));
                }

                extraScroll = 0;
            }

            function displayRight(htmlContent) {
                $boxes = $formRightCol.find('div.data-box');

                if (!rightDisplayed) {
                    $formRightColCar.html(htmlContent);
                } else {
                    $formRightColCar.append($(htmlContent));
                }

                var delay = 250;
                extraScroll = 17;

                $formRightCol.css('display', 'block');
                $scrollableInside.css('min-height', $formRightColCar.height() + parseFloat($formLeftCol.css('margin-top')));

                updateSizes();

                if (rightDisplayed) {
                    $formRightColCar.animate({left: - wW + 200}, delay, 'easeInOutExpo', function() {
                        var $boxOne = $($formRightCol.find('.data-box')[0]);
                        $boxOne.remove();
                        $formRightColCar.css({left: 0});
                        rightDisplayed = true;
                        $('#form-right-table-container').css('max-height', (wH - headerOffset - 82 - stewardsDecisionOffset));
                    });
                } else {
                    $formRightCol.css('left', (wW + 10));
                    $formRightCol.animate({left: 220}, delay, 'easeInOutExpo', function(){ rightDisplayed = true; });
                }

                $formLeftCol.animate({width:190}, delay, 'easeInOutExpo', function(){ $formLeftCol.addClass("small"); });

                Tipped.create($("#form-right-table-container > table > thead > tr span.info-icon"),
                raceBetsJS.i18n.data.labelTooltipDistances,
                {
                    hook: 'bottommiddle',
                    skin: 'racebets',
                    maxWidth: 200,
                    offset: { x: 3 }
                });

                if ($(htmlContent).find('span.purse').data('tip') != '') {
                    Tipped.create($('#form-right-col div.sub-header span.purse'),
                    $(htmlContent).find('span.purse').data('tip'),
                    {
                        hook: 'bottommiddle',
                        skin: 'racebets',
                        maxWidth: 600,
                        offset: { x: -10 }
                    });
                }

                if ($(htmlContent).find('span.race-conditions').length == 1) {
                    Tipped.create($('#form-right-col div.sub-header span.race-conditions'),
                    $(htmlContent).find('span.race-conditions').data('tip'),
                    {
                        hook: 'bottommiddle',
                        skin: 'racebets',
                        maxWidth: 600,
                        offset: { x: 3 }
                    });
                }

                $('#form-right-table-container table tbody tr td a.runnerName').each(function() {
                    var tip = '<table><tr><td style="padding-right: 5px">' + raceBetsJS.i18n.data.labelTrainer
                        + ':</td><td>' + $(this).data('trainer') + '</td></tr>'
                        + '<tr><td style="padding-right: 5px">' + raceBetsJS.i18n.data.labelOwner + '</td><td>'
                        + $(this).data('owner') + '</td></tr></table>';
                    Tipped.create($(this),
                    tip,
                    {
                        hook: 'bottommiddle',
                        skin: 'racebets',
                        maxWidth: 600,
                        hideOn: [
                            { element: 'self', event: 'mouseleave' },
                            { element: 'tooltip', event: 'mouseenter' }
                        ]
                    });
                });
            }

            function hideRight() {
                if (!onAnimation) {
                    onAnimation = true;
                    var delay = 250;
                    $formLeftCol.removeClass("small");
                    $rows.removeClass('active');
                    $formRightCol.animate({left:$( window ).width()+10},delay,'swing',function(){
                        onAnimation = false;
                        rightDisplayed=false;
                        $formRightCol.css({display:"none"});
                        $formRightColCar.html("");
                    });
                    $formLeftCol.animate({width:$( window ).width()-30},delay,'swing',function(){});
                }
            }

            // init
            function init() {
                $("body").css({"overflow-y":"hidden", "overflow-x":"hidden"});
                updateSizes();

                $('#form-left-col').on('click', "span.close", function() {
                    hideRight();
                });

                $scrollableInside.css('position', 'relative');

                $(window).resize(function() {
                  updateSizes(true);
                });
            }

            init();
        }

        function onStatistics() {
            // init
            function init() {
                $("body, #wrapper").css({"overflow-y":""});
            }

            init();
        }

        function onDuels(_data) {
            var $opponentsTable = $("#content table.duel-opponents"),
            $searchBox = $("#opponents-search-box"),
            $searchInput = $("#formguide-search-opponent-input"),
            $magniCross = $searchBox.find("span.magni-cross"),
            $duelsRight = $("#duels-right-content"),
            firstId;

            function activateOpponent(opponent) {

                $opponentsTable.find("tr").removeClass("active");
                $opponentsTable.find("tr[data-idrunner="+opponent.idRunner+"]").addClass("active");
                $duelsRight.html("");

                if(!opponent.hasOwnProperty("duels")) {
                    $("#content div.box-noresults").show();
                    return false;
                }

                $("#content div.box-noresults").hide();
                $duelsRight.html(raceBetsJS.application.templates.formguide.duels.rightColumn({data:{runner : _data,opponent: opponent}}));

                //video icons
                $('#content').on('click','.toracecard',function(e){
                    e.preventDefault();
                    raceBetsJS.browser.crossFrame.send('open', '/'+$(this).attr('href'));
                });

                $("#content").on("click","span.tovideo",function(){
                    if(raceBetsJS.application.user.loggedIn) {
                        var duelHash = $(this).data("duelhash");

                        var popupFixedHeight = 381, // stream + details height
                            popupRunnerHeight = 38,
                            popupMaxHeight = $(window).height() - 40,
                            popupHeight;


                        $.each(opponent.duels, function(k, duel) {
                            if(duel.duelHash == duelHash) {

                                // precalculate the height of the popup from number of players
                                popupHeight = popupFixedHeight + (Math.round(duel.runnersNum / 2) * popupRunnerHeight) + 15;
                                if (popupHeight > popupMaxHeight) {
                                    popupHeight = popupMaxHeight;
                                }

                                raceBetsJS.application.assets.media.show({
                                    idRace: duel.idRace,
                                    idChannel: duel.media.idChannel,
                                    streamType: duel.media.streamType,
                                    url: duel.media.archiveLink,
                                    country: duel.country,
                                    forcePopup: true,
                                    href: '/media/archivePopup/idRace/' + duel.idRace + '/',
                                    height: popupHeight
                                });

                                return false;
                            }
                        });
                    } else {
                        raceBetsJS.application.assets.dialogs.loginDialog.show();
                    }
                });
            }

            function addOpponent(runner) {
                if($opponentsTable.find("tr[data-idrunner="+runner.idRunner+"]").length <=0 ) {
                    $newOpponent = $(raceBetsJS.application.templates.formguide.duels.runner({runner:runner}));
                    $opponentsTable.append($newOpponent);
                }
            }

            function clearSearch() {
                $searchInput.val("").focus();
                $magniCross.removeClass("cross").addClass("magnifier");
            }

            // Autocomplete dropdown
            $searchInput.autoCompDrop({
                liContentTemplate : raceBetsJS.application.templates.autoCompleteDrop.liTemplates.runner,
                searchParamName : "s",
                extraRequestParameters : {
                    id : _data.idSubject
                },
                requestURL : "/ajax/formguide/duelsSearch",
                guideElement : $("#content .input-wrapper"),
                onFailMsg : raceBetsJS.i18n.data.labelNothingFound,
                extraClass : "extra",
                onSelect : function(opponent){

                    var requestInProgress = false;
                    $searchInput.val(opponent.name);

                    if (requestInProgress === false) {
                        requestInProgress = true;
                        $("#content div.box-noresults").hide();
                        $("#duels-right").removeClass("loading").addClass("loading");
                        $.ajax({
                            url: "/ajax/formguide/getOpponent",
                            type: 'get',
                            data: {
                                id : _data.idSubject,
                                o : opponent.idSubject
                            },
                            complete: function() {
                                requestInProgress = false;
                                $("#duels-right").removeClass("loading");
                            },
                            success: function(opponentData) {
                                if(!$.isEmptyObject(opponentData)){
                                    activateOpponent(opponentData);
                                    $opponentsTable.find("tr").removeClass("active");
                                    $opponentsTable.find("tr[data-idrunner="+opponentData.idRunner+"]").addClass("active");
                                    $duelsRight.html("").html(raceBetsJS.application.templates.formguide.duels.rightColumn({data : {opponent: opponentData,runner : _data}}));
                                }else {
                                    $("#content div.box-noresults").show();
                                }
                            }
                        });
                    }
                }
            });

            $searchBox.on("click","span.magni-cross.cross",function(){
                clearSearch();
            });

            $searchInput.on("keyup",function(){
                var $this = $(this);
                var nClass = !$this.val() ? "magnifier" : "cross";
                $magniCross.removeClass("cross magnifier").addClass( nClass );
            });

            // Race opponents table  binding

            $("#content table.duel-opponents").on("click","tr",function(){
                var $runner = $(this);
                var idRunner = $runner.data("idrunner");
                $.each(_data.opponents,function(i,runner){
                    if(runner.idRunner == idRunner) {
                        activateOpponent(runner);
                        return false;
                    }
                });

                clearSearch();
            });


            // Activate first option
            firstId = $("#content table.duel-opponents tr").first().data("idrunner");
            $.each(_data.opponents,function(i,runner){
                if(runner.idRunner == firstId) {
                    activateOpponent(runner);
                    return false;
                }
            });

            // init
            function init() {
                $('body').css('overflow-y', '');
            }

            init();
        }

        function onAntePost(_data) {
            //console.log('Ante Post...');
            var $buttons = $("#content .box-primary .odds-button");
            $.each($buttons,function(i,b){
                var $button = $(b);
                var betTypes = _data[$button.data("index")].betTypes;
                if($.inArray($button.data("bet-type"),betTypes) != -1){
                    var generatedButton = new raceBetsJS.application.content.race.OddsButton($button, 'FXW');
                    generatedButton.setOdds($button.children('span').data('odds'),$button.hasClass('enabled'));
                } else {
                    $button.removeClass("enabled").addClass("disabled");
                }
            });

            $("#content").on('mouseup', '.odds-button.fixed.enabled', function() {
                raceBetsJS.application.assets.bettingDialog.show(this);
            });
        }

        function onFormData(_dat) {

            var _data;

            dom = {
                nav: $('#nav'),
                content: $('#content'),
                buttons: {
                    notes: $('#horse div.editor button[role="notes"]'),
                    follow: $('#horse div.editor button[role="follow"]')
                },
                numHints: {
                    duels: $('#nav li[role="duels"] span.num-hint'),
                    antepost: $('#nav li[role="antepost"] span.num-hint')
                }
            };

            dom.nav.find('ul li a').removeClass('active');
            dom.nav.find('ul li[role="' + category + '"] a').addClass('active');

            if((category == "overview") && (_dat.hasOwnProperty("comment"))) {

                var l = 250;

                _dat.showMore = false;

                if (_dat.comment.length > l) {
                    _dat.shortComment = _dat.comment.substring(0,l) + "...";
                    _dat.showMore = true;
                } else {
                    _dat.shortComment = _dat.comment;
                }
                _dat.shortComment = (_dat.comment.length > l) ? _dat.comment.substring(0,l) + "..." : _dat.comment;

            }

            if ($.inArray(category,["duels","antepost"]) > -1) {
                if (category == "antepost") {
                    _dat = runnerInfo.antepost;
                } else {
                    _dat = runnerInfo ;
                }
            }

            if ($.inArray(category,["form","chart"]) > -1) {
                _data = {
                    data : _dat.formTable,
                    flags : {
                        ratingsFlag: _dat.ratingsFlag,
                        categoryLetterFlag: _dat.categoryLetterFlag
                    }
                };
            } else {
                _data = { data : _dat };
            }

            setFavourite();

            template = raceBetsJS.application.templates.formguide[category](_data);

            $('#content').html(template).attr('class', category);
            $("#foot").show(); // toggle back footer on every tab

            switch (category) {
                case 'form':
                    onForm();
                    break;
                case 'statistics':
                    onStatistics();
                    break;
                case 'chart':
                    raceBetsJS.application.content.formguide.chart.init(_dat.formTable, resizeW, resizeH);
                    break;
                case 'duels':
                    onDuels(_dat);
                    break;
                case 'antepost':
                    onAntePost(_dat);
                    break;
                default:
                    onOverview(_dat);
            }
        }

        function createTooltips() {
            if (raceBetsJS.application.user.loggedIn) {
                Tipped.create('#horse button.notes', raceBetsJS.i18n.data.labelNotes, {
                    //hook: 'bottomright',
                    maxWidth: 600,
                    hideOn: [
                        { element: 'self', event: 'mouseleave' },
                        { element: 'tooltip', event: 'mouseenter' }
                    ]
                });
                Tipped.create('#horse button.follow', raceBetsJS.i18n.data.labelFavourite, {
                    //hook: 'bottomright',
                    maxWidth: 600,
                    hideOn: [
                        { element: 'self', event: 'mouseleave' },
                        { element: 'tooltip', event: 'mouseenter' }
                    ]
                });
            } else {
                Tipped.create('#horse button', raceBetsJS.i18n.data.featureRequiresLogin, {
                    //hook: 'bottomright',
                    maxWidth: 600,
                    hideOn: [
                        { element: 'self', event: 'mouseleave' },
                        { element: 'tooltip', event: 'mouseenter' }
                    ]
                });
            }
        }

        function setFavourite() {
            var ajaxInProgress = false;

            if (raceBetsJS.application.user.loggedIn) {
                $('#horse').off('click.editor').on('click.editor', '.editor button', function() {
                    var $this = $(this);

                    if (ajaxInProgress === false) {
                        if ($this.attr('role') == 'follow') {
                            ajaxInProgress = true;

                            $.ajax({
                                url: '/ajax/favourites/star/id/' + runnerInfo.idSubject + '/store/' + ($this.hasClass('active') ? 'false' : 'true'),
                                type: 'get',
                                complete: function() {
                                    ajaxInProgress = false;
                                },
                                success: function() {
                                    $this.toggleClass('active');
                                },
                                error: function(jqXHR) {
                                    var responseText = $.parseJSON(jqXHR.responseText);

                                    switch (responseText.errorMsg) {
                                        case 'LOGIN_REQUIRED':
                                            raceBetsJS.application.assets.dialogs.loginDialog.show();
                                            break;
                                        default:
                                            raceBetsJS.application.assets.modalDialog.show({
                                                closeButton: true,
                                                content: '<p>' + responseText.errorMsg + '</p>'
                                            });
                                    }
                                }
                            });

                        } else if ($this.attr('role') == 'notes') {
                            raceBetsJS.application.assets.dialogs.runnerNotes.show({
                                'idSubject': runnerInfo.idSubject,
                                'title': raceBetsJS.i18n.print('modalRunnerNotesTitle', {runnerName: runnerInfo.name}),
                                'eventTarget': $this
                            });
                        }
                    }
                });

            } else {
                $('#horse').off('click.editor').on('click.editor', '.editor button', function(e) {
                    e.preventDefault();
                    raceBetsJS.application.assets.dialogs.loginDialog.show();
                });
            }
        }

        // @public
        return {
            init: function() {

                // @TODO: maybe there is a regex that can combine the first two without interfering with the third?
                raceBetsJS.application.contentController.addCallback(/^\/formguide\/?$/, onSearchForm);
                raceBetsJS.application.contentController.addCallback(/^\/formguide\/main\/name\//, onSearchForm);
                raceBetsJS.application.contentController.addCallback(/^\/formguide\/horse\/id/, onHorseStatistics);

                raceBetsJS.application.contentController.addDynamicPage({
                    urlPattern: /^\/formguide\/(?!horse|race)(\w+)\/id\/(\d+)/,
                    dataSourceURLBuilder: function(_category, _idRunner) {
                        //console.log(_category, _idRunner);
                        category = _category;
                        idRunner = _idRunner;

                        if( $.inArray(category,["duels","antepost"]) > -1) {
                            return "";
                        }
                        else {
                            return '/ajax/formguide/' + category + '/id/' + idRunner;
                        }

                    },
                    onDataReceived: onFormData,
                    onUnload: function() {
                        data = null;
                        idRunner = null;
                    }
                });

                createTooltips();
            }
        };
    }();
})(raceBetsJS);
