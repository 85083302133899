/**
* Router
*
* @description 	Matches strings against regexp patterns and invokes callback functions with capture groups applied as arguments.
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
    raceBetsJS.content.Route = Class.extend({
        init: function(pattern) {
            this.pattern = pattern;
            this.callbacks = [];
        },

        to: function(callback) {
            this.callbacks.push(callback);
        },

        fire: function(args) {
            $.each(this.callbacks, function() {
                this.apply(null, args);
            });
        }
    });


	raceBetsJS.content.Router = Class.extend({
        init: function() {
		    this.routes = {};
	    },

		route: function(pattern) {
			if(this.routes[pattern] === undefined) {
				this.routes[pattern] = new raceBetsJS.content.Route(pattern);
			}

			return this.routes[pattern];
		},

		trigger: function(string) {
			var route = this.match(string);

			if(route !== undefined) {
				var matches = route.pattern.exec(string);
				route.fire(matches.splice(1, matches.length - 1));
				return true;
			}

			return false;
		},

		match: function(string) {
            //$.log('Trying to find dynamic content for: ' + string);

            for(var route in this.routes) {
                if(this.routes[route].pattern.test(string)) {
                    //$.log('Found route: ' + this.routes[route].pattern);
                    return this.routes[route];
                }
            }

            //$.log('NO ROUTE FOUND: ' + string);
		}
	});
})(raceBetsJS);