/**
* Select Box
*
* @author Robin Ebers
*/
(function(raceBetsJS) {
    raceBetsJS.application.assets.SelectBox = Class.extend({

        init: function(elem, _options) {
            this.dom = {};
            this.options = $.extend({}, _options);
            this.items = [];


            var items = [],
                active = null,
                dom = {},
                selectBox,
                template = _.template('\
                    <input type="hidden" value="<%= (active ? active.value : "") %>" />\
                    <% if (options.editable !== undefined && options.editable === true) { %>\
                        <input type="text" value="<%= (active ? active.label : "") %>">\
                    <% } else { %>\
                        <label><%= (active ? active.label : "") %></label>\
                    <% } %>\
                    <button class="btn">\
                        <span class="icon arrow-down"></span>\
                    </button>\
                    <ul>\
                        <% $.each(items, function() { %>\
                            <li data-value="<%= this.value %>" class="<%= (this.selected ? "selected" : "") %> <%= (this.disabled ? "disabled" : "") %>"><%= this.label %></li>\
                        <% }); %>\
                    </ul>\
                ');

            // parse items
            $.each(elem.find('option'), $.proxy(function(i, option) {
                this.items.push({
                    value:    $(option).attr('value'),
                    label:    this.toHTML($(option).text()),
                    selected: $(option).is(':selected'),
                    disabled: $(option).is(':disabled')
                });

                // active item?
                if ($(option).is(':selected') && !$(option).is(':disabled')) {
                    active = {
                        value: $(option).attr('value'),
                        label: this.toHTML($(option).text())
                    }
                }
            }, this));

            // create html and save reference
            this.dom.container = $('<div />').attr('class', 'select-box').html(template({
                active: active,
                items: this.items,
                options: this.options
            }))
            elem.replaceWith(this.dom.container);

            // get all dom references
            this.dom.hidden = this.dom.container.find('input[type="hidden"]');

            if (this.options.editable === true) {
                this.dom.input = this.dom.container.find('input[type="text"]');
            } else {
                this.dom.label = this.dom.container.find('label');
            }

            // copy attributes
            $.each(['id', 'name'], $.proxy(function() {
                var key = this.toString();
                if (typeof elem.attr(key) !== 'undefined' && elem.attr(key) !== false) {
                    this.dom.hidden.attr(key, elem.attr(key));
                }
            }, this));

            // observe select click
            this.dom.container.on('click', $.proxy(function(e) {

                // disabled
                if (this.dom.container.hasClass('disabled')) {
                    return;
                }

                // is expanded right now
                if (this.dom.container.hasClass('expanded')) {
                    this.collapse();

                } else {
                    this.expand();

                    // ... otherwise the select won't show
                    e.stopImmediatePropagation();
                }
            }, this));

            // observe option click
            this.dom.container.on('click', 'li', $.proxy(function(e, elem, elem2) {
                var item = $(e.currentTarget);
                e.stopPropagation();

                if (item.hasClass('disabled')) {
                    return;
                }

                // hide current item and show all others
                this.dom.container.find('li').removeClass('selected');
                item.addClass('selected');

                // update label and value
                this.dom.hidden.val(item.data('value'));

                if (this.options.editable === true) {
                    this.dom.input.val(item.text());
                } else {
                    this.dom.label.html(item.html());
                }

                this.onChange();

                // hide itemlist
                this.dom.container.removeClass('expanded');

            }, this));

            // input observers
            if (this.options.editable === true) {
                if (typeof this.options.onTextInputBlur === 'function') {
                    this.dom.input.on('blur', $.proxy(function(e) {
                        this.options.onTextInputBlur(e);
                        this.onChange();
                    }, this));
                }

                if (typeof this.options.onTextInputKeyUp === 'function') {
                    this.dom.input.on('keyup', $.proxy(function(e) {
                        this.options.onTextInputKeyUp(e);
                        this.onChange();
                    }, this));
                }

                // on keydown
                this.dom.input.on('keydown', $.proxy(function() {
                    this.collapse();
                }, this));

                // on click
                this.dom.input.on('click', function(e) {
                    this.select();
                    e.stopPropagation();
                });
            }

            // set fixed width, if required
            if (this.options.width !== undefined) {
                this.dom.container.css('width', this.options.width + 'px');

                if (this.options.editable === true) {
                    this.dom.input.css('width', (this.options.width-36) + 'px');
                }
            }

            // if some element was active, finally trigger onSelect
            if (active !== undefined) {
                this.dom.hidden.val(this.val());
            }
        },

        collapse: function() {
            this.dom.container.removeClass('expanded');
        },

        expand: function() {
            // is closed
            this.dom.container.addClass('expanded');

            // hide on click
            $('html').one('click', $.proxy(function() {
                this.dom.container.removeClass('expanded');
            }, this));
        },

        val: function(label, value) {
            if (label === undefined) {
                return this.dom.hidden.val();
            } else {
                this.dom.hidden.val(value);
            }

            if (this.options.editable === true) {
                this.dom.input.val(label);
            } else {
                this.dom.input.val(this.toHTML(label));
            }
        },

        focus: function() {
            if (this.options.editable !== true) {
                return;
            }

            this.dom.input.focus();
        },

        onChange: function() {
            if (typeof this.options.onSelect == 'function') {
                this.options.onSelect(this.val());
            }
        },

        toHTML: function(str) {
            if (typeof str !== 'string') {
                return;
            }
            var html = str.replace(/\{/g, '<').replace(/\}/g, '>');
            return html;
        },

        enable: function() {
            this.dom.container.removeClass('disabled');
        },

        disable: function() {
            this.dom.container.addClass('disabled');
        }
    });
})(raceBetsJS);
