/**
* Event
*
* @description: Event content module
*
* @author: Moritz Honig
* @author: Robin Ebers
*/
(function(raceBetsJS) {
    raceBetsJS.application.content.event = function() {
        // @private

        var eventData,
            domElements,
            pendingRequest;

        //  Container to store the subscription so we can remove it onunload
        var webSocketSubscription;

        var leaveSocketChannel = function(){
            if(webSocketSubscription){
                raceBetsJS.webSockets.nodeJS.leave(webSocketSubscription);
                webSocketSubscription = null;
            }
        }

        var joinSocketChannel = function (){
            webSocketSubscription = raceBetsJS.webSockets.nodeJS.join({
                        channel : "node_eventCard_"+eventData.event.idEvent,
                        callback : socketCB,
                        timestamp: eventData.timestamp
            });
        }


        // Updater
        var pollingRequestInterval;
        var startAjaxUpdates = function(){
            pollingRequestInterval = new raceBetsJS.time.Interval({
                interval: raceBetsJS.application.globals.ajax.poolDelay,
                tick: function() {
                    // toggle stream link
                    toggleLiveStreamLink();

                    // update details
                    pendingRequest = new $.ajax({
                        url: raceBetsJS.application.content.getAjaxCacheUrl('/ajax/events/details/id/' + eventData.event.idEvent + '/version/short/'),
                        data: { followup: true },
                        success: socketCB,
                        complete: function() {
                            // Clear pending request
                            pendingRequest = null;
                        }
                    });
                }
            });
        }




        // node.js response callback
        var socketCB = function(data) {
            // this will be updated if data.event is present
            if (data.event && eventData.event.isSpecialBets) {
                // if betting dialog is visible, don't update at all
                if (raceBetsJS.application.assets.bettingDialog.isVisible()) {
                    return;
                }

                // if the number of total bets changed, reload
                if (eventData.event.numRaces != data.event.numRaces) {
                    pollingRequestInterval.stop();
                    leaveSocketChannel();
                    $.get(
                        raceBetsJS.application.content.getAjaxCacheUrl('/ajax/events/details/id/' + eventData.event.idEvent),
                        onDataReceived
                    );
                    return;
                }
            }

            if(data.races){
                // compare all races details to the cached ones and update when necessary
                $.each(data.races, function(index, race) {

                    var eventRace = eventData.racesById[race.idRace];

                    race.scratches = race.scratches || eventRace.scratches;
                    race.favourites = race.favourites || eventRace.favourites;
                    race.results = race.results || [];

                    // Remove favourites if we have incoming results or the race is over
                    if((race.results && race.results.length>0) || ($.inArray(race.raceStatus,['OPN','STR']) == -1)){
                        eventRace.favourites = [];
                        race.favourites = [];
                    }

                    //  Remove results when raceStatus make its necessary
                    if($.inArray(race.raceStatus,['OPN','STR','CNC','SPD']) != -1){
                        eventRace.results = [];
                        race.results = [];
                    }

                    $.each(race, function(key, value) {
                        var isObject = value instanceof Object;
                        var showSilks = null;
                        var raceDetails = null;

                        // If new data is different from current
                        if ((isObject && !raceBetsJS.object.equals(value, eventRace[key])) || (!isObject && value != eventRace[key])) {

                            eventRace.scratches = [];
                            eventRace.favourites = [];

                            // update the cached data, so we will  work with updated version
                            $.extend(true, eventRace, race);

                            // cannot use $.replaceWith here as we loose the DOMelement reference for the race then
                            raceDetails = domElements.races[race.idRace];

                            if (eventData.event.isSpecialBets && eventData.event.specialBetType == 'H2H') {
                                showSilks = true;
                               //$.each(race.runners, function() {     });

                                raceDetails.html($(raceBetsJS.application.templates.event.special.h2h.race({
                                    race: $.extend(eventRace, {showSilks: showSilks})
                                })).html());

                            } else if (eventData.event.isSpecialBets) {
                                raceDetails.html($(raceBetsJS.application.templates.event.special.race({
                                    race: eventRace
                                })).html());

                            } else {

                                if(race.archiveVideoExtension && eventRace.archiveLink && race.archiveVideoExtension ){
                                    eventRace.archiveLink = (eventRace.archiveLink + race.archiveVideoExtension).toLowerCase();
                                    eventRace.archiveVideo = true;
                                    eventRace.streamType = "MP4";
                                }

                                raceDetails.html($(raceBetsJS.application.templates.event.race({
                                    event: {
                                        country: eventData.event.country,
                                        media: eventData.event.media
                                    },
                                    race: eventRace
                                })).html());
                            }

                            return false; // break and go to next race
                        }
                    });
                });


            }
        }



        // Updater
        var pollingRequestInterval = new raceBetsJS.time.Interval({
            interval: 3000,
            tick: function() {
                // toggle stream link
                toggleLiveStreamLink();
            }
        });

        var toggleLiveStreamLink = function() {
            if (domElements.liveStreamLink === undefined) {
                return;
            }

            var anchor = $(domElements.liveStreamLink);

            // check if stream can be activated
            if (!anchor.hasClass('enabled')
                && ($.now() - raceBetsJS.application.user.timeDiff) > anchor.data('start') * 1000
            ) {
                anchor
                    .addClass('enabled')
                    .click(function() {
                        if (!raceBetsJS.application.header.login.check()) {
                            return;
                        }

                        var params = {
                            streamType: 'LIV',
                            provider: eventData.event.media.provider,
                            country: eventData.event.country,
                            idRace: getNextDueRaceId(),
                            idChannel: eventData.event.media.idChannel
                        };

                        if (params.provider === 'PFG' || !eventData.event.media.idChannel) {
                            params['idRace'] = eventData.event.media.idRace;
                        }

                        raceBetsJS.application.assets.media.show(params);
                    })
                    .find('span.link').html(raceBetsJS.i18n.data.linkOpenLivestream);
            }

            // remove link if races are over
            var racesOver = true;
            $.each(eventData.races, function() {
                if (this.raceStatus != 'FNL' && this.raceStatus != 'CNC') {
                    racesOver = false;
                    return false;
                }
            });

            var idxLastRace = eventData.races.length - 1;
            if (racesOver ||
                (
                    eventData.races[idxLastRace] && eventData.races[idxLastRace].filterCountries && eventData.races[idxLastRace].filterType &&
                    !raceBetsJS.media.isCountryAllowed(raceBetsJS.application.user.mediaCountry, eventData.races[idxLastRace].filterCountries, eventData.races[idxLastRace].filterType)
                )
            ) {
                anchor.remove();
                domElements.liveStreamLink = undefined;
            }
        }

        function getNextDueRaceId() {
            var dueRace = getNextDueRace();
            return dueRace.idRace;
        }

        function getNextDueRace() {
            if(eventData.races) {
                return _.find(eventData.races, function(race) {
                    return ['OPN', 'STR'].indexOf(race.raceStatus) > -1;
                });
            }
        }

        /**
        * onDataReceived
        *
        * @param data
        */
        function onDataReceived(data) {
            // Restores Tipped tooltips to initial state
            Tipped.init();

            if (data.type == 'error') {
                if (data.errorMsg == 'EVENT_NOT_FOUND') {
                    raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix +  '/static/error404/')
                } else {
                    raceBetsJS.application.assets.modalDialog.generic(data.errorMsg);
                }

                return;
            }

            _.each(data.races, function(race) {
                if(race.promotions) {
                    race.promotions = _.filter(race.promotions, function(idx,key){
                        return _.contains(race.promotions[key].countries, raceBetsJS.application.user.country);
                    });

                    race.promotions = _.map(race.promotions, function(idx,key){
                        if (race.promotions[key].promotionType !== 'custom') {
                            race.promotions[key].label = raceBetsJS.string.hyphenToCamelCase('labelPromotion-'+race.promotions[key].promotionType);
                        }
                        return race.promotions[key];
                    });
                }
            });

            // if we have only one race in an ante-post market that is no special bets event, go to the race
            if (data.event.isAntePost && !data.event.isSpecialBets && data.races.length == 1) {
                raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/race/details/id/'
                    + data.races[0].idRace + '/', true);
                return;
            }

            // delete media for breeders cup if ipCountry is IE/GB
            var eventDate = raceBetsJS.application.assets.timezone.date('Y-m-d', data.event.firstStart, 'Europe/Berlin');
            if (eventDate.match(/2013-11-0[1|2]/) && data.event.idTrack == 393 && $.inArray(raceBetsJS.application.user.ipCountry, ['GB', 'IE']) > -1) {
                delete data.event.media;
            }

            // remove banners outside their publishing time range
            data = bannerExpiration(data);

            // Set nav state
            raceBetsJS.application.header.navigation.activateItemByKey('racing');

            // Set eventData
            eventData = data;

            if(raceBetsJS.application.globals.webSockets){
                // Subscribe to short version at node js server
                joinSocketChannel();
            }else{
                startAjaxUpdates();
            }

            // remove media data if idChannel is not supported on current device
            if (eventData.event.media !== undefined && raceBetsJS.application.assets.media.isUnsupportedChannel(eventData.event.media.idChannel)) {
                delete eventData.event.media;
            }

            // check if ATR archive media is already avalible (5 mins after now)
            if (eventData.event.media !== undefined && eventData.event.media.provider === 'ATR') {
                _.each(data.races, function(race) {
                    if ( ($.now() - raceBetsJS.application.user.timeDiff) > race.postTime * 1000 + (1000 * 60 * 5)) {
                        race.atrArchive = true;
                    }
                });
            }

            // Render data with template
            var content = $(raceBetsJS.application.templates.event(data));

            // reference dom elements
            domElements = {
                liveStreamLink: content.find('a.live-stream')[0],
                races: {}
            };

            // generate idRace > race data map
            eventData.racesById = {};
            $.each(eventData.races, function(index, race) {
                eventData.racesById[race.idRace] = race;
            });

            // toggle live stream link
            toggleLiveStreamLink();

            // Store all elements required for later use
            content.find('li.race').each(function() {
                var raceElement = $(this);
                var idRace = raceElement.data('id-race');

                domElements.races[idRace] = raceElement;
            });

            // Observers
            if (!eventData.event.isSpecialBets) {
                // Observe race rows clicks
                content.on('click', 'li.race', function(e) {
                    if (e.target.tagName.toLowerCase() != 'a') {
                        $(this).find('a.racecard-link').click();
                    }
                });

                // observe archive video click
                content.on('click', 'li.race span.archive-video', function(e) {
                    //e.stopPropagation(); // avoid link to the racecard

                    if (!raceBetsJS.application.header.login.check()) {
                        return;
                    }

                    var elem = $(this);
                    raceBetsJS.application.assets.media.show({
                        idRace: elem.data('id-race'),
                        provider: eventData.event.media.provider,
                        idChannel: eventData.event.media.idChannel,
                        streamType: elem.data('stream-type'),
                        url: elem.data('archive-link'),
                        country: eventData.event.country
                    });
                });
            } else {
                // if it is special bets event, observe odds buttons
                content.on('mouseup', '.odds-button.fixed.enabled', function() {
                    raceBetsJS.application.assets.bettingDialog.show(this);
                });
            }

            // populate dom content
            raceBetsJS.application.globals.contentMain.html(content);

            // Start polling request
            pollingRequestInterval.start();
        }

        function showSilks(race) {
            var showSilks = true;
            $.each(race.runners, function() {
                if (!this.silkExtension) {
                    showSilks = false;
                }
            });

            return showSilks;
        }

        function hasFinalPositions(race) {
            var hasPos = true;
            $.each(race.runners, function(i, runner) {
                if (!runner.finalPosition) {
                    hasPos = false;
                    return;
                }
            });

            return hasPos;
        }

        // remove banners outside their publishing time range
        // or if user and banner have no bonus flag
        function bannerExpiration(data) {
            if (Object.keys(data.banner).length > 0) {
                var now = Math.floor(Date.now() / 1000);
                $.each(data.banner, function(index, values) {

                    if ((values.startDate && now < values.startDate)
                        || (values.endDate && now > values.endDate)) {
                        delete data.banner[index];
                    }
                    if (raceBetsJS.application.user.noBonus && values.isBonusPromotion) {
                        delete data.banner[index];
                    }
                });
            }

            return data;
        }

        // @public
        return {
            init: function() {
                // Setup route controller
                raceBetsJS.application.contentController.addDynamicPage({
                    urlPattern: /event\/details\/id\/(\d+)\/?/,
                    dataSourceURLBuilder: function(idEvent) {
                        return raceBetsJS.application.content.getAjaxCacheUrl('/ajax/events/details/id/' + idEvent);
                    },
                    onDataReceived: onDataReceived,
                    onUnload: function() {
                        // Stop polling request
                        pollingRequestInterval.stop();
                        if(raceBetsJS.application.globals.webSockets){
                            leaveSocketChannel();
                        }


                        // Abort a running pollingRequest
                        if (pendingRequest) {
                            pendingRequest.abort();
                            pendingRequest = null;
                        }

                        // Empty elements
                        eventData = domElements = null;
                    }
                });
            },
            showSilks: showSilks,
            hasFinalPositions: hasFinalPositions,
            bannerExpiration: bannerExpiration
        };
    }();
})(raceBetsJS);
