/**
* Homepage race of the day module
*
* @author David Calleja
*/
(function(raceBetsJS) {
	raceBetsJS.application.content.home.raceOfDay = (function() {
		// @private
		var panel_template = raceBetsJS.application.templates.panel,
			content_template = raceBetsJS.application.templates.home.raceOfDay,
			panel_data = {
				'title': '',
				'cssClass':'c-widget--raceOfDay light with-rows',
				'id':'race-of-day'
			},
			content_area_selector = '.content_container',
			el = null,
			data = {};

		var render = function(area){
			var content = $('<div>').append(content_template(data));

			if(el == null){
				area.append(panel_template($.extend(panel_data, {'content': $('<div>').append( content.eq(0).clone() ).html() })));
				el = area.find('#' + panel_data.id).find(content_area_selector);
			} else {
				el.empty();
				el.append(content);
			}
		};


		var remove = function(){
			el = null;
		};

		var setData = function(json){
			data = json;
		};

		// @public
		return {
			init: function(json) {
				if(json != null && !_.isEmpty(json) && json.content != false){
					setData(json);

					//set translations
					panel_data.title = raceBetsJS.i18n.data.headRaceOfTheDay;

                    //Initialize 'more' link
                    panel_data.more = {
                        text: raceBetsJS.i18n.data.linkMore,
                        link: raceBetsJS.application.globals.urlPrefix + '/content/show/module/raceoftheday/id/' + data.idRace
                    };

					return this;
				} else if(el != null){
					return this;
				}
				return false;
			},
			render: render,
			remove: remove
		};
	})();
})(raceBetsJS);
