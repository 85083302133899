/**
* @description Settings
*
*/
(function(raceBetsJS) {

    raceBetsJS.application.templates.settings = {};

    /**
	* Settings template
    */
	raceBetsJS.application.templates.settings.content = _.template(
	    '<div class="c-tab c-tab--settings">' +
            '<div class="c-tab__body">' +
                '<div id="settings-tab-format" class="c-tab__content isSelected">' +
                    '<%= raceBetsJS.application.templates.settings.tabSettingsFormat(data) %>' +
                '</div>' +
                '<div id="settings-tab-general" class="c-tab__content">' +
                    '<%= raceBetsJS.application.templates.settings.tabSettingsBasic(data) %>' +
                '</div>' +
                '<% if (raceBetsJS.application.globals.gdpr) { %>' +
                    '<div id="settings-tab-cookie" class="c-tab__content">' +
                        '<%= raceBetsJS.application.templates.settings.tabSettingsCookie(data) %>' +
                    '</div>' +
                '<% } %>' +
                '<div id="settings-tab-betslip" class="c-tab__content">' +
                    '<%= raceBetsJS.application.templates.settings.tabSettingsBetslip(data) %>' +
                '</div>' +
                '<div id="settings-tab-countries" class="c-tab__content">' +
                    '<%= raceBetsJS.application.templates.settings.tabSettingsCountry(data) %>' +
                '</div>' +
            '</div>' +
        '</div>'
    );

    /**
     * Settings header tabs
     */
    raceBetsJS.application.templates.settings.tabSettings = _.template(
        '<ul id="settings-tabs" class="c-btnGroup c-btnGroup--menu">' +
            '<li class="c-btnGroup__item" data-content-id="settings-tab-format">' +
                '<a href="#settings-tab-format" class="c-btn c-btn--menu isSelected">' +
                    '<span class="c-btn__label"><%= raceBetsJS.i18n.data.tabSettingsBasic %></span>' +
                '</a>' +
            '</li>' +
            '<li class="c-btnGroup__item" data-content-id="settings-tab-general">' +
                '<a href="#settings-tab-general" class="c-btn c-btn--menu">' +
                    '<span class="c-btn__label"><%= raceBetsJS.i18n.data.tabSettingsMore %></span>' +
                '</a>' +
            '</li>' +
            '<% if (raceBetsJS.application.globals.gdpr) { %>' +
                '<li class="c-btnGroup__item" data-content-id="settings-tab-cookie">' +
                    '<a href="#settings-tab-cookie" class="c-btn c-btn--menu">' +
                        '<span class="c-btn__label"><%= raceBetsJS.i18n.data.tabSettingsCookie %></span>' +
                    '</a>' +
                '</li>' +
            '<% } %>' +
            '<li class="c-btnGroup__item" data-content-id="settings-tab-betslip">' +
                '<a href="#settings-tab-betslip" class="c-btn c-btn--menu">' +
                    '<span class="c-btn__label"><%= raceBetsJS.i18n.data.tabSettingsBetslip %></span>' +
                '</a>' +
            '</li>' +
            '<li class="c-btnGroup__item" data-content-id="settings-tab-countries">' +
                '<a href="#settings-tab-countries" class="c-btn c-btn--menu">' +
                    '<span class="c-btn__label"><%= raceBetsJS.i18n.data.tabSettingsCountries %></span>' +
                '</a>' +
            '</li>' +
        '</ul>'
    );

    /**
    * Country Order
    */
    raceBetsJS.application.templates.settings.tabSettingsCountry = _.template(
        '<div class="c-form__set">' +
            '<p class="c-form__legend"><%= raceBetsJS.i18n.data.textSettingsCountry %></p>' +
            '<div class="country-list">' +
                '<label class="c-select">' +
                    '<select class="c-select__input" id="setting-countries-select">' +
                        '<option value="common" selected><%= raceBetsJS.i18n.data.optionCommonCountries %></option>' +
                        '<option value="all"><%= raceBetsJS.i18n.data.optionAllCountries %></option>' +
                    '</select>' +
                    '<span class="c-select__value"><%= raceBetsJS.i18n.data.optionCommonCountries %></span>' +
                    '<span class="c-select__btn"><span class="c-iconFont c-iconFont--caret-down"></span></span>' +
                '</label>' +
                '<ul id="settings-countries-source" class="settings-country-sort common"></ul>' +
            '</div>' +
            '<div class="country-list">' +
                '<ul id="settings-countries-sorted" class="settings-country-sort"></ul>' +
            '</div>' +
        '</div>'
    );

    /**
    * Format Settings
    */
    raceBetsJS.application.templates.settings.tabSettingsFormat = _.template(
        '<div class="c-form__set">' +
            '<% if (!raceBetsJS.application.globals.isB2B) { %>' +
                '<div class="c-form__group <% if (Object.keys(raceBetsJS.application.globals.languages).length === 1) { %>hidden<% } %>">' +
                    '<div class="c-form__control">' +
                        '<label class="c-form__label"><%= raceBetsJS.i18n.data.labelPreferredLanguage %></label>' +
                    '</div>' +
                    '<div class="c-form__input">' +
                        '<div class="rb-select language">' +
                            '<select></select>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '<% } %>' +
            '<div class="c-form__group">' +
                '<div class="c-form__control">' +
                    '<label class="c-form__label"><%= raceBetsJS.i18n.data.labelOddsFormat %></label>' +
                    '<p class="c-form__description"><%= raceBetsJS.i18n.data.textOddsFormat %></p>' +
                '</div>' +
                '<div class="c-form__input">' +
                    '<label for="settings-odds-format-base1">' +
                        '<span class="c-input c-input--radio pullBlock">' +
                            '<input type="radio" id="settings-odds-format-base1" class="c-input__radio" value="base1" name="settings[odds-format]">' +
                            '<span class="c-input__label">2.50</span>' +
                        '</span>' +
                    '</label>' +
                    '<% if (raceBetsJS.application.user.lang === "de") { %>' +
                        '<label for="settings-odds-format-base10">' +
                            '<span class="c-input c-input--radio pullBlock">' +
                                '<input type="radio" id="settings-odds-format-base10" class="c-input__radio" value="base10" name="settings[odds-format]">' +
                                '<span class="c-input__label">25</span>' +
                            '</span>' +
                        '</label>' +
                    '<% } %>' +
                    '<label for="settings-odds-format-fractional">' +
                        '<span class="c-input c-input--radio pullBlock">' +
                            '<input type="radio" id="settings-odds-format-fractional" class="c-input__radio" value="fractional" name="settings[odds-format]">' +
                            '<span class="c-input__label">6/4</span>' +
                        '</span>' +
                    '</label>' +
                '</div>' +
            '</div>' +
            '<div class="c-form__group">' +
                '<div class="c-form__control">' +
                    '<label class="c-form__label"><%= raceBetsJS.i18n.data.labelUnitSystem %></label>' +
                    '<p class="c-form__description"><%= raceBetsJS.i18n.data.textUnitSystem %></p>' +
                '</div>' +
                '<div class="c-form__input">' +
                    '<label for="settings-units-metric">' +
                        '<span class="c-input c-input--radio pullBlock">' +
                            '<input type="radio" id="settings-units-metric" class="c-input__radio" value="metric" name="settings[unit-system]">' +
                            '<span class="c-input__label"><%= raceBetsJS.i18n.data.labelMetric %></span>' +
                        '</span>' +
                    '</label>' +
                    '<label for="settings-units-imperial">' +
                        '<span class="c-input c-input--radio pullBlock">' +
                            '<input type="radio" id="settings-units-imperial" class="c-input__radio" value="imperial" name="settings[unit-system]">' +
                            '<span class="c-input__label"><%= raceBetsJS.i18n.data.labelImperial %></span>' +
                        '</span>' +
                    '</label>' +
                '</div>' +
            '</div>' +
        '</div>'
    );

    /**
    * Basic Settings
    */
    raceBetsJS.application.templates.settings.tabSettingsBasic = _.template(
        '<div class="c-form__set">' +
            '<div class="c-form__group">' +
                '<div class="c-form__control">' +
                    '<label class="c-form__label"><%= raceBetsJS.i18n.data.labelHideDogs %></label>' +
                    '<p class="c-form__description"><%= raceBetsJS.i18n.data.textHideDogs %></p>' +
                '</div>' +
                '<div class="c-form__input">' +
                    '<label for="settings-dogs-true">' +
                        '<span class="c-input c-input--radio pullBlock">' +
                            '<input type="radio" id="settings-dogs-true" class="c-input__radio" value="true" name="settings[dogs]">' +
                            '<span class="c-input__label"><%= raceBetsJS.i18n.data.buttonYes %></span>' +
                        '</span>' +
                    '</label>' +
                    '<label for="settings-dogs-false">' +
                        '<span class="c-input c-input--radio pullBlock">' +
                            '<input type="radio" id="settings-dogs-false" class="c-input__radio" value="false" name="settings[dogs]">' +
                            '<span class="c-input__label"><%= raceBetsJS.i18n.data.buttonNo %></span>' +
                        '</span>' +
                    '</label>' +
                '</div>' +
            '</div>' +
            '<div class="c-form__group">' +
                '<div class="c-form__control">' +
                    '<label class="c-form__label"><%= raceBetsJS.i18n.data.labelPopupMode %></label>' +
                    '<p class="c-form__description"><%= raceBetsJS.i18n.data.textPopupMode %></p>' +
                '</div>' +
                '<div class="c-form__input">' +
                    '<label for="settings-streams-popup-true">' +
                        '<span class="c-input c-input--radio pullBlock">' +
                            '<input type="radio" id="settings-streams-popup-true" class="c-input__radio" value="true" name="settings[popup-streams]">' +
                            '<span class="c-input__label"><%= raceBetsJS.i18n.data.buttonYes %></span>' +
                        '</span>' +
                    '</label>' +
                    '<label for="settings-streams-popup-false">' +
                        '<span class="c-input c-input--radio pullBlock">' +
                            '<input type="radio" id="settings-streams-popup-false" class="c-input__radio" value="false" name="settings[popup-streams]">' +
                            '<span class="c-input__label"><%= raceBetsJS.i18n.data.buttonNo %></span>' +
                        '</span>' +
                    '</label>' +
                '</div>' +
            '</div>' +
            '<div class="c-form__group">' +
                '<div class="c-form__control">' +
                    '<label class="c-form__label"><%= raceBetsJS.i18n.data.labelCloseCountries %></label>' +
                    '<p class="c-form__description"><%= raceBetsJS.i18n.data.textCloseCountries %></p>' +
                '</div>' +
                '<div class="c-form__input">' +
                    '<label for="settings-close-countries-true">' +
                        '<span class="c-input c-input--radio pullBlock">' +
                            '<input type="radio" id="settings-close-countries-true" class="c-input__radio" value="true" name="settings[close-countries]">' +
                            '<span class="c-input__label"><%= raceBetsJS.i18n.data.buttonYes %></span>' +
                        '</span>' +
                    '</label>' +
                    '<label for="settings-close-countries-false">' +
                        '<span class="c-input c-input--radio pullBlock">' +
                            '<input type="radio" id="settings-close-countries-false" class="c-input__radio" value="false" name="settings[close-countries]">' +
                            '<span class="c-input__label"><%= raceBetsJS.i18n.data.buttonNo %></span>' +
                        '</span>' +
                    '</label>' +
                '</div>' +
            '</div>' +
            '<% if (!raceBetsJS.application.globals.isB2B) { %>' +
                '<div class="c-form__group timezone">'+
                    '<div class="c-form__control">'+
                        '<div class="c-form__label" for="settings-timezone">'+
                            '<label class="c-form__label"><%= raceBetsJS.i18n.data.labelTimezone %></label>'+
                        '</div>'+
                    '</div>'+
                    '<div class="c-form__input">'+
                        '<select id="settings-timezone">'+
                            '<% $.each(raceBetsJS.time.timezone.getTimezoneList(), function(olsonId, label) { %>'+
                                '<option value="<%= this[0] %>"><%= this[1] %></option>'+
                            '<% }); %>'+
                        '</select>'+
                    '</div>'+
                '</div>'+
            '<% } %>'+
        '</div>'
    );

    /**
     * Cookie Settings
     */
    raceBetsJS.application.templates.settings.tabSettingsCookie = _.template(
        '<% if (!raceBetsJS.application.user.loggedIn) { %>' +
            '<div class="c-form__set">' +
                '<div class="c-form__group">' +
                    '<div class="c-form__control">' +
                        '<label class="c-form__label"><%= raceBetsJS.i18n.data.labelNecessaryCookies %></label>' +
                        '<p class="c-form__description"><%= raceBetsJS.i18n.print("textNecessaryCookies", {link: "/static/cookiepolicy"}) %></p>' +
                    '</div>' +
                    '<div class="c-form__input">' +
                        '<label for="settings-necessary-cookies-on">' +
                            '<span class="c-input c-input--radio pullBlock">' +
                                '<input type="radio" id="settings-necessary-cookies-on" class="c-input__radio" value="true" name="settings[necessaryCookies]" disabled="disabled" checked="checked">' +
                                '<span class="c-input__label"><%= raceBetsJS.i18n.data.buttonYes %></span>' +
                            '</span>' +
                        '</label>' +
                        '<label for="settings-necessary-cookies-off">' +
                            '<span class="c-input c-input--radio pullBlock">' +
                                '<input type="radio" id="settings-necessary-cookies-off" class="c-input__radio" value="false" name="settings[necessaryCookies]" disabled="disabled">' +
                                '<span class="c-input__label"><%= raceBetsJS.i18n.data.buttonNo %></span>' +
                            '</span>' +
                        '</label>' +
                    '</div>' +
                '</div>' +
                '<div class="c-form__group">' +
                    '<div class="c-form__control">' +
                        '<label class="c-form__label"><%= raceBetsJS.i18n.data.labelAdvertisingCookies %></label>' +
                        '<p class="c-form__description"><%= raceBetsJS.i18n.data.textAdvertisingCookies %></p>' +
                    '</div>' +
                    '<div class="c-form__input">' +
                        '<label for="settings-advertising-cookies-on">' +
                            '<span class="c-input c-input--radio pullBlock">' +
                                '<input type="radio" id="settings-advertising-cookies-on" class="c-input__radio" value="true" name="settings[advertisingCookies]">' +
                                '<span class="c-input__label"><%= raceBetsJS.i18n.data.buttonYes %></span>' +
                            '</span>' +
                        '</label>' +
                        '<label for="settings-advertising-cookies-off">' +
                            '<span class="c-input c-input--radio pullBlock">' +
                                '<input type="radio" id="settings-advertising-cookies-off" class="c-input__radio" value="false" name="settings[advertisingCookies]">' +
                                '<span class="c-input__label"><%= raceBetsJS.i18n.data.buttonNo %></span>' +
                            '</span>' +
                        '</label>' +
                    '</div>' +
                '</div>' +
            '</div>' +
        '<% } %>'
    );

    /**
    * Betslip Settings
    */
    raceBetsJS.application.templates.settings.tabSettingsBetslip = _.template(
        '<div class="c-form__set">' +
            '<div class="c-form__group">' +
                '<div class="c-form__control">' +
                    '<label class="c-form__label"><%= raceBetsJS.i18n.data.labelBetslipConfirmation %></label>' +
                    '<p class="c-form__description"><%= raceBetsJS.i18n.data.textBetslipConfirmation %></p>' +
                '</div>' +
                '<div class="c-form__input">' +
                    '<label for="settings-betslip-confirmation-true">' +
                        '<span class="c-input c-input--radio pullBlock">' +
                            '<input type="radio" id="settings-betslip-confirmation-true" class="c-input__radio" value="true" name="settings[betslip-confirmation]">' +
                            '<span class="c-input__label"><%= raceBetsJS.i18n.data.buttonYes %></span>' +
                        '</span>' +
                    '</label>' +
                    '<label for="settings-betslip-confirmation-false">' +
                        '<span class="c-input c-input--radio pullBlock">' +
                            '<input type="radio" id="settings-betslip-confirmation-false" class="c-input__radio" value="false" name="settings[betslip-confirmation]">' +
                            '<span class="c-input__label"><%= raceBetsJS.i18n.data.buttonNo %></span>' +
                        '</span>' +
                    '</label>' +
                '</div>' +
            '</div>' +
            '<% var currencies = ["EUR", "USD"]; %>' +
            '<% if ($.inArray(raceBetsJS.application.user.currency, currencies) === -1) { currencies.splice(0, 0, raceBetsJS.application.user.currency); } %>' +
            '<% $.each(betslip.stakes, function(currency, stakes) { %>' +
                '<% if ($.inArray(currency, currencies) === -1) { return; } %>' +
                '<div class="c-form__group default-stakes">' +
                    '<div class="c-form__control">' +
                        '<label class="c-form__label"><%= raceBetsJS.i18n.print("labelDefaultStakes", { currency: currency }) %></label>' +
                    '</div>' +
                    '<div class="c-form__input">' +
                        '<ul data-currency="<%= currency %>">' +
                            '<% $.each(this, function(slot, stake) { %>' +
                            '<li class="stake <%= (slot < 6) ? "row1" : "row2" %><%= (($.inArray(slot, ["5","11"]) > -1) ? " last " : "") %>" data-slot="<%= slot %>" data-stake="<%= stake %>"><%= raceBetsJS.format.money(stake, 2) %></li>' +
                            '<% }); %>' +
                        '</ul>' +
                    '</div>' +
                '</div>' +
            '<% }); %>' +
        '</div>'
    );
})(raceBetsJS);
