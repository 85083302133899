/**
 * Cross frame messages using postMessage API
 *
 * @author Andras Matzon
 */
(function (raceBetsJS) {
    raceBetsJS.browser.crossFrame = (function () {
        var originFilters = [];
        var millionIframe = null;


        function init(originFilter) {
            originFilters.push(originFilter);

            window.addEventListener('message', function(event) {
                // Always verify sender's identity
                var eventOrigin = event.origin || event.originalEvent.origin;
                var i = 0;
                var originMatch = -1;

                while(originFilters[i]) {
                    originMatch = Math.max(eventOrigin.indexOf(originFilters[i]), originMatch)
                    i++;
                }

                if (raceBetsJS.application.globals.isTest) {
                    console.groupCollapsed('postMessage RECEIVED @ ' + window.location.host);
                    console.log('%c origin', 'color: #9E9E9E; font-weight: bold', eventOrigin);
                    console.log('%c data', 'color: #03A9F4; font-weight: bold', event.data);
                    console.groupEnd();
                }

                if (!raceBetsJS.application.globals.isTest && originMatch < 0) {
                    return;
                }

                if (raceBetsJS.application.globals.isTest) {
                    var timestamp = new Date();
                    var timestampFormatted = timestamp.getHours()+':'+timestamp.getMinutes()+':'+timestamp.getSeconds()+'.'+timestamp.getMilliseconds();
                    window.console.groupCollapsed('postmessage received @ '+timestampFormatted);
                    window.console.log(
                        '%c origin',
                        'font-weight:bold;color:#9E9E9E',
                        event.origin
                    );
                    window.console.log(
                        '%c messageId',
                        'font-weight:bold;color:#9E9E9E',
                        event.data.messageId
                    );
                    window.console.log(
                        '%c payload',
                        'font-weight:bold;color:#9E9E9E',
                        event.data.payload
                    );
                    window.console.groupEnd();
                }

                pub(event);
            }, false);
        }

        function initIframe(originFilter, iframeId ) {
            originFilters.push(originFilter);
            millionIframe = document.getElementById(iframeId);
        }

        function removeIframe(originFilter) {
            var idx = originFilters.indexOf(originFilter);
            originFilters.splice(idx, 1);
            millionIframe = null;
        }

        function pub(event) {
            switch (event.data.messageId) {
                case 'scroll':
                    if (raceBetsJS.application.globals.isB2B) {
                        raceBetsJS.application.globals.iframe.scroll = parseInt(event.data.payload, 10);
                        $.publish('/scroll', [raceBetsJS.application.globals.iframe.scroll]);
                    }
                    break;
                case 'sizes':
                    if (raceBetsJS.application.globals.isB2B) {
                        raceBetsJS.application.globals.iframe.top = parseInt(event.data.payload.top, 10);
                        raceBetsJS.application.globals.iframe.height = parseInt(event.data.payload.height, 10);
                        $.publish('/sizes', [raceBetsJS.application.globals.iframe]);
                    }
                    break;
                case 'navigationHash':
                    if (raceBetsJS.application.globals.isB2B) {
                        if(window.location.pathname !== event.data.payload) {
                            raceBetsJS.browser.history.navigateTo(event.data.payload);
                        }
                    }
                    break;
                case 'height':
                    millionIframe.style.height = event.data.payload + 'px';
                    break;
                case 'requestLogin':
                    raceBetsJS.application.assets.dialogs.loginDialog.show();
                    break;
                case 'requestRegistration':
                    raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/register');
                    break;
                case 'requestSportsbook':
                    raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix);
                    break;
                case 'paymentIframeClose':
                    raceBetsJS.application.assets.overlay.close();
                    var url = raceBetsJS.application.globals.urlPrefix + 'cashier/deposit/result/' + event.data.provider;
                    if (event.data.paymentId) {
                        url += '/' + event.data.paymentId;
                    }
                    url += '/' + event.data.status;
                    if (event.data.ndc) {
                        url += '?ndc=1';
                    }
                    raceBetsJS.browser.history.navigateTo(url);
                    break;
                case 'navigation':
                    window.scrollTo(0, 0);
                    break;
                case 'goToRaceCard':
                    window.scrollTo(0, 0);
                    raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + 'race/details/id/' + event.data.payload);
                    break;
            }
        }

        function send(messageId, payload, iframeName) {
            var messageObject = {},
                targetOrigin = '*';

            if (!messageId) {
                throw new Error('Message ID has to be defined');
            } else {
                messageObject.messageId = messageId;
            }

            if(!_.isUndefined(payload)) {
                messageObject.payload = payload;
            }

            if(iframeName === 'million') {
                if (millionIframe) millionIframe.contentWindow.postMessage(messageObject, targetOrigin);
            } else if (window.self !== window.top) {
                window.parent.postMessage(messageObject, targetOrigin);
            }

        }

        return {
            init: init,
            initIframe: initIframe,
            removeIframe: removeIframe,
            pub: pub,
            send: send
        };
    })();
})(raceBetsJS);
