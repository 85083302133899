/**
* Modal dialog module
*
* @author Robin Ebers
*/



(function(raceBetsJS) {
    raceBetsJS.application.assets.modalDialog = function() {
        // default options
        var defaultOptions = {
            type: '',
            icon: false,
            width: 412,
            title: '',
            content: '',
            className: '',
            hasSupport: false,
            hasLinks: false,
            closeButton: true,
            closeCallback: false,
            backgroundClose: false,
            buttonCaption: undefined,
            buttonAlign: 'default',
            buttons: 'default'
        };

        var show = function(options) {
            var dom = {};
            options = $.extend({}, defaultOptions, options || {}),

            /**
            * CREATE DOM
            */
            dom.container = $('<div />');
            dom.dialog = $('<div class="dialog" />').addClass(options.className);
            dom.closeButton = $('<div class="close" />').append($('<div class="inner" />').append($('<div class="cross" />')));
            dom.content = $('<div class="content" />');
            dom.buttons = $('<div class="buttons" />').append('<table><tr></tr></table>');
            dom.buttonRow = dom.buttons.find('tr');
            dom.title = $('<h3  class="title" />');
            dom.footer = $('<div class="footer" />');

            if (options.hasLinks) {
                dom.buttons.addClass('has-links');
            }

            if (options.buttonAlign !== 'default') {
                dom.buttons.addClass(options.buttonAlign);
            }

            if (options.title !== false) {
                dom.container.append(dom.title);
            }
            dom.container.append(dom.dialog);

            // add close button to dialog
            if (options.closeButton === true) {
                dom.closeButton.on('click', function() {
                    raceBetsJS.application.assets.overlay.close();
                    if (options.closeCallback) {
                        options.closeCallback();
                    }
                });
                dom.dialog.append(dom.closeButton);
            }


            /**
            * WHAT KIND OF DIALOG IS IT?
            * We usually use error, success, confirmation and attention
            */
            if (options.title === '') {
				switch (options.type) {
                    case 'error':
                    	options.title = raceBetsJS.i18n.data.msgBoxTitleError;
                        break;
                    case 'success':
                    	options.title = raceBetsJS.i18n.data.msgBoxTitleSuccess;
                        break;
                    case 'confirmation':
                    	options.title = raceBetsJS.i18n.data.msgBoxTitleConfirmation;
                        break;
                    // case 'attention':
                    default:
                        options.title = raceBetsJS.i18n.data.msgBoxTitleAttention;
                        break;
                }
            }

            dom.title.html(options.title);
            dom.content.html(options.content);

            // add footer if set
            if (options.footer !== undefined && options.footer !== false) {
                dom.footer.html(options.footer);
            }

            // Add icon if set
            if (options.icon !== false) {
                dom.content.append($('<span />').addClass('icon ' + options.icon));
            }


            /**
            * WHAT BUTTONS ARE AVAILABLE
            * There are only three options, either a simple OK box, customised buttons or none at all
            */
            if (options.buttons === 'default') {
                var buttonOK = $('<button class="btn btn-primary" />').text(options.buttonCaption || raceBetsJS.i18n.data.buttonOK);
                buttonOK.click(function(e) {
                    e.stopPropagation();

                    if (options.buttonAction){
                        options.buttonAction(e);
                    } else {
                        raceBetsJS.application.assets.overlay.close();
                        if (options.closeCallback) {
                            options.closeCallback();
                        }
                    }
                });
                dom.buttonRow.append($('<td />').append(buttonOK));

            } else if (options.buttons !== false) {
                options.buttons = _.sortBy(options.buttons, function(btnOption) {
                    return btnOption.active === true;
                });
                $.each(options.buttons, function (i, button) {
                    var newButton = $('<button />').text(button.label);
                    var defaultClass = button.active === true ? (options.type === 'success' ? 'btn-action' : 'btn-primary') : '';

                    newButton
                        .addClass( 'btn ' + (button.btnClass ? 'btn-' + button.btnClass : defaultClass))
                        .attr('id', (button.id ? button.id : ''))
                        .click(button.action);

                    dom.buttonRow.append($('<td />').append(newButton));
                });
            }

            dom.dialog.append(dom.content);

            if (options.buttons !== false) {
                dom.dialog.append(dom.buttons);
            }

            // fix buttons
            var cells = dom.buttons.find('td');
            cells.css({ width: Math.floor(100 / cells.length) + '%' });
            if (cells.length === 1) {
                cells.addClass('default');
            }

            /**
            * SUPPORT EXTENSION
            * In some case we are going to show our support information.
            */
            if (options.hasSupport !== false) {
                dom.dialog.append(raceBetsJS.application.templates.dialogs.support());
            }

            /**
             * FOOTER EXTENSION
             * In some cases we are going to add a footer for more information
             */
            if (options.footer) {
                dom.dialog.append(dom.footer);
            }

            /**
            * FINALISE DIALOG
            * Finally, create and show the dialog to the user
            */
            raceBetsJS.application.assets.overlay.create($.extend({}, options, {
                content: dom.container,
                className: options.type,
                closeButton: false
            } || {}));

            /**
            * DEFAULT BUTTON
            */
            if (_.isObject(options.buttons)) {
                $.each(options.buttons, function(i, elem) {
                    if (elem.active) {
                        dom.buttons.find('button').get(i).focus();
                    }
                });
            } else {
                dom.buttons.find('button').first().focus();
            }

            // SHAKE IT BABY :)
            if (options.type === 'error') {
                $('.overlay').find('.modal-dialog').last().shake();
            }
        };

        var generic = function(error) {
            show({
                width: 400,
                title: raceBetsJS.i18n.data.anErrorOccurred,
                content: raceBetsJS.i18n.data.ajaxErrorReload + ((error !== undefined) ? ('<br /><br />' + error) : ''),
                buttons: [
                    {
                        label: raceBetsJS.i18n.data.buttonReload,
                        action: function() {
                            raceBetsJS.application.assets.overlay.close();
                            raceBetsJS.application.assets.overlay.showPleaseWait();
                            location.reload();
                        }
                    },
                    {
                        label: raceBetsJS.i18n.data.buttonOK,
                        action: function() {
                            raceBetsJS.application.assets.overlay.close();
                        }
                    }
                ]
            });
        };

        return {
            show: show,
            generic: generic
        };
    }();
})(raceBetsJS);
