/**
* Race
*
* @description: Race details module
*
* @author: Moritz Honig
* @author: Robin Ebers
*/
(function(raceBetsJS) {
    raceBetsJS.application.content.race = function() {
        var card = {};
        var pendingRequest = null;
        var subscriptions = [];

        // jackpot related variables
        var jackpotCurrent, jackpotNew, jackpotCounter, jackpotInterval;

        // debug mode
        var debugMode = false;

        //  Container to store the subscription so we can remove it onunload
        var webSocketSubscription = null;

        // node.js response callback
        var socketCB = function(data){
            updateCard(data);
        }

        var leaveSocketChannel = function(){
            if(raceBetsJS.application.globals.webSockets && webSocketSubscription)  {
                raceBetsJS.webSockets.nodeJS.leave(webSocketSubscription);
                webSocketSubscription = null;
            }
        }

        var raceDetailsInterval;
        var startAjaxUpdates = function(){

            /**
            * raceDetailsInterval
            *
            * Interval for polling new race details
            */
            raceDetailsInterval = new raceBetsJS.time.Interval({
                interval: raceBetsJS.application.globals.ajax.poolDelay,
                tick: function() {
                    pendingRequest = $.ajax({
                        url: raceBetsJS.application.content.getAjaxCacheUrl('/ajax/races/details/id/' + card.data.race.idRace + '/version/short/'),
                        success: updateCard,
                        complete: function() {
                            // Clear pending request
                            pendingRequest = null;
                        }
                    });
                }
            });

            raceDetailsInterval.start();
            // subscribe to update requests
            subscriptions.push($.subscribe('/race/' + card.data.race.idRace + '/update', function() {
                raceDetailsInterval.tick();
            }));
        };

        /**
        * initCard
        *
        * Init the race card
        */
        var initCard = function(data) {
            // Restores Tipped tooltips to initial state
            Tipped.init();

            if (data.type == 'error') {
                if (data.errorMsg == 'RACE_NOT_FOUND') {
                    raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix +  '/static/error404/')
                } else {
                    raceBetsJS.application.assets.modalDialog.generic(data.errorMsg);
                }

                return;
            }

            // set some required variables
            var eventDate = raceBetsJS.application.assets.timezone.date('Y-m-d', data.event.firstStart, 'Europe/Berlin');

            // determine if it's rule 4
            data.race.isRule4 = (data.event.rule4 && (data.race.fixedOddsStatus === 'ON' || data.race.fixedOddsStatus === 'SPD'));

            // delete media for breeders cup if ipCountry is IE/GB
            if (eventDate.match(/2013-11-0[1|2]/) && data.event.idTrack == 393 && $.inArray(raceBetsJS.application.user.ipCountry, ['GB', 'IE']) > -1) {
                delete data.race.media;
            }

            if (data.race.purseDetails) {
                $.each(data.race.purseDetails, function(index, elem) {
                        data.race.purseDetails[index] = raceBetsJS.format.number(elem);
                });
                data.race.purseDetails = data.race.purseDetails.join(' - ');
            }


            // set debug mode
            if (raceBetsJS.browser.params.get('debugMode') !== false) {
                debugMode = raceBetsJS.browser.params.get('debugMode');
            }

            // Debug Mode: "results"
            // Simmulate a race shortly before results come in
            // rob: doesn't work any more since Javier Cobos changed something (NodeJS I assume) - 18/03/2014
            if (debugMode === 'results') {
                data.race.raceStatus = 'OPN'; // immitate open race
                data.result = {}; // remove results from initial request
                data.race.rule4Deductions = {}; // remove deductions
                raceDetailsInterval.setInterval(3000); // set update to 3 seconds
            }
            // -- debug mode: "results" --

            var runnerAux = [];
            var i,l;

            if (data.type == 'error') {
                if (data.errorMsg == 'RACE_NOT_FOUND') {
                    raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix +  '/static/error404/')
                } else {
                    raceBetsJS.application.assets.modalDialog.generic(data.errorMsg);
                }

                return;
            }

            if (data.race.promotions) {
                data.race.promotions = _.filter(data.race.promotions, function(idx,key){
                    return _.contains(data.race.promotions[key].countries, raceBetsJS.application.user.country);
                });

                data.race.promotions = _.map(data.race.promotions, function(idx,key){
                    if (data.race.promotions[key].promotionType !== 'custom') {
                        data.race.promotions[key].label = raceBetsJS.string.hyphenToCamelCase('labelPromotion-'+data.race.promotions[key].promotionType);
                        data.race.promotions[key].index = key;
                    }
                    data.race.promotions[key].fullTerms = raceBetsJS.string.hyphenToCamelCase('termsPromotion-'+data.race.promotions[key].promotionType);
                    return data.race.promotions[key];
                });
            }

            // Let's create the order array
            data.runners.order = [];

            // lets put all the runners in the same array
            for ( key in data.runners.data){
                if(data.runners.data.hasOwnProperty(key)){
                    runnerAux.push(data.runners.data[key]);
                }
            }

            // ordering the array by programnumber
            runnerAux.sort(function(a, b){
                var a1= a.programNumber, b1= b.programNumber;
                if(a1== b1) return 0;
                return a1 > b1? 1: -1;
            });

            // put all the data in order array
            l =  runnerAux.length;
            for(var i = 0; i< l;++i){
                data.runners.order.push(runnerAux[i].idRunner);
            }
            // remove scratches from stables
            //data.race.stables

            card = { data: data };

            raceBetsJS.application.header.navigation.activateItemByKey('racing');

            // remove media data if country is not allowed
            if (data.race.media !== undefined &&
                !raceBetsJS.media.isCountryAllowed(raceBetsJS.application.user.mediaCountry, data.race.media.filterCountries, data.race.media.filterType)) {
                delete data.race.media;
            }

            // remove media data if idChannel is not supported on current device
            if (data.race.media !== undefined && raceBetsJS.application.assets.media.isUnsupportedChannel(data.race.media.idChannel)) {
                delete data.race.media;
            }

            // remove banners outside their publishing time range
            data = raceBetsJS.application.content.event.bannerExpiration(data);

            // Render template
            var content = $(raceBetsJS.application.templates.race(data));

            // insert head2head icons
            if (data.race.head2head) {
                $.each(data.race.head2head, function(index, h2h) {
                    var button = $('<a />')
                        .addClass('ajaxify c-tag isH2H h2h-' + (index + 1))
                        .attr('href', raceBetsJS.application.globals.urlPrefix + '/event/details/id/' + h2h.idEvent)
                        .text('H2H');

                    $.each(h2h.runners, function(index2, idRunner) {
                        content.find('#runner-' + idRunner + ' td.horse span.horse span.icons').append(button.clone());
                    });
                });
            }

            // populate div#content-main
            raceBetsJS.application.globals.contentMain.html(content);

            // scroll to bottom on arrow click
            if(!raceBetsJS.browser.type.isiPad() && !raceBetsJS.browser.type.isiPhone()){
                $('#scroll-down').click(function(e){
                    e.preventDefault();

                    var footer = $('#card-betslip-footer');
                    $('html,body').animate({
                        scrollTop: (footer.offset().top - $('html').height() + footer.outerHeight() + 10
                      )
                    }, 500);
                });

            } else {
                // hide on ipad
                $('#scroll-down').hide();
            }

            // cache dom elements
            var buttons = $(document.getElementById("race-buttons"));
            card.dom = {
                buttons: buttons,
                buttonMyBets: buttons.children('li.my-bets'),
                buttonMedia: buttons.children('li.media'),
                postTime: $(document.getElementById('post-time')),
                raceDetails: $(document.getElementById('race-details')),
                raceStatusButton: $(document.getElementById('race-details')).find('.race-status-btn'),
                jackpotButton: $(document.getElementById('race-details')).find('div.race-jackpot-btn'),
                raceStatus: $('.race-status-elem'),
                siblingNav: $(document.getElementById('sibling-nav')),
                result: $(document.getElementById('race-result')),
                detailsTags: $(document.getElementById('race-details-tags')),
                secondRow: $('.row.second'),
                termsLink: $('.terms-link'),
                thCol1: $('.th-col-1')
            };


            if (data.race.previewHasAudio) {
                $('.race-preview').addClass('hasAudio');
                raceBetsJS.application.assets.audioPreview.show($('.race-preview-text'), data.race.idRace);
            }

            if (raceBetsJS.application.assets.accBetslip.isOpen() === 'pick' && raceBetsJS.format.isPickPlace(raceBetsJS.application.assets.accBetslip.betType())) {
                $('#runners-table .top .checkboxes .col-1').text(raceBetsJS.i18n.data.betTypePLC);
            }

            // instantiate runners class
            card.runners = new raceBetsJS.application.content.race.Runners($('#runners-table'), data);

            // adjust stables object (before new Betslip)
            adjustStables(true);

            // instantiate betslip class
            card.betslip = new raceBetsJS.application.content.race.Betslip($('#card-betslip-options'), $('#card-betslip-footer'), card);

            // adjust bet buttons
            setButtonsBetting();

            // show media buttons
            setButtonMedia();

            // adjust results box
            adjustResultBox();

            // update race status button
            updateRaceStatusButton();

            // update jackpots
            updateJackpotButton(true);

            // set free bets and subscribe to initial
            if (!raceBetsJS.application.globals.isPopup) {
                setFreeBets();
                $.subscribe('/updateLoginInfo/first', setFreeBets);
            }

            // if user is logged in
            if (raceBetsJS.application.user.loggedIn) {
                // get customer's infos (bets, favourites)
                $.ajax({
                    url: '/ajax/races/customerinfo/id/' + card.data.race.idRace,
                    success: personaliseCard
                });

            } else {
                $('div.racecard table.runners tbody td.horse div.icons').find('a.notes, a.favourite')
                    .addClass('tipped').attr('title', raceBetsJS.i18n.data.featureRequiresLogin);
            }

            // init terms and confirmations link opening dialog with full conditions
            function setPromoListeners () {
                card.dom.termsLink.on('click', function(e) {
                    if ($(this).data().termsIndex === undefined) return;

                    var idx = $(this).data().termsIndex,
                        fullTerms = raceBetsJS.i18n.data[data.race.promotions[idx].fullTerms];

                    e.preventDefault();

                    raceBetsJS.application.assets.modalDialog.show({
                        type: 'confirmation',
                        title: raceBetsJS.i18n.data.labelFullConditions,
                        buttons: [
                            {
                                id: 'dlg-lockout-confirm',
                                label: raceBetsJS.i18n.data.buttonOK,
                                action: function(){
                                    raceBetsJS.application.assets.overlay.close();
                                }
                            }
                        ],
                        content: fullTerms,
                    });
                });
            }

            // init betslip buttons
            card.dom.buttons.on('click', 'li.betslip.enabled', function(e) {
                e.stopPropagation();
                toggleBetslip($(this).data('type'));
            });
            // init quickpick buttons
            card.dom.buttons.on('click', 'li.quick-pick.enabled', function(e) {
                e.stopPropagation();
                raceBetsJS.application.assets.quickPick.show(card.data.race.idRace);
            });

            // init special bets button
            card.dom.buttons.on('click', 'li.special-bets.enabled', function(e) {
                raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/event/details/id/' + card.data.race.idEventSpecialBets);
            });

            // init my bets button
            card.dom.buttons.on('click', 'li.my-bets.enabled', toggleMyBets);

            // initially sort card
            card.runners.sort(card.data.event.isAntePost ? $('#th-fow') : $('#th-pn'), true);

            // make sortable column heads clickable
            card.runners.dom.table.on('click', 'th.sortable', function() {
                card.runners.sort($(this));
            });

            // runners details buttons observers
            // only show if logged in, in case US tote race
            if (!raceBetsJS.application.user.loggedIn && card.data.event.toteCurrency == 'USD') {
                card.runners.dom.table.find('.pp-detailed.enabled, a.ante-post.enabled')
                    .addClass('tipped')
                    .attr('title', raceBetsJS.i18n.data.featureRequiresLogin);
            } else {
                card.runners.dom.table.on('click', 'a.pp-detailed.enabled, a.ante-post.enabled', function() {
                    var elem = $(this);
                    var type = (elem.hasClass('ante-post')) ? 'antePost' : 'ppDetailed';

                    card.runners.runners[elem.parents('tr.runner').data('id-runner')].toggleDetailsRow(type);
                });
            }

            // runners odds-buttons prices
            card.runners.dom.table.on('mouseup', 'td.odds.price .odds-button.enabled', clickOddsButton);

            // runners odds-buttons fixed
            card.runners.dom.table.on('mouseup', 'td.odds.fixed .odds-button.enabled', function() {
                // mark checkbox if multiples are enabled and accumulation betslip is open
                if (card.betslip.betslipType === 'acc' && card.data.event.multiplesFxd === true && card.data.event.isAntePost === false) {

                    var col = card.betslip.checkboxColsVisible[0];
                    var idRunner = $(this).parents('tr.runner').data('id-runner');

                    var df = new $.Deferred();
                    $.when(df).done(function() {
                        // mark checkbox
                        if (card.betslip.isMarked(col, idRunner) === false) {
                            card.betslip.dom.checkboxes[col][idRunner].addClass('checked');
                            card.betslip.bet.marks[col].push(idRunner);
                        }
                    });

                }

                raceBetsJS.application.assets.bettingDialog.show(this, { accDeferred: df });
            });

            if(!raceBetsJS.browser.type.isiPad() && !raceBetsJS.browser.type.isiPhone()){
                // sibling races tooltip
                Tipped.create('div.racecard #sibling-nav .tipped', {
                    hook: 'bottommiddle',
                    maxWidth: 600,
                    hideOn: [
                        { element: 'self', event: 'mouseleave' },
                        { element: 'tooltip', event: 'mouseenter' }
                    ]
                });
            }

            // tooltip for auto start message
            Tipped.create('div.racecard .auto-start', {
                hook: 'righttop',
                maxWidth: 360,
                hideOn: [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' }
                ]
            });

            Tipped.create('.notify-tag:not(.virtual)', function(elem) {
                    return raceBetsJS.application.templates.race.promotions({ promotion: data.race.promotions[$(elem).data('label-tag')] });
                }, {
                    hook: 'bottommiddle',
                    offset: { y: 0 },
                    onShow: function(content, element) {
                        card.dom.termsLink = $('.terms-link');
                        setPromoListeners();
                    },
                    onHide: function(content, element) {
                        $(content).find('.terms-link').off();
                    },
                    hideOn: [
                        { element: 'self', event: 'mouseleave' },
                        { element: 'tooltip', event: 'mouseleave' },
                        { element: 'tooltip', event: 'click' }
                    ],
                    maxWidth: 150,
                    zIndex: 10000
                }
            );

            // create common tooltips
            createTooltips();

            // runner details tooltip
            Tipped.create('div.racecard table.runners tbody td.horse span.name span', function(elem) {
                var span = $(elem).parent();
                return card.runners.runners[$(elem).parents('tr.runner').data('idRunner')].getDetails((span.outerWidth() >= parseInt(span.css('max-width'))));
            }, {
                hook: 'bottomleft',
                skin: 'runnerDetails',
                hideOn: [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' },
                    { element: 'tooltip', event: 'click' }
                ],
                maxWidth: 495,
                zIndex: 10000
            });

            Tipped.create('div.racecard table.runners td.jockey span.jockey', function(elem) {
                    return card.runners.runners[$(elem).parents('tr.runner').data('idRunner')].getJockeyStats();
                }, {
                    hook: 'topleft',
                    offset: { y: 0 },
                    skin: 'runnerDetails',
                    hideOn: [
                        { element: 'self', event: 'mouseleave' },
                        { element: 'tooltip', event: 'mouseenter' },
                        { element: 'tooltip', event: 'click' }
                    ],
                    maxWidth: 495,
                    zIndex: 10000
                }
            );

            Tipped.create('div.racecard table.runners td.jockey span.greyhound-trainer', function(elem) {
                    return card.runners.runners[$(elem).parents('tr.runner').data('idRunner')].getTrainerStats();
                }, {
                    hook: 'topleft',
                    offset: { y: 0 },
                    skin: 'runnerDetails',
                    hideOn: [
                        { element: 'self', event: 'mouseleave' },
                        { element: 'tooltip', event: 'mouseenter' },
                        { element: 'tooltip', event: 'click' }
                    ],
                    maxWidth: 495,
                    zIndex: 10000
                }
            );

            Tipped.create('div.racecard table.runners td.jockey span.trainer', function(elem) {
                    return card.runners.runners[$(elem).parents('tr.runner').data('idRunner')].getTrainerStats();
                }, {
                    hook: 'bottomleft',
                    offset: { y: 0 },
                    skin: 'runnerDetails',
                    hideOn: [
                        { element: 'self', event: 'mouseleave' },
                        { element: 'tooltip', event: 'mouseenter' },
                        { element: 'tooltip', event: 'click' }
                    ],
                    maxWidth: 495,
                    zIndex: 10000
                }
            );

            if(!raceBetsJS.browser.type.isiPad() && !raceBetsJS.browser.type.isiPhone()){
                // price history tooltip
                Tipped.create('div.racecard table.runners tbody td.odds.price div.trend .odds-button', function(elem) {
                    return card.runners.runners[$(elem).parents('tr.runner').data('idRunner')].getPriceHistory();
                }, {
                    hook: 'leftmiddle',
                    skin: 'oddsHistory'
                });

                // fixed odds history tooltip
                Tipped.create('div.racecard table.runners tbody td.odds.fixed.win .odds-button', function(elem) {
                    return card.runners.runners[$(elem).parents('tr.runner').data('idRunner')].getFixedOddsHistory();
                }, {
                    hook: 'leftmiddle',
                    skin: 'oddsHistory'
                });
            }

            // activate polling for up to 12 hours after a race
            var secondsSincePost = (($.now() - raceBetsJS.application.user.timeDiff) / 1000) - card.data.race.postTime;

            // Joining to node.js info channel, only if race is not over, or cancel or not more than 12 hours
            if (secondsSincePost < 3600 * 12 || (card.data.race.raceStatus != 'FNL' && card.data.race.raceStatus != 'CNC') || debugMode === 'results') {

                if(raceBetsJS.application.globals.webSockets){
                    webSocketSubscription = raceBetsJS.webSockets.nodeJS.join({
                                channel : "node_raceCard_"+data.race.idRace,
                                callback : socketCB,
                        timestamp: card.data.timestamp,
                        idEvent: card.data.event.idEvent
                    });
                }
                else{
                    startAjaxUpdates();
                }
            }

            // subscribe to toggle runner requests (for acc betslip)
            subscriptions.push($.subscribe('/race/' + card.data.race.idRace + '/toggleRunner', function(idRunner) {
                if (card.betslip.isOpen() != 'std') {
                    card.betslip.toggleMark(1, idRunner);
                }
            }));

            // subscribe to acc betslip close action
            subscriptions.push($.subscribe('/accBetslip/close', function() {
                if (card.betslip.isOpen() != 'std') {
                    card.betslip.close();
                }
            }));

            // start highlighting favourites
            favouritesInterval.tick();
            favouritesInterval.start();

            // check if an external archive playback is suppose to be triggered
            var idExternal = raceBetsJS.browser.params.get('external');
            var isArchive = raceBetsJS.browser.params.get('archive');
            if (idExternal !== false || isArchive == 'true') {
                // if there is an externalId, start the media browser
                raceBetsJS.application.assets.media.show({
                    idRace: card.data.race.idRace,
                    idChannel: card.data.race.media.idChannel,
                    provider: card.data.race.media.provider,
                    streamType: card.data.race.media.streamType,
                    url: card.data.race.media.archiveLink,
                    country: card.data.event.country,
                    idExternal: idExternal
                });

                if (isArchive == 'true' && !raceBetsJS.application.user.loggedIn) {
                    raceBetsJS.application.assets.dialogs.loginDialog.show();
                }
            }

            adjustWidth();
            adjustJockeyWeight();

            // enable kevboard left/right arrow navigation between races
            if (data.siblingRaces && data.siblingRaces.races && card.dom.siblingNav.length) {
                $(document).on('keydown.racecard', function(e) {
                    if (raceBetsJS.application.assets.bettingDialog.isVisible() === false && card.betslip.dom.options.is(':visible') === false) {
                        if (e.keyCode == 39 && card.data.race.raceNumber < data.siblingRaces.races.length) {
                            raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/race/details/id/' + data.siblingRaces.races[card.data.race.raceNumber].idRace);
                            e.preventDefault();
                        } else if (e.keyCode == 37 && card.data.race.raceNumber > 1) {
                            raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/race/details/id/' + data.siblingRaces.races[card.data.race.raceNumber-2].idRace);
                            e.preventDefault();
                        }
                    }
                });
            }

            canceledRace(data);

            // Open Stream for VR races automatically.
            if (card.data.event.isVirtual && card.data.race.media && raceBetsJS.application.user.loggedIn && $('#content-media').is(':hidden')) {
                openStream();
            }
        };


        var canceledRace = function (data) {
            if (data.race && data.race.raceStatus && data.race.raceStatus === 'CNC') {
                var runners = _.values(data.runners.data);

                if (runners.length === 1 && runners[0].idSubject === 0) {
                    card.runners.dom.tableWrapper.remove();
                    card.dom.secondRow.remove();
                }
            }
        }

        var createTooltips = function() {
            // general tooltip
            Tipped.create('div.racecard .content .tipped', {
                hook: 'bottommiddle',
                maxWidth: 600,
                hideOn: [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' }
                ]
            });

            // rule4 deductions tooltip
            Tipped.create('div.racecard .content .notify-tag.rule4', function() {
                if (card.data.race.rule4Deductions.length > 0) {
                    return $('#rule4-deductions-content').html();
                } else {
                    return false;
                }
            }, {
                hook: 'bottomleft',
                maxWidth: 550,
                hideOn: [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' }
                ]
            });

            // BOG tooltip
            Tipped.create('div.racecard .bog', {
                hook: 'bottommiddle',
                maxWidth: 320,
                hideOn: [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' }
                ]
            });
        }

        /**
        * raceDetailsInterval
        *
        * Interval for polling new race details
        */
        var raceDetailsInterval = new raceBetsJS.time.Interval({
            interval: 30000,
            tick: function() {
                pendingRequest = $.ajax({
                    url: raceBetsJS.application.content.getAjaxCacheUrl('/ajax/races/details/id/' + card.data.race.idRace + '/version/short/'),
                    success: updateCard,
                    complete: function() {
                        // Clear pending request
                        pendingRequest = null;
                    }
                });
            }
        });

        /**
        * favouritesInterval
        *
        * Highlights the favourites
        */
        var favouritesInterval = new raceBetsJS.time.Interval({
            interval: 800,
            tick: function() {
                card.runners.dom.table.find('.odds-button.enabled.favourite').toggleClass('blink');
            }
        });

        /**
        * updateCard
        *
        * Update the card with changed race details
        */
        var updateCard = function(data) {
            var updateFavourite = false;
            var updatePriceHistory = false;
            var updateFixedOddsHistory = false;

            // betTypes
            if(data.betTypes){
                $.extend(true,card.data.betTypes, data.betTypes);
                $.each(card.runners.runners, function() {
                    $.extend(true,this.data.betTypes, data.betTypes);
                });

                if (raceBetsJS.application.globals.brandName !== 'suaposta'
                    && card.data.event.country === 'US'
                    && card.data.event.toteGateway === 'EGT'
                    && data.betTypes.normal
                    && data.betTypes.normal.BOK !== undefined) {
                        var logData = {
                            brand: raceBetsJS.application.globals.brandName,
                            update: data
                        };
                        $.post('/rest/v1/log/general', JSON.stringify({log_level: 'INF', data: JSON.stringify(logData)}));
                }
            }

            // race updates
            if(data.runners){
                $.each(data.runners, function(i, newData) {
                    var runner = card.runners.runners[newData.idRunner];
                    var runnerData = card.data.runners.data[newData.idRunner];
                    var elem;

                    // make sure both exists
                    if (newData.jockey.firstName == null || newData.jockey.lastName == null) {
                        return;
                    }

                    // toggle reserve runner
                    if (newData.reserve === true) {
                        runner.dom.row.addClass('reserve');
                        runner.dom.row.find('span.jockey').html(raceBetsJS.i18n.data.labelReserve);
                    } else {
                        runner.dom.row.removeClass('reserve');
                        if (newData.jockey && card.data.race.raceType !== 'D') {
                            runner.dom.row.find('span.jockey').html(newData.jockey.firstName + '&nbsp;' + newData.jockey.lastName);
                        }
                    }

                    // continue if runner is not defined, happens in ante-post markets if a horse is unscratched
                    if (!runner) {
                        return;
                    }

                    if (runnerData.scratched != newData.scratched || !raceBetsJS.object.equals(runnerData.odds, newData.odds)) {
                        updateFavourite = true;
                        updatePriceHistory = (updatePriceHistory || runnerData.odds.PRC != newData.odds.PRC);
                        updateFixedOddsHistory = (updateFixedOddsHistory || runnerData.odds.FXW != newData.odds.FXW);

                        // enable/disable checkboxes in betslip
                        if (runnerData.scratched != newData.scratched) {
                            runnerData.scratched = newData.scratched;
                            card.betslip.updateRunnerStatus(newData.idRunner);

                            if (newData.scratched) {
                                card.runners.numNotScratched--;
                            } else {
                                card.runners.numNotScratched++;
                            }
                        }

                        runnerData.odds.FXW = newData.odds.FXW;
                        runnerData.odds.FXP = newData.odds.FXP;
                        var oddsTypes = ['FXW', 'FXP'];
                        if (newData.odds.hasOwnProperty('PRC')){
                            runnerData.odds.PRC = newData.odds.PRC;
                            oddsTypes.push('PRC');
                        }

                        runner.updateOddsButtons(oddsTypes);
                    }
                });
            }

            if (updateFavourite) {
                card.runners.setFavourites();
            }

            if (updatePriceHistory) {
                card.runners.updatePriceHistory();
            }

            if (updateFixedOddsHistory) {
                card.runners.updateFixedOddsHistory();
            }

            // post time change
            if (data.race && data.race.postTime && (card.data.race.postTime != data.race.postTime)) {
                card.data.race.postTime = data.race.postTime;
                card.dom.postTime.html(raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, card.data.race.postTime));
            }

            // race status change
            if (data.race && data.race.raceStatus && (card.data.race.raceStatus != data.race.raceStatus) ){
                card.data.race.raceStatus = data.race.raceStatus;
                card.dom.raceStatus[0].className = card.dom.raceStatus[0].className.replace(/status-(OPN|FNL|TMP|STR|RVW|CNC|SPD)/, 'status-' + card.data.race.raceStatus);
                card.runners.runnersDo('updateOddsButtons');

                if (card.data.race.raceStatus != 'OPN') {
                    toggleBetslip(null, false);
                    raceBetsJS.application.assets.accBetslip.removeRace(card.data.race.idRace);
                }

                // update current sibiling icon
                elem = card.dom.siblingNav.children('li.race-' + card.data.race.idRace)[0];
                elem.className = elem.className.replace(/status-([A-Z]{3})/, 'status-' + card.data.race.raceStatus);

                updateRaceStatusButton();
                setButtonsBetting();
                updateLiveOnTag();
            }

            // fixed odds status change
            if (data.race &&  data.race.fixedOddsStatus  && (card.data.race.fixedOddsStatus != data.race.fixedOddsStatus)) {
                card.data.race.fixedOddsStatus = data.race.fixedOddsStatus;
                card.runners.setColVisibility();
                card.runners.runnersDo('updateOddsButtons', [['FXW', 'FXP']]);
            }

            // determine if it's rule 4
            if (data.race) {
                data.race.isRule4 = (data.event !== undefined && data.race !== undefined && (data.event.rule4 && data.race.fixedOddsStatus == 'ON'));
            }

            // sibling races changes
            if (data.siblingRaces && data.siblingRaces.races && card.dom.siblingNav.length) {
                $.each(data.siblingRaces.races, function(i) {

                    var race = this,
                    idRace = race.idRace,
                    srl = card.data.siblingRaces.races.length,
                    cardRace = false;

                    for (var j = 0; j < srl; ++j){
                        if(card.data.siblingRaces.races[j].idRace == idRace){
                            cardRace = card.data.siblingRaces.races[j];
                            break;
                        }
                    }


                    if((cardRace != false) && (cardRace.raceStatus != race.raceStatus)) {
                        cardRace.raceStatus = race.raceStatus;
                        var elem = card.dom.siblingNav.children('li.race-' + idRace)[0];
                        elem.className = elem.className.replace(/status-([A-Z]{3})/, 'status-' + race.raceStatus);
                    }

                    race = idRace = srl = cardRace = null;
                });
            }

            // update off-time if results are in already and off-time wasn't available yet or has changed
            if (card.dom.result.length && data.race && data.race.offTime && (card.data.race.offTime === undefined || card.data.race.offTime !== data.race.offTime)) {
                // update in cache
                card.data.race.offTime = data.race.offTime;

                // update time
                card.dom.result.find('table.odds-container th.off-time span.time').html(
                    raceBetsJS.i18n.print('timeLong', {
                        time: raceBetsJS.application.assets.timezone.date('H:i' + (($.inArray(card.data.event.country, ['GB', 'IE', 'AE']) > -1) ? ":s" : ""), data.race.offTime )
                    })
                );
            }

            // update deductions (or cache them in the beginning)
            /*
            if ((data.race && data.event) && (card.data.race.rule4Deductions === undefined || !raceBetsJS.object.equals(card.data.race.rule4Deductions, data.race.rule4Deductions))) {
                card.data.race.rule4Deductions = data.race.rule4Deductions;
                card.dom.raceDetails.find('div.details').append(raceBetsJS.application.content.race.getRule4Row(data));
            }
            */

            // rule 4 deductions updated?
            if (data.race && raceBetsJS.object.equals(card.data.race.rule4Deductions, data.race.rule4Deductions) === false) {
                if (data.race.rule4Deductions !== undefined && data.race.rule4Deductions.length > 0) {
                    // new deductions, check if it existed before and create element (with content)

                    // create HTML tooltip
                    var deductions = '';
                    var raceDate = raceBetsJS.application.assets.timezone.date('Y-m-d', data.race.postTime); // localized date of the race day

                    $.each(data.race.rule4Deductions, function() {
                        var startDate = raceBetsJS.application.assets.timezone.date('Y-m-d', this.dateStart); // localized date of the deduction start
                        var endDate = raceBetsJS.application.assets.timezone.date('Y-m-d', this.dateEnd);  // localized date of the deduction end

                        var startDateStr = raceBetsJS.application.assets.timezone.date((startDate != raceDate) ? raceBetsJS.i18n.data.dateFormatDate + ' H:i:s' : 'H:i:s', this.dateStart);
                        var endDateStr = raceBetsJS.application.assets.timezone.date((endDate != raceDate) ? raceBetsJS.i18n.data.dateFormatDate + ' H:i:s' : 'H:i:s', this.dateEnd);

                        deductions += raceBetsJS.i18n.print('rule4RunnerDeduction', {
                                deduction: this.deduction,
                                from: (this.dateStart > 0 ? startDateStr : '00:00:00'),
                                till: endDateStr
                            }) + ' (' + this.name + ') <br />';
                    });

                    // update globally
                    card.data.race.rule4Deductions = data.race.rule4Deductions;

                } else {
                    // remove deductions
                    card.data.race.rule4Deductions = [];
                }
            }

            // check if race details tags are required
            // @todo: add Best Odds Guaranteed to NodeJS and adjust logic
            // if (((data.race.isRule4 !== card.data.race.isRule4) && data.race.isRule4 === true) && (card.data.race.bestOddsGuaranteed !== true)) {
            //     // add tags row to race details
            //     card.dom.detailsTags = $('<span />').attr({
            //         'class': 'row last',
            //         'id' : 'race-details-tags'
            //     });

            //     // append to dom
            //     card.dom.raceDetails.find('.details').append(card.dom.detailsTags);

            // } else {
            //     // remove tags row from race details
            //     // ... but only if BOG is not requiring the space
            //     if (card.data.race.bestOddsGuaranteed !== true && data.event !== undefined) {
            //         $('#race-details-tags').remove();
            //         delete card.dom.detailsTags;
            //     }
            // }

            // rule 4 updated?
            if (data.event !== undefined && data.race !== undefined && data.race.isRule4 !== card.data.race.isRule4) {
                // rule 4 ENABLED
                if (data.race.isRule4 === true) {
                    // create BOG label
                    card.dom.detailsTags.append(
                        $('<a />').attr({
                            'id': 'rule4-tag',
                            'href': raceBetsJS.application.assets.settings.getBrandSettings('supportURLs', 'rule4'),
                            'class': 'rule4 popup',
                            'data-popup-width': '980',
                            'data-popup-scroll': 'yes'
                        }).append(
                            $('<span />').attr({
                                'class': 'notify-tag white has-icon rule4',
                                'data-tipped': raceBetsJS.i18n.data.hintBOG
                            }).append(
                                $('<b />').text(raceBetsJS.i18n.data.labelRule4Applies)
                            )
                        ),
                        $('<span id="rule4-deductions-content" style="display: none" />').html(deductions)
                    );

                } else {

                    // rule 4 DISABLED
                    $('#rule4-tag').remove();
                    $('#rule4-deductions-content').remove();
                }

                // update globally
                card.data.race.isRule4 = data.race.isRule4;
            }

            // place factor updated?
            if (data.race !== undefined && data.race.placesNum !== card.data.race.placesNum && data.event !== undefined) {
                if (data.race.placesNum !== undefined) {
                    if ($('#race-each-way-terms').length > 0) {
                        // update text
                        $('#race-each-way-terms').html(
                            '&nbsp;|&nbsp;' + raceBetsJS.i18n.data.labelEachWayTerms + ': '
                                + data.race.placesNum + ' ' + raceBetsJS.i18n.data.labelPlaces + ' @ 1/'
                                + data.race.placeOddsFactor + ' ' + raceBetsJS.i18n.data.labelOdds
                        );

                    } else {
                        // create and append element
                        card.dom.raceDetails.find('.details .row.second').append(
                            $('<span />')
                                .attr({
                                    'id': 'race-each-way-terms',
                                    'class': (data.event.spEvent ? 'tipped' : undefined),
                                    'title': (data.event.spEvent ? raceBetsJS.i18n.data.tipEachWayTerms : undefined)
                                }).html(
                                    '&nbsp;|&nbsp;' + raceBetsJS.i18n.data.labelEachWayTerms + ': '
                                        + data.race.placesNum + ' ' + raceBetsJS.i18n.data.labelPlaces + ' @ 1/'
                                        + data.race.placeOddsFactor + ' ' + raceBetsJS.i18n.data.labelOdds
                                )
                        )
                    }

                }

                card.data.race.placesNum = data.race.placesNum;
            }

            // result change
            if (data.result && (!raceBetsJS.object.equals(card.data.result, data.result))) {
                card.data.result = data.result;

                if (data.result.positions == undefined) {
                    if (card.dom.result) {
                        card.dom.result.remove();
                        card.dom.result = {};
                    }
                } else {
                    var result = $(raceBetsJS.application.templates.race.result({
                        result: data.result,
                        wps: (card.data.event.toteCurrency == 'USD'),
                        country: card.data.event.country,
                        isAntePost: card.data.event.isAntePost,
                        raceStatus: card.data.race.raceStatus,
                        runners: card.data.runners,
                        spEvent: card.data.event.spEvent,
                        deduction: card.data.race.rule4Deductions,
                        deadHeat: card.data.race.deadHeat,
                        offTime: data.race.offTime,
                        placesNum: data.race.placesNum,
                        placeOddsFactor: data.race.placeOddsFactor
                    }));

                    if (!card.dom.result.length) {
                        // if there is no result box yet, insert it
                        card.dom.result = result.hide();
                        card.dom.raceDetails.parent().after(card.dom.result);
                        card.dom.result.slideDown();

                    } else {

                        // update the existing result box
                        card.dom.result.html(result.html());
                    }

                    adjustResultBox();
                }

                card.runners.setWinners();
            }

            // update off-time
            if (data.race && data.race.offTime && card.dom.result.length) {
                card.dom.result.find('table.odds-container th.off-time span.time').html(
                    raceBetsJS.i18n.print('timeLong', {time:raceBetsJS.application.assets.timezone.date("H:i" + (($.inArray(card.data.event.country, ["GB", "IE", "AE"]) > -1) ? ":s" : ""), data.race.offTime )})
                );
            }

            canceledRace(data);
            adjustWidth();
            adjustJockeyWeight();
            createTooltips();
            adjustStables();
        };

        /**
        * personaliseCard
        *
        * Number of bets and customer's favourites
        */
        var personaliseCard = function(data) {
            card.customer = data;

            setMyBetsNum();
            setMyFavourites();
        };

        /**
        * adjustHead
        *
        * Adjust the head of the race card
        */
        var adjustHead = function() {
            // set first/last class
            card.dom.buttons.children('li.first, li.last').removeClass('first last');
            card.dom.buttons.children('li:visible:first').addClass('first');
            card.dom.buttons.children('li:visible:last').addClass('last');

            // set race details box min height
            if (card.dom.raceDetails.height() < card.dom.buttons.innerHeight() ) {
                card.dom.raceDetails.css('min-height', card.dom.buttons.innerHeight());
            }
        }

        var adjustJockeyWeight = function() {
            $.each(card.runners.runners, function() {
                var row = this.dom.row,
                    jockey = this.runner.jockey,
                    content = "";

                // weight not existing in trot races
                if (jockey.weight === undefined) {
                    return;
                }

                if (jockey.weight.addition > 0 || jockey.weight.allowance > 0) {
                    var span = row.find('td.horse-age span.weight:not(.extra)');

                    span.html(span.html()  + '*').addClass('extra');

                    if (jockey.weight.addition > 0) {
                        content += raceBetsJS.i18n.data.labelWeightAddition + ': +' + raceBetsJS.format.weight(jockey.weight.addition, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) + '<br />';
                    }

                    if (jockey.weight.allowance > 0 ) {
                        content += raceBetsJS.i18n.data.labelWeightAllowance + ': -' + raceBetsJS.format.weight(jockey.weight.allowance, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem);
                    }

                    Tipped.create(span, content, {
                        skin: 'racebets ',
                        hook: 'topmiddle'
                    });
                }
            });
        }

        /**
        * adjustResultBox
        *
        * Fix height of odds-container table because of borders
        */
        var adjustResultBox = function() {
            if (!card.dom.result.length) {
                return;
            }

            var finalPositionsElem = card.dom.result.find('table.positions');
            var finalOddsElem = card.dom.result.find('table.odds-container');
            var positionsHeight = finalPositionsElem.outerHeight();

            if (finalOddsElem.height() < positionsHeight) {
                finalOddsElem.height(positionsHeight);
            }

            multiOddsTooltips();
        };

        /**
        * clickOddsButton
        *
        * Executed when an odds button (price) is clicked
        */
        var clickOddsButton = function() {
            // open betslip
            $.when(card.betslip.isOpen() || toggleBetslip('std', true))
                .done($.proxy(function(opened) { // opened is true if the betslip was opened with this click
                    // mark checkbox
                    var col = card.betslip.checkboxColsVisible[0];
                    var idRunner = $(this).parents('tr.runner').data('id-runner');

                    if (card.betslip.betslipType === 'std' ||
                        card.betslip.betslipType === 'acc' && card.betslip.isMarked(col, idRunner) === false) {
                        card.betslip.toggleMark(col, idRunner, undefined, undefined);
                    }
                }, this));
        };

        /**
        * setMyBetsNum
        *
        * Sets the number of bets the customer has in this race
        */
        var setMyBetsNum = function(racesToIncrease) {
            var updated = false;

            if (!card.customer || !card.dom) {
                return;
            }

            // increase number of bets by 1
            if (_.isArray(racesToIncrease) && $.inArray(card.data.race.idRace, racesToIncrease) > -1) {
                card.customer.betsNum++;
                updated = true;
            }

            // update button label
            card.dom.buttonMyBets.find('.em').html('(' + card.customer.betsNum + ')');

            // enable button if customer has bets
            if (card.customer.betsNum > 0) {
                card.dom.buttonMyBets.removeClass('hidden').addClass('enabled');

                // update the bets if they are opened and increase was triggered
                if (updated && card.dom.customersBets && card.dom.customersBets.is(':visible')) {
                    updateMyBets();
                }
            }
        }

        /**
        * setMyFavourites
        *
        * Sets favourite notes and email alert icons
        * */
        var setMyFavourites = function() {
            var tr;
            $.each(card.customer.favourites, function(idSubject, data) {
                tr = card.runners.dom.table.find('.runner[data-id-subject="' + idSubject + '"] div.icons');

                if (data.emailAlert === '1') {
                    tr.find('a.favourite').addClass('active');
                }

                if (data.note !== '') {
                    var elem = tr.find('a.notes');
                    elem.addClass('active');
                    elem.data( "title", data.note );

                    Tipped.create(elem, _.escape(data.note), {
                        hook: 'topmiddle',
                        skin: 'racebets',
                        maxWidth: 220
                    });
                }
            });

            card.runners.dom.table.on('click', 'div.icons a', function() {
                var elem = $(this);
                var idSubject = $(this).parents('tr.runner').data('id-subject');
                var orginalNoteData;

                if (elem.hasClass('favourite')) {
                    $.ajax({
                        url: '/ajax/favourites/star/id/' + idSubject + '/race/' + card.data.race.idRace + '/store/' + (elem.hasClass('active') ? 'false' : 'true'),
                        type: 'get',
                        success: function(data) {
                            elem.toggleClass('active');
                        }
                    });

                } else if (elem.hasClass('notes')) {
                    if (elem.hasClass('open')) {
                        elem.removeClass('open');
                        Tipped.hide(elem);
                        return;
                    }

                    // save orginal string to compare with string when dialog is closing
                    orginalNoteData = elem.data( "title") || "";

                    elem.addClass('open');

                    // remove current tooltip
                    Tipped.remove(elem);

                    // create temporary tooltip with textarea
                    Tipped.create(elem, function(elem) {
                        var textarea = $('<textarea>');
                        var idRunner = $(elem).parents('tr').data('id-runner');

                        textarea.on('keypress', function(e) {
                            if (e.which == 13) {
                                Tipped.hide();

                                // do not actually execute the enter/return key
                                e.preventDefault();

                                // hide tip
                                Tipped.hide(elem);
                            }
                        });

                        textarea.val($(elem).data('title'));

                        return textarea;

                    },{
                        hook: 'topmiddle',
                        skin: 'runnersNotes',
                        showOn: false,
                        hideOn: false,
                        fadeOut: false,
                        hideOnClickOutside: true,
                        onShow: function() {
                            // focus
                            var textarea = $('div.t_Tooltip_runnersNotes textarea');
                            $(textarea).autoResize({ animate: false, extraSpace: 0,
                                onResize: function() {
                                    Tipped.refresh(elem);
                                }
                            }).trigger('change');

                            textarea.focus();
                        },
                        onHide: function() {
                            var note = $('div.t_Tooltip_runnersNotes textarea').val();
                            elem.removeClass('open');

                             // remove current tooltip
                            Tipped.remove(elem);

                            if(orginalNoteData !== note){
                                // update note
                                $.ajax({
                                    url: '/ajax/favourites/savenote/',
                                    type: 'post',
                                    data: {
                                        id: idSubject,
                                        race: card.data.race.idRace,
                                        note: note
                                    }
                                });
                            }

                            // if note is not empty, add tooltip
                            if ($.trim(note) != '') {
                                elem.data('title', note);
                                Tipped.create(elem, _.escape(note), {
                                    hook: 'topmiddle',
                                    skin: 'racebets',
                                    maxWidth: 220
                                });

                                elem.addClass('active');
                            } else {
                                $(elem).removeData('title');
                                elem.removeClass('active');
                            }
                        }

                    }).show();
                }
                // @todo: extend as necessary
            });
        }

        /**
        * setFreeBets
        *
        * Shows available free bets in this race
        * */
        var setFreeBets = function() {
            var freeBet = null,

                /**
                * getDetailsByProgramNumber
                * Returns name of runner by program number
                */
                getDetailsByProgramNumber = function(programNumber) {
                    var ret = false;
                    $.each(card.data.runners.data, function() {
                        if (this.programNumber == programNumber) {
                            ret = {
                                idRunner: this.idRunner,
                                name: this.name,
                                programNumber: this.programNumber
                            };
                        }
                    });

                    return ret;
                },

                /**
                * getFreeBetById
                * Returns object of idFreeBet
                */
                getFreeBetById = function(id) {
                    var ret = false;
                    $.each(freeBets, function() {
                        if (this.idFreebet == id) {
                            ret = this;
                        }
                    });

                    return ret;
                },

                /**
                * setFreeBetOptions
                * Sets betslip options to reflect changes
                */
                setFreeBetOptions = function() {
                    if (raceBetsJS.application.globals.brandName === 'racebets') {
                        var betType = card.dom.freeBets.betType.find(':radio:checked').val();
                    } else {
                        var radios = card.dom.freeBets.betType,
                            radioChecked = radios.find(':radio:checked').next(".c-input__mark"),
                            radioUnChecked = radios.find(':radio:not(:checked)').next(".c-input__mark"),
                            betType = radios.find(':radio:checked').val();

                        radioChecked.removeClass('c-iconFont--circle-thin').addClass('c-iconFont--dot-circle-o');
                        radioUnChecked.removeClass('c-iconFont--dot-circle-o').addClass('c-iconFont--circle-thin');
                    }

                    card.betslip.setBetCategory('BOK');
                    card.betslip.setBetType(betType);
                    card.betslip.setStake((betType == 'WP' ? freeBet.amount/2 : freeBet.amount));
                    card.betslip.setIdFreeBet(freeBet.idFreebet);
                },

                openBetslip = function(classToAdd, classToRemove) {
                    // update button
                    card.dom.freeBets.button.removeClass(classToRemove).addClass(classToAdd).text(raceBetsJS.i18n.data.buttonCancel);

                    // disable select
                    card.dom.freeBets.select.disable();

                    // actually open betslip
                    $.when(card.betslip.open('free-bet')).done(function() {
                        card.betslip.showTag('isFreebet', raceBetsJS.i18n.data.labelFreeBet);

                        if (freeBet !== undefined && freeBet.runnerDetails !== undefined) {
                            card.betslip.setExclusiveMark(1, freeBet.runnerDetails.idRunner);
                        }
                    });

                    // fade in bet type
                    card.dom.freeBets.betTypeContainer.show();

                    // set bet options
                    setFreeBetOptions();
                },

                closeBetslip = function(classToAdd, classToRemove) {
                    // update button
                    card.dom.freeBets.button.removeClass(classToRemove).addClass(classToAdd).text(raceBetsJS.i18n.data.btnUseFreeBet);

                    // enable select
                    card.dom.freeBets.select.enable();

                    // fade out bet type
                    card.dom.freeBets.betTypeContainer.hide();

                    // close betslip
                    card.betslip.close();
                }

            var freeBets = raceBetsJS.application.assets.session.get('freebets');

            if (!freeBets || freeBets.length === 0 || card.data.race.raceStatus !== 'OPN' || card.data.betTypes.normal.BOK === undefined || card.data.betTypes.normal.BOK.WIN === undefined || raceBetsJS.application.assets.accBetslip.isOpen()) {
                // no free bets available OR race not open OR bookie not available
                return;
            }

            // get available betTypes
            var betTypes = [];
            $.each(card.data.betTypes.normal.BOK, function(betType, minStake) {
                if ($.inArray(betType, ['WIN', 'WP']) > -1) {
                    betTypes.push(betType);
                }
            });

            // get available free bets
            var freeBetsValid = [];
            $.each(freeBets, function() {

                // ante post don't match
                if (this.antepost !== card.data.event.isAntePost) {
                    return;
                }

                // if freebet has country limitation, check if event country match
                if (this.countries.length > 0 && this.countries.indexOf(card.data.event.country) < 0) {
                    return;
                }

                // event doesn't match
                if (this.idEvent !== undefined && this.idEvent !== card.data.event.idEvent) {
                    return;
                }

                // race doesn't match
                if (this.idRace !== undefined && this.idRace !== card.data.race.idRace) {
                    return;
                }

                // another free bet placed and not a multi bet
                var racesWithFreeBets = raceBetsJS.application.assets.session.get('freeBetsRaces');
                if ($.inArray(card.data.race.idRace, racesWithFreeBets) > -1 && this.multiBetting !== true) {
                    return;
                }

                // if limited to programNumbers, add names
                if (this.programNumber !== undefined) {
                    this.runnerDetails = getDetailsByProgramNumber(this.programNumber);
                }

                // push object to array
                freeBetsValid.push(this);
            });

            // create free bets container if there are freebets available
            if (freeBetsValid.length >= 1) {
                card.runners.dom.table.parent().before(raceBetsJS.application.templates.race.freeBets({
                    freeBets: freeBetsValid,
                    betTypes: betTypes
                }));
            }
            // else return, no need to init freebet events
            else {
                return;
            }

            // dom references
            card.dom.freeBets = {};
            card.dom.freeBets = {
                row: $('#free-bets'),
                select: $('#free-bets .select-box'),
                betTypeContainer: $('#free-bets .bet-type'),
                betType: $('#free-bets .bet-type'),
                button: $('#free-bets .c-btn')
            };

            if (raceBetsJS.application.globals.brandName === 'racebets') {
                card.dom.freeBets.betType = $('#free-bets .toggle');
            }

            // create select box
            card.dom.freeBets.select = raceBetsJS.application.assets.guidelines.selectBoxControl(card.dom.freeBets.select, {
                onSelect: function(value) {
                    // no valid value
                    if (value < 1) {
                        return;
                    }

                    freeBet = getFreeBetById(value);
                    card.dom.freeBets.button.prop('disabled', false);
                }
            });
            freeBet = getFreeBetById(card.dom.freeBets.select.val());

            if (card.dom.freeBets.select.val() > 0) {
                card.dom.freeBets.button.prop('disabled', false);
            }

            // set bet-types
            card.dom.freeBets.betType.find(':radio').on('click', function() {
                if (!card.betslip.isOpen()) {
                    return;
                }

                // set bet options
                setFreeBetOptions();
            });

            // open / cancel betslip
            card.dom.freeBets.button.on('click', function(event) {
                var classOne = 'c-btn--link',
                    classTwo = 'c-btn--primary';

                if ($(this).hasClass(classTwo)) {
                    openBetslip(classOne, classTwo);
                } else {
                    closeBetslip(classTwo, classOne);
                }

            });

            // subscribe to placed betslips
            subscriptions.push($.subscribe('/bet/placed', function(bet) {
                if (bet === undefined || !bet.idFreebet) {
                    return;
                }

                // reset betslip
                closeBetslip();
            }));

            // subscribe to updated free bets
            subscriptions.push($.subscribe('/updateLoginInfo/freeBets', function() {
                // todo update free bet select
            }));

            // slide down free bets
            if (freeBetsValid.length > 0) {
                card.dom.freeBets.row.show();
            }
        }

        /**
        * setButtonMedia
        *
        * Set/Remove the media button
        */
        var setButtonMedia = function() {
            if (card.data.race.media !== undefined) {
                if( card.data.race.media.provider !== "PFG" && (($.now() - raceBetsJS.application.user.timeDiff) < card.data.race.media.startTime * 1000 || card.dom.buttonMedia.hasClass('archive') ) ) {
                    return;
                }
            } else {
                return;
            }

            // check if provider is ATR and it is al least 5 mins after now - we use it to show archive button
            var atrArchive = card.data.race.media.provider === 'ATR' && ($.now() - raceBetsJS.application.user.timeDiff) > card.data.race.postTime * 1000 + (1000 * 60 * 5);

            // check if stream can be activated
            if (!card.dom.buttonMedia.hasClass('live') && $.inArray(card.data.race.raceStatus, 'OPN STR TMP'.split(' ')) > -1 && card.data.race.media.streamType === 'LIV') {
                card.dom.buttonMedia
                    .addClass('enabled live')
                    .unbind('click')
                    .click(function() {
                        if (!raceBetsJS.application.header.login.check()) {
                            return;
                        }

                        if(raceBetsJS.application.globals.isB2B && card.data.race.media.provider === "PFG" ) {

                            if (card.customer.betsNum < 1) {
                                raceBetsJS.application.assets.modalDialog.show({
                                    icon: 'info',
                                    content: raceBetsJS.i18n.data.msgErrorStreamTurnover,
                                    buttons: [
                                        {
                                            label: raceBetsJS.i18n.data.buttonOK,
                                            action: function() {
                                                if (raceBetsJS.application.globals.isPopup) {
                                                    window.close();
                                                }
                                                raceBetsJS.application.assets.overlay.close();
                                            }
                                        }
                                    ]
                                });
                                return;
                            } else if ( ($.now() - raceBetsJS.application.user.timeDiff + (1000*3*60) ) < card.data.race.media.startTime * 1000 ) {
                                raceBetsJS.application.assets.modalDialog.show({
                                    icon: 'info',
                                    content: raceBetsJS.i18n.data.msgErrorStreamNotStarted,
                                    buttons: [
                                        {
                                            label: raceBetsJS.i18n.data.buttonOK,
                                            action: function() {
                                                if (raceBetsJS.application.globals.isPopup) {
                                                    window.close();
                                                }
                                                raceBetsJS.application.assets.overlay.close();
                                            }
                                        }
                                    ]
                                });
                                return;
                            }
                        }

                        var options = {
                            streamType: 'LIV',
                            provider: card.data.race.media.provider,
                            country: card.data.event.country,
                            idRace: card.data.race.idRace,
                            idChannel: card.data.race.media.idChannel
                        };

                        raceBetsJS.application.assets.media.show(options);
                    })
                    .html(raceBetsJS.i18n.data.linkOpenLivestream + (raceBetsJS.application.globals.brandName === 'racebets' ? ' <span class="arrow"></span>' : ''));

            } else if (($.inArray(card.data.race.media.streamType, ['MP4', 'MSC']) > -1) || atrArchive ) {
                card.dom.buttonMedia
                    .addClass('enabled archive')
                    .unbind('click')
                    .click(function(){
                        if (!raceBetsJS.application.header.login.check()) {
                            return;
                        }

                        openStream();
                    })
                    .html(raceBetsJS.i18n.data.linkOpenArchiveVideo + ' ').append($('<span class="arrow">'));
            } else {
                card.dom.buttonMedia.hide();
                adjustHead();
            }
        };

        /**
        * Open stream
        *
        */
        var openStream = function() {
            raceBetsJS.application.assets.media.show({
                idRace: card.data.race.idRace,
                idChannel: card.data.race.media.idChannel,
                provider: card.data.race.media.provider,
                streamType: card.data.race.media.streamType,
                url: card.data.race.media.archiveLink,
                country: card.data.event.country
            });
        }

        /**
        * setButtonsBetting
        *
        * Enable/Disable the betting buttons
        */
        var setButtonsBetting = function() {
            if (card.data.race.raceStatus == 'OPN' && !_.isEmpty(card.data.betTypes.normal)) {
                card.dom.buttons.find('li.betting').show();
                if(_.isEmpty(card.data.betTypes.normal.BOK) && _.isEmpty(card.data.betTypes.normal.TOT)) {
                    card.dom.buttons.find('[data-type="std"]').hide();
                }

            } else {
                card.dom.buttons.find('li.betting').hide();
            }

            adjustHead();
        };

        /**
        * toggleBetslip
        *
        * Show/Hide the betslip
        */
        var toggleBetslip = function(betslipType, doOpen) {
            if (doOpen !== false && !raceBetsJS.application.header.login.check()) {
                // a bit dirty, but this part will be removed anyway soon
                var df = $.Deferred();
                df.reject();
                return df.promise();
            }

            var ret;

            if (!_.isBoolean(doOpen)) {
                doOpen = (betslipType != 'std' || card.betslip.isOpen() !== betslipType);
            }

            if (doOpen) {
                ret = card.betslip.open(betslipType);
            } else {
                ret = card.betslip.close();
            }

            adjustWidth();

            return ret;
        };

        /**
        * toggleMyBets
        *
        * Toggle My Bets info
        */
        var toggleMyBets = function() {
            // cache dom element and init observers
            if (!card.dom.customersBets) {
                card.dom.customersBets = $('#customers-bets');

                // button delegators
                card.dom.customersBets.on('click', 'a.button.refresh', function() { updateMyBets(true); });
                card.dom.customersBets.on('click', 'a.button.close', toggleMyBets);
                card.dom.customersBets.on('click', 'a[data-public-id]', function(e) {
                    e.preventDefault(); // prevent action
                    e.stopPropagation(); // prevent public delegator

                    var publicId = $(this).data('public-id');

                    // show dialog
                    raceBetsJS.application.assets.betDetails.show({
                        id: publicId
                    });
                });
            }

            if (card.dom.customersBets.is(':visible')) {
                card.dom.customersBets.slideUp(500);
            } else {
                // update bets if neccessary
                $.when(updateMyBets()).done(function() {
                    card.dom.customersBets.slideDown(500);
                });
            }
        };

        /**
        * updateMyBets
        *
        * Updates the customer's bets if neccessary
        */
        var updateMyBets = function(forceUpdate) {
            var df = $.Deferred();

            if (!forceUpdate && card.customer.bets && card.customer.bets.length == card.customer.betsNum) {
                df.resolve();
            } else {
                $.ajax({
                    url: '/ajax/races/customerbets/id/' + card.data.race.idRace,
                    beforeSend: function() {
                        card.dom.buttonMyBets.addClass('loading');
                    },
                    complete: function() {
                        card.dom.buttonMyBets.removeClass('loading');
                    },
                    success: function(data) {
                        card.customer.bets = data.bets;
                        card.customer.betsNum = data.bets.length;
                        setMyBetsNum();

                        // generate dom
                        card.dom.customersBets.html(raceBetsJS.application.templates.race.bets({
                            bets: card.customer.bets,
                            wps: (card.data.event.toteCurrency == 'USD'),
                            country: card.data.event.country
                        }));

                        df.resolve();
                    }
                });
            }

            return df.promise();
        };

        var updateRaceStatusButton = function() {
            var buttonShown = ['STR', 'TMP', 'CNC', 'SPD'];

            if ($.inArray(card.data.race.raceStatus, buttonShown) > -1) {
                card.dom.raceStatusButton
                    .removeClass()
                    .addClass('race-status-btn ' + (raceBetsJS.application.globals.brandName === 'racebets' ? '' : 'status-') + card.data.race.raceStatus)
                    .html(raceBetsJS.i18n.data['raceStatus' + card.data.race.raceStatus])
                    .show();
            } else {
                card.dom.raceStatusButton.hide();
            }
        }

        var updateLiveOnTag = function() {
            var buttonShown = ['FNL', 'CNC'],
                liveOnTag = card.dom.detailsTags.find('.live-on');

            if (liveOnTag.length) {
                if ($.inArray(card.data.race.raceStatus, buttonShown) > -1) {
                    liveOnTag.hide();
                } else {
                    liveOnTag.show();
                }
            }
        };

        var updateJackpotButton = function(init) {
            var jackpots;
            if (raceBetsJS.application.globals.isB2B) {
                jackpots = _.filter(card.data.race.jackpot, function (jackpot) {
                    return ['JPT', 'SUP', 'GUP'].indexOf(jackpot.jackpotType) > -1;
                });
            } else {
                jackpots = card.data.race.jackpot;
            }

            if (jackpots.length === 0) {
                return;
            }

            // if jackpots are available and init is set, init interval
            if (init === true && jackpots.length > 0) {
                if (jackpotInterval !== undefined) {
                    jackpotInterval.stop();
                }

                if (jackpots.length > 1) {
                    jackpotInterval = new raceBetsJS.time.Interval({
                        interval: 5000,
                        tick: updateJackpotButton
                    });
                    jackpotInterval.start();
                }

                // reset counter
                jackpotCounter = 0;
            }

            var jackpot = jackpots[jackpotCounter];

            var betCategoryName;

            // Use special labels for Colossus bet
            if (['GB', 'IE'].lastIndexOf(card.data.event.country) > -1 && jackpot.betCategory === 'TOT')  {
                betCategoryName = raceBetsJS.i18n.data["betCategoryCOL"];
            } else {
                betCategoryName = raceBetsJS.i18n.data["betCategory" + jackpot.betCategory];
            }

            if (jackpot.betType != '') {
                var betTypeName = raceBetsJS.format.betTypeName(
                    jackpot.betType, jackpot.betCategory, raceBetsJS.application.user.lang,
                    (card.data.event.toteCurrency == 'USD'), card.data.event.country
                );
            }

            var jackpotAmount = $('<span>').addClass('amount').html(
                ($.inArray(jackpot.jackpotType, ['JPT', 'GUP']) > -1) ?
                    raceBetsJS.format.money(jackpot.jackpotAmount, 0, jackpot.jackpotCurrency, true) :
                        ($.inArray(jackpot.jackpotType, ['WBN', 'SBN']) > -1) ?
                            jackpot.jackpotAmount + '%' :
                            raceBetsJS.i18n.data.labelSpecial

            );

            var jackpotLabel = $('<span>').addClass('jackpot').html((function () {
                // Use special labels for Colossus bet
                if (['GB', 'IE'].lastIndexOf(card.data.event.country) > -1 && jackpot.betCategory === 'TOT') {
                    return raceBetsJS.i18n.data["jackpotTypeCOL"];
                } else {
                    return raceBetsJS.i18n.data["jackpotType" + jackpot.jackpotType] +
                        ((jackpot.jackpotType == 'SUP') ? ' ' + betTypeName : '')
                }
            })());

            var jackpotType = $('<span>').addClass('bet-type').html(
                (jackpot.jackpotType == 'SUP' || jackpot.betType == '') ? '<span class="bet-category">' + betCategoryName + '</span>' : betTypeName + '<span class="bet-category">(' + betCategoryName + ')</span>'
            );

            if (raceBetsJS.application.globals.brandName === 'racebets') {
                jackpotNew = $('<div>').addClass('jackpot-container').append(jackpotAmount, jackpotLabel, jackpotType);
            } else {
                var jackpotLabelWrapper = $('<div>').addClass('c-tag isJackpot2').append(jackpotAmount, jackpotLabel);
                jackpotNew = $('<div>').addClass('jackpot-container').append(jackpotLabelWrapper, jackpotType);

            }



            if (!jackpotCurrent) {
                card.dom.jackpotButton.append(jackpotNew.css({ left: 0 }));

            } else {
                jackpotNew.css({
                    right: card.dom.jackpotButton.outerWidth() + 'px',
                    opacity: 0
                });
                card.dom.jackpotButton.append(jackpotNew);

                jackpotCurrent.animate({
                    left: '-' + card.dom.jackpotButton.outerWidth(),
                    opacity: 0
                }, 400, 'linear', function() {
                    $(this).remove();
                });

                jackpotNew.animate({
                    left: 0,
                    opacity: 1
                }, 400, 'linear');
            }

            jackpotCurrent = jackpotNew;
            if (++jackpotCounter >= jackpots.length) {
                jackpotCounter = 0;
            }
        };

        /**
        * multiOddsTooltips
        *
        * Creates multi odds tooltips delegator
        */
        var multiOddsTooltips = function() {
            Tipped.create(card.dom.result.find('ul.final-odds li.multi-odds span.title'), function(elem) {
                return $(elem).parent().find('.tooltip').html();
            }, {
                hook: 'rightmiddle',
                skin: 'multiOdds',
                hideOn: [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' },
                    { element: 'tooltip', event: 'click' }
                ]
            });
        };

        /**
        * adjustWidth()
        * Adjust the max-width and min-width of all horse name spans in the race card.
        */
        var adjustWidth = function() {
            if (card.runners === undefined) {
                return;
            }

            // get first column
            var runners = card.runners.runners;
            var hasSilks = (card.runners.dom.table.hasClass('has-silks'));

            var _iOSDevice = !!navigator.userAgent.match(/(iPad|iPhone|iPod)/g);

            if (_iOSDevice) {
                $('.runners').addClass('iOS');
            }

            $.each(runners, function(idRunner, runner) {
                var td = runner.dom.row.find('td.horse');
                var name = td.find('span.name');
                var icons = td.find('span.icons');
                var buttons = td.find('div.icons table');
                var position = td.find('div.icons .c-finishingPost');
                var sidePaddings = parseInt(td.css('padding-left')) + parseInt(td.css('padding-right'));

                name.removeAttr('style');

                var maxWidth = td.outerWidth() - icons.outerWidth() - buttons.outerWidth() - position.outerWidth() - sidePaddings - 14; // 14px margin
                var minWidth = ( icons.width() + buttons.outerWidth() + position.outerWidth() + maxWidth > td.innerWidth() - sidePaddings ) ? 'none' : name.width() + 2; // iOS8 calc bug;

                if ( minWidth > maxWidth ) {
                    minWidth = maxWidth;
                }

                name.css({ maxWidth: maxWidth, minWidth: minWidth });
            });
        };

        /**
        * Rule 4 Row in Race Card
        */
        var getRule4Row = function(data) {
           /*
            if ((data.event.spEvent && data.race.fixedOddsStatus == 'ON') || data.race.placesNum !== undefined || (card.data.event.country == 'DE' && card.data.race.raceType == 'T' && card.data.event.idTrack == 10)) {
                var elemRule4 = $('<span />').attr({ 'class': 'row', 'id': 'rule4-details' }).css('height', '11px');

                if (data.event.spEvent && data.race.fixedOddsStatus == 'ON') {

                    // rule 4
                    elemRule4.append(
                        // rule 4 hint + tip
                        $('<a class="tipped rule4 popup" />')
                            .attr({
                                'href': 'http://support.racebets.com/customer/' + (raceBetsJS.application.user.lang == 'de' ? 'de' : 'en') + '/portal/articles/780077-rule-4',
                                'data-popup-width': 980,
                                'data-popup-scroll': 'yes'
                            }).html(
                                raceBetsJS.i18n.data.labelRule4Applies
                            ),

                        // rule 4 deductions content
                        $('<span id="rule4-deductions-content" style="display: none" />').html(
                            raceBetsJS.application.content.race.getRule4Deductions()
                        )
                    );
                }

                // add separator
                if ((data.event.spEvent && data.race.fixedOddsStatus == 'ON') && data.race.placesNum !== undefined) {
                    elemRule4.append('&nbsp;|&nbsp;');
                }

                // add place terms
                if (data.race.placesNum !== undefined) {
                    elemRule4.append($('<span />')
                        .attr({
                            'id': 'race-each-way-terms',
                            'class': (data.event.spEvent ? 'tipped' : undefined),
                            'title': (data.event.spEvent ? raceBetsJS.i18n.data.tipEachWayTerms : undefined)
                        })
                        .html(raceBetsJS.i18n.data.labelEachWayTerms + ': ' + data.race.placesNum + ' ' + raceBetsJS.i18n.data.labelPlaces + ' @ 1/' + data.race.placeOddsFactor + ' ' + raceBetsJS.i18n.data.labelOdds)
                    );
                }

                // for GERMAN TROT races from 01/01/2013 - show label with "Place" terms
                if (card.data.event.country == 'DE' && card.data.race.raceType == 'T' && card.data.event.idTrack == 10 && card.data.race.postTime > 1356994800) {
                    if (raceBetsJS.application.user.lang == 'de') {
                        elemRule4.append($('<a />')
                            .attr({
                                'id': 'race-each-way-terms',
                                'href': raceBetsJS.application.globals.urlPrefix + '/content/show/module/pages/page/placetrot/',
                                'class': 'ajaxify with-icon'
                            })
                            .html(raceBetsJS.i18n.data.labelEachWayTerms + ': 2 ' + raceBetsJS.i18n.data.labelPlaces)
                        );
                    } else {
                        elemRule4.append($('<span />')
                            .attr({'id': 'race-each-way-terms'})
                            .html(raceBetsJS.i18n.data.labelEachWayTerms + ': 2 ' + raceBetsJS.i18n.data.labelPlaces)
                        );
                    }
                }

                // remove previous ones, this is required as place factor etc is subject to change
                $('#rule4-details').remove();

                // fix for non-existence of outerHtml() in jQuery
                return elemRule4.clone().wrap('<p>').parent().html();

            } else {
                // remove the row again
                if ($('#rule4-details').length > 0) {
                    $('#rule4-details').remove();
                }
            }

            // empty result
            return '';
            */
        };

        /**
        * Rule 4 Tooltip
        */
        var getRule4Deductions = function(data) {
            if (data === undefined) {
                data = card.data;
            }

            var deductions = '',
                raceDate = raceBetsJS.application.assets.timezone.date('Y-m-d', data.race.postTime), // localized date of the race day
                startDate = '', // localized date of the deduction start
                endDate = '', // localized date of the deduction end
                startDateStr = '', // formatted date string of start date
                endDateStr = ''; // formatted date string of end date

            $.each(data.race.rule4Deductions, function() {
                startDate = raceBetsJS.application.assets.timezone.date('Y-m-d', this.dateStart);
                endDate = raceBetsJS.application.assets.timezone.date('Y-m-d', this.dateEnd);

                endDateStr = raceBetsJS.application.assets.timezone.date((endDate != raceDate) ? raceBetsJS.i18n.data.dateFormatDate + ' H:i:s' : 'H:i:s', this.dateEnd);

                if (!this.dateStart) {
                    deductions += raceBetsJS.i18n.print('rule4RunnerDeductionOvernight', {
                        deduction: this.deduction,
                        till: endDateStr
                    }) + ' (' + this.name + (this.odds ? ' | ' + this.odds : '') + ')';
                } else {
                    startDateStr = raceBetsJS.application.assets.timezone.date((startDate != raceDate) ? raceBetsJS.i18n.data.dateFormatDate + ' H:i:s' : 'H:i:s', this.dateStart);

                    deductions += raceBetsJS.i18n.print('rule4RunnerDeduction', {
                        deduction: this.deduction,
                        from: startDateStr,
                        till: endDateStr
                    }) + ' (' + this.name + (this.odds ? ' | ' + this.odds : '') + ')';
                }

                if (this.waived) {
                    deductions += ' - ' + raceBetsJS.i18n.data.labelWaived;
                }

                deductions += '<br />';
            });

            return deductions;
        };

        /**
        * adjustStables
        * Keeps a clean object of arrays of runners in stables, taking scratches into consideration.
        * This was needed due to stable horses, of which the first one was scratched, were not actually placed.
        * - rob (06/02/2014)
        */
        var adjustStables = function(init) {
            // if init, set original/complete horse stables
            if (init !== undefined && init === true) {
                card.data.race.stablesOrig = $.extend({}, card.data.race.stables, {});
            }

            // reset stables
            //var cleanStables = [];
            card.data.race.stables = {}; //$.extend({}, card.data.race.stablesOrig, {});

            $.each(card.data.race.stablesOrig, function(i, idRunners) {
                $.each(idRunners, function(j, idRunner) {
                    var runner = card.runners.data.runners.data[idRunner];

                    // remove runner from stables if scratched
                    if (runner.scratched === true) {
                        idRunners = _.without(idRunners, idRunner);
                    }
                });


                //card.data.race.stables[i] = idRunners;
                card.data.race.stables[i] = idRunners;
            });
        }

        var removeAllTipped = function () {
            $('div.racecard table.runners tbody td.horse span.name span')
                .add('div.racecard .bog')
                .add('div.racecard .auto-start')
                .add('div.racecard .content .tipped')
                .add('div.racecard #sibling-nav .tipped')
                .add('div.racecard table.runners td.jockey span.jockey')
                .add('div.racecard table.runners td.jockey span.greyhound-trainer')
                .add('div.racecard table.runners td.jockey span.trainer')
                .add('div.racecard table.runners tbody td.odds.price div.trend a.odds-button')
                .add('div.racecard .content .notify-tag.rule4')
                .add('ul.final-odds li.multi-odds span.title')
                .add('td.horse-age span.weight')
                .add('div.racecard a.notes')
                .add('div.racecard .bonus-money-info')
                .add('.notify-tag')
                .each(function(idx, elem){
                     Tipped.remove(elem);
                });
        };

        // @public
        return {
            init: function() {
                // link to next race
                raceBetsJS.application.contentController.addDynamicPage({
                    urlPattern: /race\/next/,
                    dataSourceURLBuilder: function() {
                        var url = '/ajax/races/nextraceid';

                        if (raceBetsJS.browser.params.get('hasSp') !== false) {
                            url += '/?hasSp=1';
                        }

                        return url;
                    },
                    onDataReceived: function(data) {
                        raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/race/details/id/' + data.idRace);
                    }
                });

                // Setup route controller
                raceBetsJS.application.contentController.addDynamicPage({
                    urlPattern: /race\/details\/id\/(\d+)/,
                    //urlPattern:  /^[0-9]{4}-[0-9]{2}-[0-9]{2}\/[A-Za-z0-9-]+\/[A-Za-z0-9-]+\/r(\d+)\/?$|race\/details\/id\/(\d+)\/?$/,
                    // @todo: had to change urlPattern because it didn't load card as soon as I gave it another parameter
                    //urlPattern: /race\/details\/id\/(\d+)\/?$/,
                    dataSourceURLBuilder: function(idRace,idRaceOld) {
                        return raceBetsJS.application.content.getAjaxCacheUrl('/ajax/races/details/id/' + (idRace | idRaceOld))
                    },
                    onDataReceived: initCard,
                    onUnload: function() {

                        if(raceDetailsInterval){
                           raceDetailsInterval.stop();
                           raceDetailsInterval=null;
                        }

                        leaveSocketChannel();
                        removeAllTipped();

                        // Kill the intervals
                        favouritesInterval.stop();

                        // Cleanup Jackpot related variables and timers
                        if (jackpotInterval !== undefined) {
                            jackpotInterval.stop();
                        }
                        jackpotCurrent = undefined;

                        // Abort a running pollingRequest
                        if (pendingRequest) {
                            pendingRequest.abort();
                            pendingRequest = null;
                        }

                        // unsubscribe
                        $.each(subscriptions, function(index, subscription) {
                            $.unsubscribe(subscription);
                        });

                        // remove sticky footer listener of betslip
                        $(window).off('scroll.betslip');

                        // remove keyboard navigation
                        $(document).off('keydown.racecard');

                        raceBetsJS.application.assets.audioPreview.destroy();

                        card = {};
                        subscriptions = [];
                    }
                });
            },
            setMyBetsNum: setMyBetsNum,
            adjustWidth: adjustWidth,
            //getRule4Row: getRule4Row,
            getRule4Deductions: getRule4Deductions
        };
    }();
})(raceBetsJS);
