/**
* Media asset 2.5
* @author Robin Ebers
*/
(function(raceBetsJS) {
    raceBetsJS.application.assets.media = function() {
        // @private

        var data; // contains jsons, timers and other references / data
        var hlsPlayer;
        var popups = []; //  contains popup stream references
        var settings = {
            hideSelectTimeout: 1000, // default timeout before select slides up again
            intervalStreamPolling: 300000, // poll interval for stream json
            intervalRacesPolling: 60000, // poll interval for upcoming races json
            durationFading: 100, // duration of fade animations
            durationSliding: 400, // duration of slide animations
            stickyMedia: false, // default value of sticky media browser
            cookieName: 'rbcache', // media token cache cookie name
            debug: false, // log debug messages in console
            videoBonus: false, // show video bonus banner?
            forcePopup: false // force popup?
        };

        // --------------------------------------------------------------------------------
        // --------------------------------------------------------------------------------

        /**
        * show()
        * Show the media asset
        *
        * @param options
        */
        function show(options) {
            // redirect to popup mode if required
            if (options !== undefined && (raceBetsJS.application.assets.settings.get().dialog.general.popupStreams === true || options.forcePopup === true)) {
                openPopup(options);
                return;
            }

            // if animated, quit right here
            if ($('#content-media').is(':animated')) {
                return;
            }

            // if still hidden, construct asset
            if ($('#content-media').is(':hidden')) {
                construct(options);

                // fetch streams, then continue
                getMediaJson(function() {
                    data.dom.asset.removeClass('loading');

                    // populate streams to dom
                    populateStreams();

                    // if started with options, now publish player (if options given)
                    if (options !== undefined) {
                        publishPlayer(options);
                    }

                    // slide down the asset
                    data.dom.container.slideDown(settings.durationSliding, function() {
                        // fade in close button after slide animation finished
                        data.dom.closeButton = data.dom.asset.find('.close');
                        data.dom.closeButton.fadeIn(settings.durationFading).click(hide);

                        data.dom.asset.removeClass('with-options');
                        // data.dom.asset.css({'position': 'relative'});
                        adjustHeight();
                    });
                });

            } else {
                // if asset already visible, just publish the player (if options given)
                if (options !== undefined) {
                    publishPlayer(options);
                }
            }
        }



        /**
        * popupMode()
        * Show the player only version within popup
        *
        * @param options
        */
        function openPopup(options) {
            var height = options.height || 288;
            var width = (options.streamType == 'LIV' && options.idRace !== undefined && options.provider !== 'PFG') ? 383 : 512; // 4:3 or 16:9
            width = (options.streamType == 'LIV' && options.provider === 'PFG') ? 800 : width;
            height = (options.streamType == 'LIV' && options.provider === 'PFG') ? 584 : height;
            
            var popupIndex = popups.length;

            // special case for idChannel = 13, Sky Australia
            height = (options.idChannel == 13) ? 295 : height;

            // special case for EGT player
            if (options.provider == 'EGT') {
                height = 380;
                width = 500;
            }


            var popupName = 'raceBetsStream_'
                    + ((options.idRace !== undefined) ? options.idRace : options.idChannel)
                    + (($.inArray(options.streamType, ['FLV', 'MP4']) > -1) ? 'A' : '') // archive video

            popups[popupIndex] = options;

            raceBetsJS.application.assets.session.setCookie('media', popups);

            var popupOptions = {
                url: (options.href || '/media/popup/') + 'id/' + popupIndex + '/',
                name: popupName,
                height: height,
                width: width,
                scroll: 'no',
                resizable: (options.idChannel == 13 ? 'no' : 'yes')
            };

            debug('Creating popup', popupName, popupOptions, popupIndex);

            var popup = raceBetsJS.browser.popup.open(popupOptions);

            if (!raceBetsJS.application.globals.isPopup && data !== undefined) {
                hide();
            }
        }


        /**
        * initPopup()
        * When popup is loaded...
        */
        function initPopup() {

            var index = raceBetsJS.browser.params.get('id'),
                options = raceBetsJS.application.assets.session.getCookie('media')[index];

            debug('Options', index, options);

            // stream variable not available?
            if (options === undefined) {
                window.opener ? window.opener.raceBetsJS.application.assets.modalDialog.generic() : raceBetsJS.application.assets.modalDialog.generic();
                return;
            }

            // init basics
            construct(options);

            // get streams, publish player
            getMediaJson(function() {
                publishPlayer(options);
            });
        }

        /**
        * hide()
        * Hide the media asset
        */
        function hide() {
            // if animated, quit right here
            if ($('#content-media').is(':animated')) {
                return;
            }

            // reset stickyMedia
            settings.stickyMedia = false;

            // fadeout some elements for style reasons
            data.dom.toolbar.fadeOut(settings.durationFading);
            data.dom.closeButton.fadeOut(settings.durationFading);

            // remove bonus
            if (settings.videoBonus) {
                raceBetsJS.application.globals.contentContainer
                    .find('.icon-banner.video-bonus')
                    .slideUp(function() {
                        $(this).remove();
                    });
            }

            if (((hlsPlayer !== undefined) && !hlsPlayer.paused()) || (data.options && data.options.provider === 'PFG')) {
                onStop();
            }

            // slide up content media
            data.dom.container.slideUp(settings.durationSliding, function() {
                // remove player before removed from dom, important for some flash versions
                removePlayer();

                // destroy delegators and stuff
                destroy();

                data.dom.asset.find('div.streams').hide();
                data.dom.asset.removeClass('loading');
            });
        }

        /**
        * toggle()
        * Toggle the media asset
        *
        * @param options
        */
        function toggle(options) {
            if (data === undefined || data.dom.container.is(':hidden')) {
                show(options);
                $('#m-header__menu [data-key="streams"] .c-btn').addClass('isActive');
            } else {
                hide();
                $('#m-header__menu [data-key="streams"] .c-btn').removeClass('isActive');
            }
        }

        /**
        * construct()
        * stuff to do when the media asset is actually initialised
        */
        function construct(options) {
            // cache the content media reference
            data = {};
            data.dom = {};
            data.dom.container = $('#content-media');

            // init timer namespace
            data.timer = {};

            // generate and write template to dom
            if (!raceBetsJS.application.globals.isPopup) {
                data.dom.container.html(raceBetsJS.application.templates.media());
            }

            // get video container
            data.dom.video = data.dom.container.find('div.video');

            // get media browser reference
            data.dom.asset = $('#media-browser');
            data.dom.asset.addClass('loading default');

            if (options !== undefined) {
                data.dom.asset.addClass('with-options');
            }

            // necessary due to iOS bugs in combination with JW Player
            data.playerId = 'stream-content';

            // bonus banner
            settings.videoBonus = (raceBetsJS.browser.params.get('external') !== false && raceBetsJS.application.user.lang == 'de');
            if (settings.videoBonus && !raceBetsJS.application.globals.isPopup) {
                var $streamsPromotion = $( raceBetsJS.application.templates.media.banner() );
                $streamsPromotion
                    .hide()
                    .prependTo( raceBetsJS.application.globals.contentContainer )
                    .slideDown(function() {
                        $(this).find('.btn').on('click', function() {
                            document.location.href = $(this).data('href');
                        });
                    });
            }

            // if not popup, do further stuff
            if (!raceBetsJS.application.globals.isPopup) {
                // bind to scroll event
                if (raceBetsJS.application.globals.isB2B) {
                    $.subscribe('/scroll', relocateMediaAsset);
                    relocateMediaAsset();
                } else {
                    $(window).on('scroll', relocateMediaAsset);
                }

                // toolbar button delegator
                data.dom.toolbar = data.dom.asset.find('div.toolbar').first();
                data.dom.toolbar.on('click', 'span.icon.enabled', toolbarActions);
                data.dom.toolbar.on('mouseover', 'span.icon.enabled', toolbarHints);

                // cache toolbar buttons
                data.dom.toolbar.buttons = {};
                $.each(['play', 'quality', 'sticky', 'popup'], function() {
                    data.dom.toolbar.buttons[this] = data.dom.toolbar.children('span.icon[data-action="' + this + '"]');
                });

                // more dom references caching
                data.dom.races = data.dom.asset.find('div.upcoming-races');
                data.dom.results = data.dom.asset.find('div.race-results');
                data.dom.streams = data.dom.asset.find('div.streams');
                data.dom.streams.select = data.dom.streams.children('div.select');

                // stream ajax polling
                data.timer.streams = new raceBetsJS.time.Interval({
                    interval: settings.intervalStreamPolling,
                    tick: function() {
                        getMediaJson(populateStreams);
                    }
                });
                data.timer.streams.start();

                // init stream select, delegators and sliding etc
                initStreamSelect();
            }
        }

        /**
        * destroy()
        * stuff to do when the media asset is destroyed
        */
        function destroy() {
            // stop getting current streams
            if (data.timer.streams !== undefined) {
                data.timer.streams.stop();
            }

            // stop getting current races
            if (data.timer.races !== undefined) {
                data.timer.races.stop();
            }

            // remove player if existing
            removePlayer();

            // reset select
            toggleStreamItem();

            // disable toolbar delegator
            data.dom.asset.off('click', 'div.toolbar span.icon.enabled', toolbarActions);

            // disable sticky scrolling
            if (raceBetsJS.application.globals.isB2B) {
                $.unsubscribe(['/scroll']);
            } else {
                $(window).off('scroll', relocateMediaAsset);
            }
        }

        // --------------------------------------------------------------------------------
        // --------------------------------------------------------------------------------

        /**
        * getMediaJson()
        * Retrieves the media asset JSON, containing streams, country management etc
        * The callback function will be triggered after every successful AJAX request
        *
        * @param callback
        */
        function getMediaJson(callback) {
            $.ajax({
                type: 'get',
                url: raceBetsJS.application.content.getAjaxCacheUrl('/ajax/media/streams/'),
                data: {polling: false},
                success: function(result) {
                    data.streams = result.channels;
                    debug('Fetched latest streams JSON: ', result);
                },
                complete: function() {
                    // callback function
                    if (callback !== undefined) {
                        callback();
                    }
                }
            });
        }

        /**
        * populateStreams()
        * Populate current and upcoming streams to the asset
        */
        function populateStreams() {
            // clear existing elements
            data.dom.streams.children('ul.options').empty();

            // get stream label
            var label = data.dom.streams.children('span.label');

            // current and upcoming stream container
            var currentStreams = $('<div>').addClass('stream-container current').append(
                $('<li>').addClass('header').html(raceBetsJS.i18n.data.headLiveStreams)
            );
            var upcomingStreams = $('<div>').addClass('stream-container upcoming').append(
                $('<li>').addClass('header').html(raceBetsJS.i18n.data.headUpcomingStreams)
            );

            // actually populate streams
            $(data.streams).each(function() {
                var stream = this;

                // hide dogs-only channels if filtered out
                if (stream.raceTypes && stream.raceTypes.length === 1 && stream.raceTypes[0] === 'D'
                    && raceBetsJS.application.assets.settings.get().dialog.general.hideDogs) {
                    return;
                }

                // check if the stream is allowed based on user country
                if (!raceBetsJS.media.isCountryAllowed(raceBetsJS.application.user.mediaCountry, stream.filterCountries, stream.filterType)) {
                    data.dom.streams.select.children('.label').html(raceBetsJS.i18n.data.labelNoStreamAvailable);
                    return;
                }

                // check if device is not supported
                if (raceBetsJS.application.assets.media.isUnsupportedChannel(stream.idChannel)) {
                    return;
                }

                // if stream is limited to one odds format, hide it if it doesn't match user odds format (only support fractional and base1 and default to base1)
                if (stream.oddsFormat && (['fractional', 'base1'].indexOf(stream.oddsFormat) > -1)) {
                    var userFormat = raceBetsJS.application.assets.settings.get().dialog.general.oddsFormat == 'fractional' ? 'fractional' : 'base1';
                    if (userFormat !== stream.oddsFormat) {
                        return;
                    }
                }

                // create required dom elements
                var li = $('<li>').attr({ 'data-country': stream.country, 'data-id-channel': stream.idChannel, 'data-id-race': stream.idRace, 'data-provider': stream.provider });
                li.addClass('stream ' + (isAudioStream(stream) ? 'audio' : ''));

                var flag = $('<span>').addClass('c-flag isCountry' + stream.country);
                var country = $('<span>').addClass('country').text(raceBetsJS.i18n.data.countries[stream.country]);
                var tracks = $('<span>').addClass('tracks').html(stream.tracks);

                // sort streams by current and upcoming
                var now = Math.round($.now() / 1000);
                if (now >= stream.startTime && now < stream.endTime || settings.debug === true) {
                    currentStreams.append(li.append(flag, country, tracks));
                } else {
                    upcomingStreams.append(li.append(flag, country, tracks));
                }
            });

            // insert stream headers
            data.dom.streams.children('ul.options').append(currentStreams, upcomingStreams);
            data.dom.streams.find('ul.options li:last').addClass('last');

            // hide empty stream headers
            hideEmptyStreamHeaders();

            // keep current selection
            if (data.stream) {
                toggleStreamItem(data.stream.idChannel, data.stream.country);
            }
        }

        /**
        * getRacesJson()
        * Fetches upcoming races on the current channel
        *
        * @param callback
        */
        function getRacesJson(callback) {
            $.ajax({
                type: 'get',
                url: raceBetsJS.application.content.getAjaxCacheUrl('/ajax/media/races/id/' + data.stream.idChannel + '/'),
                data: {polling: true},
                success: function(result) {
                    data.stream.races = result.races;
                    debug('Fetched upcoming races for idChannel: ' + data.stream.idChannel, result);
                },
                complete: function() {
                    // callback
                    if (callback !== undefined) {
                        callback();
                    }
                }
            });
        }

        /**
        * populateRaces()
        * Populate upcoming races to DOM
        */
        function populateRaces() {
            // only show if races are available
            if (data.stream.races.length === 0) {
                data.dom.races.hide();
                return;
            }

            // delete races
            data.dom.races.children('ul').remove();

            // generate list
            var races = $('<ul>');
            $(data.stream.races).each(function() {
                var item = $('<li>').append(
                    $('<a>')
                        .attr('href', raceBetsJS.application.globals.urlPrefix + '/race/details/id/' + this.idRace + '/')
                        .addClass('ajaxify')
                        .append($('<span>').addClass('race-number status-' + this.raceStatus).text(this.raceNumber))
                        .append($('<span>').addClass('course').text(this.title))
                        .append($('<span>').addClass('details small light-grey').text(
                            raceBetsJS.i18n.print('timeLong', {
                                time: raceBetsJS.application.assets.timezone.date('H:i', this.postTime)
                            }) + ' | ' +
                            raceBetsJS.i18n.print('numStarters', {
                                numStarters: this.numStarters
                            })
                        ))
                );

                races.append(item);
            });

            // insert races
            data.dom.races.append(races);

            // set "last" class
            data.dom.races.find('ul li:last').addClass('last');
            data.dom.races.show();
        }

        /**
        * getResultsJson()
        * Fetches archive results for current archive video
        *
        * @param callback
        */
        function getResultsJson(callback) {
            $.ajax({
                type: 'get',
                url: '/ajax/media/results/id/' + data.stream.idRace,
                data: {polling: true},
                success: function(result) {
                    data.stream.results = result;
                    debug('Fetched results for idRace: ' + data.stream.idRace, result);
                },
                complete: function() {
                    // callback
                    if (callback !== undefined) {
                        callback();
                    }
                }
            });
        }

        /**
        * populateResults()
        * Populate race results to DOM
        */
        function populateResults() {
            // only show if results are available
            if (data.stream.results.runners.length === 0) {
                data.dom.results.hide();
                return;
            }

            // show replacement silks if no silk is available for the following countries
            var replacementSilks = ['AE', 'DE', 'GB', 'IE', 'IT', 'MO', 'NZ', 'SG', 'ZA'];

            // delete results
            data.dom.results.children('ul').remove();

            // generate list
            var results = $('<ul>').addClass((data.stream.results.hasSilks) ? 'has-silks' : '');

            $(data.stream.results.runners).each(function() {
                var item = $('<li>')
                    .append($('<span>').addClass('place').text(this.finalPosition))
                    .append(
                        $('<span>').addClass('runner').append(
                            (data.stream.results.hasSilks) ?
                                $('<img class="silk" />').attr({
                                    src: raceBetsJS.application.globals.imageHost +
                                            (!this.silkExtension ?
                                                '/images/cards/silks/' + (($.inArray(data.stream.country, replacementSilks)) ? data.stream.country.toLowerCase() : 'slw') + '.png' :
                                                '/cache/img/silks/' + this.silkId + '_w42.' + this.silkExtension
                                            ),
                                    width: 21
                                }) :
                                ''
                        ).append($('<span>').addClass('name').text(this.name))
                            .append($('<span>').addClass('light-grey').text('(' + this.programNumber + ')')
                        )
                    );

                results.append(item);
            });

            // insert races
            data.dom.results.append(results);

            // set "last" class
            data.dom.results.find('ul li:last').addClass('last');
            data.dom.results.show();
        }

        function getArchiveStreamURL(url) {
            var streamPath = url.split('/');

            return 'https://vod.racebets.com/' +
                streamPath[2] + '/' +
                streamPath[3].substr(0, 4) + '/' + streamPath[3].substr(4, 2) + '/' + streamPath[3].substr(6) + '/' +
                streamPath[4] + '/' + streamPath[5];
        }

        // --------------------------------------------------------------------------------
        // --------------------------------------------------------------------------------

        /**
        * publishPlayer()
        * Checks what player is the right choice, requests a token and calls the actual player publish function
        *
        * @param options
        */
        function publishPlayer(options) {
            if (options.streamType == 'LIV' && !raceBetsJS.application.user.loggedIn) {
                raceBetsJS.application.assets.dialogs.loginDialog.show();
                /*
                raceBetsJS.application.assets.modalDialog.show({
                    type: 'attention',
                    content: raceBetsJS.i18n.data.msgLoginRequired
                });
                */

                return;
            }

            // remove previous player
            removePlayer();

            if (options.streamType == 'LIV' && options.country !== undefined && options.idChannel !== undefined) {
                toggleStreamItem(options.idChannel, options.country);
            } else {
                toggleStreamItem();
            }

            // cache latest options for popout-toolbar button
            data.options = options;

            // hardcode archive hack for cobain
            options.idChannel = options.idChannel ? parseInt(options.idChannel, 10) : false;
            if ($.inArray(options.idChannel, [16, 17]) > -1 && options.url !== undefined) {
                switch (options.idChannel) {
                    case 16:
                        options.idChannel = 1;
                        break;
                    case 17:
                        options.idChannel = 15;
                        break;
                }
            }

            debug('Publish player with options:', options);

            // hotfix MNT-2383
            if (options.idChannel === 18) {
                debug('Wowza: Stream without token');

                // set current stream to live stream or archive video
                data.stream = (options.streamType === 'LIV') ?
                                    getStream(options.idChannel, options.country) :
                                    options;

                // extend with options (for streamType i.e.)
                data.stream = $.extend(data.stream, options);

                // no token required but call getToken to handle errors like "no turnover"
                getToken(data.stream.idChannel, data.stream.streamType, raceBetsJS.browser.params.get('external'), function(result) {
                    if (result.type !== undefined && result.type === 'error') {
                        handleErrors(result);
                        return;
                    }

                    debug('Wowza Player: Stream does not require token', result, data.stream);

                    // just publish this player
                    publishPlayerWowza();
                });

            } else if (options.provider === 'PFG') {
                data.stream = $.extend(data.stream, options);
                
                if (options.streamType === 'LIV') {
                    preparePlayerPFG(function () {
                        data.timer.races = new raceBetsJS.time.Interval({
                            interval: settings.intervalRacesPolling,
                            tick: function () {
                                getRacesJson(populateRaces);
                            }
                        });
                        data.timer.races.start();
                        getRacesJson(populateRaces);
                    });
                } else {
                    getStreamUrl(options, publishPlayerHLS);
                }
            } else if (options.streamType === 'MP3' || options.streamType === 'MP4' && options.provider !== 'ATR') {
                data.stream = getStream(options.idChannel, options.country);
                data.stream = $.extend(data.stream, options);
                data.stream.url = getArchiveStreamURL(data.stream.url);

                publishPlayerHLS();

            } else if (['HEX', 'SKY', 'SISTV', 'YOUTUBE'].indexOf(options.provider) > -1 && options.streamType === 'LIV') {

                data.stream = getStream(options.idChannel, options.country);
                data.stream = $.extend(data.stream, options);

                getStreamUrl(data.stream, function(result) {
                    if(options.provider === 'SISTV' && typeof RTCPeerConnection === 'function' && result.stream_player) {
                        publishPlayerIframe()
                    } else {
                        publishPlayerHLS();
                    }

                    data.timer.races = new raceBetsJS.time.Interval({
                        interval: settings.intervalRacesPolling,
                        tick: function() {
                            getRacesJson(populateRaces);
                        }
                    });
                    data.timer.races.start();
                    getRacesJson(populateRaces);
                });

            //'ATR', archive stream
            } else if(['ATR'].indexOf(options.provider) > -1) {
                data.stream = $.extend(data.stream, options);
                getStreamUrl(options, function(result) {
                    publishPlayerHLS();
                });

            // otherwise we need to open an EGT stream (IFRAME)
            } else if (options.provider === 'EGT') {
                data.stream = $.extend(data.stream, options);

                getStreamUrl(data.stream, function (result) {
                    if (result.stream_player) {
                        publishPlayerIframe();
                    } else {
                        publishPlayerHLS();
                    }


                    if (options.streamType === 'LIV') {
                        data.timer.races = new raceBetsJS.time.Interval({
                            interval: settings.intervalRacesPolling,
                            tick: function () {
                                getRacesJson(populateRaces);
                            }
                        });
                        data.timer.races.start();
                        getRacesJson(populateRaces);
                    }
                });

            // if live stream (with no idRace) or if archive URL is defined, take JW/WMV Player logic
            } else if ((options.streamType == 'LIV' && options.idChannel != 13) || options.url !== undefined) {

                // set current stream to live stream or archive video
                data.stream = (options.streamType == 'LIV') ?
                    getStream(options.idChannel, options.country) : options;
                data.stream = $.extend(data.stream, options); // extend with options (for streamType i.e.)

                // SWF stream requiring a token (TV1 + archive videos)
                if (data.stream.provider == 'TV1' || options.url !== undefined) {
                    getToken(options.idChannel, options.streamType, raceBetsJS.browser.params.get('external'), function(result) {
                        if (result.type !== undefined && result.type == 'error') {
                            handleErrors(result);
                            return;
                        }

                        debug('JW Player: Stream requires a token, acquired it', result, data.stream);

                        // set default media resource if live stream
                        if (options.streamType == 'LIV') {
                            setMediaResource();
                        }

                        // publish the actual JW Player
                        publishPlayerSWF();
                    });

                // JW Player stream without token
                } else if (options.idChannel == 9 || options.idChannel == 11 || options.idChannel == 18) {
                    debug('JW Player: Stream without token', data.stream);

                    // set default media resource if live stream
                    if (options.streamType == 'LIV') {
                        setMediaResource();
                    }

                    publishPlayerSWF();

                } else if (data.stream.provider === 'CBN') {
                     // get stream and extend with options (for streamType i.e.)
                    data.stream = $.extend(getStream(options.idChannel, options.country), options);

                    // set media resource
                    setMediaResource();

                    // get cobain token
                    getCobainUrl(options.idChannel, options.streamType, raceBetsJS.browser.params.get('external'), data.stream.resource.file, function(result) {
                        if (result.type !== undefined && result.type == 'error') {
                            handleErrors(result);
                            return;
                        }

                        debug('Cobain: Stream URL acquired', result, data.stream);

                        // publish cobain player
                        publishPlayerCBN();
                    });
                }

            } else if (options.idChannel === 13) {
                debug('Kickstatic: Stream without token (<iframe>)');

                // set current stream to live stream or archive video
                data.stream = (options.streamType === 'LIV') ?
                                    getStream(options.idChannel, options.country) :
                                    options;

                // extend with options (for streamType i.e.)
                data.stream = $.extend(data.stream, options);

                // no token required but call getToken to handle errors like "no turnover"
                getStreamUrl(data.stream, function () {
                    debug('Kickstatic Player: Stream does not require token', data.stream);
                    // just publish this player
                    publishPlayerKCK();
                });

            } else {
                debug('CRITICAL: No appropriate player found to play selected stream!');

            }

            // malfunction information
            malfunction(data.stream !== undefined && data.stream.malfunction !== undefined
                && data.stream.malfunction == '1');
        }

        function preparePlayerPFG (callback) {

            getStreamUrl(data.stream, function(result) {
                var parent = document.getElementById('stream-content');
                var script = document.createElement('script');

                while (parent.hasChildNodes()) {
                    parent.firstChild.remove()
                }

                script.src = '//player.performgroup.com/csb.js';

                script.dataset.uuid = result.fixture_id;
                script.dataset.outletkey = result.outlet_key;
                script.dataset.apptype = 'csb';
                script.dataset.env = 'betting';
                script.dataset.streamuuid = result.asset_id;
                script.dataset.streamuser = result.username;
                script.dataset.oauthtoken = result.auth_token;
                script.dataset.width = raceBetsJS.application.globals.isPopup ? 800 : '512';
                script.dataset.rmg = raceBetsJS.application.globals.isPopup ? 'true' : 'false';
                script.dataset.headerdisabled = raceBetsJS.application.globals.isPopup ? 'false' : 'true';
                script.dataset.footerdisabled = raceBetsJS.application.globals.isPopup ? 'false' : 'true';

                data.dom.asset.removeClass('default').addClass('mode-script');

                parent.appendChild(script);    
                
                toggleButton('sticky', 'enabled', true);
                toggleButton('popup', 'enabled', true);
                callback();
            });
        }

        function publishPlayerIframe() {
            // publish iframe
            var video = $('<iframe src="'+ data.stream.iframe +'" id="stream-iframe" allowfullscreen></iframe>');
            $('#stream-content').html('').append(video);
            data.dom.asset.removeClass('default').addClass('mode-iframe');

            if (!raceBetsJS.application.globals.isPopup) {
                // enable toolbar buttons
                toggleButton('sticky', 'enabled', true);
                toggleButton('popup', 'enabled', true);
            }
        }

        /**
        * publishPlayerHLS()
        * Creates a Videojs Player instance based on the given options
        */
        function publishPlayerHLS() {

            function getType(streamType) {
                switch (streamType) {
                    case 'LIV':
                        return 'application/x-mpegURL';
                    case 'MP4':
                        return 'video/mp4';
                    case 'MP3':
                        return 'audio/mp3';
                    default:
                        debug('Unknown media type');
                }
            }

            function getMediaType(provider, streamType) {
                if(provider === 'ATR') return 'application/x-mpegURL'
                return getType(streamType);
            }

            function buildYoutubePlayer(url) {
                $('#stream-content').html(raceBetsJS.application.templates.media.html5());
                $('#stream-content video').attr('data-setup', '{ "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "' + url + '"}]}');
                hlsPlayer = videojs('videojs');
                hlsPlayer.on('pause', onStop);
            }

            function buildPlayer(url, idChannel) {
                $('#stream-content').html(raceBetsJS.application.templates.media.html5());
                $('#videojs source').attr('src', url);
                $('#videojs source').attr('type', getMediaType(data.stream.provider, data.stream.streamType));

                // disable contextmenu for videojs player (right click)
                $('#videojs').bind('contextmenu', function() {
                    return false;
                });

                if (raceBetsJS.application.globals.brandName === 'racebets' && data.stream.resources !== undefined && data.stream.resources[0] && data.stream.resources[0].technology == 'MP3') {
                    $('#videojs').attr('poster', "/images/media/bg-audio-stream-de.jpg");
                }

                hlsPlayer = videojs('videojs', { muted: idChannel === 33 ? true : false });

                if (data.stream.streamType !== 'LIV') {
                    hlsPlayer.controlBar.liveDisplay.contentEl_.hidden = true;
                }

                hlsPlayer.on('pause', onStop);
            };

            if (!document.getElementById('video-js-css')) {
                $('head').append('<link id="video-js-css" rel="stylesheet" href="/scripts/lib/video-js/video-js-7.css" type="text/css" />');
            }

            $('div#media-browser div.video').empty().html('<div id="stream-content"></div>');

            data.dom.asset.removeClass('default').addClass('mode-hls');
            toggleButton('sticky', 'enabled', true);
            toggleButton('popup', 'enabled', true);

            if (data.stream.provider === 'YOUTUBE') {
                buildYoutubePlayer(data.stream.iframe);
            } else {
                buildPlayer(data.stream.url, data.stream.idChannel);
            }

        };

        /**
        * publishPlayerSWF()
        * Creates a JW Player instance based on the given options
        */
        function publishPlayerSWF() {

            // change layout
            data.dom.asset.removeClass('default').addClass('mode-swf');

            // timer related stuff in full-mode
            if (!raceBetsJS.application.globals.isPopup) {
                // if streamType is not LIV, it must be an archive stream
                if (data.stream.streamType !== 'LIV') {
                    // archive video
                    debug('JW Player: Archive video found, get result JSON and start streaming');

                    // get results of video
                    getResultsJson(populateResults);

                    // toggle button states
                    toggleButton('play', 'enabled', true);
                    toggleButton('quality', 'enabled', false);
                    toggleButton('sticky', 'enabled', true);
                    toggleButton('popup', 'enabled', true);

                } else {
                    // live streaming
                    debug('JW Player: Live-Stream found, get upcoming races JSON and start streaming');

                    // create polling for races on this channel
                    data.timer.races = new raceBetsJS.time.Interval({
                        interval: settings.intervalRacesPolling,
                        tick: function() {
                            getRacesJson(populateRaces);
                        }
                    });
                    data.timer.races.start();
                    getRacesJson(populateRaces); // do it once now

                    // toggle button states
                    toggleButton('play', 'enabled', true);
                    toggleButton('quality', 'enabled', (data.stream.resources.length > 1));
                    toggleButton('sticky', 'enabled', true);
                    toggleButton('popup', 'enabled', true);
                }
            }

            // ----------------------------------------------------------------------------------------
            // player setup, create options for all JW player streams, like logo, streamer, plugins etc
            // ----------------------------------------------------------------------------------------

            // streamer & co.
            var rtmpSubscribe = false;
            var playlist = [];

            var url = (data.stream.provider == 'TV1' && data.stream.streamType == 'LIV')
                ? data.stream.folder + data.stream.resource.file
                : (data.stream.streamType == 'LIV') ? data.stream.resource.file : data.stream.url;

            if (data.stream.provider == 'TV1' || data.stream.url !== undefined) {
                var useAwsArchive = function(url) {
                    if (!url) {
                        return false;
                    }
                    url = url.split('/');
                    var diffDays,
                        raceDate = new Date(url[3].substr(0,4)+'-'+url[3].substr(4,2)+'-'+url[3].substr(6)),
                        currentDate = new Date();

                    diffDays = Math.floor(Math.abs(raceDate.getTime() - currentDate.getTime())/(24*60*60*1000));

                    return diffDays > 2;
                };

                if (data.stream.streamType !== 'LIV' && useAwsArchive(data.stream.url) || data.stream.provider === 'HEX') {
                    // push stream
                    playlist.push({
                        file: getArchiveStreamURL(data.stream.url),
                        type: 'mp4'
                    });
                } else {
                    // push stream
                    playlist.push({
                        file: 'rtmp://xapp990314496c27014.s.' + (data.stream.streamType == 'LIV' ? 'l' : 'o')
                            + '.f.lb.core-cdn.net/' + data.stream.token + '/27014rci/flv:' + url
                    });

                    // create base URL for html5 and rtsp links
                    var urlNative = 'xapp990314496c27014.f.' + (data.stream.streamType == 'LIV' ? 'l' : 'o')
                                  + '.z.lb.core-cdn.net/' + (data.stream.streamType == 'LIV' ? '10049xrci' : '10062xrciod')
                                  + '/_definst_/27014rci/' + url;


                    // push HTML5 stream
                    if (url.match(/mp4$/) || (data.stream.streamType == 'LIV' && data.stream.provider == 'TV1')) {
                        playlist.push({
                            file: 'http://' + urlNative + '/playlist.m3u8?key='
                                + ((data.stream.tokenHtml5 !== undefined) ? data.stream.tokenHtml5 : data.stream.token),
                            type: 'hls'
                        });
                    }

                }

            } else if (data.stream.provider == 'DGB') {
                playlist.push({ file: 'rtmp://digibet.fc.llnwd.net/digibet/flv:karlshorst_low' });

            } else if (data.stream.provider == 'SLW') {
                playlist.push({ file: 'rtmp://80.94.54.40/redirect/flv:zavodisko' });

            } else if (data.stream.provider == 'INS') {
                playlist.push(
                    { file: 'rtmp://insinceu.fc.llnwd.net/insinceu/170mag/flv:mag1' },
                    { file: 'http://l2170.insinceurope.net/live/mag1.stream/playlist.m3u8', type: 'hls' }
                );

                rtmpSubscribe = true;
            }

            // build options object
            options = {
                flashplayer: '/scripts/lib/jwplayer/jwplayer.flash.swf?v=722',
                // html5player: raceBetsJS.application.globals.imageHost + '/scripts/lib/jwplayer/jwplayer.html5.js', // deprecated
                playlist: [{
                    sources: playlist
                }],
                skin: {
                    name: 'seven',
                    active: raceBetsJS.application.assets.settings.getBrandSettings('streamColor')
                },
                ga: {
                    idstring: (data.stream.name !== undefined) ? escape(data.stream.name) : ''
                },
                width: '100%',
                height: 288,
                // stretching: 'uniform', // default
                //abouttext: 'RaceBets.com', // premium feature
                //aboutlink: 'https://www.racebets.com/', // premium feature
                primary: 'flash',
                autostart: true,
                // fallback: false, // deprecated
                rtmp: {
                    subscribe: rtmpSubscribe
                }
            };

            // ----------------------------------------------
            // end of options, now actually create the player
            // ----------------------------------------------

            data.playerId = raceBetsJS.string.uniqueId();
            var newStreamContent = $('<div id="' + data.playerId + '"></div>');

            // add content
            data.dom.video.addClass('has-stream').html('').append(newStreamContent);

            // create RTSP fallback for android devices
            if (typeof urlNative !== 'undefined' && raceBetsJS.browser.type.isAndroid()) {
                newStreamContent.html('<a class="fallback rtsp" href="rtsp://' + urlNative + '?key=' + data.stream.token + '"></a>')
            }

            // create player
            debug('JW Player: Creating with options', options, data.playerId);
            jwplayer(data.playerId).setup(options);

            // listen to player events
            jwplayer(data.playerId).on('play', onJwPlayerPlay);
            jwplayer(data.playerId).on('buffer', onJwPlayerPlay);
            jwplayer(data.playerId).on('pause', onJwPlayerStop);
            jwplayer(data.playerId).on('idle', onJwPlayerStop);
        }

        /**
        * publishPlayerEGT()
        * Creates an <IFRAME> containing the live stream provided by EGT (mostly USA)
        */
        function publishPlayerEGT() {
            // if previous race polling was active, stop it
            if (data.timer.races !== undefined) {
                data.timer.races.stop(); // in case there was another one running already, stop it
            }

            // timer related stuff in full-mode
            if (!raceBetsJS.application.globals.isPopup) {
                // if streamType is not LIV, it must be an archive stream
                debug('Easygate: Live-Stream found, get upcoming races JSON and start streaming');

                // create polling for races on this channel
                data.timer.races = new raceBetsJS.time.Interval({
                    interval: settings.intervalRacesPolling,
                    tick: function() {
                        getRacesJson(populateRaces);
                    }
                });
                data.timer.races.start();
                getRacesJson(populateRaces); // do it once now

                // toggle button states
                toggleButton('sticky', 'enabled', true);
                toggleButton('popup', 'enabled', true);
            }

            // change layout
            data.dom.asset.removeClass('default').addClass('mode-egt');

            // publish iframe
            var link = data.stream.url;
            var video = $('<iframe>').attr('src', link).attr('id', 'stream-egt-iframe').attr('scrolling', 'no');
            $('#' + data.playerId).html('').append(video);

            if (!raceBetsJS.application.globals.isPopup) {
                // enable toolbar buttons
                toggleButton('sticky', 'enabled', true);
                toggleButton('popup', 'enabled', true);
            }
        }

        /**
        * publishPlayerWMV()
        * Creates a JW WMV Player instance based on the given options
        */
        function publishPlayerWMV() {
            data.dom.asset.removeClass('default').addClass('mode-wmv');
            debug('WMV stream not implemented yet', data);

            // @todo: NOT YET REFACTORED YET!
            // TO BE TESTED WHEN WMV STREAM IS AVAILABLE!
            /*
            var config = {
                width: '100%',
                height: '100%',
                autostart: 'false', // start when event listeners are there
                showdigits: 'false',
                windowless: 'true',
                file: 'mms://a1979.l12278653874.c122786.e.lm.akamaistream.net/D/1979/122786/v0001/reflector:53874'
            };
            wmvPlayer = new jeroenwijering.Player(document.getElementById('stream-content'),
                '/scripts/lib/wmvplayer/wmvplayer.xaml', config);

            // listen to player events
            addListeners();

            // Note that the assigning of listeners during page load will likely fail, because the XAML
            // has to be loaded yet. In order to fix that, add a small timeout to the listeners.
            function addListeners() {
                if(wmvPlayer.view) {
                    wmvPlayer.addListener('STATE', function(oldState, newState) {
                        if (newState == 'Playing') {
                            togglePlayButton('pause');

                        } else if (newState == 'Paused' || newState == 'Completed') {
                            togglePlayButton('play');
                        }
                    });

                    wmvPlayer.sendEvent('PLAY');
                } else {
                    setTimeout(addListeners, 100);
                }
            };
            */
        }

        /**
        * publishPlayerKCK()
        * Creates a Kickstatic player instance based on given options
        */
        function publishPlayerKCK() {
            // if previous race polling was active, stop it
            if (data.timer.races !== undefined) {
                data.timer.races.stop(); // in case there was another one running already, stop it
            }

            // timer related stuff in full-mode
            if (!raceBetsJS.application.globals.isPopup) {
                // if streamType is not LIV, it must be an archive stream
                debug('Kickstatic: Live-Stream found, get upcoming races JSON and start streaming');

                // create polling for races on this channel
                data.timer.races = new raceBetsJS.time.Interval({
                    interval: settings.intervalRacesPolling,
                    tick: function() {
                        getRacesJson(populateRaces);
                    }
                });

                data.timer.races.start();
                getRacesJson(populateRaces); // do it once now

                // toggle button states
                toggleButton('sticky', 'enabled', true);
                toggleButton('popup', 'enabled', true);
            }

            // change layout
            data.dom.asset.removeClass('default').addClass('mode-kck');

            // publish player
            $('#' + data.playerId).html(
                '<iframe id="stream-kck-iframe" width="512" height="293" src="//'+window.location.host+'/scripts/lib/sky-australia/index.html" frameborder="0" style="width:512px;height:293px;"></iframe>'
            );

            if (!raceBetsJS.application.globals.isPopup) {
                // enable toolbar buttons
                toggleButton('sticky', 'enabled', true);
                toggleButton('popup', 'enabled', true);
            }
        }

        /**
        * publishPlayerWowza()
        * Includes the Wowza Player
        */
        function publishPlayerWowza() {
            // if previous race polling was active, stop it
            if (data.timer.races !== undefined) {
                data.timer.races.stop(); // in case there was another one running already, stop it
            }

            // timer related stuff in full-mode
            if (!raceBetsJS.application.globals.isPopup) {
                // if streamType is not LIV, it must be an archive stream
                debug('Wowza: Live-Stream found, get upcoming races JSON and start streaming');

                // create polling for races on this channel
                data.timer.races = new raceBetsJS.time.Interval({
                    interval: settings.intervalRacesPolling,
                    tick: function() {
                        getRacesJson(populateRaces);
                    }
                });

                data.timer.races.start();
                getRacesJson(populateRaces); // do it once now

                // toggle button states
                toggleButton('sticky', 'enabled', true);
                toggleButton('popup', 'enabled', true);
            }

            // change layout
            data.dom.asset.removeClass('default').addClass('mode-egt').addClass('mode-wowza');

            // publish mobile or desktop iframe
            var videoDiv = $('<div>').attr('id', 'wowza_player');
            var videoScript = $('<script>').attr('id', 'player_embed')
                .attr('src', '//player.cloud.wowza.com/hosted/mghmv08r/wowza.js')
                .attr('type', 'text/javascript');

            //$('#' + data.playerId).html('').append(videoDiv);
            //$('#' + data.playerId).append(videoScript);

            $('#' + data.playerId).attr('style', 'background: white; height: 100%; padding: 20px; font-size: 12px;');
            $('#' + data.playerId).html('Wegen technischer Probleme finden Sie den deutschen Stream heute unter '
                + '<a href="https://player.cloud.wowza.com/hosted/mghmv08r/player.html" target="_blank">https://player.cloud.wowza.com/hosted/mghmv08r/player.html</a>'
                + '<br><br>'
                + 'For technical reasons you find the German stream today at '
                + '<a href="https://player.cloud.wowza.com/hosted/mghmv08r/player.html" target="_blank">https://player.cloud.wowza.com/hosted/mghmv08r/player.html</a>');

            if (!raceBetsJS.application.globals.isPopup) {
                // enable toolbar buttons
                toggleButton('sticky', 'enabled', true);
                toggleButton('popup', 'enabled', true);
            }
        }

        /**
        * publishPlayerCBN()
        * Creates a Cobain/JW Player instance based on the given options for COBAIN
        */
        function publishPlayerCBN() {
            // timer related stuff in full-mode
            if (!raceBetsJS.application.globals.isPopup) {
                // live streaming
                debug('Cobain: Live-Stream found, get upcoming races');

                // create polling for races on this channel
                data.timer.races = new raceBetsJS.time.Interval({
                    interval: settings.intervalRacesPolling,
                    tick: function() {
                        getRacesJson(populateRaces);
                    }
                });
                data.timer.races.start();
                getRacesJson(populateRaces); // do it once now

                // toggle button states
                toggleButton('play', 'enabled', true);
                toggleButton('quality', 'enabled', (data.stream.resources.length > 1));
                toggleButton('sticky', 'enabled', true);
                toggleButton('popup', 'enabled', true);
            }

            // ----------------------------------------------------------------------------------------
            // player setup, create options for all cobain streams, like logo, streamer, plugins etc
            // ----------------------------------------------------------------------------------------

            data.playerId = raceBetsJS.string.uniqueId();
            var newStreamContent = $('<div id="' + data.playerId + '"></div>');

            // add content
            data.dom.video.addClass('has-stream').html('').append(newStreamContent);

            // create RTSP fallback for android devices
            if (raceBetsJS.browser.type.isAndroid()) {
                newStreamContent.html('<a class="fallback rtsp" href="' + data.stream.cobainUrl + '"></a>')
                return;
            }

            // create options
            var options = {
                logo: {
                    file: '/images/media/logo-stream.png',
                    position: 'top-right',
                    out: 0.8,
                    timeout: 10
                },
                file: data.stream.cobainUrl,
                aspectratio: '16:9',
                primary: 'flash',
                ga: {
                    idstring: (data.stream.name !== undefined) ? escape(data.stream.name) : ''
                },
                height: '100%',
                width: '100%',
                stretching: 'uniform',
                abouttext: 'RaceBets.com',
                aboutlink: 'https://www.racebets.com/',
                autostart: true,
                rtmp: {
                    subscribe: true
                }
            };

            // ----------------------------------------------
            // end of options, now actually create the player
            // ----------------------------------------------

            debug('Cobain: Creating with options', options, data.playerId);
            jwplayer(data.playerId).setup(options);

            // listen to player events
            jwplayer(data.playerId).on('play', onJwPlayerPlay);
            jwplayer(data.playerId).on('buffer', onJwPlayerPlay);
            jwplayer(data.playerId).on('pause', onJwPlayerStop);
            jwplayer(data.playerId).on('idle', onJwPlayerStop);
        }

        /**
        * removePlayer()
        * Removes player based on current player mode
        */
        function removePlayer() {
            if (raceBetsJS.application.globals.isPopup) {
                return;
            }

            removePlayerKCK();
            removePlayerEGT();
            removePlayerSWF();
            removePlayerWowza();
            removePlayerHLS();
            removePlayerIFrame();
            removePlayerPFG();

            // disable toolbar
            $.each(['play', 'quality', 'sticky', 'popup'], function() {
                toggleButton(this, 'enabled', false);
                toggleButton(this, 'active', false);
            });

            // reset stream select
            toggleStreamItem();

            // stop getting current races
            if (data.timer.races !== undefined) {
                data.timer.races.stop();
            }

            // hide results / upcoming races
            data.dom.races.hide();
            data.dom.results.hide();

            // remove mode class
            data.dom.asset.removeClass(function (index, css) {
                return (css.match (/\bmode-\S+/g) || []).join(' ');
            });

            // remove class from video container
            data.dom.video.removeClass('has-stream');
        }

        /**
        * removePlayerSWF()
        * Helper function that is used in several places
        */
        function removePlayerSWF() {
            // remove previous player
            try {
                // check if if jwplayer exists
                debug('Player ID: ' + data.playerId);

                if (jwplayer(data.playerId).remove !== undefined) {
                    debug('Removed player (SWF) but could not find instance.');
                    jwplayer(data.playerId).remove();
                } else {
                    debug('Tried to remove player (SWF) but could not find instance.');
                }
            } catch (err) {
                debug('Remove Player (SWF) failed', err);
            }
        }

        /**
        * removePlayerHLS()
        * Helper function that is used in several places
        */
        function removePlayerHLS() {
            // remove previous player
            try {
                if (hlsPlayer && hlsPlayer.dispose) {
                    hlsPlayer.dispose();
                    hlsPlayer = undefined;
                }
            } catch (err) {
                debug('Remove Player (HLS) failed', err);
            }
        }

        /**
        * removePlayerIFrame()
        */
        function removePlayerIFrame() {
            var $iframe = $('#stream-iframe');
            if (!$iframe.length) {
                return;
            }
            $iframe.remove();
            debug('Removed player (iframe).');
        }

        /**
        * removePlayerPFG()
        */
        function removePlayerPFG() {
            var $iframe = $('#stream-content iframe');
            var $script = $('#stream-content script');
            if ($iframe.length) {
                $iframe.remove();
            }

            if ($script.length) {
                $script.remove();
            }
            
            debug('Removed player (PFG).');
        }


        /**
        * removePlayerEGT()
        * Removes <IFRAME> with Easygate livestream in it
        */
        function removePlayerEGT() {
            var $playerEGT = data.dom.asset.find('#stream-egt-iframe');
            if (!$playerEGT.length) {
                return;
            }
            $playerEGT.remove();
            debug('Removed player (EGT).');
        }

        /**
        * removePlayerWMV()
        * Remove JW WMV Player
        */
        function removePlayerWMV() {
            // @todo: do it
            debug('removePlayerWMV() not yet implemented');
        }

        /**
        * removePlayerKCK()
        * Remove Kickstatic player
        */
        function removePlayerKCK() {
            var $playerKCK = data.dom.asset.find('#stream-kck-iframe');
            if (!$playerKCK.length) {
                return;
            }
            $playerKCK.remove();
            debug('Removed player (KCK).');
        }

        /**
        * removePlayerWowza()
        * Remove Wowza player
        */
        function removePlayerWowza() {
            // remove the object itself
            $('div#media-browser.mode-wowza div.video div').remove();
            $('div#media-browser.mode-wowza div.video script').remove();
        }

        /**
        * setMediaResource()
        * Initiates or toggles the media stream resources
        *
        * @param toggle
        */
        function setMediaResource(toggle) {
            //removePlayer(); // why was this here? rob, 19/02/2014

            if (toggle !== undefined && toggle === true) {
                // toggle resources
                $(data.stream.resources).each(function() {
                    if (this.idResource != data.stream.resource.idResource) {
                        data.stream.resource = this;
                        return false;
                    }
                });

                // republish player using new resource
                if (data.stream.provider === 'CBN') {
                    // get cobain token
                    getCobainUrl(data.stream.idChannel, data.stream.streamType, raceBetsJS.browser.params.get('external'), data.stream.resource.file, function(result) {
                        if (result.type !== undefined && result.type == 'error') {
                            handleErrors(result);
                            return;
                        }

                        debug('Cobain: Stream URL acquired', result, data.stream);

                        // publish cobain player
                        publishPlayerCBN();
                    });

                } else {
                    publishPlayerSWF();
                }

            } else {
                // set first resource
                if (data.stream.resources.length == 1) {
                    // just one resource available
                    data.stream.resource = data.stream.resources[0];

                } else {

                    // if multiple resources, take settings into consideration
                    if ((raceBetsJS.application.assets.settings.get().dialog.general.streamFormat == 'high')) {
                        data.stream.resource = data.stream.resources[1];
                        toggleButton('quality', 'active', true);

                    } else {
                        data.stream.resource = data.stream.resources[0];
                        toggleButton('quality', 'active', false);
                    }
                }
            }
        }

        /**
        * getToken()
        * Retrieves token for an idChannel / streamType combo
        * Optionally retrieves token for an idExternal and / or calls callback afterwards
        *
        * @param idChannel
        * @param streamType
        * @param idExternal
        * @param callback
        */
        function getToken(idChannel, streamType, idExternal, callback) {
            // MNT-2383
            var token = {
                token: '7RqTJHOTi9927014',
                tokenHtml5: '7RqTJHOTi9927014'
            };

            data.stream.token = token.token;
            if (token.tokenHtml5 !== undefined) {
                data.stream.tokenHtml5 = token.tokenHtml5;
            }

            if (callback !== undefined) {
                callback(token);
            }

            return token;


/*
            var tokens = getTokenCookie();

            // if token doesn't exist or is not valid anymore, fetch a new one
            if (tokens[idChannel] === undefined ||
                tokens[idChannel][streamType] === undefined ||
                tokens[idChannel][streamType].validBefore <= (($.now() - raceBetsJS.application.user.timeDiff) / 1000)) {

                    // request token for idChannel / streamType
                    $.ajax({
                        type: 'post',
                        context: this,
                        url: '/ajax/media/resource/',
                        data: {
                            idChannel: idChannel,
                            streamType: streamType,
                            idExternal: (idExternal !== undefined) ? idExternal : undefined
                        },
                        success: function(result) {
                            if (result.type === 'error') {
                                return;
                            }

                            // initilise cookie
                            if (tokens[idChannel] === undefined) {
                                tokens[idChannel] = {};
                                tokens[idChannel][streamType] = {};
                            } else {
                                tokens[idChannel][streamType] = {};
                            }

                            // save token to cookie and data stream object
                            tokens[idChannel][streamType].token = result.token;
                            data.stream.token = result.token;

                            if (result.tokenHtml5 !== undefined) {
                                tokens[idChannel][streamType].tokenHtml5 = result.tokenHtml5;
                                data.stream.tokenHtml5 = result.tokenHtml5;
                            }
                            tokens[idChannel][streamType].validBefore = result.validBefore;

                            debug('Requesting a new token for idChannel: ' + idChannel + ' / streamType: ' + streamType, result);

                            // rewrite cookie
                            setTokenCookie(tokens);

                            return token;
                        },
                        complete: function(result) {
                            // callback if given
                            if (callback !== undefined) {
                                callback($.parseJSON(result.responseText));
                            }
                        }

                    });

            } else {
                // still valid? then use existing one
                var token = tokens[idChannel][streamType];

                // save token data stream object
                data.stream.token = token.token;
                if (token.tokenHtml5 !== undefined) {
                    data.stream.tokenHtml5 = token.tokenHtml5;
                }

                debug('Token still valid for idChannel: ' + idChannel + ' / streamType: ' + streamType, token);

                // callback if given
                if (callback !== undefined) {
                    callback({ token: token });
                }
            }

            return token;*/
        }

        function getTokenCookie() {
            try {
                var token = $.parseJSON($.cookie(settings.cookieName));
                return (token !== null && token !== undefined) ? token : {};
            } catch (e) {
                // reset cookie
                setTokenCookie({});
                return {};
            }
        }

        function setTokenCookie(json) {
            $.cookie(settings.cookieName, $.stringify(json), {
                secure: true,
                path: '/',
                domain: '.' + document.location.hostname.replace(/^www\d*\./ig, '')
            });
        }

        function getCobainUrl(idChannel, streamType, idExternal, quality, callback) {
            data.dom.asset.removeClass('default').addClass('mode-cbn loading');

            $.ajax({
                type: 'post',
                url: '/ajax/media/resource/',
                data: {
                    idChannel: idChannel,
                    streamType: streamType,
                    idExternal: (idExternal !== undefined) ? idExternal : undefined,
                    quality: quality,
                    mobile: raceBetsJS.browser.type.isTablet()
                },
                success: function(result) {
                    if (result.type == 'error') {
                        // callback if given
                        if (callback !== undefined) {
                            callback(result);
                        }

                        return;
                    }

                    data.stream.cobainUrl = result.cobainUrl;
                },
                complete: function(result) {
                    data.dom.asset.removeClass('loading');

                    // callback if given
                    if (callback !== undefined) {
                        callback($.parseJSON(result.responseText));
                    }
                }
            });
        }

        function getStreamUrl(streamOptions, callback) {
            var postData = {
                idChannel: streamOptions.idChannel,
                streamType: streamOptions.streamType
            };

            if (['ATR', 'SISTV', 'PFG', 'EGT', 'YOUTUBE'].indexOf(streamOptions.provider) > -1) {
                postData.idRace = streamOptions.idRace;
            }

            $.ajax({
                type: 'post',
                url: '/ajax/media/resource/',
                data: postData,
                success: function(result) {
                    if (result.type !== 'error') {
                        data.stream.url = result.stream_url || result.archive_url;
                        data.stream.iframe = result.stream_player;
                        data.stream.requestId = result.request_id;

                        if(result.idChannel && result.idChannel !== data.stream.idChannel) {
                            //this can change if subChannel is fetched
                            data.stream.idChannel = result.idChannel;
                            toggleStreamItem(data.stream.idChannel, data.stream.country);
                        }

                        if (callback !== undefined) {
                            callback(result);
                        }
                    } else {
                        handleErrors(result);
                        return;
                    }
                }
            });
        }

        /**
        * getStream()
        * Checks if the stream exists and returns it if found (otherwise returns false)
        */
        function getStream(idChannel, country) {
            var stream = false;
            $(data.streams).each(function() {
                if (this.idChannel == idChannel && this.country == country) {
                    stream = this;
                    return;
                }
            });

            return stream;
        }

        // --------------------------------------------------------------------------------
        // --------------------------------------------------------------------------------

        /**
        * onStreamSelect()
        * Provides logic after customer selected a stream from the list
        */
        function onStreamSelect() {
            var li = $(this);

            // ignore headers
            if (li.hasClass('header')) {
                return;
            }

            options = {
                idChannel: li.data('id-channel'),
                country: li.data('country'),
                idRace: li.data('id-race'),
                provider: li.data('provider'),
                streamType: 'LIV'
            };

            if (raceBetsJS.application.assets.settings.get().dialog.general.popupStreams == true) {
                openPopup(options);
            } else {
                // hide empty headers
                hideEmptyStreamHeaders();

                // switch channel
                publishPlayer(options);
            }

            // hide select options
            data.dom.streams.select.trigger('hide');
        }

        /**
        * toggleStreamLabel()
        * Toggles the stream label (selection) from "No streams available" and the actual stream selection
        *
        * @param enabled
        */
        function toggleStreamItem(idChannel, country) {
            if (raceBetsJS.application.globals.isPopup) {
                return;
            }

            var stream = getStream(idChannel, country);

            if (idChannel !== undefined && country !== undefined && stream !== false) {
                // update stream label
                data.dom.streams.select.children('span.label').html(
                    raceBetsJS.i18n.data.countries[stream.country]
                );

                data.dom.streams.select.children('span.c-flag')
                    .attr('class', 'c-flag isCountry' + stream.country)
                data.dom.streams.select
                    .attr('class', 'select' + (isAudioStream(stream) ? ' audio' : ''));

                // update selection in stream select
                data.dom.streams.find('ul.options li.selected').removeClass('selected');
                data.dom.streams.find('ul.options li.current').remove();

                // select item
                data.dom.streams.find('li.selected').removeClass('selected');
                var li = data.dom.streams.find('li[data-country="' + country + '"][data-id-channel="' + idChannel + '"]');
                li.addClass('selected');

                // clone <li> to top
                var clone = li.clone().removeAttr('data-country data-id-channel').removeClass('selected').addClass('current');
                data.dom.streams.find('ul.options').prepend(clone);

                // set last li list item
                data.dom.asset.find('div.streams ul.options li.last').removeClass('last');
                data.dom.asset.find('div.streams ul.options li:last').addClass('last');

            } else {
                // no streams available or default
                data.dom.streams.removeClass('has-selection');
                data.dom.streams.select.children('span.label').html(
                    (data.streams.length > 0)
                        ? raceBetsJS.i18n.data.labelSelectStream
                        : raceBetsJS.i18n.data.labelNoStreamAvailable
                );
                data.dom.streams.select.removeClass('audio');
                data.dom.streams.find('li.selected').removeClass('selected');
                data.dom.streams.find('ul.options li.current').remove();
                data.dom.asset.addClass('default');

            }

            // toggle "has-selection" class
            data.dom.streams.toggleClass('has-selection', (li !== undefined));
        }

        /**
        * initStreamSelect()
        * Initialise the stream option select
        */
        function initStreamSelect() {
            var options = data.dom.streams.children('ul.options');

            data.dom.streams.select.on('show', function() {
                // if no streams are available, don't slide down
                if (data.streams.length == 0) {
                    return;
                }

                if (options.is(':animated')){
                    return;
                }

                // stop getting streams while menu is expanded
                data.timer.streams.stop();

                // slide down options
                options.addClass('expanded').slideDown(settings.durationSliding);

            }).on('hide', function() {
                if (options.is(':animated')){
                    return;
                }

                // slide up options and remove expanded class
                options.slideUp(settings.durationSliding, function() {
                    $(this).removeClass('expanded');
                    data.timer.streams.start();
                });

            }).on('toggle', function() {
                if (options.is(':visible')){
                    data.dom.streams.select.trigger('hide');
                } else {
                    data.dom.streams.select.trigger('show');
                }
            });

            // enable observer and delegator
            data.dom.streams.select.on('click', function() {
                data.dom.streams.select.trigger('toggle');
            });
            data.dom.streams.on('click', 'div.stream-container.current li', onStreamSelect);

            // hide stream options after some time automatically
            data.dom.streams.on('mouseleave', function() {
                data.timer.select = new raceBetsJS.time.Timeout({
                    callback: function() {
                        data.dom.streams.select.trigger('hide');
                    },
                    delay: settings.hideSelectTimeout
                });
            });

            // stop hide counter when you enter the area again
            data.dom.streams.on('mouseenter', function() {
                if (data.timer.select !== undefined) {
                    data.timer.select.stop();
                }
            });

            // if you click anywhere but the drop down, hide it as well
            $('html').click(onClickOutside);
            data.dom.streams.click(function(e) {
                e.stopPropagation();
            });
        }

        /**
        * isAudioStream()
        * Check if the resources are only MP3
        */
        function isAudioStream(stream) {
            var isAudio = true;
            var resources = stream.resources;

            // no resources available, so false
            if (resources === undefined || resources.length == 0) {
                return false;
            }

            $(resources).each(function() {
                if (this.technology != 'MP3') {
                    isAudio = false;
                    return;
                }
            });

            return isAudio;
        }

        /**
        * toggleButton()
        * Toggles the state of buttons (supposed to be enabled + active) of toolbar buttons
        *
        * @param action
        * @param className ("enabled" and "active")
        * @param enabled
        */
        function toggleButton(action, className, state) {
            if (raceBetsJS.application.globals.isPopup) {
                return;
            }

            data.dom.toolbar.buttons[action].toggleClass(className, state);

            // play / pause "hack"
            if ($.inArray(action, ['play', 'pause']) > -1 && $.inArray(className, ['play', 'pause']) > -1 && state == true) {
                data.dom.toolbar.buttons[action].data('action', className);
            }
        }

        /**
        * hideEmptyStreamHeaders()
        * Hide empty headers (live- and upcoming) when they don't contain streams
        */
        function hideEmptyStreamHeaders() {
            data.dom.streams.find('div.stream-container').each(function() {
                container = $(this);
                container.toggle(container.find('li:not(.selected)').size() > 1);
            });
        }

        /**
        * toolbarActions()
        * Responsible for the toolbar actions
        */
        function toolbarActions(e) {
            var button = $(this);
            var action = button.data('action');

            if (action == 'play') {
                if (data.dom.asset.hasClass('mode-swf')) {
                    jwplayer(data.playerId).play();
                } else if (data.dom.asset.hasClass('mode-wmv')) {
                    wmvPlayer.sendEvent('PLAY');
                }

                // toggle buttons play/pause
                button.removeClass('play').addClass('pause').data({action: 'pause', tipped: raceBetsJS.i18n.data.tipMediaPause});

                // hint can change on the play/pause button while over it, so force update
                toolbarHints(e);

                toggleButton('play', 'pause', true);

            } else if (action == 'pause') {
                if (data.dom.asset.hasClass('mode-swf')) {
                    if (data.stream.streamType == 'LIV') {
                        jwplayer(data.playerId).stop();
                    } else {
                        jwplayer(data.playerId).pause();
                    }
                } else if (data.dom.asset.hasClass('mode-wmv')) {
                    wmvPlayer.sendEvent('STOP');
                }

                // toggle buttons play/pause
                button.removeClass('pause').addClass('play').data({action: 'play', tipped: raceBetsJS.i18n.data.tipMediaPlay});

                // hint can change on the play/pause button while over it, so force update
                toolbarHints(e);

            } else if (action == 'quality') {
                if (data.dom.asset.hasClass('mode-swf') || data.dom.asset.hasClass('mode-cbn')) {
                    toggleButton('quality', 'active', !button.hasClass('active'));
                    setMediaResource(true);
                }

            } else if (action == 'sticky') {
                settings.stickyMedia = !settings.stickyMedia;
                button.toggleClass('active', settings.stickyMedia);
                relocateMediaAsset();

            } else if (action == 'popup') {
                openPopup(data.options);
                resetMediaPlayer();
            }
        }

        /**
        * toolbarHints()
        * Show toolbar button tooltips
        */
        function toolbarHints(e) {
            // don't show for iOS devices
            if(raceBetsJS.browser.type.isiPad() || raceBetsJS.browser.type.isiPhone()){
                return;
            }

            var elem = e.target;

            Tipped.create(elem, $(elem).attr('data-tipped'),
            {
                skin: 'racebets',
                hook: 'topmiddle',
                maxWidth: 180,
                hideOn: [
                  { element: 'self', event: 'mouseleave' },
                  { element: 'tooltip', event: 'mouseenter' }
                ],
                onHide: function(content, element) {
                    Tipped.remove(element);
                }
            });
        }

        /**
        * relocateMediaAsset()
        * Responsible for the logic to keep the media browser "glued" to the top of the screen
        */
        function relocateMediaAsset(parentFrameScrollPosition) {
            if(raceBetsJS.application.globals.isB2B) {
                var topIframePosition = raceBetsJS.application.globals.iframe.top,
                topOffset;

                topOffset = window.document.getElementById('content-media').getBoundingClientRect().top + topIframePosition;

                if(parentFrameScrollPosition > topOffset && settings.stickyMedia){
                    data.dom.asset.addClass('sticky');
                    data.dom.asset.css({top:parentFrameScrollPosition - topIframePosition});
                    data.dom.asset.width( $('#content-main').width() );
                }else{
                    data.dom.asset.removeClass('sticky');
                }
            } else {
                if (!raceBetsJS.browser.viewport.isElementVisible('content-media', window) && settings.stickyMedia) {
                    data.dom.asset.addClass('sticky');
                    data.dom.asset.width( $('#content-main').width() );
                } else {
                    data.dom.asset.removeClass('sticky');
                }
            }
        }

        /**
        * onClickOutside()
        * Hides select by clicking anywhere on the side
        */
        function onClickOutside() {
            if (data.timer.select !== undefined) {
                data.timer.select.stop();
            }

            data.dom.streams.select.trigger('hide');
        }

        /**
        * JW Player events helper functions
        */
        function onJwPlayerPlay() {
            toggleButton('play', 'play', false);
            toggleButton('play', 'pause', true);
        }

        function onJwPlayerStop() {
            if (data.stream.streamType == 'LIV') {
                jwplayer(data.playerId).stop();
            }

            toggleButton('play', 'play', true);
            toggleButton('play', 'pause', false);
        }

        // --------------------------------------------------------------------------------
        // --------------------------------------------------------------------------------

        /**
        * popupStreamOptions()
        * Returns the reference of the popup stream
        */
        function getPopupOptions(index) {
            if (popups[index] === undefined) {
                raceBetsJS.application.assets.modalDialog.generic();
                return;
            }

            return popups[index];
        }

        /**
        * malfunction()
        * Show mailfunction error
        *
        * @param status
        */
        function malfunction(status) {
            if (status == true) {
                raceBetsJS.application.assets.messageBox.show({
                    id: '#media-malfunction',
                    type: 'error',
                    before: $('#media-browser .content_container'),
                    content: raceBetsJS.i18n.data.errorStreamDifficulties,
                    fadeOut: false,
                    closeButton: false,
                    buttons: false
                });
                adjustHeight();

            } else {
                raceBetsJS.application.assets.messageBox.hide({
                    id: '#media-malfunction',
                    onHide: function() {
                        adjustHeight();
                    }
                });
            }
        }

        /**
        * adjustHeight()
        * Due to the Mozilla bug while rendering Flash contents, the whole asset needs to be positioned absolute.
        * Therefore we need to adjust the height manually every time we change the content of the asset with this helper.
        */
        function adjustHeight() {
            var height = data.dom.asset.outerHeight();

            data.dom.container.css({ height: height });
        }

        /**
        * resetMediaPlayer()
        * Reset select, player etc and shows initial layout
        */
        function resetMediaPlayer() {
            // if previous race polling was active, stop it
            if (data.timer.races !== undefined) {
                data.timer.races.stop(); // in case there was another one running already, stop it
            }

            data.stream = undefined;
            removePlayer();
            toggleStreamItem();
        }

        /**
        * handleErrors()
        * Handling AJAX error messages
        *
        * @param data
        */
        function handleErrors(data) {
            switch (data.errorMsg) {
                case 'INVALID_RESOURCE':
                    var content = raceBetsJS.i18n.data.msgStreamErrorGeneric;
                    break;
                case 'COUNTRY_BLOCKED':
                    var content = raceBetsJS.i18n.data.msgStreamErrorBlocked;
                    break;
                case 'LOGIN_REQUIRED':
                    raceBetsJS.application.assets.dialogs.loginDialog.show()
                    return;
                case 'NO_TURNOVER':
                    var content = raceBetsJS.i18n.print('msgNoTurnover', {
                        period: 14,
                        turnover: raceBetsJS.format.money(raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].streamsAccessMinTurnover14d, 2, raceBetsJS.application.user.currency)
                    })
                    break;
                case 'TOKEN_NOT_FOUND':
                    var content = raceBetsJS.i18n.data.errorStreamDifficulties;
                    break;
                case 'NO_TURNOVER_UK':
                    var content = raceBetsJS.i18n.data.msgErrorStreamTurnover;
                    break;
                case 'NO_ARCHIVE':
                    var content = raceBetsJS.i18n.data.msgErrorStreamNoArchive;
                    break;
                case 'eventnotstarted':
                    var content = raceBetsJS.i18n.data.msgErrorStreamNotStarted;
                    break;
                case 'eventended':
                case 'eventover':
                    var content = raceBetsJS.i18n.data.msgErrorStreamEnded;
                    break;
                case 'geoblocked':
                    var content = raceBetsJS.i18n.data.msgStreamErrorBlocked;
                    break;
            }

            if (content !== undefined) {
                raceBetsJS.application.assets.modalDialog.show({
                    icon: 'info',
                    content: content,
                    buttons: [
                        {
                            label: raceBetsJS.i18n.data.buttonOK,
                            action: function() {
                                if (raceBetsJS.application.globals.isPopup) {
                                    // if in popup mode, close
                                    window.close();
                                }

                                raceBetsJS.application.assets.overlay.close();
                            }
                        }
                    ]
                });

            } else if (data.errorMsg.match(/EASYGATE_ERROR_/)) {
                raceBetsJS.application.assets.modalDialog.show({
                    icon: 'info',
                    content: raceBetsJS.i18n.data.msgStreamErrorGeneric,
                    buttons: [
                        {
                            label: raceBetsJS.i18n.data.buttonOK,
                            action: function() {
                                if (raceBetsJS.application.globals.isPopup) {
                                    // if in popup mode, close
                                    window.close();
                                }

                                raceBetsJS.application.assets.overlay.close();
                            }
                        }
                    ]
                });

            } else {

                if (raceBetsJS.application.globals.isPopup) {
                    // if in popup mode, close
                    if (window.opener) {
                        window.opener.raceBetsJS.application.assets.modalDialog.generic(data.errorMsg);
                    }
                    window.close();
                } else {
                    raceBetsJS.application.assets.modalDialog.generic(data.errorMsg);
                }
            }
        }

        /**
        * isUnsupportedChannel
        * Checks if no specific devices the idChannel is unsupported
        */
        function isUnsupportedChannel(idChannel) {
            idChannel = parseInt(idChannel); // make sure we have an integer here...

            var unsupportedChannels = {
                ios: [13]
            }

            // if iOS device, check if stream is unsupported
            if(raceBetsJS.browser.type.isiPad() || raceBetsJS.browser.type.isiPhone()) {
                return ($.inArray(idChannel, unsupportedChannels.ios) > -1);
            }

            return false;
        }

        /**
         * onStop()
         * Informs backend when customer stops watching streaming
         */
        function onStop() {
            if (data.stream.streamType === 'LIV') {
                var postData = {
                    request_id: data.stream.requestId
                };

                $.ajax({
                    url: '/rest/v2/media/stream/close',
                    type: 'PUT',
                    dataType: 'json',
                    contentType: 'application/json;charset=UTF-8',
                    data: $.stringify(postData),
                    success: function (result) {}
                });
            }
        }

        /**
        * debug()
        * Instead of console.log or $.log, use this one here as it has a toggle in the options to disable it.
        */
        function debug() {
            if ((settings.debug === true || raceBetsJS.application.globals.isOffice) && typeof console !== 'undefined' && typeof console.log !== 'undefined') {
                console.log(arguments);
            }
        }

        // --------------------------------------------------------------------------------
        // --------------------------------------------------------------------------------

        // @public
        return {
            init: function() {
                // primary navigation
                $('#m-header__menu [data-key="streams"] .c-btn').on('click', function(e) {
                    e.stopPropagation();
                    e.preventDefault();

                    toggle();
                });

                // when in popup...
                raceBetsJS.application.contentController.addCallback(/^\/media\/popup/, initPopup);
            },
            show: show,
            hide: hide,
            toggle: toggle,
            getPopupOptions: getPopupOptions,
            isUnsupportedChannel: isUnsupportedChannel,
            initPopup: initPopup,
            openPopup : openPopup
        };
    }();
})(raceBetsJS);
