/**
* Media
*/

(function(raceBetsJS) {
	raceBetsJS.media = {

        /**
        * Returns the result of a country filter
        */
        isCountryAllowed: function(userCountry, countries, filterType) {
            if (filterType === null) {
                return true;
            }

            var res = $.inArray(userCountry, countries);
            return ((filterType === 'ALW' && res !== -1) || (filterType === 'DIS' && res === -1));
        }
    };
})(raceBetsJS);
