/**
 * Responsible gaming week.
 *
 */
(function(raceBetsJS) {
    raceBetsJS.application.header.rbWeek = function() {
        // @private
        var cookieEventName = 'responsibleGamblingWeek',
            startPromoDate = new Date('November 7, 2019 00:00:00');

        var init = function() {
            var userCountry = raceBetsJS.application.user.ipCountry;
            var rbWeekLogo = $('.responsibleGamingLogo');

            if (promoIsActive() && (userCountry === 'GB' || userCountry === 'IE')) {
                $('.m-header').addClass('responsibleGamingWrapper');
                rbWeekLogo.addClass('responsibleGamingImg');
            } else {
                $('.m-header').removeClass('responsibleGamingWrapper');
                rbWeekLogo.removeClass('responsibleGamingImg');
            }
        };

        var endPromoDate = function(start) {
            var finish = new Date(start);
            finish.setDate(finish.getDate() + 7);
            return finish;
        };

        var promoIsActive = function() {
            var today = new Date();
            var endDay = endPromoDate(startPromoDate);

            return today < endDay && today > startPromoDate;
        };

        var showResponsibleGamingDialog = function () {
            // only for logged in users from GB and IE
            if (raceBetsJS.application.user.loggedIn && ['GB', 'IE'].indexOf(raceBetsJS.application.user.ipCountry) !== -1) {

                // only active between 1/11 and 7/11
                if (promoIsActive()) {

                    // show only once
                    if (!$.cookie(cookieEventName)) {
                        var date = new Date();
                        date.setYear(date.getFullYear() + 1);

                        // set cookie to avoid showing again
                        $.cookie(cookieEventName, JSON.stringify({ seen: true }), {
                            secure: false,
                            path: '/',
                            expires: date
                        });

                        raceBetsJS.application.assets.dialogs.imageDialog.show({
                            title: 'Responsible Gambling Week',
                            imageUrl: '/images/temp/rgw-banner.jpg',
                            width: 400,
                            url: 'https://responsiblegamblingweek.org'
                        });

                    }
                }
            }
        };
        // @public
        return {
            init: init,
            promoIsActive: promoIsActive,
            showResponsibleGamingDialog: showResponsibleGamingDialog
        };
    }();
})(raceBetsJS);
