/**
* Dialog Templates
*
*/
(function (raceBetsJS) {
    raceBetsJS.application.templates.dialogs = {};

    raceBetsJS.application.templates.dialogs.passwordReset = _.template('\
        <div class="sub-header">\
            <span class="icon attention"></span>\
            <%= raceBetsJS.i18n.data.validationErrorPassword %>\
        </div>\
        <form action="/rest/v1/users/reset_password" name="confirmResetPassword" method="post" class="c-form">\
            <fieldset class="c-form__group">\
                <label for="newPassword"><%= raceBetsJS.i18n.data.labelNewPassword %></label>\
                <input type="password" id="newPassword" name="password" class="c-input c-input--text" autocomplete="new-password" autocorrect="off" autocapitalize="off" spellcheck="false">\
            </fieldset>\
            <fieldset class="c-form__group">\
                <label for="confirmNewPassword"><%= raceBetsJS.i18n.data.labelPasswordConfirmation %></label>\
                <input type="password" id="confirmNewPassword" name="passwordConfirm" class="c-input c-input--text" autocomplete="new-password" autocorrect="off" autocapitalize="off" spellcheck="false">\
            </fieldset>\
        </form>\
    ');

    raceBetsJS.application.templates.dialogs.login = _.template(
        '<% if (typeof heading !== "undefined") { %>' +
            '<div class="sub-header">' +
                '<% if (typeof heading.icon !== "undefined" && heading.icon !== "") { %>' +
                    '<span class="icon <%= heading.icon %>"></span>' +
                '<% } %>' +
                '<%= heading.content %> ' +
            '</div>' +
        '<% } %>' +
        '<form>' +
            '<ul>' +
                '<li>' +
                    '<label for="dlg-login-username"><%= raceBetsJS.i18n.data.labelUsername %></label>' +
                    '<input type="text" id="dlg-login-username" autocomplete="username" autocorrect="off" autocapitalize="off" spellcheck="false" />' +
                '</li>' +
                '<li>' +
                    '<label for="dlg-login-password"><%= raceBetsJS.i18n.data.labelPassword %></label>' +
                    '<input type="password" id="dlg-login-password" autocomplete="current-password" autocorrect="off" autocapitalize="off" spellcheck="false" />' +
                '</li>' +
            '</ul>' +
        '</form>' +
        '<% if (typeof hasLinks !== "undefined") { %>' +
            '<div class="links align-right">' +
                '<a href="<%= raceBetsJS.application.globals.urlPrefix %>/account/forgotten" id="dlg-login-forgotten" class="ajaxify"><%= raceBetsJS.i18n.data.linkForgottenDetails %></a>' +
            '</div>' +
        '<% } %>'
    );

    raceBetsJS.application.templates.dialogs.loginSecondStep = _.template('\
        <div>\
            <%= raceBetsJS.i18n.data.msgVerifyCode %>\
        </div>\
        <form>\
            <ul>\
                <li>\
                    <label for="dlg-login-code"><%= raceBetsJS.i18n.data.labelVerificationCode %></label>\
                    <input id="dlg-login-code" type="text" maxlength="4" autocomplete="one-time-code" autocorrect="off" autocapitalize="off" spellcheck="false" inputmode="numeric" />\
                <li>\
            </ul>\
        </form>\
    ');

    raceBetsJS.application.templates.dialogs.support = _.template('\
        <% if(raceBetsJS.application.globals.brandName === "racebets") { %>\
        <div class="support-bar">\
            <div class="bubble">\
                <%= raceBetsJS.i18n.data.labelNeedHelp %>\
                <span class="arrow"><span>\
            </div>\
            <ul>\
                <li>\
                    <span class="icon chat"></span>\
                    <a href="#" class="banner support openLiveChat"><%= raceBetsJS.i18n.data.linkLiveChat %></a>\
                    <span class="assistly-widget" id="assistly-widget-2" style="display: none;"></span>\
                </li>\
                <li>\
                    <span class="icon mail"></span>\
                    <a href="<%= raceBetsJS.application.assets.settings.getBrandSettings("supportURLs", "contact") %>"><%= raceBetsJS.i18n.data.linkEmailUs %></a>\
                </li>\
            </ul>\
        </div>\
        <% } %>\
    ');

    raceBetsJS.application.templates.dialogs.betPlaced = _.template(
        '<p><%= raceBetsJS.i18n.data.betPlacedText %></p>' +
        '<% if(raceBetsJS.application.globals.brandName === "racebets") { %>' +
            '<%= raceBetsJS.application.templates.dialogs.resultSMS() %>' +
        '<% } %>'
    );

    raceBetsJS.application.templates.dialogs.resultSMS = _.template(
        '<div class="result-sms rounded">' +
            '<div class="checkbox clearfix">' +
                '<% if(raceBetsJS.application.user.country !== "AE") { %>' +
                    '<input type="checkbox" id="betslip-sms-cb">' +
                    '<label for="betslip-sms-cb"><%= raceBetsJS.i18n.data.cbOrderResultSms %> (<%= raceBetsJS.format.money(raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].smsFee, 2, raceBetsJS.application.user.currency, true) %>)</label>' +
                '<% } %>' +
            '</div>' +
            '<div class="details">' +
                '<input type="text" id="betslip-sms-mobile" />' +
                '<span class="light-grey"><%= raceBetsJS.i18n.data.mobileFormatExample %></span>' +
                '<span class="error"></span>' +
            '</div>' +
        '</div>'
    );

    raceBetsJS.application.templates.dialogs.runnerNotes = _.template(
        '<form action="#">' +
            '<textarea id="dlg-runnernote-textarea" class="c-input c-input--textarea" placeholder="<%= raceBetsJS.i18n.data.modalRunnerNotesPlaceholder %>" disabled><% if (typeof runnerNote.note !== "undefined") { %><%= runnerNote.note %><% } %></textarea>' +
            '<div id="dlg-content-loading" class="content-loading"></div>' +
        '</form>'
    );

    raceBetsJS.application.templates.dialogs.limitLugas = _.template('\
            <p><%= contentMsg %></p>\
            </br></br>\
            <p>\
                <input id="lugasCheckbox" type="checkbox" id="confirm" name="confirm" class="c-input c-input--checkbox" >\
                <label class="c-form__label" for="lugasCheckbox"><%= raceBetsJS.i18n.data.labelConfirmPopupLugas %></label>\
            </p><br/>\
    ');

    raceBetsJS.application.templates.dialogs.limits = _.template('\
        <p><%= raceBetsJS.i18n.print("dialogResLimitDesc" + limitType) %></p><br/>\
        <% if (!limit.pending_limit && !isGermanCustomer) { %>\
            <div class="c-note c-note--info">\
                <p class="c-note__message"><%= raceBetsJS.i18n.print("labelResCoolingOffNote", { period: raceBetsJS.i18n.print("timeXHours", {hours: "24"}) }) %></p>\
            </div>\
        <% } %>\
        <div class="g-row">\
            <% if (!limit.pending_limit || (limitType === "LUGAS" && (!limit.pending_limit || (limit.pending_limit && limit.pending_limit.cooling_off_stamp)))) { %>\
            <div class="g-span--6-6">\
                <form id="newLimitForm" name="newLimitForm" action="/rest/v1/users/me/limits">\
                    <div class="c-box">\
                        <div class="c-box__head c-box__head--light">\
                            <p class="c-box__title"><%= raceBetsJS.i18n.data.labelResLimitNew %></p>\
                            <hr class="c-ruler" />\
                        </div>\
                        <div class="c-box__body">\
                            <div class="g-row">\
                                <div class="g-span--1-4">\
                                    <label class="c-form__label" for="limitPeriodOption"><%= raceBetsJS.i18n.data.labelResPeriod %>:</label>\
                                </div>\
                                <div class="g-span--3-4">\
                                    <% if (limitType !== "LUGAS") { %>\
                                        <select c id="limitPeriodOption" name="period_days_option">\
                                            <option value="-1" selected><%= raceBetsJS.i18n.data.labelPleaseSelect %></option>\
                                            <% $.each(options, function(key, option) { %>\
                                                <option value="<%= option.period %>"><%= option.label %></option>\
                                            <% }) %>\
                                            <input id="limitPeriodOptionHidden" class="limitPeriodOptionHidden" type="hidden" value="-1" name="period_days">\
                                        </select>\
                                        <% } else { %>\
                                        <span style="padding: 8px 0; display: inline-block;"><%= raceBetsJS.i18n.data.labelCalendarMonth %></span>\
                                        <select id="limitPeriodOption" name="period_days_option" style="display:none;" disabled>\
                                            <option value="month"><%= raceBetsJS.i18n.data.labelCalendarMonth %></option>\
                                            <input id="limitPeriodOptionHidden" class="limitPeriodOptionHidden" type="hidden" value="30" name="period_days">\
                                        </select>\
                                    <% } %>\
                                </div>\
                            </div>\
                            <div class="g-row">\
                                <div class="g-span--1-4">\
                                    <label class="c-form__label" for="newLimitAmount"><%= raceBetsJS.i18n.data.labelLimit %>:</label>\
                                </div>\
                                <div class="g-span--3-4">\
                                    <input id="newLimitAmount" style="width: 70px;" name="amount" min="0" step="1" oninput="this.value=this.value.replace(/[^0-9]/g,\'\');" type="number" value="" class="newLimitAmount c-input c-input--text" <% if (limitType !== "LUGAS") { %> disabled <% } %> > <span id="limitCurrency"><%= limitType !== "LUGAS" ? raceBetsJS.application.user.currency : "EUR" %></span>\
                                </div>\
                            </div>\
                        </div>\
                        <div class="c-box__foot">\
                            <p id="msgExceeded" class="msg-exceeded" style="display: none"><%= raceBetsJS.i18n.data.msgAmountExceeded %></p>\
                            <button id="createLimitBtn" type="submit" class="c-btn c-btn--default" disabled><%= raceBetsJS.i18n.data.buttonSave %></button>\
                        </div>\
                    </div>\
                </form>\
            </div>\
            <% } else if (limit.pending_limit) { %>\
            <div class="g-span--6-6">\
                <div class="c-box">\
                    <div class="c-box__head c-box__head--light">\
                        <p class="c-box__title">\
                            <%=  raceBetsJS.i18n.data.labelResLimitPending %>\
                            <% if (!coolingOffExpired) { %> \
                                <span class="pullRight" style="font-weight:normal;"><%= coolingOffLeft %></span>\
                            <% } %>\
                        </p>\
                        <hr class="c-ruler" />\
                    </div>\
                    <div class="c-box__body">\
                        <div class="g-row">\
                            <div class="g-span--2-4">\
                                <label class="c-form__label"><%= raceBetsJS.i18n.data.labelResPeriod %>:</label>\
                            </div>\
                            <div class="g-span--2-4">\
                                <% if (limitType !== "LUGAS") { %>\
                                    <strong class="c-form__label"><%= (limit.pending_limit.period_days) ? ((limit.pending_limit.period_days > 1) ? raceBetsJS.i18n.print("timeXDays", {days: limit.pending_limit.period_days }) : raceBetsJS.i18n.print("timeOneDay", {days: limit.pending_limit.period_days })) : raceBetsJS.i18n.data.labelNoLimit %></strong>\
                                <% } else { %>\
                                    <strong class="c-form__label"><%= raceBetsJS.i18n.data.labelCalendarMonth %></strong>\
                                <% } %>\
                            </div>\
                        </div>\
                        <div class="g-row">\
                            <div class="g-span--2-4">\
                                <label class="c-form__label"><%= raceBetsJS.i18n.data.labelLimit %>:</label>\
                            </div>\
                            <div class="g-span--2-4">\
                                <strong class="c-form__label"><% if (limit.pending_limit.amount) { %><%= limit.pending_limit.amount %> <%= limitType !== "LUGAS" ? raceBetsJS.application.user.currency : "EUR" %><% } else { %><%= raceBetsJS.i18n.data.labelNoLimit %><% } %></strong>\
                            </div>\
                        </div>\
                    </div>\
                    <div class="c-box__foot">\
                        <form id="pendingLimitForm" name="pendingLimitForm" action="" class="g-row">\
                            <% if (coolingOffExpired && showConfirmButton) { %>\
                            <p style="margin-bottom:0.5em;">\
                                <button id="confirmLimitBtn" type="button" class="c-btn c-btn--primary"><%= raceBetsJS.i18n.data.labelResLimitConfirm %></button>\
                            </p>\
                            <% } %>\
                            <% if (!showConfirmButton) { %>\
                            <p>\
                                <button id="revokeLimitBtn" type="button" class="c-btn c-btn--default"><%= raceBetsJS.i18n.data.labelResCancelPendingLimit %></button>\
                            </p>\
                            <% } %>\
                        </form>\
                    </div>\
                </div>\
            </div>\
            <% } %>\
        </div>\
    ');

    raceBetsJS.application.templates.dialogs.lockout = _.template('\
        <form action="/rest/v1/users/me/exclusions" name="confirmExclusionForm" method="post">\
            <fieldset>\
                <% if (type === "SE") {%>\
                    <p><%= raceBetsJS.i18n.data.msgResInfoSelfExclusion %></p>\
                    <!-- \
                    <% if (raceBetsJS.application.user.country === "DE" && period !== "6m") { %>\
                        <div class="c-note c-note--info" style="margin-top:8px;"><%= raceBetsJS.i18n.data.msgResInfoSelfExclusionOASIS %></div>\
                    <% } %>\
                    -->\
                <% } %>\
                <% if (type == "TO") {%>\
                    <%= raceBetsJS.i18n.data.msgResInfoTimeOut %>\
                <% } %>\
            </fieldset>\
            <% if (oasisReasons) {%>\
                <fieldset>\
                    <% $.each(oasisReasons, function(key, reason) { %>\
                        <label for="oasisReason<%= key %>">\
                            <input type="checkbox" id="oasisReason<%= key %>" value="<%= key %>" class="c-input c-input--checkbox">\
                            <%= reason %>\
                        </label>\
                    <% }) %>\
                </fieldset>\
            <% } %>\
            <% if (requestMaidenName || requestPlaceOfBirth) {%>\
                <fieldset>\
                    <p><%= raceBetsJS.i18n.data.msgResInfoMaidenName %></p>\
                </fieldset>\
                <fieldset>\
                    <% if (requestMaidenName) {%>\
                        <div>\
                            <label for="maidenName"><%= labelMaidenName %></label>\
                            <input type="text" id="maidenName" name="maidenName" class="c-input c-input--text">\
                        </div>\
                    <% } %>\
                    <% if (requestPlaceOfBirth) {%>\
                        <div>\
                            <label for="placeOfBirth"><%= labelPlaceOfBirth %></label>\
                            <input type="text" id="placeOfBirth" name="placeOfBirth" class="c-input c-input--text">\
                        </div>\
                    <% } %>\
                </fieldset>\
            <% } %>\
            <fieldset>\
                <label for="confirmLockout">\
                    <input type="checkbox" id="confirmLockout" class="c-input c-input--checkbox">\
                    <%= raceBetsJS.i18n.data.exclusionConfirm %>\
                </label>\
            </fieldset>\
            <fieldset>\
                <label for="confirmLockoutPassword"><%= raceBetsJS.i18n.data.labelPassword %></label>\
                <input type="password" id="confirmLockoutPassword" name="password" disabled class="c-input c-input--text">\
            </fieldset>\
            <fiedlset>\
            <% if (oasis) {%>\
                    <div id="dlg-lockout-cancel-notice" class="c-note c-note--error" style="display:none;"><%= raceBetsJS.i18n.data.exclusionConfirmNoteOasis %></div>\
                <% } else { %>\
                    <div id="dlg-lockout-cancel-notice" class="c-note c-note--error" style="display:none;"><%= raceBetsJS.i18n.data.exclusionConfirmNote %></div>\
                <% } %>\
            </fieldset>\
        </form>\
    ');

    raceBetsJS.application.templates.dialogs.sessions = _.template('\
        <p><%= raceBetsJS.i18n.data.msgMultipleActiveSessions %></p>\
        <form id="sessionsForm" action="/rest/v1/users/me/logout/sessions" method="post">\
        <p><br/><label><input type="checkbox" value="0" name="hideSessionsDialog" id="hideSessionsDialog" /> <%= raceBetsJS.i18n.data.labelHideDialog %></label></p>\
        <ol class="c-dataList rowsBordered">\
            <li class="c-dataList__row">\
                <span class="c-dataList__cell c-dataList__cell--device isHighlighted">\
                    <%= raceBetsJS.i18n.data.labelDevice %>\
                </span>\
                <span class="c-dataList__cell c-dataList__cell--browser isHighlighted">\
                    <%= raceBetsJS.i18n.data.labelBrowser %>\
                </span>\
                <span class="c-dataList__cell c-dataList__cell--logged isHighlighted">\
                    <%= raceBetsJS.i18n.data.labelLoggedInAt %>\
                </span>\
            </li>\
            <% $.each(sessions, function(key, session) { %>\
            <li class="c-dataList__row">\
                <span class="c-dataList__cell c-dataList__cell--device" title="IP: <%= session.ip_adress %>">\
                    <span class="c-iconFont c-iconFont--small c-iconFont--<%= (raceBetsJS.browser.type.getOS(session.user_agent) !== "") ? raceBetsJS.browser.type.getOS(session.user_agent).toLowerCase() : ((session.channel === "WEB") ? "desktop" : "mobile") %>"></span> <%= raceBetsJS.browser.type.getOS(session.user_agent) %>\
                </span>\
                <span class="c-dataList__cell c-dataList__cell--browser">\
                    <span class="c-iconFont c-iconFont--small c-iconFont--<%= raceBetsJS.browser.type.getBrowser(session.user_agent).toLowerCase() %>"></span> <%= raceBetsJS.browser.type.getBrowser(session.user_agent) %>\
                </span>\
                <span class="c-dataList__cell c-dataList__cell--logged">\
                    <%= raceBetsJS.format.date(raceBetsJS.i18n.data.dateFormatDateTime, session.start_time) %> <input type="checkbox" value="<%= session.id_session %>" name="selectedSessions[<%= session.id_session %>]" />\
                </span>\
            </li>\
            <% }) %>\
        </ol>\
        </form>\
    ');

    raceBetsJS.application.templates.dialogs.jurisdictionRedirect = _.template('\
        <p><%= obj.message %></p>\
        <form action="#" name="jurisdictionRedirectForm" method="post">\
            <label>\
                <input type="checkbox" id="rememberChoice" name="rememberChoice" checked="checked" class="c-input c-input--checkbox">\
                <%= obj.label %>\
            </label>\
        </form>\
    ');

    raceBetsJS.application.templates.dialogs.newTerms = _.template(
        '<div>'+
            '<div id="dlg-terms-changes" class="terms-changes terms-content"></div>'+
            '<div id="dlg-terms-full" class="terms-full terms-content" style="display:none;"></div>'+
            '<div class="footer">'+
                '<div><button type="submit" id="dlg-terms-accept" class="c-btn c-btn--large c-btn--primary"><%= labelAccept %></button></div>'+
                '<div>'+
                    '<button type="button" id="dlg-terms-show-full" class="c-btn c-btn--link"><%= labelViewFullTerms %></button>'+
                    '<button type="button" id="dlg-terms-show-changes" class="c-btn c-btn--link" style="display:none;"><%= labelViewTermsChanges %></button>'+
                '</div>'+
            '</div>'+
            '<div id="dlg-terms-loading" class="terms-loading"></div>'+
        '</div>'
    );

    raceBetsJS.application.templates.dialogs.optin = _.template('\
        <div>\
            <div>\
                <%= raceBetsJS.i18n.data.msgJoinPromotion %>\
            </div>\
            <label class="c-box__foot">\
                <input type="checkbox" id="marketingConsent" name="marketingConsent" class="c-input c-input--checkbox">\
                <%= raceBetsJS.i18n.data.msgJoinPromotionMailing %>\
            </label>\
        </div>\
    ');

    raceBetsJS.application.templates.dialogs.picture = _.template('\
        <a href="<%= url %>" target="_blank">\
            <img src="<%= src %>" >\
        </a>\
    ');

    raceBetsJS.application.templates.dialogs.advent = _.template('\
        <div class="c-advent-popup">\
            <a href="<%= url %>">\
                <img src="<%= src %>" >\
            </a>\
            <h2><%= message %></h2>\
        </div>\
    ');



})(raceBetsJS);
