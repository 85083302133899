/**
* Time
*/
(function(raceBetsJS) {
	raceBetsJS.time.toString = function(time, truncate, useAccusative) {

        var negative = false;
        if (time < 0) {
            negative = true;
            time = Math.abs(time);
        }

        var days    = Math.floor(time / 86400);
        var hours   = Math.floor(time % 86400 / 3600);
        var minutes = Math.floor(time % 86400 % 3600 / 60);
        var seconds = time % 86400 % 3600 % 60;

        if (truncate === undefined) {
            elemXDays = 'timeXDays';
            elemOneDay = 'timeOneDay';
            elemOneHour = 'timeOneHour';
            elemXHours = 'timeXHours';
            elemXMinutes = 'timeXMinutes';
            elemOneMinute = 'timeOneMinute';
        } else {
            elemXDays = (useAccusative !== undefined && useAccusative === true) ? 'timeLetterDays' : 'timeXDaysAccu';
            elemOneDay = 'timeLetterDays';
            elemXHours = 'timeLetterHours';
            elemXMinutes = 'timeLetterMinutes';
            elemOneMinute = 'timeLetterMinutes';
        }

        if (days > 1 && !negative) {
            return raceBetsJS.i18n.print(elemXDays, { days: days });
        } else if (days == 1 && !negative) {
            return raceBetsJS.i18n.print(elemOneDay, { days: days });
        } else if (hours > 0 && !negative) {
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            return raceBetsJS.i18n.print(elemXHours, { hours: hours + ':' + minutes });
        } else if (minutes > 1 && !negative) {
            return raceBetsJS.i18n.print(elemXMinutes, { minutes: minutes });
        } else if (minutes == 1 && !negative) {
            return raceBetsJS.i18n.print(elemOneMinute, { minutes: minutes });
        } else {
            return raceBetsJS.i18n.data.timeDue;
        }
	};
})(raceBetsJS);
