/**
* Archive popup
*
* @description: Archive stream player with runners
* @author Javier Cobos
* @author Andras Matzon
* @author Peter Ader
*/

(function(raceBetsJS) {
    raceBetsJS.application.content.archivePopup = function() {

        function onContentLoaded(data) {
            if ($.isEmptyObject(data)) {
                return false;
            }

            var $dom,
                runnersHeight,
                windowHeight = $(window).height(),
                hasFinalPositions = 0;

            var racePopup = function(idRace) {
                var popupOptions = {
                    href: raceBetsJS.application.globals.urlPrefix + '/race/details/id/' + idRace + '/popup/true/loadModules/race',
                    name: idRace,
                    height: 740,
                    width: 840,
                    scroll: 'no',
                    resizable: 'yes'
                };
                var popup = raceBetsJS.browser.popup.open(popupOptions);
            };

            var sortRunners = function(runnersToSort, sortBy) {
                var runners = [];

                switch (sortBy) {
                    case 'programNumber':
                        // Sort runners by programnumber
                        $.each(runnersToSort, function(i, runner) {
                            runners.push(runner);
                        });
                        runners.sort(function(a, b) {
                            return parseFloat(a.programNumber) - parseFloat(b.programNumber);
                        });
                        return runners;

                    case 'finalPosition':
                        var runnersWithFinalPos = [],
                            runnersWithNoFinalPos = [];

                        // Sort runners with no final position
                        $.each(runnersToSort, function(i, runner) {
                            if (runner.finalPosition === 0) {
                                runnersWithNoFinalPos.push(runner);
                            }
                        });
                        runnersWithNoFinalPos.sort(function(a, b) {
                            return parseFloat(a.programNumber) - parseFloat(b.programNumber);
                        });

                        // Sort runners with final position
                        $.each(runnersToSort, function(i, runner) {
                            if (runner.finalPosition > 0) {
                                runnersWithFinalPos.push(runner);
                            }
                        });
                        runnersWithFinalPos.sort(function(a, b) {
                            return parseFloat(a.finalPosition) - parseFloat(b.finalPosition);
                        });

                        // Merge sorted runners
                        runners = $.merge(runnersWithFinalPos, runnersWithNoFinalPos);
                        return runners;

                    default:
                        return runnersToSort;
                }
            };

            var initEvents = function() {
                function sortTable() {

                    var target = $(this),
                        factor = target.hasClass('horse-header-number'),
                        sortFactor = factor ? 'programNumber' : 'finalPosition',
                        activeClass = factor ? '.horse-header-number' : '.horse-header-final-pos';


                    $('.runner-details').remove();

                    $domRunnerDetails = $( raceBetsJS.application.templates.archivePopup.runnerDetails({
                            'data': {
                                'event': data.event,
                                'race': data.race,
                                'runners': ($.isEmptyObject(data.runners)) ? {} : sortRunners(data.runners, sortFactor)
                            }
                        })
                    );

                    $('#content-info').append($domRunnerDetails);
                    $('.runner-details').css({
                        'overflow': 'auto',
                        'height': runnersHeight
                    });
                    $(activeClass).addClass('active')
                    $('.runner-details').on('click', '.horses-table-header .clickable', sortTable);
                }
                $dom.on('click', '.horses-table-header .clickable', sortTable);

                $dom.on('click','.toracecard', function (e) {
                    e.preventDefault();
                    raceBetsJS.browser.crossFrame.send('open', $(this).attr('href'));
                });
            };

            // Set final positions true/false to display checkbox or not
            $.each(data.runners, function(i, runner) {
                hasFinalPositions += runner.finalPosition
            });
            hasFinalPositions = (hasFinalPositions > 1) ? true : false;

            // Create and append content from template
            $dom = $( raceBetsJS.application.templates.archivePopup({
                    'data': {
                        'event': data.event,
                        'race': data.race,
                        'runners': ($.isEmptyObject(data.runners)) ? {} : sortRunners(data.runners, 'programNumber'),
                        'hasFinalPositions': hasFinalPositions
                    }
                })
            );
            $('body').css('overflow', 'hidden');
            $('#content-info').append($dom);

            // Init stream in popup
            raceBetsJS.application.assets.media.initPopup();

            // Set scroll for runners if window is small
            if (windowHeight < $('#media-browser').outerHeight()) {
                runnersHeight = windowHeight - $('#content-media').outerHeight() - $('#content-info .event-details').outerHeight() - $('#content-info .race-details').outerHeight();

                $('#content-info .runner-details').css({
                    'overflow': 'auto',
                    'height': runnersHeight
                });
            }

            $('.horse-header-number').addClass('active');

            // Init events
            initEvents();
        }

        // @public
        return {
            init: function() {
                // Setup route controller
                raceBetsJS.application.contentController.addDynamicPage({
                    urlPattern: /^\/media\/archivePopup\/idRace\/(\d+)\/id\/(\d+)\/?$/i,
                    dataSourceURLBuilder: function(_idRace, _id) {
                        return '/ajax/media/racedetails/id/' + _idRace + '/';
                    },
                    onDataReceived: onContentLoaded
                });
            }
        };
    }();
})(raceBetsJS);
