/**
* Particular Data
*
* @author: Robin Ebers
*/

(function(raceBetsJS) {
    raceBetsJS.application.content.featuredHorses = function() {
        // @private

        function onFeaturedHorsesStars() {

	        Tipped.create($('#featured-horses-panels').find('[data-tipped]'), function(elem) {
	            return $(elem).data('tipped');
	        },
	        {
	            hook: 'bottomleft',
	            skin: 'racebets',
	            maxWidth: 400,
	            offset: { y: -10, x: 9 },
                hideOn: [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' },
                    { element: 'tooltip', event: 'click' }
                ]
	        });

            $(raceBetsJS.application.globals.contentMain).find('div.column.left .odds-button').each(function() {
                var fixedOdds = $(this).hasClass('fixed'),
                    button = null;

                if(fixedOdds){
                    button = new raceBetsJS.application.content.race.OddsButton($(this), 'FXW');
                } else {
                    button = new raceBetsJS.application.content.race.OddsButton($(this), 'PRC');
                }
                button.setOdds($(this).children('span').data('odds'), $(this).hasClass('enabled'));
            })

            $(raceBetsJS.application.globals.contentMain).find('div.panel').on('mouseup', '.odds-button.fixed.enabled', function(e) {
                e.preventDefault();
                raceBetsJS.application.assets.bettingDialog.show(this);
            });

            $(raceBetsJS.application.globals.contentMain).find('div.panel').on('mouseup', '.odds-button.price.enabled', function(e) {
                e.preventDefault();
                raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/race/details/id/' + $(this).attr('data-id-race'));
            });
        }

        function showHomelandFlag(countryCode) {
            return ['DE', 'GB', 'IT', 'FR', 'IE', 'ES', 'SE', 'US', 'ZA'].indexOf(countryCode) > -1;
        }

        // @public
        return {
            init: function() {
                // add content loaded observer
                raceBetsJS.application.contentController.addCallback(
                    /^\/content\/show\/module\/featuredhorses\//, onFeaturedHorsesStars);
            },
            showHomelandFlag: showHomelandFlag
        };
    }();
})(raceBetsJS);
