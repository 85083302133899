/**
* Object
*/
(function(raceBetsJS) {
	raceBetsJS.object = {

        /**
        * Compare two objects recursively
        */
        equals: function(obj1, obj2) {
            if(obj1 instanceof Object && obj2 instanceof Object) {
                var obj3 = new Object();

                for (var property in obj1) { obj3[property] = obj1[property]; }
                for (var property in obj2) { obj3[property] = obj2[property]; }

                for(var property in obj3) {
                    if(obj1.hasOwnProperty(property) && obj2.hasOwnProperty(property)) {
                        if(!raceBetsJS.object.equals(obj1[property], obj2[property])) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            } else if(typeof obj1 != typeof obj2) {
                return false;
            } else if(obj1 != obj2) {
                return false;
            }

            return true;
        },

        sortByProperty: function (arrayInObject, propertyName, reverse) {
            if (arrayInObject && arrayInObject.constructor === Array){
                var propPath = (propertyName.constructor === Array) ? propertyName : propertyName.split('.');
                arrayInObject.sort(function(a,b){
                    for (var p in propPath){
                        if (a[propPath[p]] && b[propPath[p]]){
                            a = a[propPath[p]];
                            b = b[propPath[p]];
                        }
                    }
                    // convert numeric strings to integers
                    a = (typeof a == 'string' && a.match(/^\d+$/)) ? +a : a;
                    b = (typeof b == 'string' && b.match(/^\d+$/)) ? +b : b;

                    return ( (a < b) ? -1 : ((a > b) ? 1 : 0) );
                });

                // reverse in case it's true
                if (reverse !== undefined && reverse === true) {
                    arrayInObject.reverse();
                }
            }

        },

        getByProperty: function (obj, propertyName, value) {
            var returnValue = false;
            $.each(obj, function() {
                if (this[propertyName] == value) {
                    returnValue = this;
                }
            });

            return returnValue;
        }
    };
})(raceBetsJS);