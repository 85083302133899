/**
* @description home template
*
*/
(function(raceBetsJS) {
	raceBetsJS.application.templates.home = _.template(
        '<div id="hp_area_top" class="m-home__widgetArea">' +
            '<div class="m-home__widgetAreaTopCol1"><div id="mainBanner" class="panel mainBanner c-widget--banner"></div></div>' +
            '<div class="m-home__widgetAreaTopCol2"></div>' +
        '</div>' +
        '<div id="hp_area_middle" class="m-home__widgetArea">' +
            '<div class="m-home__widgetAreaMidLeft">' +
                '<div class="m-home__widgetAreaMidLeftTop"></div>' +
                '<div class="m-home__widgetAreaMidLeftCol1"></div>' +
                '<div class="m-home__widgetAreaMidLeftCol2"></div>' +
            '</div>' +
            '<div class="m-home__widgetAreaMidRight"></div>' +
        '</div>' +
		'<div id="hp_area_bottom" class="m-home__widgetArea"></div>' +
		'<div id="seo_text_home_page"></div>' +
		'<div id="show_more"><span id="button_seo_text"><%= raceBetsJS.i18n.data.labelShowMore %></span><span class="c-caret toDown" role="button"></span> </div>'
	);

	raceBetsJS.application.templates.home.nextRaces = _.template(
		'<ul class="races"></ul>' +
		'<div class="showcase">' +
			'<div class="container">' +
				'<div class="info"></div>' +
				'<ul class="runners"></ul>' +
			'</div>' +
		'</div>'
	);

	raceBetsJS.application.templates.home.nextRaces.showcase = _.template(
        '<div class="info">' +
            '<span class="c-flag isCountry<%= country %>"></span>' +
            '<strong><%= raceBetsJS.i18n.getRaceTitle(raceBetsJS.string.truncate(raceTitle, 30), raceNumber) %></strong> <br />' +
            '<span class="info-details"><%= raceBetsJS.i18n.print("numStarters", { numStarters: runners }) %> | <%= raceBetsJS.i18n.print("timeLong", {time:raceBetsJS.application.assets.timezone.date("H:i", raceTime)}) %></span>' +
        '</div>' +
        '<ul class="runners">' +
            '<% $.each(topRunners, function(i){ %>' +
                '<li class="<% if(i%2==0) { %>even<% } %> ">' +
                    '<% if(country === "US" || country === "CA") { %>' +
                        '<span class="silk us us-<%= raceType + this.programNumber %>"><span><%= this.programNumber %></span></span>' +
                    '<% } else if (this.silkExtension !== "" && this.silkExtension !== null && showSilks) { %>' +
                        '<span class="silk"><img src="<%= raceBetsJS.application.globals.imageHost %>/cache/img/silks/<%= this.silkId %>_w42.<%= this.silkExtension %>" width="21" height="20" /></span>' +
                    '<% } %>' +
                    '<span class="name"><%= this.name %></span>' +
                    '<button class="odds-button<% if(this.odds != null) { %> enabled<% } %><%= (fixedOddsStatus == "ON") ?  " fixed" : " price" %>" data-id-race="<%= idRace %>" data-id-runner="<%= this.idRunner %>" data-bet-type="WIN"><span data-odds="<%= this.odds %>"></span></button>' +
                '</li>' +
            '<% }); %>' +
        '</ul>' +
        '<div class="full">' +
            '<a href="<%= raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + idRace %>" class="ajaxify grey-arrows"><%= raceBetsJS.i18n.data.linkGoToRace %></a>' +
        '</div>'
	);

	raceBetsJS.application.templates.home.nextRaces.race = _.template(
		'<a href="<%= raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + idRace %>" class="ajaxify">' +
			'<span class="track type"><%= title %></span>' +
			'<span class="time"></span>' +
		'</a>'
	);

	raceBetsJS.application.templates.home.highlights = _.template(
		'<ul class="races"></ul>' +
		'<a href="<%= raceBetsJS.application.globals.urlPrefix %>/content/show/module/highlights" class="more ajaxify"><%= raceBetsJS.i18n.data.linkMoreHighlights %></a>' +
		'<div class="showcase">' +
			'<div class="container">' +
				'<div class="info"></div>' +
				'<ul class="runners"></ul>' +
			'</div>' +
		'</div>'
	);

	raceBetsJS.application.templates.home.highlights.showcase = _.template('\
			<div class="info">\
				<strong><%= title %> | <%= raceBetsJS.i18n.print("raceCategory" + category) %></strong> <br />\
				<span class="info-details"><%= raceBetsJS.i18n.print("numStarters", { numStarters: runnersNum }) %> | <%= raceBetsJS.format.money(purse, 0, purseCurrency) %> | <%= raceBetsJS.i18n.print("timeLong", {time:raceBetsJS.application.assets.timezone.date("H:i", postTime)}) %></span>\
			</div>\
			<ul class="runners">\
				<% $.each(topRunners, function(i){ %>\
					<% if(i < 3) { %>\
						<li class="<% if(i%2==0) { %>even<% } %> ">\
							<% if(raceStatus == "FNL") { %>\
								<span class="position"><%= i + 1 %>.</span>\
							<% } %>\
							<% if(country === "US" || country === "CA") { %>\
								<span class="silk us us-<%= raceType + this.programNumber %>"><span><%= this.programNumber %></span></span>\
							<% } else if (this.silkExtension !== "" && this.silkExtension !== null && showSilks) { %>\
								<span class="silk"><img src="<%= raceBetsJS.application.globals.imageHost %>/cache/img/silks/<%= this.silkId %>_w42.<%= this.silkExtension %>" width="21" height="20" /></span>\
							<% } %>\
							<span class="name"><%= this.name %></span>\
							<% if((raceStatus == "FNL" && i < 1) || (raceStatus != "FNL")) { %>\
								<button class="odds-button<% if(raceStatus == "OPN") { %> enabled<% } %><%= (fixedOddsStatus == "ON") ?  " fixed" : " price" %>" data-id-race="<%= idRace %>" data-id-runner="<%= this.idRunner %>" data-bet-type="WIN"><span data-odds="<%= this.odds %>"></span></button>\
							<% } %>\
						</li>\
					<% } %>\
				<% }); %>\
			</ul>\
			<div class="full">\
				<a href="<%= raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + idRace %>" class="ajaxify grey-arrows"><%= raceBetsJS.i18n.data.linkGoToRace %></a>\
			</div>\
	');

	raceBetsJS.application.templates.home.highlights.race = _.template('\
		<a href="<%= raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + idRace %>" class="ajaxify">\
			<span class="c-flag isCountry<%= country %>"></span>\
			<span class="track"><div class="truncated"><%= raceBetsJS.i18n.getRaceTitle(raceTitle, raceNumber) %></div><div class="full"><%= raceTitle %></div></span>\
			<span class="time"><%= raceBetsJS.i18n.print("raceCategoryAbbr" + category) %></span>\
		</a>\
	');

	raceBetsJS.application.templates.home.antePostRaces = _.template('\
		<div class="details">\
			<ul<% if (!data.hasSilks) { %> class="no-silks"<% } %>>\
				<% if ( !$.isEmptyObject(data.topRunners) ) { %>\
				<% $.each(data.topRunners, function(i, runner) { %>\
					<li>\
						<span class="silk">\
						<% if (runner.silkExtension !== "" && runner.silkExtension != null ) { %>\
							<img src="<%= raceBetsJS.application.globals.imageHost %>/cache/img/silks/<%= runner.silkId %>_w42.<%= runner.silkExtension %>" width="21" height="20" alt="" />\
						<% } else { %>\
							<img src="<%= raceBetsJS.application.globals.imageHost + "/images/cards/silks/slw.png" %>" width="21" height="20" alt="" />\
						<% } %>\
						</span>\
						<span class="name"><%= runner.name %></span>\
						<button class="odds-button<% if(data.raceStatus == "OPN") { %> enabled<% } %><%= (data.fixedOddsStatus == "ON") ?  " fixed" : " price" %>" data-id-race="<%= data.idRace %>" data-id-runner="<%= runner.idRunner %>" data-bet-type="WIN"><span data-odds="<%= runner.odds %>"></span></button>\
					</li>\
				<% }); %>\
				<% } %>\
			</ul>\
			<div class="more">\
				<a href="<%= raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + data.idRace %>" class="ajaxify grey-arrows"><%= raceBetsJS.i18n.data.linkGoToRace %></a>\
			</div>\
		</div>\
	');
	raceBetsJS.application.templates.home.antePostRaces.image = _.template('\
		<div class="image-overlay">\
			<img src="" alt="" class="image1" /><img src="" alt="" class="image2" />\
			<div class="image-overlay-mask"></div>\
		</div>\
	');
	raceBetsJS.application.templates.home.antePostRaces.controls = _.template('\
		<div class="controls">\
			<ul>\
				<% if ( !$.isEmptyObject(data.antepostEvent) ) { %>\
				<% $.each(data.antepostEvent, function(i) { %>\
					<li data-antepost-event="<%= this.idRace %>"></li>\
				<% }); %>\
				<% } %>\
			</ul>\
		</div>\
	');
	raceBetsJS.application.templates.home.antePostRunners = _.template('\
		<a title="<%= name %>" href="<%= raceBetsJS.application.globals.urlPrefix + "/content/show/module/featuredhorses/cat/antepost" %>" class="ajaxify">\
			<span class="horse"><%= name %></span>\
			<span class="c-flag isCountry<%= countryAntepost %>"></span></span>\
			<span class="event"><%= raceTitleAntepost %></span>\
		</a>\
		<button class="odds-button<% if(raceStatus == "OPN") { %> enabled<% } %><%= (fixedOddsStatus == "ON") ?  " fixed" : " price" %>" data-id-race="<%= idRace %>" data-id-runner="<%= idRunner %>" data-bet-type="WIN"><span data-odds="<%= odds %>"></span></button>\
	');
	raceBetsJS.application.templates.home.europeansAbroad = _.template('\
		<a href="<%= raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + idRace %>" class="ajaxify">\
		    <span class="name"><%= name %></span>\
            <% if(finalPosition != null){ %><span class="light-grey"><%= "(" + finalPosition + ".)" %></span><% } %>\
            <span class="c-flag isCountry<%= origin %>"></span>\
		</a>\
		<button class="odds-button<% if(raceStatus === "OPN") { %> enabled<% } %><%= (fixedOddsStatus == "ON") ?  " fixed" : " price" %>" data-id-runner="<%= idRunner %>" data-id-race="<%= idRace %>" data-bet-type="WIN"><span data-odds="<%= odds %>"></span></button>\
	');
	raceBetsJS.application.templates.home.horsesAbroad = _.template('\
		<a href="<%= raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + obj.id_race %>" class="ajaxify">\
		    <span class="silk">\
		    <% if(obj.silk_extension) { %>\
		        <img src="<%= raceBetsJS.application.globals.imageHost %>/cache/img/silks/<%= obj.silk_id %>_w42.<%= obj.silk_extension %>" width="21" height="20" />\
		    <% } else { %>\
		        <span class="c-runnerSilk c-runnerSilk--s hasNoSilk"></span>\
		    <% } %>\
		    </span>\
		    <span class="name"><%= obj.name %></span>\
			<% if (obj.final_position) { %><span class="light-grey"><%= "(" + obj.final_position + ".)" %></span><% } %>\
			<span class="c-flag c-flag--rounded isCountry<%= obj.homeland %>" title="<%= raceBetsJS.i18n.data.labelHomeland %>: <%= raceBetsJS.i18n.data.countries[obj.homeland] %>"></span>\
			<span class="c-iconFont c-iconFont--angle-right"></span>\
			<span class="c-flag isCountry<%= obj.event_country %>"></span>\
		</a>\
		<% if (obj.fixed_odds_status) { %>\
		    <button class="odds-button<% if (obj.race_status === "OPN") { %> enabled<% } %> fixed" data-id-runner="<%= obj.id_runner %>" data-id-race="<%= obj.id_race %>" data-bet-type="WIN"><span data-odds="<%= odds.FXW %>"></span></button>\
		<% } else { %>\
		    <button class="odds-button<% if (obj.race_status === "OPN") { %> enabled<% } %> price" data-id-runner="<%= obj.id_runner %>" data-id-race="<%= obj.id_race %>" data-bet-type="WIN"><span data-odds="<%= odds.PRC %>"></span></button>\
		<% } %>\
	');

	raceBetsJS.application.templates.home.head2head = _.template(
		'<div class="info">' +
            '<span class="title">' +
                '<span class="c-flag isCountry<%= country %>"></span>' +
                '<strong><%= title %> | <%= raceBetsJS.i18n.print("raceNumberX", {"race":raceNumber}) %></strong>' +
            '</span><br>' +
            '<span class="details"><%= raceBetsJS.i18n.print("timeLong", {time:raceBetsJS.application.assets.timezone.date("H:i", raceTime)}) %> | <%= raceBetsJS.format.distance(distance, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) %> | <%= raceBetsJS.format.money(purse, 0, purseCurrency) %></span>' +
		'</div>' +
		'<div class="runners">' +
			'<ul>' +
				'<% $.each(topRunners, function(i){ %>' +
					'<li <% if(i==0){ %> class="first" <% } %>>' +
						'<% if(this.silkExtension !== "" && this.silkExtension !== null && showSilks) { %>' +
                            '<span class="silk"><img src="<%= raceBetsJS.application.globals.imageHost %>/cache/img/silks/<%= this.silkId %>_w42.<%= this.silkExtension %>" width="21" height="20" /></span>' +
						'<% } %>' +
						'<span class="name"><%= this.name %></span>' +
						'<button class="odds-button<% if(this.fixedOddsWin != null) { %> enabled<% } %> fixed" data-id-race="<%= idRace %>" data-id-runner="<%= this.idRunner %>" data-bet-type="WIN"><span data-odds="<%= this.fixedOddsWin %>"></span></button>' +
					'</li>' +
				'<% }); %>' +
			'</ul>' +
		'</div>' +
		'<div class="controls">' +
			'<button class="c-btn c-btn--xs c-btn--default" data-direction="prev"><span class="c-iconFont c-iconFont--caret-left"></span></button>' +
			'<button class="c-btn c-btn--xs c-btn--default" data-direction="next"><span class="c-iconFont c-iconFont--caret-right"></span></button>' +
		'</div>'
	);

	raceBetsJS.application.templates.home.marketMovers = _.template('\
		<a href="<%= raceBetsJS.application.globals.urlPrefix + "/content/show/module/featuredhorses/cat/marketmovers" %>" class="ajaxify">\
			<span class="horse type"><%= name %></span>\
			<span class="event"><%= title %></span>\
			<span class="trend <% if(newOdds > oldOdds) { %> up<% } else if(newOdds == oldOdds) { %> even<% } else { %> down<% } %>"></span>\
		</a>\
		<button class="odds-button<% if(raceStatus == "OPN") { %> enabled<% } %><%= (fixedOddsStatus == "ON") ?  " fixed" : " price" %>" data-id-race="<%= idRace %>" data-id-runner="<%= idRunner %>" data-bet-type="WIN"><span data-odds="<%= newOdds %>"></span></button>\
	');
	raceBetsJS.application.templates.home.mostBet = _.template('\
		<a href="<%= raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + idRace %>" class="ajaxify">\
			<span class="horse type"><%= name %></span>\
		    <span class="post"><%= raceBetsJS.i18n.print("timeLong", {time:raceBetsJS.application.assets.timezone.date("H:i", postTime)}) %></span>\
		</a>\
		<button class="odds-button<% if(raceStatus == "OPN") { %> enabled<% } %> price" data-id-race="<%= idRace %>" data-bet-type="WIN"><span data-odds="<%= odds %>"></span></button>\
	');
	raceBetsJS.application.templates.home.stars = _.template('\
		<a href="<%= raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + idRace %>" class="ajaxify" <% if(comment != null) { %> data-comment="<%= comment %>" <% } %> data-name="<%= name %>">\
			<span class="c-flag isCountry<%= country %>"></span>\
			<span class="name"><%= name %></span>\
			<% if(finalPosition != null){ %><span class="light-grey"><%= "(" + finalPosition + ".)" %></span><% } %>\
		</a>\
		<button class="odds-button<% if(raceStatus == "OPN") { %> enabled<% } %><%= (fixedOddsStatus == "ON") ?  " fixed" : " price" %>" data-id-runner="<%= idRunner %>" data-id-race="<%= idRace %>" data-bet-type="WIN"><span data-odds="<%= odds %>"></span></button>\
	');

    raceBetsJS.application.templates.home.banners = _.template(
        '<div class="container">' +
            '<ul class="banners">' +
                '<% $.each(banners, function() { %>' +
                    '<li>' +
						'<a href="<%= this.url %>" style="background-image:url(<%= this.imagePath %>)"' +
                            '<% if (this.url.substr(0,4) !== "http") { %> class="ajaxify"<% } %>' +
							'<% if (this.target === "_blank") { %> target="_blank"<% } %>' +
                            '>' +
                            '<% if(this.showUkTermsAndConditions) { %>'+
                                '<div class="terms-and-conditions"><%= this.ukTermsAndConditionsText %></div>'+
                            '<% } %>'+
                        '</a>' +
                        '<% if (this.termsUrl) { %> <a class="termsLink grey-arrows" href=" <%= this.termsUrl %> " target="_blank"><%= raceBetsJS.i18n.data.labelTAndC %></a> <% } %>'+
                    '</li>' +
                '<% }); %>' +
            '</ul>' +
            '<ul class="buttons">' +
                '<% $.each(banners, function(i) { %>' +
                    '<li data-banner="<%= i %>"></li>' +
                '<% }); %>' +
            '</ul>' +
        '</div>'
    );

    raceBetsJS.application.templates.home.raceOfDay = _.template(
        '<ul>' +
            '<li>' +
                '<span class="race">' +
                    '<span class="c-flag isCountry<%= country %>"></span>' +
                    '<%= title %>' +
                '</span>' +
                '<span class="post"><%= raceBetsJS.i18n.print("timeLong", {time:raceBetsJS.application.assets.timezone.date("H:i", raceTime)}) %></span>' +
            '</li>' +
        '</ul>' +
        '<p><%= content %></p>'
    );

    raceBetsJS.application.templates.home.topRatio = _.template(
        '<a href="<%= raceBetsJS.application.globals.urlPrefix + "/content/show/module/betslips/cat/rto" %>" class="ajaxify">' +
            '<span class="stakes"><%= raceBetsJS.format.money(raceBetsJS.localize.exchange(stakes, currency, raceBetsJS.application.user.currency), 2, raceBetsJS.application.user.currency, true) %></span>' +
            '<span class="arrow">&rarr;</span>' +
            '<span class="winning"><%= raceBetsJS.format.money(raceBetsJS.localize.exchange(amount, currency, raceBetsJS.application.user.currency), 0, raceBetsJS.application.user.currency, true) %></span>' +
            '<span class="ratio ">x <%= raceBetsJS.format.number(ratio, ((ratio > 10) ? 0 : 1)) %></span>' +
        '</a>'
    );

    raceBetsJS.application.templates.home.topWinnings = _.template(
        '<a href="<%= raceBetsJS.application.globals.urlPrefix + "/content/show/module/betslips/cat/win" %>" class="ajaxify">' +
            '<span class="stakes"><%= raceBetsJS.format.betTypeName(betType, betCategory, raceBetsJS.application.user.lang, false) %></span>' +
            '<span class="winning">' +
                '<span class="arrow"></span>' +
                '<%= raceBetsJS.format.money(raceBetsJS.localize.exchange(amount, currency, raceBetsJS.application.user.currency), 0, raceBetsJS.application.user.currency, true) %>' +
            '</span>' +
        '</a>'
    );

    raceBetsJS.application.templates.home.tips = _.template(
        '<a href="<%= raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + idRace %>" class="ajaxify">' +
            '<span class="horse type"><%= name %></span>' +
            '<span class="title"><strong><%= raceBetsJS.i18n.data["tips" + raceBetsJS.string.ucfirst(type)] %></strong></span>' +
        '</a>' +
        '<button class="odds-button<% if(raceStatus == "OPN") { %> enabled<% } %><% if(odds == 0) { %> large<% } %> price" data-id-race="<%= idRace %>" data-bet-type="WIN">' +
            '<span data-odds="<%= odds %>"><%= (odds > 0) ? raceBetsJS.format.odds(odds, null, true) : ((raceStatus == "OPN") ? raceBetsJS.i18n.data.buttonBet : "-") %></span>' +
        '</button>'
    );

})(raceBetsJS);
