/**
* Todays Starters
*
* @author: Robin Ebers
*/

(function(raceBetsJS) {
    raceBetsJS.application.content.starters = function() {
        // @private
        function onContentLoaded() {
            var dom = {
                calendarInput: $('#starters-date'),
                chanegeDayButtons: $('.c-toggleGroup--featuredHorsesCalendar .chanege-day-buttons'),
                selectInputs: $('.c-select select'),
                submitButton: $('#button-update'),
                startersType: $('#starters-type'),
                raceType: $('#race-type'),
                pagination: $('#starters-panel div.pagination')
            };

            dom.calendarInput.datepicker({
                firstDay: 1,
                dateFormat: '@',
                changeMonth: true,
                changeYear: true,
                updateInput: false,
                defaultDate: new Date($('#starters-date').data('timestamp') * 1000),
                onSelect: function(dateText) {
                    dateText = new Date(parseInt(dateText));
                    setNewDateOnCalendar(dateText);
                }
            });

            function chengeDay(event) {
                var date = dom.calendarInput.datepicker('getDate'),
                    offset = parseInt(event.currentTarget.dataset.offset);

                date.setDate(date.getDate() + offset);
                setNewDateOnCalendar(date);
            };

            function setNewDateOnCalendar(newDate) {
                dom.calendarInput.data('timestamp', newDate.getTime() / 1000);
                dom.calendarInput.attr('data-timestamp', newDate.getTime() / 1000);
                dom.calendarInput.datepicker('option', 'defaultDate', newDate);

                raceBetsJS.localize.time( dom.calendarInput, raceBetsJS.application.assets.settings.get().dialog.general.timezone);
            }

            function changeSelection(event) {
                var elem = $(event.currentTarget);
                elem.next('.c-select__value').text(elem.find('option:selected').text());
            }

            function updateTable() {
                var href = '',
                    offset = dom.pagination.find('a.current').text();

                href += 'content/show/module/starters/cat/';
                href += dom.startersType.val();
                href += '/date/';
                href += dom.calendarInput.data('timestamp');
                href += dom.raceType.val() ? '/type/' + dom.raceType.val() : '';
                href += '/' + ((dom.startersType.val() === 'postTime') ?
                                'hour/' +  (/^\d+$/.test(offset) ? offset : new Date().getHours()) :
                                'offset/' + (/^\d+$/.test(offset) ? 'A' : offset));

                raceBetsJS.browser.history.navigateTo(href);
            }

            dom.selectInputs.each(function(idx,elem) {
                elem = $(elem)
                elem.change(changeSelection);
                elem.next('.c-select__value').text(elem.find('option:selected').text());
            });
            dom.chanegeDayButtons.click(chengeDay);
            dom.submitButton.click(updateTable);

        }

        // @public
        return {
            init: function() {
                // add content loaded observer
                raceBetsJS.application.contentController.addCallback(/\/content\/show\/module\/starters/, onContentLoaded);
            }
        };
    }();
})(raceBetsJS);
