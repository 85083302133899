(function(raceBetsJS) {
    raceBetsJS.application.content.formguide.chart = function() {

        function onChart(_data, resizeW, resizeH) {
            if ($.isEmptyObject(_data)) {
                return false;
            }

            // total runners array
            var tRArray = [];
            // final positions
            var fPArray = [];
            // X axis values array
            var xValues = [];
            // Maximum number of runners in single race
            var maxTotalRunners = 0;
            // IdRaces for tooltips
            var idRaces = [];
            // tooltip selector class
            var ttClass = 'tooltipselector';

            var graph, graphOptions,
                colorSetings = raceBetsJS.application.assets.settings.getBrandSettings('chartColors');

            $.each(_data, function(i, race) {
                if (i==9) {
                    return false;
                }

                var nr = race.numRunners;
                var auxXValues = [];
                var raceCat = "";

                idRaces.push(race.idRace);

                auxXValues.push( raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.formatDateLong, race.date) );
                auxXValues.push( raceBetsJS.i18n.data["trackSurface"+race.trackSurface] || "-" );

                // RACE CATEGORY / HANDICAP
                if(race.category != "") {
                    if(race.raceType == "D") {
                        raceCat = race.category;
                    } else {
                        raceCat = raceBetsJS.i18n.data["raceCategory" + race.category];
                    }
                }

                auxXValues.push(raceCat);

                xValues.push(auxXValues);

                tRArray.push(nr);

                fPArray.push(race.finalPosition || 'x');

                if(nr > maxTotalRunners) {
                    maxTotalRunners = nr;
                }
            });

            // reverse data to show most recent on the right hand side
            idRaces.reverse();
            xValues.reverse();
            tRArray.reverse();
            fPArray.reverse();


            switch (raceBetsJS.application.globals.brandName ) {
                case 'betsafe':
                    var poligonColor = '#1b1b1d';
                    var lineColor = '#ffc23d';
                    var dotColor = '#ffc23d';
                    break;

                case 'betsson':
                case 'suaposta':
                    var poligonColor = '#2E3542';
                    var lineColor = '#FFFFFF';
                    var dotColor = '#FF6602';
                    break;

                case 'nordicbet':
                    var poligonColor = '#2F4152';
                    var lineColor = '#ffffff';
                    var dotColor = '#ffffff';
                    break;

                case "europebet":
                    var poligonColor = "#1b1b1d";
                    var lineColor = "#ffc23d";
                    var dotColor = "#ffc23d";
                    break;

                default:
                    var poligonColor = '#000000';
                    var lineColor = '#ffffff';
                    var dotColor = '#ffffff';
                    break;
            }

            graphOptions = {
                canvas: $('#canvas-graph'),
                type: 'PERF',
                width: 810,
                height: 500,
                noDataSign: 'x',
                xAxis: {
                    height: 0,
                    type: 'FIXED',
                    values: xValues,
                },
                yAxis : {
                    width: 0,
                    type: 'RANGE',
                    range: [1, maxTotalRunners],
                    separatorModule: 5,

                },
                padding: {top: 20, right: 40, bottom: 50, left: 40},
                dataGroups: [
                    {
                        type: 'POLYGON',
                        data: tRArray,
                        color: poligonColor
                    },
                    {
                        type: 'LINE',
                        data: fPArray,
                        dotColor: dotColor,
                        lineColor: lineColor
                    }
                ]
            };

            graphOptions.xAxis.color = colorSetings.xAxisColor;
            graphOptions.xAxis.textColor = colorSetings.xAxisTextColor;
            graphOptions.yAxis.color = colorSetings.yAxisColor;
            graphOptions.yAxis.textColor = colorSetings.yAxisTextColor;
            graphOptions.yAxis.topStrokeColor = colorSetings.yAxisTopStrokeColor;
            graphOptions.yAxis.gridStrokeColor = colorSetings.yAxisGridStrokeColor;
            graphOptions.yAxis.every5gridStrokeColor = colorSetings.yAxisEvery5gridStrokeColor;

            graph = RBGraph(graphOptions);

            // display chart
            graph.render();

            // display leyend items
            graph.renderLeyend({
                list: $('#leyend-list'),
                fields: [
                    {
                        label: (raceBetsJS.i18n.data.labelNumberOfRunners || ''),
                        color: raceBetsJS.application.globals.brandName === 'nordicbet' ? '#2F4152' : "#1B1B1D"
                    }
                ]
            });

            // generate
            graph.renderTooltips({
                container: $('#graph-container'),
                ttclass: ttClass,
                // which element we set on data attribute to be retrieved on hover
                elemData: {
                    key: 'idrace',
                    data: idRaces
                }
            });

            // Tooltips.
            Tipped.create('div.' + ttClass, function(e) {
                    var $e = $(e),
                    idRace = $e.data('idrace'),
                    result = "";

                    $.each(_data, function(i, market) {
                        if (market.idRace == idRace) {
                            result = raceBetsJS.application.templates.formguide.chartDotTooltip({ data : market });
                            return false;
                        }
                    });

                    return result;
            },
            {
                skin: 'formguideChart'
            });

            // init
            function init() {
                $("body, #wrapper").css({"overflow-y":"hidden"});

                // F*ing Chrome bug:
                // #horse elem width not expanding on Chart page, when coming from a page
                // that had a scrollbar (e.g. Statistics).
                // No CSS rule solved it (not even by JS), but if you modify stuffs in dev tools, it fix itself...
                // Initiating mind trick: "This is not the script you are looking for!"
                var isChrome = !!window.chrome && !!window.chrome.webstore,
                    $doc = $(document),
                    $bFx = $doc.data('ChromeBugFix');

                if (isChrome) {
                    window.resizeTo( ($bFx ? ++resizeW : --resizeW) , resizeH);
                    $doc.data('ChromeBugFix', !$bFx);
                }
                // If you ever read this in the future, please revalidate the use case!
                // Or a kitten will die :(
            }

            init();
        }
        return {
            init: onChart
        }
    }();
})(raceBetsJS);
