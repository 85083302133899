/**
* Overlay Class
*
* @description Implements the overlay and required functions by modal dialog functions
*
* @author Robin Ebers
*/
(function(raceBetsJS) {
    raceBetsJS.application.assets.overlay = function() {
        var stack = [], // global stack of modal windows
            $document = $(document),
            $window = $(window),
            SCROLLBAR_WIDTH = 0;

        $document
            .on('ready', function () {
                // As long as we don't have scrollbar in the iframe this function is unnecessary
                // and SCROLLBAR_WIDTH should stay 0
                if (!raceBetsJS.application.globals.isB2B) {
                    SCROLLBAR_WIDTH = _measureScrollbar();
                }
            })
            // overlay background close
            .on('click', '.overlay', $.proxy(function() {
                if (stack[stack.length-1].getOptions().backgroundClose) {
                    close();
                }
            }))
            .on('click', '.modal-dialog', function (e) {
                e.stopPropagation();
            })
            // dialog close button
            // with the new router close also when a link is clicked
            .on('click', '.modal-dialog .close-button, .modal-dialog a[href^="/"][target!="_blank"]', function() {
                // CHECK if onClose is defined
                var onClose = (stack[stack.length-1].options.onClose !== undefined) ? stack[stack.length-1].options.onClose : false;

                // close
                close();

                // TRIGGER onClose
                // this needs to be done AFTER removing the latest dialog, as otherwise if you
                // open a new dialog in onClose, it would immeditely be closed again.
                if (onClose) {
                    onClose();
                }
            });

        // class definition
        var Overlay = Class.extend({
            init: function(options) {

                /* Default options */
                var defaultOptions = {
                        width: 420,                 // Width (px) of the overlay dialog
                        content: '',                // HTML / DOM content for the overlay dialog
                        className: '',              // Assign a class to the overlay
                        marginTop: raceBetsJS.application.globals.isB2B ? 0 : 20,  // Margin from the top and bottom
                        closeButton: true,          // Shows a standard close button
                        closeAction: false,         // Default close action
                        backgroundClose: true,      // Close overlay on click on background layer
                        backgroundColor: raceBetsJS.application.assets.settings.getBrandSettings('overlayColor'),    // Opacity of the background layer

                        type: 'default'				// Type of notification
                    },
                    innerDialog;

                // Merge options with defaults
                this.options = $.extend({}, defaultOptions, options || {});

                /* Create necessary DOM elements to show overlay and dialog */
                this.content = $('<div />').attr('class', 'modal-dialog').hide();
                innerDialog = $('<div />').attr('class', 'inner');
                this.overlayElement = $('<div />').attr('class', 'overlay').hide();

                // append modal dialog
                this.overlayElement.append(this.content);

                if (this.options.type) {
                    this.content.addClass(this.options.type);
                }

                if (this.options.closeButton) {
                    this.closeButton = $('<div class="close-button" />');
                    this.content.append(this.closeButton);
                }

                this.content.append(innerDialog);

                // Update content
                innerDialog.replaceWith(this.options.content);

                // Set Z-Index
                this.overlayElement.css({ 'z-index': 10000 });

                // Set width of the modal dialog
                if (typeof this.options.width === 'number') {
                    this.options.width += 2; // 2px left+right border
                    this.content.css({ width: this.options.width });
                }
                else {
                    this.options.width = 0;
                }

                // Add custom class if defined
                if (this.options.className !== '') {
                    this.content.addClass(this.options.className);
                }

                // Add some margin top and bottom
                if (this.options.marginTop) {
                    this.content.css({ marginTop: this.options.marginTop, marginBottom: this.options.marginTop });
                }

                // Inserting the overlay elements and show it
                $(document.body).append(this.overlayElement);
                this.overlayElement.css({ 'display': 'block' });

                // required for some calculations
                this.content.css({
                    top: calculateTopPos(this.content, this.options),
                    marginLeft: (this.options.width || this.content.outerWidth()) / -2
                });

                // Detect resize and move window
                $window.resize($.proxy(function() {
                    this.content.css({
                        top: calculateTopPos(this.content, this.options),
                        marginLeft: (this.options.width || this.content.outerWidth()) / -2
                    });
                }, this));

                this.content.show();
                this.content.find('button').first().focus();

                // @todo: eventually maybe find a nicer, CSS based way to do this?
                // @NOTE: yeah, would be great!
                new raceBetsJS.time.Timeout({
                    callback: $.proxy(function() {
                        this.content.css({
                            top: calculateTopPos(this.content, this.options),
                            marginLeft: (this.options.width || this.content.outerWidth()) / -2
                        });
                    }, this),
                    delay: 20
                });
            },

            /**
            * Default Close Action
            */
            remove: function() {
                this.overlayElement.remove();
            },

            /**
            * Send Dialog to Background
            */
            sendToBack: function() {
                this.content.css({ 'z-index': 9990 });
                this.overlayElement.css({ backgroundColor: 'transparent' });
            },

            /**
            * Bring Dialog to Foreground
            */
            bringToFront: function() {
                this.content.css({ 'z-index': 10010 });
                this.overlayElement.css({ backgroundColor: this.options.backgroundColor });
            },

            /**
            * Return options
            */
            getOptions: function() {
                return this.options;
            }
        });

        var calculateTopPos = function(content, options) {

            function calculateForIframe(content) {
                var scrollPosition = raceBetsJS.application.globals.iframe.scroll,
                top = raceBetsJS.application.globals.iframe.top,
                offset = Math.max(top - scrollPosition, 0),
                contentHeight = content.outerHeight(),
                innerIframeSize =  raceBetsJS.application.globals.iframe.height - offset,
                modalPositioning = (innerIframeSize / 2) - (contentHeight / 2) + scrollPosition - top + offset,
                limit = $('body').height() - contentHeight,
                position;

                if ( scrollPosition < 0 ) {
                    // in case of media dialog where #content is NULL
                    position = 20;
                } else if(modalPositioning > limit) {
                    position = limit;
                } else {
                    position = modalPositioning < 20 ? 20 : modalPositioning;
                }

                return position + 'px';
            }

            function calculateForStandalone(content, options) {
                var topPos = ($window.height() / 2.6) - (content.outerHeight() / 2); // by dividing by > 2, we position it a bit further up

                if (options.marginTop) {
                    topPos -= options.marginTop; // deduct the whole marginTop as it applies to bottom as well
                }

                return (topPos > 0 ? topPos : 0) + 'px';
            }

            return raceBetsJS.application.globals.isB2B ? calculateForIframe(content) : calculateForStandalone(content, options);
        };

        /**
        * Order Dialogs
        */
        var orderDialogs = function() {
            $(stack).each(function(i, overlay) {
                if (i < stack.length-1) {
                    overlay.sendToBack();
                } else {
                    overlay.bringToFront();
                }
            });
        };

        /**
        * Create Overlay
        */
        var create = function(options) {
            options = $(options).extend(options, { closeAction: close });

            // stop body flickering
            if (stack.length === 0) {
                $('body')
                    .css({
                        paddingRight: SCROLLBAR_WIDTH,
                        marginRight: -SCROLLBAR_WIDTH,
                        marginLeft: -SCROLLBAR_WIDTH
                    })
                    .addClass('overlayIsOpen')
                        .find('.overlay')
                        .css({
                            paddingRight: SCROLLBAR_WIDTH
                        })
                    .end()
                        .find('#footer')
                        .css({
                            left: -SCROLLBAR_WIDTH
                        });
            }

            stack.push(new Overlay(options));

            // Order dialogs
            orderDialogs();

            raceBetsJS.browser.crossFrame.send('overlayDisplayed', true);

        };

        /**
        * Close Overlay
        */
        var close = function(dialogId) {
            if (!stack.length) {
                return; // no overlay
            }

            if (dialogId) {
                var overlayToClose = _.find(stack, function(overlay) {
                    return overlay.options.className === dialogId;
                });

                if (overlayToClose) {
                    overlayToClose.remove();

                    stack = stack.filter(function(overlay) {
                        return overlay.options.className !== dialogId;
                    });
                }
            } else {
                // remove current dialog
                stack[stack.length-1].remove();
                stack.pop(stack.length-1);
            }

            // remove anti-flickering styles and overlay
            if (!stack.length) {
                $('body')
                    .css({
                        paddingRight: 0,
                        marginRight: 0,
                        marginLeft: 0
                    })
                    .removeClass('overlayIsOpen')
                        .find('#footer')
                        .css({
                            left: 0
                        })
                    .end()
                        .find('.overlay')
                        .css({
                            paddingRight: SCROLLBAR_WIDTH
                        })
                        .last().remove();

                raceBetsJS.browser.crossFrame.send('overlayDisplayed', false);
            }

            // Order dialogs
            orderDialogs();
        };

        var isOpen = function(dialogId) {
            if (stack.length && dialogId) {
                var overlayToCheck = _.find(stack, function(overlay) {
                    return overlay.options.className === dialogId;
                });

                if (overlayToCheck) {
                    return true;
                }
            }
            return false;
        };

        /**
        * Please Wait Overlay
        */
        var showPleaseWait = function() {
            var content =  '<p>' + raceBetsJS.i18n.data.pleaseWait + '</p>' + raceBetsJS.application.templates.loadingLayer({ loadingStyle: 'dark' });

            hidePleaseWait();
            create({
                content: content,
                className: 'please-wait',
                width: 'auto',
                closeButton: false,
                backgroundClose: false
            });
        };

        var hidePleaseWait = function() {
            close('please-wait');
        };

        function _measureScrollbar() {
            var scrollDiv = document.createElement('div'),
                scrollbarWidth = 0;

            $(scrollDiv).css({
                position: 'absolute',
                top: '-9999px',
                width: '50px',
                height: '50px',
                overflow: 'scroll'
            });

            document.body.appendChild(scrollDiv);
            scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
            document.body.removeChild(scrollDiv);

            return scrollbarWidth;
        }

        return {
            create: create,
            close: close,
            showPleaseWait: showPleaseWait,
            hidePleaseWait: hidePleaseWait,
            isOpen: isOpen,
            calculateTopPos: calculateTopPos
        };
    }();
})(raceBetsJS);
