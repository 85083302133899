/**
* Cashier
*
* @author: Robin Ebers
*/

(function (raceBetsJS) {
    raceBetsJS.application.content.cashier = function () {
        // @private
        var options = false;

        var cashier;
        var sidebar;
        var content;
        var methods;
        var categories;
        var paymentCountry;
        var currentMethod;

        var cashierUrl;

        // anti-money laundering rules for withdrawals
        var amlLimit = 4900,
            amlCurrency = 'USD';

        // ERROR MAPPING
        var errorElements = {
            INVALID_VOUCHER_CODE:           'msgInvalidVoucherCode',
            VOUCHER_ALREADY_REDEEMED:       'msgVoucherAlreadyRedeemed',
            VOUCHER_INVALID:                'msgVoucherInvalid',
            VOUCHER_PACK_USED:              'msgVoucherPackUsed',
            CODE_UNKNOWN:                   'msgBonusCodeUnknown',
            CONTACT_SERVICE:                'errorContactService',
            CODE_USED:                      'errorCodeUsed',
            RECOMMENDED_BY_FRIEND:          'errorCodeUsed',
            AFFILIATE_ASSIGNED:             'errorCodeUsed',
            NO_DEPOSIT:                     'errorNoDepositBonus',
            CODE_EXPIRED:                   'errorCodeExpired',
            EMAIL_NOT_VERIFIED:             'errorEmailNotVerified',
            CURRENCY_NOT_SUPPORTED:         'errorCurrencyNotSupported',
            DEPOSIT_WITH_CONNECTED_BONUS:   'msgTransactionFound',
            WDR_FOUND:                      'msgWithdrawalFound',
            ACCOUNT_BOUND_METHOD:           'msgAccountUsed',
            INVALID_REMOTE_CREDENTIALS:     'msgInvalidRemoteCredentials',
            INSUFFICIENT_REMOTE_FUNDS:      'msgInsufficientRemoteFunds',
            BANK_NOT_SUPPORTED:             'msgBankNotSupported',
            FAILED_ATTEMPTS_LIMIT_REACHED:  'creditcardTemporarilyBlocked',
            INVALID_BANK_DETAILS:           'errorInvalidBankDetails',
            ACCOUNT_NUMBER_INVALID:         'errorInvalidAccountNumber',
            BANK_CODE_INVALID:              'errorInvalidBankCode',
            BANK_TRANSFER_ABORTED:          'msgBankTransferAborted',
            BANK_TRANSFER_REJECTED:         'msgBankTransferRejected',
            GIROPAY_ERROR:                  'msgGiropayError',
            ACCOUNTS_DO_NOT_MATCH:          'errorAccountsNotMatch',
            INVALID_AMOUNT:                 'msgInvalidAmount',
            ACCOUNT_NOT_VERIFIED:           'msgAmountNeedsVerification',
            INVALID_IBAN:                   'errorInvalidIBAN',
            INVALID_BIC:                    'errorInvalidBIC',
            INVALID_IBAN_OR_COUNTRY:        'errorInvalidIBANOrCountry',
            NO_WITHDRAWAL:                  'msg_error_account_no_withdrawal',
            PAYOUT_TOP_PENALTY_AMOUNT:      'msgAmountToHigh',
            INVALID_PASSWORD:               'errorInvalidPassword',
            WITHDRAWALS_DISABLED:           'errorWithdrawalsDisabled',
            OWNER_INVALID:                  'errorAccountOwnerInvalid',
            PAYSAFECARD_ERROR:              'msgPaySafeError',
            PAYSAFECARD_DEBIT_FAILED:       'msgPaySafeFailed',
            NEW_CUSTOMERS_ONLY:             'msgNewUserOnly',
        };

        function onCashierLoaded(data) {
            var category = 'WDR';
            options = $.extend({}, data, { category: category });


            // populate div#content-main
            raceBetsJS.application.globals.contentMain.html(raceBetsJS.application.templates.cashier({ data: options }));

            // cache common elements
            cashier = $('#cashier-options').show();
            content = cashier.find('div.content');
            sidebar = cashier.find('div.sidebar');

            // prevent users that are not logged in to -directly- accessing withdrawal
            if (raceBetsJS.application.user.loggedIn !== true) {
                raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix);
                return;
            }

            // set category and to associated tasks
            setCashierCategory(options.category);

            // set default option in sidebar
            var method = raceBetsJS.browser.params.get('method');

            if (raceBetsJS.application.user.loggedIn !== true || (options.category == 'WDR' && method === false) ||
                    options.emailVerified === false || options.openWithdrawals === true) {
                // show overview
                showOverview();
            } else if (method !== false) {
                setPaymentMethod(method, raceBetsJS.browser.params.get('idPayment'));
            } else if (options.showOverview === true || raceBetsJS.browser.params.get('deposit') === 'overview') {
                showOverview();
            } else {
                // set default method
                setPaymentMethod(options.methods[0].method);
            }

            // logged in catch for links that go to a method or withdrawal
            cashier.on('click', 'a[href*="/cashier/withdrawal"]', checkLoginStatus);

            // show cashier
            cashier.show();

            Tipped.create(sidebar.find('[data-tipped]'), {
                hook: 'bottommiddle',
                skin: 'racebets',
                maxWidth: 200,
                offset: { x: 3 }
            });

        }

        function showOverview() {
            // remove active payment method
            methods.find('li.method.active').removeClass('active');

            // update content page
            content.html(
                raceBetsJS.application.templates
                    .cashier['withdrawal'].overview({ data: options })
            );

            // display withdrawal limit for german temp accounts
            if (raceBetsJS.application.user.country === 'DE' && raceBetsJS.application.user.accountLimitedAccess !== 'inactive') {
                raceBetsJS.application.assets.messageBox.show(
                    {
                        closeButton: false,
                        fadeOut: false,
                        before: content.find('div.withdrawal'),
                        content: raceBetsJS.i18n.data.msgWithdrawalNeedsVerification
                    }
                );
            }

            // withdrawal logic
            // cancellation successful
            if (raceBetsJS.browser.params.get('cancellation') !== false) {
                raceBetsJS.application.assets.messageBox.show({
                    type: 'success',
                    closeButton: true,
                    prepend: content,
                    content: raceBetsJS.i18n.data.withdrawalCancellationSuccessful
                });
            }

            // withdrawal request successfull
            if (raceBetsJS.browser.params.get('requested') !== false) {
                raceBetsJS.application.assets.messageBox.show({
                    type: 'success',
                    closeButton: true,
                    prepend: content,
                    content: raceBetsJS.i18n.data.withdrawalSuccessful
                });
            }

            content.find('ul.open-withdrawals li a.delete').click(function () {
                raceBetsJS.application.assets.modalDialog.show({
                    type:'attention',
                    content: raceBetsJS.i18n.data.confirmWithdrawalCancellation,
                    buttons: [
                        {
                            label: raceBetsJS.i18n.data.buttonNo,
                            action: function () {
                                // modal dialog
                                raceBetsJS.application.assets.overlay.close();
                            }
                    },
                        {
                            label: raceBetsJS.i18n.data.buttonYes,
                            action: $.proxy(function () {
                                // hide modal dialog
                                raceBetsJS.application.assets.overlay.close();

                                // cache public id
                                var publicId = $(this).data('public-id');

                                $.ajax({
                                    type: 'post',
                                    url: '/ajax/withdrawal/cancel/id/' + publicId,
                                    beforeSend: raceBetsJS.application.assets.overlay.showPleaseWait(),
                                    success: function (data) {
                                        if (data.success === true) {
                                            // update login info
                                            // raceBetsJS.application.header.login.updateLoginInfo();

                                            // refresh overview
                                            options = false;
                                            raceBetsJS.browser.history.navigateTo(
                                                raceBetsJS.application.globals.urlPrefix +
                                                '/cashier/withdrawal/overview/cancellation/' + publicId
                                            );
                                        } else {
                                            // failed
                                            raceBetsJS.application.assets.messageBox.show({
                                                type: 'error',
                                                closeButton: true,
                                                prepend: content,
                                                content: raceBetsJS.i18n.data.errorWithdrawalCancellation
                                            });
                                        }
                                    },
                                    complete: function () {
                                        // close please wait
                                        raceBetsJS.application.assets.overlay.close();
                                    }
                                });
                            }, this),
                        active: true
                    }
                    ]
                });
            });

            Tipped.create(content.find('[data-tipped]'), {
                hook: 'rightmiddle',
                skin: 'racebets',
                maxWidth: 200,
                offset: { x: 3 }
            });

            Tipped.create(
                '.balance tr.kyc a',
                function (elem) {
                    return raceBetsJS.i18n.data.infoMoneyLaundering;
                },
                {
                    hook: 'rightmiddle',
                    skin: 'racebets',
                    maxWidth: 200,
                    offset: { x: 3 },
                    hideOn: [
                        { element: 'self', event: 'mouseleave' },
                        { element: 'tooltip', event: 'mouseleave' },
                        { element: 'tooltip', event: 'click' }
                    ]
                }
            );

            resizeCashier();
        }

        function checkLoginStatus(e) {
            if (raceBetsJS.application.user.loggedIn !== true) {
                e.preventDefault();
                raceBetsJS.application.assets.dialogs.loginDialog.show();
                return false;
            }
        }

        function showWithdrawalContent(method, idPayment) {
            // show content of payment method
            var method = getMethod(method, idPayment);
            method.brandName = raceBetsJS.application.globals.brandName;
            if (method.brandName === 'suaposta' && options.brazilBanks) {
                method.accountType = ['C', 'P'];
                method.accountHolderPositions = [1, 2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
                method.brazilBanks = options.brazilBanks;
            }

            content.html(raceBetsJS.application.templates.cashier.withdrawal.content({ data: method }));

            // cache dom elements
            var details = content.find('div.payment-method');
            var limits = details.find('ul li.limits');
            var form = content.find('#cashier-form');
            var totalAmount = form.find('div.total-amount');
            var amount = $('#form-element-amount');

            // set max amount
            amount.attr('max', ((method.maxWithdrawal !== undefined && method.maxWithdrawal > 0) ?
                ((method.maxWithdrawal > options.balance.maxWithdrawal) ? options.balance.maxWithdrawal : method.maxWithdrawal)
                    : options.balance.maxWithdrawal));

            /**
            * Additional Bank Transfer Logic
            */
            if (method.methodCode == 'BTF') {
                // bank account select
                var bankAccount = new raceBetsJS.application.assets.Dropdown($('#form-element-bank-transfer-select'), {
                    width: 260,
                    onChange: function (select) {
                        var idBankAccount = select.val();

                        $("#form-element-amount").prop("disabled",false).removeClass("disabled");
                        $("#form-element-password").prop("disabled",false).removeClass("disabled");
                        raceBetsJS.application.assets.messageBox.hide();

                        if (idBankAccount === '') {
                            toggleBankTransferForms(country.val());
                        } else {
                            if (raceBetsJS.application.globals.brandName === 'suaposta') {
                                $('#bank-transfer-form').hide().find('input').removeClass('required');
                                $('#bank-transfer-form').hide().find('select').removeClass('required');
                            } else {
                                $('#bank-transfer-form').hide().find('input').removeClass();
                            }
                        }

                        // trigger recalculation of fee
                        form.find('#form-element-amount').trigger('change');
                    }
                });

                if (raceBetsJS.application.globals.brandName === 'suaposta') {
                    var country = $('#form-element-bank-transfer-account-country');

                    var acconuntTypeDropdown = new raceBetsJS.application.assets.Dropdown($('#form-element-account-type-select'), {width: 160});
                    var acconuntHolderPositionDropdown = new raceBetsJS.application.assets.Dropdown($('#form-element-account-holder-position-select'), {width: 160});
                    var bankCodeDropdown = new raceBetsJS.application.assets.Dropdown($('#form-element-bank-transfer-bank-code'), {width: 260});

                    $('#cashier-form input').click(function(){
                        $(this).removeClass('error');
                    });

                 } else {
                    // bank country
                    var userCountry = raceBetsJS.application.user.country;
                    var country = new raceBetsJS.application.assets.Dropdown($('#form-element-bank-transfer-account-country'), {
                        width: 260,
                        selected: userCountry,
                        sortOptions: true,
                        onChange: function (select) {
                            toggleBankTransferForms(select.val());

                            // trigger recalculation of fee
                            form.find('#form-element-amount').trigger('change');
                        }
                    });

                }
                // toggle form init
                toggleBankTransferForms(country.val());

                Tipped.create(
                    '#bank-transfer-international span.sepa-info-icon',
                    raceBetsJS.i18n.data.sepa_tooltip_info,
                    {
                        hook: 'righttop',
                        skin: 'racebets',
                        maxWidth: 220,
                        offset: {  y : -7, x: -4 },
                        radius: 3
                    }
                );

            }
            /* -- END OF BTF LOGIC */

            // Fee calculations
            form.find('#form-element-amount').change(function () {
                var fee = 0;
                amount = $(this).val().replace(',', '.');

                // if zero or NaN, hide fee info
                if ((parseFloat(amount) < 0.01) || isNaN(parseFloat(amount))) {
                    totalAmount.hide();
                    return;
                }

                if (method.methodCode == 'BTF') {
                    var countryCode;

                    if (bankAccount.val() > 0) {
                        $.each(method.details.accounts, function () {
                            if (parseInt(this.idBankAccount) == parseInt(bankAccount.val())) {
                                countryCode = this.bankCountry;
                            }
                        });
                    } else {
                        if (raceBetsJS.application.globals.brandName === 'suaposta') {
                            countryCode = 'BR';
                        } else {
                            countryCode = country.val();
                        }
                    }
                }

                if (method.methodCode == 'BTF' && raceBetsJS.application.user.currency == 'NOK') {
                    fee = raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].feeWithdrawalNonSEPA;
                    var elem = raceBetsJS.i18n.print('msgWithdrawalFeeGbpBtf', {
                        fee: '<span class="noBr">' + raceBetsJS.format.money(fee, 2, raceBetsJS.application.user.currency, true) + '</span>'
                    });
                } else if (method.methodCode == 'BTF' && $.inArray(countryCode, getCountriesWithoutFee()) == -1) {
                    fee = raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].feeWithdrawalNonSEPA;
                    var elem = raceBetsJS.i18n.print('msgWithdrawalFeeInternational', {
                        fee: '<span class="noBr">' + raceBetsJS.format.money(fee, 2, raceBetsJS.application.user.currency, true) + '</span>'
                    });
                } else if (amount < raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].minFeelessWithdrawal) {
                    fee = raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].feeWithdrawalBelowMinimum;
                    var elem = raceBetsJS.i18n.print('msgWithdrawalFeeLowAmount', {
                        fee: '<span class="noBr">' + raceBetsJS.format.money(fee, 2, raceBetsJS.application.user.currency, true) + '</span>',
                        minAmount: '<span class="noBr">' + raceBetsJS.format.money(raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].minFeelessWithdrawal, 2, raceBetsJS.application.user.currency, true) + '</span>'
                    });
                } else if (options.feeWithdrawal) {
                    fee = raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].feeBulkWithdrawals;
                    var elem = raceBetsJS.i18n.print('msgWithdrawalFee', {
                        fee: '<span class="noBr">' + raceBetsJS.format.money(fee, 2, raceBetsJS.application.user.currency, true) + '</span>'
                    });
                }

                // if max amount is available, adjust it to fee
                if ($(this).attr('max') !== undefined) {
                    if ($(this).data('max-base') === undefined) {
                        $(this).data('max-base', $(this).attr('max'));
                    }

                    amount = parseFloat(amount);
                    fee = parseFloat(fee);
                    maxBase = parseFloat($(this).data('max-base'));
                    options.balance.maxWithdrawal = parseFloat(options.balance.maxWithdrawal);

                    var amountAdjusted = maxBase;
                    if ((maxBase+fee) >= options.balance.maxWithdrawal) {
                        amountAdjusted = (amountAdjusted-fee).toFixed(2);
                    }

                    $(this).attr('max', (amountAdjusted > 0) ? amountAdjusted : 0);
                }

                // update amount + fee
                $('#form-element-total-amount').val(amount);
                $('#form-element-total-fee').val(fee);
                totalAmount.find('label.amount').html(raceBetsJS.i18n.print('msgTotalAmountX', {
                    amount: raceBetsJS.format.money((parseFloat(amount) + fee), 2, raceBetsJS.application.user.currency, true)
                }));
                totalAmount.find('label.details').html(elem);
                totalAmount.toggle((fee > 0));

                resizeCashier();
            });

            /**
            * Tipped's
            */
            form.on('focus', 'input[type="text"], input[type="password"]', function () {
                if ($(this).hasClass('disabled')) {
                    return;
                }

                var targetElem = $(this);
                createFieldHint(targetElem);
            });

            form.on('blur', 'input[type="text"], input[type="password"]', function () {
                if ($(this).hasClass('disabled')) {
                    return;
                }

                Tipped.get(this).remove();
            });

            Tipped.create(content.find('[data-tipped]'), {
                hook: 'rightmiddle',
                skin: 'racebets',
                maxWidth: 200,
                offset: { x: 3 }
            });

            form.validate({
                ignoreTitle: true,
                showErrors: function (errorMap, errorList) {
                    // add validation-error data tag to all elements
                    $.each(errorList, function () {
                        $(this.element).data('validation-error', this.message);
                    });

                    this.defaultShowErrors();
                },
                highlight: function (elem, errorClass) {
                    elem = $(elem);

                    // add error class
                    elem.addClass(errorClass);

                    // add if focuses, update field hint
                    if (elem.is(':focus') && (elem.data('form-hint') || elem.data('validation-error'))) {
                        createFieldHint(elem);
                    }
                },
                unhighlight: function (elem, errorClass) {
                    elem = $(elem);

                    elem.removeClass(errorClass).removeData('validation-error');

                    // add if focused, update field hint
                    if (elem.is(':focus') && (elem.data('form-hint') || elem.data('validation-error'))) {
                        createFieldHint(elem);
                    } else {
                        if (!elem.hasClass('disabled')) {
                            Tipped.get(elem).remove();
                        }
                    }
                },
                errorPlacement: function (error, elem) {
                    // when using the showError method, no error classes will be set to the inputs as we're overwriting
                    // the default behavior with this callback. we can return this to behavior with defaultShowError(),
                    // this however would add labels to the form that we don't want. Therefore the "return false;" here.
                    return false;
                },
                submitHandler: function (form) {
                    // form = $(form);
                    var formData = $(form).serialize();

                    // BONUS TO BE CANCELLED?
                    var amountToCancel = (options.balance.amount - options.balance.maxWithdrawal) + options.balance.openBetslipsBonusBalance;
                    if (amountToCancel > 0 && options.cancelBonus !== true) {
                        raceBetsJS.application.assets.overlay.close();
                        raceBetsJS.application.assets.modalDialog.show({
                            type:'attention',
                            content: raceBetsJS.i18n.print('msgBonusCancellation', {
                                amount: raceBetsJS.format.money(amountToCancel, 2, raceBetsJS.application.user.currency)
                            }),
                            buttons: [
                                {
                                    label: raceBetsJS.i18n.data.buttonYes,
                                    action: $.proxy(function () {
                                        raceBetsJS.application.assets.overlay.close();
                                        options.cancelBonus = true;
                                        $(form).submit();
                                    }, this)
                                },
                                {
                                    label: raceBetsJS.i18n.data.buttonNo,
                                    action: function () {
                                        raceBetsJS.application.assets.overlay.close();
                                    },
                                    active: true
                                }
                            ]
                        });
                        return false;
                    }

                    // Check amount against EU anti-money laundering transfer limit
                    if (method.methodCode === 'CCD' && (!options.amlAccepted || options.amlAccepted === false)) {
                        var AMLNotification = (raceBetsJS.application.user.currency !== amlCurrency) ?
                                        ((amount * raceBetsJS.application.globals.exchangeRates[raceBetsJS.application.user.currency].USD > amlLimit) ? true : false) :
                                        ((amount > amlLimit) ? true : false);

                        if (AMLNotification) {
                            raceBetsJS.application.assets.overlay.close();
                            raceBetsJS.application.assets.modalDialog.show({
                                type: 'attention',
                                content: raceBetsJS.i18n.print('msgAmlNotification', {limit: amlLimit, currency: amlCurrency}),
                                closeButton: false,
                                buttons: [
                                    {
                                        label: raceBetsJS.i18n.data.buttonOK,
                                        active: true,
                                        action: $.proxy(function () {
                                            raceBetsJS.application.assets.overlay.close();
                                            $.extend(options, {amlAccepted: true});
                                            $(form).submit();
                                        }, this)
                                },
                                    {
                                        label: raceBetsJS.i18n.data.buttonCancel,
                                        action: function () {
                                            raceBetsJS.application.assets.overlay.close();
                                            return false;
                                        }
                                }
                                ]
                            });
                            return false;
                        }
                    }

                    $.ajax({
                        type: 'post',
                        url: $(form).attr('action'),
                        data: formData,
                        beforeSend: function () {
                            raceBetsJS.application.assets.overlay.showPleaseWait();
                        },
                        success: function (data) {
                            if (data.type == 'success') {
                                // track event
                                dataLayer.push({
                                    'Status': 'Successful',
                                    'event': 'Withdrawal'
                                });
                                withdrawalSuccessful();
                                raceBetsJS.application.assets.overlay.close();
                                return false;
                            } else {
                                raceBetsJS.application.assets.overlay.close();
                                // track event
                                dataLayer.push({
                                    'Status': 'Failed',
                                    'event': 'Withdrawal'
                                });
                                // define error relations
                                if (errorElements[data.errorMsg] !== undefined) {
                                    if (data.errorMsg === 'INVALID_BIC' && data.hasOwnProperty("additionalInfo") ) {
                                        raceBetsJS.application.assets.messageBox.show({
                                            type: 'error',
                                            closeButton: true,
                                            fadeOut: false,
                                            after: content.find('div.payment-method'),
                                            content: raceBetsJS.i18n.print('sepa_suggestion', { 'bicCode' : data.additionalInfo })
                                        });
                                    } else {
                                        raceBetsJS.application.assets.messageBox.show({
                                            type: 'error',
                                            closeButton: true,
                                            after: content.find('div.payment-method'),
                                            content: raceBetsJS.i18n.data[errorElements[data.errorMsg]]
                                        });
                                    }
                                } else if (data.errorMsg === 'ACCOUNT_NOT_VERIFIED') {
                                    var dialogContent;
                                    if (raceBetsJS.application.user.country === 'DE' && raceBetsJS.application.user.accountLimitedAccess !== 'inactive') {
                                        dialogContent = raceBetsJS.i18n.data.msgWithdrawalNeedsVerification;
                                    } else {
                                        dialogContent = raceBetsJS.i18n.print('msgAmountNeedsVerification', {
                                            amount: raceBetsJS.format.money(raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].thresholdKyc, 0, raceBetsJS.application.user.currency)
                                        });
                                    }
                                    raceBetsJS.application.assets.messageBox.show({
                                        type: 'error',
                                        closeButton: true,
                                        after: content.find('div.payment-method'),
                                        content: dialogContent
                                    });
                                } else {
                                    raceBetsJS.application.assets.modalDialog.generic(data.errorMsg);
                                }
                            }
                        },
                        complete: function () {
                            options.cancelBonus = true;
                        }
                    });

                    return false;
                }
            });

            resizeCashier();
        }

        function setCashierCategory(category) {
            // load sidebar
            sidebar.html(raceBetsJS.application.templates.cashier.sidebar({ data: options }));

            // cache elements
            methods = cashier.find('ul.payment-methods');
            categories = cashier.find('ul.cashier-category');

            // country select
            new raceBetsJS.application.assets.Dropdown(sidebar.find('#cashier-country-select'), {
                width: 226,
                selected: options.country,
                sortOptions: true,
                onChange: function (select) {
                    raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix +
                        '/cashier/deposit/overview/country/' + select.val());
                }
            });

            // set category
            categories.find('li.category.active').removeClass('active');
            categories.find('li.category[data-cashier-category="' + category + '"]').addClass('active');

            // create payment methods
            $.each(options.methods, function () {
                if (options.category == 'WDR' && (parseFloat(this.maxWithdrawal) == 0 || (parseFloat(options.kycLimit) == 0) && !options.isVerified)) {
                    return;
                }

                methods.append(
                    raceBetsJS.application.templates.cashier["button" + options.category]({
                        data: this, category: options.category
                    })
                );
            });
        }

        function setPaymentMethod(method, idPayment) {
            currentMethod = method;

            methods.find('li.method.active').removeClass('active');
            content.find('div.payment-method').removeClass(getPaymentMethod());

            if (idPayment === undefined || idPayment === false) {
                methods.find('li.method[data-payment-method="' + method + '"]').addClass('active');
                content.find('div.payment-method').addClass(method);
            } else {
                methods.find('li.method[data-id-payment="' + idPayment + '"]').addClass('active');
                content.find('div.payment-method').addClass(method);
            }

                showWithdrawalContent(method, idPayment);

        }

        function getPaymentMethod() {
            return methods.find('li.method.active').data('payment-method');
        }

        function getMethod(method, idPayment) {
            var paymentMethod = false;
            $.each(options.methods, function () {
                if (this.method == method && (idPayment === undefined || this.idPayment == idPayment)) {
                    paymentMethod = this;
                }
            });

            return paymentMethod;
        }

        function getCountriesWithoutFee() {
            if (raceBetsJS.application.globals.brandName === 'suaposta') {
                return ['BR'];
            }
            return getSepaBankCountries();
        }

        function getSepaBankCountries() {

            // UPDATED 2016-09-19
            return ['AT', 'BE', 'BG', 'BL', 'CH', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GB', 'GF', 'GI',
                'GP', 'GR', 'HU', 'HR', 'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MC', 'MF', 'MQ', 'MT', 'NL', 'NO', 'PL',
                'PM', 'PT', 'RE', 'RO', 'SE', 'SI', 'SK', 'YT', 'SM'];
        }

        function getBanksSweden() {
            return [
                'Avanza Bank',
                'Citibank',
                'Danske Bank',
                'DNB Bank ASA, filial Sverige',
                'Erik Penser Bankaktiebolag',
                'Forex Bank',
                'GE Money Bank',
                'ICA Banken',
                'IKANO Banken',
                'Landshypotek',
                'Länsförsäkringar Bank',
                'Marginalen Bank',
                'Nordea Bank',
                'Nordnet',
                'Parex Bank',
                'PlusGirot Bank',
                'Resurs bank',
                'Riksgälden',
                'Royal Bank of Scotland',
                'SBAB Bank AB',
                'SEB',
                'SkandiaBanken',
                'Sparbanken Syd',
                'Sparbanken Öresund',
                'Svenska Handelsbanken',
                'Swedbank',
                'Ålandsbanken Abp, filial Sveriga'
            ];
        }

        function createFieldHint(elem) {
            var elem = $(elem);
            if ((!elem.data('form-hint') && !elem.data('validation-error'))) {
                return;
            }

            Tipped.create(
                elem,
                function () {
                    var tipContent = $('<div class="field-hint">');

                    if (elem.data('validation-error')) {
                        tipContent.append($('<p class="error">').text(elem.data('validation-error')));
                    }

                    if (elem.data('form-hint')) {
                        var formHint = $('<p class="hint">').text(elem.data('form-hint'));
                        if (elem.data('validation-error')) {
                            tipContent.addClass('has-error');
                        }
                        tipContent.append(formHint);
                    }

                    return tipContent;

                },
                {
                    hook: 'righttop',
                    hideOthers: true,
                    offset: { x: 2, y: -4 },
                    stem: { offset: { x: 0, y: 6 } },
                    skin: 'dark form-field-hint',
                    padding: 0,
                    fadeIn: false,
                    showOn: false,
                    hideOn: 'click-outside',
                    maxWidth: 200
                }
            ).show();
        }

        function withdrawalSuccessful() {
            // raceBetsJS.application.header.login.updateLoginInfo();

            options = false; // forces json update
            raceBetsJS.browser.history.navigateTo(
                raceBetsJS.application.globals.urlPrefix  + '/cashier/withdrawal/requested/true/'
            );

            resetCashierForm();
            resizeCashier();
        }

        function toggleBankTransferForms(country) {
            $('#bank-transfer-form').show();

            var labelIban = $('#bank-transfer-international label[for="form-element-bank-transfer-account-iban"]').removeClass("iconned");
            var labelBic = $('#bank-transfer-international label[for="form-element-bank-transfer-account-bic"]').removeClass("iconned");
            var sepaInfoIcons = $('span.sepa-info-icon').hide();
            $("#form-element-bank-transfer-account-holder").prop("disabled",true).addClass("disabled");

            if(raceBetsJS.application.globals.brandName === 'suaposta') {
                $("#form-element-bank-transfer-cpf").prop("disabled",true).addClass("disabled");
                $('#bank-transfer-form input').addClass('required');
                return
            } else if (country == 'SE' && raceBetsJS.application.user.currency == 'SEK' ) {
                addSwedishBanks();

                $('#bank-transfer-germany').hide().find('input').removeClass();
                $('#bank-transfer-international').show().find('input[name="iban"]').addClass('required');

                // update labels for sweden
                labelIban.html(raceBetsJS.i18n.data.accountNumber);
                labelBic.html(raceBetsJS.i18n.data.accountClearingNo);
            } else if (country == 'NO') {
                hideBankSelector();

                // set requirements
                $('#bank-transfer-germany').hide().find('input').removeClass();
                $('#bank-transfer-international').show().find('input').removeClass();
                $('#form-element-bank-transfer-account-iban').removeClass('required');

                // update labels for norway
                labelIban.html(raceBetsJS.i18n.data.accountNumber);
                labelBic.html(raceBetsJS.i18n.data.accountBIC);
            } else {
                hideBankSelector();

                $('#bank-transfer-germany').hide().find('input').removeClass();
                $('#bank-transfer-international').show().find('input').addClass('required');

                if ($.inArray(country, options.ibanCountries) == -1) {
                    labelIban.html(raceBetsJS.i18n.data.labelAccountNumber);
                    labelBic.html('SWIFT');
                } else {
                    labelIban.html(raceBetsJS.i18n.data.labelIban).addClass("iconned");
                    labelBic.html(raceBetsJS.i18n.data.labelBic).addClass("iconned");
                    sepaInfoIcons.show();
                }
            }

            // bank name always required
            $('#form-element-bank-transfer-bank-name').addClass('required');

            resizeCashier();
        }

        function addSwedishBanks() {
            var banks = $('<select id="form-element-bank-transfer-bank-name" name="bankName" />');

            $.each(getBanksSweden(), function () {
                //banks.append(new Option(this, this));
                banks.append($('<option>').attr('value', this.replace(' ', '_')).text(this)); // required for IE8?!
            });

            $('#form-element-bank-transfer-bank-container').html('').append(banks);
            new raceBetsJS.application.assets.Dropdown($('select#form-element-bank-transfer-bank-name'), {
                width: 220
            });
        }

        function hideBankSelector() {
            if ($('#form-element-bank-transfer-bank-container div.rb-dropdown').size() > 0) {
                $('#form-element-bank-transfer-bank-container').html(
                    '<input type="text" id="#form-element-bank-transfer-bank-name" name="bankName" maxlength="32" class="required" /><br />'
                );

                $('label[for="form-element-bank-transfer-account-iban"]').html(raceBetsJS.i18n.data.accountIBAN);
                $('label[for="form-element-bank-transfer-account-bic"]').html(raceBetsJS.i18n.data.accountBIC);
            }
        }

        /**
        * resetCashierForm
        * Resets the cashier (deposit / withdrawal) form
        */
        function resetCashierForm() {
            // if form is visible, reset
            var form = $('#cashier-form');
            if (form.size() > 0) {
                //form.reset(); // doesn't work?!
                document.getElementById('cashier-form').reset();
                form.find('div.total-amount').hide();
            }

            // sepa temporal notification
            $("#sepa-notification-container").hide();
        }

        /**
        * resizeCashier()
        * Resized the cashier depending on how much space it needs
        */
        function resizeCashier() {
            // set height
            if (options.category == 'DEP' && (content.outerHeight() > sidebar.outerHeight()) ) {
                sidebar.css({ height: content.outerHeight() });
            } else if (options !== false) {
                var optionsHeight = (180 + (options.methods.length * 75));
                sidebar.css({ height: (content.outerHeight() > optionsHeight) ? content.outerHeight() : optionsHeight });
            }
        }

        // @public
        return {
            init: function () {
                // Setup route controller
                raceBetsJS.application.contentController.addDynamicPage({
                    urlPattern: /\/cashier\/(withdrawal)\/?(method|\/overview)?\/?([\w\-]+)?(\/country\/(\w+))?\/?/,
                    dataSourceURLBuilder: function (category, ignoreMe, option, ignoreMe2, country) {

                        // withdrawal page and not logged in?
                        if (raceBetsJS.application.user.loggedIn !== true && category == 'withdrawal') {
                            raceBetsJS.browser.history.navigateTo(
                                raceBetsJS.application.globals.urlPrefix + '/static/loginrequired/'
                            );
                            return false;
                        }

                        // get payment country
                        paymentCountry = raceBetsJS.browser.params.get('country');

                        if (raceBetsJS.application.comesFromAngular) {
                            raceBetsJS.application.comesFromAngular = false;
                            options = false;
                        }

                        raceBetsJS.application.header.navigation.activateItemByKey('account withdrawal');
                        cashierUrl = '/ajax/cashier/withdrawal/';
                        return cashierUrl;
                    },
                    onDataReceived: onCashierLoaded,
                    onUnload: function () {
                        options = false;
                    }
                });
            },
            getErrorMap: function () {
                return errorElements;
            }
        };
    }();
})(raceBetsJS);
