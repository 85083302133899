/**
* Guidelines
* @author Robin Ebers
*/
(function(raceBetsJS) {
    raceBetsJS.application.assets.guidelines = function() {
        // @private

        /**
        * init()
        * Initialise guideline controls
        */
        function init(options) {
            toggleControl();
        }

        function toggleControl() {
            raceBetsJS.application.globals.contentContainer.on('click', 'div.toggle label:not(.disabled)', function() {
                var _radio = $('#' + $(this).attr('for')), // radio button
                    _label = $('[for="' + $(this).attr('for') + '"]'), // label
                    _parent = $(this).parent(); // parent (div container)

                _parent.find('label').removeClass('checked');
                _parent.find(':radio').attr('checked', false);

                _radio.attr('checked', true);
                _label.addClass('checked');
            })
        }

        function selectBoxControl(elem, options) {
            var selectBox = new raceBetsJS.application.assets.SelectBox(elem, options);

            return selectBox;
        }

        // @public
        return {
            init: init,
            selectBoxControl: selectBoxControl
        };
    }();
})(raceBetsJS);
