/**
* Runner
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
    raceBetsJS.application.content.race.Runner = Class.extend({
        /**
        * init
        *
        * Constructor of the Runner class
        */
        init: function(idRunner, elem, data) {
            this.idRunner = idRunner;
            this.data = data;
            this.runner = this.data.runners.data[this.idRunner]; // shortcut

            this.cache = {
                detailsRow: {}
            };

            var OddsButton = raceBetsJS.application.content.race.OddsButton;

            // cache dom references
            this.dom = { row: elem };
            this.oddsButtons = {
                price: new raceBetsJS.application.content.race.OddsButton(elem.find('td.odds.price .odds-button'), 'PRC'),
                fixedOddsWin: new raceBetsJS.application.content.race.OddsButton(elem.find('td.odds.fixed.win .odds-button'), 'FXW'),
                fixedOddsPlace: new raceBetsJS.application.content.race.OddsButton(elem.find('td.odds.fixed.place .odds-button'), 'FXP')
            }

            this.updateOddsButtons();
        },

        /**
        * Return details for a runner to show in a tooltip
        */
        getDetails: function(includeName) {
            // see if we card.cached details for this runner yet
            if (this.cache.details) {
                return this.cache.details;
            }

            var content = $(raceBetsJS.application.templates.race.runner.details($.extend(true, {}, this.runner, { raceType: this.data.race.raceType, includeName: includeName, eventCountry: this.data.event.country })));
            var ret = false;

            if ($.trim(content.find('table').text())) {
                ret = content[0];
            } else if ($.trim(content.text())) {
                // remove margin-top of first paragraph
                content.find('p:first').addClass('no-margin-top');
                ret = content[0];
            }

            this.cache.details = ret;
            return ret;
        },

        /**
         * Return jockey stats for a runner to show in a tooltip
         */
        getJockeyStats: function() {
            // see if we card.cached details for this runner yet
            if (this.cache.jockeyStats) {
                return this.cache.jockeyStats;
            }

            var content;

            if (this.runner.jockey.stats || this.runner.jockey.photo) {
                content = raceBetsJS.application.templates.race.runner.personStats({ person: this.runner.jockey, eventCountry: this.data.event.country });
                this.cache.jockeyStats = content;
                return content;
            }

            return false;
        },

        /**
         * Return trainer stats for a runner to show in a tooltip
         */
        getTrainerStats: function() {
            // see if we card.cached details for this runner yet
            if (this.cache.trainerStats) {
                return this.cache.trainerStats;
            }

            var content;

            if (this.runner.trainer.stats || this.runner.trainer.photo) {
                content = raceBetsJS.application.templates.race.runner.personStats({ person: this.runner.trainer, eventCountry: this.data.event.country });
                this.cache.trainerStats = content;
                return content;
            }

            return false;
        },

        /**
        * Get the price history for a runner to show it in a tooltip
        */
        getPriceHistory: function() {
            if (this.runner.scratched || !this.runner.priceHistory[1]) {
                return false;
            }

            var tipContent = $('<div></div>');
            var oddsTrends = $('<ul></ul>');

            $.each(this.runner.priceHistory, function(key, price) {
                if (price == 0) {
                    return;
                }

                oddsTrends.prepend($('<li></li>').html(raceBetsJS.format.odds(price, undefined, true)));

            });
            oddsTrends.find('li:last').addClass('last');

            return tipContent.append($('<span></span>').html(raceBetsJS.i18n.data.labelOddsTrend), oddsTrends);
        },

        /**
        * Get the fixed odds history for a runner to show it in a tooltip
        */
        getFixedOddsHistory: function() {
            if (this.runner.scratched || Object.keys(this.runner.fixedOddsHistory).length < 2) {
                return false;
            }

            var tipContent = $('<div></div>');
            var oddsTrends = $('<ul></ul>');

            $.each(this.runner.fixedOddsHistory, function(key, odds) {
                oddsTrends.prepend($('<li></li>').html(raceBetsJS.format.odds(odds, undefined, true)));

            });
            oddsTrends.find('li:last').addClass('last');

            return tipContent.append($('<span></span>').html(raceBetsJS.i18n.data.labelFixedOddsHistory), oddsTrends);
        },

        /**
        * positionDetailsRow
        *
        * Re-Position the details row below the runner
        */
        positionDetailsRow: function() {
            if (!this.detailsRow) {
                return;
            }

            this.dom.row.after(this.detailsRow);
        },

        /**
        * setPriceTrend
        *
        * Set the price trend of the runner
        */
        setPriceTrend: function() {
            // finde price trend element
            if (!this.dom.priceTrend) {
                this.dom.priceTrend = this.dom.row.find('td.odds.price div.trend');
            }

            // remove trend if nothing to display
            if (this.runner.scratched || this.runner.priceHistory[0] == 0 || this.runner.priceHistory[1] == 0) {
                this.dom.priceTrend.removeClass('up down even');
                return false;
            }

            // find trend
            var trend;
            if (this.runner.priceHistory[0] > this.runner.priceHistory[1]) {
                trend = 'up';
            } else if (this.runner.priceHistory[0] < this.runner.priceHistory[1]) {
                trend = 'down';
            } else {
                trend = 'even';
            }

            // set trend
            this.dom.priceTrend
                .removeClass('up down even')
                .addClass(trend);

            return true;
        },

        /**
        * toggleDetailsRow
        *
        * Hide/Show Runners Details
        */
        toggleDetailsRow: function(details) {
            var prevDetails, detailsRow;

            if (!this.dom.pn) {
                this.dom.pn = this.dom.row.children('td.number');
            }

            // check if nothing needs to be done
            if (this.detailsRow) {
                prevDetails = this.detailsRow.data('details');
                this.detailsRow.remove();
                this.dom.pn.attr('rowspan', 1); // removeAttr looks bad in IE7
                this.detailsRow = null;

                if (details == prevDetails) {
                    return;
                }
            }

            // show details
            detailsRow = $('<tr></tr>')
                .attr('id', 'runner-details-' + this.idRunner)
                .addClass('runner-details')
                .data('details', details)
                .append($('<td></td>')
                    .attr('colspan', 7)
                    .html('<img src="/images/global/loading-indicator.gif" width="16" height="16" />'
                )
            );

            this.dom.row.after(detailsRow);
            this.dom.pn.attr('rowspan', 2);

            this.detailsRow = $('#runner-details-' + this.idRunner);

            var url;
            if (details == 'ppDetailed') {
                url = raceBetsJS.application.content.getAjaxCacheUrl('/ajax/runners/pastperformance/');
            } else if (details == 'antePost') {
                url = '/ajax/runners/antepost/';
            } else {
                return false;
            }

            var tdElem = this.detailsRow.find('td:first');

            // see if we card.cached details for this runner yet
            if (this.cache.detailsRow[details]) {
                tdElem.html(this.cache.detailsRow[details]);
                return;
            }

            $.ajax({
                url: url,
                data: {
                    type: 'runner',
                    id: this.idRunner
                },
                context: this,
                success: function(data) {
                    $.each(data, $.proxy(function(idRunner, runnerData) {
                        if(!parseInt(idRunner, 10)) {
                            return 
                        }

                        this.cache.detailsRow[details] = raceBetsJS.application.templates.race.runner[details]({
                            raceType: this.data.race.raceType,
                            data: runnerData
                        });

                        if (tdElem) { // row might have been closed/removed again meanwhile
                            tdElem.html(this.cache.detailsRow[details]);
                        }
                    }, this));
                }
            });
        },

        /**
        * updateOddsButtons
        *
        * Update one or all oddsButtons depending on the current data
        */
        updateOddsButtons: function(categories) {
            var button, odds,
                raceEnabled = (this.data.race.raceStatus == 'OPN');

            var doUpdate = $.proxy(function(button, odds, enabled) {
                if (this.runner.scratched) {
                    button.scratch();
                    return;
                }

                button.update(odds, enabled);
            }, this);

            if (!categories || $.inArray('PRC', categories) > -1) {
                button = this.oddsButtons.price;
                odds = this.runner.odds['PRC'];
                buttonEnabled = (raceEnabled && (this.data.betTypes.normal.BOK || this.data.betTypes.normal.TOT)) ? true : false;

                doUpdate(button, odds, buttonEnabled);
            }

            if (!categories || $.inArray('FXW', categories) > -1) {
                button = this.oddsButtons.fixedOddsWin;
                odds = this.runner.odds['FXW'];
                buttonEnabled = (raceEnabled && this.data.race.fixedOddsStatus == 'ON' && this.data.betTypes.normal.FXD && (this.data.betTypes.normal.FXD.WIN || this.data.betTypes.normal.FXD.WP)) ? true : false;

                doUpdate(button, odds, buttonEnabled);
            }

            if (!categories || $.inArray('FXW', categories) > -1) {
                button = this.oddsButtons.fixedOddsPlace;
                odds = this.runner.odds['FXP'];
                buttonEnabled = (raceEnabled && this.data.betTypes.normal.FXD && this.data.betTypes.normal.FXD.PLC) ? true : false;

                doUpdate(button, odds, buttonEnabled);
            }
        }
    });
})(raceBetsJS);
