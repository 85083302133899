/**
* betting
*/

(function(raceBetsJS) {
	raceBetsJS.betting = {
        calculateTax: function(totalStake, taxFeeRate, betCategory, noFee) {
            var res = {
                taxDue: 0, // tax amount which is due to the authorities (right now just 5% German tax is considered)
                taxFeeRate: 0, // tax fee set for this race/bet category
                taxFee: 0, // fee that we charge the customer for the tax
                taxSubvention: 0, // subvention of RaceBets for the tax amount
                taxSubventionRate: 0, // subvention rate
                totalCost: totalStake // total cost of the betslip
            };

            if (noFee !== undefined && noFee === true) {
                return res;
            }

            if (raceBetsJS.application.user.taxFees.taxable) {
                res.taxDue = Math.floor(totalStake * 5) / 100;

                res.taxFeeRate = (taxFeeRate === undefined) ? 0 : taxFeeRate;

                // special tax rate for this customer for BOK/FXD bets?
                if (raceBetsJS.application.user.taxFees.rate !== undefined && (betCategory == 'BOK' || betCategory == 'FXD')) {
                    res.taxFeeRate = raceBetsJS.application.user.taxFees.rate;
                }

                if (raceBetsJS.application.user.taxFees.deductions[betCategory]) {
                    res.taxFeeRate *= (100 - raceBetsJS.application.user.taxFees.deductions[betCategory]) / 100;
                }

                res.taxFee = Math.floor(totalStake * res.taxFeeRate) / 100;
                res.taxSubvention = Math.round((res.taxDue - res.taxFee) * 100) / 100;
                res.taxSubventionRate = 5 - res.taxFeeRate;
                res.totalCost = Math.round((totalStake + res.taxFee) * 100) / 100;
            }

            return res;
        },

        getToteStepSize: function(country) {
            var toteStepSizes = { 'DE': 0.50, 'MT': 1.00 };
            return (toteStepSizes[country] !== undefined) ? toteStepSizes[country] : 0.01;

        }
	};
})(raceBetsJS);