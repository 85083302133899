/**
* Homepage "Ante-Post Runners" module
*
* @author David Calleja
*/
(function(raceBetsJS) {
	raceBetsJS.application.content.home.antePostRunners = function() {
		// @private
		var panel_template = raceBetsJS.application.templates.panel,
			content_template = raceBetsJS.application.templates.home.antePostRunners,
			panel_data = {
				'title':'',
				'cssClass':'c-widget--antepostRunners light with-rows',
				'id':'antepost-runners'
			},
			content_area_selector = '.content_container',
			el = null,
			data = {};

		var render = function(area){
			var content = $('<ul>');

			$.each(
				data,
				function(i){
					var li = $('<li>');
					if((i+1)%2 == 0){
						li.addClass('even');
					}
					content.append(li.append(content_template(this)));
				}
			);
			if(el == null){
				area.append(panel_template($.extend(panel_data, {'content': $('<div>').append( content.eq(0).clone() ).html() })));
				el = area.find('#' + panel_data.id).find(content_area_selector);
				eventsInit();
			} else {
				el.empty();
				el.append(content);
			}
			createOddsButtons();
		};

		var eventsInit = function(){
			el.on('mouseup', '.odds-button.fixed.enabled', function() {
				raceBetsJS.application.assets.bettingDialog.show(this);
			});
			el.on('mouseup', '.odds-button.price.enabled', function() {
				raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + $(this).attr("data-id-race"));
			});
		};

		var createOddsButtons = function(){
			$.each(
				el.find('ul').find('.odds-button'),
				function(){
					var fixedOdds = $(this).hasClass('fixed'),
						button = null;

					if(fixedOdds){
						button = new raceBetsJS.application.content.race.OddsButton($(this), 'FXW');
					} else {
						button = new raceBetsJS.application.content.race.OddsButton($(this), 'PRC');
					}
					button.setOdds($(this).children('span').data('odds'), $(this).hasClass('enabled'));
				}
			);
		};

		var setData = function(json){
			data = json;
		};


		var remove = function(){
			el = null;
		};

		// @public
		return {
			init: function(json) {
				if(json != null && !_.isEmpty(json)){
					setData(json);

                    //set translations
                    panel_data.title = raceBetsJS.i18n.data.headAntePostRunners;

					//Initialize 'more' link
					panel_data.more = {
                        text: raceBetsJS.i18n.data.linkMore,
                        link: raceBetsJS.application.globals.urlPrefix + "/content/show/module/featuredhorses/cat/antepost"
                    };

					return this;
				} else if(el != null){
					return this;
				}
				return false;
			},
			render: render,
			remove: remove
		};
	}();
})(raceBetsJS);
