/**
* OddsButton
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
    raceBetsJS.application.content.race.Runners = Class.extend({
        /**
        * init
        *
        * Constructor of the Runners class
        */
        init: function(elem, data) {
            this.data = data;

            // restrict SP for GB/IE
            if (this.data.race.restrictSP === 1 && $.inArray(raceBetsJS.application.user.ipCountry, ['GB', 'IE']) > -1) {
                delete this.data.betTypes.normal['BOK'];
                delete this.data.betTypes.normal['TOT'];
            }

            this.dom = {
                table: elem,
                tableWrapper: elem.parent(),
                col: {},
                thFixedOdds: elem.children('thead').find('tr.top th.odds.fixed')
            };

            // hide post-positions if not available
            if (!this.dom.table.find('tr.runner td.number span.pp').length) {
                this.dom.table.addClass('no-pp');
            }

            // cache references to col tags
            this.dom.table.children('colgroup').children('col').each($.proxy(function(key, value) {
                var col = $(value);
                var name = col.data('name');

                this.dom.col[name ? name : $.camelize(col.attr('class'))] = col;
            }, this));

            // create runner instances
            this.runners = {};
            this.numNotScratched = 0;
            this.dom.table.children('tbody').children('tr.runner').each($.proxy(function(key, value) {
                var tr = $(value);
                var idRunner = tr.data('id-runner');
                this.runners[idRunner] = new raceBetsJS.application.content.race.Runner(idRunner, tr, data);

                if (!data.runners.data[idRunner].scratched) {
                    this.numNotScratched++;
                }
            }, this));

            this.setColVisibility();
            this.setLastRow();
            this.updatePriceHistory();
            this.updateFixedOddsHistory();

            if (this.data.race.raceStatus == 'OPN') {
                this.setFavourites();
            } else {
                this.setWinners();
            }
        },

        /**
        * runnersDo
        *
        * Execute a method of every Runner instance
        */
        runnersDo: function(func, params) {
            var returns = [];

            if (params == undefined) {
                params = [];
            }

            $.each(this.runners, function(key, runner) {
                returns.push(runner[func].apply(this, params));
            });

            return returns;
        },

        /**
        * setColVisibility
        *
        * Set the column visbility basing on available betting types
        */
        setColVisibility: function(hideFXP) {
            // price
            if (this.data.betTypes.normal.TOT !== undefined || this.data.betTypes.normal.BOK !== undefined) {
                this.dom.table.addClass('col-price');
            } else {
                this.dom.table.removeClass('col-price');
            }

            // fixed odds
            var colspan = 0;
            if (this.data.race.fixedOddsStatus != 'NAV' && this.data.betTypes.normal.FXD !== undefined) {
                // win
                if (this.data.betTypes.normal.FXD.WIN !== undefined || this.data.betTypes.normal.FXD.WP !== undefined) {
                    this.dom.table.addClass('col-fow');
                    colspan++;
                } else {
                    this.dom.table.removeClass('col-fow');
                }

                // place
                if ((this.data.betTypes.normal.FXD.PLC !== undefined || this.data.betTypes.normal.FXD.WP !== undefined) && !hideFXP) {
                    // don't enabled fixed place odds for fractional customers!
                    if (raceBetsJS.application.assets.settings.get().dialog.general.oddsFormat == 'fractional' && this.data.betTypes.normal.FXD.PLC === undefined) {
                        // todo: too braindead to find right opposite condition before UK "relaunch", therefore the else condition here - rob
                    } else {
                        this.dom.table.addClass('col-fop');
                        colspan++;
                    }
                } else {
                    this.dom.table.removeClass('col-fop');
                }
            } else {
                this.dom.table.removeClass('col-fow col-fop');
            }

            // colspan if both fixed odds are shown
            if (colspan == 2) {
                this.dom.thFixedOdds.attr('colspan', 2);
            } else {
               	if ($.browser.msie && parseInt($.browser.version, 10) == 7) {
					//Do Nothing
                } else {
                	this.dom.thFixedOdds[0].colSpan = 1; // jquery leads to problems in IE7 here
                }
            }

            /*
            if ($.browser.msie && $.browser.version.charAt(0) == 7) {
                this.dom.table.addClass('col-all');
                window.setTimeout($.proxy(function() {
                    this.dom.table.removeClass('col-all');
                }, this), 1);
            }*/

            this.setHorseColWidth();
        },

        /**
        * setHorseColWidth
        *
        * Sets CSS classes and changes the width of the horse name column
        */
        setHorseColWidth: function() {
            var newLastCol = 'jockey';
            var tmpWidth = 150; // jockey (always visible column)

            if (!this.dom.table.hasClass('ante-post')) {
                tmpWidth += this.dom.col.number.outerWidth() + this.dom.col.horseAge.outerWidth();
            }

            if (this.dom.table.hasClass('col-price')) {
                newLastCol = 'price';
                tmpWidth += this.dom.col.price.outerWidth();
            }

            if (this.dom.table.hasClass('col-fow')) {
                newLastCol = 'fow';
                tmpWidth += this.dom.col.fixedOddsWin.outerWidth();
            }

            if (this.dom.table.hasClass('col-fop')) {
                newLastCol = 'fop';
                tmpWidth += this.dom.col.fixedOddsPlace.outerWidth();
            }

            if (this.dom.table.hasClass('col-checkboxes')) {
                newLastCol = null;
                tmpWidth += this.dom.col.checkboxes.outerWidth();
            }

            if (this.lastCol != newLastCol) {
                if (this.lastCol) {
                    this.dom.table.removeClass('last-' + this.lastCol);
                }

                if (newLastCol) {
                    this.dom.table.addClass('last-' + newLastCol);
                }

                this.lastCol = newLastCol;
            }

            this.dom.col.horse.width(731 - tmpWidth);

            raceBetsJS.application.content.race.adjustWidth();
        },

        /**
        * setFavourites
        *
        * Find the favourite(s) and highlight them
        */
        setFavourites: function() {
            var minPrice = 99.8;
            var newFavourites = [];

            // find current favourites
            $.each(this.data.runners.data, function() {
                if (this.odds.PRC > 0 && !this.scratched) {
                    if (this.odds.PRC < minPrice) {
                        minPrice = this.odds.PRC;
                        newFavourites = [this.idRunner];
                    } else if (this.odds.PRC == minPrice) {
                        newFavourites.push(this.idRunner);
                    }
                }
            });

            if (!raceBetsJS.object.equals(this.favourites, newFavourites)) {
                // remove old favourites
                this.favourites && $.each(this.favourites, $.proxy(function(key, idRunner) {
                    this.runners[idRunner].oddsButtons.price.unsetFavourite();
                }, this));

                // display new favourites
                $.each(newFavourites, $.proxy(function(key, idRunner) {
                    this.runners[idRunner].oddsButtons.price.setFavourite();
                }, this));


                this.favourites = newFavourites;
            }
        },

        /**
        * setLastRow
        *
        * Set "last" css class for last runner tr
        */
        setLastRow: function() {
            this.dom.table.find('tbody > tr.runner.last').removeClass('last');
            this.dom.table.find('tbody > tr.runner:last-child').addClass('last');
        },

        /**
        * setWinners
        *
        * Set the winner css class for all winning runners
        */
        setWinners: function() {
            var oldWinners = this.dom.table.find('tr.winner');

            this.data.result.positions && $.each(this.data.result.positions, $.proxy(function(key, pos) {
                if (pos.position == 1) {
                    this.runners[pos.idRunner].dom.row.addClass('winner'); // usually we should have a method Runner::setWinner(true)
                    oldWinners = oldWinners.not(this.runners[pos.idRunner].dom.row[0])
                } else {
                    return false;
                }
            }, this));

            oldWinners.removeClass('winner'); // usually we should have a method Runner::setWinner(false)
        },

        /**
        * sort
        *
        * Sort the runners
        */
        sort: function(colHead, initial) {
            // ascending or descending
            var direction = colHead.hasClass('ascending') ? 'descending' : 'ascending';

            // find column to be sorted after
            var column = colHead.data('sort-column');

            // Build array of (data, tableRowElement) pairs.
            var dataElementPairs = [];

            // regex to find runners which need to be shown at the end
            var lastRunners = /(^| )anderer($| )|nachgenannt/i;

            $.each(this.runners, $.proxy(function(idRunner, runner) {
                var val;
                var runnerData = runner.runner;

                // workaround for non-runners
                if ((runnerData.scratched && (column == 'price' || column == 'fixedOddsWin')) || (initial && lastRunners.test(runnerData.name))) {
                    val = (direction == 'ascending') ? 1000 : 0;
                } else {
                    switch(column) {
                        case 'age':
                            val = runnerData.age;
                            break;

                        case 'distance':
                            val = runnerData.distance;
                            break;

                        case 'fixedOddsWin':
                            val = runnerData.odds.FXW;
                            break;

                        case 'jockey':
                            val = runnerData.jockey.lastName;
                            break;

                        case 'minTime':
                            val = runnerData.pp.minTime;
                            break;

                        case 'name':
                            val = runnerData.name;
                            break;

                        case 'pn':
                            val = parseInt(runnerData.programNumber);
                            // work around for stable horses
                            if (typeof runnerData.programNumber != 'number') {
                                val += parseFloat('0.' + runnerData.programNumber);
                            }
                            break;

                        case 'pp':
                            val = runnerData.postPosition;
                            break;

                        case 'price':
                            val = runnerData.odds.PRC;
                            break;

                        case 'trainer':
                            val = runnerData.trainer.lastName;
                            break;

                        case 'weight':
                            val = runnerData.jockey.weight.weight;
                            break;

                        default:
                            return false;
                    }
                }

                dataElementPairs.push({
                    value: val,
                    trElem: runner.dom.row
                });
            }, this));

            if (!dataElementPairs.length) {
                return false;
            }

            // Sort the array
            var sortCallback;
            if (typeof dataElementPairs[0].value == 'number') {
                sortCallback = function(a, b) {
                    return b > a;
                }
            } else {
                sortCallback = function(a, b) {
                    return raceBetsJS.string.strnatcmp(b, a) == 1;
                }
            }
            dataElementPairs = $.mergeSort(dataElementPairs, sortCallback);

            if(direction == 'descending') {
                dataElementPairs.reverse();
            }

            // Insert elements in sorted order back into the table body
            var tbody = $(this.dom.table.children('tbody')[0]);
            $.each(dataElementPairs, function() {
                tbody.append(this.trElem);
            });

            // re-position details rows, if they are open
            this.runnersDo('positionDetailsRow');
            this.setLastRow();

            // css classes
            this.dom.table.children('thead').find('th.sortable.ascending, th.sortable.descending').removeClass('ascending descending');
            colHead.addClass(direction);
        },

        /**
        * updatePriceHistory
        *
        * Updates the price history of all runners
        */
        updatePriceHistory: function() {
            $.each(this.data.runners.data, function() {
                // add new odds at the beginning
                this.priceHistory.unshift(this.odds.PRC);

                if (this.priceHistory.length > 4) {
                    // remove last element
                    this.priceHistory.pop();
                }
            });

            var returns = this.runnersDo('setPriceTrend');

            if ($.inArray(true, returns) != -1) {
                this.dom.table.addClass('has-price-trend');
            }
        },

        /**
         * updateFixedOddsHistory
         *
         * Updates the fixed odds history of all runners
         */
        updateFixedOddsHistory: function() {
            $.each(this.data.runners.data, function() {
                if (!this.fixedOddsHistory || !this.odds.FXW) {
                    return;
                }

                // add new odds at the beginning
                this.fixedOddsHistory.unshift(this.odds.FXW);

                if (this.fixedOddsHistory.length > 4) {
                    // remove last element
                    this.fixedOddsHistory.pop();
                }
            });
        }
    });
})(raceBetsJS);
