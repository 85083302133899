/**
* History
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
    raceBetsJS.browser.history = function() {
        // Redirect if initial URL contains #
//        if(window.location.href.indexOf('#') != -1) {
//            window.location.href = window.location.href.substring(window.location.href.indexOf('#') + 1);
//            return;
//        }
//
//        // @private
//        var currentURL;
//        var navigateTo;
//
//        var browseEvent = new raceBetsJS.events.Event();
//
//        // first click done flag
//
//
//        if(window.history && history.pushState) {   // HTML5 History API
//            // var first = true;
//
//            // Navigation
//            navigateTo = function(href, replace) {
//                currentURL = href;
//                if (replace) {
//                    window.history.replaceState({}, '', href);
//                } else {
//                    window.history.pushState({}, '', href);
//                }
//                browseEvent.fire({
//                    href: href
//                });
//            };
//
//            // Back/Forward
//            window.onpopstate = function(event) {
//                /**
//                * This Chrome bug is fixed 16.01.2014 https://code.google.com/p/chromium/issues/detail?id=63040
//                * According to browser stats we can remove it. Uncomment if we need it again, but then we also need another hack.
//                **/
//                // Chrome fires event on first page load, this behaviour is not replicated in other browsers.
//                // if(first && navigator.userAgent.indexOf('Chrome') != -1) {
//                //  first = false;
//                //  return;
//                // }
//
//                currentURL = getCurrentPageURL();
//
//                browseEvent.fire({
//                    href: currentURL
//                });
//            };
//
//            // Get URL
//            var getCurrentPageURL = function() {
//                return window.location.pathname;
//            };
//
//        } else { // IE8 (native), IE6+ emulated onhashchange
//            /**
//            * I struggeled with this one for a while, but then I found the hashchange plugin from jquery, that simulates
//            * the onHashChange event in IE6/7 and every other browser that supports iFrames but not onHashChange.
//            */
//
//            // Navigation
//            navigateTo = function(href) {
//                    currentURL = href;
//                    window.location.hash = href;
//            };
//
//            // Back/Forward
//            $(window).hashchange(function() {
//                var url = getCurrentPageURL();
//                currentURL = url;
//                browseEvent.fire({
//                    href: url
//                });
//            });
//
//            // Get URL
//            var getCurrentPageURL = function() {
//                return window.location.hash.substr(1) || window.location.pathname;
//            };
//        }
//
//        // Set Current URL
//        currentURL = getCurrentPageURL();
//
//        var navigateToWrapper = function(href, replace) {
//
//            if (raceBetsJS.application.globals.reloadPage) {
//                window.location = href;
//                return;
//            }
//
//            if(!raceBetsJS.application.globals.firstClickDone){
//                document.title = raceBetsJS.i18n.data.seoHomeTitle || document.title;
//                raceBetsJS.application.globals.firstClickDone = true;
//            }
//
//            navigateTo(href, replace);
//        };

        // @public
        return {
//            handleAnchorClick: function(e) {
//                // don't use ajaxify if shift or control is pressed
//                if (e.shiftKey || e.ctrlKey) {
//                    return;
//                }
//
//                var anchorElem = $(this);
//                var href = anchorElem.attr('href');
//
//                e.preventDefault();
//                navigateToWrapper(href);
//            },

            navigateTo: function(href, replace) {
                raceBetsJS.application.angularApp.commonCtrl().navigateTo(href);
//                if (replace === undefined) {
//                    replace = false;
//                }
//                navigateToWrapper(href.replace(window.location.protocol + '//' + window.location.hostname, ''), replace);
            }

//            browseEvent: browseEvent,
//            getCurrentPageURL: getCurrentPageURL
        };
    }();
})(raceBetsJS);
