/**
* Get country order
*/
(function(raceBetsJS) {
    raceBetsJS.application.assets.countryOrder = function() {

        // @private
        var getOrder = function() {
            var langOrder;
            // the following order is also in mobile/public/scripts/assets/settings.js!
            var defaultCountryOrder = ['AE', 'FR', 'GB', 'IE', 'ZA', 'HK', 'DE', 'ES', 'IT', 'SE', 'NO', 'DK', 'FI', 'MT',
                'BR', 'AU', 'NZ', 'CZ', 'HU', 'SG', 'MO', 'MU', 'AT', 'CH', 'CL', 'ZW', 'TR', 'AR', 'JP', 'BE', 'SK', 'NL',
                'PR', 'IN', 'US', 'CA'];

            // change order depending on language
            switch (raceBetsJS.application.user.lang) {
                case 'de':
                    langOrder = ['DE'];
                    break;

                case 'en':
                    langOrder = ['GB', 'IE', 'ZA'];
                    break;

                case 'fr':
                    langOrder = ['FR'];
                    break;

                case 'es':
                    langOrder = ['ES', 'AR', 'CL', 'MX', 'CO', 'PE', 'PR', 'GB', 'IE', 'ZA'];
                    break;

                case 'ag':
                case 'cl':
                case 'co':
                case 'mx':
                case 'pe':
                    langOrder = ['AR', 'CL', 'MX', 'CO', 'PE', 'US', 'PR', 'CA', 'GB'];
                    break;

                case 'it':
                    langOrder = ['IT'];
                    break;

                case 'sv':
                    langOrder = ['SE', 'NO', 'FI'];
                    break;

                case 'fi':
                    langOrder = ['FI', 'SE', 'NO'];
                    break;

                case 'no':
                case 'eu':
                    langOrder = ['NO', 'EU', 'SE', 'FI'];
                    break;

                case 'pl':
                case 'cs':
                case 'hu':
                case 'ru':
                    langOrder = ['CZ', 'HU', 'SK'];
                    break;

                case 'br':
                case 'pt':
                    langOrder = ['US', 'BR', 'PE', 'CL', 'AR', 'UR', 'CO'];
                    break;

                case 'tr':
                    langOrder = ['TR'];
                    break;

                default:
                    langOrder = [];
            }

            var userOrder = raceBetsJS.application.assets.settings.get().dialog.countries.order;
            return _.union(userOrder, langOrder, defaultCountryOrder);
        }

        return {
            getOrder: getOrder
        };

    }();
})(raceBetsJS);
