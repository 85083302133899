/**
* Popup
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
	raceBetsJS.browser.popup = function() {
		// @private

        var defaults = {
            width: 800,
            height: 600,
            scroll: 'yes'
        }

        var handleAnchorClick = function(e) {
            var anchorElem = $(this);
            var options = {
                url: anchorElem.attr('data-url') || anchorElem.attr('href'),
                width: anchorElem.data('popup-width'),
                height: anchorElem.data('popup-height'),
                scroll: (anchorElem.data('popup-scroll') !== undefined) ? anchorElem.data('popup-scroll') : 'no'
            }

            if(anchorElem.data('popup-name')) {
                options.name = anchorElem.data('popup-name');
            }

            e.preventDefault();

            if (raceBetsJS.application.globals.isB2B) {
                options.url = options.url.replace(raceBetsJS.application.globals.urlPrefix, '');

                raceBetsJS.browser.crossFrame.send('popup', options);
            } else {
                open(options);
                anchorElem.blur();
            }
        }

        // Delegate all link clicks
        $('html').on('click', 'a.popup, button.popup', handleAnchorClick);

        var open = function(options) {
            defaults.name = 'popup' + $.now();
            options = $.extend({}, defaults, options);

            if (options.hasOwnProperty('href')){
                options.url = options.href;
                delete options.href;
            }

            if (raceBetsJS.application.globals.isB2B) {
                options.url = options.url.replace(raceBetsJS.application.globals.urlPrefix, '');
                raceBetsJS.browser.crossFrame.send('popup',options);

            } else {
                var top  = Math.ceil(window.screen.height - options.height) / 2;
                var left = Math.ceil(window.screen.width - options.width) / 2;

                var popup = window.open(options.url, options.name, 'width=' + options.width + ',height=' + options.height + ',top=' + top + ',left=' + left + ',resizable=' + options.resizable + ',scrollbars=' + options.scroll);

                return popup;
            }
        }

		// @public
		return {
            open: open
		};
	}();
})(raceBetsJS);
