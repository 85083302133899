/**
* Audio race preview player
*
* @author Przemek Marciniak
*/

(function(raceBetsJS) {
    raceBetsJS.application.assets.audioPreview = function() {

        var audioPlayer = null;

        /**
         * Display audio player for mp3 audio preview
         */
        function show(container, idRace) {
            var loggedIn = raceBetsJS.application.user.loggedIn;

            // if needed add dynamically orginal videojs styles
            if (!document.getElementById('video-js-css')) {
                $('head').append('<link id="video-js-css" rel="stylesheet" href="/scripts/lib/video-js/video-js-7.css" type="text/css" />');
            }

            var rootElement = $(document.createElement('audio'))
                .attr({ id: 'audiojs', class : 'video-js', autoplay: false, height: 30, controls: true });

            var sourceElement = $(document.createElement('source'))
                .attr({ type : 'audio/mpeg', src: '/rest/v2/audiopreview/' + idRace + '/' + raceBetsJS.application.user.lang });

            rootElement.append(sourceElement);
            container.append(rootElement);
            container.addClass(loggedIn ? 'isLoggedIn' : '');

            audioPlayer = videojs('audiojs', {
                controlBar: {
                  fullscreenToggle: false
                },
                preload: loggedIn ? 'auto' : 'none',
                plugins: { eventTracking: true }
            }, function() {
                audioPlayer.bigPlayButton.off('click');
                audioPlayer.bigPlayButton.on('click', function(event){
                    raceBetsJS.application.assets.dialogs.loginDialog.show();
                });
            });

            // Listen to player events
            audioPlayer.on('play', function() {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Play'
                });
            });

            audioPlayer.on('pause', function() {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Pause'
                });
            });

            audioPlayer.on('ended', function() {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Ended'
                });
            });

            // User rewinds the audio track
            audioPlayer.on('tracking:seek', function(e, data) {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Seek'
                });
            })

            audioPlayer.on('tracking:first-quarter', function(e, data) {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Progress 25%'
                });
            })

            audioPlayer.on('tracking:second-quarter', function(e, data) {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Progress 50%'
                });
            })

            audioPlayer.on('tracking:third-quarter', function(e, data) {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Progress 75%'
                });
            })

            audioPlayer.on('tracking:fourth-quarter', function(e, data) {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Progress 100%'
                });
            })
        }

        /**
         * Destroy audio player
         */
        function destroy() {
            try {
                if (audioPlayer && audioPlayer.dispose) {
                    audioPlayer.dispose();
                    audioPlayer = null;
                }
            } catch (err) {
                throw ('Remove Player (audio preview) failed', err);
            }
        }

        // @public
        return {
            show: show,
            destroy: destroy
        };
    }();
})(raceBetsJS);
