/**
* Timezone
*
* @author Robin Ebers
*/

(function(raceBetsJS) {
    raceBetsJS.application.assets.timezone = function() {

        /**
        * date
        * Wrapper of raceBetsJS.format.date() on application level with added timezone support
        *
        * @param format
        * @param timestamp
        * @param timezone
        */
        var date = function(format, timestamp, timezone) {
            if (timezone === undefined) {
                timezone = raceBetsJS.application.assets.settings.get().dialog.general.timezone;
            }

            // time difference
            timestampTimezone = raceBetsJS.time.timezone.convert(Math.round(timestamp), timezone);

            return raceBetsJS.format.date(format, timestampTimezone);
        }

        /**
        * checkOffsetDifference
        * Checks if the current local computer offset is the same, as the selected one.
        * If not, it shows a dialog (max. every two weeks) that asks if the customers wants to adjust that.
        */
        var checkOffsetDifference = function() {
            var lastCheck = raceBetsJS.application.assets.settings.status('other', 'lastTimezoneCheck');
            if (lastCheck !== false && lastCheck > ($.now() / 1000 - 1209600)) { // 2 weeks
                return;
            }

            var timezoneLocal = raceBetsJS.time.timezone.detect();
            var timezoneActive = raceBetsJS.application.assets.settings.get().dialog.general.timezone;

            var now = $.now();
            var offsetLocal = new timezoneJS.Date(now, timezoneLocal).getTimezoneOffset();
            var offsetActive = new timezoneJS.Date(now, timezoneActive).getTimezoneOffset();

            // if they are the same, all good!
            if (offsetLocal == offsetActive) {
                return;
            }

            if (raceBetsJS.application.globals.isB2B) {
                var settings = raceBetsJS.application.assets.settings.get(true);

                settings.dialog.general.timezone = timezoneLocal;
                raceBetsJS.application.assets.settings.set(settings);
                raceBetsJS.application.assets.settings.save($.stringify(settings));
            } else {
                // if not, show a dialog
                raceBetsJS.application.assets.modalDialog.show({
                    type: 'attention',
                    title: raceBetsJS.i18n.data.titleTimezoneOffset,
                    content: raceBetsJS.i18n.data.msgTimezoneOffset,
                    closeButton: false,
                    buttons: [
                        {
                            label: raceBetsJS.i18n.data.buttonYes,
                            active: true,
                            action: function() {
                                // close overlay
                                raceBetsJS.application.assets.overlay.close();

                                // show settings dialog
                                raceBetsJS.application.assets.settings.show({ activeTab: 1 });
                            }
                        },
                        {
                            label: raceBetsJS.i18n.data.buttonNo,
                            action: function() {
                                // close overlay
                                raceBetsJS.application.assets.overlay.close();

                                // set last update
                                raceBetsJS.application.assets.settings.status('other', 'lastTimezoneCheck', Math.round($.now() / 1000));
                                raceBetsJS.application.assets.settings.save(); // update settings
                            }
                        }
                    ]
                });
            }
        }

        return {
            date: date,
            checkOffsetDifference: checkOffsetDifference
        };
    }();
})(raceBetsJS);
