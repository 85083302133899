/**
* Homepage banner module
*
* @author David Calleja
* @author Robin Ebers <robin.ebers@gmail.com>
*/

(function(raceBetsJS) {
    raceBetsJS.application.content.home.banner = (function() {
        // @private
        var el = false,
            first = true,
            data = {},
            currentIndex,
            refreshRate = 6,
            timer,
            isAnimated = false,
            subscription,
            bannerData;


        var init = function(banner) {
            bannerData = banner;
            return this;
        };

        var render = function() {

            el = $('#mainBanner');

            setData(bannerData);

            if (_.isEmpty(data) || isAnimated) {
                return;
            }

            el.html(raceBetsJS.application.templates.home.banners({ banners: data }));

            if (first) {
                el.on('click', '.buttons li:not(.active)', function() {
                        showBanner($(this).data('banner'));
                    })
                    .on('mouseover', stopTimer)
                    .on('mouseout', startTimer)
                    .on('click', 'ul.banners a', function(event){
                        var href = $(this).attr('href');

                        if(this.target === '_blank') {
                            event.preventDefault();
                            raceBetsJS.browser.crossFrame.send('open', href);
                        }
                    });
                first = false;
            }

            nextBanner();
        };

        var remove = function(){
            stopTimer();
            if (el) {
                el.off('click mouseover mouseout');
                el = null;
            }
            first = true;
        };

        var setData = function(json){
            stopTimer();

            data = json;
        };

        var startTimer = function() {
            if (timer === undefined) {
                timer = new raceBetsJS.time.Interval({
                    interval: refreshRate * 1000,
                    tick: function() {
                        nextBanner();
                    }
                });
            }

            if (data.length > 1) {
                timer.start();
            }
        };

        var stopTimer = function() {
            if (timer === undefined) {
                return;
            }

            timer.stop();
        };

        var nextBanner = function() {
            if (!data.length) {
                return;
            }

            if(data.length < 2 ){
                currentIndex = undefined;
            }

            if (currentIndex === undefined) {
                // randomly select first banner
                showBanner(0);
            } else {
                // next
                showBanner((currentIndex >= (data.length -1 )) ? 0 : currentIndex + 1);
            }
        };

        var showBanner = function(index) {
            if (isAnimated) {
                return false;
            }

            isAnimated = true;

            if (!el) {
                setTimeout(function() {
                    raceBetsJS.application.content.home.banner.render();
                }, 500);
                return;
            }

            el.find('ul.buttons li').removeClass('active');
            el.find('ul.buttons li:eq(' + index + ')').addClass('active');

            el.find('ul.banners li:eq(' + currentIndex + ')').css('z-index', '9');

            el.find('ul.banners li:eq(' + index + ')')
                .css({ 'margin-left': '-370px', 'display': 'block', 'z-index': '10' })
                .stop()
                .animate({'margin-left': '0'}, 300, function() {
                    if (currentIndex !== undefined) {
                        $(el).find('ul.banners li:eq(' + currentIndex + ')').css('z-index', '0').hide();
                    }

                    isAnimated = false;

                    currentIndex = index;
                });
        };

        // @public
        return {
            init: init,
            render: render,
            remove: remove
        };
    })();
})(raceBetsJS);
