/**
* Bet
*
* @author Moritz Honig
*/
(function (raceBetsJS) {
    raceBetsJS.Bet = Class.extend({
        /**
        * init
        *
        * Constructor of the Bet class
        * Example: bet = {
        *     idRace: 1234,
        *     betType: 'WIN',
        *     betslipType: 'STD',
        *     betCategory: 'BOK',
        *     unitStake: 1.50,
        *     currency: 'EUR',
        *     numBets: 2,
        *     fixedOddsWin: undefined,
        *     fixedOddsPlace: undefined,
        *     marks[0]: [{programNumber: ..., name: ...}, ...],
        *     marks[1]: ...,
        *     ...
        * };
        */
        init: function (bet, extras) {
            this.bet = bet;
            this.extras = extras || {};
            this.idBetslip = null;
        },

        /**
        * submit
        *
        * Submits the betslip and returns a Deferred
        */
        submit: function () {
            var df = $.Deferred();

            if (this.idBetslip) {
                df.reject();
            } else {
                var confirmation = !raceBetsJS.application.assets.settings.get().dialog.betslip.confirmation || this.showConfirmationDialog();
                var betData = $.extend({}, this.bet, { marks: this.getMarksString() });
                delete betData.marksAcc;

                $.when(confirmation)
                    .always(function (overlay) {
                        if (overlay) {
                            raceBetsJS.application.assets.overlay.close();
                        }
                    })
                    .fail(function () {
                        df.reject();
                    })
                    .done($.proxy(function () {
                        var now = new Date();
                        $.ajax({
                            type: 'post',
                            url: '/ajax/betslip/send/',
                            data: betData,
                            context: this,
                            timeout: 90000,
                            beforeSend: function (jqXHR) {
                                var lastBetCall = raceBetsJS.application.assets.session.get('lastBetCall');
                                var lastBet = raceBetsJS.application.assets.session.get('lastBet');
                                if (lastBetCall) {
                                    var difference = (now.getTime() - lastBetCall.getTime()) / 1000;

                                    if (difference <= 3 && _.isEqual(betData, lastBet)) {
                                        jqXHR.abort();
                                        df.reject();
                                        return false;
                                    }
                                }
                                raceBetsJS.application.assets.session.set('lastBetCall', now);
                                raceBetsJS.application.assets.session.set('lastBet', betData);
                                raceBetsJS.application.assets.overlay.showPleaseWait();
                            },
                            error: function () {
                                // neccessary as "complete" is triggered *after* success, which can call other modal windows.
                                raceBetsJS.application.assets.overlay.close();
                            },
                            success: function (data) {
                                raceBetsJS.application.assets.overlay.close();

                                if (data.type == 'success') {
                                    this.idBetslip = data.publicId;

                                    // send event to angular
                                    if (raceBetsJS.betSuccess) {
                                        raceBetsJS.betSuccess(this.bet);
                                    }

                                    // did bet activate a bonus?
                                    if (data.extraStatusCode == 1) {
                                        raceBetsJS.application.assets.modalDialog.show({
                                            type: 'success',
                                            content: raceBetsJS.i18n.data.msgBetActivatedBonus
                                        });
                                    } else if (data.extraStatusCode == 2) {
                                        raceBetsJS.application.assets.modalDialog.show({
                                            type: 'success',
                                            content: raceBetsJS.i18n.data.msgBetFinishedBonus
                                        });
                                    }

                                    // bet confirmation
                                    var content = $( raceBetsJS.application.templates.dialogs.betPlaced() );
                                    var publicId = this.idBetslip;
                                    var mobileDf;
                                    var footer = $('<p>' + raceBetsJS.i18n.data.betPlacedFooter + '</p>');

                                    var dom = {
                                        resultSms: content.filter('div.result-sms'),
                                        details: content.find('div.details'),
                                        checkbox: content.find('#betslip-sms-cb'),
                                        mobile: content.find('#betslip-sms-mobile'),
                                        error: content.find('span.error')
                                    };

                                    // initial hide of details
                                    dom.details.hide();

                                    if (raceBetsJS.application.globals.brandName === 'racebets') {
                                        dom.checkbox.change(function () {
                                            if (dom.checkbox.attr('checked')) {
                                                mobileDf = mobileDf || $.get('/ajax/particulars/getMobile');

                                                $.when(mobileDf).done(function (data) {
                                                    dom.mobile.val(data.mobile).data('default', data.mobile);
                                                    dom.resultSms.addClass('enabled');
                                                    dom.details.show();
                                                });
                                            } else {
                                                dom.details.hide();
                                                dom.resultSms.removeClass('enabled');
                                            }
                                        });

                                        dom.mobile.keypress(function (e) {
                                            if (e.which == 13) { // Enter pressed
                                                buttonAction();
                                            }
                                        });
                                    }

                                    var buttonAction = function () {
                                        var subscription;

                                        if (raceBetsJS.application.globals.brandName === 'racebets') {
                                            if (dom.checkbox.attr('checked')) {
                                                subscription = $.ajax({
                                                    url: '/ajax/betslip/resultsms',
                                                    type: 'post',
                                                    data: {
                                                        publicId: publicId,
                                                        mobile: dom.mobile.val(),
                                                        newMobile: (dom.mobile.val() != dom.mobile.data('default'))
                                                    },
                                                    beforeSend: raceBetsJS.application.assets.overlay.showPleaseWait
                                                });
                                            }

                                            $.when(subscription).always(function (data) {
                                                if (dom.checkbox.attr('checked')) {
                                                    raceBetsJS.application.assets.overlay.close(); // close the please wait overlay
                                                }

                                                if (!data || data.type == 'success') {
                                                    raceBetsJS.application.assets.overlay.close(); // close the dialog

                                                    // if it was a free-bet, reload race card
                                                    if (betData !== undefined && betData.idFreebet && raceBetsJS.browser.params.get('race') == 'details') {
                                                        raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/race/details/id/' + raceBetsJS.browser.params.get('id'));
                                                    }

                                                    return;
                                                }

                                                var errorMsg;
                                                switch (data.errorMsg) {
                                                    case 'INCORRECT_MOBILE':
                                                        errorMsg = raceBetsJS.i18n.data.msgResultSmsInvalidMobile;
                                                        break;

                                                    case 'INSUFFICIENT_FUNDS':
                                                        dom.checkbox.attr('checked', false).attr('disabled', true).next('label').css({'color':'#777777'});
                                                        dom.mobile.hide().next('span').hide();
                                                        errorMsg = raceBetsJS.i18n.data.msgResultSmsInsufficientFunds;
                                                        break;

                                                    default:
                                                        errorMsg = raceBetsJS.i18n.data.anErrorOccurred;
                                                }
                                                dom.error.html(errorMsg);
                                            });
                                        } else {
                                            // close the dialog
                                            raceBetsJS.application.assets.overlay.close();
                                            // if it was a free-bet, reload race card
                                            if (betData !== undefined && betData.idFreebet && raceBetsJS.browser.params.get('race') === 'details') {
                                                raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/race/details/id/' + raceBetsJS.browser.params.get('id'));
                                            }
                                        }
                                    };

                                    // insert quick pick info
                                    if (data.marks) {
                                        var quickPickDetails = $('<div />')
                                            .addClass('quick-pick-details')
                                            .append('<b>' + raceBetsJS.i18n.data.labelTotalStake2 + ':</b> ' + raceBetsJS.format.money(data.totalStake, 2, 'EUR') + '<br /><br /><b>' + raceBetsJS.i18n.data.labelYourMarks + ':</b>');

                                        var marksArr = data.marks.split('/');
                                        for (var i = 1; i <= 4; i++) {
                                            quickPickDetails.append('<br />' + raceBetsJS.i18n.data['labelCol' + i] + ': ' + marksArr[i-1].replace(/ /g, ' - '));
                                        }

                                        dom.resultSms.prepend(quickPickDetails);
                                    }

                                    raceBetsJS.application.assets.modalDialog.show({
                                        type: 'success',
                                        title: raceBetsJS.i18n.print('betPlacedTitle', { idBetslip: publicId }),
                                        content: content,
                                        closeButton: false,
                                        footer: footer,
                                        buttonAction: buttonAction
                                    });

                                    // update login info
                                    // raceBetsJS.application.header.login.updateLoginInfo();

                                    // increase customer's bets num
                                    var update = [];
                                    if (betData.betslipType == 'ACC') {
                                        $.each(this.bet.marksAcc, function (index, race) {
                                            update.push(parseInt(race.idRace));
                                        });
                                    } else {
                                        update.push(this.bet.idRace);
                                    }
                                    raceBetsJS.application.content.race.setMyBetsNum(update);

                                    // Track event
                                    dataLayer.push({
                                        'BetCategory': this.bet.betCategory,
                                        'BetType': this.bet.betType,
                                        'BetMarket': this.bet.country || this.extras.eventCountry || 'n/a',
                                        'CouponID': this.idBetslip,
                                        'TotalStake': this.bet.totalStakeEUR,
                                        'event': 'BetConfirmed'
                                    });

                                    if (raceBetsJS.application.globals.isB2B) {
                                        raceBetsJS.browser.crossFrame.send('betPlacementSuccess');
                                    }

                                    df.resolve();
                                    return;
                                }

                                // show error dialog
                                this.showError(data, df);

                                return false;
                            }
                        });
                    }, this));
            }

            return df.promise();
        },

        /**
        * showError
        *
        * Shows the error message for an according error code received while submitting the bet
        */
        showError: function (data, df) {
            var options,
                errorCode = data.errorCode || 'unknown',
                type = 'default';

            // Track event
            dataLayer.push({
                'Error': data.errorCode,
                'event': 'BetFailed'
            });

            switch (errorCode) {
                case 62:  // general tote error
                case 113: // can not connect with easygate server
                case 114: // wrong easygate response
                case 115: // easygate returned error
                    options = { content: raceBetsJS.i18n.print('msgToteError', { errorCode: errorCode }), type:'error' };
                    break;

                case 97:
                case 124: // not allowed to play TOT from your country (ATG restrictions)
                    options = { content: raceBetsJS.i18n.print('msgToteBlockedInCountry', { ipCountry: raceBetsJS.i18n.data.countries[raceBetsJS.application.user.ipCountry] }), type:'attention' };
                    break;

                case 103: // not enough money
                    options = {
                        type: 'attention',
                        content: raceBetsJS.i18n.data.msgInsufficientFunds,
                        buttons: [
                            {
                                label: raceBetsJS.i18n.data.buttonCancel,
                                action: function () {
                                    raceBetsJS.application.assets.overlay.close();
                                }
                            },
                            {
                                label: raceBetsJS.i18n.data.buttonDepositFunds,
                                action: function () {
                                    if(raceBetsJS.application.globals.isB2B) {
                                        raceBetsJS.browser.crossFrame.send('showDeposit');
                                        raceBetsJS.application.assets.overlay.close();
                                    } else {
                                        raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/cashier/deposit');
                                        raceBetsJS.application.assets.overlay.close();
                                    }
                                },
                                btnClass: 'action',
                                active: true
                            }
                        ]
                    };
                    break;
                
                case 105: // xtc race closed
                    options = { content: raceBetsJS.i18n.data.msgRaceClosed, type:'attention' };
                    break;

                case 106: // race not open
                    options = { content: raceBetsJS.i18n.data.msgRaceStarted, type:'attention' };
                    break;

                case 107: // stakes limit for this race is reached
                case 108: // runner stake limit is reached
                case 109: // runner payout limit is reached
                    options = { content: raceBetsJS.i18n.data.msgLimitReached, type:'attention' };
                    break;

                case 111: // fixed odds win do not match
                case 112: // fixed odds place do not match

                    // special logic for accumulation betslips
                    if (this.bet.betslipType === 'ACC') {
                        options = {
                            type:'attention',
                            content: raceBetsJS.i18n.data.msgAccFixedOddsChanged
                        };
                        df.reject();

                        // update odds
                        $.each(data.errorRunners, function (key, data) {
                            raceBetsJS.application.assets.accBetslip.updateOdds(data.idRunner, data.fixedOddsWin, data.fixedOddsPlace, true);
                        });

                        break;
                    }

                    // normal betslips
                    var content = raceBetsJS.i18n.data.msgFixedOddsChanged + ' ';
                    if (data.fixedOddsWin) {
                        content = content + raceBetsJS.i18n.print('msgNewWinFixedOdds', { odds: raceBetsJS.format.odds(data.fixedOddsWin) }) + ' ';
                    }
                    if (data.fixedOddsPlace) {
                        content = content + raceBetsJS.i18n.print('msgNewPlaceFixedOdds', { odds: raceBetsJS.format.odds(data.fixedOddsPlace) }) + ' ';
                    }
                    content = content + raceBetsJS.i18n.data.msgAcceptNewFixedOdds;

                    options = {
                        type:'attention',
                        content: content,
                        buttons: [
                            {
                                label: raceBetsJS.i18n.data.buttonYes,
                                action: $.proxy(function (e) {
                                    e.stopPropagation();
                                    df.resolve();
                                    raceBetsJS.application.assets.overlay.close();
                                    this.confirmChangedFixedOdds(data.fixedOddsWin, data.fixedOddsPlace);
                                }, this),
                                active: true
                            },
                            {
                                label: raceBetsJS.i18n.data.buttonNo,
                                action: $.proxy(function (e) {
                                    e.stopPropagation();
                                    df.reject();
                                    raceBetsJS.application.assets.overlay.close();

                                    // close fix odds betting dialog
                                    if (this.bet.betCategory == 'FXD') {
                                        Tipped.hideAll();
                                    }
                                }, this)
                            }
                        ]
                    };
                    break;

                case 116: // customer restrictions error
                case 144:
                    if (data.restrictionAmount && data.periodOfTime) {
                        var placeholders = {
                            limitAmount: raceBetsJS.format.money(data.restrictionAmount, 2, this.bet.currency),
                            period: data.periodOfTime
                        };
                        options = {
                            type:'attention',
                            content: raceBetsJS.i18n.print((data.restrictionType === 'STK') ? 'msgStakeLimit' : 'msgLossLimit', placeholders)
                        };
                    } else {
                        options = {
                            type:'attention',
                            content: raceBetsJS.i18n.data.msgStakeOrLossLimitReached
                        };
                    }
                    break;

                case 117: // customer is self-excluded via external database (i.e. Oasis)
                    options = {
                        type: 'error',
                        content: raceBetsJS.i18n.data.msgAccountExcludedExternal
                    };
                    break;

                case 118: // fixed odds are not active in this race (events_races.fixedOddsStatus != ON)
                    options = { content: raceBetsJS.i18n.data.msgFixedOddsOffline, type:'attention'};
                    break;

                case 119: // self-exclusion can not be verified against via external database (i.e. Oasis unavailable)
                    options = {
                        type: 'error',
                        content: raceBetsJS.i18n.data.msgAccountExcludedExternalServiceUnavailable
                    };
                    break;

                case 120: // TOT doesn't accept those marks
                    options = {
                        width: 500,
                        content: raceBetsJS.i18n.data.msgToteErrorCombination,
                        type:'attention'
                    };
                    break;

                case 126: // not allowed to bet on this event from user's country
                case 335: // not allowed to bet from user's country
                    options = {
                        content: raceBetsJS.i18n.print('msgUserCountryBlocked', { country: raceBetsJS.i18n.data.countries[raceBetsJS.application.user.country] }),
                        type: 'error'
                    };
                    break;

                case 132: // deposit required to use bonuses
                    options = {
                        type: 'attention',
                        content: raceBetsJS.i18n.data.errorDepositRequired,
                        buttons: [
                            {
                                label: raceBetsJS.i18n.data.buttonCancel,
                                action: function () {
                                    raceBetsJS.application.assets.overlay.close();
                                }
                            },
                            {
                                label: raceBetsJS.i18n.data.buttonDepositFunds,
                                action: function () {
                                    if(raceBetsJS.application.globals.isB2B) {
                                        raceBetsJS.browser.crossFrame.send('showDeposit');
                                        raceBetsJS.application.assets.overlay.close();
                                    } else {
                                        raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/cashier/deposit');
                                        raceBetsJS.application.assets.overlay.close();
                                    }
                                },
                                btnClass: 'action',
                                active: true
                            }
                        ]
                    };
                    break;

                case 133: // stake exceeds withdrawable balance
                    options = {
                        type: 'attention',
                        content: raceBetsJS.i18n.print('errorMinOddsRequired', {
                            minOdds: raceBetsJS.format.odds(1.5)
                        })
                    };
                    break;

                case 134: // bonuses cannot be placed on odds lower than 1.5
                    options = {
                        type: 'attention',
                        content: raceBetsJS.i18n.data.errorStakeExceedsWithdrawal
                    };
                    break;

                case 135: // invalid free bet
                case 137: // invalid free bet - cannot be used on that race/horse
                case 138: // invalid free bet - only one runner per free bet
                    options = {
                        type: 'attention',
                        content: raceBetsJS.i18n.data.errorFreeBetInvalid
                    };
                    break;

                case 136: // only one free bet per race
                    options = {
                        type: 'attention',
                        content: raceBetsJS.i18n.data.errorFreeBetOnePerRace
                    };
                    break;

                case 140: // maximum unit stake before price change
                    options = {
                        type: 'attention',
                        content: raceBetsJS.i18n.print('msgMaxUnitStakePrice', {
                            newUnitStake: raceBetsJS.format.money(data.newUnitStake, 2, this.bet.currency)
                        }),
                        buttons: [
                            {
                                label: raceBetsJS.i18n.data.buttonYes,
                                action: $.proxy(function (e) {
                                    e.stopPropagation();
                                    df.resolve();
                                    raceBetsJS.application.assets.overlay.close();

                                    this.bet.unitStake = data.newUnitStake;

                                    // close fix odds betting dialog
                                    if (this.bet.betCategory == 'FXD') {
                                        Tipped.hideAll();
                                    }
                                    this.submit();
                                }, this),
                                active: true
                            },
                            {
                                label: raceBetsJS.i18n.data.buttonNo,
                                action: $.proxy(function (e) {
                                    e.stopPropagation();
                                    df.reject();
                                    raceBetsJS.application.assets.overlay.close();
                                    // close fix odds betting dialog
                                    if (this.bet.betCategory == 'FXD') {
                                        Tipped.hideAll();
                                    }
                                }, this)
                            }
                        ]
                    };
                    break;

                case 142: // No bonus customer excluded from enhanced place markets WP betting
                    options = {
                        type: 'error',
                        content: raceBetsJS.i18n.data.errorNoEnhancedPlacesMarkets
                    };
                    break;


                case 145: // pending limit
                    if (data.restrictionAmount && data.periodOfTime) {
                        var placeholders = {
                            limitAmount: raceBetsJS.format.money(data.restrictionAmount, 2, this.bet.currency),
                            period: data.periodOfTime
                        };
                        options = {
                            type: 'attention',
                            content: raceBetsJS.i18n.print((data.restrictionType === 'STK') ? 'msgStakeLimit' : 'msgLossLimit', placeholders)
                        };
                    } else {
                        options = {
                            type: 'attention',
                            content: raceBetsJS.i18n.data.msgStakeOrLossLimitReached
                        };
                    }
                    break;

                case 147:
                    options = {
                        content: raceBetsJS.i18n.data.msgToteNoTestAccounts,
                        type:'error'
                    };
                    break;

                case 150: 
                    options = {
                        content: raceBetsJS.i18n.data.errorPoolBetClosed,
                        type:'error'
                    };
                    break;
                    
                case 151: // xtc horse was scratched while user selecting his bets
                    options = {
                        content: raceBetsJS.i18n.data.msgScratchedHorse,
                        type:'error'
                    };
                    break;

                case 152: // xtc stake not divisible by min unit stake
                    options = {
                        content: raceBetsJS.i18n.data.stepSizeInvalidTote,
                        type:'error'
                    };
                    break;
                case 153: 
                    options = {
                        content: raceBetsJS.i18n.data.msgToteBlockedCustomerCountry,
                        type:'error'
                    };
                    break;

                case 207:
                    options = {
                        content: raceBetsJS.i18n.data.errorLimitedAccessExpired,
                        type:'error'
                    };
                    break;

                case 210: // Account not verified
                    options = {
                        content: raceBetsJS.i18n.data.errorAccountNotVerified,
                        type: 'error'
                    };
                    break;

                case 301: // rms bet not accepted
                case 310: // rms bet not accepted (auto decision)
                case 330: // Customer is not allowed to bet (noBetting property)
                    options = { content: raceBetsJS.i18n.data.betResponseRejected, type:'attention' };
                    break;

                case 302: // rms new unit stake
                    options = {
                        type:'attention',
                        content: raceBetsJS.i18n.print('msgNewUnitStake', {
                            newUnitStake: raceBetsJS.format.money(data.newUnitStake, 2, this.bet.currency)
                        }),
                        buttons: [
                            {
                                label: raceBetsJS.i18n.data.buttonYes,
                                action: $.proxy(function (e) {
                                    e.stopPropagation();
                                    df.resolve();
                                    raceBetsJS.application.assets.overlay.close();
                                    this.confirmChangedUnitStake(data.newUnitStake, data.idRms);
                                }, this),
                                active: true
                            },
                            {
                                label: raceBetsJS.i18n.data.buttonNo,
                                action: function (e) {
                                    e.stopPropagation();
                                    df.reject();
                                    raceBetsJS.application.assets.overlay.close();
                                }
                            }
                        ]
                    };
                    break;

                case 306: // accepting WP only as WIN
                    options = {
                        type:'attention',
                        content: raceBetsJS.i18n.data.msgAcceptWinOnly,
                        buttons: [
                            {
                                label: raceBetsJS.i18n.data.buttonYes,
                                action: $.proxy(function (e) {
                                    e.stopPropagation();
                                    df.resolve();
                                    raceBetsJS.application.assets.overlay.close();

                                    this.bet.numBets = this.bet.numBets / 2;
                                    this.bet.betType = 'WIN';
                                    this.bet.idRms = data.idRms;
                                    this.submit();

                                }, this)
                            },
                            {
                                label: raceBetsJS.i18n.data.buttonNo,
                                action: function (e) {
                                    e.stopPropagation();
                                    df.reject();
                                    raceBetsJS.application.assets.overlay.close();
                                }
                            }
                        ]
                    };
                    break;

                case 307: // some runners not accepted as FXD and redirected to BOK
                    var content = raceBetsJS.i18n.data.multiple307Error;
                    content += '<br /><ul style="list-style-type: disc; margin: 10px 20px;">';

                    var runnerIds = data.runnersSp.split(',');
                    $.each(runnerIds, $.proxy(function (i, idRunner) {
                        var runner = this.extras.runners[idRunner];
                        content += '<li>' + runner.name + ' (' + runner.programNumber + ')</li>';
                    }, this));

                    content += '</ul>';

                    options = {
                        type:'attention',
                        content: content,
                        buttons: [
                            {
                                label: raceBetsJS.i18n.data.buttonYes,
                                active: true,
                                action: $.proxy(function (e) {
                                    e.stopPropagation();
                                    df.resolve();
                                    raceBetsJS.application.assets.overlay.close();

                                    // make sure we are handling integers
                                    runnerIds = $.map(data.runnersSp.split(','), function (val, i) {
                                        return parseInt(val);
                                    });

                                    // add runner details to content
                                    $.each(runnerIds, $.proxy(function (i, idRunner) {
                                        var runner = this.extras.runners[idRunner];
                                        content += '<li>' + runner.name + ' (' + runner.programNumber + ')<li>';
                                    }, this));

                                    // make association from idRunner to programNumber/
                                    var adjustedMarks = {};
                                    $.each(this.extras.runners, $.proxy(function (idRunner, runner) {
                                        if ($.inArray(parseInt(idRunner), runnerIds) > -1) {
                                            if (!_.has(adjustedMarks, runner.idRace)) {
                                                adjustedMarks[runner.idRace] = [];
                                            }
                                            adjustedMarks[runner.idRace].push(parseInt(runner.programNumber));
                                        }
                                    }, this));

                                    // adjust marks
                                    $.each(this.bet.marksAcc, $.proxy(function (i, leg) {
                                        $.each(leg.runners, $.proxy(function (j, runner) {
                                            if (_.has(adjustedMarks, leg.idRace) && $.inArray(parseInt(runner.programNumber), adjustedMarks[leg.idRace]) > -1) {
                                                delete runner.fixedOddsWin;
                                                runner.useSp = 1;
                                            }
                                        }, this));
                                    }, this));

                                    // set all categories (just for nicer display)
                                    //raceBetsJS.application.assets.accBetslip.changeBetCategory(runnerIds);

                                    // set idRms and resubmit
                                    this.bet.idRms = data.idRms;
                                    this.submit();

                                }, this)
                            },
                            {
                                label: raceBetsJS.i18n.data.buttonNo,
                                action: function (e) {
                                    e.stopPropagation();
                                    df.reject();
                                    raceBetsJS.application.assets.overlay.close();
                                }
                            }
                        ]
                    };
                    break;

                case 309: // the bet is not accepted as FXD, but can be places as BOK
                    options = {
                        type:'attention',
                        content: raceBetsJS.i18n.data.errorCannotAcceptFXD,
                        closeButton: false,
                        buttons: [
                            {
                                label: raceBetsJS.i18n.data.buttonOK,
                                active: true,
                                action: $.proxy(function (e) {
                                    e.stopPropagation();
                                    df.resolve();
                                    raceBetsJS.application.assets.overlay.close();

                                    // set idRms, send as bookie and resubmit
                                    this.bet.betCategory = 'BOK';
                                    this.bet.idRms = data.idRms;
                                    this.submit();

                                }, this)
                            },
                            {
                                label: raceBetsJS.i18n.data.buttonCancel,
                                action: function (e) {
                                    e.stopPropagation();
                                    df.reject();
                                    raceBetsJS.application.assets.overlay.close();
                                }
                            }
                        ]
                    };
                    break;
                case 345:
                    options = {
                        content: raceBetsJS.i18n.data.errorBetTypeLimitReached,
                        type:'error'
                    };
                    break;
                case 714: // Techsson - not allowed to use bonus for TOT
                    options = {
                        type:'error',
                        content: raceBetsJS.i18n.data.errorBonusNotAllowedOnTote,
                        buttons: [
                            {
                                label: raceBetsJS.i18n.data.buttonOK,
                                action: function () {
                                    raceBetsJS.application.assets.overlay.close();
                                }
                            },
                            {
                                label: raceBetsJS.i18n.data.buttonDepositFunds,
                                action: function () {
                                    if(raceBetsJS.application.globals.isB2B) {
                                        raceBetsJS.browser.crossFrame.send('showDeposit');
                                        raceBetsJS.application.assets.overlay.close();
                                    } else {
                                        raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/cashier/deposit');
                                        raceBetsJS.application.assets.overlay.close();
                                    }
                                },
                                btnClass: 'action',
                                active: true
                            }
                        ]
                    };
                    break;
                
                case 804: // Lugas - PARALLEL_GAMING_WITH_OTHER_PROVIDER
                    options = { content: raceBetsJS.i18n.data.errorLugasParallelOtherProvider, type:'attention' };
                    break;
                case 805: // Lugas - PARALLEL_GAMING_COOLING_OFF
                    options = { content: raceBetsJS.i18n.data.errorLugasParallelCoolingOff, type:'attention' };
                    break;
                default:
                    options = { content: raceBetsJS.i18n.print('msgUnknownError', { errorCode: errorCode }), type:'error' };
            }

            // special cases where the reject is handled in the case-statements
            if ($.inArray(errorCode, [111, 112, 302, 306, 307, 309]) === -1) {
                df.reject();
            }
            if (options.germanLimit !== true) {
                raceBetsJS.application.assets.modalDialog.show($.extend({
                    buttonAlign: 'right'
                }, options));
            }

        },

        /**
        * confirmChangedFixedOdds
        *
        * Shows a dialog to confirm the new fixed odds
        */
        confirmChangedFixedOdds: function (win, place) {
            if (win) {
                this.bet.fixedOddsWin = win;
            }

            if (place) {
                this.bet.fixedOddsPlace = place;
            }

            // place bet again
            this.submit();
        },

        /**
        * confirmChangedUnitStake
        *
        * Shows a dialog to confirm the new unit stake as received from RMS
        */
        confirmChangedUnitStake: function (newUnitStake, idRms) {
            this.bet.unitStake = newUnitStake;
            if (idRms) {
                this.bet.idRms = idRms;
            }

            // place bet again
            this.submit();
        },

        /**
        * getMarksString
        *
        * generate the marks string
        */
        getMarksString: function () {
            if (this.bet.quicktipp) {
                return undefined;
            }

            if (this.bet.betslipType != 'ACC') {
                var marks = [];
                $.each(this.bet.marks, function (col, runners) {
                    var runnersPn = [];
                    $.each(runners, function (key, runner) {
                        runnersPn.push(parseInt(runner.programNumber));
                    });
                    marks.push(runnersPn.join(' '));
                });
                return marks.join('/');
            } else {
                var ret = JSON.stringify(this.bet.marksAcc);
                return ret;
            }
        },

        /**
        * showConfirmationDialog
        *
        * Shows a dialog to confirm the bet details
        */
        showConfirmationDialog: function () {
            var df = $.Deferred();

            var options = {
                title: raceBetsJS.i18n.data.betslipConfirmationHead,
                type: 'attention',
                closeButton: false,
                content: raceBetsJS.application.templates.betConfirmationDialog({
                    bet: this.bet,
                    extras: this.extras,
                    hideProgramNumber: this.extras.isAntePost || this.extras.isSpecialBet
                }),
            buttons: [
                    {
                        label: raceBetsJS.i18n.data.buttonCancel,
                        action: function (e) {
                            e.stopPropagation();
                            df.reject(true);
                        }
                },
                    {
                        label: raceBetsJS.i18n.data.buttonPlaceBet,
                        active: true,
                        action: function (e) {
                            e.stopPropagation();
                            df.resolve(true);
                        }
                }
                ]
            };

            raceBetsJS.application.assets.modalDialog.show(options);

            // tooltips
            Tipped.create('.overlay table.betslip-confirmation .tipped', {
                hook: 'bottommiddle',
                maxWidth: 600,
                hideOn: [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' }
                ]
            });

            return df.promise();
        }
    });
})(raceBetsJS);
