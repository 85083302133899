/**
* Header initialization
*
* @description Initialisation function for all functional units within the header.
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
	raceBetsJS.application.header.init = function() {
		raceBetsJS.application.header.navigation.init();

        if (!raceBetsJS.application.globals.isB2B) {
            raceBetsJS.application.header.clock.init();

            var xtremePushIsEnabled = raceBetsJS.application.assets.settings.getXtremePushEnabledState();
            if(xtremePushIsEnabled) {
                var messages = $('#header-container [data-key="messages"]')
                var badge = $('#header-container [data-key="badge"]')
                // Show bell icon only if xtremepush injected and user is logged-in
                var showBellIcon = window.xtremepush && raceBetsJS.application.user.loggedIn;

                // Listen to Bell icon click
                messages.on('click', function(e) {
                    e.preventDefault();
                    if(window.xtremepush) {
                        // Hide new messages count
                        badge.text(0).toggleClass('isHidden', true);
                        $(this).toggleClass(function(index, className) {
                            if(className.indexOf('close') === -1) {
                                xtremepush('inbox', 'open');
                            } else {
                                xtremepush('inbox', 'close');
                            }
                            return 'close'
                        })
                    } else {
                        // redirect to the /xtremepush opt-in page.
                        // most likely, the user dismissed the browser's native dialog instead of opting in or out.
                        raceBetsJS.browser.history.navigateTo(
                            raceBetsJS.application.assets.settings.xtremePushOptInPageRoute
                        );
                    }
                });

                $.subscribe('$routeChangeSuccess', function() {
                    if(window.xtremepush) {
                        // Show icon on navigation. We do not have an event to listen to when a new message arrives, therefore we check for it on every route change
                        showBellIcon ? messages.removeClass('isHidden') : messages.addClass('isHidden');
                        xtremepush('ready', function() {
                            // make sure to retrieve inbox badge inside ready callback to ensure that user is registered
                            xtremepush('inbox', 'badge', {}, function(result) {
                                var count = result && result.badge ? parseInt(result.badge, 10) : 0;
                                badge.text(count).toggleClass('isHidden', count < 1)
                            }, function(err) {
                                console.log(err);
                            });
                        });
                    }
                });

                setTimeout(function(){
                    showBellIcon ? messages.removeClass('isHidden') : messages.addClass('isHidden');
                 }, 3000);

                $.subscribe('xtremepush:injected', function() {
                    showBellIcon ? messages.removeClass('isHidden') : messages.addClass('isHidden');
                });

            }
        }
    };
})(raceBetsJS);
