/**
* Login Box
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
    'use strict';

    raceBetsJS.application.header.login = function() {
        // @private

        var initial = true,
            cookie,
            currentFreeBets;

        function init() {

            if (raceBetsJS.application.user.loggedIn) {

                // Read balance hidden from user settings cookie
                cookie = raceBetsJS.application.assets.settings.get(true);

                // Updating balance from node
                if (raceBetsJS.application.globals.webSockets && (raceBetsJS.application.user.cookie = $.parseJSON($.cookie('sockie')) )) {
                    // First we subscribe loggedInNode event

                    /*$.subscribe('loggedInNode',function(){

                        // all user's channels must respect 'user_channel_userID'
                        $.subscribe(
                            'user_balance',
                             function(dat){
                                    dat.dropDownActive = dropDownActive;
                                    // Here's where we are going to update the balance.
                                    $loginInfo.empty().append(raceBetsJS.application.templates.loginBox(dat));
                                    initToolips();
                                    if (dat.bonus !== undefined) {
                                        raceBetsJS.application.assets.mobileVerification.show({ data : dat });
                                    }
                                }
                        );
                    });
                    */
                }
            }
        }

        function updateLoginInfo(data, first) {
            // Publish freebet updates
            if (!raceBetsJS.object.equals(currentFreeBets, data.freebets)) {
                // Update freebets object
                currentFreeBets = data.freebets || false;

                // Publish Freebets update
                $.publish('/updateLoginInfo/freeBets', [data.freebets || false]);

            }

            if (first) {
                // Set initial to false
                initial = false;

                // Store current Freebets
                currentFreeBets = raceBetsJS.application.assets.session.get('freebets');

                // Publish event of first updateLoginInfo
                $.publish('/updateLoginInfo/first', [data]);
            }
        }

        /**
        * check
        *
        * stupid function, has to be removed sooner or later...
        */
        function check() {
            if (!raceBetsJS.application.user.loggedIn) {
                raceBetsJS.application.assets.dialogs.loginDialog.show();
                return false;
            }

            return true;
        }


        // @public
        return {
            init: init,
            check: check,
            updateLoginInfo: updateLoginInfo
        };
    }();
})(raceBetsJS);
