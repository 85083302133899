/**
* Event
*
* @description Implements the observer pattern.
*
* @author Richard Assar
*/
(function(raceBetsJS) {
	raceBetsJS.events.Event = Class.extend({
		init: function(options) {
			this.observers = [];
		},

		observe: function(callback) {
			this.observers.push(callback);
		},

		fire: function(data) {
			for(var i = 0; i < this.observers.length; i++) {
				this.observers[i](data);
			}
		}
	});
})(raceBetsJS);