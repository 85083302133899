/**
* Core
*
* @description Initial definition of the RaceBetsJS framework.
*
* @author Moritz Honig
*/

(function(window, undefined) {
	// Ensure the correct document is being used.
	var document = window.document;

	/**
	* Definition of the RaceBetsJS namespace.
	*/
	var raceBetsJS = (function() {
		// @private

		// @public
		return {
			/**
			* Top level package declarations within the RaceBetsJS namespace.
			*/
			i18n: {},
			browser: {},
			events: {},
            media: {},
			time: {},
            ui: {},
			object: {},
			string: {},
            content: {},
            webSockets : {},
            angularApp: {}
		};
	})();

	/**
	* Export the library by attaching it to the global (window) scope.
	*/
	window.raceBetsJS = window.$RB = raceBetsJS;
})(window);
