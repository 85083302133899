/**
* Betslip
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
    raceBetsJS.application.content.race.Betslip = Class.extend({
        /**
        * Object of bet types
        */
        betTypes: {
            BOK: [],
            US: []
        },

        /**
        * Array of all checkbox columns
        */
        checkboxCols: [1, 2, 3, 4, 5, 'c'],

        /**
        * init
        *
        * Constructor of the Betslip class
        */
        init: function(elemOptions, elemFooter, card) {
            this.card = card;
            this.betslipType = null;

            // dom elements
            this.dom = {
                options: elemOptions,
                footer: elemFooter,
                footerContainer: elemFooter.children('div.betslip-footer-container')
            };

            this.close(false);


            // check if accumulation betslip has to be opened
            var openRace = (this.card.data.race.raceStatus == 'OPN'),
                isOpen = raceBetsJS.application.assets.accBetslip.isOpen();

            if (isOpen == 'acc' && this.card.data.event.multiplesBok && openRace) {
                this.open('acc', true);
            } else if (isOpen == 'pick' && raceBetsJS.application.assets.accBetslip.hasRace(this.card.data.race.idRace) && openRace) {
                this.open('pick', true);
            }
        },

        /**
        * initBetslip
        *
        * Initialize the betslip (set buttons, observers etc.)
        */
        initBetslip: function() {
            this.initialized = true;
            this.betTypes = {
                BOK: ['WIN', 'PLC', 'WP', 'EXA', 'TRI', 'SFC', 'QNL', 'SWG', 'TRO', 'ITA', 'TRT'],
                US: ['WIN', 'PLC', 'SHW', 'WP', 'WS', 'PS', 'WPS', 'EXA', 'QNL', 'TRI', 'SFC'],
                FRTOT: ['TOF', 'QRP', 'QNP', 'SF4', 'TRC', 'M4', 'M5', 'M6', 'M7', 'PK5']
            };

            // cache further dom elements
            this.dom = $.extend(this.dom, {
                betCategories: this.dom.options.find('div.category li'),
                betTypes: this.dom.options.find('div.type li'),
                minStakeLabel: this.dom.options.find('div.type div.label span.min-stake'),
                stakeLabel: this.dom.options.find('div.stake div.label h3'),
                stakeInput: this.dom.options.find('div.stake div.label input'),
                stakes: this.dom.options.find('div.stake li'),
                checkboxes: {
                    1: {},
                    2: {},
                    3: {},
                    4: {},
                    5: {},
                    c: {}
                },
                betStatus: this.dom.footer.find('p.bet-status'),
                diffCurrency: this.dom.footer.find('p.diff-currency'),
                numBets: this.dom.footer.find('p.num-bets'),
                totalStake: this.dom.footer.find('p.total-stake'),
                submitButton: this.dom.footer.find('button.betslip-submit'),
                bonusMoneyInfo: this.dom.footer.find('.bet-info .bonus-money-info')
            });

            // Extend BOK bet type for suaposta US races
            if (this.card.data.event.toteGateway === 'EGT' && this.card.data.betTypes.normal.BOK && raceBetsJS.application.globals.brandName === 'suaposta') {
                this.betTypes.BOK = this.betTypes.US.filter(function(bt) { return bt !== 'WPS' });
                //this.dom.betTypes.filter('.is-fr-tot.row3').removeClass('is-fr-tot').addClass('is-us-bok');
                //$('#card-betslip-options').addClass('is-three-rows');
            }

            var isFRTOT = false;

            if (this.card.data.betTypes.normal.TOT) {
                for (var betTypeKey in this.card.data.betTypes.normal.TOT) {
                    if (this.card.data.betTypes.normal.TOT.hasOwnProperty(betTypeKey)) {
                        if(this.betTypes.FRTOT.indexOf(betTypeKey) > -1) {
                            isFRTOT = true;
                        }
                    }
                }
            }

            if (isFRTOT) {
                $.merge(this.betTypes.BOK, this.betTypes.FRTOT);
            } else {
                this.dom.betTypes.filter('.is-fr-tot').hide();
                $('#card-betslip-options').removeClass('is-four-rows');
            }

            // cache checkboxes dom references
            this.card.runners.dom.table.find('tr.runner td.checkboxes span').each($.proxy(function(key, elem) {
                elem = $(elem);
                var col = elem.data('col');
                var idRunner = elem.parents('tr.runner').data('id-runner');

                this.dom.checkboxes[col][idRunner] = elem;

                // disable checkboxes if runner is scratched
                if (col == 'c' && this.card.data.runners.data[idRunner].scratched) {
                    this.updateRunnerStatus(idRunner);
                }
            }, this));

            // bet categories observer
            this.dom.options.on('click', 'div.category li.enabled', $.proxy(function(e) {
                this.setBetCategory($(e.currentTarget).data('bet-category'));
            }, this));

            // bet types observer
            this.dom.options.on('click', 'div.type li.enabled', $.proxy(function(e) {
                var oldBetType = this.bet.betType;
                var newBetType = $(e.currentTarget).data('bet-type');

                this.setBetType(newBetType);

                // Toggle label in table header for checkbox column
                switch(newBetType) {
                    case 'PLC':
                        this.card.dom.thCol1.text(raceBetsJS.i18n.data.labelOddsPlaced).removeAttr( 'style' );
                        break;
                    case 'WP':
                        this.card.dom.thCol1.text(raceBetsJS.i18n.data.betTypeWP).css( 'width', '54px' );
                        break;
                    default:
                        this.card.dom.thCol1.text(raceBetsJS.i18n.data.labelCol1).removeAttr( 'style' );
                  }

                // only if change is to 'WIN' or from 'WIN' bet type remove stable with different program number from betslip
                if (oldBetType === 'WIN' || newBetType === 'WIN') {
                    var runners = this.card.data.runners.data,
                        stableObj = this.card.data.race[(this.card.data.event.toteCurrency == 'EUR' ? 'stables' : 'stablesOrig')];

                    // each column
                    for (var col in this.bet.marks) {
                        var marks = this.bet.marks[col];

                        // each mark (idRunner)
                        for (var i = 0; i < marks.length; i++) {
                            var isStableWithDiffProgNumber = false;
                            var idRunner = marks[i];

                            // each stable array
                            for (var key in stableObj) {
                                var stable = stableObj[key];

                                // find stable array with runner with passed 'idRunner'
                                if (stable.indexOf(idRunner) > -1) {

                                    // if idRunner was found in stable array
                                    for (var j = 0; j < stable.length; j++) {

                                        // check if idRunner is different then stable runner id and if their program numbers are different
                                        if(idRunner !== stable[j] && runners[stable[j]].programNumber !== runners[idRunner].programNumber ) {
                                            isStableWithDiffProgNumber = true;
                                        }
                                    }
                                }
                            }

                            if (isStableWithDiffProgNumber) {
                                this.toggleMark(col, idRunner, true);
                                // 'toggleMark' removes idRunner form 'this.bet.marks[col]' array so its length is -1
                                i--;
                            }
                        }
                    }
                }
            }, this));

            // stake buttons observer
            this.dom.options.on('click', 'div.stake li.enabled', $.proxy(function(e) {
                this.setStake($(e.currentTarget).data('stake'));
            }, this));

            // stake input observer
            this.dom.stakeInput
                .focus(function() {
                    $(this).select();
                })
                .mouseup(function(e) { // neccessary for Safari/Chrome
                    e.preventDefault();
                })
                .keyup(function(e) {
                    if (e.which == 13) { // ENTER key
                        $(this).blur();
                    }
                })
                .blur($.proxy(function(e) {
                    this.setStake(e.target.value);
                    this.updateStatus();
                }, this));

            // checkboxes observer
            this.card.runners.dom.table.on('click', 'td.checkboxes span.enabled', $.proxy(function(e) {
                var elem = $(e.target);
                this.toggleMark(elem.data('col'), elem.parents('tr.runner').data('id-runner'));
            }, this));

            // toggle column observer
            this.card.runners.dom.table.on('click', 'thead tr.bottom th.checkboxes span', $.proxy(function(e) {
                this.toggleColumn($(e.target).data('col'));
            }, this));

            // submit button observer
            this.dom.submitButton.on('click', $.proxy(this.submit, this));
        },

        /**
        * initOnOpen
        *
        * Set the checkbox observers etc.
        */
        initOnOpen: function() {
            // bet data
            this.bet = {
                idRace: this.card.data.race.idRace,
                betslipType: 'STD',
                betCategory: null,
                betType: null,
                unitStake: null,
                currency: null,
                numBets: 0,
                marks: {
                    1: [],
                    2: [],
                    3: [],
                    4: [],
                    5: [],
                    c: []
                },
                idFreebet: null
            };

            if (!this.initialized) {
                this.initBetslip();
            }

            // remove checkbox marks
            $.each(this.card.runners.dom.table.find('td.checkboxes span.enabled.checked'), function() {
                $(this).removeClass('checked');
            });

            // set checkbox marks for acc betslips
            if (this.betslipType != 'std' && this.betslipType != 'free-bet') {
                $.each(raceBetsJS.application.assets.accBetslip.getRunners(this.bet.idRace), $.proxy(function(index, idRunner) {
                    this.toggleMark(1, idRunner, true, true);
                }, this));
            }
        },

        /**
        * setBetCategory
        *
        * Set the bet's category
        */
        setBetCategory: function(category) {
            if (!this.card.data.betTypes.normal[category]) {
                return false;
            }

            // remove active class of old category's button
            if (this.bet.betCategory) {
                this.dom.betCategoryButtons[this.bet.betCategory].removeClass('active');
            }

            this.bet.betCategory = category;
            this.bet.currency = (category == 'TOT') ? this.card.data.event.toteCurrency : raceBetsJS.application.user.currency;
            this.dom.betCategoryButtons[category].addClass('active');

            this.updateBetTypes();
        },

        /**
        * setBetType
        *
        * Set the bet's type
        */
        setBetType: function(type) {
            if (!this.card.data.betTypes.normal[this.bet.betCategory][type]) {
                return false;
            }

            // remove active class of old type's button
            if (this.bet.betType) {
                this.dom.betTypeButtons[this.bet.betType].removeClass('active');
            }

            this.bet.betType = type;
            this.dom.betTypeButtons[type].addClass('active');

            // set min stake label
            var minUnitStake = (this.bet.betCategory === 'TOT')
                ? this.card.data.betTypes.normal[this.bet.betCategory][this.bet.betType][0]
                : raceBetsJS.format.getMinStakeBOKFXD(raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency], this.bet.betCategory, this.bet.betType);
            var minTotalStake = this.card.data.betTypes.normal[this.bet.betCategory][this.bet.betType][1];

            if (this.bet.betCategory === 'BOK'
                && $.inArray(this.card.data.event.country, ['DK', 'FI', 'NO', 'SE']) > -1
                && this.bet.betType === 'TRI'
            ) {
                // adjust different min stake for scandinavia
                minUnitStake = raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].minStakeBOKTRISE;
                this.setStake(minUnitStake);
            }

            if (this.bet.unitStake < minUnitStake) {
                this.setStake(minUnitStake);
            } else if (this.bet.betCategory === 'BOK') {
                var minUnitStakeDefault = raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].minStakeBOK;
                if (minUnitStake != minUnitStakeDefault) {
                    this.setStake(minUnitStake);
                }
            }

            this.dom.minStakeLabel.html(raceBetsJS.i18n.print('minAmountX', {
                amount: raceBetsJS.format.money(minUnitStake, 2, this.bet.currency, true) +
                    (minTotalStake > 0 ? " / " + raceBetsJS.format.money(minTotalStake, 2, this.bet.currency, true) : "")
            }));

            this.updateStakes();
            this.showCheckboxes();
        },

        /**
        * setStake
        *
        * Set the stake
        */
        setStake: function(stake) {
            // replace , with . for people from e.g. Germany
            if (typeof stake == 'string') {
                stake = stake.replace(/,/, '.') * 1;
            }

            // remove active class of old stake's button
            if (this.bet.unitStake && this.dom.stakeButtons[this.bet.unitStake]) {
                this.dom.stakeButtons[this.bet.unitStake].removeClass('active');
            }

            if (stake < 0 || isNaN(stake)) {
                this.bet.unitStake = null;
                return false;
            }

            this.bet.unitStake = stake;
            this.dom.stakeInput.val(raceBetsJS.format.money(this.bet.unitStake, 2));

            // malta tote adjustments
            if (this.bet.unitStake >= 20 && this.bet.betCategory == 'TOT' && this.card.data.event.country == 'MT') {
                if ($('#tote-pool-notice').size() < 1) {
                    raceBetsJS.application.assets.messageBox.show({
                        id: '#tote-pool-notice',
                        content: raceBetsJS.i18n.data.noticeTotePoolSize,
                        after: '#card-betslip-options',
                        closeButton: false,
                        fadeOut: false
                    });
                }
            } else {
                $('#tote-pool-notice').remove();
            }
            // -- malta tote adjustments --

            if (this.dom.stakeButtons && this.dom.stakeButtons[stake]) {
                this.dom.stakeButtons[stake].addClass('active');
            }

            this.updateStatus();
        },

        /**
        * showFixedOddsTip
        *
        * Show tip to explain fixed odds
        */
        showFixedOddsTip: function() {
            var elem = this.card.runners.dom.table.find('.odds-button.fixed').not('.non-runner').first();
            Tipped.create(elem, '<p>' + raceBetsJS.i18n.data.fixedOddsBettingTip + '</p>', {
                fadeIn: 300,
                hideOn: (raceBetsJS.browser.type.isiPad()) ? [
                    { element: 'self', event: 'mouseleave' },
                    { element: 'tooltip', event: 'mouseenter' },
                    { element: 'tooltip', event: 'click' }
                ] : 'click-outside',
                maxWidth: 240,
                hook: 'leftmiddle',
                skin: 'racebets',
                showOn: false
            }).show();
        },

        /**
        * reset
        *
        * Resets the betslip (unchecks all checkboxes)
        */
        reset: function() {
            if (this.betslipType === 'free-bet') {
                // if freebet, reset freebet panel


            } else {
                // normal betslip, uncheck all checkboxes
                for (var col in this.bet.marks) {
                    var marks = this.bet.marks[col];

                    while(marks.length > 0) {
                        this.toggleMark(col, marks[0], true);
                    }
                }
            }
        },

        /**
        * getCleanMarks
        *
        * Returns the marks array cleaned from stable horses and when defined with horse names
        */
        getCleanMarks: function(allDetails) {
            var marks = {};

            $.each(this.bet.marks, $.proxy(function(col, runners) {
                var runnersClean = [];
                var ignoreRunners = []; // filled with the id's of stable horses

                $.each(runners.sort(), $.proxy(function(key, idRunner) {
                    // ignore this runner if the stable is already in the marks
                    if ($.inArray(idRunner, ignoreRunners) > -1) {
                        return;
                    }

                    // add stable horses to the ignore list
                    var stable = this.getStableHorses(idRunner);

                    $.extend(ignoreRunners, stable);

                    if (!allDetails) {
                        // push this runner to the marks string
                        runnersClean.push(idRunner);

                    } else {
                        var name = this.card.data.runners.data[idRunner].name;
                        $.each(stable, $.proxy(function(key, idRunnerStable) {
                            name = name + '/' + this.card.data.runners.data[idRunnerStable].name;
                        }, this));

                        runnersClean.push({
                            programNumber: parseInt(this.card.data.runners.data[idRunner].programNumber),
                            name: name
                        });
                    }
                }, this));

                if (allDetails) {
                    runnersClean = _.sortBy(runnersClean, function(runner) {
                        return runner.programNumber;
                    });
                }

                marks[col] = runnersClean;
            }, this));

            if(this.bet.betType !== 'QNP') {
                delete marks[5];
            }

            return marks;
        },

        /**
        * getStableHorses
        *
        * Returns an array of idRunners which are in the same stable
        */
        getStableHorses: function(idRunner) {
            var result = [],
                runners = this.card.data.runners.data,
                stableObj = this.card.data.race[(this.card.data.event.toteCurrency == 'EUR' ? 'stables' : 'stablesOrig')],
                runnerProgramNumber = this.card.data.runners.data[idRunner].programNumber,
                betType = raceBetsJS.application.assets.accBetslip.isOpen() ? raceBetsJS.application.assets.accBetslip.betType() : this.bet.betType;

            for (var key in stableObj) {
                var stable = stableObj[key];

                // find stable array with runner with passed 'idRunner'
                if (stable.indexOf(idRunner) > -1) {
                    for (var i = 0; i < stable.length; i++) {

                        // return only runners with diffrent id then 'idRunner'
                        // and only with the same program number (USA)
                        // or different program number but only when bet type is WIN
                        if(idRunner !== stable[i] && (runners[stable[i]].programNumber === runnerProgramNumber || betType === 'WIN') ) {
                            result.push(stable[i]);
                        }
                    }
                }
            }

            return result;
        },

        /**
        * toggleColumn
        *
        * Toggles a complete column of checkboxes
        */
        toggleColumn: function(col) {
            if (col == 'c') {
                var markedElsewhere = _.union(this.bet.marks[1], this.bet.marks[2], this.bet.marks[3], this.bet.marks[4]);

                var runnerIds = $.map(_.values(this.card.data.runners.order), function(idRunner){
                    return parseInt(idRunner);
                });

                if (this.bet.marks['c'].length == this.card.runners.numNotScratched - markedElsewhere.length) {
                    // remove all marks
                    $.each($.extend([], this.bet.marks['c']), $.proxy(function(index, idRunner) {
                        this.toggleMark('c', idRunner, true);
                    }, this));
                } else {
                    // mark all runners in column c which are not marked elsewhere or in column c
                    $.each(_.difference(runnerIds, _.union(this.bet.marks['c'], markedElsewhere)), $.proxy(function(index, idRunner) {
                        this.toggleMark('c', idRunner, true);
                    }, this));
                }
            } else {
                var toggleAll = (this.bet.marks[col].length == 0 || this.bet.marks[col].length == this.card.runners.numNotScratched);

                $.each(this.card.data.runners.order, $.proxy(function(i, idRunner) {
                    if (!toggleAll && this.isMarked(col, idRunner)) {
                        // toggle all unchecked one's only
                        return;
                    }

                    this.toggleMark(col, idRunner, true);
                }, this));
            }
        },

        /**
        * unmarkAll
        *
        * Unmark all checkboxes
        */
        unmarkAll: function(col) {
            $.each(this.bet.marks[col], $.proxy(function(index, idRunner) {
                // remove check
                $(this.dom.checkboxes[col][idRunner]).removeClass('checked');

                // remove mark
                this.bet.marks[col].splice($.inArray(idRunner, this.bet.marks[col]), 1);
            }, this));
        },

        /**
        * toggleMark
        *
        * Check/Unchecks a runners mark
        */
        toggleMark: function(col, idRunner, ignoreStables, noAccTrigger) {
            // if exclusive, no manual toggling is possible
            if (this.isExclusiveMark === true) {
                return;
            }

            idRunner = parseInt(idRunner);
            var elem = this.dom.checkboxes[col][idRunner];

            if (!this.isMarked(col, idRunner)) {
                // check but abort if it is a non-runner and not stable horse
                if (this.card.data.runners.data[idRunner].scratched && ignoreStables) {
                    return;
                }

                // in free bets, only allow one mark to be checked
                if (this.betslipType == 'free-bet') {
                    this.unmarkAll(1);
                }

                if (['QRP', 'QNP', 'SF4', 'TRC'].indexOf(this.bet.betType) > -1 ) {
                    if (col !== 'c') {
                        this.unmarkAll(col);
                        for (var i = 1; i <= 5; i++) {
                            if (this.isMarked(i, idRunner)) {
                                this.toggleMark(i, idRunner);
                            }
                        }
                    }
                }


                if (col == 'c') {
                    // check if any of columns 1-4 of this runners is marked and toggle if so
                    for (var i = 1; i <= 5; i++) {
                        if (this.isMarked(i, idRunner)) {
                            this.toggleMark(i, idRunner);
                        }
                    }
                } else {
                    // check if c column is marked and toggle if so
                    if (this.isMarked('c', idRunner)) {
                        this.toggleMark('c', idRunner);
                    }
                }
                if (['acc', 'pick'].indexOf(this.betslipType) === -1) {
                    // no acc betslip
                    elem.addClass('checked');
                    this.bet.marks[col].push(idRunner);

                } else if (this.betslipType !== 'std' && this.betslipType !== 'free-bet') {
                    // add runner (but check if not scratched because of stable horses)
                    if ((this.addAccumulationRunner(col, idRunner) === true || noAccTrigger) && this.card.data.runners.data[idRunner].scratched === false) {
                        elem.addClass('checked');
                        this.bet.marks[col].push(idRunner);
                    }
                }

            } else {
                // uncheck
                elem.removeClass('checked')
                this.bet.marks[col].splice($.inArray(idRunner, this.bet.marks[col]), 1);

                if (this.betslipType != 'std' && this.betslipType != 'free-bet') {
                    raceBetsJS.application.assets.accBetslip.removeRunner({
                        idRace: this.card.data.race.idRace,
                        idRunner: idRunner,
                        programNumber: this.card.data.runners.data[idRunner].programNumber,
                        noPublish: true
                    });
                }
            }

            // if this is a stable horse mark all other horses from that stable
            if (!ignoreStables) {
                var stableArr = this.getStableHorses(idRunner);
                var thisVal= elem.hasClass('checked');

                for (var i = 0; i < stableArr.length; i++) {
                    // toggle stable checkbox only if has diffrent value then orginal checkbox
                    if(thisVal !== this.dom.checkboxes[col][stableArr[i]].hasClass('checked')) {
                        this.toggleMark(col, stableArr[i], true, noAccTrigger);
                    }
                }
            }

            if (this.betslipType == 'std' ||  this.betslipType == 'free-bet') {
                this.updateStatus();
            }
        },

        /**
        * addAccumulationRunner
        *
        * Add runner to accumulation betslip
        */
        addAccumulationRunner: function(col, idRunner) {
            // collect bet types
            var betTypes = {},
                accBetTypes = ['WIN', 'PLC', 'WP', 'ITA', 'TRT'];

            $.each(['BOK', 'FXD'], $.proxy(function(i, key) {
                betTypes[key]= ((this.betslipType != 'acc' && (typeof noAccTrigger !== 'undefined' && !noAccTrigger)) || !_.has(this.card.data.betTypes.normal, key))
                    ? [] : _.intersection(accBetTypes, _.keys(this.card.data.betTypes.normal[key]));
            }, this));

            // delete fixed odds if multiplesFxd is false or fixed odds status it not ON
            var odds = this.card.data.runners.data[idRunner].odds;
            if (this.card.data.event.multiplesFxd === false || this.card.data.race.fixedOddsStatus !== 'ON') {
                delete odds.FXW;
                delete odds.FXP;
            }
            var isStableWithDiffProgNumber = function (idRunner, that) {
                var result = false,
                    runners = that.card.data.runners.data,
                    stableObj = that.card.data.race[(that.card.data.event.toteCurrency == 'EUR' ? 'stables' : 'stablesOrig')],
                    runnerProgramNumber = that.card.data.runners.data[idRunner].programNumber;

                for (var key in stableObj) {
                    var stable = stableObj[key];

                    // find stable array with runner with passed 'idRunner'
                    if (stable.indexOf(idRunner) > -1) {
                        for (var i = 0; i < stable.length; i++) {
                            if(idRunner !== stable[i] && (runners[stable[i]].programNumber !== runnerProgramNumber)) {
                                result = true;
                            }
                        }
                    }
                }
                return result;
            }

            // accumulation betslip
            var details = {
                race: {
                    idRace: this.card.data.race.idRace.toString(),
                    event: this.card.data.event.title,
                    country: this.card.data.event.country,
                    raceNumber: this.card.data.race.raceNumber,
                    betTypes: betTypes,
                    isSpecialBets: false,
                    isAntePost: this.card.data.event.isAntePost,
                    stables: this.card.data.race.stables,
                    runners: this.card.data.runners.data
                },
                runner: {
                    idRunner: idRunner.toString(),
                    betCategory: this.betTypeCat,
                    name: this.card.data.runners.data[idRunner].name,
                    programNumber: this.card.data.runners.data[idRunner].programNumber,
                    odds: this.card.data.runners.data[idRunner].odds,
                    scratched: this.card.data.runners.data[idRunner].scratched,
                    markAsStableWithDiffProgNumber: isStableWithDiffProgNumber(idRunner, this),
                    ignoreStable: true
                }
            };

            if (this.getStableHorses(idRunner).length > 0) {
                details.runner.ignoreStable = false;
                details.runner.stable = parseInt(this.card.data.runners.data[idRunner].programNumber);
            }

            // check if runner would be a valid one
            if (raceBetsJS.application.assets.accBetslip.isValidRunner(details)) {
                return raceBetsJS.application.assets.accBetslip.addRunner(details);
            }

            return false;
        },

        /**
        * isMarked
        *
        * Returns if a runners is marked in a given col
        */
        isMarked: function(col, idRunner) {
            idRunner = parseInt(idRunner, 10); // avoid problems when idRunner is given as string
            return ($.inArray(idRunner, this.bet.marks[col]) > -1);
        },

        /**
        * updateBetCategories
        *
        * Update the bet category buttons
        */
        updateBetCategories: function() {
            this.dom.betCategoryButtons = {};
            $.each(this.dom.betCategories, $.proxy(function(key, elem) {
                elem = $(elem);
                cat = elem.data('bet-category');

                // cache button dom
                this.dom.betCategoryButtons[cat] = elem;

                if (this.card.data.betTypes.normal[cat]) {
                    elem.addClass('enabled');
                } else {
                    elem.removeClass('enabled');
                }
            }, this));

            // set a default bet category
            if (!this.bet.betCategory || !this.card.data.betTypes.normal[this.bet.betCategory]) {
                var betCategory;

                // check if user has special setting for ignoring bookie presets
                if (raceBetsJS.application.user.ignoreBookiePreset) {
                    betCategory = (this.card.data.betTypes.normal.TOT) ? 'TOT' : 'BOK';
                } else {
                    betCategory = (this.card.data.betTypes.normal.TOT && (!this.card.data.race.bookiePreset || !this.card.data.betTypes.normal.BOK)) ? 'TOT' : 'BOK';
                }

                this.setBetCategory(betCategory);

            } else {
                this.setBetCategory(this.bet.betCategory);
            }
        },

        /**
        * updateBetTypes
        *
        * Updates the bet types buttons
        */
        updateBetTypes: function() {
            this.dom.betTypeButtons = {};
            this.betTypeCat = (this.bet.betCategory == 'TOT' && this.bet.currency == 'USD') ? 'US' : 'BOK';

            // walk through all the buttons, even empty one's
            var i = 0;

            $.each(this.dom.betTypes, $.proxy(function(key, elem) {
                elem = $(elem);
                var betType = this.betTypes[this.betTypeCat][i++];

                // cache button dom
                this.dom.betTypeButtons[betType] = elem;

                // set label and data of button
                elem.html((!betType) ? '&nbsp;' : '<span>' + raceBetsJS.format.betTypeName(betType, this.bet.betCategory, raceBetsJS.application.user.lang, (this.bet.currency == 'USD'), this.card.data.event.country))  + '</span>';
                elem.data('bet-type', betType);

                // add/remove enabled class
                if (this.card.data.betTypes.normal[this.bet.betCategory][betType]) {
                    elem.addClass('enabled');

                    // remove active class if betType is not selected
                    if (betType != this.bet.betType) {
                        elem.removeClass('active');
                    }
                } else {
                    elem.removeClass('enabled active');
                }
            }, this));

            // set default bet type (first available)
            if (!this.bet.betType || !this.card.data.betTypes.normal[this.bet.betCategory][this.bet.betType]) {
                $.each(this.betTypes[this.betTypeCat], $.proxy(function(key, betType) {
                    if (this.card.data.betTypes.normal[this.bet.betCategory][betType]) {
                        this.setBetType(betType);
                        return false;
                    }
                }, this));
            } else {
                this.setBetType(this.bet.betType);
            }
        },

        /**
        * updateStakes
        *
        * Updates the stakes buttons
        */
        updateStakes: function() {
            this.dom.stakeButtons = {};

            // set label for stakes field
            this.dom.stakeLabel.html(raceBetsJS.i18n.print('labelStakeInCurrency', { currency: this.bet.currency }));

            // update all buttons
            var stakes = _.values(raceBetsJS.application.assets.settings.get().dialog.betslip.stakes[this.bet.currency]);
            var minStake = (this.bet.betCategory == 'TOT')
                ? this.card.data.betTypes.normal[this.bet.betCategory][this.bet.betType][0]
                : raceBetsJS.format.getMinStakeBOKFXD(raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency], this.bet.betCategory, this.bet.betType);
            var i = 0;

            function getStakesForFRTOT(betType) {
                switch(betType) {
                    case 'TRC':
                        return [0.5, 1, 1.5];
                    case 'QRP':
                        return [0.65, 1.3, 2];
                    case 'QNP':
                    case 'SF4':
                        return [0.5, 1, 1.5];
                    case 'PK5':
                        return [0.1, 0.5, 1];
                    case 'TOF':
                        return [0.75, 1.5, 3];
                    case 'M4':
                    case 'M5':
                    case 'M6':
                    case 'M7':
                        return [0.75, 1.5, 3];
                    default:
                        return [0.5, 1, 1.5];
                  }
            };

            if(this.betTypes.FRTOT.indexOf(this.bet.betType) > -1) {
                var newFirstThreeStake = getStakesForFRTOT(this.bet.betType);

                stakes = stakes.filter(function(stake) {
                    return stake > newFirstThreeStake[2];
                });
                minStake = newFirstThreeStake[0];
                stakes.splice(0, 0, newFirstThreeStake[0], newFirstThreeStake[1], newFirstThreeStake[2]);
            }

            // if minimum stake is < lowest stake on buttons, add this stake and remove last
            if (parseFloat(minStake) < parseFloat(stakes[0])) {
                stakes.unshift(minStake);
                stakes.pop();
            }

            $.each(this.dom.stakes, $.proxy(function(key, elem) {
                elem = $(elem);
                var stake = stakes[i++];

                // cache button dom
                this.dom.stakeButtons[stake * 1] = elem;

                elem.html((!stake) ? '&nbsp;' : raceBetsJS.format.money(stake, ((stake < 10 || stake % 1 != 0) ? 2 : 0), this.bet.currency, true));
                elem.data('stake', stake);

                // add/remove enabled class
                if (parseFloat(stake) >= minStake) {
                    elem.addClass('enabled');

                    // remove active class if stake is not selected
                    if (stake != this.bet.unitStake) {
                        elem.removeClass('active');
                    }
                } else {
                    elem.removeClass('enabled active');
                }

                elem.html( '<span>'+elem.text()+'</span>' );

            }, this));

            // set default stake, if customers setting
            if (!this.bet.unitStake && raceBetsJS.application.assets.settings.get().dialog.betslip.preselection || this.betTypes.FRTOT.indexOf(this.bet.betType) > -1) {
                this.setStake(minStake);
            } else if (this.bet.unitStake) {
                this.setStake(this.bet.unitStake);
            }
        },

        /**
        * updateRunnerStatus
        *
        * enabling/disabling the checkboxes depending on the runners status
        */
        updateRunnerStatus: function(idRunner) {
            // only update status if betslip is initialized, otherwise status is updated during initialization
            if (!this.initialized) {
                return;
            }

            idRunner = parseInt(idRunner);

            $.each(this.checkboxCols, $.proxy(function(index, col) {
                if (this.card.data.runners.data[idRunner].scratched) {
                    // runner is scratched
                    if (this.isMarked(col, idRunner)) {
                        // @todo: warning that a mark has been removed
                        this.toggleMark(col, idRunner, true);
                    }
                    this.dom.checkboxes[col][idRunner].removeClass('enabled');
                } else {
                    // runner is not scratched
                    this.dom.checkboxes[col][idRunner].addClass('enabled');

                    // mark runner if the stable is marked
                    var stableHorses = this.getStableHorses(idRunner);
                    if (stableHorses.length && this.isMarked(col, stableHorses[0])) {
                        this.toggleMark(col, idRunner, true);
                    }
                }
            }, this));
        },

        /**
        * close
        *
        * Close betslip
        */
        close: function(animate, leaveCheckboxes) {
            var animate = (animate === undefined) ? true : animate;

            if (!this.isOpen() && animate) {
                return new $.Deferred().resolve().promise();
            }

            if (!leaveCheckboxes) {
                this.hideCheckboxes();
            }

            if (!raceBetsJS.application.globals.isB2B) {
                Tipped.remove(this.dom.bonusMoneyInfo);
            }

            this.dom.footer.hide();

            if (animate) {
                this.dom.options.slideUp(500, function() {
                    $(this).find('div.category li').removeClass('active enabled');
                });
            } else {
                this.dom.options.hide();
            }

            // stop sticky footer observer
            if (raceBetsJS.application.globals.isB2B) {
                $.unsubscribe(['/scroll']);
                $.unsubscribe(['/sizes']);
            }

            $(window).off('scroll.betslip'); // also called on race card unload!

            this.betslipType = null;

            // show free bets, if hidden
            $('#free-bets:hidden').slideDown();

            // hide tag
            this.hideTag();

            // unlock exclusive mark
            this.isExclusiveMark = false;

            return this.dom.options.promise();
        },

        /**
        * open
        *
        * Open betslip
        *
        * @todo: refactoring
        * All this opening stuff is very, very dirty and bad. I don't understand it by myself anymore. ;-)
        * Needs to be refactored definitively with a completely different concept. (Moritz, 3/2012)
        */
        open: function(betslipType, ignoreChecks) {
            var goAhead = true;
            var opened = this.isOpen();

            // if ante post market or restricted SP betting, show tip instead of betslip if multiples are not enabled
            if ((this.card.data.event.isAntePost && betslipType === 'std')
                || (this.card.data.race.restrictSP === 1 && $.inArray(raceBetsJS.application.user.ipCountry, ['GB', 'IE']) > -1)) {
                this.showFixedOddsTip();
                return false;
            }

            if (!ignoreChecks) {
                if (opened == betslipType && (betslipType != 'pick' || raceBetsJS.application.assets.accBetslip.getPickRace() == this.card.data.race.idRace)) {
                    // do nothing as betslip is already open
                    return false;

                } else if (opened || (raceBetsJS.application.assets.accBetslip.isOpen() && raceBetsJS.application.assets.accBetslip.getPickRace() != this.card.data.race.idRace)) {
                    goAhead = $.Deferred();
                    raceBetsJS.application.assets.modalDialog.show({
                        type: 'attention',
                        content: raceBetsJS.i18n.data.confirmDiscardBetslip,
                        buttons: [
                            {
                                label: raceBetsJS.i18n.data.buttonYes,
                                active: true,
                                action: function() {
                                    raceBetsJS.application.assets.overlay.close();

                                    var df = true;
                                    if (opened != 'std' && this.betslipType != 'free-bet') {
                                        df = raceBetsJS.application.assets.accBetslip.close();
                                    }

                                    $.when(df).done(goAhead.resolve);
                                }
                            },
                            {
                                label: raceBetsJS.i18n.data.buttonNo,
                                action: function() {
                                    goAhead.reject();
                                    raceBetsJS.application.assets.overlay.close();
                                }
                            }
                        ]
                    });
                }
            }

            var df = $.Deferred();

            $.when(goAhead).done($.proxy(function() {
                if (opened) {
                    this.close(true, true);
                }

                this.betslipType = betslipType;
                this.initOnOpen();

                // init bet categories (and other stuff as called from this function)
                this.updateBetCategories();

                // hide free bets
                if (betslipType !== 'free-bet') {
                    $('#free-bets').slideUp();
                }

                if (betslipType === 'free-bet') {
                    this.dom.footer.show();
                    this.showCheckboxes();

                    // sticky footer
                    this.relocateFooter();
                    $(window).on('scroll.betslip', $.proxy(this.relocateFooter, this));

                    if (raceBetsJS.application.globals.isB2B) {
                        $.subscribe('/scroll', $.proxy(this.relocateFooter, this));
                        $.subscribe('/sizes', $.proxy(this.relocateFooter, this));
                    }

                    df.resolve(true);

                } else if (betslipType == 'std') {
                    // standard betslip
                    this.dom.options.slideDown(500).promise().done($.proxy(function() {
                        this.dom.footer.show();
                        this.showCheckboxes();

                        // sticky footer
                        this.relocateFooter();

                        if (raceBetsJS.application.globals.isB2B) {
                            $.subscribe('/scroll', $.proxy(this.relocateFooter, this));
                            $.subscribe('/sizes', $.proxy(this.relocateFooter, this));
                        }

                        $(window).on('scroll.betslip', $.proxy(this.relocateFooter, this));

                        df.resolve(true);
                    }, this));
                } else {

                    // accumulation/pick betslip
                    this.showCheckboxes();

                    var options = {}
                    if (betslipType == 'pick') {
                        options = {
                            pick: {
                                country: this.card.data.event.country,
                                idRace: this.card.data.race.idRace,
                                types: this.card.data.betTypes.pick
                            }
                        };
                    }

                    raceBetsJS.application.assets.accBetslip.init(options);
                    df.resolve(true);
                }
            }, this));

            return df.promise();
        },

        /**
        * relocateFooter
        *
        * Providing the sticky footer functionality
        */
        relocateFooter: function() {
            if(raceBetsJS.browser.type.isiPad() || raceBetsJS.browser.type.isiPhone()) {
                this.dom.footerContainer.removeClass('sticky');
                return;
            }

            if (raceBetsJS.application.globals.isB2B) {
                var parentFrameScrollPosition = raceBetsJS.application.globals.iframe.scroll,
                tempParentFrameHeight = raceBetsJS.application.globals.iframe.height,
                topIframePosition = raceBetsJS.application.globals.iframe.top,
                elementHeight,
                elementPosition = window.document.getElementById('card-betslip-footer').getBoundingClientRect();

                elementHeight = elementPosition.bottom - elementPosition.top;



                if (elementPosition.bottom - tempParentFrameHeight > parentFrameScrollPosition - topIframePosition) {
                    this.dom.footerContainer.addClass('sticky');
                    this.dom.footerContainer.css({ top: parentFrameScrollPosition - topIframePosition + tempParentFrameHeight - elementHeight });
                    this.dom.footerContainer.css({ width: $('#content-main').width() });
                    this.dom.footerContainer.css({ left: document.getElementById('content-main').getBoundingClientRect().left });

                } else {
                    this.dom.footerContainer.removeClass('sticky');
                    this.dom.footerContainer.width('auto');
                }
            } else {
                if (!raceBetsJS.browser.viewport.isElementVisible('card-betslip-footer', window, { ignoreNegativeTop: true })) {
                    this.dom.footerContainer.addClass('sticky');

                    this.dom.footerContainer.css({ width: $('#content-main').width() });
                    this.dom.footerContainer.css({ left: document.getElementById('content-main').getBoundingClientRect().left });

                    // this.dom.footerContainer.width( $('.racecard .content').width() ); // this was in racebets instead of those two lines above
                } else {
                    this.dom.footerContainer.removeClass('sticky');
                    this.dom.footerContainer.removeAttr('style');
                }
            }
        },

        /**
        * setIdFreeBet
        * Set idFreebet in betslip
        */
        setIdFreeBet: function(idFreebet) {
            this.bet.idFreebet = idFreebet;
        },

        /**
        * setExclusiveMark
        *
        * set exclusive mark in betslip, prevents user from changing mark
        */
        setExclusiveMark: function(col, idRunner) {
            // first mark if not marked
            if (!this.isMarked('c', idRunner)) {
                this.toggleMark(col, idRunner);
            }

            // then lock
            this.isExclusiveMark = true;
        },

        /**
        * isOpen
        *
        * Return if betslip is opened and which one
        */
        isOpen: function() {
            return this.betslipType ? this.betslipType : false;
        },

        /**
        * hideCheckboxes
        *
        * Hide the current checkboxes
        */
        hideCheckboxes: function() {
            this.card.runners.dom.table.removeClass('col-checkboxes');
            this.card.runners.setColVisibility();
        },

        /**
        * showCheckboxes
        *
        * Show the current or a new selection of checkboxes
        */
        showCheckboxes: function() {
            // not for ante-post accumulators
            if (this.betslipType === 'acc' && this.card.data.event.isAntePost === true) {
                return;
            }

            // which columns need to be visible
            var cols = [];
            switch (this.bet.betType) {
                case 'WIN':
                case 'PLC':
                case 'SHW':
                case 'WP':
                case 'WS':
                case 'PS':
                case 'WPS':
                    cols = [1];
                    break;

                case 'ITA':
                    cols = [2];
                    break;

                case 'TRT':
                    cols = [3];
                    break;

                case 'QNL':
                case 'SWG':
                case 'TOF':
                case 'M4':
                case 'M5':
                case 'M6':
                case 'M7':
                case 'PK5':
                    cols = [1, 'c'];
                    break;

                case 'EXA':
                case 'TRO':
                    cols = [1, 2, 'c'];
                    break;

                case 'TRI':
                case 'TRC':
                    cols = [1, 2, 3, 'c'];
                    break;

                case 'SFC':
                case 'QRP':
                case 'SF4':
                    cols = [1, 2, 3, 4, 'c'];
                    break;
                case 'QNP':
                    cols = [1, 2, 3, 4, 5, 'c'];
                    break;
            }

            // in case that there was no active market and race has 'pick' option on
            if (cols.length === 0 && this.betslipType === 'pick') {
                cols = [1];
            }

            // remove all marks which are not valid anymore
            if (this.checkboxColsVisible) {
                // which columns are visible, but will be hidden now
                var arr = $.grep(this.checkboxColsVisible, function(col) {
                    return $.inArray(col, cols) == -1;
                });

                // loop through the marks and remove them
                $.each(arr, $.proxy(function(key, col) {
                    $.each($.extend([], this.bet.marks[col]), $.proxy(function(key, idRunner) {
                        this.toggleMark(col, idRunner, true);
                    }, this));
                }, this));
            }

            this.checkboxColsVisible = cols;

            if (cols && cols.length) {
                $.each(this.checkboxCols, $.proxy(function(key, col) {
                    if ($.inArray(col, cols) > -1) {
                        this.card.runners.dom.table.addClass('cb-col-' + col);
                    } else {
                        this.card.runners.dom.table.removeClass('cb-col-' + col);
                    }
                }, this));


                // adjust ckb col width +  ie7 fix
                var width = (($.browser.msie && parseInt($.browser.version, 10) == 7) ? 40 : 26) * cols.length;
                this.card.runners.dom.table.find('.checkboxes').css('width', (width > 72) ? width : 72 + 'px');
                //this.card.runners.dom.col.checkboxes.width((width < 72) ? 72 : width);
            }

            this.card.runners.dom.table.addClass('col-checkboxes');
            this.card.runners.setColVisibility(true);
        },

        /**
        * showTag
        *
        * Show tag next to bet info, e.g. "free bet"
        */
        showTag: function(tag, label) {
            // remove previous tag
            this.hideTag();

            // add new one
            this.dom.footerContainer.find('.bet-info').prepend(
                $('<div>').addClass('extra').append(
                    $('<p>').addClass('c-tag ' + tag).text(label)
                )
            );
        },

        /**
        * hideTag
        *
        * Hides/removes the tag from the betslip
        */
        hideTag: function() {
            this.dom.footerContainer.find('.bet-info .extra').remove();
        },

        /**
        * Universal method for 2 columns
        * @minimumMarks - the number of minimum marked runners
        * @marks - Object of columns with marked runners as Arrays
        */
        outOfFirstNth: function (minimumMarks, marks) {
            var columnsArr = _.uniq(_.flatten(marks));
            var result = {
                error: false,
                numBets: 0,
            };

            if(marks[1].length + marks['c'].length < minimumMarks) {
                // Not enough marks - Zero bets
                result.numBets = 0;
            } else if(marks[1].length >= minimumMarks && marks['c'].length > 0) {
                // To many bankers (minimum amount or more) with combinations - error
                result.error = raceBetsJS.i18n.print('maxNBank', { num: minimumMarks - 1 });
            } else if (marks[1].length === 0 || marks['c'].length === 0) {
                // Only one column marked Bankers or Combination - this case both column behave the same
                result.numBets = Combinatorics.combination(columnsArr, minimumMarks).length;
            } else {
                // Combination of Bankers and Combinations
                result.numBets = Combinatorics.combination(columnsArr, minimumMarks).filter(function(permut) {
                    return marks[1].every(function(i) {
                        return permut.indexOf(i) > -1;
                    });
                }).length;
            }
            return result;
        },

        /**
        * Method for 3 or more columns
        * @numOfColumns - number of column
        * @marks - Object of columns with marked runners as Arrays
        */
        countCombinations: function(numOfColumns, marks) {
            var bankCount = marks[1].length + marks[2].length + marks[3].length + marks[4].length + marks[5].length;
            var combinationsCount = marks['c'].length;
            var bankers = {};
            var result = {
                    error: false,
                    numBets: 0,
                };

            if (bankCount + combinationsCount < numOfColumns) {
                // Not enough marks - Zero bets
                result.numBets = 0;
            } else if (!bankCount && combinationsCount) {
                // Only one Combination column
                result.numBets = Combinatorics.permutation(marks['c'], numOfColumns).length;
            } else if (bankCount && combinationsCount) {
                // Combination column with some bankers
                var i = 1;
                var arrOfAllChoosenRidersIds = _.uniq(_.flatten(marks));

                if (bankCount > 4) {
                    result.error = raceBetsJS.i18n.print('maxNBank', { num: numOfColumns - 1 });
                    return result;
                }

                var allPossibleBet = Combinatorics.permutation(arrOfAllChoosenRidersIds, numOfColumns).filter(function (permut) {
                    var result = true;

                    for (var k = 1; numOfColumns >= k; k++) {
                        if (marks[k].length && marks[k].indexOf(permut[k - 1]) === -1 ) {
                            result = false;
                        }
                    }
                    return result;
                });

                result.numBets = allPossibleBet.length;

            } else {
                result.numBets = 1;
            }
            return result;
        },

        /**
        * evalBet
        *
        * Evaluate the betslip
        */
        evalBet: function() {
            var numBets = 0,
                numColC = this.bet.marks['c'].length,
                bank1 = 0,
                bank2 = 0,
                bank3 = 0,
                bankH1 = 0,
                bankH2 = 0,
                bankH3 = 0,
                numBank = 0,
                bitPos = [0, 1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048];

            // marks cleaned from stable horses
            var marks = this.getCleanMarks();

            // reset vars
            this.bet.numBets = 0;
            this.error = null;

            /**
            * Win, Place, Show, Ita, Trita, Win/Place, Win/Show, Place/Show, Win/Place/Show
            */
            if ($.inArray(this.bet.betType, ['WIN', 'PLC', 'SHW', 'ITA', 'TRT', 'WP', 'WS', 'PS', 'WPS']) > -1) {
                var col = (this.bet.betType == 'ITA') ? 2 : ((this.bet.betType == 'TRT') ? 3 : 1);
                var num = (this.bet.betType == 'WPS') ? 3 : (($.inArray(this.bet.betType, ['WP', 'WS', 'PS']) > -1) ? 2 : 1);
                this.bet.numBets = num * marks[col].length;

            /**
            * Exacta
            */
            } else if (this.bet.betType == 'EXA') {
                // without combination column
                if (!numColC) {
                    $.each(marks[1], $.proxy(function(index, idRunner) {
                        this.bet.numBets += marks[2].length;

                        if ($.inArray(idRunner, marks[2]) > -1) {
                            this.bet.numBets--;
                        }
                    }, this));

                // with combination column
                } else {
                    if (marks[1].length > 1 || marks[2].length > 1) {
                        this.error = raceBetsJS.i18n.data.maxOneBankPerCol;
                        return;
                    }

                    bank1 = (marks[1].length ? marks[1][0] : null);
                    bank2 = (marks[2].length ? marks[2][0] : null);

                    if (bank1 && bank2 && bank1 != bank2) {
                        this.error = raceBetsJS.i18n.data.maxOneBank;
                        return;
                    }

                    if (bank1 || bank2) {
                        // two bank horses (same horse) or one bank horse
                        numBank = (bank1 && bank2) ? 2 : 1;
                        this.bet.numBets = marks['c'].length * numBank;
                    } else {
                        // just combination column
                        this.bet.numBets = marks['c'].length * (marks['c'].length - 1);
                    }
                }

            /**
            * Quinella, Swinger
            */
            } else if ($.inArray(this.bet.betType, ['QNL', 'SWG']) > -1) {
                if (marks[1].length > 1) {
                    this.error = raceBetsJS.i18n.data.maxOneBankPerCol;
                    return;
                }

                bank1 = (marks[1].length ? marks[1][0] : null);

                if (bank1) {
                    // with bank horse
                    this.bet.numBets = marks['c'].length;
                } else {
                    // just combination column
                    // with 5 runners it is for example 1 + 2 + 3 + 4 = 10
                    this.bet.numBets = ((marks['c'].length - 1) * marks['c'].length) / 2;
                }

            /**
            * Trifecta
            */
            } else if ($.inArray(this.bet.betType, ['TRI', 'TRC']) > -1) {
                if (!numColC) {
                    // no combination column used
                    $.each(marks[1], $.proxy(function(index1, idRunner1) {
                        $.each(marks[2], $.proxy(function(index2, idRunner2) {
                            if (idRunner2 == idRunner1) {
                                return;
                            }

                            $.each(marks[3], $.proxy(function(index3, idRunner3) {
                                if (idRunner3 == idRunner1 || idRunner3 == idRunner2) {
                                    return;
                                }

                                this.bet.numBets++;
                            }, this));
                        },this));
                    }, this));
                } else {
                    for (var i = 1; i <= 3; i++) {
                        $.each(marks[i], $.proxy(function(index, idRunner) {
                            var pn = this.card.data.runners.data[idRunner].programNumber;

                            if (!bank1 || bank1 == pn) {
                                bank1 = pn;
                                bankH1 |= pn | bitPos[8 + i];
                            } else if (!bank2 || bank2 == pn) {
                                bank2 = pn;
                                bankH2 |= pn | bitPos[8 + i];
                            } else {
                                this.error = raceBetsJS.i18n.print('maxNBank', { num: 2 });
                                return false;
                            }
                        }, this));

                        if (this.error) {
                            return;
                        }
                    }

                    if (bank1 && bank2) {
                        // two bank horses
                        for (var j = 1; j <= 3; j++) {
                            if ((bankH1 & bitPos[8 + j]) == 0) {
                                continue;
                            }

                            for (var k = 1; k <= 3; k++) {
                                if (k == j || (bankH2 & bitPos[8 + k]) == 0) {
                                    continue;
                                }

                                $.each(marks['c'], $.proxy(function(index, idRunner) {
                                    if (idRunner != bank1 && idRunner != bank2) {
                                        this.bet.numBets++;
                                    }
                                }, this));
                            }
                        }
                    } else if (bank1) {
                        // one bank horse
                        if (numColC >= 2) {
                            for (var k = 1; k <= 3; k++) {
                                if ((bankH1 & bitPos[8 + k]) == 0) {
                                    continue;
                                }

                                $.each(marks['c'], $.proxy(function(index1, idRunner1) {
                                    if (idRunner1 == bank1) {
                                        return;
                                    }

                                    $.each(marks['c'], $.proxy(function(index2, idRunner2) {
                                        if (idRunner2 != idRunner1 && idRunner2 != bank1) {
                                            this.bet.numBets++;
                                        }
                                    }, this));
                                }, this));
                            }
                        }
                    } else {
                        // just combination column
                        this.bet.numBets = marks['c'].length * (marks['c'].length - 1) * (marks['c'].length - 2);
                    }
                }

            /**
            * Trio
            */
            } else if (this.bet.betType == 'TRO') {
                if (marks[1].length > 1 || marks[2].length > 1) {
                    this.error = raceBetsJS.i18n.data.maxOneBankPerCol;
                    return;
                }

                bank1 = (marks[1].length ? marks[1][0] : null);
                bank2 = (marks[2].length ? marks[2][0] : null);

                if (bank1 && bank1 == bank2) {
                    this.error = raceBetsJS.i18n.data.sameBank;
                    return;
                }

                if (bank1 && bank2) {
                    // two bank horses
                    this.bet.numBets = marks['c'].length;

                } else if (bank1 || bank2) {
                    // one bank horse
                    // with 5 runners in the combi column it is for example 1 + 2 + 3 + 4 = 10
                    this.bet.numBets = ((marks['c'].length - 1) * marks['c'].length) / 2;
                } else if (numColC >= 3) {
                    // just combination column (binominal coefficient)
                    this.bet.numBets = 1;

                    for (var i = numColC - 3 + 1; i <= numColC; i++) {
                        this.bet.numBets *= i;
                    }

                    for (var i = 1; i <= 3; i++) {
                        this.bet.numBets /= i;
                    }
                }

            /**
            * Superfecta
            */
            } else if ($.inArray(this.bet.betType, ['SFC', 'QRP', 'SF4']) > -1) {
                if (!numColC) {
                    // no combination column used
                    $.each(marks[1], $.proxy(function(index1, idRunner1) {
                        $.each(marks[2], $.proxy(function(index2, idRunner2) {
                            if (idRunner2 == idRunner1) {
                                return;
                            }

                            $.each(marks[3], $.proxy(function(index3, idRunner3) {
                                if (idRunner3 == idRunner1 || idRunner3 == idRunner2) {
                                    return;
                                }

                                $.each(marks[4], $.proxy(function(index4, idRunner4) {
                                    if (idRunner4 == idRunner1 || idRunner4 == idRunner2 || idRunner4 == idRunner3) {
                                        return;
                                    }

                                    this.bet.numBets++;
                                }, this));
                            }, this));
                        },this));
                    }, this));
                } else {
                    // combination column used
                    for (var i = 1; i <= 4; i++) {
                        $.each(marks[i], $.proxy(function(index, idRunner) {
                            var pn = this.card.data.runners.data[idRunner].programNumber;

                            if (!bank1 || bank1 == pn) {
                                bank1 = pn;
                                bankH1 |= pn | bitPos[8 + i];
                            } else if (!bank2 || bank2 == pn) {
                                bank2 = pn;
                                bankH2 |= pn | bitPos[8 + i];
                            } else if (!bank3 || bank3 == pn) {
                                bank3 = pn;
                                bankH3 |= pn | bitPos[8 + i];
                            } else {
                                this.error = raceBetsJS.i18n.print('maxNBank', { num: 3 });
                                return false;
                            }
                        }, this));

                        if (this.error) {
                            return;
                        }
                    }

                    if (bank1 && bank2 && bank3) {
                        // three bank horses
                        for (var j = 1; j <= 4; j++) {
                            if ((bankH1 & bitPos[8 + j]) == 0) {
                                continue;
                            }

                            for (var k = 1; k <= 4; k++) {
                                if (k == j || (bankH2 & bitPos[8 + k]) == 0) {
                                    continue;
                                }

                                for (var l = 1; l <= 4; l++) {
                                    if (l == j || l == k || (bankH3 & bitPos[8 + l]) == 0) {
                                        continue;
                                    }

                                    $.each(marks['c'], $.proxy(function(index, idRunner) {
                                        if (idRunner != bank1 && idRunner != bank2 && idRunner != bank3) {
                                            this.bet.numBets++;
                                        }
                                    }, this));
                                }
                            }
                        }
                    } else if (bank1 && bank2) {
                        // two bank horses
                        if (numColC >= 2) {
                            // first horse place 1-2 second horse place 3-4 + combi === invalid
                            if(!(
                                this.bet.betType === 'SFC' &&
                                marks[1][0] === marks[2][0] && marks[3][0] === marks[4][0] &&
                                _.flatten([marks[1], marks[2], marks[3], marks[4]]).length === 4)
                            ) {
                                for (var j = 1; j <= 4; j++) {
                                    if ((bankH1 & bitPos[8 + j]) == 0) {
                                        continue;
                                    }

                                    for (var k = 1; k <= 4; k++) {
                                        if (k == j || (bankH2 & bitPos[8 + k]) == 0) {
                                            continue;
                                        }

                                        $.each(marks['c'], $.proxy(function(index1, idRunner1) {
                                            if (idRunner1 == bank1 || idRunner1 == bank2) {
                                                return;
                                            }

                                            $.each(marks['c'], $.proxy(function(index2, idRunner2) {
                                                if (idRunner2 != bank1 && idRunner2 != bank2 && idRunner2 != idRunner1) {
                                                    this.bet.numBets++;
                                                }
                                            }, this));
                                        }, this));
                                    }
                                }
                            }
                        }
                    } else if (bank1) {
                        // one bank horse
                        if (numColC >= 3) {
                            for (var k = 1; k <= 4; k++) {
                                if ((bankH1 & bitPos[8 + k]) == 0) {
                                    continue;
                                }

                                $.each(marks['c'], $.proxy(function(index1, idRunner1) {
                                    if (idRunner1 == bank1) {
                                        return;
                                    }

                                    $.each(marks['c'], $.proxy(function(index2, idRunner2) {
                                        if (idRunner2 == bank1 || idRunner2 == idRunner1) {
                                            return;
                                        }

                                        $.each(marks['c'], $.proxy(function(index3, idRunner3) {
                                            if (idRunner3 != bank1 && idRunner3 != idRunner1 && idRunner3 != idRunner2) {
                                                this.bet.numBets++;
                                            }
                                        }, this));
                                    }, this));
                                }, this));
                            }
                        }
                    } else {
                        // just combination column
                        this.bet.numBets = marks['c'].length * (marks['c'].length - 1) * (marks['c'].length - 2) * (marks['c'].length - 3);
                    }
                }
            } else if (this.bet.betType == 'TOF') {
                // The same as QNL and SWG but if only 1st column is marked it works as second column when its only marked
                if (marks[1].length > 1) {
                    if (marks['c'].length === 0) {
                        this.bet.numBets = ((marks[1].length - 1) * marks[1].length) / 2;
                    } else {
                        this.error = raceBetsJS.i18n.data.maxOneBankPerCol;
                        return;
                    }

                } else if (marks[1].length === 1) {
                    // with bank horse
                    this.bet.numBets = marks['c'].length;
                } else {
                    // just combination column
                    // with 5 runners it is for example 1 + 2 + 3 + 4 = 10
                    this.bet.numBets = ((marks['c'].length - 1) * marks['c'].length) / 2;
                }
            } else if ($.inArray(this.bet.betType, ['M4', 'M5', 'M6', 'M7', 'PK5']) > -1) {
                var lastCharacterOfBetType = this.bet.betType.substr(-1);
                var minAmoutOfMarks = parseInt(lastCharacterOfBetType, 10)
                var result = this.outOfFirstNth(minAmoutOfMarks, marks);

                this.error = result.error;
                this.bet.numBets = result.numBets;
                if (this.error) return;
            } else if (this.bet.betType === 'QNP') {
                var result =  this.countCombinations(5, marks);
                this.error = result.error;
                this.bet.numBets = result.numBets;
                if (this.error) return;
            }

            // check if bet is valid
            if (!this.bet.numBets) {
                this.error = raceBetsJS.i18n.data.betInvalid;
                return;
            }

            // get min stakes
            var minUnitStake = (this.bet.betCategory == 'TOT')
                ? this.card.data.betTypes.normal[this.bet.betCategory][this.bet.betType][0]
                : raceBetsJS.format.getMinStakeBOKFXD(raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency], this.bet.betCategory, this.bet.betType);
            var minTotalStake = this.card.data.betTypes.normal[this.bet.betCategory][this.bet.betType][1];

            // adjust different min stake for scandinavia
            if (this.bet.betCategory === 'BOK' && this.bet.betType === 'TRI' && $.inArray(this.card.data.event.country, ['DK', 'FI', 'NO', 'SE']) > -1) {
                minUnitStake = raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency].minStakeBOKTRISE;
            }

            // min step size
            var minToteStepSize = raceBetsJS.betting.getToteStepSize(this.card.data.event.country);

            // min unit stake
            if (!this.bet.unitStake || this.bet.unitStake < minUnitStake) {
                this.error = raceBetsJS.i18n.print('errorMinStake', { amount: raceBetsJS.format.money(minUnitStake, 2, this.bet.currency, true) });
                return;
            }

            // min total stake for some tote bets
            if (this.bet.unitStake * this.bet.numBets < minTotalStake) {
                this.error = raceBetsJS.i18n.print('errorMinTotalStake', { amount: raceBetsJS.format.money(minTotalStake, 2, this.bet.currency, true) });
                return;
            }

            if (this.bet.betCategory != 'TOT') {
                // max stake for bookie bets
                var maxUnitStake = raceBetsJS.format.getMaxStakeBOKFXD(raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency], this.bet.betCategory, this.bet.betType);
                if (this.bet.unitStake > maxUnitStake) {
                    this.error = raceBetsJS.i18n.print('errorMaxStake', {amount: raceBetsJS.format.money(maxUnitStake, 2, this.bet.currency, true)});
                    return;
                }
            }

            // step size for US tote bets; unit stake under 1 USD has to be a multiplication of min stake
            if (this.bet.betCategory === 'TOT' &&
                    $.inArray(this.card.data.event.country, ['US']) > -1 &&
                    this.bet.unitStake < 1 &&
                    (this.bet.unitStake * 100) % (minUnitStake * 100) !== 0) {
                this.error = raceBetsJS.i18n.print('stepSizeUnitStakeInvalid', { amount: raceBetsJS.format.money(minUnitStake, 2, this.bet.currency, true) });
                return;
            }

            // step size for tote bets
            if (this.bet.betCategory === 'TOT' && this.betTypes.FRTOT.indexOf(this.bet.betType) === -1 &&
                    math.eval(
                        math.format(this.bet.unitStake * 100, {precision: 16}) % math.format(minToteStepSize * 100, {precision: 16})
                    ) !== 0) {
                this.error = raceBetsJS.i18n.print('stepSizeInvalid', { amount: raceBetsJS.format.money(minToteStepSize, 2, this.bet.currency, true) });
                return;
            }

            // step size for tote PMU bets
            if (this.bet.betCategory === 'TOT' && this.betTypes.FRTOT.indexOf(this.bet.betType) > -1) {
                // Stake has to divisible by minUnitStake or by 1 when unit stake is greater then minTotalStake
                var factor = 100;
                var tUnitStake = this.bet.unitStake * factor;
                var tMinUnitStake = minUnitStake * factor;
                var isStakeDivisibleByMinUnitStake = tUnitStake % tMinUnitStake === 0;

                if(!isStakeDivisibleByMinUnitStake && !(this.bet.unitStake >= minTotalStake && tUnitStake % factor === 0)) {
                    if (minUnitStake <= 1 && factor % tMinUnitStake === 0) {
                        //use simpler error message if minUnitStake for this betType is already divisible by 1 (eg. 0.5/0.25)
                        this.error = raceBetsJS.i18n.print('stepSizeInvalid', {
                            amount: raceBetsJS.format.money(minUnitStake, 2, this.bet.currency, true)
                        });
                    } else {
                        this.error = raceBetsJS.i18n.print('stepSizeInvalidPMU', {
                            amount: raceBetsJS.format.money(minUnitStake, 2, this.bet.currency, true),
                            minTotalStake: raceBetsJS.format.money(minTotalStake, 2, this.bet.currency, true)
                        });
                    }
                    return;
                }
            }

        },

        /**
        * updateStatus
        *
        * Updates the status of the betslip, the footer (error message), status of the submit button etc.
        */
        updateStatus: function() {
            // eval the bet
            this.evalBet();

            if (this.error) {
                // bet is invalid
                this.dom.betStatus.removeClass('valid');
                this.dom.diffCurrency.html('');
                var numMarks = 0;
                _.each(this.bet.marks, function(col) { if (col.length > 0) numMarks++ });
                if (numMarks !== 0) {
                    this.dom.betStatus.html(this.error);
                } else {
                    this.dom.betStatus.html('');
                }
                this.dom.totalStake.html('');
                this.dom.numBets.html('');

                if(!raceBetsJS.application.globals.isB2B) {
                    this.dom.bonusMoneyInfo.hide();
                }

                this.dom.submitButton.attr('disabled', true).removeClass('c-btn--primary').addClass('c-btn--default');
            } else {
                // bet is valid
                var totalStake = Math.round(this.bet.numBets * this.bet.unitStake * 100) / 100;

                if (this.bet.currency != raceBetsJS.application.user.currency) {
                    var totalStakeToteCurrency = totalStake;
                    totalStake = raceBetsJS.localize.exchange(totalStake, this.bet.currency, raceBetsJS.application.user.currency);
                }

                // get tax percentage and total costs, but not for free bets. lucky them.
                var taxPercentage = this.card.data.race.taxFees[this.bet.betCategory];
                var totalCost = raceBetsJS.betting.calculateTax(totalStake, taxPercentage, this.bet.betCategory, (this.betslipType === 'free-bet'));

                var totalStakeHtml;
                if (this.bet.currency != raceBetsJS.application.user.currency) {
                    totalStakeHtml = '<b>' + raceBetsJS.format.money(totalStakeToteCurrency * (totalCost.taxFeeRate + 100) / 100, 2, this.bet.currency, true) + '</b> <span class="light-grey">(' + raceBetsJS.format.money(totalCost.totalCost, 2, raceBetsJS.application.user.currency, true) + ')</span>';

                    if(['suaposta', 'europebet'].indexOf(raceBetsJS.application.globals.brandName) > -1) {
                        this.dom.diffCurrency.html(
                            raceBetsJS.i18n.print('msgDiffCurrencyInfo', {
                                originalCurrency: this.bet.currency,
                                chargedAmount: raceBetsJS.format.number(totalCost.totalCost, 2) + ' ' + raceBetsJS.application.user.currency
                            })
                        );
                    }
                } else {
                    totalStakeHtml = '<b>' + raceBetsJS.format.money(totalCost.totalCost, 2, this.bet.currency, true) + '</b>';
                }

                if (totalCost.taxDue > 0) {
                    var labelTax;

                    if ( this.bet.betCategory === 'TOT' ) {
                        labelTax = raceBetsJS.i18n.data.labelForwardingFee;
                    } else {
                        labelTax = raceBetsJS.i18n.data.labelTaxRWLG;
                    }

                    totalStakeHtml += ' <span class="tax-info">&nbsp;</span>';

                    // total stake tooltip
                    Tipped.create(this.dom.totalStake, raceBetsJS.application.templates.betTaxTooltip({
                        totalStake: totalStake,
                        labelTax: labelTax,
                        taxDue: totalCost.taxDue,
                        taxSubvention: totalCost.taxSubvention,
                        taxSubventionRate: totalCost.taxSubventionRate
                    }), {
                        hook: 'topright',
                        offset: { x: -6, y: -8 },
                        skin: 'racebets betting-tax',
                        maxWidth: 300
                    });
                } else {
                    Tipped.remove(this.dom.totalStake);
                }

                if (this.bet.currency !== 'EUR') {
                    var totalStakeEUR = Math.round(this.bet.numBets * this.bet.unitStake * 100) / 100;
                    this.bet.totalStakeEUR = raceBetsJS.localize.exchange(totalStakeEUR, this.bet.currency, 'EUR');
                } else {
                    this.bet.totalStakeEUR = totalStake;
                }

                this.bet.taxFee = totalCost.taxFee; // required for confirmation dialog

                this.dom.betStatus.addClass('valid').html(raceBetsJS.i18n.data.betValid);
                this.dom.totalStake.html(raceBetsJS.i18n.print(totalCost.taxDue ? 'labelTotalCost' : 'labelTotalStake', {
                    amount: totalStakeHtml
                }));
                this.dom.numBets.html(raceBetsJS.i18n.print('labelNumBets', { numBets: '<b>' + this.bet.numBets + '</b>' }));

                this.dom.submitButton.attr('disabled', false).removeClass('c-btn--default').addClass('c-btn--primary');

                if (!raceBetsJS.application.globals.isB2B) {
                    raceBetsJS.application.assets.bonusMoney.show(this.dom.bonusMoneyInfo, totalStake);
                }


            }
        },

        /**
        * This comment is a little self explanatory now, isn't it?
        */
        submit: function() {
            // return if bet is not valid
            if (this.error) {
                return false;
            }

            this.dom.submitButton.attr('disabled', true).removeClass('c-btn--primary').addClass('c-btn--default');

            // {"type":"error","errorCode":307,"idRms":2367,"runnersSp":"883542,883557"}

            // track event
            dataLayer.push({
                'BetCategory': this.bet.betCategory,
                'BetType': this.bet.betType,
                'event': 'PlaceBetClick'
            });

            // create Bet instance and send it
            var bet = new raceBetsJS.Bet($.extend({}, this.bet, { marks: this.getCleanMarks(true) }), { eventCountry: this.card.data.event.country });
            $.when(bet.submit())
                .done($.proxy(function() {

                    // trigger sidewide event with bet info
                    $.publish('/bet/placed', [this.bet]);

                    this.reset();
                }, this))
                .fail($.proxy(function() {
                    this.dom.submitButton.attr('disabled', false).removeClass('c-btn--default').addClass('c-btn--primary');
                }, this));
        }
    });
})(raceBetsJS);
