/**
* @description racecard template
*
*/
(function(raceBetsJS) {
    /**
     * Racecard template
     */
    raceBetsJS.application.templates.race = _.template(
        '<%= raceBetsJS.application.templates.event.banner({ banner: banner.racecard }) %>'+
        '<div class="racecard country-<%= event.country.toLowerCase() %> race-type-<%= race.raceType.toLowerCase() %> status-<%= race.raceStatus %> <%= (event.isAntePost) ? "ante-post" : "" %> <%= (race.jackpot.length > 0) ? "has-jackpot" : "" %> <%= (event.spEvent) ? "starting-price" : "" %> odds-<%= raceBetsJS.application.assets.settings.get().dialog.general.oddsFormat %>">' +
            '<%= raceBetsJS.application.templates.race.header({ event: event, siblingRaces: siblingRaces, race: race }) %>' +
            '<div class="content">' +
                '<div class="top">' +
                    '<div class="racedetails" id="race-details">' +
                        '<div class="time <%= (event.isVirtual ? "is-virtual" : "") %>">' +
                            '<span class="<% if(!event.isVirtual) { %>race-status-elem<% } %> ordinal status-<%= race.raceStatus %>" id="race-status"><%= race.raceNumber %>' +
                                '<% if (race.raceStatus == "OPN" && !event.isAntePost && event.autoStart) { %>' +
                                    '<span class="auto-start" title="<%= raceBetsJS.i18n.data.autostartNotice %>"></span>' +
                                '<% } %>' +
                                '<% if (race.is_priced_up) { %>' +
                                    '<span class="c-tag c-tag--s isPricedUp" title="<%= raceBetsJS.i18n.data.betslipLabelFXD %>">' +
                                        '<%= raceBetsJS.i18n.data.labelPricedUpAbbr %>' +
                                    '</span>' +
                                '<% } %>' +
                            '</span>' +
                            '<span class="post-time status-<%= race.raceStatus %>" id="post-time">' +
                                '<%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, race.postTime) %>' +
                            '</span>' +
                        '</div>' +
                        '<div class="details">' +
                            '<div class="right">' +
                                '<div class="race-jackpot-btn"></div>' +
                            '</div>' +
                            '<span class="row title">' +
                                '<strong><%= raceBetsJS.i18n.getRaceTitle(race.raceTitle, race.raceNumber) %></strong>' +
                                '<% if(race.category != "") { %>' +
                                    '<% if(race.raceType == "D") { %>' +
                                        '<%= "- " + race.category %>' +
                                    '<% } else { %>' +
                                        '<%= " - " + raceBetsJS.i18n.data["raceCategory" + race.category] %>' +
                                    '<% } %>' +
                                '<% } %>' +
                                '<% if (race.categoryLetter !== "") { %>' +
                                    '<span class="category-letter">' +
                                        '<% if(event.country == "FR") { %>' +
                                            ' (<%= raceBetsJS.i18n.print("raceCategoryLetterFrance", {letter: race.categoryLetter}) %>)' +
                                        '<% } else if($.inArray(event.country, ["GB", "IE", "AE"]) > -1) { %>' +
                                            ' (Class <%= race.categoryLetter %>)' +
                                       ' <% } else { %>' +
                                            ' (<%= race.categoryLetter %>)' +
                                        '<% } %>' +
                                    '</span>' +
                                '<% } %>' +
                                '<span class="race-status-btn"></span>' +
                            '</span>' +
                            '<span class="row first">' +
                                '<% if (race.raceType != "D" && (race.ageFrom || race.ageTo || race.conditions)) { %>' +
                                    '<%= raceBetsJS.format.ageCondition(race.ageFrom, race.ageTo) %>' +
                                    '<% if (race.conditions) { %>&nbsp;|&nbsp; <span class="tipped conditions" title="<%- $.trim(race.conditions + " " + race.conditionsDetails) %>">&nbsp;</span><% } %>' +
                                    '<% if (race.distance || race.purse) { %>' +
                                        '&nbsp;|&nbsp;' +
                                    '<% } %>' +
                                '<% } %>' +
                                '<% if (race.distance) { %>' +
                                    '<%= raceBetsJS.i18n.data.labelDistance %>: ' +
                                    '<%= raceBetsJS.format.distance(race.distance, (race.raceType !== "D") ? raceBetsJS.application.assets.settings.get().dialog.general.unitSystem : "metric") %>' +
                                    '<% if(race.startType) { %>' +
                                        '&nbsp;|&nbsp; <%= raceBetsJS.i18n.data["startType" + race.startType] %>' +
                                    '<% } %>' +
                                    '<% if (race.purse) { %>' +
                                        '&nbsp;|&nbsp;' +
                                    '<% } %>' +
                                '<% } %>' +
                                '<% if (race.purse) { %>' +
                                    '<nobr><%= raceBetsJS.i18n.data.labelPurse %>: <span <% if(race.purseDetails) { %>class="tipped" title="<%= race.purseDetails %>"<% } %>><%= raceBetsJS.format.number(race.purse) + " " + race.purseCurrency %></span></nobr>' +
                                '<% } %>' +
                            '</span>' +
                            '<span class="row second">' +
                                '<% if (race.raceTypeDetail && race.raceTypeDetail !== "GRH") { %>' +
                                    '<%= raceBetsJS.i18n.data["raceTypeDetail" + race.raceTypeDetail] %>' +
                                    '<% if (race.trackSurface || race.placesNum !== undefined) { %>' +
                                        '&nbsp;|&nbsp;' +
                                    '<% } %>' +
                                '<% } %>' +
                                '<% if (race.trackSurface) { %>' +
                                    '<nobr>' +
                                        '<%= raceBetsJS.i18n.data.labelTrackSurface %>: <%= raceBetsJS.i18n.data["trackSurface" + race.trackSurface] %>' +
                                        '<% if (race.trackGoing) { %>' +
                                            '<% var trackSurface = race.trackSurface.charAt(0).toUpperCase() + race.trackSurface.slice(1).toLowerCase(); %>' +
                                            '&nbsp;|&nbsp; <%= raceBetsJS.i18n.data.labelTrackGoing%>:' +
                                            '<% if (race.trackSurface == "TRF" && race.trackGoing == 2.5) { %>' +
                                                '<%= raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.ceil(race.trackGoing)] %> <%= raceBetsJS.i18n.data.connectTo %> <%= raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.floor(race.trackGoing)] %>' +
                                            '<% } else if (race.trackSurface == "TRF" && race.trackGoing == 3.5 && event.country == "IE" && raceBetsJS.application.user.lang == "en") { %>' +
                                                'yielding' +
                                            '<% } else if ((race.trackGoing % 1) > 0) { %>' +
                                                '<%= raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.floor(race.trackGoing)] %> <%= raceBetsJS.i18n.data.connectTo %> <%= raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.ceil(race.trackGoing)] %>' +
                                            '<% } else { %>' +
                                                '<%= raceBetsJS.i18n.data["trackGoing" + trackSurface + Math.floor(race.trackGoing)] %>' +
                                            '<% } %>' +
                                        '<% } %>' +
                                    '</nobr>' +
                                '<% } %>' +
                                '<% /* INFO: The next condition is hardcoded to display 2 places for German Trot in Berlin-Mariendorf (idTrack 10) */ %>' +
                                '<% if (event.country == "DE" && race.raceType == "T" && event.idTrack == 10 && race.postTime > 1356994800) { %>' +
                                    '<span id="race-each-way-terms" class="each-way-terms" title="<%= (event.spEvent ? raceBetsJS.i18n.data.tipEachWayTerms : "") %>">' +
                                        '&nbsp;|&nbsp; <%= raceBetsJS.i18n.data.labelEachWayTerms %>: 2 <%= raceBetsJS.i18n.data.labelPlaces %>' +
                                    '</span>' +
                                '<% } else if (race.placesNum !== undefined) { %>' +
                                    '<span id="race-each-way-terms" class="each-way-terms<%= (event.spEvent ? " tipped" : "") %>" title="<%= (event.spEvent ? raceBetsJS.i18n.data.tipEachWayTerms : "") %>">' +
                                        '&nbsp;|&nbsp; <%= raceBetsJS.i18n.data.labelEachWayTerms %>: <%= race.placesNum %> <%= raceBetsJS.i18n.data.labelPlaces %> @ 1/<%= race.placeOddsFactor %> <%= raceBetsJS.i18n.data.labelOdds %>' +
                                    '</span>' +
                                '<% } %>' +
                            '</span>' +
                            '<% if (race.reserveOrder) { %>' +
                                '<span class="row"><%= raceBetsJS.i18n.data.labelReserveOrder %>: <%= race.reserveOrder %></span>' +
                            '<% } %>' +
                            '<span id="race-details-tags" class="row race-details-tags last">' +
                                '<% if (event.isVirtual) { %>'+
                                    '<span class="notify-tag virtual">'+
                                        '<%= raceBetsJS.i18n.data.labelVirtualRace %>'+
                                    '</span>'+
                                '<% } %>'+
                                '<% if (!event.isAntePost && (race.bestOddsGuaranteed === true || (raceBetsJS.application.user.bestOddsGuaranteed && ["GB", "IE", "ZA", "AE"].indexOf(event.country) > -1))) { %>' +
                                    '<span id="bog-tag" class="notify-tag bog" data-tipped="<%= raceBetsJS.i18n.data.hintBOG %>">' +
                                        '<%= raceBetsJS.i18n.data.labelBOG %>' +
                                    '</span>' +
                                '<% } %>' +
                                '<% if (race.isRule4 === true) { %>' +
                                    '<a id="rule4-tag" href="<%= raceBetsJS.application.assets.settings.getBrandSettings("supportURLs", "rule4") %>" class="rule4" rel="nofollow noopener" target="_blank">' +
                                        '<span class="notify-tag rule4">' +
                                            '<%= raceBetsJS.i18n.data.labelRule4Applies %>' +
                                        '</span>' +
                                    '</a>' +
                                    '<span id="rule4-deductions-content" style="display: none">' +
                                        '<%= raceBetsJS.application.content.race.getRule4Deductions() %>' +
                                    '</span>' +
                                '<% } %>' +
                                '<% if (race.promotions) { %>' +
                                    '<% $.each(race.promotions, function(index, promotion) { %>' +
                                        '<span class="notify-tag white has-icon promotion" data-label-tag="<%= index %>">' +
                                            '<span class="c-iconFont c-iconFont--info-circle"></span>' +
                                            '<span>' +
                                                '<% if (promotion.promotionType !== "custom") { %>' +
                                                    '<%= raceBetsJS.i18n.data[promotion.label] %>' +
                                                '<% } else { %>' +
                                                    '<%= promotion.label %>' +
                                                '<% } %>' +
                                            '</span>' +
                                        '</span>' +
                                    '<% }); %>' +
                                '<% } %>' +
                                '<% if (race.nonRunnerNoBet === true) { %>' +
                                    '<a id="nrnb-tag" href="<%= raceBetsJS.application.assets.settings.getBrandSettings("supportURLs", "nonRunnerNoBet") %>" class="nrnb" target="_blank">' +
                                        '<span class="notify-tag nrnb">' +
                                            '<%= raceBetsJS.i18n.data.labelNonRunnerNoBet %>' +
                                        '</span>' +
                                    '</a>' +
                                '<% } %>' +
                                '<% if (race.raceStatus !== "FNL" && race.raceStatus !== "CNC" && race.media !== undefined) { %>' +
                                    '<% if (race.media.idChannel === "3" || race.media.idChannel === "4" || race.media.idChannel === "12") { %>' +
                                        '<span class="notify-tag live-on"><span class="label"><%= raceBetsJS.i18n.data.labelLiveOn %></span> <span class="logo channel-id-<%= race.media.idChannel %>"></span></span>' +
                                    '<% } %>' +
                                '<% } %>' +
                            '</span>' +
                        '</div>' +
                        '<a class="favourite" href="#"></a>' +
                    '</div>' +
                    '<% if (raceBetsJS.application.globals.isPopup === false) { %>' +
                        '<ul class="buttons" id="race-buttons">' +
                            '<li class="betslip betting enabled" data-type="std"><%= raceBetsJS.i18n.data.linkBetslip %></li>' +
                            '<% if (event.multiplesBok || event.multiplesFxd) { %>' +
                                '<li class="betslip betting enabled" data-type="acc"><%= raceBetsJS.i18n.data.linkAccumulationBetslip %></li>' +
                            '<% } %>' +
                            '<% if (!$.isEmptyObject(betTypes.pick)) { %>' +
                                '<% var pickTypes = []; %>' +
                                '<% if (betTypes.pick.TOT) { %>' +
                                    '<% pickTypes = $.map(betTypes.pick.TOT, function(val, index) { return [index] }) %>' +
                                '<% } else if (betTypes.pick.BOK) { %>' +
                                    '<% pickTypes = $.map(betTypes.pick.BOK, function(val, index) { return [index] }) %>' +
                                '<% } %>' +
                                '<li class="betslip betting enabled" data-type="pick" data-bet-category="<%= (betTypes.pick.TOT) ? "TOT" : "BOK" %>">' +
                                    '<% if (pickTypes.length === 1) { %>' +
                                        '<%= raceBetsJS.format.betTypeName(pickTypes[0], "", raceBetsJS.application.user.lang, null, event.country) %>' +
                                    '<% } else { %>' +
                                        '<%= raceBetsJS.format.formatComplexPickName(pickTypes, event.country) %>' +
                                    '<% } %>' +
                                '</li>' +
                            '<% } %>' +
                            '<% if (race.idEventSpecialBets) { %><li class="special-bets betting enabled"><%= raceBetsJS.i18n.data.specialBets %></li><% } %>' +
                            '<li class="my-bets hidden"><%= raceBetsJS.i18n.data.linkMyBets %> <span class="em"></span></li>' +
                            '<% if (race.media !== undefined) { %><li class="media"><%= raceBetsJS.i18n.print("infoLiveStreamStart", {"time" : raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, race.media.startTime) }) %></li><% } %>' +
                        '</ul>' +
                    '<% } %>' +
                '</div>' +
                '<div id="customers-bets" class="customers-bets" style="display: none;"></div>' +
                '<% if (result.positions !== undefined) { %>' +
                    '<%= raceBetsJS.application.templates.race.result({ result: result, wps: (event.toteCurrency == "USD"), country: event.country, isAntePost: event.isAntePost, spEvent: event.spEvent, raceStatus: race.raceStatus, offTime: race.offTime, runners: runners, placesNum: race.placesNum, placeOddsFactor: race.placeOddsFactor, deduction: race.deduction, deadHeat: race.deadHeat }) %>' +
                '<% } %>' +
                '<div id="card-betslip-options" class="betslip betslip-options is-four-rows">' +
                    '<div class="category field">' +
                        '<h3 class="label"><%= raceBetsJS.i18n.data.labelBetCategory %></h3>' +
                        '<ul class="field-options">' +
                            '<li data-bet-category="TOT"><span><%= raceBetsJS.i18n.data.betslipLabelTOT %></span></li>' +
                            '<li data-bet-category="BOK" class="last"><span><%= raceBetsJS.i18n.data.betslipLabelBOK %></span></li>' +
                        '</ul>' +
                    '</div>' +
                    '<div class="type field">' +
                        '<div class="label">' +
                            '<h3><%= raceBetsJS.i18n.data.labelBetType %></h3>' +
                            '<span class="min-stake"></span>' +
                        '</div>' +
                        '<ul class="field-options">' +
                            '<li class="row1"></li>' +
                            '<li class="row1"></li>' +
                            '<li class="row1"></li>' +
                            '<li class="row1"></li>' +
                            '<li class="row1"></li>' +
                            '<li class="row1 last"></li>' +
                            '<li class="row2"></li>' +
                            '<li class="row2"></li>' +
                            '<li class="row2"></li>' +
                            '<li class="row2"></li>' +
                            '<li class="row2"></li>' +
                            '<li class="row2 last"></li>' +
                            '<li class="row3 is-fr-tot"></li>' +
                            '<li class="row3 is-fr-tot"></li>' +
                            '<li class="row3 is-fr-tot"></li>' +
                            '<li class="row3 is-fr-tot"></li>' +
                            '<li class="row3 is-fr-tot"></li>' +
                            '<li class="row3 is-fr-tot last"></li>' +
                            '<li class="row4 is-fr-tot"></li>' +
                            '<li class="row4 is-fr-tot"></li>' +
                            '<li class="row4 is-fr-tot"></li>' +
                            '<li class="row4 is-fr-tot"></li>' +
                            '<li class="row4 is-fr-tot"></li>' +
                            '<li class="row4 is-fr-tot last"></li>' +
                        '</ul>' +
                    '</div>' +
                    '<div class="stake field">' +
                        '<div class="label">' +
                            '<h3></h3>' +
                            '<input class="c-input c-input--text unit-stake" type="text" />' +
                        '</div>' +
                        '<ul class="field-options">' +
                            '<li class="row1"></li>' +
                            '<li class="row1"></li>' +
                            '<li class="row1"></li>' +
                            '<li class="row1"></li>' +
                            '<li class="row1"></li>' +
                            '<li class="row1 last"></li>' +
                            '<li class="row2"></li>' +
                            '<li class="row2"></li>' +
                            '<li class="row2"></li>' +
                            '<li class="row2"></li>' +
                            '<li class="row2"></li>' +
                            '<li class="row2 last"></li>' +
                        '</ul>' +
                    '</div>' +
                '</div>' +
                '<div class="runners-container">' +
                    '<% var usSaddleColours = (["US", "CA", "MX"].indexOf(event.country) > -1 && !event.isAntePost); %>' +
                    '<% if (race.hasSilks) { %>' +
                    '<style>' +
                        '<% $.each(runners.order, function(i, idRunner) { %>' +
                            '<% if (runners.data[idRunner].silkExtension) { %>' +
                                '<%= raceBetsJS.application.templates.race.silks({ runner: runners.data[idRunner] }) %>' +
                            '<% } %>' +
                        '<% }); %>' +
                    '</style>' +
                    '<% } %>' +
                    '<table id="runners-table" class="runners <%= (event.isVirtual ? "is-virtual" : "") %> <%= race.hasSilks ? ("has-silks silks-" + race.silkDesigner) : "" %> <%= usSaddleColours ? "us-saddle-colours" : event.isVirtual ? "vr-saddle-colours" : event.country.toLowerCase()+"-saddle-colours" %> <%= event.isAntePost ? "ante-post" : "" %> <%= (race.raceType == "D") ? "dogs" : "horses" %>" border="1">' +
                        '<colgroup>' +
                            '<col class="number"></col>' +
                            '<col class="horse"></col>' +
                            '<col class="horse-age"></col>' +
                            '<col class="jockey"></col>' +
                            '<col class="odds price" data-name="price"></col>' +
                            '<col class="odds fixed win" data-name="fixedOddsWin"></col>' +
                            '<col class="odds fixed place" data-name="fixedOddsPlace"></col>' +
                            '<col class="checkboxes"></col>' +
                        '</colgroup>' +
                        '<thead>' +
                            '<tr class="top">' +
                                '<th class="number sortable" data-sort-column="pn" id="th-pn"><span><%= raceBetsJS.i18n.data.labelProgramNumber %></span></th>' +
                                '<th class="horse sortable" data-sort-column="name"><span><%= raceBetsJS.i18n.data.labelRunner %></span></th>' +
                                '<% if (race.raceType == "T") { %>' +
                                    '<th class="distance sortable" data-sort-column="distance"><span><%= raceBetsJS.i18n.data.labelDistance %></span></th>' +
                                '<% } else if (race.raceType == "D" && event.country == "US") { %>' +
                                    '<th class="horse-age sortable" data-sort-column="age"><span><%= raceBetsJS.i18n.data.labelAge %></span></th>' +
                                '<% } else if (race.raceType == "D") { %>' +
                                    '<th class="weight sortable" data-sort-column="minTime"><span><%= raceBetsJS.i18n.data.labelRecord %></span></th>' +
                                '<% } else { %>' +
                                    '<th class="weight sortable" data-sort-column="weight"><span><%= raceBetsJS.i18n.data.labelWeight %></span></th>' +
                                '<% } %>' +
                                '<% if (!event.isAntePost && race.raceType !== "D") { %>' +
                                    '<%= raceBetsJS.application.templates.race.jockeyHead({race: race}) %>' +
                                '<% } else { %>' +
                                    '<%= raceBetsJS.application.templates.race.trainerHead() %>' +
                                '<% } %>' +
                                '<th class="odds price"><%= raceBetsJS.i18n.data.labelToteOdds %></th>' +
                                '<th class="odds fixed" colspan="2"><%= raceBetsJS.i18n.data.labelFixedOdds %></th>' +
                                '<th class="checkboxes">' +
                                    '<span class="col-1"><%= raceBetsJS.i18n.data.labelCol1 %></span>' +
                                    '<span class="col-2"><%= raceBetsJS.i18n.data.labelCol2 %></span>' +
                                    '<span class="col-3"><%= raceBetsJS.i18n.data.labelCol3 %></span>' +
                                    '<span class="col-4"><%= raceBetsJS.i18n.data.labelCol4 %></span>' +
                                    '<span class="col-5"><%= raceBetsJS.i18n.data.labelCol5 %></span>' +
                                    '<span class="col-c"><%= raceBetsJS.i18n.data.labelColC %></span>' +
                                '</th>' +
                            '</tr>' +
                            '<tr class="bottom">' +
                                '<th class="pp sortable" data-sort-column="pp"><span><%= raceBetsJS.i18n.data.labelPostPosition %></span></th>' +
                                '<th class="form"><span class="form"><%= raceBetsJS.i18n.data.labelForm %></span></th>' +
                                '<th class="horse-age <%= (race.raceType != "D") ? "sortable" : "" %>" data-sort-column="age"><span><%= (race.raceType != "D") ? raceBetsJS.i18n.data.labelAge : "" %></span></th>' +
                                '<% if (event.isAntePost) { %>' +
                                    '<%= raceBetsJS.application.templates.race.jockeyHead({race: race}) %>' +
                                '<% } else { %>' +
                                    '<%= (race.raceType === "D") ? raceBetsJS.application.templates.race.ownerHead() : raceBetsJS.application.templates.race.trainerHead() %>' +
                                '<% } %>' +
                                '<th class="odds price bettype win sortable" data-sort-column="price"><span><%= raceBetsJS.i18n.data.labelOddsWin %></span></th>' +
                                '<th class="odds fixed bettype win sortable" data-sort-column="fixedOddsWin" id="th-fow"><span><%= raceBetsJS.i18n.data.labelOddsWin %></span></th>' +
                                '<th class="odds fixed bettype place"><%= raceBetsJS.i18n.data.labelOddsPlaced %></th>' +
                                '<th class="checkboxes">' +
                                    '<span class="col-1" data-col="1"></span>' +
                                    '<span class="col-2" data-col="2"></span>' +
                                    '<span class="col-3" data-col="3"></span>' +
                                    '<span class="col-4" data-col="4"></span>' +
                                    '<span class="col-5" data-col="5"></span>' +
                                    '<span class="col-c" data-col="c"></span></th>' +
                            '</tr>' +
                        '</thead>' +
                        '<tbody>' +
                            '<% $.each(runners.order, function(i, idRunner) { %>' +
                                '<%= raceBetsJS.application.templates.race.runner({ runner: runners.data[idRunner], idEvent: event.idEvent, idRace: race.idRace, raceType: race.raceType, hasSilks: race.hasSilks, isAntePost: event.isAntePost, usSaddleColours: usSaddleColours, country: event.country, isVirtual: event.isVirtual}) %>' +
                            '<% }); %>' +
                        '</tbody>' +
                    '</table>' +
                '</div>' +
                '<div id="card-betslip-footer" class="betslip-footer">' +
                    '<div class="betslip-footer-container">' +
                        '<div class="bet-info">' +
                            '<div class="left">' +
                                '<p class="bet-status"></p>' +
                                '<p class="diff-currency"></p>' +
                            '</div>' +
                            '<div class="right">' +
                                '<p class="num-bets"></p>' +
                                '<p class="total-stake"></p>' +
                            '</div>' +
                        '</div>' +
                        '<button class="c-btn c-btn--l c-btn--primary betslip-submit" type="button"><%= raceBetsJS.i18n.data.buttonPlaceBet %></button>' +
                    '</div>' +
                '</div>' +
                '<% if (race.preview !== undefined && race.preview !== null && race.preview !== "") { %>' +
                    '<% if (race.previewProvider && race.previewProvider !== "" && race.previewProvider !== "PA") { %>' +
                        '<p class="notice race-preview">' +
                            '<% if (race.previewProvider === "GON") { %>' +
                                '<a href="http://www.galopponline.de/" target="_blank" rel="nofollow" class="race-preview-label"><strong class="race-preview-logo provider-<%= race.previewProvider %>"></strong><%= raceBetsJS.i18n.data.labelRacePreviewGB %></a>' +
                            '<% } else {%>' +
                                '<span class="race-preview-label"><strong class="race-preview-logo provider-<%= race.previewProvider %>"></strong><%= raceBetsJS.i18n.data.labelRacePreviewGB %></span>' +
                            '<% } %>' +
                            '<span><span class="race-preview-text"><%= race.preview %></span></span>' +
                        '</p>' +
                    '<% } else { %>' +
                        '<p class="notice race-preview">' +
                            '<span><strong><%= raceBetsJS.i18n.data.labelRacePreviewGB %></strong> <%= race.preview %></span>' +
                        '</p>' +
                    '<% } %>' +
                '<% } %>' +
                '<% if (race.tips) { %>' +
                    '<p class="notice race-tips">' +
                        '<strong><%= raceBetsJS.i18n.data.labelTips %>:</strong>' +
                        '<%= $.map(race.tips, function(tip) { return tip.name + " <span>(" + tip.programNumber + ")</span>"; }).join(" - ") %>' +
                    '</p>' +
                '<% } %>' +
                '<% if (race.raceOfTheDay) { %>' +
                    '<p class="margin-top notice race-of-the-day">' +
                        '<%= race.raceOfTheDay %>' +
                        '<a href="<%= raceBetsJS.application.globals.urlPrefix %>/content/show/module/raceoftheday/id/<%- race.idRace %>" class="ajaxify grey-arrows">' +
                            '<%= raceBetsJS.i18n.data.linkMore %>' +
                        '</a>' +
                    '</p>' +
                '<% } %>' +
            '</div>' +
        '</div>'
    );


    /**
     * Jockey Header
     */
    raceBetsJS.application.templates.race.jockeyHead = _.template(
        '<th class="jockey sortable" data-sort-column="jockey"><span class="jockey"><%= ((race.raceType !== "T") ? raceBetsJS.i18n.data.labelJockey : raceBetsJS.i18n.data.labelDriver) %></span></th>'
    );


    /**
     * Trainer Header
     */
    raceBetsJS.application.templates.race.trainerHead = _.template(
        '<th class="trainer sortable" data-sort-column="trainer"><span><%= raceBetsJS.i18n.data.labelTrainer %></span></th>'
    );


    /**
     * Owner Header
     */
    raceBetsJS.application.templates.race.ownerHead = _.template(
        '<th class="jockey"><span><%= raceBetsJS.i18n.data.labelRaceOwner %></span></th>'
    );


    /**
    * Header
    */
    raceBetsJS.application.templates.race.header = _.template(
        '<div class="event-heading">' +
            '<% if (!event.isVirtual) { %>'+
                '<a href="<%= raceBetsJS.application.globals.urlPrefix + "/event/details/id/" + event.idEvent %>" class="back ajaxify"><span class="c-iconFont c-iconFont--caret-left"></span></a>' +
            '<% } %>'+
            '<h1>' +
                '<% if (!event.isVirtual) { %>'+
                    '<span class="c-flag isCountry<%= event.country %>"></span>' +
                '<% } %>'+
                '<% if (siblingRaces && siblingRaces.races  && siblingRaces.races.length > 1 && raceBetsJS.application.globals.isPopup === false && !event.isVirtual) { %>' +
                    '<a href="<%= raceBetsJS.application.globals.urlPrefix + "/event/details/id/" + event.idEvent %>" class="ajaxify"><%= event.title %></a>' +
                '<% } else { %>' +
                    '<%= event.title %>' +
                '<% } %>' +
            '</h1>' +
            '<p class="details">' +
                '<span class="date-locale" data-timestamp="<%= race.postTime %>" data-date-format="<%= raceBetsJS.i18n.data.dateFormatDateWeekday %>"></span> &nbsp;|&nbsp;' +
                '<%= (!event.isSpecialBets) ? raceBetsJS.i18n.data["raceType" + event.raceType] : raceBetsJS.i18n.data[((event.specialBetType == "H2H") ? "headToHead" : "specialBets")] %>' +
            '</p>' +
            '<% if (siblingRaces && siblingRaces.races  && siblingRaces.races.length > 1 && raceBetsJS.application.globals.isPopup === false) { %>' +
                '<ul id="sibling-nav" class="nav race-numbers">' +
                    '<% $.each(siblingRaces.races, function(i, siblingRace) { %>' +
                        '<li class="race-number tipped race-<%= siblingRace.idRace %> status-<%= siblingRace.raceStatus %><%= (siblingRace.idRace === race.idRace) ? " active" : "" %> <%= (event.isVirtual ? "is-virtual" : "") %>" title="<%= raceBetsJS.i18n.getRaceTitle(siblingRace.raceTitle, siblingRace.raceNumber) %>">' +
                            '<a href="<%= raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + siblingRace.idRace %>" class="ajaxify">'+
                                '<%= ((event.isVirtual) ? raceBetsJS.application.assets.timezone.date("H:i", siblingRace.postTime) : siblingRace.raceNumber) %>'+
                            '</a>' +
                        '</li>' +
                    '<% }); %>' +
                '</ul>' +
            '<% } %>' +
        '</div>'
    );

    /*
     * Promotion labels (tooltip)
     */
    raceBetsJS.application.templates.race.promotions = _.template('\
        <div class="clearfix promoLabel">\
            <%= promotion.terms %>\
            <br />\
            <% if (promotion.promotionType !== "custom") { %>\
                <a href="#" class="terms-link" data-terms-index="<%= promotion.index %>"><%= raceBetsJS.i18n.data.labelFullConditions %></a>\
            <% } else { %>\
            <% if (promotion.termsUrl !== "") { %>\
                <a href="<%= promotion.termsUrl %>" class="terms-link"><%= raceBetsJS.i18n.data.labelFullConditions %></a>\
            <% } else { %>\
                <a href="static/terms" class="terms-link"><%= raceBetsJS.i18n.data.labelFullConditions %></a>\
            <% } %>\
            <% } %>\
        </div>\
    ');

    /*
    * Silks
    */
    raceBetsJS.application.templates.race.silks = _.template(
        '#runner-<%= runner.idRunner %> > .horse {' +
            'background-image: url(<%= raceBetsJS.application.globals.imageHost %>/cache/img/silks/<%= runner.silkId %>_w31.<%= runner.silkExtension %>)' +
        '}' +
        '@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {' +
            '#runner-<%= runner.idRunner %> > .horse {' +
                'background-image: url(<%= raceBetsJS.application.globals.imageHost %>/cache/img/silks/<%= runner.silkId %>_w62.<%= runner.silkExtension %>)' +
            '}' +
        '}'
    );

    /*
    * Runner
    */
    raceBetsJS.application.templates.race.runner = _.template('\
        <tr id="runner-<%= runner.idRunner %>" class="runner<%= (runner.reserve) ? " reserve" : "" %>" data-id-runner="<%= runner.idRunner %>" data-id-subject="<%= runner.idSubject %>">\
            <td class="number">\
                <span class="number <%= raceType + parseInt(runner.programNumber, 10) %>"><span class="corner"></span>\
                <span class="pn"><%= runner.programNumber %><% if(country === "US" && runner.stable) { %><%= runner.stable %><% } %> </span></span>\
                <% if (runner.postPosition) { %><span class="pp"><%= runner.postPosition %></span><% } %>\
            </td>\
            <td class="horse">\
                <div class="icons">\
                    <table>\
                        <tr>\
                            <td>\
                                <a class="notes" title="<%= raceBetsJS.i18n.data.labelNotes %>"></a>\
                            </td>\
                            <td>\
                                <a class="favourite" title="<%= raceBetsJS.i18n.data.labelFavourite %>"></a>\
                            </td>\
                            <% if (!isAntePost) { %>\
                                </tr>\
                                <tr>\
                                <td>\
                                    <a class="pp-detailed<%= runner.ppDetailed ? " enabled" : "" %>" title="<%= raceBetsJS.i18n.data.labelForm %>"></a>\
                                </td>\
                            <% } %>\
                            <td>\
                                <a class="ante-post <%= runner.antePostMarkets ? "enabled" : "" %>" title="<%= raceBetsJS.i18n.data.labelStats %>"></a>\
                            </td>\
                        </tr>\
                    </table>\
                    <% if (runner.finalPosition) { %>\
                        <span class="c-finishingPost">\
                            <span class="c-finishingPost__position"><%= runner.finalPosition %></span>\
                            <span class="c-finishingPost__post"></span>\
                        </span>\
                    <% } %>\
                </div>\
                <span class="horse">\
                    <% if(isAntePost && !hasSilks && runner.origin.iso) { %><span class="c-flag isCountry<%= runner.origin.iso %> first" title="<%= runner.origin.name %>"></span><% } %>\
                    <span class="name">\
                        <span>\
                            <% if (raceType !== "D" && !isVirtual && runner.idSubject) { %>\
                                <a data-url="<%= raceBetsJS.application.globals.urlPrefix %>/formguide/overview/id/<%= runner.idSubject %>/baseRace/<%= idRace %>" class="popup"\
                                data-popup-width="1000" data-popup-height="725" data-popup-scroll="yes" data-popup-name="<%= runner.idRunner %>"><%= runner.name %></a>\
                            <% } else { %>\
                                <%= runner.name %>\
                            <% } %>\
                            <%= (runner.blinkers) ? " (" + raceBetsJS.i18n.data.blinkersAbbr + ")" : "" %>\
                        </span>\
                        <% if(runner.shoes) { %>\
                            <div class="tipped shoes <%= runner.shoes %>" title="<%= raceBetsJS.i18n.data["labelShoes"+runner.shoes[0].toUpperCase() + runner.shoes.slice(1)] %>" ></div>\
                        <% } %>\
                    </span>\
                    <span class="icons">\
                        <% if(country !== "US") { %>\
                            <% if(runner.stable) { %>\
                                <span class="stable st-<%= runner.stable %>"></span>\
                            <% } %>\
                        <% } %>\
                        <% if(runner.homeland && country !== runner.homeland) { %>\
                            <span title="<%= raceBetsJS.i18n.data.labelHomeland %>: <%= raceBetsJS.i18n.data.countries[runner.homeland] %>" class="c-flag c-flag--rounded isCountry<%= runner.homeland %>"></span>\
                        <% } %>\
                        <% if(runner.featuredHorse.isStar) { %><span class="star"></span><% } %>\
                    </span>\
                </span>\
                <span class="form"><%= runner.ppString ? runner.ppString : "-" %></span>\
            </td>\
            <td class="horse-age">\
                <% if (!isAntePost) { %>\
                    <% if (raceType == "T") { %>\
                        <span class="distance"><%= raceBetsJS.format.distance(runner.distance, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) %></span>\
                    <% } else if (raceType == "D") { %>\
                        <span class="weight"><%= (runner.pp.minTime != "0.00") ? runner.pp.minTime : "-" %></span>\
                    <% } else { %>\
                        <span class="weight">\
                            <% if (runner.jockey.weight.weight > 0) { %>\
                                <%= raceBetsJS.format.weight(runner.jockey.weight.weight, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) %>\
                            <% } else { %>\
                                -\
                            <% } %>\
                        </span>\
                    <% } %>\
                    <% if (raceBetsJS.application.user.lang == "en" && runner.age < 5 && runner.gender == "M") { %>\
                        <% runner.gender = "F";  %>\
                    <% } %>\
                    <span class="age" title="<%= runner.gender ? raceBetsJS.i18n.data[((raceType != "D") ? "gender" : "genderGreyhounds") + runner.gender] : "" %>">\
                        <%= (runner.age) ? raceBetsJS.i18n.print("xYearOldAbbr", { age: runner.age }) : "" %> <%= runner.gender ? (raceBetsJS.i18n.data[((raceType != "D") ? "gender" : "genderGreyhounds") + runner.gender] || "").charAt(0).toUpperCase() : "" %>\
                    </span>\
                <% } %>\
            </td>\
            <td class="jockey">\
                <% if (raceType != "D") { %>\
                    <span class="jockey">\
                        <% if (runner.reserve) { %>\
                            <%= raceBetsJS.i18n.data.labelReserve %>\
                        <% } else { %>\
                            <%= runner.jockey.firstName %>&nbsp;<%= runner.jockey.lastName %>\
                        <% } %>\
                    </span>\
                    <span class="trainer"><%= runner.trainer.firstName %>&nbsp;<%= runner.trainer.lastName %></span>\
                <% } else { %>\
                    <span class="jockey"><%= runner.trainer.firstName %>&nbsp;<%= runner.trainer.lastName %></span>\
                    <span class="trainer" title="<%- runner.owner %>"><%= (runner.owner.length > 23) ? $.trim(runner.owner.substr(0, 23)) + "." : runner.owner %></span>\
                <% } %>\
            </td>\
            <td class="odds price"><div class="trend"><button type="button" class="odds-button"><span></span></button></div></td>\
            <td class="odds fixed win"><button type="button" class="odds-button fixed" data-id-runner="<%= runner.idRunner %>" data-bet-type="WIN"><span></span></button></td>\
            <td class="odds fixed place"><button type="button" class="odds-button fixed" data-id-runner="<%= runner.idRunner %>" data-bet-type="PLC"><span></span></button></td>\
            <td class="checkboxes"><span class="col-1 enabled" data-col="1"></span><span class="col-2 enabled" data-col="2"></span><span class="col-3 enabled" data-col="3"></span><span class="col-4 enabled" data-col="4"></span><span class="col-5 enabled" data-col="5"></span><span class="col-c enabled" data-col="c"></span></td>\
        </tr>\
    ');

    /*
    * Runner Details (tooltip)
    */
    raceBetsJS.application.templates.race.runner.details = _.template('\
        <div>\
            <div class="clearfix<% if (obj.photo) { %> with-photo<% } %>">\
                <table>\
                    <% if (includeName !== undefined && includeName === true) { %>\
                        <tr>\
                            <td class="label"><%= raceBetsJS.i18n.data.labelRunner %></td>\
                            <td>\
                                <%= name %>\
                                <%= (blinkers) ? " (" + raceBetsJS.i18n.data.blinkersAbbr + ")" : "" %>\
                            </td>\
                        </tr>\
                    <% } %>\
                    <% if (sire) { %>\
                        <tr>\
                            <td class="label"><%= raceBetsJS.i18n.data.labelBreeding %></td>\
                            <td><%= sire %> - <%= dam %> <%= (damSire) ? "(" + damSire + ")" : "" %></td>\
                        </tr>\
                    <% } %>\
                    <% if (owner && raceType != "D") { %>\
                        <tr>\
                            <td class="label"><%= raceBetsJS.i18n.data.labelOwner %></td>\
                            <td><%= owner %></td>\
                        </tr>\
                    <% } %>\
                    <% if (obj.homeland && eventCountry !== obj.homeland) { %>\
                        <tr>\
                            <td class="label"><%= raceBetsJS.i18n.data.labelHomeland %>:</td>\
                            <td><%= raceBetsJS.i18n.data.countries[obj.homeland] %></td>\
                        </tr>\
                    <% } %>\
                    <% if (breeder) { %>\
                        <tr>\
                            <td class="label"><%= raceBetsJS.i18n.data.labelBreeder %></td>\
                            <td><%= breeder %></td>\
                        </tr>\
                    <% } %>\
                    <% if (colour) { %>\
                        <tr>\
                            <td class="label"><%= raceBetsJS.i18n.data.labelColour %></td>\
                            <td><%= raceBetsJS.i18n.data["colour" + colour] %></td>\
                        </tr>\
                    <% } %>\
                    <% if (pp.purse) { %>\
                        <tr>\
                            <td class="label"><%= raceBetsJS.i18n.data.labelWinningAmount %></td>\
                            <td><%= raceBetsJS.format.money(pp.purse, 0) + " " + pp.purseCurrency %></td>\
                        </tr>\
                    <% } %>\
                    <% if (pp.minTime !== undefined && pp.minTime != "0.00" && raceType != "D") { %>\
                        <tr>\
                            <td class="label"><%= raceBetsJS.i18n.data.labelMinTime %></td>\
                            <td><%= raceBetsJS.i18n.print("minTime", { time: pp.minTime }) %></td>\
                        </tr>\
                    <% } %>\
                    <% if (pp.lastRunFlat || pp.lastRunHurdles) { %>\
                        <tr>\
                            <td class="label"><%= raceBetsJS.i18n.data.labelLastRun %></td>\
                            <td><%= raceBetsJS.i18n.print("lastRunDays", { flat: pp.lastRunFlat ? pp.lastRunFlat : "-", hurdles: pp.lastRunHurdles ? pp.lastRunHurdles : "-" }) %></td>\
                        </tr>\
                        <tr>\
                            <td class="label"><%= raceBetsJS.i18n.data.labelWins %></td>\
                            <td><%= raceBetsJS.i18n.print("winsPerCategory", { course: pp.course, distance: pp.distance, courseDistance: pp.courseDistance }) %></td>\
                        </tr>\
                    <% } %>\
                </table>\
                <% if (photo) { %>\
                    <img class="photo" src="<%= raceBetsJS.application.globals.imageHost + "/cache/img/photos/subjects/" + idSubject + "_" + photo + "_" + "w230" + ".jpg" %>" width="115" height="76">\
                <% } %>\
            </div>\
            <% if (featuredHorse.comment) { %>\
                <p><%= featuredHorse.comment %></p>\
            <% } %>\
            <% var pinSticker; if (pinSticker && pinSticker !== "0") { %>\
                <p><%= pinSticker %></p>\
            <% } %>\
        </div>\
    ');

    /*
     * Person Statistics (tooltip)
     */
    raceBetsJS.application.templates.race.runner.personStats = _.template('\
        <div class="clearfix<% if (person.photo) { %> with-photo<% } %><% if (person.stats) { %> with-stats<% } %>">\
            <% if (person.stats) { %>\
            <table>\
                <tr>\
                    <td class="label" colspan="2" style="color:#ffc531;">\
                        <%= raceBetsJS.i18n.data.labelStatistics %> <%= new Date().getFullYear() %> <span class="c-flag isCountry<%= eventCountry %>" style="margin-left:4px;"></span> \
                    </td>\
                </tr>\
                <tr>\
                    <td class="label"><%= raceBetsJS.i18n.data.labelStarts %>:</td>\
                    <td><%= person.stats.numRaces %></td>\
                </tr>\
                <tr>\
                    <td class="label"><%= raceBetsJS.i18n.data.labelWins %></td>\
                    <td><%= person.stats.numWin %> <% if (person.stats.numWin) { %>(<%= Math.round(person.stats.numWin / person.stats.numRaces * 100) %>%)<% } %></td>\
                </tr>\
                <tr>\
                    <td class="label"><%= raceBetsJS.i18n.data.labelPlaces %>:</td>\
                    <td><%= person.stats.numPlace %> <% if (person.stats.numPlace) { %>(<%= Math.round(person.stats.numPlace / person.stats.numRaces * 100) %>%)<% } %></td>\
                </tr>\
                <tr>\
                    <td class="label"><%= raceBetsJS.i18n.data.labelEarnings %>:</td>\
                    <td><%= raceBetsJS.format.money(person.stats.earnings, 2, "EUR", true) %></td>\
                </tr>\
            </table>\
            <% } %>\
            <% if (person.photo) { %>\
                <img class="photo" src="<%= raceBetsJS.application.globals.imageHost %>/cache/img/photos/persons/<%= person.idPerson %>_<%= person.photo %>_w150.jpg" width="75" />\
            <% } %>\
        </div>\
    ');

    /*
    * Runner Past Performance (details row)
    */
    raceBetsJS.application.templates.race.runner.ppDetailed = _.template('\
        <table>\
            <thead>\
                <tr>\
                    <th><%= raceBetsJS.i18n.data.labelDate %></th>\
                    <th><%= raceBetsJS.i18n.data.labelTrack %></th>\
                    <th class="final-position"><%= raceBetsJS.i18n.data.labelFinalPosition %></th>\
                    <th class="distance"><%= raceBetsJS.i18n.data.labelDistance.replace(/:/, "") %></th>\
                    <th class="category"><%= raceBetsJS.i18n.data.labelCategory %></th>\
                    <% if (raceType == "T") { %>\
                        <th><%= raceBetsJS.i18n.data.labelDriver %></th>\
                        <th><%= raceBetsJS.i18n.data.labelRunnerTime %></th>\
                    <% } else { %>\
                        <th><%= raceBetsJS.i18n.data.labelJockey %></th>\
                    <% } %>\
                    <th class="win-odds"><%= raceBetsJS.i18n.data.labelToteOdds %></th>\
                </tr>\
            </thead>\
            <tbody>\
                <% $.each(data, function() { %>\
                    <tr>\
                        <td><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, this.raceDate) %></td>\
                        <td><%= this.track %></td>\
                        <td class="final-position"><%= (isNaN(this.finalPosition)) ? this.finalPosition : ((this.finalPosition > 0) ? this.finalPosition + "." : "-") %></td>\
                        <td class="distance">\
                            <%= this.distance ? raceBetsJS.format.distance(this.distance, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) : "-" %>\
                            <% if (this.startType) { %><span class="light-grey"><%= raceBetsJS.i18n.data["startType" + this.startType].charAt(0) %></span><% } %>\
                        </td>\
                        <td class="category"><%= this.category ? raceBetsJS.i18n.data["raceCategoryAbbr" + this.category] : "-" %></td>\
                        <td><%= this.jockeyLn ? $.trim(this.jockeyFn + " " + this.jockeyLn) : "-" %></td>\
                        <% if (raceType == "T") { %><td><%= this.ownTime ? this.ownTime : "-" %></td><% } %>\
                        <td class="win-odds"><%= (this.winOdds) ? raceBetsJS.format.odds(this.winOdds) : "-" %></td>\
                    </tr>\
                <% }); %>\
            </tbody>\
        </table>\
    ');


    /*
    * Runner Ante Post Markets (details row)
    */
    raceBetsJS.application.templates.race.runner.antePost = _.template('\
        <table>\
            <thead>\
                <tr>\
                    <th><%= raceBetsJS.i18n.data.labelAntePostMarket %></th>\
                    <th><%= raceBetsJS.i18n.data.labelDate %></th>\
                    <th class="category"><%= raceBetsJS.i18n.data.labelCategory %></th>\
                    <th class="purse"><%= raceBetsJS.i18n.data.labelPurse %></th>\
                    <th class="win-odds"><%= raceBetsJS.i18n.data.labelOdds %></th>\
                </tr>\
            </thead>\
            <tbody>\
                <% $.each(data, function() { %>\
                    <tr>\
                        <td><a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= this.idRace %>" class="ajaxify"><%= raceBetsJS.i18n.getRaceTitle(this.raceTitle, this.raceNumber) %></td>\
                        <td><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, this.postTime) %></td>\
                        <td class="category"><%= this.category ? raceBetsJS.i18n.data["raceCategoryAbbr" + this.category] : "-" %></td>\
                        <td class="purse"><%= this.purse ? (raceBetsJS.format.money(this.purse, 0) + " " + this.purseCurrency) : "-" %></td>\
                        <td class="win-odds"><%= raceBetsJS.format.odds(this.fixedOddsWin) %></td>\
                    </tr>\
                <% }); %>\
            </tbody>\
        </table>\
    ');


    /*
    * Result
    */
    raceBetsJS.application.templates.race.result = _.template(
        '<div class="result" id="race-result">' +
            '<table class="result-container">' +
                '<tr class="head">' +
                    '<td class="col1">' +
                        '<table border="1" class="positions <% if (result.odds !== undefined) { %><%= (result.odds.wps.WIN !== undefined) ? "col-win" : "" %> <%= (result.odds.wps.PLC !== undefined) ? "col-place" : "" %> <%= (result.odds.wps.SHW !== undefined) ? "col-show" : "" %><% } %>">' +
                            '<colgroup>' +
                                '<col class="runner"></col>' +
                                '<col class="odds win"></col>' +
                                '<col class="odds place"></col>' +
                                '<col class="odds show"></col>' +
                                '<col class="odds price"></col>' +
                            '</colgroup>' +
                            '<thead>' +
                                '<tr>' +
                                    '<th><%= raceBetsJS.i18n.data.headResult %></th>' +
                                    '<th class="odds win"><%= raceBetsJS.format.betTypeName("WIN", "", raceBetsJS.application.user.lang, wps) %></th>' +
                                    '<th class="odds place"><%= raceBetsJS.format.betTypeName("PLC", "", raceBetsJS.application.user.lang, wps) %></th>' +
                                    '<th class="odds show"><%= raceBetsJS.format.betTypeName("SHW", "", raceBetsJS.application.user.lang, wps) %></th>' +
                                    '<th class="odds price">SP</th>' +
                                '</tr>' +
                            '</thead>' +
                            '<tbody>' +
                                '<% var positionBefore; %>' +
                                '<% var oddsFormat = raceBetsJS.application.assets.settings.get().dialog.general.oddsFormat; %>' +
                                '<% var resultOddsFormat = (oddsFormat == "fractional" ? "base1" : oddsFormat); %>' +
                                '<% $.each(result.positions, function(i) { %>' +
                                    '<% if (this.position != "0") { %>' +
                                        '<% var pn = parseInt(this.programNumber, 10); %>' +
                                        '<tr class="<%= (i == 0) ? "first" : "" %> <%= (i == result.positions.length - 1) ? "last" : "" %> <%= (this.position == 1) ? "winner" : "" %> <%= (this.position == positionBefore) ? "same-position" : "" %>">' +
                                            '<td><span class="position"><%= this.position %>.</span> <span class="name"><%= this.name %></span> <span class="em"><%= this.programNumber %></span></td>' +
                                            '<td class="odds win"><%= (result.odds !== undefined && result.odds.wps.WIN !== undefined && result.odds.wps.WIN[pn] > 0) ? raceBetsJS.format.odds(result.odds.wps.WIN[pn]) : "" %></td>' +
                                            '<td class="odds place"><%= (result.odds !== undefined && result.odds.wps.PLC !== undefined && result.odds.wps.PLC[pn] > 0) ? raceBetsJS.format.odds(result.odds.wps.PLC[pn]) : "" %></td>' +
                                            '<td class="odds show"><%= (result.odds !== undefined && result.odds.wps.SHW !== undefined && result.odds.wps.SHW[pn] > 0) ? raceBetsJS.format.odds(result.odds.wps.SHW[pn]) : "" %></td>' +
                                            '<td class="odds price"><%= raceBetsJS.format.odds(runners.data[this.idRunner].odds.PRC) %></td>' +
                                        '</tr>' +
                                        '<% positionBefore = this.position; %>' +
                                    '<% } %>' +
                                '<% }); %>' +
                                '<% if (deduction > 0) { %>' +
                                    '<tr>' +
                                        '<td colspan="5">' +
                                            '<%= raceBetsJS.i18n.print("labelRule4Deductions", { deduction: deduction }) %>' +
                                        '</td>' +
                                    '</tr>' +
                                '<% } %>' +
                                '<% if (deadHeat && $.inArray(country, ["GB", "IE", "AE"] > -1) && raceStatus === "FNL") { %>' +
                                    '<tr class="deadheat">' +
                                        '<td colspan="5">' +
                                            '<span class="c-tag isDeadHeat"><%= raceBetsJS.i18n.data.labelRule4DeadHeat %></span>' +
                                        '</td>' +
                                    '</tr>' +
                                '<% } %>' +
                                '<% if (result.otherOdds && raceStatus === "FNL") { %>' +
                                    '<tr>' +
                                        '<td colspan="5" class="other-odds">' +
                                            '<% if (result.otherOdds.coupledRunners) { %>' +
                                                '<div class="coupled-runners-wrapper">' +
                                                    '<strong><%= raceBetsJS.i18n.data.labelCoupledRunners %>:</strong>' +
                                                    '<div class="coupled-runners">' +
                                                        '<% $.each(result.otherOdds.coupledRunners.WIN, function(pn, odd) { %>' +
                                                            '<span><%= (_.find(runners.data, function(runner) { return parseInt(runner.programNumber, 10) == pn})).name + " (" + pn + ") " +" @ "+ raceBetsJS.format.odds(odd) %></span>' +
                                                        '<% }) %>' +
                                                    '</div>' +
                                                '</div>' +
                                            '<% } else if (result.otherOdds.doubleResult) { %>' +
                                                '<strong><%= raceBetsJS.i18n.data.labelDoubleResultsConcession %>:</strong> <% $.each(result.otherOdds.doubleResult.WIN, function(pn, odd) { %><%= (_.find(runners.data, function(runner) { return runner.programNumber == pn})).name +" @ "+ raceBetsJS.format.odds(odd) %><% }) %>' +
                                            '<% } %>' +
                                        '</td>' +
                                    '</tr>' +
                                '<% } %>' +
                            '</tbody>' +
                        '</table>' +
                    '</td>' +
                    '<td class="col2">' +
                        '<table class="odds-container">' +
                            '<thead>' +
                                '<tr>' +
                                    '<th colspan="2" class="off-time">' +
                                        '<% if (offTime !== 0) { %>' +
                                            '<%= raceBetsJS.i18n.data.labelOffTime %>' +
                                            '<span class="time">' +
                                                '<%= raceBetsJS.i18n.print("timeLong", {time:raceBetsJS.application.assets.timezone.date("H:i:s", offTime)}) %>' +
                                            '</span>' +
                                        '<% } %>' +
                                    '</th>' +
                                '</tr>' +
                            '</thead>' +
                            '<tbody>' +
                                '<tr>' +
                                    '<% if(result.odds !== undefined && result.odds.other !== undefined) { %>' +
                                        '<td>' +
                                            '<ul class="final-odds">' +
                                                '<% var i = 1, colChange = Math.ceil((result.odds.numOther + (oddsFormat == "fractional" ? 1 : 0)) / 2); %>' +
                                                '<% if (spEvent && i == 1 && oddsFormat == "fractional") { %>' +
                                                    '<li data-bet-type="PLC">' +
                                                        '<span class="title"><%= raceBetsJS.i18n.data.labelEachWayTerms %></span>' +
                                                        '<span class="odds"><%= placesNum %> @ 1/<%= placeOddsFactor %></span>' +
                                                    '</li>' +
                                                    '<% if (i++ == colChange) { %>' +
                                                        '</ul></td>' +
                                                        '<td><ul class="final-odds">' +
                                                    '<% } %>' +
                                                '<% } %>' +
                                                '<% $.each(result.odds.other, function(index) { %>' +
                                                    '<% var betType, combs; %>' +
                                                        '<% for(key in this) { %>' +
                                                            '<% if (this.hasOwnProperty(key)) { %>' +
                                                                '<% betType = key; %>' +
                                                                '<% combs = this[key]; %>' +
                                                                '<% break; %>' +
                                                            '<% } %>' +
                                                    '<% } %>' +
                                                    '<% $.each(combs, function(odds, combinations) { %> ' +
                                                        '<li class="<%= (combinations.length > 1) ? "multi-odds" : "" %>" data-bet-type="<%= betType %>">' +
                                                            '<span class="title <%- (combinations.length > 1) ? "grey-arrows" : "" %>"><%= raceBetsJS.format.betTypeName(betType, ($.inArray(country, ["GB", "IE", "AE"]) > -1 ? "BOK" : "TOT"), raceBetsJS.application.user.lang, wps, country) %></span>' +
                                                            '<% if (combinations.length == 1) { %>' +
                                                                '<span class="number"><%= combinations[0] %></span>' +
                                                            '<% } else { %>' +
                                                                '<div class="tooltip" data-bet-type="<%= betType %>">' +
                                                                    '<% $.each(combinations, function() { %>' +
                                                                        '<%= this %> <br />' +
                                                                    '<% }); %>' +
                                                                '</div>' +
                                                            '<% }%>' +
                                                            '<span class="odds"><%= raceBetsJS.format.odds(odds, resultOddsFormat) %></span>' +
                                                        '</li>' +
                                                        '<% if (i++ == colChange) { %>' +
                                                            '</ul></td>' +
                                                            '<td><ul class="final-odds">' +
                                                        '<% } %>' +
                                                    '<% }); %>' +
                                                '<% }); %>' +
                                            '</ul>' +
                                        '</td>' +
                                    '<% } else if (!isAntePost && raceStatus != "FNL") { %>' +
                                        '<td class="full-width"><%= raceBetsJS.i18n.data.noOddsAvailableYet %></td>' +
                                    '<% } else { %>' +
                                        '<td class="full-width">&nbsp;</td>' +
                                    '<% } %>' +
                                '</tr>' +
                            '</tbody>' +
                        '</table>' +
                    '</td>' +
                '</tr>' +
            '</table>' +
        '</div>'
    );

    raceBetsJS.application.templates.race.freeBets = _.template(
        '<div id="free-bets">' +
            '<div class="container">' +
                '<div class="free-bets-label">' +
                    '<%= raceBetsJS.i18n.data.msgFreeBetAvailable %>' +
                '</div>' +
                '<div class="selector">' +
                    '<select class="select-box" id="freebet-select">' +
                        '<% $.each(freeBets, function() { %>' +
                            '<option value="<%= this.idFreebet %>" <% if (this.valid === false) { %>disabled="disabled"<% } %>>' +
                                '{span class="amount"}<%= raceBetsJS.format.money(this.amount, 2, raceBetsJS.application.user.currency, true) %>{/span}' +
                                '<% if (typeof this.runnerDetails !== "undefined") { %>' +
                                    ' <%= this.runnerDetails.name %> (<%= this.runnerDetails.programNumber %>)<br />' +
                                '<% } else { %>' +
                                    ' <%= raceBetsJS.i18n.data.selectAllRunners %>' +
                                '<% } %>' +
                            '</option>' +
                        '<% }); %>' +
                    '</select>' +
                '</div>' +
                '<div class="button">' +
                    '<button class="c-btn c-btn--primary" disabled="disabled"><%= raceBetsJS.i18n.data.btnUseFreeBet %></button>' +
                '</div>' +
                '<div class="bet-type">' +
                    '<% $.each(["WIN", "WP"], function(index, betType) { %>' +
                        '<label data-index="<%= index %>">' +
                            '<span class="c-input c-input--radio">' +
                                '<input type="radio" id="toggle-bet-type-<%= betType %>" name="bet-type" class="c-input__radio" value="<%= betType %>" <% if (index == 0) { %>checked="checked"<% } %> >' +
                                '<span class="c-input__label"><%= raceBetsJS.i18n.data["betType" + betType] %></span>' +
                            '</span>' +
                        '</label>' +
                    '<%}) %>' +
                '</div>' +
            '</div>' +
        '</div>'
    );

    /*
    * My Bets
    */
    raceBetsJS.application.templates.race.bets = _.template('\
        <div class="rounded panel panel-mybets-racecard clearfix bets new light with-rows">\
            <h2>\
                <%= raceBetsJS.i18n.data.headMyBets %>\
                <a class="button refresh second" title="<%= raceBetsJS.i18n.data.titleRefresh %>"><span></span></a>\
                <a class="button close" title="<%= raceBetsJS.i18n.data.titleClose %>"><span></span></a>\
            </h2>\
            <h3 class="captions clearfix first">\
                <span class="bet-type"><%= raceBetsJS.i18n.data.labelBetType %></span>\
                <span class="details"><%= raceBetsJS.i18n.data.labelBetDetails %></span>\
                <span class="stake"><%= raceBetsJS.i18n.data.labelStake %></span>\
                <span class="payout"><%= raceBetsJS.i18n.data.labelPayout %></span>\
                <span class="id"><%= raceBetsJS.i18n.data.labelBetslipId %></span>\
                <span class="status"><%= raceBetsJS.i18n.data.labelStatus %></span>\
            </h3>\
            <ul class="list">\
                <% $.each(bets, function(i) { %>\
                    <% var bet = this; %>\
                    <li class="clearfix <%= (i == 0) ? "first" : "" %> <%= (i == bets.length - 1) ? "last" : "" %> <%- (i % 2) ? "even" : "" %>">\
                        <a class="ajaxify" data-public-id="<%= this.publicId %>">\
                            <span class="bet-type">\
                                <%= raceBetsJS.format.betTypeName(this.betType, this.betCategory, raceBetsJS.application.user.lang, undefined, country) %>\
                                <% if (this.betCategory == "FXD") { %>\
                                    <span class="light-grey"><%= raceBetsJS.i18n.data["betCategory" + this.betCategory] %></span>\
                                <% } %>\
                            </span>\
                            <span class="details">\
                                <% if (this.betslipType == "STD") { %>\
                                    <% if (this.betCategory != "FXD" && this.horseName != null) { %>\
                                        <span>\
                                            <%= this.horseName %> <span class="light-grey">(<%= this.marks[0] %>)</span>\
                                            <% if (this.freebet === true) { %>\
                                                &nbsp;\
                                                <span class="tag free-bet">\
                                                    <%= raceBetsJS.i18n.data.labelFreeBet %>\
                                                </span>\
                                            <% } %>\
                                        </span>\
                                    <% } else if (this.betCategory == "FXD" && this.horseName != null) { %>\
                                        <span>\
                                            <%= this.horseName %>\
                                            <span class="light-grey">(<%= this.marks[0] %>)</span>\
                                            <% if (this.freebet === true) { %>\
                                                &nbsp;\
                                                <span class="tag free-bet">\
                                                    <%= raceBetsJS.i18n.data.labelFreeBet %>\
                                                </span>\
                                            <% } %>\
                                        </span>\
                                        <span class="odds">\
                                            <% if (this.betType == "WIN") { %>\
                                                @ <span><%= raceBetsJS.format.odds(this.fixedOddsWin) %></span>\
                                            <% } else if (this.betType == "PLC") { %>\
                                                @ <span><%= raceBetsJS.format.odds(this.fixedOddsPlace) %></span>\
                                            <% } else if (this.betType == "WP") { %>\
                                                <% if(this.betCategory == "FXD") { %>\
                                                    <% if ($.inArray(this.country, ["GB", "IE", "AE"]) > -1) { %>\
                                                        @ <%= raceBetsJS.format.odds(this.fixedOddsWin) %>\
                                                    <% } else { %>\
                                                        @ <%= raceBetsJS.format.odds(this.fixedOddsWin) %> | <%= raceBetsJS.format.odds(this.fixedOddsPlace) %>\
                                                    <% } %>\
                                                <%}%>\
                                            <% } %>\
                                        </span>\
                                    <% } else { %>\
                                        <table class="marks">\
                                        <% var colsNum = this.marks.length - 1; %>\
                                            <% $(this.marks).each(function(j) { %>\
                                                <%\ if (this == "") { return; } %>\
                                                <tr>\
                                                    <td class="col-label">\
                                                        <% if (j < colsNum) { %>\
                                                            <%= raceBetsJS.format.romanNumeral(j+1) %>:\
                                                        <% } else { %>\
                                                            <%= raceBetsJS.i18n.data.labelBetslipCombinationCol %>\
                                                        <% } %>\
                                                    </td>\
                                                    <td><%= this %></td>\
                                                </tr>\
                                            <% }); %>\
                                        </table>\
                                    <% } %>\
                                <% } else { %>\
                                    <%= raceBetsJS.i18n.data.accumulatedBet %>\
                                    <span class="light-grey">\
                                        <% if (this.accSystem == "C" || this.accSystem == "F") { %>\
                                            <%= raceBetsJS.i18n.data["system" + this.accumulationDetails.length + this.accSystem] %>\
                                        <% } else if (this.accSystem != "") { %>\
                                            <%= raceBetsJS.i18n.data["system" + this.accSystem + "X"] %>\
                                        <% } %>\
                                    </span>\
                                    <% if (bet.antePost == 1) { %>\
                                        <div class="ante-post-details">\
                                            <% var iteration = 0; $.each(this.accumulationDetails, $.proxy(function(i, event) { %>\
                                                <%= event.title %><%= (iteration < _.size(this.accumulationDetails)-1) ? ", " : "" %>\
                                            <% iteration++; }, this)); %>\
                                        </div>\
                                    <% } else { %>\
                                        <table class="marks">\
                                            <% $(this.accumulationDetails).each(function(i) { %>\
                                                <tr>\
                                                    <td class="col-label">\
                                                        <% if (this.offline) { %>\
                                                            <a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= this.idRace %>" class="ajaxify"><%= this.title %><% if (bet.antePost == 0) { %>, <%= raceBetsJS.i18n.print("abbrRaceNumberX", { race: this.raceNumber }) %><% } %>\
                                                        <% } else { %>\
                                                            <%= this.title %><% if (bet.antePost == 0) { %>, <%= raceBetsJS.i18n.print("abbrRaceNumberX", { race: this.raceNumber }) %><% } %>\
                                                        <% } %>\
                                                    </td>\
                                                    <td class="col-marks">\
                                                        <%= this.subMarks.replace(" ", "-") %>\
                                                    </td>\
                                                    <td class="light-grey">(<%= raceBetsJS.i18n.data["betStatus" + this.subStatus] %>)</td>\
                                                </tr>\
                                            <% }); %>\
                                        </table>\
                                    <% } %>\
                                <% } %>\
                            </span>\
                            <span class="stake">\
                                <span><%= raceBetsJS.format.money(this.totalStakes, 2) %> <%= raceBetsJS.application.user.currency %></span>\
                                <% if (this.totalStakes > this.unitStake || this.totalStakes < this.unitStake) { %>\
                                    <span class="light-grey"><%= raceBetsJS.format.money(this.unitStake, 2) %> <%= raceBetsJS.application.user.currency %></span>\
                                <% } %>\
                            </span>\
                            <span class="payout">\
                                <% if (this.winAmount > 0 && this.refundAmount > 0) { %>\
                                    <span><%= raceBetsJS.format.money(this.winAmount, 2) %> <%= raceBetsJS.application.user.currency %></span>\
                                    <span class="light-grey"><%= raceBetsJS.format.money(this.refundAmount, 2) %> <%= raceBetsJS.application.user.currency %></span>\
                                <% } else if (this.winAmount > 0) { %>\
                                    <span><%= raceBetsJS.format.money(this.winAmount, 2) %> <%= raceBetsJS.application.user.currency %></span>\
                                <% } else if (this.refundAmount > 0) { %>\
                                    <span><%= raceBetsJS.format.money(this.refundAmount, 2) %> <%= raceBetsJS.application.user.currency %></span>\
                                <% } else { %>\
                                    -\
                                <% } %>\
                            </span>\
                            <span class="id">\
                                <%= this.publicId %>\
                            </span>\
                            <span class="bet-status <%= this.betStatus %>">\
                                <div class="container">\
                                    <span class="status-btn">\
                                        <%= raceBetsJS.i18n.data["betStatus" + this.betStatus] %>\
                                    </span>\
                                </div>\
                            </span>\
                        </a>\
                    </li>\
                <% }); %>\
            </ul>\
        </div>\
    ');
})(raceBetsJS);
