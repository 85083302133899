/**
* OddsButton
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
    raceBetsJS.application.content.race.OddsButton = Class.extend({
        /**
        * init
        *
        * Constructor of the OddsButton class
        */
        init: function(elem, category) {
            // cache dom references
            this.dom = {
                button: elem,
                span: elem.children('span')
            };

            this.category = category;

            // @todo: find other way to get language
            this.oddsFormat = raceBetsJS.application.assets.settings.get().dialog.general.oddsFormat;

            // observer on button
            // @todo: how to use a global delegator instead?
            if (this.oddsFormat == 'fractional' && this.category == 'PRC') {
                this.dom.button.on('mouseover', $.proxy(this.onMouseOver, this));
                this.dom.button.on('mouseout', $.proxy(this.onMouseOut, this));
            }
        },

        /**
        * enable
        *
        * Enables the odds button
        */
        enable: function() {
            this.dom.button.addClass('enabled');
        },

        /**
        * disable
        *
        * Disables the odds button
        */
        disable: function() {
            this.dom.button.removeClass('enabled');
        },

        /**
        * scratch
        *
        * Sets the odds button to scratched
        */
        scratch: function() {
            if (this.dom.button.hasClass('non-runner')) {
                return;
            }

            this.dom.button
                .removeClass('enabled favourite')
                .addClass('non-runner');

            this.dom.span
                .removeData('odds')
                .html(
                    (this.dom.button.parents('tr').hasClass('reserve') ? '-' : raceBetsJS.i18n.data.labelScratchedButton)
                )
                .attr('title', raceBetsJS.i18n.data.labelScratched);
        },

        /**
        * setFavourite
        *
        * Sets the odds button to the favourite
        */
        setFavourite: function() {
            this.dom.button.addClass('favourite');
        },

        /**
        * unsetFavourite
        *
        * Removes the favourite style from the odds button
        */
        unsetFavourite: function() {
            this.dom.button.removeClass('favourite blink');
        },

        /**
        * setOdds
        *
        * Set value of odds in button
        */
        setOdds: function(odds, enabled, hover) {
			 if (odds > 0) {
                this.dom.span.data('odds', odds).html(raceBetsJS.format.odds(odds, ((this.oddsFormat == 'fractional' && this.category == 'FXP') ? 'base1' : this.oddsFormat), true));
                this.dom.button.removeClass('large');

            } else if (this.category == 'PRC' && enabled) {
                this.dom.span.removeData('odds').html(
                    (this.oddsFormat == 'fractional') ? 'SP' : raceBetsJS.i18n.data.buttonBet
                );
                this.dom.button.addClass('large');

            } else {
                this.dom.span.removeData('odds').html('-');
                this.dom.button.removeClass('large');
            }

            //Add class if contents of buttons are too big
			if(this.dom.span.html().toString('.', '').replace(',', '').length > 5) {
				this.dom.button.addClass('large');
        	} else {
				this.dom.button.removeClass('large');
            }
        },

        onMouseOver: function() {
            if (this.oddsFormat != 'fractional' || this.category != 'PRC' || !this.dom.button.hasClass('enabled')) {
                return;
            }

            this.dom.span.html('SP');
        },

        onMouseOut: function() {
            if (this.oddsFormat != 'fractional' || this.category != 'PRC' || !this.dom.button.hasClass('enabled') || !this.dom.span.data('odds')) {
                return;
            }

            this.setOdds(this.dom.span.data('odds'), this.dom.button.hasClass('enabled'), true);
        },

        /**
        * update
        *
        * Update the odds button
        */
        update: function(odds, enabled) {

            if (this.dom.button.hasClass('non-runner')) {
                this.dom.button.removeClass('non-runner');
                this.dom.span.removeAttr('title');
            }

            if (odds != this.dom.span.data('odds')) {
                this.setOdds(odds, enabled);
            }

            if (enabled) {
                this.enable();
            } else {
                this.disable();
            }
        }
    });
})(raceBetsJS);
