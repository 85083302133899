/**
* Interval class
*
* @description Wrapper for setInterval.
*
* @author Moritz Honig
*
*/
(function(raceBetsJS) {
	raceBetsJS.time.Interval = Class.extend({
		init: function(options) {
            this.interval = false;
			this.options = options || {};
		},

		start: function() {
            if (this.interval) {
                return;
            }

			this.interval = setInterval(this.options.tick, this.options.interval);
		},

		stop: function() {
			clearInterval(this.interval);
            this.interval = false;
		},

        getInterval: function() {
            return this.options.interval;
        },

        setInterval: function(newInterval) {
            var restart = false;

            if (this.interval) {
                this.stop();
                restart = true;
            }

            this.options.interval = newInterval;

            if (restart) {
                this.start();
            }
        },

        tick: function() {
            this.options.tick();
        }
	});
})(raceBetsJS);