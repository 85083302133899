/**
* New Formguide Search
*
* @author Javier Cobos Mesa
*/

(function(raceBetsJS) {
    raceBetsJS.application.content.newFormGuide = function() {
        // @private

        function onContentLoaded() {

            var $DOMContainer = $('#new-horse-search-container');
            var searchInProgress = false;

            $DOMContainer.html(raceBetsJS.application.templates.formguide.horseSearch.base());

            var $DOMElements = {
                input : $('#formguide-search-opponent-input'),
                guide : $DOMContainer.find('.input-wrapper'),
                cross : $DOMContainer.find('span.cross'),
                templateCont : $DOMContainer.find('.new-horse-search-container'),
                boxNoResults : $('#box-noresults'),
                searchBoxCont : $('#search-box-cont'),
                loading : $DOMContainer.find('div.loading'),
                button : $('#search-button'),
                searchResultsList : $('#search-results'),
                queryField: $('#search-query'),
                infoBox: $('#infoBox')
            };

            // Autocomplete dropdown
            ($DOMElements.input).autoCompDrop({
                liContentTemplate : raceBetsJS.application.templates.autoCompleteDrop.liTemplates.runner,
                searchParamName : "s",
                requestURL : '/ajax/formguide/searchAc',
                guideElement : $DOMElements.guide,
                onFailMsg : raceBetsJS.i18n.data.labelNothingFoundSearch,
                cross : $DOMElements.cross,
                onEnter : function() {
                    search($DOMElements.input.val());
                },
                extraClass : 'extra',
                onSelect : function(_data){
                    var query = _data.name;
                    $DOMElements.input.val(query);
                    search(query);
                }
            });


            function search(query) {

                if(query == '') { return false; }

                searchInProgress = true;
                $DOMElements.searchBoxCont.hide();
                $DOMElements.boxNoResults.hide();
                $DOMElements.loading.show();
                $DOMElements.infoBox.hide();

                $.ajax({
                    url: "/ajax/formguide/search", // @todo correct ajax url
                    type: 'get',
                    data: {
                        s : query
                    },
                    complete: function() {
                        searchInProgress = false;
                        $DOMElements.loading.hide();
                    },
                    success: function(_data) {

                        var first,popup;

                        if((!$.isEmptyObject(_data)) && (!$.isEmptyObject(_data.subjects))){

                            if(_data.subjects.length == 1){

                                first = _data.subjects[0];

                                popup = raceBetsJS.browser.popup.open({
                                    href: raceBetsJS.application.globals.urlPrefix + '/formguide/overview/id/' + first.idSubject,
                                    name: first.idSubject,
                                    height: 725,
                                    width: 840,
                                    scroll: 'no',
                                    resizable: 'yes'
                                });
                            }

                            $DOMElements.queryField.html(query);
                            $DOMElements.searchBoxCont.show();
                            $DOMElements.searchResultsList.html(raceBetsJS.application.templates.formguide.horseSearch.searchResults({data:_data}));

                            if( parseInt(_data.total) > parseInt(_data.subjects.length) ) {

                                $DOMElements.infoBox.html(raceBetsJS.i18n.print('textRedefineSearch',{total:_data.total,results:_data.subjects.length}));
                                $DOMElements.infoBox.show();
                            }

                        }else {
                            $DOMElements.boxNoResults.show();
                            $DOMElements.searchBoxCont.hide();

                        }
                    }
                });


            }


            // events
            $DOMElements.button.on("click",function(e){
                e.preventDefault();
                search($DOMElements.input.val());

            });

            // list popup
            $DOMElements.searchResultsList.on('click','tr',function(e){
                e.preventDefault();
                var $this = $(this);
                var popup = raceBetsJS.browser.popup.open({
                    href: raceBetsJS.application.globals.urlPrefix + '/formguide/overview/id/' + $this.data('idsubject'),
                    name: $this.data('idsubject'),
                    height: 725,
                    width: 840,
                    scroll: 'no',
                    resizable: 'yes'
                });
            });


        }

        // @public
        return {
            init: function() {
                // add content loaded observer
                raceBetsJS.application.contentController.addCallback(/^\/formguide\/?$/, onContentLoaded);
            }
        };
    }();
})(raceBetsJS);
