/**
* Bet details dialog
*
* @author Robin Ebers <robin.ebers@gmail.com>
* @author Andras Matzon <matzon@racebets.com>
*/

(function(raceBetsJS) {
    raceBetsJS.application.assets.betDetails = function() {
        // default options
        var defaultOptions = {
            id: null,
            type: 'dlg-bet-details',
            width: 590,
            title: '',
            content: '',
            buttonAlign: 'right'
        };


        var show = function(options) {
            var options = $.extend({}, defaultOptions, options || {}),
                $dom,
                $domTooltip,
                $dlgBetDetails,
                $myBetsDetails,
                myBetsMaxHeight = 0;

            var showModalDialog = function(data) {
                // cache tote currencies if needed
                if (data.betDetails.currency !== raceBetsJS.application.user.currency) {
                    data.betDetails.unitStakeTote = raceBetsJS.localize.exchange(data.betDetails.unitStake, data.betDetails.currency, raceBetsJS.application.user.currency);
                    data.betDetails.totalStakeTote = raceBetsJS.localize.exchange(data.betDetails.totalStake, data.betDetails.currency, raceBetsJS.application.user.currency);
                }

                $dom = $(raceBetsJS.application.templates.betDetails(data));

                // title set or take default?
                if (options.title) {
                    var betDetailsTitle = options.title;

                } else {
                    /*
                    For reference:
                    > I didn't see any clear pattern here, so I implemented it "more freely". (rob, 11/04/2014)
                    100€ Win | Ante-Post | Single
                    100€ Win | Ante-Post | Multiple
                    100€ Special | Ante-Post
                    100€ Head-to-Head | Single
                    $2 Pick 3 | Tote
                    100€ Special
                    100€ Swinger | Tote
                    100€ Win | Single
                    100€ Win | Tote
                    100€ Superfecta | Tote
                    100€ Multiple | Trebles
                    */

                    // prepare some variables upfront as we'll most likely re-use them
                    var betDetailsTitle = raceBetsJS.format.money(data.betDetails.unitStake, 2, raceBetsJS.application.user.currency, true, false, false, true),
                        betTypeName = raceBetsJS.format.betTypeName(data.betDetails.betType, data.betDetails.betCategory, raceBetsJS.application.user.lang, (data.betDetails.currency === "USD"), data.betDetails.country);

                    // building title
                    if (data.betDetails.specialBetType === 'H2H') {
                        // if head-2-head, add identifier
                        betDetailsTitle += ' ' + raceBetsJS.i18n.data.labelHead2Head;

                    } else if (data.betDetails.isSpecialBets === true) {
                        // if special, add identifier
                        betDetailsTitle += ' ' + raceBetsJS.i18n.data.labelSpecial;

                    } else if (data.betDetails.betslipType === 'ACC' && data.betDetails.betCategory !== 'TOT') {
                        // count legs etc, add system
                        if (data.betDetails.accSystem === 'C' || data.betDetails.accSystem === 'F') {
                            betDetailsTitle += ' | ' + raceBetsJS.i18n.data['system' + data.betDetails.legs.length + data.betDetails.accSystem];
                        } else if (raceBetsJS.format.isPickBetType(data.betDetails.betType)) {
                            betDetailsTitle += ' | ' + raceBetsJS.format.betTypeName(data.betDetails.betType, data.betDetails.betCategory, raceBetsJS.application.user.lang, (data.betDetails.currency === "USD"), data.betDetails.country);
                        } else {
                            betDetailsTitle += ' | ' + raceBetsJS.i18n.data['system' + data.betDetails.legs.length + 'X'];
                        }

                    } else {
                        // add only bet type in other situations
                        betDetailsTitle += ' ' + betTypeName;
                    }

                    // if ante-post, add identifier
                    if (data.betDetails.featured === true) {
                        betDetailsTitle += ' | ' + raceBetsJS.i18n.data.labelAntePost;
                    }

                    // add single or multiple
                    if (!raceBetsJS.format.isPickBetType(data.betDetails.betType)) {
                        betDetailsTitle += ' | ' + raceBetsJS.i18n.data['label' + (data.betDetails.betslipType === 'ACC' ? 'Multiple' : 'Single')];
                    }

                    // if tote, add identifier
                    if (data.betDetails.betCategory === 'TOT') {
                        betDetailsTitle += ' | ' + raceBetsJS.i18n.data.betCategoryTOT;
                    }

                }

                // close previous overlay if exists
                //raceBetsJS.application.assets.overlay.close();

                // show modal dialog with bet details
                raceBetsJS.application.assets.modalDialog.show({
                    type: options.type,
                    title: betDetailsTitle,
                    backgroundClose: true,
                    buttons: false,
                    content: $dom,
                    width: data.betDetails.betType === 'QNP' ? 650 : options.width,
                    buttonAlign: options.buttonAlign
                });

                $dlgBetDetails = $('.' + options.type);
                $myBets = $dlgBetDetails.find('.my-bets');
                $myBetsDetails = $dlgBetDetails.find('.my-bets-details');
                $dlgBetDetails.find('.title').addClass(data.betDetails.betStatus);

                setMyBetsMaxHeight();
                initEvents();
            };

            var setMyBetsMaxHeight = function() {
                var $contentNotScrolled = $dlgBetDetails.find('.title, .my-bets-options, .my-bets-summary, .my-bets-footer'),
                    windowHeight = $(window).height(),
                    dlgMargin = ( $('.' + options.type).offset().top - $(window).scrollTop() ) * 1.5,
                    contentNotScrolledHeight = 0;

                $.each($contentNotScrolled, function() {
                    contentNotScrolledHeight += $(this).outerHeight();
                });

                myBetsMaxHeight = Math.max(windowHeight - dlgMargin - contentNotScrolledHeight, 100);

                $myBets.css({
                    'max-height': myBetsMaxHeight
                });

                if ($myBetsDetails.outerHeight() > myBetsMaxHeight) {
                    $dlgBetDetails.addClass('scroll');
                }
            };

            var initEvents = function() {
                var $transactionButton = $dlgBetDetails.find('.toggle-transactions'),
                    $transactions = $dlgBetDetails.find('.my-bets-transactions'),
                    $transactionTable = $transactions.find('.bet-details-transactions');

                $dom.on('click', '.dlg-bet-details .toggle-transactions', function(e) {
                    e.preventDefault();
                    var newMyBetsHeight = $transactionTable.outerHeight() + $myBetsDetails.outerHeight();

                    if ($transactions.hasClass('open')) {
                        if ( myBetsMaxHeight > $myBetsDetails.outerHeight() ) {
                            $dlgBetDetails.removeClass('scroll');
                        }
                        $transactions
                            .stop().animate({
                                'max-height': 0
                            }, function() {
                                $(this).toggleClass('open');
                                $transactionButton.children('.label').text( raceBetsJS.i18n.data.labelShowTransactions );
                                $myBets.animate({
                                    scrollTop: 0
                                }, 'fast');
                            });
                    } else {
                        if (myBetsMaxHeight < newMyBetsHeight) {
                            $dlgBetDetails.addClass('scroll');
                        }
                        $transactionButton.children('.label').text( raceBetsJS.i18n.data.labelHideTransactions );
                        $transactions
                            .stop().animate({
                                'max-height': 1000
                            }, function() {
                                $(this).toggleClass('open');
                                $myBets.animate({
                                    scrollTop: $transactions.position().top
                                }, 'slow');
                            });

                    }
                });

                // Tipped.create($dom.find('.exchange-rate'), function(elem) {
                //     return raceBetsJS.application.templates.betDetails.tooltipExchangeRate({
                //         amount: data.betDetails.totalStake,
                //         exchangeRate: data.betDetails.exchangeRate
                //     });
                // }).refresh();

                // Tooltip for channel
                Tipped.create($dom.find('.tipped.mobile'), $(this).data('tipped'), { hook: 'topmiddle' } ).refresh();

                // Tooltip for special event details
                Tipped.create($dom.find('.bet-details-special .tipped, .bet-details-antepost-special .tipped'), $(this).data('tipped'), { hook: 'bottommiddle' } ).refresh();

                // Tooltip for total stake exchange rate
                Tipped.create($dom.find('.tipped.exchange-rate'), $dom.find('.my-bets-exchange-rate > th').html() ).refresh();

                // Tooltip for race info
                $.each($('.dlg-bet-details .race .tipped'), function() {
                    Tipped.create( $(this), $(this).next('.my-bets-rules').html(), { skin: 'bet-details', hook: 'rightmiddle' } ).refresh();
                });

                // Print version
                $dom.on('click', '.dlg-bet-details .bet-sharing .print', function(e) {
                    e.preventDefault();
                    $('html').addClass('print print-bet-details');
                    window.print();
                });
            };

            $.ajax({
                type: 'get',
                url: '/ajax/bets/details/id/' + options.id,
                beforeSend: function() {
                    // show "please wait"
                    raceBetsJS.application.assets.overlay.showPleaseWait();
                },
                success: function(data) {
                    // close "please wait"
                    raceBetsJS.application.assets.overlay.close();

                    // init modal dialog
                    showModalDialog(data);
                }
            });
        }

        return {
            show: show
        }
    }();
})(raceBetsJS);
