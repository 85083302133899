/**
* @description media player
*
*/
(function(raceBetsJS) {
    /**
    * Media player template
    */
    raceBetsJS.application.templates.media = _.template(
        '<div id="media-browser" class="clearfix">' +
            '<div class="panel">' +
                '<div class="header_container">' +
                    '<h2 class="label"><%= raceBetsJS.i18n.data.navStreams %></h2>' +
                    '<span class="close"></span>' +
                '</div>' +
                '<div class="content_container">' +
                    '<div class="video">' +
                        '<div id="stream-content"></div>' +
                    '</div>' +
                    '<div class="stream-options">' +
                        '<div class="streams">' +
                            '<div class="select">' +
                                '<span class="c-flag"></span>' +
                                '<span class="label"><%= raceBetsJS.i18n.data.labelSelectStream %></span>' +
                            '</div>' +
                            '<ul class="options" style="display: none"></ul>' +
                        '</div>' +
                        '<div class="additives upcoming-races" style="display: none">' +
                            '<h2><%= raceBetsJS.i18n.data.headNextRaces %></h2>' +
                        '</div>' +
                        '<div class="additives race-results" style="display: none">' +
                            '<h2><%= raceBetsJS.i18n.data.headResult %></h2>' +
                            '<ul class="has-silks"></ul>' +
                        '</div>' +
                        '<div class="toolbar">' +
                            '<span class="icon pause" data-action="pause" data-tipped="<%= raceBetsJS.i18n.data.tipMediaPause %>"></span>' +
                            '<span class="icon quality" data-action="quality" data-tipped="<%= raceBetsJS.i18n.data.tipMediaQuality %>"></span>' +
                            '<span class="icon sticky" data-action="sticky" data-tipped="<%= raceBetsJS.i18n.data.tipMediaSticky %>"></span>' +
                            '<span class="icon popout last" data-action="popup" data-tipped="<%= raceBetsJS.i18n.data.tipMediaPopup %>"></span>' +
                        '</div>' +
                    '</div>' +
                    '<div class="intro"><%= raceBetsJS.i18n.data.textInitialStream %></div>' +
                '</div>' +
            '</div>' +
        '</div>'
    );

    /**
    * Media HTML5 player template
    */
    raceBetsJS.application.templates.media.html5 = _.template(
        '<video id="videojs" class="video-js" preload="auto" width="512" height="288" controls autoplay="true">' +
            '<source></source>' +
        '</video>'
    );

    raceBetsJS.application.templates.media.banner = _.template(
        '<div class="icon-banner video-bonus">' +
            '<div class="icon-banner-content">' +
                '<span class="banner-icon"></span>' +
                '<span class="title"><%= raceBetsJS.i18n.print("textStreamsPromotion", {"bonusAmount": "10 Euro"}) %></span>' +
                '<button type="button" data-href="<%= raceBetsJS.application.globals.urlPrefix + "/register" %>" class="btn btn-primary btn-lg"><%= raceBetsJS.i18n.data.buttonStreamsPromotion %></button>' +
            '</div>' +
        '</div>'
    );

})(raceBetsJS);
