/**
* @description Bonuses
*/
(function(raceBetsJS) {
    raceBetsJS.application.templates.bonuses = _.template('\
        <div id="section-bonuses" class="bonuses <%=  (_.size(data.pendingOffers) < 1 ? "no-bonuses" : "") %> <%=  (_.size(data.freebets) < 1 ? "no-free-bets" : "") %>">\
            <div class="c-pageHeader">\
                <h1 class="c-pageHeader__title">\
                    <%= raceBetsJS.i18n.data.titlePendingOffers %>\
                    <% if (data.showHelpLink) { %>\
                        <a href="<%= data.helpLink %>" class="ajaxify right hiw">\
                            <button class="btn has-icon btn-primary">\
                                <span class="icon bulb"></span>\
                                <span class="label"><%= raceBetsJS.i18n.data.howItWorks %></span>\
                            </button>\
                        </a>\
                    <% } %>\
                </h1>\
                <span class="c-icon c-icon--dt isBonus"></span>\
            </div>\
            <% if(data.currentBonus && !$.isEmptyObject(data.currentBonus.chunk)){ %>\
                <div id="active-bonus" class="box box-primary margin-bottom current clearfix" style="position: relative">\
                    <%= raceBetsJS.application.templates.bonuses.currentBonus({ data: data })%>\
                </div>\
            <% } %>\
        <div id="pending-offers" class="box box-primary pending clearfix">\
            <h3 class="header">\
                <%= raceBetsJS.i18n.data.headPendingBonuses %>\
                <% if ((_.size(data.pendingOffers) > 0 || _.size(data.freebets) > 0) && raceBetsJS.application.user.noBonus === false) { %>\
                    <ul class="tabs">\
                        <li data-tab="bonuses" class="<%=  (_.size(data.pendingOffers) < 1 ? "disabled" : "") %>">\
                            <%= raceBetsJS.i18n.data.tabBonuses %>\
                            &nbsp; <span class="notify-tag <%=  (_.size(data.pendingOffers) > 0 ? "orange" : "") %>"><%= _.size(data.pendingOffers) %></span>\
                        </li>\
                        <li data-tab="freebets" class="<%=  (_.size(data.freebets) < 1 ? "disabled" : "") %>">\
                            <%= raceBetsJS.i18n.data.tabFreeBets %>\
                            &nbsp; <span class="notify-tag <%=  (_.size(data.freebets) > 0 ? "orange" : "") %>"><%= _.size(data.freebets) %></span>\
                        </li>\
                    </ul>\
                <% } %>\
            </h3>\
            <div class="content">\
                <% if (typeof data.unfinishedVerification !== "undefined") { %>\
                    <div class="mobile-verification"><%= raceBetsJS.i18n.data.msgEnterMobileCode %></div>\
                <% } %>\
                <% if (data.displayBonusBox === true) { %>\
                    <div class="sub-header">\
                        <div class="claim-panel">\
                            <form id="form-deposit-bonus" action="/ajax/deposit/bonus" method="post" class="formatted">\
                                <input type="text" class="c-input form-control" id="bonusCodeInput" name="bonusCode" placeholder="<%= raceBetsJS.i18n.data.placeholderEnterBonusCode %>" />\
                                <button class="c-btn c-btn--primary has-icon" id="bonusCodeButton">\
                                    <%= raceBetsJS.i18n.data.buttonGetBonus %>\
                                    <span class="icon arrow-right"></span>\
                                </button>\
                            </form>\
                            <%= raceBetsJS.i18n.data.labelBonusHint %>\
                        </div>\
                        <% if (_.size(data.pendingOffers) > 0) { %>\
                            <div class="redeem-panel">\
                                <span class="arrow"></span>\
                                <button id="activateBonusButton" class="btn has-icon" disabled="disabled">\
                                    <span class="label"><%= raceBetsJS.i18n.data.buttonRedeemBonus %></span>\
                                    <span class="icon arrow-right"></span>\
                                </button>\
                                <span class="error"></span>\
                                <span class="hint">\
                                    <% if (data.activeBonus) { %>\
                                        <%= raceBetsJS.i18n.data.labelBonusActive %>\
                                    <% } else if (data.pendingOffers.length > 0) { %>\
                                        <%= raceBetsJS.i18n.data.labelSelectBonusBelow %>\
                                    <% } else { %>\
                                        <%= raceBetsJS.i18n.data.labelNoPendingActive %>\
                                    <% } %>\
                                </span>\
                            </div>\
                        <% }%>\
                    </div>\
                    <div class="inner">\
                    </div>\
                <% } else { %>\
                    <div class="sub-header no-bonus">\
                        <%= raceBetsJS.i18n.data.msgNoBonus %>\
                    </div>\
                <% } %>\
            </div>\
        </div>\
    ');

    raceBetsJS.application.templates.bonuses.tabBonuses = _.template('\
        <table>\
            <colgroup>\
                <% if (!data.activeBonus) { %>\
                    <col width="35" />\
                <% } %>\
                <col width="100" />\
                <col width="auto" />\
                <col width="120" />\
                <col width="120" />\
            </colgroup>\
            <thead>\
                <tr>\
                    <% if (!data.activeBonus) {%>\
                        <td>&nbsp;</td>\
                    <% } %>\
                    <td><%= raceBetsJS.i18n.data.labelDate %></td>\
                    <td><%= raceBetsJS.i18n.data.labelDescription %></td>\
                    <td><%= raceBetsJS.i18n.data.labelExpiryDate %> <span class="icon info expiry-date"></span></td>\
                    <td class="last"><%= raceBetsJS.i18n.data.labelAmount %></td>\
                </tr>\
            </thead>\
            <tbody id="pendingBonuses">\
                <% $.each(data.pendingOffers,function(index,bonus){ %>\
                    <% var zebraClass = (index%2 == 0) ? "odd" : ""; %>\
                    <tr class="<%= zebraClass %>" id="pending-bonus-<%= bonus.idBonus %>">\
                        <% if(!data.activeBonus){%>\
                            <td>\
                                <input data-amount="<%= bonus.amount %>" type="checkbox"\
                                       data-combinable="1"\
                                       data-mobile-verification="<%= bonus.mobileVerificationReq %>"\
                                       data-operation-detail="<%= bonus.operationDetail %>"\
                                       value="<%= bonus.idBonus %>" />\
                            </td>\
                        <% } %>\
                        <td><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.formatDateLong, bonus.creationStamp) %></td>\
                        <td>\
                            <%= raceBetsJS.application.content.bonuses.getBonusDescription(bonus) %>\
                            <span data-cshTurnover="<%= bonus.cshTurnover || 0 %>" data-actturnover="<%= bonus.actTurnover || 0 %>" class="icon conditions"></span>\
                        </td>\
                        <td><%= (bonus.expirationStamp !== 0) ? raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.formatDateLong, bonus.expirationStamp) : "" %></td>\
                        <td class="last"><%= raceBetsJS.format.money(bonus.amount) %> <%= raceBetsJS.application.user.currency %></td>\
                    </tr>\
                <% }); %>\
            </tbody>\
        </table>\
    ');

    raceBetsJS.application.templates.bonuses.tabFreebets = _.template('\
        <table>\
            <colgroup>\
                <col width="auto" />\
                <col width="120" />\
                <col width="120" />\
                <col width="120" />\
            </colgroup>\
            <thead>\
                <tr>\
                    <td><%= raceBetsJS.i18n.data.labelDescription %></td>\
                    <td><%= raceBetsJS.i18n.data.labelBonusCode %></td>\
                    <td><%= raceBetsJS.i18n.data.labelExpiryDate %> <span class="icon info expiry-date"></span></td>\
                    <td class="last"><%= raceBetsJS.i18n.data.labelAmount %></td>\
                </tr>\
            </thead>\
            <tbody id="pendingFreeBets">\
                <% $.each(data.freebets,function(index, bonus) { %>\
                    <% var zebraClass = (index%2 == 0) ? "odd" : ""; %>\
                    <tr class="<%= zebraClass %>" data-id="<%= bonus.idBonus %>">\
                        <td>\
                            <%= raceBetsJS.application.content.bonuses.getBetDescription(bonus) %>\
                        </td>\
                        <td><%= (typeof bonus.promotionCode !== "undefined" ? bonus.promotionCode : "-") %></td>\
                        <td><%= (parseInt(bonus.expirationStamp) > 0) ? raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDate, bonus.expirationStamp) : "-" %></td>\
                        <td class="last"><%= raceBetsJS.format.money(bonus.amount) %> <%= raceBetsJS.application.user.currency %></td>\
                    </tr>\
                <% }); %>\
            </tbody>\
        </table>\
    ');

    raceBetsJS.application.templates.bonuses.currentBonus = _.template('\
        <h3 class="header">\
            <% if (data.currentBonus && !$.isEmptyObject(data.currentBonus.chunk)) { %>\
                <% var currentBonus = data.currentBonus.bonuses[0]; %>\
                <% if (data.currentBonus.bonuses.length > 1) { %>\
                    <%= raceBetsJS.i18n.print("labelXBonuses", { num: data.currentBonus.bonuses.length }) %>\
                    <span class="icon info bonus-description"></span>\
                <% } else { %>\
                    <%= raceBetsJS.application.content.bonuses.getBonusDescription(currentBonus) %>\
                <% } %>\
                <% var timeLeft = (data.currentBonus.chunk.expirationStamp - ($.now() / 1000)) / 86400; %>\
                <em>\
                    <%= raceBetsJS.i18n.data["bonusStatus" + currentBonus.bonusStatus] %>\
                    <% if ($.inArray(currentBonus.bonusStatus, ["ACT", "SEL"]) > -1) { %>\
                        <% if (timeLeft > 0 && timeLeft <= 1) { %>\
                            - <%= raceBetsJS.i18n.labelExpiresToday %>\
                        <% } else if (timeLeft > 0) { %>\
                            - <%= raceBetsJS.i18n.print("labelExpiresInXDays", { days: Math.floor(timeLeft) }) %>\
                        <% } %>\
                    <% } %>\
                </em>\
            <% } else { %>\
                <%= raceBetsJS.i18n.data.headNoPendingOffers %>\
            <% } %>\
            <% if(!$.isEmptyObject(data.previousBonuses)){ %>\
                <div id="bonus-history">\
                    <span class="switcher"><%= raceBetsJS.i18n.data.labelMoreBonuses %><span class="icon"></span></span>\
                </div>\
            <% } %>\
        </h3>\
        <div class="content">\
            <div class="bonus-history-list">\
                <ul>\
                    <% if(!$.isEmptyObject(data.previousBonuses)){ %>\
                        <% $.each(data.previousBonuses,function(index,oldBonus){ %>\
                            <li data-id-bonus-chunk="<%= oldBonus.idBonusChunk %>" class="<%= (index % 2 == 0) ? "odd" : "even" %>">\
                                <% if (oldBonus.bonusesCount > 1) { %>\
                                    <span class="name"><%= raceBetsJS.i18n.print("labelXBonuses", { num: oldBonus.bonusesCount }) %></span>\
                                <% } else { %>\
                                    <span class="name"><%= raceBetsJS.application.content.bonuses.getBonusDescription(oldBonus) %></span>\
                                <% } %>\
                                <span class="desc"><%= raceBetsJS.format.currencySymbols[raceBetsJS.application.user.currency] %><%= raceBetsJS.format.money(oldBonus.amount,2)%>,\
                                    <%= raceBetsJS.i18n.data["bonusStatus" + oldBonus.chunkStatus] %>\
                                </span>\
                            </li>\
                        <% }); %>\
                    <% }; %>\
                </ul>\
            </div>\
            <table id="active-bonus-table">\
                <colgroup>\
                    <col width="125">\
                </colgroup>\
                <% var chunk = data.currentBonus.chunk; %>\
                    <tr class="active" id="chunk-<%= chunk.idBonusChunk %>">\
                        <td>\
                            <%= raceBetsJS.format.money(chunk.amount,2) %> <%= raceBetsJS.application.user.currency %><br />\
                            <% if(parseFloat(chunk.remainingCshTurnover) < parseFloat(chunk.cshTurnover)) { %>\
                                <a class="see-details" data-idBonusChunk="<%= chunk.idBonusChunk %>" href="#">― <%= raceBetsJS.i18n.data.labelSeeDetails %> ―</a>\
                            <% } %>\
                        </td>\
                        <td class="progress-bar-container">\
                            <div class="progress-bar-area">\
                            </div>\
                        </td>\
                    </tr>\
            </table>\
        </div>\
    ');

    raceBetsJS.application.templates.bonuses.progressBar = _.template('\
        <div id="<%= prefix %>-bar" class="<%= type %>-bar">\
            <div class="gray-bar">\
                <div class="green-bar"></div>\
            </div>\
            <div class="gray-bubble"></div>\
            <div class="green-bubble"><div class="white-tick"></div></div>\
            <div class="sub-label"></div>\
            <div class="top-label"></div>\
        </div>\
    ');


    raceBetsJS.application.templates.bonuses.detailedTable = _.template('\
        <table class="bonus-detail-table-head">\
            <tbody>\
                <tr>\
                    <td class="left"><%= raceBetsJS.i18n.data.labelDate %></td>\
                    <td class="left"><%= raceBetsJS.i18n.data.labelId %></td>\
                    <td><%= raceBetsJS.i18n.data.labelStake %></td>\
                    <td><%= raceBetsJS.i18n.data.labelAmount %></td>\
                    <td><%= raceBetsJS.i18n.data.labelAccumulated %></td>\
                </tr>\
            </tbody>\
        </table>\
        <div class="bonus-detail-table-container">\
            <table class="bonus-detail-table">\
                <tbody>\
                    <% $.each(data.chunks,function(index,chunk) { %>\
                    <% var zebraClass = (index%2 == 0) ? "odd" : ""; %>\
                    <tr class="<%= zebraClass %>">\
                        <td class="left"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.formatDateLong, chunk.stamp) %></td>\
                        <td class="left"><a href="/" data-public-id="<%= chunk.publicId %>"><%= chunk.publicId %></a></td>\
                        <td><%= raceBetsJS.format.money(chunk.transactionTotal, 2, raceBetsJS.application.user.currency) %></td>\
                        <td><%= raceBetsJS.format.money(chunk.amountCounted, 2, raceBetsJS.application.user.currency) %></td>\
                        <td><%= raceBetsJS.format.money(chunk.sumAmountCounted, 2, raceBetsJS.application.user.currency) %></td>\
                    </tr>\
                    <% }); %>\
                </tbody>\
            </table>\
        </div>\
    ');


})(raceBetsJS);
