/**
* @description home template
*
*/
(function(raceBetsJS) {
    raceBetsJS.application.templates.autoCompleteDrop = {};

    raceBetsJS.application.templates.autoCompleteDrop.base = _.template(
        '<div class="auto-complete-dropdown-container <%= extraClass %>">' +
            '<ul></ul>' +
        '</div>'
	);

    raceBetsJS.application.templates.autoCompleteDrop.liTemplates = {};

    raceBetsJS.application.templates.autoCompleteDrop.liTemplates.runner = _.template(
        '<li class="runner"><span class="repalable-field"><%= runner.name %></span></li>'
    );

    raceBetsJS.application.templates.autoCompleteDrop.liTemplates.label = _.template(
        '<li class="runner"><span class="repalable-field"><%= label %></span></li>'
    );

})(raceBetsJS);
