/**
* Right Column
*/
(function(raceBetsJS) {
	raceBetsJS.application.assets.rightColumn = function() {
		// @private


        // @public

        var init = function() {
		    var rightCol = raceBetsJS.application.globals.contentMain.find('div.columns.two-column div.column.right');
            if(rightCol.length && !rightCol.find('*').length) {
                var sidebarWidgets = {};
                $.getJSON(
                    raceBetsJS.application.content.getAjaxCacheUrl('/ajax/home/content/version/sidebar'),
                    function(data){

                        sidebarWidgets.H2H = raceBetsJS.application.content.home.head2head.init(data.nextH2H);
                        if (raceBetsJS.application.content.home.links) {
                            sidebarWidgets.links = raceBetsJS.application.content.home.links.init();
                        }
                        sidebarWidgets.topRatio = raceBetsJS.application.content.home.topRatio.init(data.topRatio);
                        sidebarWidgets.topWinnings = raceBetsJS.application.content.home.topWinnings.init(data.topWinnings);

                        $.each(
                            sidebarWidgets,
                            function(key, value){
                                if(value){
                                    this.remove();
                                    this.render(rightCol);
                                }
                            }
                        );
                    }
                )
            }
        };

		return {
			init: init
		};
	}();
})(raceBetsJS);
