/**
* Content module.
*
* @description: Initializes all content modules
*
* @author: Moritz Honig
* @author: Robin Ebers
*/
(function(raceBetsJS) {
	raceBetsJS.application.content = function() {
		var onBeforeUnload = new raceBetsJS.events.Event();

		// @public
		return {
            hideSidebar : function(hide){
                var $body = $("body");
                if(hide)  {
                    $body.addClass("hideSidebar");
                } else {
                    $body.removeClass("hideSidebar");
                }
            },
            hideRightbar: function (hide) {
                if (hide) {
                    $('body').addClass('hideRightbar');
                } else {
                    $('body').removeClass('hideRightbar');
                }
            },
            toggleFantasy: function (isFantasy) {
                if (isFantasy) {
                    $('#wrapper').addClass('isFantasy');
                } else {
                    $('#wrapper').removeClass('isFantasy');
                }
            },
			init: function() {
                this.initValidator();
				this.initContentController();
				this.initContentModules();
				this.initPage();
				this.addBrowserCssHelper();
                this.initDatepicker();

				// Navigate to current page, if dynamic
				//var currentPageURL = raceBetsJS.browser.history.getCurrentPageURL();
                var currentPageURL = window.location.pathname;

				if (raceBetsJS.application.contentController.isDynamic(currentPageURL)) {
                    raceBetsJS.application.contentController.navigate(currentPageURL)
                } else {
                    raceBetsJS.application.contentController.triggerCallback(currentPageURL);
					raceBetsJS.application.header.navigation.activateItemByURL(currentPageURL);
				}

                raceBetsJS.application.initDone = true;

                // message parent window when ready
                if (raceBetsJS.application.globals.isB2B) {
                    setTimeout(function(){
                        raceBetsJS.browser.crossFrame.send('ready');
                        raceBetsJS.browser.crossFrame.send('height', $('body').height());
                    }, 1000);
                }
			},

			addBrowserCssHelper: function() {

		        var userAgent = $.browser.version.substring(0,$.browser.version.indexOf('.'));
		        var version = userAgent;

                //Jquery fail to detect IE 11
                //https://stackoverflow.com/questions/18684099/jquery-fail-to-detect-ie-11
                if ($.browser.msie || !!navigator.userAgent.match(/Trident\/7\./)) {
		            $('html').addClass('ie');
		            $('html').addClass('ie-' + version);
                }
                if (/Edge/.test(navigator.userAgent)) {
                    $('html').addClass('edge');
                    $('html').addClass('edge-' + version);
                }
		        if($.browser.webkit) {
		            $('html').addClass('webkit');
		            $('html').addClass('webkit-' + version);
		        }
		        if($.browser.mozilla) {
		            $('html').addClass('mozilla');
		            $('html').addClass('mozilla-' + version);
		        }
			},

            initDatepicker: function() {
                var dpDefaults = {
                    dayNames: raceBetsJS.localize.getDayNames(),
                    dayNamesMin: raceBetsJS.localize.getDayNames(2),
                    dayNamesShort: raceBetsJS.localize.getDayNames(2),
                    monthNames: raceBetsJS.localize.getMonthNames(),
                    monthNamesShort: raceBetsJS.localize.getMonthNames(3),
                    nextText: raceBetsJS.i18n.data.buttonNext,
                    prevText: raceBetsJS.i18n.data.buttonPrevious
                };

                $.datepicker.setDefaults(dpDefaults);
            },

			initContentController: function() {
				// Create content controller
				raceBetsJS.application.contentController = new raceBetsJS.content.ContentController({
					onContentLoaded: function(url) {

						// Update navigation state
						raceBetsJS.application.header.navigation.activateItemByURL(url);

                        // localize times, odds and amounts
                        raceBetsJS.localize.times(raceBetsJS.application.globals.contentMain,
                            raceBetsJS.application.assets.settings.get().dialog.general.timezone);
                        raceBetsJS.localize.odds(raceBetsJS.application.globals.contentMain);
                        raceBetsJS.localize.amounts(raceBetsJS.application.globals.contentMain);
                    }
				});

				// Couple the browsing event to content controller
				//raceBetsJS.browser.history.browseEvent.observe(function(event) {
                    // Hide any existing overlays
                    //raceBetsJS.application.assets.overlay.close();

					// Load the content
					//raceBetsJS.application.contentController.navigate(event.href);
				//});
			},

            initContentModules: function() {
                //return scope for the angularJs App controller containing methods to be used in legacy (native) js code
                raceBetsJS.application.angularApp = {
                    commonCtrl: function() {
                        if(window.exportsAngularAppCtrl === undefined) {
                            return window.exportsAngularAppCtrl = angular.element($("#ExportsAngularAppCtrl-div")).scope();
                        }
                        return window.exportsAngularAppCtrl;
                    }
                };

                // always load this modules
                raceBetsJS.application.assets.media.init(); // streams everywhere
                raceBetsJS.application.content.formguide.init(); // formguide popup etc
                raceBetsJS.application.content.archivePopup.init(); // archive stream player popup
                raceBetsJS.application.assets.session.init(); // javascript sessions

                // init B2B only content
                if (!raceBetsJS.application.globals.isB2B) {
                    raceBetsJS.application.content.staticPage.init();
                }

                // only load in main framework
                if (!raceBetsJS.application.globals.isPopup) {
                    raceBetsJS.application.assets.settings.init();
                    raceBetsJS.application.assets.guidelines.init();

                    raceBetsJS.application.content.favourites.init();
                    raceBetsJS.application.content.highlights.init();
                    raceBetsJS.application.content.home.init();
                    raceBetsJS.application.content.starters.init();
                    raceBetsJS.application.content.myBets.init();

                    if (!raceBetsJS.application.globals.isB2B) {
                        raceBetsJS.application.content.cashier.init();
                        //used as a view for AngularJs app
                        raceBetsJS.application.content.cashierV2.init();
                        raceBetsJS.application.content.particulars.init();
                        raceBetsJS.application.content.transactions.init();
                        raceBetsJS.application.content.bonuses.init();
                        raceBetsJS.application.content.personStatistics.init();
                        raceBetsJS.application.content.offers.init();
                    }

                    raceBetsJS.application.content.calendar.init();
                    raceBetsJS.application.content.featuredHorses.init();
                    raceBetsJS.application.content.bonuses.init();
                    raceBetsJS.application.content.newFormGuide.init();

                    // Dynamic pages
                    raceBetsJS.application.content.event.init();
                    raceBetsJS.application.content.race.init();

                } else if(raceBetsJS.application.globals.isPopup === true && raceBetsJS.browser.params.get('loadModules') !== false) {
                    // in case of popup, that requres modules, load modules :)
                    var modules = raceBetsJS.browser.params.get('loadModules').split(',');

                    // iterare and init modules
                    $.each(modules, function() {
                        if (raceBetsJS.application.content[this] === undefined) {
                            $.log('Module not found: ' + this);
                            return;
                        }

                        raceBetsJS.application.content[this].init();
                    });
                }
            },

            initPage: function() {
                raceBetsJS.localize.times(
                    $('body'),
                    raceBetsJS.application.assets.settings.get().dialog.general.timezone
                );
                raceBetsJS.localize.odds($('body'));
                raceBetsJS.localize.amounts($('body'));

                // load right column if neccessary
                raceBetsJS.application.assets.rightColumn.init();

                // timezone differences
                raceBetsJS.application.assets.timezone.checkOffsetDifference();

                /**
                * If base10 and not german, just reload with base1 settings
                */
                var settings = raceBetsJS.application.assets.settings.get(true);
                if (raceBetsJS.application.user.lang !== 'de' && settings.dialog.general.oddsFormat === 'base10') {
                    raceBetsJS.application.assets.overlay.showPleaseWait();
                    settings.dialog.general.oddsFormat = 'base1';
                    raceBetsJS.application.assets.settings.set(settings);
                    location.reload();
                }

                /**
                * Sticky right bar
                */
                var scrollTimeout,
                    $document = $(document),
                    $body = $('body'),
                    $content = $('#content'),
                    $stickySidebar = $('#rightBar > .l-sticky'),
                    $scrollTop,
                    $stickySidebarHeight,
                    $stickySidebarWidth = $('#rightBar').width();

                $(window).on('scroll',function () {
                    if (scrollTimeout) {
                        clearTimeout(scrollTimeout);
                        scrollTimeout = null;
                    }
                    scrollTimeout = setTimeout(stickyRightBar, 10);
                });

                $(window).on('resize',function () {
                    if ($stickySidebarWidth === 0) {
                        $stickySidebarWidth = $('#rightBar').width();
                        $stickySidebar.width($stickySidebarWidth);
                    }
                });

                // send new height if content resizes body
                $body.on('resize', function(event, newWidth, newHeight){
                    raceBetsJS.browser.crossFrame.send('height', newHeight);
                });

                // Expose functionality, maybe not a right way...
                raceBetsJS.application.globals.rightBar = {};
                raceBetsJS.application.globals.rightBar.checkStickiness = stickyRightBar;

                if ($('#rightBar').is(':visible')) {
                    $stickySidebar.width( $('#rightBar').width() );
                }

                if (!raceBetsJS.application.globals.isB2B) {
                    raceBetsJS.application.content.adventPromo.init();
                }

                function stickyRightBar() {
                    $scrollTop = $document.scrollTop(),
                    $stickySidebarHeight = $stickySidebar.height();

                    $stickySidebar
                        .toggleClass('isScrolled', isScrollable());

                    handleCollision();

                    function isScrollable() {
                        return $scrollTop >= 86 &&
                                ($('#sidebar').height() >= $stickySidebarHeight || $('#content-container').height() >= $stickySidebarHeight);
                    }

                    function handleCollision() {
                        var collisionBorder = $('#header').height() + $content.height(),
                            comparisonPlane = Math.round( $scrollTop + $stickySidebarHeight ) - 5 /* inaccurate calculation fix */,
                            difference = collisionBorder - comparisonPlane;

                        // difference is still inconsistable in different browsers...
                        $stickySidebar.css('top', ( isScrollable() && difference < 0 ) ? difference : 0 );
                    }
                }
            },

            // TODO: This should be in a separate file
            initValidator: function() {
                // set custom messages
                $.extend($.validator.messages, {
                    required: raceBetsJS.i18n.data.validationErrorRequired,
                    email: raceBetsJS.i18n.data.validationErrorIsEmail,
                    dateISO: raceBetsJS.i18n.data.validationErrorDate,
                    number: raceBetsJS.i18n.data.validationErrorIsFloat,
                    digits: raceBetsJS.i18n.data.validationErrorIsInt,
                    equalTo: raceBetsJS.i18n.data.validationErrorEqualTo,
                    minlength: $.validator.format(raceBetsJS.i18n.data.validationErrorMinLength),
                    min: $.validator.format(raceBetsJS.i18n.data.validationErrorMinValue),
                    max: $.validator.format(raceBetsJS.i18n.data.validationErrorMaxValue)
                });

                /**
                * minAlphabetic
                *
                * Checks for a minimum number of alphabetic characters given as parameter
                */
                $.validator.addMethod('minAlphabetic', function(value, elem, param) {
                    var matches = value.match(/[a-z]/ig);
                    return (!matches ? false : matches.length >= param);
                }, raceBetsJS.i18n.data.validationErrorMinAlphabetic);

                /**
                * dateMysql
                *
                * Checks for a valid date in MySQL format (basing on JS date function, also 2011-02-31 is valid)
                */
                $.validator.addMethod('dateMysql', function(value, elem) {
                    if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(value)) {
                        return false;
                    }

                    var date = value.split('-');
                    return !/Invalid|NaN/.test(new Date(date[1] + '/' + date[2] + '/' + date[0]));
                }, raceBetsJS.i18n.data.validationErrorDate);

                /**
                * minAge
                *
                * Checks if a birthdate in MySQL-Format results in a minimum age given as parameter
                */
                $.validator.addMethod('minAge', function(value, elem, param) {
                    var now = new Date();
                    var date = value.split('-');

                    var age = now.getFullYear() - date[0];
                    if (now.getMonth() + 1 < date[1] || (now.getMonth() + 1 == date[1] && now.getDate() < date[2])) {
                        age -= 1;
                    }

                    return (age >= param || age >= 120);
                }, raceBetsJS.i18n.data.validationErrorMinAge);

                /**
                * username
                */
                $.validator.addMethod('username', function(value, element) {
                    return /^[-_a-zA-Z0-9]*$/.test(value);
                }, raceBetsJS.i18n.data.validationErrorUsername);

                /**
                * password
                */
                $.validator.addMethod('password', function(value, element) {
                    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/.test(value);
                }, raceBetsJS.i18n.data.validationErrorPassword);

                /**
                * fields values can not match
                */
                $.validator.addMethod('notEqualTo', function(value, element, param) {
                    // bind to the blur event of the target in order to revalidate whenever the target field is updated
                    // TODO find a way to bind the event just once, avoiding the unbind-rebind overhead
                    var target = $(param).unbind(".validate-notEqualTo").bind("blur.validate-notEqualTo", function() {
                        $(element).valid();
                    });

                    return value != target.val();
                });

                /**
                * number - overwrite original validator function to also accept comma as decimal separator
                */
                $.validator.addMethod('number', function (value, element) {
                    return this.optional(element) || /^-?(?:\d+|\d{1,3}(?:[\s\.,]\d{3})+)(?:[\.,]\d+)?$/.test(value);
                });

                /**
                * min - overwrite original validator function to also accept comma as decimal separator
                */
                $.validator.addMethod('min', function (value, element, param) {
                    return (value.replace(/,/, '.') >= param);
                });

                /**
                * max - overwrite original validator function to also accept comma as decimal separator
                */
                $.validator.addMethod('max', function (value, element, param) {
                    return (value.replace(/,/, '.') <= param);
                });
            },

            /**
            * getAjaxCacheUrl
            * Builds cache powered AJAX url based on browser support and ajaxHost availability
            */
            getAjaxCacheUrl: function(url) {
                // test for CORS support
                browserSupported = (('withCredentials' in new XMLHttpRequest()) // good browsers
                    || (parseInt($.browser.version) > 7 && !$.browser.opera)); // CORS is only supported by IE8+ and not by Opera

                // build custom URL
                if (raceBetsJS.application.globals.ajaxHost != '' && browserSupported) {
                    return raceBetsJS.application.globals.ajaxHost + url + '?lang=' + raceBetsJS.application.user.lang
                } else {
                    return url;
                }
            }
		};
	}();
})(raceBetsJS);
