/**
 * @description Tours
 *
 * @author: Andras Matzon <andras.matzon@racebets.com>
 * @author: Bogdan Gersak <bogdan.gersak@racebets.com> 10/29/2014
 *
 */
(function(raceBetsJS) {

    raceBetsJS.application.templates.tours = {};

    raceBetsJS.application.templates.tours.sidebar = {};

    raceBetsJS.application.templates.tours.sidebar.tourButton = _.template('\
        <div id="m-sidebar__tourBtn" class="sb-tour-btn"><span><%= raceBetsJS.i18n.data.labelSidebarTourButton  %><span class="pullRight"><span class="c-caret toRight"></span><span class="c-caret toRight"></span></span></span></div>\
    ');

    raceBetsJS.application.templates.tours.sidebar.welcome = _.template('\
        <div class="sb-tour-welcome">\
            <span class="title"><%= raceBetsJS.i18n.data.labelSidebarTourStep0Title %></span>\
            <span class="content sb-tour-welcome-content">\
                <%= raceBetsJS.i18n.data.labelSidebarTourStep0Content %>\
                <div class="sb-welcome-buttons">\
                    <button id="sidebarTourBtnSkip" class="btn btn-sm"><%= raceBetsJS.i18n.data.labelSidebarTourButtonSkip %></button>\
                    <button id="sidebarTourBtnStart" class="btn btn-sm btn-primary"><%= raceBetsJS.i18n.data.labelSidebarTourButtonStart %></button>\
                </div>\
            </span>\
        </div>\
    ');

    raceBetsJS.application.templates.tours.sidebar.countryEvent = _.template('\
        <ul id="sb-tour-country-event" class="c-dataList c-dataList--sidebar rowsBordered">\
            <li class="c-dataList__row">\
                <div class="c-dataList__cell c-dataList__cell--type">\
                    <span class="c-dataList__data">\
                        <span class="c-icon c-icon--eventType hasRaceG"></span>\
                    </span>\
                </div>\
                <a class="c-dataList__cell c-dataList__cell--title isLink hasChildEvent--2">\
                    <span class="c-dataList__data hasTag--pricedUp">Event Title</span>\
                    <span class="c-dataList__data">\
                        <span class="c-tag c-tag--s isPricedUp" title="Fixed Odds"><%= params.f %></span>\
                    </span>\
                </a>\
                <div class="c-dataList__cell c-dataList__cell--time isHighlighted">\
                    <span class="c-dataList__data">\
                        <span class="c-tag--time ng-binding">12m</span>\
                    </span>\
                </div>\
                <div class="c-dataList__cell c-dataList__cell--specials">\
                    <span class="c-dataList__data">\
                        <a title="Head to Head" class="c-btn--xs c-btn--default isEventH2H">H</a>\
                    </span>\
                </div>\
                <div class="c-dataList__cell c-dataList__cell--specials">\
                    <span class="c-dataList__data">\
                        <a title="Special Bets" class="c-btn--xs c-btn--default isEventSpecial">S</a>\
                    </span>\
                </div>\
            </li>\
            <li class="c-dataList__row" style="display:none;"></li>\
        </ul>\
    ');

    raceBetsJS.application.templates.tours.sidebar.buttonTable = _.template('\
        <table class="sb-tour-button-table">\
            <% $.each(buttons, function(i, button) { %>\
                <tr>\
                    <td><%= button.html %></td>\
                    <td><%= button.description %></td>\
                </tr>\
            <% }) %>\
        </table>\
    ');

    raceBetsJS.application.templates.tours.sidebar.filterButton = _.template('\
        <button class="c-btn c-btn--default" style="width:50px;padding:4px;cursor:pointer;pointer-events:none;"><span class="c-icon c-icon--eventType hasRace<%= type %>"></span> <span class="c-icon c-icon--raceType isType<%= type %>"></span></button>\
    ');

})(raceBetsJS);
