/**
* @description Archive popup template
*
*/
(function(raceBetsJS) {

    raceBetsJS.application.templates.archivePopup = _.template('\
        <div class="event-details">\
            <div class="event-name">\
                <span class="c-flag isCountry<%= data.event.country %>"></span>\
                <strong><%= data.event.title %></strong>,\
                <span class="event-date"><%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.formatDateLong, data.race.postTime) %></span>\
                <div class="event-more">\
                    <%= (!$.isEmptyObject(data.race.raceTitle)) ? data.race.raceTitle : raceBetsJS.i18n.print("raceNumberX", { race: data.race.raceNumber }) %>\
                    <% if (data.race.category != "") { %>\
                        <% if (data.race.raceType === "D") { %>\
                            <%= "- " + data.race.category %>\
                        <% } else { %>\
                            <%= "- " + raceBetsJS.i18n.data["raceCategory" + data.race.category] %>\
                        <% } %>\
                    <% } %>\
                    <% if (data.race.categoryLetter !== "") { %>\
                        <% if(data.event.country === "FR") { %>\
                            (<%= raceBetsJS.i18n.print("raceCategoryLetterFrance", {letter: data.race.categoryLetter}) %>)\
                        <% } else if($.inArray(data.event.country, ["GB", "IE", "AE"]) > -1) { %>\
                            (Class <%= data.race.categoryLetter %>)\
                        <% } else { %>\
                            (<%= data.race.categoryLetter %>)\
                        <% } %>\
                    <% } %>\
                    <% if (data.race.category != "" || data.race.categoryLetter !== "") { %> | <% } %>\
                    <%= raceBetsJS.format.distance(data.race.distance, raceBetsJS.application.assets.settings.get().dialog.general.unitSystem) %> | <%= raceBetsJS.format.number(data.race.purse) + " " + data.race.purseCurrency %>\
                </div>\
            </div>\
            <a href="race/details/id/<%= data.race.idRace %>" data-idrace="<%= data.race.idRace %>" class="toracecard" title="<%= raceBetsJS.i18n.data.labelOpenRaceCard %>"></a>\
        </div>\
        <%= raceBetsJS.application.templates.archivePopup.runnerDetails({ data: data }) %>\
    ');

    raceBetsJS.application.templates.archivePopup.runnerDetails = _.template('\
        <div class="runner-details">\
            <div class="runner-table-wrapper">\
                <% if ( !$.isEmptyObject(data.runners) ) { %>\
                    <% $.each(data.runners, function(i, runner) { %>\
                        <% if (i === 0 || i === Math.round(data.runners.length / 2) ) { %>\
                        <ul>\
                            <li class="horses-table-header">\
                                <% if (i === 0) { %>\
                                    <span class="horse-header-number clickable">#</span>\
                                    <span class="horse-header-name">Horse</span>\
                                    <span class="horse-header-final-pos clickable">Final Pos.</span>\
                                <% } %>\
                            </li>\
                        <% } %>\
                            <li class="runner<% if (runner.finalPosition) { %> has-position<% } %>">\
                                <div class="default">\
                                    <span class="prog-num"><%= runner.programNumber %></span>\
                                    <span class="silk">\
                                        <% if (runner.silkExtension !== "") { %>\
                                            <img src="<%= raceBetsJS.application.globals.imageHost %>/cache/img/silks/<%= runner.silkId %>_w42.<%= runner.silkExtension %>" width="21" height="20" alt="" />\
                                        <% } else { %>\
                                            <img src="<%= raceBetsJS.application.globals.imageHost %>/images/cards/silks/slw.png" width="21" height="20" alt="" />\
                                        <% } %>\
                                    </span>\
                                    <div class="name"><%= runner.name %></div>\
                                    <% if (runner.finalPosition) { %><span class="final-num"><%= runner.finalPosition %></span><% } %>\
                                </div>\
                            </li>\
                        <% if (i === data.runners.length - 1 || i === Math.round(data.runners.length / 2) - 1 ) { %>\
                        </ul>\
                        <% } %>\
                    <% }); %>\
                <% } %>\
            </div>\
        </div>\
    ');

})(raceBetsJS);
