/**
* Timeout class
*
* @description Wrapper for setTimeout.
*
* @author Moritz Honig
*
*/
(function(raceBetsJS) {
	raceBetsJS.time.Timeout = Class.extend({
		init: function(options) {
			this.options = options || {};
			this.remaining = this.options.delay;
			this.done = false;
			this.resume();
		},

		pause: function() {
			clearTimeout(this.timer);
			this.remaining -= new Date() - this.start;
		},

		resume: function() {
			if(this.done) {
				return;
			}

			this.start = new Date();

			this.timer = setTimeout($.proxy(function() {
				this.options.callback();
				this.done = true;
			}, this), this.remaining)
		},

		stop: function() {
			clearTimeout(this.timer);
			this.done = true;
		}
	});
})(raceBetsJS);