/**
* Localize
*/
(function(raceBetsJS) {
    raceBetsJS.localize = {

        odds: function(container) {
            $(container).find('span.odds-locale').each(function() {
                var elem = $(this);
                var odds = elem.data('odds');

                if (odds > 0) {
                    elem.html(raceBetsJS.format.odds(odds));
                }

                elem.removeClass('odds-locale').addClass('odds-localized');
            });
        },

        /**
        * Show the localized date from data-timestamp attribute of a html element
        */
        time: function(elem, timezone) {
            elem = $(elem);

            var ts = elem.data('timestamp') || Math.round($.now() / 1000);
            var format = elem.data('date-format') || 'd.m.Y H:i';
            var i18nElem = elem.data('i18n-element');
            var tagName = elem.get(0).tagName.toLowerCase();

            var localized = raceBetsJS.format.date(format, raceBetsJS.time.timezone.convert(ts, timezone));

            if (i18nElem !== undefined && raceBetsJS.i18n.data[i18nElem] !== undefined) {
                localized = raceBetsJS.i18n.print(i18nElem, { time: localized });
            }

            if (tagName == 'input') {
                elem.val(localized);
            } else{
                elem.html(localized);
            }

            elem.removeClass('date-locale').addClass('date-localized');
        },

        /**
        * Localizes the timestamps of all date-locale elements within a container
        */
        times: function(container, timezone) {
            $(container).find('span.date-locale, input.date-locale').each(function() {
                raceBetsJS.localize.time(this, timezone);
            });
        },

        /**
        * getDayNames
        */
        getDayNames: function(truncate) {
            var days = raceBetsJS.application.globals.localizedDateStrings.slice(0, 7);

            if (truncate) {
                var arr = [];
                $.each(days, function() {
                    arr.push(this.substr(0, truncate));
                });
                return arr;
            }

            return days;
        },

        /**
        * getMonthNames
        */
        getMonthNames: function(truncate) {
            var months = raceBetsJS.application.globals.localizedDateStrings.slice(7, 19);

            if (truncate) {
                var arr = [];
                $.each(months, function() {
                    arr.push(this.substr(0, truncate));
                });
                return arr;
            }

            return months;
        },

        exchange: function(amount, baseCurrency, targetCurrency, withoutRate) {
            if (baseCurrency == targetCurrency) {
                return amount;
            }

            var exchangeRates = raceBetsJS.application.globals.exchangeRates;
            if (exchangeRates[baseCurrency] === undefined || exchangeRates[baseCurrency][targetCurrency] === undefined) {
                throw 'The currency tried to exchange is undefined (' + baseCurrency + ' => ' + targetCurrency + ')';
            }

            if (withoutRate) { 
                return parseFloat(amount) * exchangeRates[baseCurrency][targetCurrency];
            }
            // ... and return result with 1% more expensive exchange rate
            return parseFloat(amount) * (exchangeRates[baseCurrency][targetCurrency] * 1.01);
        },

        /**
        * Show the localized amount
        */
        amount: function(elem) {
            elem = $(elem);

            // use defined currency element or amount specified
            var amount = (elem.data('currency-elem') ? raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency][elem.data('currency-elem')] : elem.data('amount'));

            var baseCurrency = elem.data('base-currency') || raceBetsJS.application.user.currency;
            var targetCurrency = elem.data('target-currency') || raceBetsJS.application.user.currency;
            var decimals = (elem.data('decimals') !== 0) ? (elem.data('decimals') || 2) : 0;
            var useSymbol = (elem.data('use-symbol') === true);
            var forceSuffix = (elem.data('force-suffix') === true);
            var tagName = elem.get(0).tagName.toLowerCase();

            var localized = raceBetsJS.format.money(
                raceBetsJS.localize.exchange(amount, baseCurrency, targetCurrency), decimals, targetCurrency, useSymbol, false, forceSuffix
            );

            if (elem.data('title-only') == true) {
                if (baseCurrency != targetCurrency) {
                    elem.addClass('tipped').attr('title', localized);
                }

            } else {
                if (tagName == 'input') {
                    elem.val(localized);
                } else{
                    elem.html(localized);
                }

                if (baseCurrency != targetCurrency) {
                    elem.addClass('tipped').attr('title',
                        raceBetsJS.format.money(amount, decimals, baseCurrency)
                    );
                }
            }

            elem.removeClass('amount-locale').addClass('amount-localized');
        },

        amounts: function(container) {
            $(container).find('span.amount-locale').each(function() {
                raceBetsJS.localize.amount(this);
            });
        }
    };
})(raceBetsJS);