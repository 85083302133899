/**
* Navigation module.
*
* @description
*
* @TODO: Check the whole use of this functions again (and storing of data on elements etc.)
*
* @author Moritz Honig
*/
(function(raceBetsJS) {
    raceBetsJS.application.header.navigation = (function() {
        // @private
        var $primaryNavElem,
            hierarchy = {},
            bonusButton;

        var init = function() {
            // Not for popups
            if (raceBetsJS.application.globals.isPopup) {
                return;
            }

            // Navigation container
            $primaryNavElem = $('#m-header__menu');

            // Traverse the navigation hierarchy
            hierarchy = traverseHierarchy($primaryNavElem.children('.c-btnGroup__item'), 0);

            // Init navigation events
            initEvents();

            bonusButton = $primaryNavElem.find('.m-header__bonus');

            if (raceBetsJS.application.globals.isB2B && raceBetsJS.application.user.loggedIn) {
                bonusButton.removeClass('isHidden');
            }
        };

        /**
        * Dropdown navigation events
        */
        var initEvents = function() {
            $primaryNavElem
                .on('mouseenter.dropdown', '.c-btnGroup__item.hasChild', function() {
                    $(this).addClass('isActive');
                })
                .on('mouseleave.dropdown', '.c-btnGroup__item.hasChild', function() {
                    $(this).removeClass('isActive');
                })
                .on('touchstart.dropdown', '.c-btnGroup__item.hasChild > .c-btn', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var $parentItem = $(this).parent();

                    if ($parentItem.hasClass('isActive')) {
                        $parentItem.toggleClass('isActive');
                    } else {
                        $primaryNavElem.find('.isActive').removeClass('isActive');
                        $parentItem.addClass('isActive');
                    }
                })
                .on('click', '.c-btn', function(e) {
                    var href = $(this).attr('href');

                    if ((/^http/).test(href) && raceBetsJS.application.globals.isB2B) {
                        e.preventDefault();
                        raceBetsJS.browser.crossFrame.send('open', href);
                    }
                });

            $(document).on('touchstart.dropdown', function(e) {
                var $target = $(e.target);
                if (!$target.parents().filter('#m-header__menu').length) {
                    $primaryNavElem.find('.isActive').removeClass('isActive');
                }
            });
        };

        /**
        * Split up the menu items and remove unnecessary DOM elements
        */
        var traverseHierarchy = function(elems, level) {
            var hierarchyLevel = {},
                $anchorElem,
                elemKey,
                $childElems,
                $listElem;

            $.each(elems, function(i, listElem) {
                $listElem = $(listElem);
                $anchorElem = $listElem.find('.c-btn').first();
                elemKey = $listElem.data('key');

                // Create menu item in the hierarchy
                hierarchyLevel[elemKey] = {
                    href: $anchorElem.attr('href'),
                    label: $anchorElem.text(),
                    anchorElem: $anchorElem
                };

                // Check for children

                if ($listElem.find('.c-btnGroup--subMenu').length) {
                    $childElems = $listElem.find('.c-btnGroup__item');
                    hierarchyLevel[elemKey].children = traverseHierarchy($childElems, level + 1);
                }
            });

            return hierarchyLevel;
        };

        /**
        * Update the active navigation item (keys of levels separated by space)
        */
        var activateItemByKey = function(key) {
            // Not for popups
            if (raceBetsJS.application.globals.isPopup) {
                return;
            }

            key = key.split(' ');

            if (key[0] === 'streams') {
                return; // skip streams menu item
            }

            if (_.isUndefined($primaryNavElem)) {
                $primaryNavElem = $('#m-header__menu');
            }

            // Deactivate all active items
            $primaryNavElem.find('.isSelected').removeClass('isSelected');
            $primaryNavElem.find('.isActive').removeClass('isActive');

            // Check if top level elem exists
            if (!hierarchy.hasOwnProperty(key[0])) {
                return;
            }

            var primaryItem = hierarchy[key[0]];

            // A new primary item has been selected
            primaryItem.anchorElem.addClass('isSelected');

            // Select sub menu item
            if (key.length > 1 && primaryItem.children.hasOwnProperty(key[1])) {
                primaryItem.children[key[1]].anchorElem.addClass('isSelected');
            }
        };

        /**
        * Activates a menu item in the navigation hierarchy by a URL.
        */
        var activateItemByURL = function(url, hierarchyLevel, fullKey) {
            // Not for popups
            if (raceBetsJS.application.globals.isPopup) {
                return;
            }

            var activated = false;

            // Attach global prefix if the url lacks it
            if (url && url.indexOf(raceBetsJS.application.globals.urlPrefix) === -1) {
                url = raceBetsJS.application.globals.urlPrefix + url;
            }

            if (hierarchyLevel === undefined) {
                hierarchyLevel = hierarchy;
                fullKey = '';
            }

            $.each(hierarchyLevel, function(key, item) {
                // No url is being returned from Angular
                if (url === undefined) {
                    return false;
                }

                if (!item.hasOwnProperty('children') && item.href === url) {
                    activateItemByKey($.trim(fullKey + ' ' + key));
                    activated = true;
                    return false; // break out the each
                } else if (item.hasOwnProperty('children')) {
                    activated = activateItemByURL(url, item.children, fullKey + ' ' + key);
                    if (activated) {
                        return false;  // break out the each
                    }
                }
            });

            return activated;
        };

        var updateBonusInfo = function(data) {
            if (raceBetsJS.application.globals.isB2B) {
                if (data.freebets) {
                    bonusButton.addClass('hasBonus');
                } else if (!data.freebets) {
                    bonusButton.removeClass('hasBonus');
                }
            }
        }

        // @public
        return {
            init: init,
            activateItemByKey: activateItemByKey,
            activateItemByURL: activateItemByURL,
            updateBonusInfo: updateBonusInfo
        };
    })();
})(raceBetsJS);
