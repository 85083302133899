/**
* @description favourites
*
*/
(function(raceBetsJS) {
    /**
    * Favourite Search Results
    */
    raceBetsJS.application.templates.favourites = _.template(
        '<div class="list clearfix">' +
            '<% $.each(results, function(column, runners) { %>' +
                '<ul class="col">' +
                    '<% $.each(runners, function(i, runner) { %>' +
                        '<li>' +
                             '<a class="<%= runner.emailAlert ? "active" : "" %>" data-subject="<%= runner.idSubject %>">' +
                                '<span class="c-iconFont c-iconFont--star-o"></span>' +
                                '<%= runner.horseName %>' +
                                '<% if (runner.yearOfBirth && runner.yearOfBirth !== "0000") { %>' +
                                    '<span class="em"><%= runner.yearOfBirth %></span>' +
                                '<% } %>' +
                             '</a>' +
                        '</li>' +
                    '<% }); %>' +
                '</ul>' +
            '<% }); %>' +
        '</div>'
    );

})(raceBetsJS);
