/**
* Login Dialog
* @author Robin Ebers
*
* @example
*   raceBetsJS.application.assets.loginDialog.show({
*       type: 'loginFailed'
*   });
*/

(function (raceBetsJS) {
    raceBetsJS.application.assets.dialogs = {};

    raceBetsJS.application.assets.dialogs.limitsDialog = function () {
        // default options
        var defaultOptions = {
            closeButton: true,
            buttons: false,
            width: 550,
            noLimit: {
                amount: 0,
                period_days: 0,
                status: 'INA'
            }
        };

        var limit,
            Restrictions,
            ViewModel,
            $limitForm,
            options,
            fromEditDialog;

        var show = function (params) {
            options = $.extend({}, defaultOptions, params || {});
            var dlgOptions, dlgContent;
            limit = params.limit || {};
            Restrictions = raceBetsJS.application.angularApp.commonCtrl().userRestrictions;
            ViewModel = params.ViewModel || {};
            fromEditDialog = params.fromEditDialog;

            if (!params.limit.data) {
                limit.data = options.noLimit;
                options.limit.data = options.noLimit;
            }

            dlgContent = {
                limit: options.limit.data,
                limitType: options.limit.type,
                options: options.limit.options,
                isGermanCustomer: raceBetsJS.application.user.country === 'DE',
                showConfirmButton: options.showConfirmButton,
                showPendingFormForLugas: !options.showConfirmButton && options.limit.type === 'LUGAS',
            };

            if (limit.data.pending_limit) {
                var expires = new Date(limit.data.pending_limit.cooling_off_stamp * 1000),
                    currentDate = new Date();

                dlgContent.coolingOffExpired = (Math.ceil((limit.data.pending_limit.cooling_off_stamp * 1000 - currentDate - raceBetsJS.application.user.timeDiff) / 1000) <= 0);

                var daysLeft = Math.round(Math.abs(expires.getTime() - currentDate.getTime()) / (24 * 60 * 60 * 1000));
                var hoursLeft = Math.round(Math.abs(expires.getTime() - currentDate.getTime()) / (60 * 60 * 1000));

                dlgContent.coolingOffLeft = daysLeft <= 1 ? raceBetsJS.i18n.print('labelResHoursLeft', { hours: hoursLeft }) : raceBetsJS.i18n.print('labelResDaysLeft', { days: daysLeft });
            }

            dlgOptions = {
                className: 'dialog-limits dialog-limits-' + options.limit.type.toLowerCase(),
                title: raceBetsJS.i18n.print('resType' + options.limit.type),
                content: raceBetsJS.application.templates.dialogs.limits(dlgContent)
            };

            // show dialog
            raceBetsJS.application.assets.modalDialog.show($.extend({
                buttons: options.buttons,
                closeButton: options.closeButton,
                className: options.className,
                width: options.width
            }, dlgOptions));

            // init events
            initEvents();
        };

        var initEvents = function () {
            // New limit
            var $deleteLimitBtn = $('#deleteLimitBtn');

            if (!limit.data.hasActiveLimit) {
                $deleteLimitBtn.hide();
            }

            if (!limit.data || !limit.data.pending_limit || (limit.type === 'LUGAS' && (!limit.data.pending_limit || (limit.data.pending_limit && limit.data.pending_limit.cooling_off_stamp)))) {
                var $limitOption = $('#limitPeriodOption'),
                    $limitOptionHidden = $('#limitPeriodOptionHidden'),
                    $newLimitAmount = $('#newLimitAmount'),
                    $createLimitBtn = $('#createLimitBtn'),
                    $confirm = $('#confirm'),
                    $cancel = $('#keepLimit');

                // raceBetsJS.application.assets.overlay.close();
                $limitOption.on('change', function () {
                    var selectedVal = parseInt($(this).val());

                    if (selectedVal >= 0) {
                        $newLimitAmount.removeAttr('disabled');
                        $newLimitAmount.val('').focus();
                        if (selectedVal === 0) {
                            $newLimitAmount.val(raceBetsJS.i18n.data.labelNoLimit);
                            $newLimitAmount.attr('disabled', true);
                            $createLimitBtn.addClass('c-btn--default').removeClass('c-btn--primary');
                        }
                        if ($newLimitAmount.val() !== '') {
                            $createLimitBtn.removeAttr('disabled');
                            $createLimitBtn.addClass('c-btn--primary').removeClass('c-btn--default');
                        } else {
                            $createLimitBtn.attr('disabled', true);
                            $createLimitBtn.addClass('c-btn--default').removeClass('c-btn--primary');
                        }
                    } else {
                        $createLimitBtn.attr('disabled', true);
                        $createLimitBtn.addClass('c-btn--default').removeClass('c-btn--primary');
                        $newLimitAmount.attr('disabled', true);
                        $newLimitAmount.val('');
                        if ($limitForm) {
                            $limitForm.validate().resetForm();
                        }
                    }

                    $limitOptionHidden.val(selectedVal);
                });

                $newLimitAmount.on('keyup', function () {
                    var inputVal = $(this).val();

                    if (inputVal !== '') {
                        $createLimitBtn.removeAttr('disabled');
                        $createLimitBtn.addClass('c-btn--primary').removeClass('c-btn--default');
                    } else {
                        $createLimitBtn.attr('disabled', true);
                        $createLimitBtn.addClass('c-btn--default').removeClass('c-btn--primary');
                    }
                });

                if (limit.data.status === 'INA') {
                    $limitOption.find('option[value="0"]').remove();
                }

                $deleteLimitBtn.on('click', function (e) {
                    e.preventDefault();
                    createLimit($('#deleteLimitForm'), true);
                });

                $createLimitBtn.on('click', function (e) {
                    e.preventDefault();
                    createLimit($('#newLimitForm'));
                });
            } // There's a pending limit
            else if (limit.data.pending_limit) {
                $('#revokeLimitBtn').on('click', function (e) {
                    e.preventDefault();
                    revokeLimit();
                });

                $('#confirmLimitBtn').on('click', function (e) {
                    e.preventDefault();
                    confirmLimit();
                });

                var $limitBoxes = $('.dialog-limits').find('.c-box'),
                    boxMaxHeight = 0;
                $.each($limitBoxes, function (i, box) {
                    var boxHeight = $(box).height();
                    if (boxHeight > boxMaxHeight) {
                        boxMaxHeight = boxHeight;
                    }
                });
                $limitBoxes.height(boxMaxHeight);
            }

        };

        var showSuccessDialog = function (type, content) {
            raceBetsJS.application.assets.modalDialog.show({
                type: type,
                content: content,
                closeButton: false,
                buttons: [
                    {
                        id: 'dlg-limit-success-ok',
                        label: raceBetsJS.i18n.data.buttonOK,
                        action: function () {
                            raceBetsJS.application.assets.overlay.close();
                            if (ViewModel.user) {
                                raceBetsJS.application.assets.overlay.showPleaseWait();
                                Restrictions
                                    .getLimits(true)
                                    .then(
                                        function (data) {
                                            ViewModel.user.limits = data.limits;
                                            ViewModel.user.oasisReasons = data.oasisReasons;

                                            raceBetsJS.application.assets.overlay.close();
                                        },
                                        function () {
                                            location.reload();
                                        }
                                    );
                            }
                        },
                        active: true
                    }
                ]
            });
        };

        var showErrorDialog = function (content) {
            raceBetsJS.application.assets.modalDialog.show({
                type: 'error',
                content: content,
                buttons: [
                    {
                        id: 'dlg-limit-error-ok',
                        label: raceBetsJS.i18n.data.buttonOK,
                        action: function () {
                            raceBetsJS.application.assets.overlay.close();
                        },
                        active: true
                    }
                ]
            });
        };

        var createLimit = function (limitForm, deleteLimit) {
            $limitForm = $(limitForm);

            var $limitDialog = $('.dialog-limits'),
                data = {};
            var amountMax = 999999;
            var amountMin = (deleteLimit) ? 0 : 1;

            var validateRules = {
                period_days_option: {
                    required: true
                },
                amount: {
                    required: true,
                    number: true,
                    min: amountMin,
                    max: amountMax
                },
                confirm: {
                    required: true
                }
            };

            if (limit.type === 'LUGAS') {
                var amountMax = 30000;
                var amountMin = 1;

                validateRules = {
                    period_days_option: {
                        required: false
                    },
                    amount: {
                        required: true,
                        number: true,
                        min: amountMin,
                        max: amountMax
                    },
                    confirm: {
                        required: true
                    }
                };
            }

            $limitForm.validate({
                rules: validateRules,
                messages: {
                    period_days_option: {
                        required: raceBetsJS.i18n.data.validationErrorRequired
                    },
                    amount: {
                        required: raceBetsJS.i18n.data.validationErrorRequired,
                        max: raceBetsJS.i18n.print('msgErrorAmountOutOfRange', {range: amountMin + ' - ' + amountMax})
                    }
                },
                errorElement: 'span',
                errorClass: 'hasError',
                highlight: function (elem, errorClass) {
                    var $elem = $(elem);
                    $elem.addClass(errorClass);
                },
                unhighlight: function (elem, errorClass) {
                    var $elem = $(elem);
                    $elem.removeClass(errorClass);
                },
                errorPlacement: function (error, element) {
                    if (element.next("#limitCurrency")) {
                        error.appendTo(element.next("#limitCurrency"));
                    } else {
                        error.appendTo(element);
                    }
                },
                submitHandler: function () {
                    var periodDaysVal = $limitForm.find('.limitPeriodOptionHidden').val(),
                        amountVal = $limitForm.find('.newLimitAmount').val();

                    $limitDialog.find('.c-btn').attr('disabled', true);
                    $limitDialog.find('.content').append(
                        raceBetsJS.application.templates.loadingLayer({
                            loadingStyle: 'white'
                        })
                    );

                    data = {
                        limit_type: limit.type,
                        period_days: parseInt(periodDaysVal),
                        amount: isNaN(parseFloat(amountVal)) ? 0 : amountVal,
                        id_notification: limit.data.idNotification
                    };

                    sendLimit(data);


                }
            });
            $limitForm.submit();


        };

        var sendLimit = function (limitData) {
            if (!limitData) {
                return;
            }
             raceBetsJS.application.assets.overlay.close();
            if (limitData.limit_type === 'LUGAS' && Number(limitData.amount) > 1000) {

                var contentMessages = raceBetsJS.i18n.print('msgLugasDepositPopup', { new_limit: limitData.amount });

                raceBetsJS.application.assets.dialogs.lugasLimit.show({
                    content: contentMessages,
                    title: raceBetsJS.i18n.data.titleLugasDepositPopup,
                    callback: function () {
                        makeCall(limitData)
                    },
                    buttons: [
                        {
                            id: 'lugasButtonOK',
                            label: raceBetsJS.i18n.data.labelChangeLimit,
                            action: function (e) {
                                e.preventDefault();
                                raceBetsJS.application.assets.overlay.close();
                                makeCall(limitData)
                            },
                            active: true
                        },
                        {
                            id: 'lugasButtonCancel',
                            label: raceBetsJS.i18n.data.buttonCancel,
                            action: function (e) {
                                e.preventDefault();
                                raceBetsJS.application.assets.overlay.close();
                            },
                            btnClass: 'link',
                            active: true,
                        }
                    ],
                })

            } else {
                makeCall(limitData)
            }


        };

        var makeCall = function (limitData) {
            raceBetsJS.application.assets.overlay.showPleaseWait();
            Restrictions
                .setLimit(limitData)
                .then(
                    function (resp) {
                        raceBetsJS.application.assets.overlay.close();

                        if (resp.data.status === 'PND') {
                            limit = null;
                            var pendingMsg;

                            if (limitData.limit_type === 'LUGAS') {
                                if (resp.data.pendingLugas) {
                                    pendingMsg = raceBetsJS.i18n.data.msgResLimitPendingLugas;
                                } else if (resp.data.verificationRequired) {
                                    pendingMsg = raceBetsJS.i18n.data.msgResLimitVerificationRequired;
                                } else if (resp.data.sowRequired) {
                                    if(resp.data.limit10K) {
                                        if(resp.data.limit10K.status === 'ACT') {
                                            pendingMsg = raceBetsJS.i18n.print('msgResLimitSOWRequired10kActive', {
                                                limitAmount: raceBetsJS.format.money(limitData.amount, 2, 'EUR', true)
                                            });
                                        } else if (resp.data.limit10K.status === 'PND' && resp.data.limit10K.coolingOffStamp) {
                                            pendingMsg = raceBetsJS.i18n.print('msgResLimitSOWRequired10kCoolingOff', {
                                                date: raceBetsJS.format.date('Y-m-d', resp.data.limit10K.coolingOffStamp),
                                                limitAmount: raceBetsJS.format.money(limitData.amount, 2, 'EUR', true)
                                            });
                                        } else {
                                            pendingMsg = raceBetsJS.i18n.data.msgResLimitSOWRequired;
                                        }
                                    } else {
                                        pendingMsg = raceBetsJS.i18n.data.msgResLimitSOWRequired;
                                    }

                                    var date = new Date();
                                    date.setHours(date.getHours() + 1);

                                    $.cookie(raceBetsJS.application.assets.settings.showSowPopUpCookieName, JSON.stringify({ seen: true }), {
                                        secure: true,
                                        path: '/',
                                        expires: date
                                    });
                                } else if (resp.data.coolingOffStamp) {
                                    pendingMsg = raceBetsJS.i18n.print('msgResLimitPendingLugasCoolingOff', {
                                        date: raceBetsJS.format.date('Y-m-d', resp.data.coolingOffStamp)
                                    });
                                } else {
                                    pendingMsg = raceBetsJS.i18n.print('msgResLimitPending', {
                                        period: raceBetsJS.i18n.print('timeXDays', { days: '8' })
                                    });
                                }
                            } else if (raceBetsJS.application.user.accountLimitedAccess === 'active') {
                                pendingMsg = raceBetsJS.i18n.data.msgResLimitPendingWithTempAccount;
                            } else {
                                pendingMsg = raceBetsJS.i18n.print('msgResLimitPendingWithConfirm', {
                                    period: raceBetsJS.application.user.coolingOffPeriod === 1 ? raceBetsJS.i18n.print('timeXHours', { hours: '24' }) : raceBetsJS.i18n.print('timeXDays', { days: raceBetsJS.application.user.coolingOffPeriod })
                                });
                            }

                            showSuccessDialog('attention', pendingMsg);
                        } else if (resp.data.amount_used) {
                            raceBetsJS.application.assets.modalDialog.show({
                                className: 'dialog-limits dialog-limits-confirm',
                                type: 'attention',
                                content: raceBetsJS.i18n.print('dialogResConfirmLimit', {
                                    usedAmount: raceBetsJS.format.money(resp.data.amount_used, 2, raceBetsJS.application.user.currency, true),
                                    newAmount: raceBetsJS.format.money(limitData.amount, 2, raceBetsJS.application.user.currency, true)
                                }),
                                buttons: [
                                    {
                                        id: 'dlg-limits-confirm-no',
                                        label: raceBetsJS.i18n.data.buttonNo,
                                        action: function () {
                                            raceBetsJS.application.assets.overlay.close();
                                        }
                                    },
                                    {
                                        id: 'dlg-limits-confirm-yes',
                                        label: raceBetsJS.i18n.data.buttonYes,
                                        action: function () {
                                            $('#dlg-limits-confirm-yes, #dlg-limits-confirm-no').attr('disabled', true);
                                            $('.dialog-limits-confirm .content').append(
                                                raceBetsJS.application.templates.loadingLayer({
                                                    loadingStyle: 'white'
                                                })
                                            );

                                            sendLimit($.extend({
                                                confirmed: true
                                            }, limitData));
                                        },
                                        active: true
                                    }
                                ]
                            });
                        } else {
                            limit = null;
                            showSuccessDialog('success', raceBetsJS.i18n.data.msgResLimitSuccess);
                        }
                    },
                    function (resp) {
                        var data = resp.data.data;
                        raceBetsJS.application.assets.overlay.close();

                        if (resp.data.error && resp.data.error.message === 'LUGAS_LIMIT_REDUCED_MAXIMUM') {
                            showErrorDialog(raceBetsJS.i18n.data.msgLugasErrorReducedMaximum);
                        } else if (data.title === 'ACTIVE_LIMIT_IDENTICAL') {
                            showErrorDialog(raceBetsJS.i18n.data.sameLimit);
                        } else if (data.title === 'REMOVE_LIMIT_NOT_ALLOWED') {
                            showErrorDialog(raceBetsJS.i18n.data.msgGenericError);
                        } else {
                            showErrorDialog(raceBetsJS.i18n.print('msgUnknownError', { errorCode: data.code }));
                        }
                    }
                );
        }

        var revokeLimit = function () {
            var $revokeButton = $('#revokeLimitBtn'),
                $limitDialog = $('.dialog-limits');

            raceBetsJS.application.assets.overlay.close();

            raceBetsJS.application.assets.modalDialog.show({
                className: 'dialog-limits dialog-limits-revoke',
                type: 'attention',
                title: raceBetsJS.i18n.data.labelResLimitRevoke,
                content: raceBetsJS.i18n.data.msgResLimitRevokeConfirm,
                buttons: [
                    {
                        id: 'dlg-limit-revoke-no',
                        label: raceBetsJS.i18n.data.buttonNo,
                        action: function () {
                            raceBetsJS.application.assets.overlay.close();
                        }
                    },
                    {
                        id: 'dlg-limit-revoke-yes',
                        label: raceBetsJS.i18n.data.buttonYes,
                        action: function () {
                            $('#dlg-limit-revoke-yes, #dlg-limit-revoke-no').attr('disabled', true);
                            $('.dialog-limits-revoke .content').append(
                                raceBetsJS.application.templates.loadingLayer({
                                    loadingStyle: 'white'
                                })
                            );
                            Restrictions
                                .revokeLimitChange(limit.data.pending_limit.id_limit)
                                .then(
                                    function (resp) {
                                        limit = null;
                                        raceBetsJS.application.assets.overlay.close();
                                        showSuccessDialog('success', raceBetsJS.i18n.data.msgResLimitRevoke);
                                    },
                                    function (resp) {
                                        var data = resp.data.data;
                                        raceBetsJS.application.assets.overlay.close();
                                        if (data.title === 'REMOVE_LIMIT_NOT_ALLOWED') {
                                            showErrorDialog(raceBetsJS.i18n.data.msgGenericError);
                                        } else {
                                            showErrorDialog(raceBetsJS.i18n.print('msgUnknownError', { errorCode: data.code }));
                                        }
                                    }
                                );
                        },
                        active: true
                    }
                ]
            });
        };

        var confirmLimit = function () {
            var $revokeButton = $('#confirmLimitBtn'),
                $limitDialog = $('.dialog-limits');

            $revokeButton.attr('disabled', true);
            $limitDialog.find('.content').append(
                raceBetsJS.application.templates.loadingLayer({
                    loadingStyle: 'white'
                })
            );

            Restrictions
                .confirmLimitChange(limit.data.pending_limit.id_limit)
                .then(
                    function (resp) {
                        limit = null;
                        raceBetsJS.application.assets.overlay.close();
                        raceBetsJS.application.angularApp.commonCtrl().hideConfirmLimitIndicators();
                        showSuccessDialog('success', raceBetsJS.i18n.data.msgResLimitSuccess);
                    },
                    function (resp) {
                        var data = resp.data.data;
                        raceBetsJS.application.assets.overlay.close();
                        showErrorDialog(raceBetsJS.i18n.print('msgUnknownError', { errorCode: data.code }));
                    }
                );
        };

        return {
            show: show
        };

    }();

    raceBetsJS.application.assets.dialogs.setLockout = function () {
        // default options
        var defaultOptions = {
            closeButton: true,
            buttons: false,
            width: 440
        };

        var data,
            options,
            $pw,
            $pob,
            loadingLayer,
            $lockoutDialog,
            $confirmCheckbox,
            $oasisReasonItems,
            requestMaidenName,
            requestPlaceOfBirth,
            isGermanCustomer;

        var show = function (params) {
            var dlgOptions;

            isGermanCustomer = raceBetsJS.application.user.country === 'DE';
            requestMaidenName = !raceBetsJS.application.user.maidenName && isGermanCustomer;
            requestPlaceOfBirth = !raceBetsJS.application.user.placeOfBirth && isGermanCustomer;

            data = params || {};
            options = $.extend({}, defaultOptions, data);

            dlgOptions = {
                className: 'dialog-lockout dialog-lockout-' + options.lockout.exclusion_type,
                title: raceBetsJS.i18n.print('dialogResConfirm' + options.lockout.exclusion_type),
                content: raceBetsJS.application.templates.dialogs.lockout({
                    type: options.lockout.exclusion_type,
                    period: options.lockout.period,
                    oasis: options.lockout.oasis,
                    requestMaidenName: requestMaidenName,
                    requestPlaceOfBirth: requestPlaceOfBirth,
                    labelMaidenName: data.translations.label_maiden_name,
                    labelPlaceOfBirth: data.translations.label_place_of_birth,
                    oasisReasons: data.oasisReasons
                }),
                buttons: [
                    {
                        id: 'dlg-lockout-confirm',
                        label: raceBetsJS.i18n.data.buttonOK,
                        action: sendConfirmExclusion
                    },
                    {
                        id: 'dlg-lockout-cancel',
                        label: raceBetsJS.i18n.data.buttonCancel,
                        action: function () {
                            raceBetsJS.application.assets.overlay.close()
                        },
                        active: true
                    }
                ]
            };

            // show dialog
            raceBetsJS.application.assets.modalDialog.show($.extend({
                buttons: options.buttons,
                closeButton: options.closeButton,
                className: options.className,
                width: options.width
            }, dlgOptions));

            // init events
            initEvents();
        };

        var handleOasisReasons = function() {
            $oasisReasonItems = $('[id^="oasisReason"]:checkbox');

            $oasisReasonItems.change(
                function(){
                    var cantCombineId = '99';
                    var checked = $oasisReasonItems.filter(':checked').length;
                    var value = $(this).is(':checked') ? $(this).val() : null;

                    // if value is "99", disable all remaining checkboxes
                    // if value is other than "99", disable checkbox with value "99"
                    // enable all if nothing is selected
                    var thereIsSelection = checked > 0;
                    var isSelected99 = value === cantCombineId;
                    var isAlternativeSelected = !isSelected99 && thereIsSelection;

                    $oasisReasonItems.filter(function() {
                        if(isAlternativeSelected) $(this).attr('disabled', $(this).val() === cantCombineId);
                        else if(isSelected99) $(this).attr('disabled', $(this).val() !== cantCombineId);
                        else $(this).attr('disabled', false);
                    })
                });
        }

        var initEvents = function () {
            var form = document.forms.confirmExclusionForm;
            var $btnConfirm = $('#dlg-lockout-confirm');
            var $btnCancel = $('#dlg-lockout-cancel');
            var $finalNote = $('#dlg-lockout-cancel-notice');

            $lockoutDialog = $('.dialog-lockout')

            $pw = $('#confirmLockoutPassword');
            $confirmCheckbox = $('#confirmLockout');
            $btnConfirm.attr('disabled', true);

            handleOasisReasons();

            $lockoutDialog.on('change', '#confirmLockout, [id^="oasisReason"]:checkbox', function () {
                // Check checkboxes for German customers only
                var oasisReasonsSelected = isGermanCustomer ? $oasisReasonItems.filter(':checked').length > 0 : true;
                var confirmed = $confirmCheckbox.is(":checked");
                if (oasisReasonsSelected && confirmed) {
                    if($pw.attr('disabled') ) $pw.removeAttr('disabled');

                    // Remove disabled state if input has value and has no error
                    if($pw.val().trim() !== '' &&  !$pw.hasClass('hasError')) {
                        $btnConfirm.attr('disabled', false);
                    }
                    $finalNote.show();
                } else {
                    $pw.attr('disabled', true);
                    $btnConfirm.attr('disabled', true);
                    $finalNote.hide();
                }
            });

            $pw.on('keyup', activateSendBtn);

            if(requestPlaceOfBirth) {
                $pob = $('#placeOfBirth');
                $pob.on('keyup', function() {
                    $pob.removeClass('hasError');
                });
            }

            form.addEventListener('submit', sendConfirmExclusion);

            function activateSendBtn() {
                if ( $pw.val() !== '' ) {
                    $btnConfirm.removeAttr('disabled').addClass('btn-red');
                    $btnCancel.removeClass('btn-primary');
                } else {
                    $btnConfirm.removeClass('btn-red').attr('disabled', true);
                    $btnCancel.addClass('btn-primary');
                }
                if ($pw.hasClass('hasError')) {
                    $pw.removeClass('hasError');
                }
            }
        };

        var sendConfirmExclusion = function (e) {
            e.preventDefault();

            if (data.lockout.period === 'infinite') {
                data.lockout.period = null;
            }

            function proceedWithSelfExclusion() {
                options.Restrictions
                .setExclusion(
                    $.extend(
                        {
                            password: $pw.val()
                        },
                        data.lockout
                    )
                )
                .then(
                    function () {
                        raceBetsJS.application.assets.overlay.close();
                        showSuccessDialog(options.lockout.exclusion_type);
                    },
                    function (resp) {
                        var error = resp.data;
                        loadingLayer.remove();
                        if (error.data.code === 127) {
                            $('.overlay').find('.modal-dialog').last().shake();
                            $pw.addClass('hasError');
                        }
                    }
                );
            }

            if ( !$pw.attr('disabled') && $pw.val() !== '') {
                // Loader
                loadingLayer = $(raceBetsJS.application.templates.loadingLayer({
                    loadingStyle: 'white'
                }))

                // Add Oasis reasons
                // Reset values
                data.lockout.reasons = [];
                $oasisReasonItems.filter(':checked').each(function() {
                    data.lockout.reasons.push(this.value)
                 });

                // Manage maiden name and/or place of birth if password was input otherwise the submit button is disabled
                if(requestMaidenName || requestPlaceOfBirth)  {
                    var $mn = $('#maidenName');
                    var details = {};

                    // Optional. Add only if value provided
                    if(requestMaidenName) {
                        var maidenNameValue = $mn.val().trim();
                        if(maidenNameValue) {
                            details.maidenName = maidenNameValue
                        }
                    }

                    // Place of birth is mandatory
                    if(requestPlaceOfBirth) {
                        var placeOfBirthValue = $pob.val().trim();

                        // Show error if no value provided
                        if(!placeOfBirthValue) {
                            $('.overlay').find('.modal-dialog').last().shake();
                            $pob.addClass('hasError');
                            return;
                        } else {
                            details.placeOfBirth = placeOfBirthValue;
                        }
                    }

                    $lockoutDialog.find('.c-btn').attr('disabled', true);
                    $lockoutDialog.find('.content').append(loadingLayer);

                    // If no values provided, simply call excludeUser method.
                    // It can be that only the optional maidenName is missing and user did not provide it.
                    if(Object.keys(details).length === 0) {
                        // Process self-exclusion
                        proceedWithSelfExclusion();
                    } else {
                        // Save details
                        $.ajax({
                            url: '/rest/v1/users/me',
                            type: 'PUT',
                            contentType: "application/json; charset=utf-8",
                            dataType: "json",
                            data: JSON.stringify(details),
                            success: function() {
                                // Process self-exclusion
                                proceedWithSelfExclusion();
                            },
                            error: function (jqXHR) {
                                loadingLayer.remove();
                                var responseText = $.parseJSON(jqXHR.responseText);
                                raceBetsJS.application.assets.modalDialog.show({
                                    closeButton: true,
                                    content: '<p>' + responseText.errorMsg + '</p>'
                                });
                            }
                        });
                    }
                } else {
                    // Maiden name and place of birth is available
                    // Process self-exclusion
                    $lockoutDialog.find('.c-btn').attr('disabled', true);
                    $lockoutDialog.find('.content').append(loadingLayer);
                    proceedWithSelfExclusion();
                }
            } else {
                loadingLayer.remove();
                $('.overlay').find('.modal-dialog').last().shake();
                $pw.addClass('hasError');
            }
        };

        var showSuccessDialog = function (exclusionType) {
            var successContent = raceBetsJS.i18n.data.msgResLockoutSuccess + '<br><br>' + (exclusionType === 'SE' && raceBetsJS.application.user.country === 'GB' ? raceBetsJS.i18n.data.msgResLockoutSuccessGB : raceBetsJS.i18n.data.msgResLockoutSuccessRespGaming);
            raceBetsJS.application.assets.modalDialog.show({
                type: 'success lockout',
                content: successContent,
                closeButton: false,
                backgroundClose: false,
                buttons: [
                    {
                        id: 'dlg-lockout-reload',
                        label: raceBetsJS.i18n.data.buttonOK,
                        action: function () {
                            raceBetsJS.application.assets.overlay.showPleaseWait();
                            window.location.href = '/';
                        },
                        active: true
                    }
                ]
            });
        };

        return {
            show: show
        };

    }();

    raceBetsJS.application.assets.dialogs.newPasswordDialog = function () {
        // default options
        var defaultOptions = {
                closeButton: false,
                backgroundClose: false
            },
            options,
            keyUpTimer,
            ngUsersService;

        var show = function (params) {
            options = $.extend({}, defaultOptions, params || {});

            ngUsersService = options.service;

            var dlgOptions = {
                className: 'dialog-resetpass',
                title: raceBetsJS.i18n.data.dialogResetPassword,
                content: raceBetsJS.application.templates.dialogs.passwordReset,
                buttons: [
                    {
                        id: 'dlg-resetpass-confirm',
                        label: raceBetsJS.i18n.data.buttonSave,
                        active: true
                    }
                ]
            };

            // show dialog
            raceBetsJS.application.assets.modalDialog.show($.extend({
                buttons: options.buttons,
                closeButton: options.closeButton,
                className: options.className,
                width: options.width
            }, dlgOptions));

            // init events
            initEvents();
        };

        var initEvents = function () {
            $('#dlg-resetpass-confirm').attr('disabled', true);

            $('#dlg-resetpass-confirm').on('click', function (e) {
                e.preventDefault();
                resetPassword();
            });

            $('#confirmNewPassword')
                .on('keypress', function (e) {
                    if (e.which === 13) {
                        $('#dlg-resetpass-confirm').click();
                    }
                })
                .on('keyup', function (e) {
                    clearTimeout(keyUpTimer);

                    keyUpTimer = setTimeout(function () {
                        if ($('#confirmNewPassword').val() && $('#newPassword').val()) {
                            $('#dlg-resetpass-confirm').attr('disabled', false).addClass('btn-primary');
                        } else {
                            $('#dlg-resetpass-confirm').attr('disabled', true).removeClass('btn-primary');
                        }
                        if ($('#confirmNewPassword').hasClass('hasError') && e.which !== 13) {
                            $('#confirmNewPassword').removeClass('hasError');
                            Tipped.remove($('#confirmNewPassword'));
                        }
                    }, 50);
                });
        };

        var resetPassword = function () {
            var $newPass = $('#newPassword'),
                $confrimeNewPass = $('#confirmNewPassword');

            if (!$newPass.val() || $newPass.val() !== $confrimeNewPass.val()) {
                $confrimeNewPass.addClass('hasError');
                Tipped
                    .create($confrimeNewPass, raceBetsJS.i18n.data.errorPasswordDoesNotMatch, {
                        hook: 'rightmiddle',
                        maxWidth: 200
                    })
                    .show();
                return;
            }

            ngUsersService
                .resetPassword({
                    email: options.email,
                    hash: options.hash,
                    password: $newPass.val()
                })
                .then(
                    function (resp) {
                        raceBetsJS.application.assets.overlay.close();
                        raceBetsJS.application.assets.modalDialog.show({
                            type: 'success',
                            content: raceBetsJS.i18n.data.msgPasswordChanged,
                            closeButton: false,
                            buttons: [
                                {
                                    id: 'dlg-success-ok',
                                    label: raceBetsJS.i18n.data.buttonOK,
                                    action: function () {
                                        window.location.href = '/';
                                        raceBetsJS.application.assets.overlay.close();
                                        raceBetsJS.application.assets.overlay.showPleaseWait();
                                    },
                                    active: true
                                }
                            ]
                        });
                    },
                    function (resp) {
                        var error = resp.data;

                        switch (error.code) {
                            // Password not valid
                            case 107:
                                $('.dialog-resetpass .sub-header strong').html('');
                                $('.dialog-resetpass .sub-header')
                                    .prepend('<strong>' + raceBetsJS.i18n.data.errorPasswordNotValid + '<br/></strong>')
                                    .addClass('error');
                                $newPass.val('');
                                $confrimeNewPass.val('');
                                $newPass.focus();
                                $('#dlg-resetpass-confirm').attr('disabled', true).removeClass('btn-primary');
                                break;

                            // Password not valid
                            case 184:
                                $('.dialog-resetpass .sub-header strong').html('');
                                $('.dialog-resetpass .sub-header')
                                    .prepend('<strong>' + raceBetsJS.i18n.data.errorIdenticalUsernameAndPassword + '<br/></strong>')
                                    .addClass('error');
                                $newPass.val('');
                                $confrimeNewPass.val('');
                                $newPass.focus();
                                $('#dlg-resetpass-confirm').attr('disabled', true).removeClass('btn-primary');
                                break;

                            // Hash doesn't exist / expired
                            case 154:
                                raceBetsJS.application.assets.overlay.close();
                                raceBetsJS.application.assets.modalDialog.show({
                                    type: 'error',
                                    content: raceBetsJS.i18n.data.msgPasswordResetExpired,
                                    close: function () {
                                        window.location.href = '/account/forgotten';
                                    },
                                    buttons: [
                                        {
                                            id: 'dlg-error-ok',
                                            label: raceBetsJS.i18n.data.buttonOK,
                                            action: function () {
                                                window.location.href = '/account/forgotten';
                                            },
                                            active: true
                                        }
                                    ]
                                });
                                break;

                            default:
                                raceBetsJS.application.assets.overlay.close();
                                raceBetsJS.application.assets.modalDialog.show({
                                    type: 'error',
                                    content: raceBetsJS.i18n.print('msgUnknownError', { errorCode: error.code }),
                                    close: function () {
                                        window.location.href = '/';
                                    },
                                    buttons: [
                                        {
                                            id: 'dlg-error-ok',
                                            label: raceBetsJS.i18n.data.buttonOK,
                                            action: function () {
                                                window.location.href = '/';
                                            },
                                            active: true
                                        }
                                    ]
                                });
                        }
                    }
                );
        };

        return {
            show: show
        };
    }();

    raceBetsJS.application.assets.dialogs.loginDialog = (function () {
        // default options
        var defaultOptions = {
                type: '',
                hasSupport: true,
                hasLinks: true,
                closeButton: true,
                backgroundClose: false
            },
            defaultButtons,
            ngAuthService;

        var _sendLoginReqest = function () {
            raceBetsJS.browser.crossFrame.send("requestLogin");
        };

        var show = function (params) {
            var options = $.extend({}, defaultOptions, params || {});

            raceBetsJS.application.angularApp.commonCtrl().showLogin(options);
        };

        var angularLogin = function (options, service) {
            var buttons = [],
                dlgOptions;

            ngAuthService = service;

            initButtons();

            switch (options.type) {
                case 'loginRequired':
                    if (raceBetsJS.application.globals.isB2B) {
                        _sendLoginReqest();
                        raceBetsJS.application.assets.overlay.close();
                    } else {
                        dlgOptions = {
                            type: 'attention',
                            className: 'dialog-login dialog-login-required',
                            title: raceBetsJS.i18n.data.featureRequiresLogin,
                            content: raceBetsJS.application.templates.dialogs.login({
                                hasLinks: options.hasLinks
                            })
                        };
                        buttons.push(
                            defaultButtons.register,
                            $.extend({}, defaultButtons.login, { active: true })
                        );
                    }
                    break;

                case 'loginTimeout':
                    if (raceBetsJS.application.globals.isB2B) {
                        raceBetsJS.application.assets.modalDialog.show({
                            closeButton: true,
                            hasSupport: false,
                            hasLinks: true,
                            className: options.className,
                            content: raceBetsJS.i18n.data.ajaxNoticeLoggedOut,
                            closeCallback: function() {
                                _sendLoginReqest();
                                raceBetsJS.application.assets.overlay.close();
                            },
                            buttons: [
                                {
                                    label: raceBetsJS.i18n.data.buttonOK,
                                    action: function () {
                                        _sendLoginReqest();
                                        raceBetsJS.application.assets.overlay.close();
                                    }
                                }
                            ]
                        });
                    } else {
                        dlgOptions = {
                            type: 'attention',
                            className: 'dialog-login dialog-login-timeout',
                            title: raceBetsJS.i18n.data.labelLoginButton,
                            content: raceBetsJS.application.templates.dialogs.login({
                                heading: {
                                    icon: 'attention',
                                    content: raceBetsJS.i18n.data.ajaxNoticeLoggedOut
                                },
                                hasLinks: options.hasLinks
                            }),
                            closeCallback: reloadAction
                        };
                        buttons.push(
                            defaultButtons.reload,
                            $.extend({}, defaultButtons.login, { active: true })
                        );

                        raceBetsJS.userLogout();
                    }
                    break;

                case 'loginFailed':
                    if (raceBetsJS.application.globals.isB2B) {
                        _sendLoginReqest();
                        raceBetsJS.application.assets.overlay.close();
                    } else {
                        dlgOptions = {
                            type: 'error',
                            className: 'dialog-login dialog-login-failed',
                            title: raceBetsJS.i18n.data.titleNotRecognised,
                            content: raceBetsJS.application.templates.dialogs.login({
                                heading: {
                                    icon: 'attention',
                                    content: raceBetsJS.i18n.data.textLoginFailed +
                                        ' <a href="' + raceBetsJS.application.globals.urlPrefix + '/account/forgotten">' +
                                        raceBetsJS.i18n.data.linkForgottenDetails + '</a>'
                                }
                            })
                        };
                        buttons.push(
                            defaultButtons.forgotten,
                            $.extend({}, defaultButtons.login, { active: true })
                        );
                    }
                    break;
                case 'codeExpired':
                    dlgOptions = {
                        type: 'error',
                        className: 'dialog-login dialog-login-failed',
                        title: raceBetsJS.i18n.data.titleCodeExpired,
                        content: raceBetsJS.application.templates.dialogs.login({
                            heading: {
                                icon: 'attention',
                                content: raceBetsJS.i18n.data.msg2FACodeExpired +
                                    ' <a href="' + raceBetsJS.application.globals.urlPrefix + '/account/forgotten">' +
                                    raceBetsJS.i18n.data.linkForgottenDetails + '</a>'
                            }
                        })
                    };
                    buttons.push(
                        defaultButtons.forgotten,
                        $.extend({}, defaultButtons.login, { active: true })
                    );
                    break;
                default:
                    if (raceBetsJS.application.globals.isB2B) {
                        _sendLoginReqest();
                        raceBetsJS.application.assets.overlay.close();
                    } else {
                        dlgOptions = {
                            type: 'attention',
                            className: 'dialog-login',
                            title: raceBetsJS.i18n.data.labelLoginButton,
                            content: raceBetsJS.application.templates.dialogs.login({
                                hasLinks: options.hasLinks
                            })
                        };

                        buttons.push(
                            defaultButtons.register,
                            $.extend({}, defaultButtons.login, { active: true })
                        );
                    }
            }

            // show dialog
            if (!raceBetsJS.application.globals.isB2B && !$('.dialog-login').length) {
                raceBetsJS.application.assets.modalDialog.show($.extend({
                    buttons: buttons,
                    closeButton: options.closeButton,
                    hasSupport: options.hasSupport,
                    hasLinks: options.hasSupport,
                    className: options.className
                }, dlgOptions));
            }

            // init events
            initEvents();
        };

        var initButtons = function () {
            defaultButtons = {
                login: {
                    id: 'dlg-login-login',
                    label: raceBetsJS.i18n.data.labelLoginButton,
                    action: loginAction
                }
            };
            if (!raceBetsJS.application.globals.isB2B) {
                defaultButtons = $.extend(defaultButtons, {
                        register: {
                            id: 'dlg-login-register',
                            label: raceBetsJS.i18n.data.buttonRegister,
                            action: registerAction,
                        },
                        reload: {
                            id: 'dlg-login-reload',
                            label: raceBetsJS.i18n.data.buttonReload,
                            action: reloadAction
                        },
                        forgotten: {
                            id: 'dlg-login-forgotten',
                            label: raceBetsJS.i18n.data.linkForgottenDetails,
                            action: forgottenAction
                        }
                    });
            }
        };

        var loginAction = function () {
            var username = $('#dlg-login-username'),
                password = $('#dlg-login-password');

            if (username.val() === '') {
                username.focus().addClass('hasError');
                return;
            } else {
                username.removeClass('hasError').addClass('isValid');
            }

            if (password.val() === '') {
                password.focus().addClass('hasError');
                return;
            } else {
                password.removeClass('hasError').addClass('isValid');
            }

            $('#dlg-login-login').attr('disabled', true);

            ngAuthService
                .login({
                    username: username.val(),
                    password: password.val()
                });
        };

        var registerAction = function () {
            if (raceBetsJS.application.globals.isPopup) {
                raceBetsJS.application.assets.overlay.close();
                window.opener.location = raceBetsJS.application.globals.urlPrefix + '/register';
            } else {
                raceBetsJS.application.assets.overlay.close();
                raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/register');
            }
        };

        var forgottenAction = function () {
            raceBetsJS.application.assets.overlay.close();
            if (raceBetsJS.application.globals.isPopup) {
                window.opener.location = raceBetsJS.application.globals.urlPrefix + '/account/forgotten';
            } else {
                raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + '/account/forgotten');
            }
        };

        var reloadAction = function () {
            location.reload();
        };

        var initEvents = function () {
            $('#dlg-login-username, #dlg-login-password').keypress(function (e) {
                if (e.which === 13) {
                    $('#dlg-login-login').click();
                }
            });

            $('#dlg-login-username').focus();

            $('.dialog-login .openLiveChat').on('click', function (e) {
                e.preventDefault();
                window.open(raceBetsJS.application.assets.settings.getBrandSettings("supportURLs", "contact"),'_blank');
            });

            $('#dlg-login-forgotten').on('click', function () {
                // Track event on link and forgotten button
                dataLayer.push({
                    'event': 'ForgottenPassword'
                });
            });
        };

        return {
            show: show,
            angularLogin: angularLogin
        };
    })();

    raceBetsJS.application.assets.dialogs.login2faDialog = (function () {
        var defaultOptions = {},
            defaultButtons,
            idAccount,
            ngAuthService;


        var show = function (params) {
            var options = $.extend({}, defaultOptions, params || {});

            raceBetsJS.application.angularApp.commonCtrl().showLogin2fa(options);
        };

        var angularLogin = function (options, service) {
            raceBetsJS.application.assets.overlay.close();
            idAccount = options.idAccount
            ngAuthService = service

            dlgOptions = {
                type: 'attention',
                buttons: buttons,
                className: 'dialog-login-second-step',
                title: raceBetsJS.i18n.data.labelVerificationRequired,
                content: raceBetsJS.application.templates.dialogs.loginSecondStep()
            };

            var options = $.extend({}, defaultOptions, options || {});
            var buttons = [];

            initButtons();

            buttons.push(
                $.extend({}, defaultButtons.save, { active: true })
            );

            dlgOptions.buttons = buttons
            raceBetsJS.application.assets.modalDialog.show(dlgOptions);
            initEvents();
        }

        function sendCode () {
            raceBetsJS.application.assets.overlay.showPleaseWait();
            ngAuthService.login2fa({
                code: $("#dlg-login-code").val(),
                id: idAccount
            });
        };

        function initButtons () {
            defaultButtons = {
                save: {
                    id: 'dlg-verify-send',
                    label: raceBetsJS.i18n.data.labelVerifyCode,
                    action: sendCode
                },
                cancel: {
                    id: 'dlg-verify-cancel',
                    label: raceBetsJS.i18n.data.buttonCancel,
                    action: function() {
                        raceBetsJS.application.assets.overlay.close();
                    }
                }
            };
        };

        var initEvents = function () {
            $('#dlg-login-code').keypress(function (e) {

                if (e.which === 13) {
                    e.preventDefault();
                    $('#dlg-verify-send').click();
                }
            });

            $('#dlg-login-code').focus();
        };

        return {
            show: show,
            angularLogin: angularLogin
        };
    })()

    raceBetsJS.application.assets.dialogs.runnerNotes = (function () {
        // default options
        var defaultOptions = {
                className: 'no-padding dialog-notes dialog-runnernotes',
                hasLinks: true,
                closeButton: true,
                backgroundClose: true
            },
            defaultButtons,
            noteContent = '',
            idSubject,
            eventTarget;

        var show = function (option) {
            var options = $.extend({}, defaultOptions, option || {}),
                title, content, buttons = [];

            noteContent = options.note;
            idSubject = options.idSubject;
            eventTarget = options.eventTarget;

            initButtons();

            var dlgOptions = {
                title: options.title,
                content: raceBetsJS.application.templates.dialogs.runnerNotes({
                    runnerNote: options
                })
            };

            buttons.push(
                defaultButtons.cancel,
                $.extend({}, defaultButtons.save, { active: true })
            );

            // show dialog
            raceBetsJS.application.assets.modalDialog.show($.extend({
                title: options.title,
                buttons: buttons,
                className: options.className,
                closeButton: options.closeButton,
                hasLinks: options.hasLinks,
                backgroundClose: options.backgroundClose
            }, dlgOptions));

            getNote();

            initEvents();
        };

        var initButtons = function () {
            defaultButtons = {
                save: {
                    id: 'dlg-runnernotes-save',
                    label: raceBetsJS.i18n.data.modalRunnerNotesSaveButton,
                    action: saveNote
                },
                cancel: {
                    id: 'dlg-runnernotes-cancel',
                    label: raceBetsJS.i18n.data.buttonCancel,
                    action: cancelNote
                }
            };
        };

        var initEvents = function () {
            var $runnernotes = $('.dialog-runnernotes'),
                keyUpTimer;

            $('#dlg-runnernotes-save').removeClass('btn-primary').attr('disabled', true);

            $runnernotes.on('keyup', '#dlg-runnernote-textarea' ,function (e) {
                var $this = $(this);

                clearTimeout(keyUpTimer);

                keyUpTimer = setTimeout(function () {
                    compareNote($this.val(), noteContent);
                }, 100);
            });
        };

        var getNote = function () {
            $.ajax({
                url: '/ajax/favourites/getnote/',
                type: 'post',
                data: {
                    id: idSubject
                },
                success: function (data) {
                    noteContent = data.note;
                    $('#dlg-runnernote-textarea')
                        .val(noteContent)
                        .attr('disabled', false);
                    $('#dlg-content-loading').fadeOut('fast').css('height','0');
                },
                error: function (jqXHR) {
                    var responseText = $.parseJSON(jqXHR.responseText);

                    switch (responseText.errorMsg) {
                        case 'LOGIN_REQUIRED':
                            raceBetsJS.application.assets.overlay.close();
                            raceBetsJS.application.assets.dialogs.loginDialog.show();
                            break;
                        default:
                            raceBetsJS.application.assets.modalDialog.show({
                                closeButton: true,
                                content: '<p>' + responseText.errorMsg + '</p>'
                            });
                    }
                }
            });
        };

        var compareNote = function (newVal, originalVal) {
            var newContent =  newVal,
                $runnernotesBtnSave = $('#dlg-runnernotes-save');

            if (newContent !== noteContent) {
                $runnernotesBtnSave.addClass('btn-primary').attr('disabled', false);
            } else {
                $runnernotesBtnSave.removeClass('btn-primary').attr('disabled', true);
            }
        };

        var saveNote = function () {
            var $this = this,
                newContent = $('#dlg-runnernote-textarea').val(),
                $runnernotesButtons = $('#dlg-runnernotes-save, #dlg-runnernotes-cancel'),
                ajaxInProgress = false;

            $('#dlg-content-loading').show();

            if (newContent !== noteContent) {
                ajaxInProgress = true;
                $runnernotesButtons.attr('disabled', true);

                $.ajax({
                    url: '/ajax/favourites/savenote/',
                    type: 'post',
                    data: {
                        id: idSubject,
                        note: newContent
                    },
                    success: function () {
                        if (newContent !== '') {
                            eventTarget.addClass('active');
                        } else {
                            eventTarget.removeClass('active');
                        }
                        raceBetsJS.application.content.favourites.displayNote(idSubject, newContent);
                        raceBetsJS.application.assets.overlay.close();
                    },
                    error: function () {
                        $('#dlg-content-loading').fadeOut('fast');
                    }
                });
            }
        };

        var cancelNote = function () {
            raceBetsJS.application.assets.overlay.close();
        };

        return {
            show: show
        };
    })();

    raceBetsJS.application.assets.dialogs.sessionsDialog = function () {
        // default options
        var defaultOptions = {
                closeButton: true,
                backgroundClose: false,
                closeCallback: function () {
                    location.reload();
                }
            },
            selectedSessions = [];

        var data,
            options;

        var show = function (params) {
            data = params || {};
            options = $.extend({}, defaultOptions, data);

            raceBetsJS.application.assets.overlay.close();
            raceBetsJS.application.assets.modalDialog.show({
                className: 'dialog-sessions',
                closeButton: options.closeButton,
                backgroundClose: options.backgroundClose,
                closeCallback: options.closeCallback,
                content: raceBetsJS.application.templates.dialogs.sessions({
                    sessions: options.sessions
                }),
                buttons: [
                    {
                        id: 'dlg-sessions-confirm',
                        label: raceBetsJS.i18n.data.buttonLogoutSessions,
                        action: function (e) {
                            e.preventDefault();
                            logoutSessions(selectedSessions, $('#hideSessionsDialog').attr('checked') ? true : false);
                        }
                    },
                    {
                        id: 'dlg-sessions-cancel',
                        label: raceBetsJS.i18n.data.buttonClose,
                        action: function () {
                            if ($('#hideSessionsDialog').attr('checked')) {
                                logoutSessions([], true);
                            } else {
                                raceBetsJS.application.assets.overlay.close();
                                raceBetsJS.application.assets.overlay.showPleaseWait();
                                location.reload();
                            }
                        },
                        active: true
                    }
                ]
            });

            // init events
            initEvents();
        };

        var initEvents = function () {
            var $sessionsDialog = $('.dialog-sessions'),
                $sessionsForm = $('#sessionsForm');

            $('#dlg-sessions-confirm').attr('disabled', true);

            $sessionsForm.on('change', '.c-dataList input[type=checkbox]', function () {
                var sessionId = $(this).val();
                if (selectedSessions.indexOf(sessionId) > -1) {
                    selectedSessions.splice(selectedSessions.indexOf(sessionId), 1);
                } else {
                    selectedSessions.push(sessionId);
                }

                if (selectedSessions.length) {
                    $sessionsDialog.find('#dlg-sessions-cancel').removeClass('btn-primary');
                    $sessionsDialog.find('#dlg-sessions-confirm').addClass('btn-primary').attr('disabled', false);
                } else {
                    $sessionsDialog.find('#dlg-sessions-confirm').removeClass('btn-primary').attr('disabled', true);
                    $sessionsDialog.find('#dlg-sessions-cancel').addClass('btn-primary');
                }
            });
        };

        var logoutSessions = function (sessions, hideDialog) {
            var deferred = $.Deferred();
            var data = {
                sessions: sessions,
                hide_sessions: hideDialog
            };

            raceBetsJS.application.assets.overlay.showPleaseWait();

            var promise = $.ajax({
                url: '/rest/v1/users/me/logout/sessions',
                type: 'post',
                dataType: 'json',
                contentType: 'application/json;charset=UTF-8',
                data: $.stringify(data)
            });

            promise.then(
                function (resp) {
                    deferred.resolve(resp);
                    location.reload();
                },
                function (resp) {
                    deferred.reject(resp);
                    raceBetsJS.application.assets.modalDialog.show({
                        type: 'error',
                        content: raceBetsJS.i18n.print('msgUnknownError', { errorCode: data.errorCode }),
                        buttons: [{
                            label: raceBetsJS.i18n.data.buttonOK,
                            action: function () {
                                location.reload();
                            }
                        }]
                    });
                }
            );
        };

        return {
            show: show
        };
    }();

    raceBetsJS.application.assets.dialogs.jurisdictionRedirect = function () {
        // default options
        var defaultOptions = {
            closeButton: true,
            backgroundClose: false
        };

        var data,
            options;

        var show = function (params) {
            data = params || {};
            options = $.extend({}, defaultOptions, data);

            raceBetsJS.application.assets.overlay.close();
            raceBetsJS.application.assets.modalDialog.show({
                type: 'error',
                className: 'dialog-jurisdiction',
                closeCallback: function () {
                    raceBetsJS.application.assets.overlay.close();
                },
                content: raceBetsJS.application.templates.dialogs.jurisdictionRedirect({
                    message: options.msg,
                    label: options.inputLabel
                }),
                buttons: [
                    {
                        id: 'dlg-jurisdiction-confirm',
                        label: options.btnLabel,
                        action: function () {
                            var remember = $('#rememberChoice').is(':checked');
                            options.action(remember);
                        },
                        active: true
                    }
                ]
            });
        };

        return {
            show: show
        };

    }();

    raceBetsJS.application.assets.dialogs.imageDialog = function () {

        var defaultOptions = {
            closeButton: true,
            backgroundClose: false,
            className: 'dialog-picture',
            buttons: false
        };

        var show = function (params) {
            var data = params || {},
                promises = $.Deferred();

            // preloads image before displays it
            (function (url, promise) {
                var img = new Image();
                img.onload = function () {
                    promise.resolve();
                };
                img.src = url;
            })(data.imageUrl, promises);

            $.when(promises).done(function () {
                raceBetsJS.application.assets.overlay.close();
                raceBetsJS.application.assets.modalDialog.show($.extend({
                    title: data.title,
                    width: data.width,
                    content: raceBetsJS.application.templates.dialogs.picture({ src: data.imageUrl, url: data.url }),
                }, defaultOptions));
            });
        };

        return {
            show: show
        };
    }();

    raceBetsJS.application.assets.dialogs.fantasyWelcomeDialog = function() {
        var fantasyCookie = 'FANTASY_WELCOME';
        var show = function (params) {
            if (!$.cookie(fantasyCookie)) {
                var date = new Date();
                date.setYear(date.getFullYear() + 5);

                $.cookie(fantasyCookie, JSON.stringify({ seen: true }), { secure: true, path: '/', expires: date});
                raceBetsJS.application.assets.modalDialog.show({content: params.content});
            }
        }
        return {
            show: show
        };
    }();

    raceBetsJS.application.assets.dialogs.adventDialog = function () {

        var defaultOptions = {
            closeButton: true,
            backgroundClose: false,
            className: 'dialog-advent',
            buttons: false
        };

        var show = function (params) {
            var data = params || {},
                promises = $.Deferred();

            (function (url, promise) {
                var img = new Image();
                img.onload = function () {
                    promise.resolve();
                };
                img.src = url;
            })(data.image.src, promises);

            $.when(promises).done(function () {
                raceBetsJS.application.assets.overlay.close();
                raceBetsJS.application.assets.modalDialog.show($.extend({
                    title: data.title,
                    width: data.width,
                    content: raceBetsJS.application.templates.dialogs.advent({ message: data.message, src: data.image.src, url: data.image.url }),
                }, defaultOptions));
            });
        };

        return {
            show: show
        };
    }();

    raceBetsJS.application.assets.dialogs.acceptNewTerms = function () {
        // default options
        var defaultOptions = {
            closeButton: true,
            buttons: false,
            width: 992
        };

        var data, options,
            $termsDialog, $termsLoading, $termsChanges, $termsFull,
            $termsShowChanges, $termsShowFull, $termsAccept,
            maxHeight = 0;

        // for content caching
        var content = {
            summary: { url: function (lang) { return '/rest/v2/terms/' + lang + '/summary' }, data: '' },
            terms: { url: function (lang) { return '/rest/v2/terms/' + lang }, data: '' }
        };

        // show dialog
        var show = function (params) {
            var dlgOptions;

            data = params || {};
            options = $.extend({}, defaultOptions, data);

            dlgOptions = {
                title: options.title,
                className: 'dialog-terms',
                content: raceBetsJS.application.templates.dialogs.newTerms({
                    labelAccept: options.labelAccept,
                    labelViewFullTerms: options.labelViewFullTerms,
                    labelViewTermsChanges: options.labelViewTermsChanges,
                    labelTermsChanges: options.labelTermsChanges
                })
            };

            raceBetsJS.application.assets.modalDialog.show($.extend({
                buttons: options.buttons,
                closeButton: options.closeButton,
                width: options.width
            }, dlgOptions));

            initEvents();
            loadSummary();
        };

        // init events
        var initEvents = function () {
            $termsDialog = $('.dialog-terms');
            $termsLoading = $termsDialog.find('#dlg-terms-loading');
            $termsChanges = $termsDialog.find('#dlg-terms-changes');
            $termsFull = $termsDialog.find('#dlg-terms-full');
            $termsAccept = $termsDialog.find('#dlg-terms-accept');
            $termsShowFull = $termsDialog.find('#dlg-terms-show-full');
            $termsShowChanges = $termsDialog.find('#dlg-terms-show-changes');

            $termsAccept.on('click', function () {
                $termsAccept.attr('disabled', true).removeClass('c-btn--primary').addClass('c-btn--disabled');
                options.action();
            });

            $termsShowFull.on('click', function () {
                if (!content.terms.data) {
                    loadFullTerms();
                    return;
                }
                showFullTerms();
            });

            $termsShowChanges.on('click', function () {
                showSummary();
            });
        };

        var loadSummary = function() {
            $termsLoading.show();

            var promise = $.ajax({
                url: content.summary.url(raceBetsJS.application.user.lang),
                type: 'GET',
                contentType: 'application/json;charset=UTF-8'
            });

            return promise.then(
                function (response) {
                    if (response.data && response.data.trim() !== '') {
                        showSummary(response.data);
                    } else {
                        loadFullTerms();
                    }
                },
                function (response) {
                    loadFullTerms();
                }
            );
        };

        var loadFullTerms = function() {
            $termsLoading.show();

            var promise = $.ajax({
                url: content.terms.url(raceBetsJS.application.user.lang),
                type: 'GET',
                contentType: 'application/json;charset=UTF-8'
            });

            return promise.then(
                function(response) {
                    if (response.data) {
                        showFullTerms(response.data);
                    }
                }
            );
        };

        var showFullTerms = function(data) {
            $termsLoading.show();

            if (data) {
                content.terms.data = data;
                $termsFull.html(content.terms.data);
            }

            $termsChanges.hide();
            $termsFull.show();
            if (content.summary.data) {
                $termsShowChanges.show();
            }
            $termsShowFull.hide();
            setMaxHeight($termsFull);
            $('.modal-dialog').css({
                top: raceBetsJS.application.assets.overlay.calculateTopPos($('.modal-dialog'), {marginTop: 20})
            });
            $termsLoading.fadeOut('fast');
        }

        var showSummary = function(data) {
            $termsLoading.show();

            if (data) {
                content.summary.data = data;
                $termsChanges.html('<h2>'+ options.labelTermsChanges +'</h2>' + content.summary.data);
            }

            $termsFull.hide();
            $termsChanges.show();
            $termsShowFull.show();
            $termsShowChanges.hide();
            setMaxHeight($termsChanges);
            $('.modal-dialog').css({
                top: raceBetsJS.application.assets.overlay.calculateTopPos($('.modal-dialog'), {marginTop: 20})
            });
            $termsLoading.fadeOut('fast');
        }

        var setMaxHeight = function($content) {
            var $contentNotScrolled = $termsDialog.find('.title, .footer'),
                windowHeight = $(window).height(),
                dlgMargin = ( $termsDialog.offset().top - $(window).scrollTop() ) * 2,
                contentNotScrolledHeight = 0;

            $.each($contentNotScrolled, function() {
                contentNotScrolledHeight += $(this).outerHeight();
            });

            maxHeight = windowHeight - dlgMargin - contentNotScrolledHeight;

            $content.css({
                'max-height': maxHeight
            });

            if ($content.outerHeight() > maxHeight) {
                $termsDialog.addClass('scroll');
            } else {
                $termsDialog.removeClass('scroll');
            }
        };

        return {
            show: show
        };

    }();

    raceBetsJS.application.assets.dialogs.xtremePushOptInDialog = function () {
        var defaultOptions = {
                closeButton: true,
                backgroundClose: false,
                closeCallback: function () {
                    raceBetsJS.application.assets.overlay.close();
                }
            };

        var data,
            options;

        var show = function (params) {
            data = params || {};
            options = $.extend({}, defaultOptions, data);

            raceBetsJS.application.assets.overlay.close();
            raceBetsJS.application.assets.modalDialog.show({
                title: window.rb_i18n_angular_json.label_push_notifications,
                className: 'dialog-xtremepush',
                closeButton: options.closeButton,
                backgroundClose: options.backgroundClose,
                closeCallback: options.closeCallback,
                content: raceBetsJS.application.templates.dialogs.xtremePushOptIn(),
                buttons: [
                    {
                        id: 'opt-in-allow',
                        label: window.rb_i18n_angular_json.label_allow,
                        action: function (e) {
                            e.preventDefault();
                            raceBetsJS.application.assets.overlay.close();
                            raceBetsJS.application.assets.settings.xtremePushOptInCb();
                        },
                        active: true
                    },
                    {
                        id: 'opt-in-disallow',
                        label: window.rb_i18n_angular_json.label_disallow,
                        action: function (e) {
                            e.preventDefault();
                            raceBetsJS.application.assets.overlay.close();
                            raceBetsJS.application.assets.settings.xtremePushOptOutCb();
                        }
                    }
                ]
            });
        };

        return {
            show: show
        };
    }();

    raceBetsJS.application.assets.dialogs.lugasLimit = function () {
         var defaultOptions = {
            closeButton: true,
            buttons: false,
            width: 800,
        };

        var data, options;

        var show = function (params) {
            data = params || {};
            options = $.extend({}, defaultOptions, data);

            options.content = raceBetsJS.application.templates.dialogs.limitLugas({contentMsg: options.content});

            raceBetsJS.application.assets.modalDialog.show($.extend({
                buttons: options.buttons,
                closeButton: options.closeButton,
                width: options.width
            }, options));



            if (options.buttons && options.buttons[0] && options.buttons[0].id) {
                var id = options.buttons[0].id;

                $('#' + id).attr('disabled', 'disabled');
                $('#' + id).removeClass('btn-primary');

                $('#lugasCheckbox').click(function (checkbox) {
                    if ($(this).prop('checked')) {
                        $('#' + id).removeAttr('disabled');
                        $('#' + id).addClass('btn-primary');
                    } else {
                        $('#' + id).attr('disabled', 'disabled');
                        $('#' + id).removeClass('btn-primary');
                    }
                });
            }
        };

        return {
            show: show
        };
    }()

    raceBetsJS.application.assets.dialogs.duplicateAccount = function () {
        var show = function () {
            raceBetsJS.application.assets.overlay.close();
            raceBetsJS.application.assets.modalDialog.show({

                closeButton: true,
                backgroundClose: false,
                closeCallback: function () {
                    location.reload();
                },
                content: raceBetsJS.i18n.data.errorAccountDuplicate,
                buttons: [
                    {
                        label: raceBetsJS.i18n.data.buttonOK,
                        action: function () {
                            raceBetsJS.application.assets.overlay.close();
                            raceBetsJS.application.assets.overlay.showPleaseWait();
                            location.reload();
                        },
                        active: true
                    }
                ]
            });
        };
        return {
            show: show
        };
    }()


})(raceBetsJS);
