/**
* Object
*/
(function(raceBetsJS) {
	raceBetsJS.tipped = {

        /**
        * Tipped wrapper
        */
        create: function(selector, source, options) {
            if (options.forceVisibility === true || (!raceBetsJS.browser.type.isiPad() && !raceBetsJS.browser.type.isiPhone())) {
                Tipped.create(selector, source, options);
            }
        }
    };
})(raceBetsJS);