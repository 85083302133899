/**
* Homepage "Top Winnings" module
*
* @author David Calleja
*/
(function(raceBetsJS) {
	raceBetsJS.application.content.home.topWinnings = function() {
		// @private
		var panel_template = raceBetsJS.application.templates.panel,
			content_template = raceBetsJS.application.templates.home.topWinnings,
			panel_data = {
				'title':'',
				'cssClass':'c-widget--topWinnings light with-rows',
				'id':'winnings',
				'more':null
			},
			content_area_selector = '.content_container',
			el = null,
			data = {};

		var render = function(area){
			var content = $('<ul>');

			$.each(
				data,
				function(i){
					var li = $('<li>');
					if((i+1)%2 == 0){
						li.addClass('even');
					}
					content.append(li.append(content_template(this)));
				}
			);

			if(el == null){
				area.append(panel_template($.extend(panel_data, {'content': $('<div>').append( content.eq(0).clone() ).html() })));
				el = area.find('#' + panel_data.id).find(content_area_selector);
			} else {
				el.empty();
				el.append(content);
			}
		};


		var remove = function(){
			el = null;
		};

		var setData = function(json){
			data = json;
		};

		// @public
		return {
			init: function(json) {
				if(json != null && !_.isEmpty(json)){
					setData(json);

                    //set translations
                    panel_data.title = raceBetsJS.i18n.data.headTopWinners;

					return this;
				} else if(el != null){
					return this;
				}
				return false;
			},
			render: render,
			remove: remove
		};
	}();
})(raceBetsJS);
