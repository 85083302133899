/**
* Homepage "Head to Head" module
*
* @author David Calleja
*/
(function(raceBetsJS) {
	raceBetsJS.application.content.home.head2head = (function() {
		// @private
		var panel_template = raceBetsJS.application.templates.panel,
			content_template = raceBetsJS.application.templates.home.head2head,
			panel_data = {
				'title':'',
				'cssClass':'c-widget--h2h dark with-rows',
				'id':'head-to-head'
			},
			content_area_selector = '.content_container',
			el = null,
			currentEvent = 0,
			data = {};

		var render = function(area){

			checkData(currentEvent);

			if(el == null){
				area.append(panel_template($.extend(panel_data, {'content': '' })));
				el = area.find('#' + panel_data.id).find(content_area_selector);
				eventsInit();
			}

			reload();

			if(data.length < 2){
				el.find('.controls').hide();
			}
		};

		var checkData = function(ev){
			if(data[ev] == null){
				currentEvent = 0;
			}
		};

		var reload = function(){
			var showSilks = true;
			el.empty();

			$.each(
				data[currentEvent].topRunners,
				function(){
					if(this.silkExtension === "" || this.silkExtension === null){
						showSilks = false;
					}
				}
			);

			var content = $('<div>').append(content_template($.extend(data[currentEvent],{'showSilks':showSilks})));
			el.append(content.html());
			setPanelLink();
			createOddsButtons();
		};


		var setPanelLink = function(){
			el.parents('.panel').find('.more').find('a').attr('href',raceBetsJS.application.globals.urlPrefix + "/event/details/id/" + data[currentEvent].idEvent);
		}

		var eventsInit = function(){
			el.on('mouseup', '.odds-button.fixed.enabled', function(e) {
				e.preventDefault();
				raceBetsJS.application.assets.bettingDialog.show(
					this,
					{
						onOddsChange: $.proxy(
							function(newOdds) {
								replaceOdds(el.find('.odds-button[data-id-race="' + $(this).data('id-race') + '"]'));
							},
							this
						)
					}
				);
			});
			el.on('mouseup', '.odds-button.price.enabled', function() {
				raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + $(this).attr("data-id-race"));
			});
			el.on('click', '.controls [data-direction]', function(e) {
				e.preventDefault();
				changeEvent($(this).data('direction'));
			});
		};

		var changeEvent = function(direction){
			if(!raceBetsJS.application.assets.bettingDialog.isVisible()){
				switch(direction){
					case 'next':
						currentEvent++;
						if(currentEvent >= data.length){
							currentEvent = 0;
						}
						break;
					case 'prev':
						currentEvent--;
						if(currentEvent < 0){
							currentEvent = data.length - 1;
						}
						break;
				}
				if(currentEvent >= data.length){
					currentEvent = 0;
				}
				reload();
			}
		};

		var replaceOdds = function(elements){
			var ids = [];
			$.each(
				elements,
				function(){
					ids.push($(this).data('id-runner'));
				}
			);
			$.post(
				'/ajax/races/odds',
				{'runners':ids.join(',')},
				function(data){
					$.each(
						data,
						function(runner,odds){
							var element = el.find('.odds-button[data-id-runner="' + runner + '"]').find('span');
							element.html(raceBetsJS.format.odds(odds, null, true));
							element.data('odds', odds);
						}
					)
				}
			);
		};

		var createOddsButtons = function(){
			$.each(
				el.find('.runners').find('ul').find('.odds-button'),
				function(){
					var fixedOdds = $(this).hasClass('fixed'),
						button = null;

					if(fixedOdds){
						button = new raceBetsJS.application.content.race.OddsButton($(this), 'FXW');
					} else {
						button = new raceBetsJS.application.content.race.OddsButton($(this), 'PRC');
					}
					button.setOdds($(this).children('span').data('odds'), $(this).hasClass('enabled'));
				}
			);
		};

		var remove = function(){
			el = null;
			currentEvent = 0;
		};

		var setData = function(json){
			data = json;
		};

		// @public
		return {
			init: function(json) {
				if(json != null && !_.isEmpty(json)){
					setData(json);

                    //set translations
                    panel_data.title = raceBetsJS.i18n.data.headToHead;

		            //Initialize 'more' link
		            panel_data.more = {
		                text: raceBetsJS.i18n.data.linkMore,
		                link: raceBetsJS.application.globals.urlPrefix + "/event/details/id/" + data[currentEvent].idEvent
		            };

					return this;
				} else if(el != null){
					return this;
				}
				return false;
			},
			render: render,
			remove: remove,
			el:el
		};
	})();
})(raceBetsJS);
