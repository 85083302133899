/**
* @description event template
*
* TODO: Special Bets > Take Parent Events Title
*/
(function(raceBetsJS) {
	/**
	* Event template
	*/
	raceBetsJS.application.templates.event = _.template(
        '<%= raceBetsJS.application.templates.event.banner({ banner: banner.racecard }) %>'+
        '<div class="rounded event <%= (event.isAntePost) ? "ante-post" : "" %> <%= (event.isSpecialBets) ? "special-bets" : "" %> <%= (event.specialBetType == "H2H") ? "head-to-head" : "" %>">'+
            '<%= raceBetsJS.application.templates.event.header({ event: event }) %>'+
            '<ul>'+
                '<% if (event.isSpecialBets && event.specialBetType == "H2H") { %>'+
                    '<%= raceBetsJS.application.templates.event.special.h2h({ races: races, event: event }) %>'+
                '<% } else if (event.isSpecialBets) { %>'+
                    '<%= raceBetsJS.application.templates.event.special({ races: races }) %>'+
                '<% } else { %>'+
                    '<% $.each(races, function(idRace) { %>'+
                        '<%= raceBetsJS.application.templates.event.race({ event: { country: event.country, media: event.media }, race: races[idRace] }) %>'+
                    '<% }); %>'+
                '<% } %>'+
            '</ul>'+
		'</div>'
	);

    /**
     * Top banner
     */
     raceBetsJS.application.templates.event.banner = _.template(
        '<% if (banner) { %>'+
            '<div class="event-banner">'+
                '<% if (banner.termsUrl) { %>'+
                    '<a class="termsLink grey-arrows" href="<%= banner.termsUrl %>" target="_blank"><%= raceBetsJS.i18n.data.labelTAndC %></a>'+
                '<% } %>'+
                '<a href="<%= banner.url %>" <% if (banner.target === "_blank") { %>target="_blank" <% } %> class="imgLink <%= (banner.url.substr(0,4) != "http" ? "ajaxify" : "") %>">'+
                    '<img src="<%= banner.imagePath %>" />'+
                '</a>'+
            '</div>'+
        '<% } %>'
    );

    /**
    * Header
    */
    raceBetsJS.application.templates.event.header = _.template(
        '<div class="event-heading">'+
            '<% if (event.idEventParent) { %>' +
                '<a href="event/details/id/<%= event.idEventParent %>" class="back ajaxify">' +
                    '<span class="c-iconFont c-iconFont--caret-left"></span>' +
                '</a>' +
            '<% } %>' +
            '<h1>'+
                '<span class="c-flag isCountry<%= event.country %>"></span>'+
                '<%= event.title %>'+
            '</h1>'+
            '<p class="details">'+
                '<span class="date-locale" data-timestamp="<%= (typeof race == "undefined") ? event.firstStart : race.postTime %>" data-date-format="<%= raceBetsJS.i18n.data.dateFormatDateWeekday %>"></span> &nbsp;|&nbsp;'+
                '<%= (!event.isSpecialBets) ? raceBetsJS.i18n.data["raceType" + event.raceType] : raceBetsJS.i18n.data[((event.specialBetType == "H2H") ? "headToHead" : "specialBets")] %>'+
            '</p>'+
            '<% if (event.media !== undefined) { %>'+
                '<a class="live-stream stream-<%= event.media.type %> inactive" data-start="<%= event.media.startTime %>">'+
                	'<span class="icon"></span>'+
                	'<span class="link grey-arrows">'+
                        '<%= raceBetsJS.i18n.print("infoLiveStreamStart", {"time" : raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatTime, event.media.startTime) }) %>'+
                	'</span>'+
                '</a>'+
            '<% } %>'+
        '</div>'
    );

	/**
	* Race row
	*/
	raceBetsJS.application.templates.event.race = _.template(
		'<li id="race-<%= race.idRace %>" class="race" data-id-race="<%= race.idRace %>">'+
			'<div class="racedetails">'+
				'<div class="time">'+
					'<span class="ordinal status-<%= race.raceStatus %>">'+
                        '<%= race.raceNumber %>'+
                        '<% if (race.is_priced_up) { %>'+
                            '<span class="c-tag c-tag--s isPricedUp" title="<%= raceBetsJS.i18n.data.betslipLabelFXD %>"><%= raceBetsJS.i18n.data.labelPricedUpAbbr %></span>'+
                        '<% } %>'+
                    '</span>'+
					'<span class="post-time"><%= raceBetsJS.application.assets.timezone.date("H:i", race.postTime) %></span>'+
				'</div>'+
				'<div class="details">'+
                    '<span class="row title">'+
                        '<strong><%= raceBetsJS.i18n.getRaceTitle(race.raceTitle, race.raceNumber) %></strong>'+
                        '<% if(race.category != "") { %>'+
                            '<% if(race.raceType == "D") { %>'+
                                '<%= "- " + race.category %>'+
                            '<% } else { %>'+
                                '<%= " - " + raceBetsJS.i18n.data["raceCategory" + race.category] %>'+
                            '<% } %>'+
                        '<% } %>'+
                        '<% if(race.categoryLetter !== "") { %>'+
                            '<span class="category-letter">'+
                                '<% if(event.country == "FR") { %>'+
                                    ' (<%= raceBetsJS.i18n.print("raceCategoryLetterFrance", {letter: race.categoryLetter}) %>)'+
                                '<% } else if($.inArray(event.country, ["GB", "IE", "AE"]) > -1) { %>'+
                                    ' (Class <%= race.categoryLetter %>)'+
                                '<% } else { %>'+
                                    ' (<%= race.categoryLetter %>)'+
                                '<% } %>'+
                            '</span>'+
                        '<% } %>'+
                        '<% if (race.hint && race.hint.text && !raceBetsJS.application.globals.isB2B) { %><span class="c-tag isJackpot"><%= race.hint.text %></span><% } %>'+
                    '</span>'+
                    '<% if (race.raceType != "D") { %><span class="row"><%= raceBetsJS.format.ageCondition(race.ageFrom, race.ageTo) %></span><% } %>'+
                    '<span class="row">'+
                        '<% if (race.distance) { %>'+
                            '<%= raceBetsJS.i18n.data.labelDistance %>: '+
                            '<%= raceBetsJS.format.distance(race.distance, (race.raceType !== "D") ? raceBetsJS.application.assets.settings.get().dialog.general.unitSystem : "metric") %>'+
                            '<% if(race.startType) { %>'+
                                '&nbsp;|&nbsp; <%= raceBetsJS.i18n.data["startType" + race.startType] %>'+
                            '<% } %>'+
                        '<% } %>'+
						'<% if (race.raceTypeDetail && race.raceTypeDetail !== "GRH") { %>'+
                            '&nbsp;|&nbsp; <%= raceBetsJS.i18n.data["raceTypeDetail" + race.raceTypeDetail] %>'+
                        '<% } %>'+
                        '<% if(race.purse) { %>'+
                            '&nbsp;|&nbsp; <%= raceBetsJS.i18n.data.labelPurse + ": " + raceBetsJS.format.number(race.purse) + " " + race.purseCurrency %>'+
                        '<% } %>'+
                        '<% if (race.distance || race.purse) { %>&nbsp;|&nbsp;<% } %>'+
                        '<%= raceBetsJS.i18n.print("numStarters", { numStarters: race.numRunners }) %>'+
                        '<% if (race.promotions.length) { %>' +
                            '<span class="row">' +
                                '<% $.each(race.promotions, function(index, promotion) { %>' +
                                    '<span class="notify-tag white has-icon promotion">' +
                                        '<% if (promotion.promotionType !== "custom") { %>' +
                                            '<%= raceBetsJS.i18n.data[promotion.label] %>' +
                                        '<% } else { %>' +
                                            '<%= promotion.label %>' +
                                        '<% } %>' +
                                    '</span>' +
                                '<% }); %>' +
                            '</span>' +
                        '<% } %>' +
                    '</span>'+
                '</div>'+
				'<div class="status">'+
                    '<% if(race.favourites.length > 0) { %>'+
                        '<span class="favourite">'+
                            '<%= raceBetsJS.i18n.data.labelFavourite %>: <%= race.favourites[0].horseName %>'+
                            '<span class="odds probable"><%= raceBetsJS.format.odds(race.favourites[0].winOdds) %></span>'+
                        '</span>'+
                    '<% } %>'+
                    '<% if(race.results.length > 0) { %>'+
                        '<ol>'+
                            '<% var finalPositionBefore; %>'+
                            '<% $.each(race.results, function(index, result) { %>'+
                                '<li class="<%= result.finalPosition == 1 ? "winner" : "" %>'+
                                    '<%= (result.finalPosition == finalPositionBefore) ? "same-position" : "" %>"><span class="final-position"><%= result.finalPosition %>.</span> <span class="horse"><%= result.horseName %></span> <span class="em">(<%= result.programNumber %>)</span>'+
                                    '<% if (result.winOdds > 0) { %><span class="odds final"><%= raceBetsJS.format.odds(result.winOdds) %></span><% } %>'+
                                '</li>'+
                                '<% finalPositionBefore = result.finalPosition; %>'+
                            '<% }); %>'+
                        '</ol>'+
                    '<% } %>'+
                    '<% if(race.raceStatus != "TMP" && race.raceStatus != "FNL") { %>'+
                        '<% if(race.scratches.length) { %>'+
                            '<span class="non-runners"><%= raceBetsJS.i18n.data.labelNonRunners %> <%= race.scratches.join(", ") %></span>'+
                        '<% } %>'+
                        '<% if(race.betTypes.pick.length === 1) { %>'+
                            '<span class="pick-bets"><%= $.map(race.betTypes.pick, function(val) { return raceBetsJS.format.betTypeName(val, "TOT", raceBetsJS.application.user.lang, null, event.country); }).join(", ") %></span>'+
                        '<% } %>'+
                        '<% if(race.betTypes.pick.length > 1) { %>'+
                            '<% var pickNumRaces = []; %>'+
                            '<% _.each(race.betTypes.pick, function(pickType, index) { pickNumRaces.push(raceBetsJS.format.getPickNumRaces(pickType)) }) %>'+
                            '<span class="pick-bets"><%= raceBetsJS.i18n.print("labelBetTypePick", { numRaces:  _.unique(pickNumRaces).join("/") }) %></span>'+
                        '<% } %>'+
                    '<% } %>'+
                    '<a href="<%= raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + race.idRace %>" class="ajaxify racecard-link grey-arrows">'+
                        '<% if(race.raceStatus == "OPN") { %>'+
                            '<%= raceBetsJS.i18n.data.linkBetslip %>'+
                        '<% } else if(race.raceStatus == "TMP" || race.raceStatus == "FNL") { %>'+
                            '<%= raceBetsJS.i18n.data.labelLinkResult %>'+
                        '<% } else { %>'+
                            '<%= raceBetsJS.i18n.data.linkRaceCard %>'+
                        '<% } %>'+
                    '</a>'+
				'</div>'+
            '</div>'+
            '<% if(event.media !== undefined && raceBetsJS.media.isCountryAllowed(raceBetsJS.application.user.mediaCountry, race.filterCountries, race.filterType)) { %>' +
                '<% if(race.archiveVideo && event.media !== undefined) { %>'+
                    '<span class="archive-video" data-id-race="<%= race.idRace %>" data-stream-type="<%= race.streamType %>" data-archive-link="<%= race.archiveLink %>"></span>'+
                '<% } %>'+
            '<% } %>'+
		'</li>'
	);

    /**
    * Special Race Row
    */
    raceBetsJS.application.templates.event.special = _.template(
        '<% $.each(races, function(i) { %>'+
            '<%= raceBetsJS.application.templates.event.special.race({ race: this }) %>'+
        '<% }); %>'
    );

    raceBetsJS.application.templates.event.special.race = _.template(
        '<li id="race-<%= race.idRace %>" class="race" data-id-race="<%= race.idRace %>">' +
            '<div class="racedetails">' +
                '<div class="special-bet">' +
                    '<div class="details">' +
                        '<span class="row title">' +
                            '<strong><%= raceBetsJS.i18n.getRaceTitle(race.raceTitle, race.raceNumber) %></strong>' +
                        '</span>' +
                        '<span class="row"><%= race.conditions %></span>' +
                    '</div>' +
                    '<div class="options">' +
                        '<% var runnersList = (race.runnersOrder === "ODD") ? _.sortBy(race.runners, function(runner) { return runner.winOdds }) : race.runners %>' +
                        '<% $.each(runnersList, function(i, runner) { %>' +
                            '<span class="option<%= ($.inArray(runner.idRunner, race.results) > -1) ? " winner" : "" %>">' +
                                '<%= runner.horseName %>' +
                                '<button class="odds-button fixed<%= (race.fixedOddsStatus === "ON" && race.raceStatus === "OPN" && !runner.scratched) ? " enabled" : "" %>" data-id-runner="<%= runner.idRunner %>" data-bet-type="WIN">' +
                                    '<span data-odds="<%= runner.winOdds %>">' +
                                        '<%= raceBetsJS.format.odds(runner.winOdds, null, true) %>' +
                                    '</span>' +
                                '</button>' +
                            '</span>' +
                        '<% }); %>' +
                        '<% if (race.raceStatus == "FNL" && race.results == 0 && race.runners.length == 1) { %>' +
                            '<span class="option winner">' +
                                '<%= raceBetsJS.i18n.data.labelNo %>' +
                        '<% } %>' +
                    '</div>' +
                '</div>' +
            '</div>' +
        '</li>'
    );

    /**
    * Head-to-Head
    *
    */
    raceBetsJS.application.templates.event.special.h2h = _.template(
        '<% $.each(races, function(i) { %>' +
            '<%= raceBetsJS.application.templates.event.special.h2h.race({ race: races[i], event: event }) %>' +
        '<% }); %>'
    );

    /*
     * Silks
     */
    raceBetsJS.application.templates.event.special.h2h.silks = _.template(
        '#runner-<%= runner.idRunner %> > .silk {' +
            'background-image: url(<%= raceBetsJS.application.globals.imageHost %>/cache/img/silks/<%= runner.silkId %>_w31.<%= runner.silkExtension %>)' +
        '}' +
        '@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {' +
            '#runner-<%= runner.idRunner %> > .silk {' +
                'background-image: url(<%= raceBetsJS.application.globals.imageHost %>/cache/img/silks/<%= runner.silkId %>_w62.<%= runner.silkExtension %>)' +
            '}' +
        '}'
    );

    raceBetsJS.application.templates.event.special.h2h.race = _.template(
        '<% var showSilks = raceBetsJS.application.content.event.showSilks(race); %>' +
        '<li id="race-<%= race.idRace %>" class="race<%= (showSilks) ? " has-silks" : "" %>" data-id-race="<%= race.idRace %>">' +
            '<% if (showSilks) { %>' +
                '<style>' +
                '<% $.each(race.runners, function(i, runner) { %>' +
                    '<% if (runner.silkExtension) { %>' +
                        '<%= raceBetsJS.application.templates.event.special.h2h.silks({ runner: runner }) %>' +
                    '<% } %>' +
                '<% }); %>' +
                '</style>' +
            '<% } %>' +
            '<div class="racedetails">' +
                '<div class="time">' +
                    '<a href="race/details/id/<%= race.relatedIdRace %>"class="ordinal ajaxify status-<%= race.raceStatus %>">' +
                        '<%= (race.relatedRaceNumber == "0") ? "S" : race.relatedRaceNumber %>' +
                    '</a>' +
                    '<span class="post-time"><%= raceBetsJS.application.assets.timezone.date("H:i", race.postTime) %></span>' +
                '</div>' +
                '<div class="special-bet">' +
                    '<ul>' +
                        '<% $.each(race.runners, function() { %>' +
                            '<li id="runner-<%= this.idRunner %>" class="<%= ($.inArray(this.idRunner, race.results) > -1) ? " is-winner" : "" %>">' +
                                '<% if (showSilks) { %>' +
                                    '<span class="silk"></span>' +
                                '<% }%>' +
                                '<span class="name" title="<%= ($.inArray(this.idRunner, race.results) > -1) ? raceBetsJS.i18n.data.labelWinner + ": " : "" %><%= this.horseName %>"><%= this.horseName %></span>' +
                                '<button type="button" class="odds-button <%= (race.fixedOddsStatus == "ON" && race.raceStatus === "OPN" && this.scratched == 0) ? "enabled" : "" %> fixed" data-id-runner="<%= this.idRunner %>" data-bet-type="WIN">' +
                                    '<span data-odds="<%= this.winOdds %>"><%= raceBetsJS.format.odds(this.winOdds) %></span>' +
                                '</button>' +
                                '<% if ($.inArray(this.idRunner, race.results) > -1) { %>' +
                                    '<span class="highlight"><span class="c-tag c-tag--s isWinner"><%= raceBetsJS.i18n.data.labelWinner %></span></span>' +
                                '<% } %>' +
                                '<% if (this.finalPosition) { %>' +
                                    '<span class="c-finishingPost" title="<%= raceBetsJS.i18n.data.labelFinalPosition %>">' +
                                        '<span class="c-finishingPost__position"><%= this.finalPosition %></span>' +
                                        '<span class="c-finishingPost__post"></span>' +
                                    '</span>' +
                                '<% } %>' +
                            '</li>' +
                        '<% }); %>' +
                    '</ul>' +
                '</div>' +
            '</div>' +
        '</li>'
    );

})(raceBetsJS);
