raceBetsJS.application.templates.myBets = _.template('\
    <div id="my-bets" class="rounded panel new dark clearfix">\
    	<div class="header_container">\
    		<h2><span class="text"><%= raceBetsJS.i18n.data.headAllMyBets %></span></h2>\
    	</div>\
    	<div class="content">\
    		<div class="c-tab__control periods">\
    			<div class="c-btnGroup">\
					<a href="<%= raceBetsJS.application.globals.urlPrefix + "/bets/main/today/" %>" class="ajaxify c-btn c-btn--tab <%= (type == "today") ? "isSelected" : "" %>" data-period="today">\
                        <span class="c-btn__label"><%= raceBetsJS.i18n.data.dateToday %></span>\
                    </a>\
    				<a href="<%= raceBetsJS.application.globals.urlPrefix + "/bets/main/yesterday/" %>" class="ajaxify c-btn c-btn--tab <%= (type == "yesterday") ? "isSelected" : "" %>" data-period="yesterday">\
                        <span class="c-btn__label"><%= raceBetsJS.i18n.data.dateYesterday %></span>\
                    </a>\
	                <a href="<%= raceBetsJS.application.globals.urlPrefix + "/bets/main/upcoming/" %>" class="ajaxify c-btn c-btn--tab <%= (type == "upcoming") ? "isSelected" : "" %>" data-period="future">\
                        <span class="c-btn__label"><%= raceBetsJS.i18n.data.dateFuture %></span>\
                    </a>\
    				<a href="<%= raceBetsJS.application.globals.urlPrefix + "/bets/main/antepost/" %>" class="ajaxify c-btn c-btn--tab <%= (type == "antepost") ? "isSelected" : "" %>" data-period="ante-post">\
                        <span class="c-btn__label"><%= raceBetsJS.i18n.data.labelAntePost %></span>\
                    </a>\
					<a href="<%= raceBetsJS.application.globals.urlPrefix + "/bets/main/filter/" %>" class=" ajaxify c-btn c-btn--tab <%= (type == "filter") ? "isSelected" : "" %>" data-period="advanced">\
                        <span class="c-btn__label"><%= raceBetsJS.i18n.data.labelAdvanced %></span>\
                    </a>\
    			</div>\
    		</div>\
    		<div class="bets">\
    			<div class="header">\
    				<span class="bet-event"><%= raceBetsJS.i18n.data.labelEvent %></span>\
    				<span class="bet-type"><%= raceBetsJS.i18n.data.labelBetType %></span>\
    				<span class="bet-details"><%= raceBetsJS.i18n.data.labelDetails %></span>\
    				<span class="bet-stake"><%= raceBetsJS.i18n.data.labelStake %></span>\
    				<span class="bet-payout"><%= raceBetsJS.i18n.data.labelPayout %></span>\
    				<span class="bet-status"><%= raceBetsJS.i18n.data.labelStatus %></span>\
				</div>\
				<div class="content">\
				</div>\
    		</div>\
    	</div>\
    </div>\
    <% if(pages > 1) { %>\
	    <div class="pagination">\
    		<a href="<%= raceBetsJS.application.globals.urlPrefix + "/bets/main/" + type + (filterString != "" ? filterString : "") + "/page/1" %>" class="first ajaxify"><span>&#171</span></a><!--\
    		--><a href="<%= raceBetsJS.application.globals.urlPrefix + "/bets/main/" + type + (filterString != "" ? filterString : "") + "/page/" %><%= ((currentPage) < 1) ? 1 : currentPage-1 %>" class="prev ajaxify"><span>&lsaquo;</span></a><!--\
    		<% for(var i = 0; i < pages; i++) { %>\
    			--><a href="<%= raceBetsJS.application.globals.urlPrefix + "/bets/main/" + type + (filterString != "" ? filterString : "") + "/page/" + (i+1) %>" class="page ajaxify <%= (currentPage == (i+1)) ? "current" : "" %>"><span><%= i+1 %></span></a><!--\
    		<% } %>\
    		--><a href="<%= raceBetsJS.application.globals.urlPrefix + "/bets/main/" + type + (filterString != "" ? filterString : "") + "/page/" %><%= ((currentPage+1) > pages) ? pages : currentPage+1 %>" class="next ajaxify"><span>&rsaquo;</span></a><!--\
    		--><a href="<%= raceBetsJS.application.globals.urlPrefix + "/bets/main/" + type + (filterString != "" ? filterString : "") + "/page/" + pages %>" class="last ajaxify"><span>&#187</span></a>\
	    </div>\
    <% } %>\
');

raceBetsJS.application.templates.myBets.filters = _.template('\
	<div class="rounded panel clearfix filters new light">\
		<h2><%= raceBetsJS.i18n.data.headBetsFilter %></h2>\
		<div id="filters" class="content_container">\
            <div class="column">\
                <label for="filter-bet-type"><%= raceBetsJS.i18n.data.labelBetType %></label>\
                <div class="c-select">\
                    <select id="filter-bet-type" data-filter="bet-type" class="c-select__input">\
                        <option value=""><%= raceBetsJS.i18n.data.filterOptionAll %></option>\
                        <% $.each(betTypes,function(){ %>\
                            <option value="<%= this %>" <% if(selected.type == this){ %>selected="selected"<% } %>><%= raceBetsJS.format.betTypeName(this) %></option>\
                        <% }) %>\
                    </select>\
                    <span class="c-select__btn">\
                        <span class="c-iconFont c-iconFont--caret-down"></span>\
                    </span>\
                    <span class="c-select__value"><%= raceBetsJS.i18n.data.filterOptionAll %></span>\
                </div>\
                <label for="filter-category"><%= raceBetsJS.i18n.data.labelBetCategory %></label>\
                <div class="c-select">\
                    <select id="filter-category" data-filter="category" class="c-select__input">\
                        <option value=""><%= raceBetsJS.i18n.data.filterOptionAll %></option>\
                        <% $.each(betCategories,function(){ %>\
                            <option value="<%= this %>" <% if(selected.category == this){ %>selected="selected"<% } %>><%= raceBetsJS.i18n.data["betCategory" + this] %></option>\
                        <% }) %>\
                    </select>\
                    <span class="c-select__btn">\
                        <span class="c-iconFont c-iconFont--caret-down"></span>\
                    </span>\
                    <span class="c-select__value"><%= raceBetsJS.i18n.data.filterOptionAll %></span>\
                </div>\
            </div>\
            <div class="column">\
                <label for="event-type"><%= raceBetsJS.i18n.data.labelEventType %></label>\
                <div class="c-select">\
                    <select id="event-type" data-filter="event-type" class="c-select__input">\
                        <option value=""><%= raceBetsJS.i18n.data.filterOptionAll %></option>\
                        <option value="NOR" <% if(selected.eventtype == "NOR"){ %>selected="selected"<% } %>><%= raceBetsJS.i18n.data.filterNormalBets %></option>\
                        <option value="SPC" <% if(selected.eventtype == "SPC"){ %>selected="selected"<% } %>><%= raceBetsJS.i18n.data.filterSpecialBets %></option>\
                        <option value="ANT" <% if(selected.eventtype == "ANT"){ %>selected="selected"<% } %>><%= raceBetsJS.i18n.data.filterAntePostBets %></option>\
                    </select>\
                    <span class="c-select__btn">\
                        <span class="c-iconFont c-iconFont--caret-down"></span>\
                    </span>\
                    <span class="c-select__value"><%= raceBetsJS.i18n.data.filterOptionAll %></span>\
                </div>\
                <label for="filter-status"><%= raceBetsJS.i18n.data.labelStatus %></label>\
                <div class="c-select">\
    				<select id="filter-status" data-filter="status" class="c-select__input">\
    					<option value=""><%= raceBetsJS.i18n.data.filterOptionAll %></option>\
    					<% $.each(betStatuses,function(){ %>\
    						<option value="<%= this %>" <% if(selected.status == this){ %>selected="selected"<% } %>><%= raceBetsJS.i18n.data["betStatus" + this] %></option>\
    					<% }) %>\
    				</select>\
                    <span class="c-select__btn">\
                        <span class="c-iconFont c-iconFont--caret-down"></span>\
                    </span>\
                    <span class="c-select__value"><%= raceBetsJS.i18n.data.filterOptionAll %></span>\
                </div>\
            </div>\
            <div class="column">\
                <label for="betslip-type"><%= raceBetsJS.i18n.data.labelBetslip %></label>\
                <div class="c-select">\
    				<select id="betslip-type" data-filter="betslip-type" class="c-select__input">\
    					<option value=""><%= raceBetsJS.i18n.data.filterOptionAll %></option>\
    					<option value="STD" <% if(selected.betslips == "STD"){ %>selected="selected"<% } %>><%= raceBetsJS.i18n.data.filterNormalBets %></option>\
    					<option value="ACC" <% if(selected.betslips == "ACC"){ %>selected="selected"<% } %>><%= raceBetsJS.i18n.data.filterAccumulatedBet %></option>\
    				</select>\
                    <span class="c-select__btn">\
                        <span class="c-iconFont c-iconFont--caret-down"></span>\
                    </span>\
                    <span class="c-select__value"><%= raceBetsJS.i18n.data.filterOptionAll %></span>\
                </div>\
                <label for="public-id"><%= raceBetsJS.i18n.data.labelBetslipId %></label>\
                <input id="public-id" type="text" class="c-input c-input--text" data-filter="public-id" value="<%= selected.id %>">\
            </div>\
			<div class="column last">\
                <div class="column-half">\
                    <label for="filter-date-from"><%= raceBetsJS.i18n.data.labelFrom %></label>\
                    <input id="filter-date-from" type="text" data-filter="date-from" class="c-input c-input--text" data-timestamp="<%= (selected.from !== undefined && !isNaN(selected.from.timestamp)) ? selected.from.timestamp : (Math.round($.now() / 1000)) - 1209600 %>" data-date-format="<%= raceBetsJS.i18n.data.dateFormatDate %>" readonly="readonly">\
                    <div id="mybets_datepicker-1" class="mybets-datepicker c-calendar--dark"></div>\
                </div><!--\
                --><div class="column-half">\
                    <label for="filter-date-to"><%= raceBetsJS.i18n.data.labelTo %></label>\
                    <input id="filter-date-to" type="text" data-filter="date-to" class="c-input c-input--text" data-timestamp="<%= (selected.to !== undefined && !isNaN(selected.to.timestamp)) ? selected.to.timestamp : (Math.round($.now() / 1000)) %>" data-date-format="<%= raceBetsJS.i18n.data.dateFormatDate %>" readonly="readonly">\
                    <div id="mybets_datepicker-2" class="mybets-datepicker c-calendar--dark"></div>\
                </div>\
                <div data-filter="time-range" class="time-range">\
                    <button data-value="[0,0]" class="c-btn c-btn--link"><%= raceBetsJS.i18n.data.dateToday %></button>\
                    <button data-value="[86400,86400]" class="c-btn c-btn--link"><%= raceBetsJS.i18n.data.dateYesterday %></button>\
                    <button data-value="[604800,0]" class="c-btn c-btn--link"><%= raceBetsJS.i18n.data.dateOneWeek %></button>\
                    <button data-value="[1209600,0]" class="c-btn c-btn--link"><%= raceBetsJS.i18n.print("dateXWeeks", { weeks: 2 }) %></button>\
                    <button data-value="[2635200,0]" class="c-btn c-btn--link"><%= raceBetsJS.i18n.data.dateOneMonth %></button>\
                </div>\
                <div class="align-right">\
                    <button id="submit-filter" class="c-btn c-btn--primary"><%= raceBetsJS.i18n.data.buttonSubmitFilter %></button>\
                </div>\
			</div>\
		</div>\
	</div>\
');

raceBetsJS.application.templates.myBets.std = _.template('\
    <div class="event">\
    	<div class="details">\
            <span class="name">\
                <% if(country !== "") { %>\
                    <span class="c-flag isCountry<%= country %>"></span>\
                <% } %>\
    			<a href="<%= raceBetsJS.application.globals.urlPrefix %>/race/details/id/<%= idRace %>/" class="ajaxify"><%=title%><%= (parseInt(antePost) ? "" : " | " + raceBetsJS.i18n.print("abbrRaceNumberX", {"race":raceNumber})) %></a>\
    		</span>\
    		<span class="time">\
    			<% if(!parseInt(antePost)){ %>\
					<%= raceBetsJS.i18n.print("timeLong", {time:raceBetsJS.application.assets.timezone.date("H:i", postTime)}) + " | " %>\
    			<% } %>\
    			<%=raceBetsJS.application.assets.timezone.date("d.m.Y",postTime)%>\
    		</span>\
    	</div>\
    	<div class="event-bets">\
    		<table>\
    			<% $.each(bets, function(i){ %>\
    				<tr class="<%= (i%2!=0) ? " even" : " odd" %><%= (i == bets.length-1) ? " last" : "" %>" data-id="<%=  this.publicId %>">\
    					<td class="bet-type">\
    						<% if(this.type == "H2H") { %>\
    							<%=  raceBetsJS.i18n.data["headToHead"] %><br />\
    						<% } else { %>\
                                <%= raceBetsJS.format.betTypeName(this.betType, this.category, raceBetsJS.application.user.lang, (this.currency == "USD"), country) %><br />\
    						<% } %>\
                            <span class="light-grey"><%=  raceBetsJS.i18n.data["betCategory" + this.category] %></span>\
    					</td>\
    					<td class="bet-details">\
    						<% if(!_.isUndefined(this.mark)) { %>\
    							<%= this.mark.name %>\
    							<% if(parseInt(antePost)) { %>\
                                    <% if(this.type == "H2H" && !_.isUndefined(this.opponents)) { %>\
                                        @ <%= raceBetsJS.format.odds(this.fixedOdds[0]) %> <br />\
                                        <% _.each(this.opponents, function(opponent) { %>\
                                            <span class="light-grey"><%=  raceBetsJS.i18n.data["headToHeadVs"] %> <%= opponent.name %></span>\
                                        <% }) %>\
                                    <% } %>\
    							<% } else if(this.type == "H2H" && !_.isUndefined(this.opponents)) { %>\
                                    @ <%= raceBetsJS.format.odds(this.fixedOdds[0]) %> <br />\
                                    <% _.each(this.opponents, function(opponent) { %>\
                                        <span class="light-grey"><%=  raceBetsJS.i18n.data["headToHeadVs"] %> <%= opponent.name %></span>\
                                    <% }) %>\
    							<% } else { %>\
									<span class="light-grey">(<%= this.mark.programNumber %>)</span>\
    							<% } %>\
    							<% if(this.category == "FXD" && this.type != "H2H") { %>\
                                    <% if ($.inArray(country, ["GB", "IE", "AE"]) > -1) { %>\
                                        @ <%= raceBetsJS.format.odds(this.fixedOdds[0]) %>\
                                    <% } else { %>\
                                        @ <%= _.map(this.fixedOdds, function(odd){ return raceBetsJS.format.odds(odd); }).join(" | ") %>\
                                    <% } %>\
                                <%}%>\
    						<% } else if(!_.isUndefined(this.marks)){ %>\
    							<% $.each(this.marks, function(key, value){ %>\
    								<%= raceBetsJS.i18n.data["labelCol" + key] %>: <%= value.join("-") %><br />\
    							<% }) %>\
    						<% } %>\
                            <% if (this.freebet === true) { %>\
                                &nbsp;\
                                <span class="tag free-bet">\
                                    <%= raceBetsJS.i18n.data.labelFreeBet %>\
                                </span>\
                            <% } %>\
    					</td>\
    					<td class="bet-stake">\
							<span><%= raceBetsJS.format.money(this.totalStake, 2, raceBetsJS.application.user.currency, true) %></span>\
							<% if(this.totalStake != this.unitStake) { %>\
							    <span class="light-grey"><%= raceBetsJS.format.money(this.unitStake, 2, raceBetsJS.application.user.currency, true) %></span>\
							<% } %>\
    					</td>\
    					<td class="bet-payout">\
                            <% if(this.winnings > 0) { %>\
                                <span><%= raceBetsJS.format.money(this.winnings, 2, raceBetsJS.application.user.currency, true) %></span>\
                            <% } %>\
                            <% if(this.refund > 0) { %>\
                                <span<% if(this.winnings > 0) { %> class="light-grey"<% } %>><%= raceBetsJS.format.money(this.refund, 2, raceBetsJS.application.user.currency, true) %></span>\
                            <% } %>\
    					</td>\
    					<td class="bet-status <%= this.status %>">\
    						<div class="container">\
    							<span class="status-btn">\
									<%=  raceBetsJS.i18n.data["betStatus" + this.status] %>\
								</span>\
    						</div>\
						</td>\
					</tr>\
    			<% }) %>\
    		</table>\
    	</div>\
    </div>\
');

raceBetsJS.application.templates.myBets.spc = _.template('\
    <div class="event">\
    	<div class="details">\
    		<span class="name">\
                <% if(country !== "") { %>\
                    <span class="c-flag isCountry<%= country %>"></span>\
                <% } %>\
    			<%=title%>\
    		</span>\
    		<span class="time">\
    			<%=raceBetsJS.application.assets.timezone.date("d.m.Y",postTime)%>\
    		</span>\
    	</div>\
    	<div class="event-bets">\
    		<table>\
    			<% $.each(bets, function(i){ %>\
    				<tr class="<%= (i%2!=0) ? " even" : " odd" %><%= (i == bets.length-1) ? " last" : "" %>" data-id="<%=  this.publicId %>">\
    					<td class="bet-type">\
    						<%=  raceBetsJS.i18n.data.labelSpecial %>\
    					</td>\
    					<td class="bet-details">\
    						<% if(!_.isUndefined(this.bet)) { %>\
    							<%= this.mark %> @ <%= raceBetsJS.format.odds(this.fixedOdds[0]) %><br />\
                                <% if (this.betType == "WP") { %>\
                                    <%= this.placesNum %> <%= raceBetsJS.i18n.data.labelPlaces %> @ 1/<%= this.placeOddsFactor %> <%= raceBetsJS.i18n.data.labelOdds %>\
    							<% } %>\
                                <span class="light-grey"><%= this.bet %></span><br />\
    						<% } else if(!_.isUndefined(this.marks)){ %>\
    							<% $.each(this.marks, function(key, value){ %>\
    								<%= raceBetsJS.i18n.data["labelCol" + key] %>: <%= value.join("-") %><br />\
    							<% }) %>\
    						<% } %>\
    					</td>\
    					<td class="bet-stake">\
							<span><%= raceBetsJS.format.money(this.totalStake, 2, raceBetsJS.application.user.currency, true) %></span>\
							<% if(this.totalStake != this.unitStake) { %>\
							    <span class="light-grey"><%= raceBetsJS.format.money(this.unitStake, 2, raceBetsJS.application.user.currency, true) %></span>\
							<% } %>\
    					</td>\
    					<td class="bet-payout">\
    					    <%	if(this.winnings > 0) { %>\
    						    <%= raceBetsJS.format.money(this.winnings, 2, raceBetsJS.application.user.currency, true) %>\
    					    <% } %>\
                            <% if(this.refund > 0) { %>\
                                <span<% if(this.winnings > 0) { %> class="light-grey"<% } %>><%= raceBetsJS.format.money(this.refund, 2, raceBetsJS.application.user.currency, true) %></span>\
                            <% } %>\
    					</td>\
    					<td class="bet-status <%= this.status %>">\
    						<div class="container">\
    							<span class="status-btn">\
									<%=  raceBetsJS.i18n.data["betStatus" + this.status] %>\
								</span>\
    						</div>\
						</td>\
					</tr>\
    			<% }) %>\
    		</table>\
    	</div>\
    </div>\
');

raceBetsJS.application.templates.myBets.acc = _.template('\
    <div class="event accumulated">\
    	<div class="details">\
    		<span class="name">\
                <% if(country !== "") { %>\
                    <span class="c-flag isCountry<%= country %>"></span>\
                <% } %>\
                <% if (raceBetsJS.format.isPickBetType(betType)) { %>\
                    <%= raceBetsJS.format.betTypeName(betType, category, raceBetsJS.application.user.lang, (currency == "USD"), country) %>\
                <% } else { %>\
                    <%= raceBetsJS.i18n.data.accumulatedBet %>\
                <% } %>\
    		</span>\
            <% if (system != "") { %>\
                <span class="system">\
                    <% if (system == "C" || system == "F") { %>\
                        <%= raceBetsJS.i18n.data["system" + legs.length + system] %>\
                    <% } else { %>\
                        <%= raceBetsJS.i18n.data["system" + system + "X"] %>\
                    <% } %>\
                </span>\
            <% } %>\
    	</div>\
    	<div class="event-bets">\
    		<table>\
    			<tr data-id="<%= publicId %>" class="last">\
    				<td class="bet-type">\
                        <% if (raceBetsJS.format.isPickBetType(betType)) { %>\
                            <% if (raceBetsJS.format.isPickPlace(betType)) { %>\
                                <%= raceBetsJS.i18n.data.betTypePLC %>\
                            <% } else { %>\
                                <%= raceBetsJS.i18n.data.betTypeWIN %>\
                            <% } %>\
                        <% } else { %>\
                            <%= raceBetsJS.format.betTypeName(betType, category, raceBetsJS.application.user.lang, (currency == "USD"), country) %>\
                        <% } %>\
                        <br />\
                        <% if (type === "ACC" && category !== "TOT") { /* its just one of those days */ } else { %>\
                            <span class="light-grey"><%= raceBetsJS.i18n.data["betCategory" + category] %></span>\
                        <% } %>\
    				</td>\
    				<td class="bet-details">\
    					<% if(!_.isUndefined(legs)){ %>\
                            <% if (antePost == 1) { %>\
                                <%= raceBetsJS.i18n.data.labelAntePost %> <br />\
                                <% var iteration = 0; $.each(events, $.proxy(function(i, event) { %>\
                                    <%= event.title %><%= (iteration < _.size(events)-1) ? ", " : "" %>\
                                <% iteration++; }, this)); %>\
                            <% } else { %>\
                                <% $.each(legs, function(){ %>\
    							    <%= this.event %> | <%= raceBetsJS.application.assets.timezone.date(raceBetsJS.i18n.data.dateFormatDateShort,this.postTime) %> | <%= raceBetsJS.i18n.print("abbrRaceNumberX", {"race":this.raceNumber}) %>: \
    							    <% if(!_.isUndefined(this.mark)) { %>\
    								    <%= this.mark.name %> <%= this.mark.programNumber %>\
    							    <% } else if(!_.isUndefined(this.marks)){ %>\
    								     <%= this.marks.join("-") %>\
    							    <% } %>\
    							    <br />\
    						    <% }) %>\
                            <% } %>\
    					<% } %>\
    				</td>\
    				<td class="bet-stake">\
						<span><%= raceBetsJS.format.money(totalStake, 2, currency, true) %></span>\
						<% if(totalStake != unitStake) { %>\
							<span class="light-grey"><%= raceBetsJS.format.money(unitStake, 2, currency, true) %></span>\
						<% } %>\
    				</td>\
    				<td class="bet-payout">\
    					<%	if(winnings > 0) { %>\
							<%= raceBetsJS.format.money(winnings, 2, currency, true) %>\
    					<% } %>\
                        <% if(refund > 0) { %>\
                            <span<% if(winnings > 0) { %> class="light-grey"<% } %>><%= raceBetsJS.format.money(refund, 2, raceBetsJS.application.user.currency, true) %></span>\
                        <% } %>\
    				</td>\
    				<td class="bet-status <%= status %>">\
    					<div class="container">\
    						<span class="status-btn">\
								<%=  raceBetsJS.i18n.data["betStatus" + status] %>\
							</span>\
    					</div>\
					</td>\
				</tr>\
    		</table>\
    	</div>\
    </div>\
');
