
(function(raceBetsJS) {
    raceBetsJS.application.templates.sidebar = _.template('\
        <div class="sidebar tabbed <%- (raceBetsJS.application.assets.settings.get().dialog.general.hideDogs === true) ? "no-dogs" : "" %>">\
            <ul class="tabs sports">\
                <% $.each(sidebars, function() { %>\
                    <li data-content-id="#sidebar-<%= this %>"><h2><%= raceBetsJS.i18n.data["headSidebar" + raceBetsJS.string.ucfirst(this)] %></h2></li>\
                <% }); %>\
            </ul>\
            <div class="mainContent content">\
                <% $.each(sidebars, function() { %>\
                    <div id="sidebar-<%= this %>" class="sidebar-cat block" data-sport="<%= this %>"></div>\
                <% }); %>\
            </div>\
        </div>\
    ');

    raceBetsJS.application.templates.sidebar.horses = _.template('\
        <div class="filters">\
	        <ul class="tabs">\
	            <% $.each(sidebars, function(category, sidebar) { %>\
	                <li data-content-id="#sidebar-<%- sport %>-<%- category %>"><h3><%- raceBetsJS.i18n.data["headSidebar" + category.charAt(0).toUpperCase() + category.substr(1)] %></h3></li>\
	            <% }); %>\
	        </ul>\
        </div>\
        <div class="separator"></div>\
        <div class="content">\
            <% $.each(sidebars, function(category) { %>\
                <div id="sidebar-<%= sport %>-<%= category %>" class="sidebar-cat block" data-sport="<%= sport %>" data-category="<%= category %>"></div>\
            <% }); %>\
        </div>\
    ');

    raceBetsJS.application.templates.sidebar.dogs = _.template('\
        <div class="content">\
            <% $.each(sidebars, function(category) { %>\
                <div id="sidebar-<%- sport %>-<%- category %>" class="sidebar-cat block" data-sport="<%- sport %>" data-category="<%- category %>"></div>\
            <% }); %>\
        </div>\
    ');

    raceBetsJS.application.templates.sidebar.content = _.template('\
        <ul class="countries">\
            <% $.each(events, function(index, country) { %>\
                <li class="country" data-country="<%= country[0].country %>">\
                    <h3>\
                        <%= raceBetsJS.i18n.data.countries[country[0].country] %>\
                        <span class="c-flag isCountry<%= country[0].country %>"></span>\
                        <span class="arrow"></span>\
                    </h3>\
                    <ul class="events">\
                        <% $.each(country, function(index, event) { %>\
                            <li class="event <%= (index == 0) ? "first" : "" %> race-type-<%= event.raceType %> <%= !event.openRaces ? "races-over" : ((event.accumulationBets || event.multiplesFxd) ? "accumulators" : "") %>">\
                                <a href="<%= raceBetsJS.application.globals.urlPrefix %>/event/details/id/<%= event.idEvent %>/" class="ajaxify">\
                                    <% if (event.newEvent === 1) { %>\
                                        <span class="new-flag lang-<%- raceBetsJS.application.user.lang %>"></span>\
                                    <% } %>\
                                    <span class="event-title"><%= event.title %></span>\
                                    <span class="time"><%= raceBetsJS.application.assets.timezone.date((category == "antePost") ? raceBetsJS.i18n.data.dateFormatDateShort : raceBetsJS.i18n.data.dateFormatTime, event.firstStart) %></span>\
                                </a>\
                            </li>\
                            <% $.each(event.children, function(childIndex, childEvent) { %>\
                                <li class="special-bets <%= (childEvent.accumulationBets ? "accumulators" : "") %>">\
                                    <a href="<%= raceBetsJS.application.globals.urlPrefix %>/event/details/id/<%= childEvent.idEvent %>/" class="ajaxify">\
                                        <span class="event-title"><%= childEvent.title %></span>\
                                    </a>\
                                </li>\
                            <% }); %>\
                        <% }); %>\
                    </ul>\
                </li>\
            <% }); %>\
        </ul>\
    ');
})(raceBetsJS);