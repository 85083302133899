/**
* @description calendar template
*
*/
(function(raceBetsJS) {

    raceBetsJS.application.templates.calendar = {};

    raceBetsJS.application.templates.calendar.countryBox = _.template(
        '<div class="box box-calendar">' +
            '<h2 class="header">' +
                '<% if (isVirtual) { %>' +
                        '<span class="title-virtual"><%= raceBetsJS.i18n.data.labelVirtualRaces %></span>'+
                '<% } else{ %>' +
                    '<span class="c-flag isCountry<%= country %>"></span><%= raceBetsJS.i18n.data.countries[country] %>' +
                '<% } %>' +
            '</h2>' +
            '<ul class="content">' +
            '</ul>' +
        '</div>'
    );

    raceBetsJS.application.templates.calendar.eventRow = _.template(
        '<li class="calendar-event <%= rowClass %> race-type-<%= raceType.toLowerCase() %><%= obj.isChild ? " is-child" : "" %>">' +
            '<a href="event/details/id/<%= idEvent %>" class="ajaxify href">' +
                '<span class="title">' +
                    '<% if (obj.isChild && obj.specialBetsEvent) { %>' +
                    '<span class="c-tag c-tag--s"><%= obj.specialBetType === "H2H" ? rb_i18n_angular_json.label_h2h_short_2 : rb_i18n_angular_json.label_special_bets_short %></span> ' +
                    '<% } %> ' +
                    '<%= title %> <%= (obj.isChild && obj.specialBetsEvent) ? obj.parentTitle : "" %>' +
                '</span>' +
                '<span class="post-time<%= (mediaType != null) ? " media media-" + mediaType : "" %><%= (videoArchive) ? " archive" : "" %><%= !raceBetsJS.media.isCountryAllowed(raceBetsJS.application.user.mediaCountry, streamFilterCountries, streamFilterType) ? " not-allowed" : "" %>">' +
                    '<%= raceBetsJS.i18n.print("fromTimeXOn", { time: raceBetsJS.application.assets.timezone.date("H:i", firstStart)}) %>' +
                    '<span class="icon"></span>' +
                '</span>' +
            '</a>' +
            '<ul class="race-numbers">' +
                '<% $.each(relatedRaces, function(i, race) { %>' +
                    '<li class="race-number status-<%= race.raceStatus %> <%= ((isVirtual) ? "is-virtual" : "") %>">' +
                        '<% if (specialBetType !== null || (specialBetsEvent === true && idEventParent === null)) { %>' +
                            '<a class="ajaxify" data-post-time="<%= race.postTime %>" data-race-title="<%= raceBetsJS.i18n.getRaceTitle(race.raceTitle, race.raceNumber) %>" href="event/details/id/<%= race.idEvent %>">' +
                                '<%= ((isVirtual) ? raceBetsJS.application.assets.timezone.date("H:i", race.postTime) : race.raceNumber) %>' +
                            '</a>' +
                        '<% } else { %>' +
                            '<a class="ajaxify" data-post-time="<%= race.postTime %>" data-race-title="<%= raceBetsJS.i18n.getRaceTitle(race.raceTitle, race.raceNumber) %>" href="race/details/id/<%= race.idRace %>">' +
                                '<%= ((isVirtual) ? raceBetsJS.application.assets.timezone.date("H:i", race.postTime) : race.raceNumber) %>' +
                            '</a>' +
                        '<% } %>' +
                    '</li>' +
                '<% }) %>' +
            '</ul>' +
        '</li>'
    );

    raceBetsJS.application.templates.calendar.dateControl = _.template(
        '<div id="date-control" class="calendar-heading">' +
            '<a class="races-prev-day ajaxify" href="calendar/main/date/<%= prevDate %>"><span class="c-iconFont c-iconFont--caret-left"></span></a>' +
            '<div id="races_datepicker_icon" class="current-date">' +
                '<%= raceBetsJS.format.date(raceBetsJS.i18n.data.dateFormatDate, calendarDate) %> <span class="em"><%= raceBetsJS.format.date("l", calendarDate) %></span>' +
            '</div>' +
            '<a class="races-next-day ajaxify" href="calendar/main/date/<%= nextDate %>"><span class="c-iconFont c-iconFont--caret-right"></span></a>' +
            '<div id="races_datepicker" class="races-datepicker c-calendar--dark"></div>' +
        '</div>'
    );

})(raceBetsJS);
