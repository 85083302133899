/**
* Accumulator Betslip
*
* @author: Moritz Honig
* @author: Robin Ebers <robin.ebers@gmail.com>
*/
(function(raceBetsJS) {
    raceBetsJS.application.assets.accBetslip = (function() {
        // @private

        /**
        * Options
        */
        var options = {};

        /**
        * Bet
        */
        var bet = {};

        /**
        * DOM reference to the betslip
        */
        var dom = {};

        /**
        * Form Elements
        */
        var form = {};

        /**
        * Races and Runners on the betslip
        */
        var legs = {};

        /**
         * To preserve the order of races from API
         */
        var raceOrder = [];

        /**
         * Current type of the Accumulator
         */
        var curAccType = null;

        /**
        * addRace
        *
        * Adds a race to the betslip
        */
        var addRace = function(race) {
            // stop if race exists already
            if (_.has(legs, race.idRace)) {
                return;
            }

            // create leg
            var leg = $(raceBetsJS.application.templates.accBetslip.leg({ race: race }));

            // insert leg in the dom
            leg.appendTo(dom.legsContainer);

            // add race to the legs object
            legs[race.idRace] = {
                dom: leg,
                //numbers: leg.find('div.row.numbers'),
                betTypes: race.betTypes,
                isHeadToHead: race.isHeadToHead,
                country: race.country,
                runners: {},
                coupledRunners: 0,
                stablesAdded: {},
                stablesLength: {}
            };

            // keep race order
            raceOrder.push(race.idRace);

            beautifyLegs();
        };

        /**
        * adjustSystemDropdown
        *
        * Adjust the system dropdown (enable/disable, set options)
        */
        var adjustSystemDropdown = function() {
            var i;
            var numRaces = _.size(legs);

            var options = [{
                value: '',
                title: raceBetsJS.i18n.data['system' + (numRaces > 1 ? numRaces + 'X' : 'None')]
            }];

            if (numRaces < 3) {
                // disable if 2 or less races
                form.system.setOptions(options).disable();
            } else {
                // enable
                for (i = 2; i < numRaces; i++) {
                    options.push({
                        value: i,
                        title: raceBetsJS.i18n.data['system' + i + 'X']
                    });
                }

                options.push({
                    value: 'C',
                    title: raceBetsJS.i18n.data['system' + numRaces + 'C']
                });

                if (numRaces < 7) {
                    options.push({
                        value: 'F',
                        title: raceBetsJS.i18n.data['system' + numRaces + 'F']
                    });
                }

                form.system.setOptions(options).enable();
            }
        };

        /**
        * beautifyLegs
        *
        * Iterates over legs, assigns running numbers and row colours
        */
        var beautifyLegs = function() {
            var i = 1;

            // iterate over all legs
            dom.betslip.find('div.leg').each(function() {
                var elem = $(this);

                // add running number
                elem.find('div.race span.leg-no').html(i);

                // add background color
                if (i++ % 2 === 0) {
                    elem.addClass('dark');
                } else {
                    elem.removeClass('dark');
                }
            });

            // show/hide no-leg content
            if (_.size(legs)) {
                dom.noLeg.hide();
            } else {
                dom.noLeg.show();
            }

            adjustSystemDropdown();
        };

        /**
        * checkLegsBetTypes
        *
        * Check if the current bet type is supported by each leg and mark them otherwise
        */
        var checkLegsBetTypes = function() {
            if (!options.pick) {
                var betType = form.betType.val();
                $.each(legs, function(idRace, leg) {
                    $.each(leg.runners, function(idRunner, runner) {
                        if ($.inArray(betType, leg.betTypes[runner.betCategory]) == -1) {
                            leg.runners[idRunner].dom.addClass('is-problem');
                        } else {
                            leg.runners[idRunner].dom.removeClass('is-problem');
                        }
                    });
                });
            }

            updateInfo();
        };

        /**
        * evalBet
        *
        * Evaluate the betting slip (number of bets, stakes etc.)
        */
        var evalBet = function() {
            // check bet type
            if (dom.betslip.find('div.leg div.runner.is-problem').length) {
                bet.error = raceBetsJS.i18n.data.errorAccBetTypeUnsupported;
                return;
            }

            // default bet object
            bet = {
                betslipType: 'ACC',
                currency: options.pick.currency || raceBetsJS.application.user.currency,
                betType: form.betType.val(),
                unitStake: parseFloat(form.unitStake.val()),
                accSystem: form.system.val(),
                numRaces: _.size(legs)
            };

            // disallow place fixed accumlation betting and get all bet categories
            var betCategories = [];
            var fixedPlaceError = false;
            $.each(legs, function(idRace, leg) {
                $.each(leg.runners, function(idRunner, runner) {
                    // add cateogory to array
                    betCategories.push(runner.betCategory);

                    if (runner.betCategory !== 'BOK' && bet.betType === 'PLC') {
                        // set
                        fixedPlaceError = true;
                    }
                });
            });

            if (fixedPlaceError === true) {
                bet.error = raceBetsJS.i18n.data. noFixedPlaceError;
                return;
            }

            // check number of races
            if (bet.numRaces < 2 && !options.pick) {
                bet.error = raceBetsJS.i18n.print('errorAccBetMinNumRaces', { num: 2 });
                return;
            } else if (bet.numRaces > 8) {
                bet.error = raceBetsJS.i18n.print('errorAccBetMaxNumRaces', { num: 8 });
                return;
            }

            // set bet category
            bet.betCategory = ( options.pick ? (Object.keys(options.pick.types)).toString() : ($.inArray('FXD', _.uniq(betCategories)) > -1 ? 'FXD' : 'BOK') );

            // check unit stake
            var minMaxStake = getMinMaxStake();
            var minStake = minMaxStake.minStake;
            var maxStake = minMaxStake.maxStake;

            if (bet.unitStake < minStake) {
                bet.error = raceBetsJS.i18n.print('errorMinStake', { amount: raceBetsJS.format.money(minStake, 2, bet.currency, true) });
                return;
            } else if (maxStake > minStake && bet.unitStake > maxStake) {
                bet.error = raceBetsJS.i18n.print('errorMaxStake', { amount: raceBetsJS.format.money(maxStake, 2, raceBetsJS.application.user.currency, true) });
                return;
            }

            // easier marks format (just array with number of runners per race)
            var marks = [];
            $.each(legs, function() {
                marks.push(_.size(this.runners) - this.coupledRunners);
            });

            // determine number of bets
            var numBets = 0;

            if (bet.accSystem === '') {
                // no system
                numBets = marks[0] || 0;
                for (i = 1; i < marks.length; i++) {
                    numBets *= marks[i];
                }
            } else if (bet.accSystem == 'C') {
                // combination system without single bets
                numBets = getNumBetsCombi(marks);
            } else if (bet.accSystem == 'F') {
                // full combination
                numBets = getNumBetsCombi(marks);
                for (i = 0; i < marks.length; i++) {
                    numBets += marks[i];
                }
            } else {
                numBets = getNumBetsSystem(marks, bet.accSystem);
            }

            if (bet.betType == 'WP') {
                numBets *= 2;
            }

            bet.numBets = numBets;

            if (bet.numBets === 0) {
                bet.error = raceBetsJS.i18n.data.errorAccBetOneRunnerPerRace;
                return;
            }

            var totalStakeEUR = Math.round(numBets * bet.unitStake * 100) / 100;
            if (bet.currency !== 'EUR') {
                totalStakeEUR = raceBetsJS.localize.exchange(totalStakeEUR, bet.currency, 'EUR');
            }
            bet.totalStakeEUR = totalStakeEUR;
        };

        /**
        * getMinMaxStake()
        *
        * Determine the minimum and maximum stake
        */
        var getMinMaxStake = function() {
            // if no legs, return zero min/max stakes
            if (_.size(legs) === 0) {
                return {
                    minStake: 0,
                    maxStake: 0
                };
            }

            var betCategories = [],
                minStakes = [],
                maxStakes = [],
                settings = raceBetsJS.application.globals.currencySettings[raceBetsJS.application.user.currency];

            // get all bet categories
            $.each(legs, function(idRace, leg) {
                $.each(leg.runners, function(idRunner, runner) {
                    if ($.inArray(runner.betCategory, betCategories) > -1) {
                        return;
                    }

                    minStakes.push(settings['minStake' + runner.betCategory]);
                    maxStakes.push(settings['maxStake' + runner.betCategory]);
                    betCategories.push(runner.betCategory);
                });
            });

            // 07.07.2014: Pickbets now can be TOT or BOK. We use min unit stake from JSON in case of pickbet
            var minStake = (raceBetsJS.format.isPickBetType(bet.betType)) ? options.minUnitStake : _.max(minStakes);
            var maxStake = (raceBetsJS.format.isPickBetType(bet.betType)) ? options.maxUnitStake : _.min(maxStakes);

            return {
                minStake: minStake,
                maxStake: maxStake
            };
        };

        /**
        * getNumBetsCombi
        *
        * Returns the number of bets for a combi system (all combinations without the single bets)
        */
        var getNumBetsCombi = function(marks) {
            var numBets = 0;
            for (var i = 2; i <= bet.numRaces; i++) {
                numBets = numBets + getNumBetsSystem(marks, i);
            }
            return numBets;
        };

        /**
        * getNumBetsSystem
        *
        * Returns the number of bets for a certain system (e.g. 2 out of 5)
        */
        var getNumBetsSystem = function(marks, system) {
            if (system === 0) {
                return 1;
            }

            var numBets = 0;

            for (var i = 0; i < marks.length - (system - 1); i++) {
                for (var j = 0; j < marks[i]; j++) {
                    var copy = $.extend([], marks);
                    for (var k = 0; k < i + 1; k++) {
                        copy.shift();
                    }
                    numBets = numBets + getNumBetsSystem(copy, system - 1);
                }
            }

            return numBets;
        };

        /**
        * relocateBetslip
        *
        * Positions the betslip over the sidebar if it would be out of the viewport otherwise
        */
        var relocateBetslip = function() {
            // If rightbar is visble, large screen media query is active
            if ($('#rightBar').is(':visible')) {
                $('#m-betslip').prepend(dom.betslip);
            } else {
                $('#sidebar').prepend(dom.betslip);
            }
            raceBetsJS.application.globals.rightBar.checkStickiness();
        };

        /**
        * selectPick
        *
        * Select a new pick bet type
        */
        var selectPick = function(type, category) {
            category = (!category) ? bet.betCategory : category;

            if (raceBetsJS.format.isPickPlace(type)) {
                $('#runners-table .top .checkboxes .col-1').text(raceBetsJS.i18n.data.betTypePLC);
            } else {
                $('#runners-table .top .checkboxes .col-1').text(raceBetsJS.i18n.data.labelCol1);
            }


            dom.legs.height(dom.legsContainer.outerHeight()).addClass('loading');
            var req = $.get('/ajax/accumulation/betslip/idRace/' + options.pick.idRace + '/betType/' + type + '/betCategory/' + category + '/');

            req.done(function(data) {
                options.minUnitStake = data.minUnitStake;
                options.maxUnitStake = data.maxUnitStake || 0;
                options.pick.currency = data.currency;

                var races = [];
                $.each(data.races, function(key, race) {
                    addRace(race);
                    races.push(race.idRace);
                });

                // remove races which are not in the pick anymore
                $.each(_.difference( $.map(_.keys(legs), function(val) { return parseInt(val); }), races ), function(key, idRace) {
                    removeRace(idRace);
                });

                dom.legs.animate({
                    height: dom.legsContainer.outerHeight()
                }).promise().done(function() {
                    dom.legs.removeClass('loading').height('auto');
                });

                // set the stake options
                var stakes = [],
                    max = options.maxUnitStake ? options.maxUnitStake / options.minUnitStake : 20;

                for (var i = 1; i <= max; i++) {
                    var value = i * data.minUnitStake;
                    stakes.push({
                        value: value,
                        title: raceBetsJS.format.money(value, 2, options.pick.currency)
                    });
                }
                form.unitStake.setOptions(stakes).selectFirst();
            });
        };

        /**
        * submit
        *
        * Submits the ACC betslip
        */
        var submit = function(bet) {
            // return if bet is not valid
            if (bet.error) {
                return false;
            }

            dom.submit.removeClass('enabled');
            dom.submit.attr('disabled', true);

            /*
            // check for any empty races, due to a bug that is reproducable at this point (14/11/2013)
            $.each(legs, function(idRace, raceData) {
                if (raceData.runners.length == 0) {
                    removeRace(idRace);
                }
            });
            */

            // form marks array
            var marksAcc = [],
                stablesAdded = {},
                eventCountries = [];

            _.each(raceOrder, function(idRace) {
                var race = {
                    idRace: idRace,
                    runners: []
                };
                var raceData = legs[idRace];
                if(!raceData) {
                    return;
                }

                eventCountries.push(raceData.country);

                stablesAdded[idRace] = [];

                $.each(raceData.runners, function(idRunner, runnerData) {

                    // if stable runner submit only first non-scratched
                    if (runnerData.ignoreStable === false) {
                        if ($.inArray(parseInt(runnerData.programNumber), stablesAdded[idRace]) === -1) {
                            stablesAdded[idRace].push(parseInt(runnerData.programNumber));
                        } else {
                            return;
                        }
                    }

                    var runner = {
                        // create basic runner object
                        programNumber: runnerData.programNumber.toString().replace(/[^\d.]/g, '')
                    };

                    if (runnerData.betCategory === 'FXD' && bet.betType === 'WIN') {
                        runner.fixedOddsWin = runnerData.odds.FXW;
                    } else if (runnerData.betCategory === 'FXD' && bet.betType === 'WP') {
                        runner.fixedOddsWin = runnerData.odds.FXW;
                        runner.fixedOddsPlace = runnerData.odds.FXP;

                    } else if (bet.betCategory === 'FXD' && runnerData.betCategory === 'BOK') {
                        runner.useSp = 1;
                    }

                    race.runners.push(runner);
                });

                marksAcc.push(race);
            });

            // get runners object
            var runners = {};
            $.each(legs, function(idRace, leg) {
                $.each(leg.runners, function(idRunner, runner) {
                    runners[idRunner] = {
                        idRace: idRace,
                        name: runner.name,
                        programNumber: runner.programNumber
                    };
                });
            });

            // make countries unique
            var uniqueCountries = _.uniq(eventCountries);

            // track event
            dataLayer.push({
                'BetCategory': bet.betCategory,
                'BetType': bet.betType,
                'event': 'PlaceBetClick'
            });

            // create Bet instance and send it
            var _bet = new raceBetsJS.Bet($.extend({}, bet, {
                marksAcc: marksAcc
            }),
            {
                eventCountry: (options.pick ? options.pick.country : uniqueCountries.toString()),
                runners: runners
            });

            $.when(_bet.submit())
                .done(close)
                .fail(function() {
                    dom.submit.addClass('enabled');
                    dom.submit.attr('disabled', false);
                });
        };

        /**
        * updateInfo
        *
        * Update the betslip footer
        */
        var updateInfo = function() {
            evalBet();

            /*
            dom.betslip.removeClass('numbers-only');
            if (dom.legs.outerHeight() > $(window).height() - 500) {
                dom.betslip.addClass('numbers-only');
            }
            */
            dom.bonusMoneyInfo.hide();

            if (bet.error) {
                dom.betStatus.removeClass('valid').html(bet.error);
                dom.betInfo.hide();
                dom.submit.removeClass('enabled');
                dom.submit.attr('disabled', true);
                dom.diffCurrencyInfo.html('');
            } else {
                raceBetsJS.application.assets.bonusMoney.isVisible(bet.unitStake).then(function(result){
                    if (result) {
                        dom.bonusMoneyInfo.show();
                    }
                });

                dom.betStatus.addClass('valid').html(raceBetsJS.i18n.data.betValid);
                dom.numBets.html(raceBetsJS.i18n.print('labelNumBets', { numBets: '<b>' + bet.numBets + '</b>' }));
                dom.totalStake
                    .html(raceBetsJS.i18n.data.labelStake + ': ')
                    .append($('<b />').text(raceBetsJS.format.money(bet.numBets * bet.unitStake, 2, bet.currency, true)));

                if (bet.currency != raceBetsJS.application.user.currency) {
                    dom.totalStake.append($('<span />').addClass('light-grey').text(' (' + raceBetsJS.format.money(bet.numBets * bet.unitStake, 2, bet.currency, true, true) + ')'));

                    if (['suaposta', 'europebet'].indexOf(raceBetsJS.application.globals.brandName) > -1) {
                        dom.diffCurrencyInfo.html(
                            raceBetsJS.i18n.print('msgDiffCurrencyInfo', {
                                originalCurrency: bet.currency,
                                chargedAmount: raceBetsJS.format.number(raceBetsJS.localize.exchange(bet.numBets * bet.unitStake, bet.currency, raceBetsJS.application.user.currency), 2) + ' ' + raceBetsJS.application.user.currency
                            })
                        );
                    }
                }

                dom.betInfo.show();
                dom.submit.addClass('enabled');
                dom.submit.attr('disabled', false);

                if (raceBetsJS.application.globals.rightBar && raceBetsJS.application.globals.rightBar.checkStickiness) {
                    raceBetsJS.application.globals.rightBar.checkStickiness();
                }
            }
        };

        // @public

        /**
        * init
        *
        * Creates and opens the betslip on top of the sidebar
        *
        * options = {
        *     pick: {
        *         idRace: 1234,
        *         type: 'P06'
        *     }
        * }
        */
        var init = function(_options) {
            // stop if betslip is open already
            if (dom.betslip) {
                return;
            }

            // set options
            options = $.extend({
                pick: false,
                minUnitStake: 0.50,
            }, _options);

            // render betslip
            dom.betslip = $(raceBetsJS.application.templates.accBetslip(options));

            // save dom references
            dom.legs = dom.betslip.find('div.legs');
            dom.legsContainer = dom.betslip.find('div.legs-container');
            dom.betStatus = dom.betslip.find('div.footer div.summary div.status');
            dom.betInfo = dom.betslip.find('div.footer div.summary div.info');
            dom.numBets = dom.betInfo.find('span.num-bets');
            dom.totalStake = dom.betInfo.find('span.total-stake');
            dom.bonusMoneyInfo = dom.betslip.find('.bonus-money-info');
            dom.bonusMoneyDetails = dom.betslip.find('.bonus-money-details');
            dom.diffCurrencyInfo = dom.betslip.find('.diff-currency-info');

            // insert dropdowns
            form.betType = new raceBetsJS.application.assets.Dropdown(dom.betslip.find('div.bet-type select'), {
                width: 120,
                textAlign: 'right',
                onChange: function() {

                    if (!options.pick) {
                        var oldBetType = bet.betType;
                        var newBetType = form.betType.val();
                        if(oldBetType === 'WIN' || newBetType === 'WIN') {
                            removeStableWithDiffProgNumber();
                        }

                        checkLegsBetTypes();
                    } else {
                        selectPick(form.betType.val());
                    }
                }
            });

            form.unitStake = new raceBetsJS.application.assets.Dropdown(dom.betslip.find('div.stake select'), {
                width: 120,
                textAlign: 'right',
                isTextInput: true,
                textInputOnBlur: function(e) {
                    var elem = $(e.target);
                    var stake = elem.val();
                    //remove all non-numeric (except .,) characters to avoid NaN value form parseFloat
                    stake = stake.replace(/[^0-9.,]/g, '');
                    stake = stake.replace(raceBetsJS.application.globals.thousandsSeparator, '');
                    stake = stake.replace(raceBetsJS.application.globals.decimalSeparator, '.');

                    stake = parseFloat(stake);

                    elem.val(raceBetsJS.format.money(stake, 2, bet.currency));
                    form.unitStake.val(raceBetsJS.format.number(stake, 2, '.', ''));
                },
                onChange: updateInfo
            });

            form.system = new raceBetsJS.application.assets.Dropdown(dom.betslip.find('div.system select'), {
                width: 100,
                textAlign: 'right',
                disabled: true,
                onChange: updateInfo
            });

            // cache dom references
            dom = $.extend(dom, {
                closeButton: dom.betslip.find('span.close'),
                noLeg: dom.betslip.find('div.no-leg'),
                footer: dom.betslip.find('div.footer'),
                submit: dom.betslip.find('div.footer .submit')
            });

            // close button delegator
            dom.closeButton.on('click', close);

            // submit button delegator
            dom.submit.on('click', function() {
                submit(bet);
            });

            dom.bonusMoneyInfo.on('click', function() {
                dom.bonusMoneyDetails.slideToggle();
            });
            // add delegator for removal of races/runners
            dom.betslip.on('click', 'span.remove', function() {
                var elem = $(this);
                var idRace = elem.parents('div.leg').data('race');

                if (elem.hasClass('runner')) {
                    removeRunner({
                        idRace: idRace,
                        idRunner: elem.parent().data('runner')
                    });
                } else {
                    removeRace(idRace);
                }
            });

            // insert hidden betslip to DOM
            $('#m-accWidget--rightBar').after($('<div />').attr({ id: 'm-betslip', class: 'm-betslip c-box' }));
            $('#m-betslip').append(dom.betslip.hide());
            updateInfo();

            // swapped or over sidebar?
            relocateBetslip();
            $(window).on('resize.accBetslip', relocateBetslip);

            // show betslip
            dom.betslip.slideDown().promise().done(function() {
                dom.closeButton.fadeIn();

                if (!options.pick) {
                    $('#sidebar').children('div.sidebar.tabbed').addClass('accumulator');
                }

                // request pick data
                if (options.pick) {
                    selectPick(form.betType.val());
                }
            });
        };

        /**
        * isOpen
        *
        * Return if acc betslip is opened
        */
        var isOpen = function() {
            return dom.betslip ? (options.pick ? 'pick' : 'acc') : false;
        };

        /**
        * betType
        *
        * Return bet type
        */
        var betType = function () {
            return form.betType.val();
        };

        /**
        * close
        *
        * Hide Acc Betslip
        */
        var close = function() {
            var df = $.Deferred();

            $('#runners-table .top .checkboxes .col-1').text(raceBetsJS.i18n.data.labelCol1);

            // hide betslip
            dom.closeButton.fadeOut().promise().done(function() {
                dom.betslip.slideUp(500).promise().done(function() {
                    $(window).off('resize.accBetslip');
                    dom.betslip.remove();
                    $('#m-betslip').remove();
                    $('#sidebar').children('div.sidebar.tabbed').removeClass('accumulator');
                    dom = {};
                    legs = {};
                    raceOrder = [];
                    df.resolve();
                });
            });

            $.publish('/accBetslip/close', [df.promise()]);

            curAccType = null;

            return df.promise();
        };

        /**
        * addRunner
        *
        * Adds a runner to the current betslip
        *
        * details = {
        *     race: {
        *         idRace: 1234,
        *         event: 'Hamburg',
        *         raceNumber: 5
        *     },
        *     runner: {
        *         idRunner: 12345,
        *         name: 'Koenigstiger',
        *         programNumber: 8
        *     }
        * }
        */
        var addRunner = function(details) {
            // abort if runner is scratched and stable horse
            if (details.runner.ignoreStable === false && details.runner.scratched) {
                return false;
            }

            var idRace = details.race.idRace;
            var idRunner = details.runner.idRunner;

            // if runner already exist, stop right here
            if (hasRace(idRace) && hasRunner({ idRace: idRace, idRunner: idRunner })) {
                // update bet category only if defined and not matching with existing selection
                if (details.runner.betCategory && details.runner.betCategory !== legs[idRace].runners[idRunner].betCategorySelect.val()) {
                    legs[idRace].runners[idRunner].betCategorySelect.val(
                        (details.runner.betCategory === 'FXD' ? 'FXW' : 'PRC'), true
                    );
                }
                return;
            }

            // is head2head / specials / ante post?
            details.race.isHeadToHead = (details.race.isHeadToHead === undefined ? false : details.race.isHeadToHead);
            details.race.isSpecialBets = (details.race.isSpecialBets === undefined ? false : details.race.isSpecialBets);
            details.race.isAntePost = (details.race.isAntePost === undefined ? false : details.race.isAntePost);

            curAccType = getAccType(details);

            // stop if pick bet and race does not exist
            if (options.pick && !hasRace(idRace)) {
                return false;
            }

            // in case of H2H, do not add a runner from the same leg
            if (details.race.isHeadToHead === true && hasRace(details.race.relatedIdRace)) {
                return false;
            }

            // in case of H2H in the same race, delete previous runner
            if (details.race.isHeadToHead === true && hasRace(idRace)) {
                removeRunner({
                    idRace: idRace,
                    idRunner: _.keys(legs[idRace].runners)[0]
                });
            }

            // add the race first
            addRace(details.race);

            // stop if runner exists already
            if (hasRunner({idRace: idRace, idRunner: idRunner})) {
                return false;
            }

            // clean up odds (remove zero fixed)
            var cleanOdds = {};
            $.each(details.runner.odds, function(cat, odds) {
                if (cat !== 'PRC' && odds === 0) {
                    return;
                }
                cleanOdds[cat] = odds;
            });
            details.runner.odds = cleanOdds;

            // insert leg in the dom
            var runner = $(details.race.isHeadToHead ?
                raceBetsJS.application.templates.accBetslip.runner.H2H({ runner: details.runner }) :
                raceBetsJS.application.templates.accBetslip.runner({ runner: details.runner }));
            legs[idRace].dom.append(runner);

            // create dynamic Dropdown()
            var betCategorySelect = new raceBetsJS.application.assets.Dropdown(runner.find('select.bet-category'), {
                width: 54,
                disabled: (_.size(details.runner.odds) === 1),
                addClass: 'small',
                textAlign: 'right',
                selected: (details.runner.betCategory === 'FXD' ? 'FXW' : 'PRC'),
                onChange: function(select) {
                    legs[idRace].runners[idRunner].betCategory = (select.val().match(/^FX/) ? 'FXD' : 'BOK');
                    checkLegsBetTypes();
                }
            });

            // add runner to the leg object
            legs[idRace].runners[idRunner] = {
                dom: runner,
                betCategory: details.runner.betCategory,
                odds: cleanOdds,
                betCategorySelect: betCategorySelect,
                name: details.runner.name,
                programNumber: details.runner.programNumber,
                ignoreStable: details.runner.ignoreStable,
                markAsStableWithDiffProgNumber: details.runner.markAsStableWithDiffProgNumber
            };

            // Check if stable was already in leg for horses from the same stable but different program numbers
            // not for US where horses from the same stable have the same program number
            function isStableAlredyRegistered () {
                if(!_.size(legs[idRace].stablesAdded)) return false;

                var stableArr = _.filter(details.race.stables, function(arr){
                    return arr.indexOf(parseInt(details.runner.idRunner, 10)) > -1;
                })[0];

                return _.size(_.filter(legs[idRace].stablesAdded, function (runnersId) {
                    return stableArr.indexOf(runnersId) > -1;
                }));
            }

            // increase coupled runners counter
            if (details.runner.ignoreStable === false) {
                if ((details.race.country === 'US' && !legs[idRace].stablesAdded[details.runner.stable]) || (details.runner.country !== 'US' && !isStableAlredyRegistered())) {
                    // add stable number and first stable horse id (used by getRunners to return the first stable horse only)
                    legs[idRace].stablesAdded[details.runner.stable] = _.find(details.race.runners, function(num) { return num.programNumber === details.runner.stable; }).idRunner;

                    _.each(details.race.stables, function(stable) {
                        stable = _.map(stable, String);
                        if (stable.indexOf(idRunner) > -1) {
                            legs[idRace].stablesLength[details.runner.stable] = stable.length;
                            legs[idRace].coupledRunners += stable.length-1;
                        }
                    });
                }
            }


            // if H2H, add related idRace and related idRunners
            if (details.race.isHeadToHead === true) {
                legs[idRace].relatedIdRace = details.race.relatedIdRace;
                legs[idRace].runners[idRunner].relatedIdRunners = details.runner.relatedIdRunners;
            }

            // mark runner if betting type is not supported
            if (!options.pick && $.inArray(form.betType.val(), legs[idRace].betTypes[details.runner.betCategory]) == -1) {
                legs[idRace].runners[idRunner].dom.addClass('is-problem');
            }

            // update numbers row
            //updateNumbersRow(idRace);

            updateInfo();

            return true;
        };

        /**
        * getPickRace
        *
        * Returns the ID of the race of this pick bet if it is one
        */
        var getPickRace = function() {
            if (options.pick) {
                return options.pick.idRace;
            } else {
                return false;
            }
        };

        /**
        * getRunners
        *
        * Returns the runners which are on the betslip for a given idRace
        */
        var getRunners = function(idRace) {
            var ret = [];

            if (!hasRace(idRace)) {
                return ret;
            } else {
                ret = $.map(_.keys(legs[idRace].runners), function(val) {
                        if (!/[A-Z]/.test(legs[idRace].runners[val].programNumber) || legs[idRace].runners[val].ignoreStable === true) {
                            // if not stable horse or first of the stable runners
                            return parseInt(val);
                        } else if (/[A-Z]/.test(legs[idRace].runners[val].programNumber) && legs[idRace].runners[val].ignoreStable === false) {
                            // if stable horse (eg. 1A, 2B), return the first idRunner from that stable (eg. idRunner of 1 if runner is 1A)
                            return legs[idRace].stablesAdded[parseInt(legs[idRace].runners[val].programNumber)];
                        } else {
                            return null;
                        }
                });
                // return idRunners, first one once for stable horses
                return _.uniq(ret);
            }
        };

        /**
        * hasRace
        *
        * Returns if the betslip does contain the given race
        */
        var hasRace = function(idRace) {
            return _.has(legs, idRace);
        };

        /**
         * hasRelatedRace
         *
         * Returns if the betslip does contain the given realted race
         */
        var hasRelatedRace = function(idRace) {
            var res = _.find(legs, function (leg) {
                return leg.relatedIdRace === idRace;
            });
            return res !== undefined;
        };

        /**
        * hasRunner
        *
        * Return if a given runner is on the betslip
        */
        var hasRunner = function(details) {
            return (_.has(legs, details.idRace) && _.has(legs[details.idRace].runners, details.idRunner));
        };

        /**
        * hasRelatedRunner
        *
        * Return if a given runner or opponent from the same parent race is on the betslip
        */
        var hasRelatedRunner = function(details) {
            var allRelatedIdRunners = [];

            _.each(legs, function(race, idRace) {
                if (idRace !== details.idRace) {
                    _.each(race.runners, function(runner) {
                        allRelatedIdRunners = allRelatedIdRunners.concat(runner.relatedIdRunners);
                    });
                }
            });

            var commonIdRunners = _.intersection(details.relatedIdRunners, allRelatedIdRunners);

            return commonIdRunners.length > 0;
        }


        /**
        * removeStableWithDiffProgNumber
        *
        * Remove all stable horses with diffrent program number
        *
        */
        var removeStableWithDiffProgNumber = function() {
            for (var idRace in legs) {
                var thisLeg = legs[idRace];

                for (var idRunner in thisLeg.runners) {

                    var thisRunner = thisLeg.runners[idRunner];
                    if (thisRunner.markAsStableWithDiffProgNumber) {
                        removeRunner({
                            idRace: idRace,
                            idRunner: idRunner
                        });
                    }
                }
            }
        };


        /**
        * removeRace
        *
        * Removes every single runner of a race and therefore the race
        */
        var removeRace = function(idRace) {
            if (!hasRace(idRace)) {
                return;
            }

            // remove runners if there are any
            if (_.size(legs[idRace].runners)) {
                $.each(legs[idRace].runners, function(idRunner) {
                    removeRunner({
                        idRace: idRace,
                        idRunner: idRunner
                    });
                });
            }

            if (hasRace(idRace)) {
                legs[idRace].dom.remove();
                delete legs[idRace];
            }

            // remove race from race order as well
            if (raceOrder.indexOf(idRace.toString()) > -1) {
                var index = raceOrder.indexOf(idRace.toString())
                raceOrder.splice(index, 1)
            }

            // reset type
            if (_.size(legs) === 0) {
                curAccType = null;
            }

            beautifyLegs();
        };

        /**
        * removeRunner
        *
        * Removes a runner from the current betslip
        *
        * details = {
        *     idRace: 1234,
        *     idRunner: 12345
        * }
        */
        var removeRunner = function(details) {
            // stop if runner does not exist
            if (!hasRunner(details)) {
                return;
            }

            var idRace = details.idRace,
                idRunner = details.idRunner,
                programNumber = legs[idRace].runners[idRunner].programNumber;

            // decrease coupled runners counter
            if (legs[idRace].runners[idRunner].ignoreStable === false && legs[idRace].stablesAdded[parseInt(programNumber)]) {
                legs[idRace].coupledRunners -= legs[idRace].stablesLength[parseInt(programNumber)]-1;
                delete legs[idRace].stablesAdded[parseInt(programNumber)];
                delete legs[idRace].stablesLength[parseInt(programNumber)];
            }

            // remove leg from the dom
            legs[idRace].runners[idRunner].dom.remove();
            delete legs[idRace].runners[idRunner];

            // publish removal to remove runner from race card
            if (!details.noPublish) {
                $.publish('/race/' + idRace + '/toggleRunner', [idRunner]);
            }

            var numRunners = _.size(legs[idRace].runners);

            // remove race if it was last runner and this is not a pick bet
            if (!options.pick && !numRunners) {
                removeRace(idRace);
            } else {
                //updateNumbersRow(idRace);
            }

            updateInfo();
        };

        /**
        * isValidRunner
        * Checks if runner can be added or not
        *
        * @param details
        */
        var isValidRunner = function(details) {
            if (details.race.isHeadToHead === true && hasRace(details.race.relatedIdRace) ||
                hasRelatedRace(details.race.idRace)) {
                    raceBetsJS.application.assets.messageBox.show({
                        type: 'notice',
                        content: raceBetsJS.i18n.data.errAccBetSpecialMixSameLeg
                    });
                    return false;
            }

            if (details.race.isHeadToHead === true &&
                hasRelatedRunner({idRace: details.race.idRace, relatedIdRunners: details.runner.relatedIdRunners})) {
                    raceBetsJS.application.assets.messageBox.show({
                        type: 'notice',
                        content: raceBetsJS.i18n.data.errAccBetSpecialSameLeg
                    });
                    return false;
            }

            if (curAccType !== null) {
                var accType = getAccType(details);
                if ((curAccType === 'ante-post' || accType === 'ante-post') && accType !== curAccType) {
                    raceBetsJS.application.assets.messageBox.show({
                        type: 'notice',
                        content: raceBetsJS.i18n.data.errAccBetSpecialMix
                    });
                    return false;
                }
            }

            return true;
        };

        var updateOdds = function(idRunner, fixedOddsWin, fixedOddsPlace, highlight) {
            $.each(legs, function(idRace, leg) {
                if (!_.has(leg.runners, idRunner)) {
                    return;
                }

                // change options
                legs[idRace].runners[idRunner].betCategorySelect.setOptions([
                    { title: 'SP', value: 'PRC' },
                    { title: raceBetsJS.format.odds(fixedOddsWin), value: 'FXW' }
                ]);

                // set odds
                legs[idRace].runners[idRunner].odds.FXW = fixedOddsWin;
                legs[idRace].runners[idRunner].odds.FXP = fixedOddsPlace;

                // reselect the fixed price
                legs[idRace].runners[idRunner].betCategorySelect.val('FXW');

                // highlight if demanded
                if (highlight !== undefined && highlight === true) {
                    legs[idRace].runners[idRunner].dom.addClass('is-problem');
                }
            });
        };

        var getAccType = function(details) {
            if (details.race.isHeadToHead === true) {
                return 'head-to-head';

            } else if (details.race.isAntePost === true) {
                return 'ante-post';

            }

            return 'normal';
        };

        /**
        * debugFunc()
        * Tests for accumulation betslip
        */
        var debugFunc = function() {
            // TEST DEBUG
            raceBetsJS.application.assets.accBetslip.init();
            raceBetsJS.application.assets.accBetslip.addRunner({
                race: {
                    idRace: 1111,
                    event: 'Townsville',
                    raceNumber: 1,
                    betTypes: {BOK:['WIN', 'WP', 'PLC'], FXD: ['WIN']},
                    isSpecialBets: false
                },
                runner: {
                    idRunner: 111111,
                    betCategory: 'BOK',
                    name: 'Haikbidiac',
                    programNumber: 1,
                    odds: {
                        PRC: 1.23,
                        FXW: 1.45,
                        FXP: 1.67
                    }
                }
            });
            raceBetsJS.application.assets.accBetslip.addRunner({
                race: {
                    idRace: 1111,
                    event: 'Leicester',
                    raceNumber: 1,
                    betTypes: {BOK:['WIN', 'WP', 'PLC'], FXD: ['WIN']},
                    isSpecialBets: false
                },
                runner: {
                    idRunner: 111112,
                    betCategory: 'FXD',
                    name: 'We\'ll Shake Hand',
                    programNumber: 2,
                    odds: {
                        PRC: 2.23,
                        FXW: 2.45,
                        FXP: 2.67
                    }
                }
            });
            raceBetsJS.application.assets.accBetslip.addRunner({
                race: {
                    idRace: 1112,
                    event: 'Rom',
                    raceNumber: 4,
                    betTypes: {BOK:['WIN', 'WP', 'PLC'], FXD: ['WIN']},
                    isSpecialBets: false
                },
                runner: {
                    idRunner: 111211,
                    betCategory: 'BOK',
                    name: 'Royal De Bellande',
                    programNumber: 6,
                    odds: {
                        PRC: 3.23,
                        FXW: 3.45,
                        FXP: 3.67
                    }
                }
            });
            /*
            raceBetsJS.application.assets.accBetslip.addRunner({
                race: {
                    idRace: 1113,
                    event: 'Chantilly Head-to-Head',
                    raceNumber: 9,
                    betTypes: {BOK:['WIN', 'WP', 'PLC'], FXD: ['WIN']},
                    isSpecialBets: true
                },
                runner: {
                    idRunner: 111115,
                    betCategory: 'BOK',
                    name: 'Commanche Warrior',
                    opponents: ['Don\'t Think Do'],
                    programNumber: 1,
                    odds: {
                        PRC: 4.23,
                        FXW: 4.45,
                        FXP: 4.67
                    }
                }
            });
            */
        };

        return {
            init: init,
            close: close,
            isOpen: isOpen,
            betType: betType,
            addRunner: addRunner,
            getPickRace: getPickRace,
            getRunners: getRunners,
            hasRace: hasRace,
            hasRunner: hasRunner,
            removeRace: removeRace,
            removeRunner: removeRunner,
            isValidRunner: isValidRunner,
            //changeBetCategory: changeBetCategory,
            updateOdds: updateOdds,
            debug: debugFunc
        };
    })();
})(raceBetsJS);
