/**
* Browser Type
*
* @author David Calleja
*/
(function(raceBetsJS) {
	raceBetsJS.browser.type = {

		version: $.browser.version.substring(0,$.browser.version.indexOf('.')),
		isBrowser: function(browser, version){
			if(version == null ){
				if($.browser[browser]) {
					return true;
		        }
		        return false;

			} else {
				if($.browser[browser] && version == this.version){
					return true;
				}
				return false;
			}
		},
        isiPad: function() {
            return navigator.userAgent.match(/iPad/i) != null;
        },
        isiPhone: function() {
            return navigator.userAgent.match(/iPad|iPhone/i) != null;
        },
		isTablet: function() {
            return (this.isiPad() || (this.isAndroid() && (!this.isMobile())))
		},
        isAndroid: function() {
            return navigator.userAgent.match(/Android/i) != null;
        },
        isMobile: function() {
            return navigator.userAgent.match(/Mobile/i) != null;
        },
		isIE: function(version){
			return this.isBrowser('msie', version);
		},
		isMozilla: function(version){
			return this.isBrowser('mozilla', version);
		},
		isWebkit: function(version){
			return this.isBrowser('webkit', version);
		},

        getBrowser: function(uaString) {
            if (/Firefox/.test(uaString)) {
                return 'Firefox';
            } else if (/(?:Chrome|CrMo|CriOS)\/([0-9.]*)/.test(uaString)) {
                return 'Chrome';
            } else if (
                /MSIE/.test(uaString) ||
                /\(IE ([0-9.]*)/.test(uaString) ||
                /Browser\/IE([0-9.]*)/.test(uaString) ||
                /Xbox\)$/.test(uaString) ||
                /Xbox One\)$/.test(uaString) ||
                /Edge\/([0-9.]*)/.test(uaString)) {
                return 'Explorer';
            } else if (/Safari/.test(uaString)) {
                return 'Safari';
            } else {
                return '';
            }
        },

        getOS: function(uaString) {
            if (/Windows/.test(uaString)) {
                return 'Windows';
            } else if (
                /Mac OS X/.test(uaString) ||
                /AppleTV[0-9],[0-9]/.test(uaString)) {
                return 'Apple OS X';
            } else if (/Linux/.test(uaString)) {
                return 'Linux';
            } else if (
                /ov\(Android ([0-9\.]+)/.test(uaString) ||
                /Android/.test(uaString)) {
                return 'Android';
            } else if (
                /iPhone/.test(uaString) ||
                /like iPhone/.test(uaString) ||
                /iPod/.test(uaString) ||
                /iPad/.test(uaString) ||
                /\(iOS;/.test(uaString)) {
                return 'iOS';
            } else {
                return '';
            }
        }
	};
})(raceBetsJS);
