/**
* Session
* Stores data temporarily (until reload) in a "session"
* @author Robin Ebers
*/
(function(raceBetsJS) {
    raceBetsJS.application.assets.session = function() {
        var cookieName = 'rbssn';

        // @private
        function init() {
            if (!raceBetsJS.application.session) {
                raceBetsJS.application.session = {};
            }
        }

        function set(name, data) {
            // Make sure session is created
            if (!raceBetsJS.application.session) {
                init();
            }
            raceBetsJS.application.session[name] = data;
        }

        function get(name) {
            var data = (raceBetsJS.application.session.hasOwnProperty(name) ? raceBetsJS.application.session[name] : false);
            return data;
        }

        function setCookie(group, data) {
            if (!group) {
                return;
            }

            // Get session cookie or create empty
            var session = getCookie() || {};

            // Set data
            session[group] = data;

            // Set cookie
            _createCookie($.stringify(session));
        }

        function getCookie(group) {
            var cookie = $.cookie(cookieName),
                data;

            if (cookie === null) {
                return false;
            }

            try {
                data = $.parseJSON(cookie);
            } catch (e) {
                // Overwrite if invalid JSON
                _createCookie('{}');
                return false;
            }

            if (group) {
                return data && data[group] ? data[group] : false;
            } else {
                return data;
            }
        }

        function _createCookie(data) {
            $.cookie(cookieName, data, {
                secure: true,
                path: '/',
                domain: raceBetsJS.application.assets.settings.getCookieDomain()
            });
        }

        // @public
        return {
            init: init,
            set: set,
            get: get,
            setCookie: setCookie,
            getCookie: getCookie
        };
    }();
})(raceBetsJS);
