/**
* @description home template
*
*/
(function(raceBetsJS) {

    raceBetsJS.application.templates.loadingLayer = _.template(
        '<div class="c-loading <%= loadingStyle ? "c-loading--" + loadingStyle : "" %>">' +
            '<span class="c-loading__indicator c-loading__indicator--bouncer">' +
                '<span class="c-loading__elem1"></span>' +
                '<span class="c-loading__elem2"></span>' +
                '<span class="c-loading__elem3"></span>' +
            '</span>' +
        '</div>'
    );

})(raceBetsJS);
