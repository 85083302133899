/**
* Homepage "Ante-post races" module
*
* @author David Calleja
*/

(function(raceBetsJS) {
    raceBetsJS.application.content.home.antePostRaces = (function() {
        // @private
        var templates = {
                'panel_template': raceBetsJS.application.templates.panel,
                'content_template': raceBetsJS.application.templates.home.antePostRaces,
                'image_template': raceBetsJS.application.templates.home.antePostRaces.image,
                'controls_template': raceBetsJS.application.templates.home.antePostRaces.controls
            },
            panel_data = {
                'title': '',
                'cssClass': 'c-widget--antepostEvents dark with-rows',
                'id': 'antepost-events',
                'more': null
            },
            content_area_selector = '.content_container',
            el = null,
            moduleArea = null,
            data = {},
            currentEvent = 0,
            refreshRate = 5,
            timer = null,
            timerStatus = 0,
            $image1 = null,
            $image2 = null,
            silks = [],
            imageFiles = [];

        var render = function(area) {

            checkData(currentEvent);

            if (el === null) {
                var $controls = $( templates.controls_template({ 'data': {'antepostEvent': data} }) ),
                    $imageOverlay = $( templates.image_template() );

                area.append( templates.panel_template( $.extend(panel_data, {'content': ''}) ) );
                area.find(content_area_selector).append( $controls ).prepend( $imageOverlay );

                // preload silks
                var dataL = data.length;
                for (var i = dataL - 1; i >= 0; i--) {
                    var dataRunner = data[i],
                        topRunner,
                        silkImage;
                    if (!$.isEmptyObject(dataRunner.topRunners)) {
                        for (var j = dataRunner.topRunners.length - 1; j >= 0; j--) {
                            silkImage = new Image();
                            topRunner = dataRunner.topRunners[j];
                            if (topRunner.silkExtension) {
                                silkImage.src = raceBetsJS.application.globals.imageHost +
                                                    '/cache/img/silks/' + topRunner.silkId + '_w42.' +
                                                    topRunner.silkExtension;
                            }
                        }
                    }

                }

                // preload replacement antepost silk
                var replacementSilk = new Image();
                replacementSilk.src = raceBetsJS.application.globals.imageHost + '/images/cards/silks/slw.png' ;

                $image1 = area.find('.image-overlay .image1');
                $image2 = area.find('.image-overlay .image2').attr('src', data[currentEvent].imagePath);

                reload();
                eventsInit();

            } else {
                reload();
            }
        };

        var checkData = function(ev) {
            if ( $.isEmptyObject(data[ev]) ) {
                currentEvent = 0;
            }
        };

        var reload = function(){
            checkData(currentEvent);
            stopTimer();
            if (el) {
                el.find('.details').remove();
            }

            el = $('#' + panel_data.id + ' ' + content_area_selector);

            var $content = $( templates.content_template({ 'data': data[currentEvent] }) );

            el.prepend($content);

            setPanelTitle( data[currentEvent] );
            setImage( currentEvent );
            setControls( currentEvent );
            setTooltip( data[currentEvent].comment );

            if (data.length < 2){
                el.find('.controls').hide();
            } else {
                startTimer();
            }

            createOddsButtons();
        };

        var isToday = function(date) {
            var currentTime = new Date();
            date = date.split('-');
            return (parseInt(date[0], 10) == currentTime.getFullYear()) && (parseInt(date[1], 10) == currentTime.getMonth() + 1) && (parseInt(date[2], 10) == currentTime.getDate());
        };

        var setPanelTitle = function(currentEvent) {
            var panelTitle = '<span class="c-flag isCountry' + currentEvent.country + '"></span>';

            if (currentEvent.raceTitle !== undefined && currentEvent.raceTitle !== '') {
                panelTitle += '<strong>' + raceBetsJS.i18n.getRaceTitle(currentEvent.raceTitle, currentEvent.raceNumber) + '</strong> ';
            }

            panelTitle += '<span class="event-date">';

            panelTitle += (isToday(currentEvent.eventDate) ?
                            raceBetsJS.i18n.data.dateToday + " " + raceBetsJS.i18n.print("timeLong", {time:raceBetsJS.application.assets.timezone.date("H:i", currentEvent.postTime)}) :
                            raceBetsJS.application.assets.timezone.date('d.m.Y',currentEvent.postTime));

            panelTitle += '</span>';

            el.parent('.panel').find('.header_container .text').html(panelTitle);
        };

        var setTooltip = function(comment) {
            var $imageOverlay = el.find('.image-overlay');

            if(comment !== '') {
                Tipped.create($imageOverlay, comment, {
                    hook: 'rightmiddle',
                    skin: 'racebets stars',
                    maxWidth: 250,
                    offset: { x: -50 },
                    hideOn: [
                        { element: 'self', event: 'mouseleave' },
                        { element: 'tooltip', event: 'mouseenter' }
                    ]
                });
            } else {
                Tipped.remove($imageOverlay);
            }
        };

        var startTimer = function() {
            timerStatus = 1;
            timer = setTimeout(
                function(){
                    if(timerStatus == 1) {
                        if (!el) {
                            // just return if the timer stopped
                            stopTimer();
                            return;
                        }
                        changeEvent('next');
                    }
                },
                refreshRate * 1000
            );
        };

        var stopTimer = function() {
            if(timer !== null && timerStatus == 1) {
                timerStatus = 0;
                clearTimeout(timer);
                timer = null;
            }
        };

        var eventsInit = function() {

            el.on('mouseenter', function(e) {
                e.preventDefault();
                stopTimer();
            });

            el.on('mouseleave', function(e) {
                e.preventDefault();

                if(timer === null) {
                    startTimer();
                }
            });

            el.on('mouseup', '.odds-button.fixed.enabled', function(e) {
                e.preventDefault();

                raceBetsJS.application.assets.bettingDialog.show(
                    this,
                    {
                        onOddsChange: $.proxy(
                            function(newOdds) {
                                replaceOdds(el.find('.odds-button[data-id-race="' + $(this).data('id-race') + '"]'));
                            },
                            this
                        )
                    }
                );
            });

            el.on('mouseup', '.odds-button.price.enabled', function(e) {
                e.preventDefault();
                raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + "/race/details/id/" + $(this).attr("data-id-race"));
            });

            el.on('click', '.controls li', function(e) {
                e.preventDefault();
                var selectedEvent = $(this).index();

                if (currentEvent !== selectedEvent) {
                    currentEvent = selectedEvent;
                    reload();
                }
            });
        };

        var setImage = function(eventNr) {
            el.find('.image-overlay img').eq(0).attr('src', data[eventNr].imagePath);

            el.find('.image-overlay img').eq(1).fadeOut(function() {
                if (el) {
                    $(this).prependTo( el.find('.image-overlay') ).show();
                }
            });

        };

        var setControls = function(eventNr) {
            var $controls = el.find('.controls li');

            $controls
                .removeClass('active')
                .eq(eventNr).addClass('active');
        };

        var changeEvent = function(direction){
            if ( !raceBetsJS.application.assets.bettingDialog.isVisible() ) {
                switch(direction) {
                    case 'next':
                        currentEvent++;
                        if (currentEvent >= data.length) {
                            currentEvent = 0;
                        }
                        break;

                    case 'prev':
                        currentEvent--;
                        if (currentEvent < 0) {
                            currentEvent = data.length - 1;
                        }
                        break;
                }

                if (currentEvent >= data.length) {
                    currentEvent = 0;
                }

                reload();
            }
        };

        var createOddsButtons = function(){
            $.each(
                el.find('.details').find('ul').find('.odds-button'),
                function(){
                    var fixedOdds = $(this).hasClass('fixed'),
                        button = null;

                    if(fixedOdds){
                        button = new raceBetsJS.application.content.race.OddsButton($(this), 'FXW');
                    } else {
                        button = new raceBetsJS.application.content.race.OddsButton($(this), 'PRC');
                    }
                    button.setOdds($(this).children('span').data('odds'), $(this).hasClass('enabled'));
                }
            );
        };

        var remove = function(){
            stopTimer();
            el = null;
        };

        var setData = function(json){
            data = json;
        };

        // @public
        return {
            init: function(json) {
                if (json !== null && !_.isEmpty(json) && json.length > 0) {
                    setData(json);
                    return this;
                } else if (el !== null) {
                    return this;
                }
                return false;
            },
            render: render,
            remove: remove
        };
    })();
})(raceBetsJS);
