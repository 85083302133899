/**
* Viewport
*
* @author Robin Ebers
*/
(function(raceBetsJS) {
	raceBetsJS.browser.viewport = {
        isElementVisible: function(element, viewport, _options) {
            var options = $.extend({
                ignoreNegativeTop: false
            }, _options);

            var rect = viewport.document.getElementById(element).getBoundingClientRect();

            return (
                (rect.top >= 0 || options.ignoreNegativeTop) &&
                rect.left >= 0 &&
                rect.bottom <= $(viewport).innerHeight() &&
                rect.right <= $(viewport).innerWidth()
            )
        }
	};
})(raceBetsJS);