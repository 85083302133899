/**
* @description betting dialog
*
*/
(function(raceBetsJS) {
    /**
    * Betting Dialog template
    */
    raceBetsJS.application.templates.bettingDialog = _.template(
        '<div class="betting-dialog">' +
            '<% var oddsFormat = raceBetsJS.application.assets.settings.get().dialog.general.oddsFormat; %>' +
            '<% var newDialog = (($.inArray(data.country, ["GB", "IE", "AE"]) > -1 && data.isAntePost) || data.spEvent); %>' +
            '<% if (newDialog && data.oddsButtonBetType == "WIN") { %>' +
                '<%= raceBetsJS.application.templates.bettingDialogWin(data) %>' +
            '<% } else if (newDialog && data.oddsButtonBetType == "PLC") { %>' +
                '<%= raceBetsJS.application.templates.bettingDialogPlace(data) %>' +
            '<% } else { %>' +
                '<%= raceBetsJS.application.templates.bettingDialogCommon(data) %>' +
            '<% } %>' +
            '<% if (typeof freeBets !== "undefined" && freeBets.length > 0) { %>' +
                '<div class="row free-bets">' +
                    '<div class="free-bets-label"><%= raceBetsJS.i18n.data.msgFreeBetAvailable %></div>' +
                    '<div class="free-bets-selector">' +
                        '<select id="free-bets-select">' +
                            '<option selected="selected"><%= raceBetsJS.i18n.data.selectNoFreeBet %></option> ' +
                            '<% $.each(freeBets, function() { %>' +
                                '<option value="<%= this.idFreebet %>" <% if (this.valid === false) { %>disabled="disabled"<% } %>>' +
                                    '{span class="amount"}<%= raceBetsJS.format.money(this.amount, this.amount % 1 != 0 ? 1 : 0, raceBetsJS.application.user.currency, true) %>{/span}' +
                                    '<%= raceBetsJS.i18n.data.labelFreeBet %>' +
                                '</option>' +
                            '<% }); %>' +
                        '</select>' +
                    '</div>' +
                '</div>' +
            '<% } %>' +
            '<div class="row stakes">' +
                '<label for="stake-input" class="box-label"><%= raceBetsJS.i18n.data.labelStake %>:</label>' +
                '<div class="stake-amount">' +
                    '<select name="unitStake">' +
                        '<% $.each(raceBetsJS.application.assets.settings.get().dialog.betslip.stakes[raceBetsJS.application.user.currency], function(i) { %>' +
                            '<option value="<%= this %>"><%= raceBetsJS.format.money(this, 2, raceBetsJS.application.user.currency) %><% if (i === 0) { %>selected="selected"<% } %></option>' +
                        '<% }); %>' +
                    '</select>' +
                '</div>' +
                '<div class="other-amount">' +
                    '<button class="c-btn c-btn--link"><%= raceBetsJS.i18n.data.linkChangeAmount %></button>' +
                '</div>' +
            '</div>' +
            '<div class="winnings"></div>' +
            '<div class="buttons">' +
                '<button type="submit" class="btn btn-primary" disabled="disabled"><%= raceBetsJS.i18n.data.buttonPlaceBet %></button>' +
            '</div>' +
        '</div>'
    );

    raceBetsJS.application.templates.bettingDialogCommon = _.template('\
        <% var oddsFormat = raceBetsJS.application.assets.settings.get().dialog.general.oddsFormat; %>\
        <% var resultOddsFormat = (oddsFormat == "fractional" ? "base1" : oddsFormat); %>\
        <div class="title title-common clearfix">\
            <div class="clearfix">\
                <span class="name">\
                    <%= name %>\
                    <span class="odds header-details">\
                        <%= (betTypes.WIN !== undefined || betTypes.WP !== undefined) ? raceBetsJS.format.odds(fixedOddsWin) : "" %>\
                        <%= (betTypes.WP !== undefined || (betTypes.WIN !== undefined && betTypes.PLC !== undefined)) ? " | " : "" %>\
                        <%= (betTypes.PLC !== undefined || betTypes.WP !== undefined) ? raceBetsJS.format.odds(fixedOddsPlace, resultOddsFormat) : "" %>\
                    </span>\
                    <span class="close-tooltip"></span>\
                </span>\
            </div>\
            <div class="title-subpart">\
                <% if (rule4) { %>\
                    <%= raceBetsJS.application.templates.bettingDialogRule4() %>\
                <% } %>\
                <div class="bet-types">\
                    <% if (betTypes.WIN !== undefined) { %>\
                        <div>\
                            <label>\
                                <span class="c-input c-input--radio">\
                                    <input type="radio" name="bet-type" class="c-input__radio bet-type" data-bet-type="WIN" value="WIN" checked>\
                                    <span class="c-input__label"><%= raceBetsJS.i18n.data.betTypeWIN %></span>\
                                </span>\
                            </label>\
                        </div>\
                    <% } %>\
                    <% if (betTypes.PLC !== undefined) { %>\
                        <div>\
                            <label>\
                                <span class="c-input c-input--radio">\
                                    <input type="radio" name="bet-type" class="c-input__radio bet-type" data-bet-type="PLC" value="PLC" <% if (betTypes.WIN == undefined) { %>checked<% } %>>\
                                    <span class="c-input__label"><%= raceBetsJS.i18n.data.betTypePLC %></span>\
                                </span>\
                            </label>\
                        </div>\
                    <% } %>\
                    <% if (betTypes.WP !== undefined) { %>\
                        <div>\
                            <label>\
                                <span class="c-input c-input--radio">\
                                    <input type="radio" name="bet-type" class="c-input__radio bet-type" data-bet-type="WP" value="WP" <% if (betTypes.WIN == undefined && betTypes.PLC == undefined) { %>checked<% } %>>\
                                    <span class="c-input__label"><%= raceBetsJS.i18n.data.betTypeWP %></span>\
                                </span>\
                            </label>\
                        </div>\
                    <% } %>\
                </div>\
            </div>\
        </div>\
    ');

    raceBetsJS.application.templates.bettingDialogWin = _.template('\
        <div class="title title-win clearfix no-odds with-option">\
            <span class="name">\
                <span><%= name %></span> | <%= raceBetsJS.i18n.data.betTypeWIN %> <span class="header-details">@ <%= raceBetsJS.format.odds(fixedOddsWin) %></span>\
                <span class="close-tooltip"></span>\
            </span>\
            <div class="title-subpart">\
                <% if (betTypes.WP !== undefined) { %>\
                    <label>\
                        <span class="c-input c-input--checkbox <% if (spEvent) { %>class="tipped" title="<%= raceBetsJS.i18n.data.tipEachWayTerms %>"<% } %>">\
                            <input type="checkbox" name="terms" class="c-input__checkbox" id="each-way-option">\
                            <span class="c-input__label"><%= raceBetsJS.format.betTypeName("WP") %> @ 1/<%= placeOddsFactor %> - <% for (place = 1; place <= placesNum; place++) { %><%= place %><% if (place < placesNum) { %>,<% } %><% } %></span>\
                        </span>\
                    </label>\
                <% } %>\
                <% if (rule4) { %>\
                    <%= raceBetsJS.application.templates.bettingDialogRule4() %>\
                <% } %>\
            </div>\
        </div>\
    ');

    raceBetsJS.application.templates.bettingDialogPlace = _.template('\
        <div class="title title-place clearfix no-odds">\
            <% var oddsFormat = raceBetsJS.application.assets.settings.get().dialog.general.oddsFormat; %>\
            <% oddsFormat = (oddsFormat == "fractional" ? "base1" : oddsFormat) %>\
            <span class="name<% if (spEvent) { %> tipped<% } %>" <% if (spEvent) { %>title="<%= raceBetsJS.i18n.data.tipEachWayTerms %>"<% } %>><%= name %> <%= raceBetsJS.i18n.data.labelToBePlaced %> @ <%= raceBetsJS.format.odds(fixedOddsPlace, oddsFormat) %> - <% for (place = 1; place <= placesNum; place++) { %><%= place %><% if (place < placesNum) { %>,<% } %><% } %><span class="close-tooltip"></span></span>\
            <% if (rule4) { %>\
                <%= raceBetsJS.application.templates.bettingDialogRule4() %>\
            <% } %>\
        </div>\
    ');

    raceBetsJS.application.templates.bettingDialogRule4 = _.template('\
        <div class="rule4">\
            <a href="<%= raceBetsJS.application.assets.settings.getBrandSettings("supportURLs", "rule4") %>" target="_blank" class="notify-tag dark-grey has-icon" ><i class="icon tick"></i><%= raceBetsJS.i18n.data.labelBettingDialogRule4 %></a>\
        </div>\
    ');

    raceBetsJS.application.templates.betConfirmationDialog = _.template('\
        <table class="betslip-confirmation">\
            <tr>\
                <td class="label"><%= raceBetsJS.i18n.data.labelBetType %></td>\
                <td><span class="bold"><%= raceBetsJS.format.betTypeName(bet.betType, bet.betCategory, raceBetsJS.application.user.lang, (bet.currency == "USD"), extras.eventCountry) %></span> <span class="light-grey">(<%= raceBetsJS.i18n.data["betCategory" + bet.betCategory] %>)</span></td>\
            </tr>\
            <% if (bet.numBets > 1) { %>\
                <tr>\
                    <td class="label"><%= raceBetsJS.i18n.data.labelStake %></td>\
                    <td>\
                        <span class="bold"><%= raceBetsJS.format.money(bet.unitStake, 2, bet.currency, true) %></span>\
                        <% if (bet.currency != raceBetsJS.application.user.currency) { %><span class="light-grey">(<%= raceBetsJS.format.money(bet.unitStake, 2, bet.currency, true, true) %>)</span><% } %>\
                    </td>\
                </tr>\
            <% } %>\
            <tr>\
                <td class="label"><%= raceBetsJS.i18n.data.labelTotalStake2 %></td>\
                <td>\
                    <span class="bold"><%= raceBetsJS.format.money((bet.totalStake ? bet.totalStake : bet.unitStake * bet.numBets), 2, bet.currency, true) %></span>\
                    <% if (bet.currency != raceBetsJS.application.user.currency) { %><span class="light-grey">(<%= raceBetsJS.format.money((bet.totalStake ? bet.totalStake : bet.unitStake * bet.numBets), 2, bet.currency, true, true) %>)</span><% } %>\
                </td>\
            </tr>\
            <% if (bet.taxFee) { %>\
                <tr>\
                    <td class="label"><%= raceBetsJS.i18n.data.labelBettingTax %></td>\
                    <td>\
                        <span class="bold"><%= raceBetsJS.format.money(bet.taxFee, 2, raceBetsJS.application.user.currency, true) %></span>\
                    </td>\
                </tr>\
            <% } %>\
            <% if (bet.betslipType == "STD" && !bet.quicktipp) { %>\
                <tr class="bet">\
                    <td class="label"><%= raceBetsJS.i18n.data.labelBet %></td>\
                    <td>\
                        <% var singleColType = _.include(["WIN", "PLC", "SHW", "WP", "WS", "PS", "WPS", "ITA", "TRT"], bet.betType); %>\
                        <% $.each(bet.marks, function(col, runners) { %>\
                            <% if (!runners.length) return; %>\
                            <% if (!singleColType) { %><span class="col"><%= raceBetsJS.i18n.data["labelCol" + col.toUpperCase()] %></span><% } %>\
                            <% var runnerString = []; %>\
                            <% $.each(runners, function(key, runner) { %>\
                                <% if (singleColType) { %>\
                                    <span class="bold"><%= runner.name %></span>\
                                    <% if (!hideProgramNumber) { %>(<span class=\\"light-grey\\"><%= runner.programNumber %></span>)<% } %>\
                                    <br />\
                                <% } else { %>\
                                    <% runnerString.push("<span class=bold>" + runner.programNumber + "</span>"); %>\
                                <% } %>\
                            <% }); %>\
                            <% if (!singleColType) { %>\
                                <%= runnerString.join(" - ") %>\
                                <br />\
                            <% } %>\
                        <% }); %>\
                    </td>\
                </tr>\
                <% if (bet.betCategory == "FXD") { %>\
                    <tr>\
                        <td class="label"><%= raceBetsJS.i18n.data.labelFixedOdds %></td>\
                        <td class="bold">\
                            <% var oddsFormat = raceBetsJS.application.assets.settings.get().dialog.general.oddsFormat; %>\
                            <% oddsFormat = (oddsFormat == "fractional" ? "base1" : oddsFormat) %>\
                            <% if (bet.fixedOddsWin && _.include(["WIN", "WP"], bet.betType)) { print(raceBetsJS.format.odds(bet.fixedOddsWin)); } %>\
                            <% if (!extras.spEvent && bet.betType == "WP") { print("|"); } %>\
                            <% if ((!extras.spEvent && bet.fixedOddsPlace && _.include(["PLC", "WP"], bet.betType)) || (bet.betType == "PLC" && extras.spEvent)) { print(raceBetsJS.format.odds(bet.fixedOddsPlace, oddsFormat)); } %>\
                        </td>\
                    </tr>\
                    <% if (_.include(["WP"], bet.betType) && extras.placesNum !== undefined) { %>\
                        <tr>\
                            <td class="label"><%= raceBetsJS.i18n.data.labelEachWayTerms %></td>\
                            <td class="bold">\
                                <span class="each-way-terms <% if (extras.spEvent) { %>tipped" title="<%= raceBetsJS.i18n.data.tipEachWayTerms %>"<% } else { %>"<% } %>><%= extras.placesNum %> <%= raceBetsJS.i18n.data.labelPlaces %> @ 1/<%= extras.placeOddsFactor %> <%= raceBetsJS.i18n.data.labelOdds %></span>\
                            </td>\
                        </tr>\
                    <% } %>\
                    <tr>\
                        <td class="label"><%= raceBetsJS.i18n.data.labelRule4Applies %></td>\
                        <td>\
                            <span class="bold"><%= raceBetsJS.i18n.data["button" + (extras.rule4 ? "Yes" : "No")] %></span>\
                        </td>\
                    </tr>\
                <% } %>\
            <% } %>\
        </table>\
    ');

    raceBetsJS.application.templates.betTaxTooltip = _.template('\
        <table>\
            <tr>\
                <td><%= raceBetsJS.i18n.data.betStake %></td>\
                <td class="amount"><%= raceBetsJS.format.money(totalStake, 2, raceBetsJS.application.user.currency, true) %></td>\
            </tr>\
            <tr>\
                <td><%= labelTax %></td>\
                <td class="amount">+&#8202;<%= raceBetsJS.format.money(taxDue, 2, raceBetsJS.application.user.currency, true) %></td>\
            </tr>\
            <% if (taxSubvention > 0) { %>\
                <tr>\
                    <td><%= raceBetsJS.i18n.print("labelTaxSubvention", { amount: (taxSubventionRate.toString().replace(/\\./, raceBetsJS.application.globals.decimalSeparator) + "%") }) %></td>\
                    <td class="amount">-&#8202;<%= raceBetsJS.format.money(taxSubvention, 2, raceBetsJS.application.user.currency, true) %></td>\
                </tr>\
            <% } %>\
        </table>\
    ');
})(raceBetsJS);
