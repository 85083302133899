/**
* Bonus Money Info
*
* @author Przemek Marciniak <przemek.marciniak@betssongroup.com>
*/

(function (raceBetsJS) {
    raceBetsJS.application.assets.bonusMoney = function () {
        var show = function (bonusMoneyInfoElem, totalStake) {
            bonusMoneyInfoElem.hide();
            isVisible(totalStake).then(function (result){
                if (result) {
                    bonusMoneyInfoElem.show();
                    Tipped.create(bonusMoneyInfoElem, raceBetsJS.i18n.print('textBonusMoneyInfo', { url: raceBetsJS.application.assets.settings.getBrandSettings('supportURLs', 'bonuses') }), {
                        hook: 'topmiddle',
                        offset: { x: 2, y: -5 },
                        maxWidth: 200,
                        closeButton: false,
                        hideOn: 'click-outside',
                        onShow: function (content, element) {
                            $(content).addClass('tooltip-with-link')
                        }
                    });
                } else {
                    bonusMoneyInfoElem.hide();
                }
            })


        };

        var isVisible = function (totalStake) {

            return raceBetsJS.application.angularApp.commonCtrl().getUserBalance().then(function (result) {
                var withdrawable = result.balance.withdrawable;
                var bonusAmount = result.bonus.balance;
                if (raceBetsJS.application.user.country === 'GB' && bonusAmount && totalStake > withdrawable && result.balance.total >= totalStake) {
                    return true;
                } else {
                    return false;
                }
            });

        };

        return {
            show: show,
            isVisible: isVisible
        }
    }();
})(raceBetsJS);
