/**
* Todays Starters
*
* @author: Robin Ebers
*/

(function(raceBetsJS) {
    raceBetsJS.application.content.highlights = function() {

        function onContentLoaded() {
            $('#highlights-filter-button').click(function() {
                var href = '/content/show/module/highlights/upcoming/true';

                // sort by
                href += '/sortby/' + $('#highlights-filter-sort').val();

                // year
                if ($('#highlights-filter-year').val()) {
                    href += '/year/' + $('#highlights-filter-year').val();
                }

                // race type
                if ($('#highlights-filter-race-type').val()) {
                    href += '/raceType/' + $('#highlights-filter-race-type').val();
                }

                // categories
                if ($('#highlights-filter-category').val()) {
                    href += '/category/' + $('#highlights-filter-category').val();
                }

                // country
                if ($('#highlights-filter-country').val()) {
                    href += '/country/' + $('#highlights-filter-country').val();
                }

                href += '/';

                raceBetsJS.browser.history.navigateTo(raceBetsJS.application.globals.urlPrefix + href);
            });
        }

        // @public
        return {
            init: function() {
                // add content loaded observer
                raceBetsJS.application.contentController.addCallback(/\/content\/show\/module\/highlights\/upcoming\/true/, onContentLoaded);
            }
        };
    }();
})(raceBetsJS);