/**
* jQuery Extensions
*
* @author Moritz Honig
*/

$.extend({
    // camelize a string (e.g. test-test becomes testTest)
    /*camelize: function(string) {
        var parts = string.split('-');
        var ret = parts[0];
        for (var i = 1; i < parts.length; i++) {
            ret += parts[i].charAt(0).toUpperCase() + parts[i].substr(1);
        }
        return ret;
    },*/

    // sort an array with an callback
    mergeSort: function(arr, predicate) {
        function merge(left, right, predicate) {
            var result = [];

            while(left.length && right.length) {
                if(predicate(left[0].value, right[0].value)) {
                    result.push(left.shift());
                } else {
                    result.push(right.shift());
                }
            }

            while (left.length) {
                result.push(left.shift());
            }

            while (right.length) {
                result.push(right.shift());
            }

            return result;
        };

        if(arr.length < 2) {
            return arr;
        }

        var middle = parseInt(arr.length / 2);
        var left = arr.slice(0, middle);
        var right = arr.slice(middle, arr.length);

        return merge($.mergeSort(left, predicate), $.mergeSort(right, predicate), predicate);
    }
});